import {
    Backdrop,
    Fade,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {Autocomplete, DialogTitle, IconButton, TextField} from "@mui/material";
import {AddCircle, CancelOutlined, Edit, SaveOutlined} from "@material-ui/icons";
import Extras from "../../components/fields/Extras";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InfoChip from "../../components/chips/InfoChip";
import DateField from "../../components/fields/DateField";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import StyledButton from "../../components/buttons/StyledButton";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import QuoteService from "../../quotes/QuoteService";
import Notification from "../../components/notifications/Notification";


function AdminRowsTab(props) {

    const {t} = useTranslation();

    const ledgerAccounts = props.ledgerAccounts;
    const rows = props.rows;
    const accounts = props.accounts;
    const contracts = props.contracts;
    const extra = props.extra;

    const [savingQuote, setSavingQuote] = useState(false);

    const {id} = useParams();

    const [openExtra1, setOpenExtra1] = useState(false);
    const [openExtra2, setOpenExtra2] = useState(false);
    const [openExtra3, setOpenExtra3] = useState(false);

    const [openAddQuote, setOpenAddQuote] = useState(false);

    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customerContracts, setCustomerContracts] = useState([]);
    const [contract, setContract] = useState(null);
    const [amount, setAmount] = useState(null);
    const [date, setDate] = useState(new Date());
    const [description, setDescription] = useState(null);
    const [ledgerAccount, setLedgerAccount] = useState(null);

    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    async function fetchCustomers() {
        let filters = {};
        filters.isCustomer = true;
        let cust = await new AccountService().getAccounts(filters);
        setCustomers(cust);
    }

    async function fetchContracts(supplier) {
        let filters = {};
        filters.accountId = supplier.id

        let contr = await new ContractService().getContractsByFilters(filters);
        setCustomerContracts(contr);
    }

    function handleOpenExtra(id) {

        switch (id) {
            case 7:
                setOpenExtra1(true)
                break;
            case 8:
                setOpenExtra2(true)
                break;
            case 9:
                setOpenExtra3(true)
                break;
            default:
                break;
        }

    }

    function isExtra(ledgerAccount) {
        if (!extra) {
            return false;
        } else if (ledgerAccount.id === 7 || ledgerAccount.id === 8 || ledgerAccount.id === 9) {
            return true;
        }
    }

    function calculateTotal(rows, ledgerAccountId) {

        let total = 0;
        let _rows = rows;

        if (ledgerAccountId) {
            _rows = rows?.filter(row => row.ledgerAccountId === ledgerAccountId);
        }

        for (let i = 0; i < _rows?.length; i++) {
            if (_rows[i]?.type === "EARN") {
                total += _rows[i]?.amount;
            } else {
                total -= _rows[i]?.amount;
            }
        }

        return total;
    }

    async function saveNewQuote() {

        setSavingQuote(true);

        let quote = {};

        quote.ledgerAccountId = ledgerAccount?.id;
        quote.description = description;
        quote.amount = amount === 0? null : amount;
        quote.quoteType = "EARN";
        quote.currencyCode = "EUR";
        quote.accountCorporateCode = customer?.accountCorporateCode;
        quote.contractCode = contract?.code;
        quote.date = moment(date).format('yyyy-MM-DD');

        await new QuoteService().addQuote(id, quote)
            .then(()=>{
                setSavingQuote(false);
                props.refresh();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setSavingQuote(false);
                    setNotificationSeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotification(true);
                });
            })

    }

    async function deleteQuote(quoteId){

        await new QuoteService().deleteQuote(quoteId)
            .then(()=>{
                setTimeout(() => {
                    props.refresh();
                }, 1000)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotificationSeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotification(true);
                });
            })

    }

    useEffect(() => {

    })

    return <div>
        <Notification open={openNotification} severity={notificationSeverity} duration={3500}
                      onClose={()=>setOpenNotification(false)}>{notificationMessage}</Notification>
        {rows.length > 0 ?
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell id={"name-column"} style={{width: "5%"}}/>
                        <TableCell style={{width: "40%"}}> <Typography
                            variant={"button"}>{t("earns")}</Typography>
                            {props.type === "quotes" &&
                            <IconButton
                            onClick={() => setOpenAddQuote(true)}>
                                <AddCircle/>
                            </IconButton>
                            }
                        </TableCell>
                        <TableCell style={{width: "40%"}}> <Typography
                            variant={"button"}>{t("costs")}</Typography></TableCell>
                        <TableCell id={"total-column"}
                                   style={{width: "10%"}}> <Typography
                            variant={"button"}>{t("total")}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ledgerAccounts.map(ledgerAccount => {
                        return <TableRow
                            style={{display: (rows?.find(row => row.ledgerAccountId === ledgerAccount.id) || isExtra(ledgerAccount)) ? null : "none"}}>
                            <TableCell style={{border: "solid 2px"}}>
                                {/*The user is allowed to update the extra only on quotes*/}
                                <Typography
                                    style={{fontWeight: "bold", fontStyle: "italic"}}>{ledgerAccount.name}
                                    &nbsp;<IconButton onClick={() => handleOpenExtra(ledgerAccount.id)}
                                                      style={{display: extra && (ledgerAccount.id === 7 || ledgerAccount.id === 8 || ledgerAccount.id === 9) ? null : "none"}}><Edit/></IconButton>
                                </Typography>
                            </TableCell>
                            <TableCell style={{border: "solid 2px"}}>
                                {rows?.filter(row => row.ledgerAccountId === ledgerAccount.id && row.type === "EARN").map(row => {
                                    const vetAccount = accounts.filter(tmpAccount => row.accountCorporateCode === tmpAccount.accountCorporateCode);
                                    const account = 0 < vetAccount.length ? vetAccount[0] : null;

                                    const vetContract = contracts.filter(tmpContract => row.contractId === tmpContract.id);
                                    const contract = 0 < vetContract.length ? vetContract[0] : null;

                                    return <TableRow style={{width: "100%"}}>
                                        <TableCell style={{border: "none", width: "20%"}}>
                                            <Typography
                                                variant={"caption"}
                                                style={{fontWeight: "bold"}}>{new Date(row.date).toLocaleDateString()}</Typography>
                                            <Typography style={{fontStyle: "italic"}}>
                                                {row.username}
                                            </Typography>
                                        </TableCell>
                                        {/*<TableCell style={{border: "none", width: "40%"}}>*/}
                                        {/*    <Typography*/}
                                        {/*        variant={"caption"}*/}
                                        {/*        style={{fontWeight: "bold"}}>{row.description}</Typography>*/}
                                        {/*</TableCell>*/}
                                        <TableCell style={{border: "none", width: "70%"}}>
                                            <Typography
                                                variant={"caption"}
                                                style={{fontWeight: "bold"}}>{account ? account.accountCorporateCode + (contract ? " - " + contract.code : "") : ""}</Typography>
                                            <br/>
                                            {account ? account.companyName : ""}
                                        </TableCell>
                                        <TableCell style={{border: "none", width: "10%"}}>
                                            <Typography variant={"caption"}
                                                        style={{
                                                            color: "green",
                                                            fontWeight: "bold"
                                                        }}>{row.amount.toFixed(2)} {row.currencyCode}</Typography>
                                        </TableCell>
                                        {row.administrativeGroupId?
                                            <TableCell style={{border: "none", width: "10%"}}>
                                                <Typography variant={"subtitle2"}
                                                >{t("administrative_group") + " " + row.administrativeGroupId}</Typography>
                                            </TableCell>
                                            :
                                            null
                                        }
                                        <TableCell style={{border: "none", width: "10%"}}>
                                            {row.username && props.type === "quotes"?
                                                <IconButton onClick={()=>deleteQuote(row.id)}>
                                                    <CancelOutlined/>
                                                </IconButton>
                                                :
                                                null
                                            }
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableCell>
                            <TableCell style={{border: "solid 2px"}}>
                                {rows?.filter(row => row.ledgerAccountId === ledgerAccount.id && row.type === "COST").map(row => {
                                    const vetAccount = accounts.filter(tmpAccount => row.accountCorporateCode === tmpAccount.accountCorporateCode);
                                    const account = 0 < vetAccount.length ? vetAccount[0] : null;

                                    const vetContract = contracts.filter(tmpContract => row.contractId === tmpContract.id);
                                    const contract = 0 < vetContract.length ? vetContract[0] : null;

                                    return <TableRow style={{width: "100%"}}>
                                        <TableCell style={{border: "none", width: "20%"}}>
                                            <Typography
                                                variant={"caption"}
                                                style={{fontWeight: "bold"}}>{new Date(row.date).toLocaleDateString()}</Typography>
                                        </TableCell>
                                        {/*<TableCell style={{border: "none", width: "40%"}}>*/}
                                        {/*    <Typography*/}
                                        {/*        variant={"caption"}*/}
                                        {/*        style={{fontWeight: "bold"}}>{row.description}</Typography>*/}
                                        {/*</TableCell>*/}
                                        <TableCell style={{border: "none", width: "70%"}}>
                                            <Typography
                                                variant={"caption"}
                                                style={{fontWeight: "bold"}}>{account ? account.accountCorporateCode + (contract ? " - " + contract.code : "") : ""}</Typography>
                                            <br/>
                                            {account ? account.companyName : ""}
                                        </TableCell>
                                        <TableCell style={{border: "none", width: "10%"}}>
                                            <Typography
                                                variant={"caption"}
                                                style={{
                                                    color: "red",
                                                    fontWeight: "bold"
                                                }}>{row.amount.toFixed(2)} {row.currencyCode}</Typography>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableCell>

                            <TableCell style={{border: "solid 2px"}}>
                                <Typography
                                    variant={"caption"}
                                    style={{
                                        fontWeight: "bold",
                                        color: calculateTotal(rows, ledgerAccount.id) > 0 ? "green" : "red"
                                    }}>{calculateTotal(rows, ledgerAccount.id).toFixed(2)} {rows[0]?.currencyCode}</Typography>
                            </TableCell>

                        </TableRow>
                    })}
                    <TableRow>
                        <TableCell style={{border: "none"}}/>
                        <TableCell style={{border: "none"}}/>
                        <TableCell style={{border: "none"}}/>
                        <TableCell style={{border: "solid 2px"}}>
                            <Typography
                                variant={"caption"}
                                style={{
                                    fontWeight: "bold",
                                    color: calculateTotal(rows) > 0 ? "green" : "red"
                                }}>{calculateTotal(rows).toFixed(2)} {rows[0]?.currencyCode}</Typography>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
            :
            <InfoChip>{t("no_rows")}</InfoChip>
        }
        <Modal open={openExtra1}
               onClose={() => setOpenExtra1(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openExtra1}>
                <Paper style={{width: "90%", margin: "auto", marginTop: "5%"}}>
                    <div style={{width: "100%"}}>
                        <Extras
                            type={props.type}
                            refresh={props.refresh}
                            label={t("extra1")}
                            extras={rows.filter(row => row.ledgerAccountId === 7)}
                            id={7}
                        />
                    </div>
                </Paper>
            </Fade>
        </Modal>
        <Modal open={openExtra2}
               onClose={() => setOpenExtra2(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openExtra2}>
                <Paper style={{width: "90%", margin: "auto", marginTop: "5%"}}>
                    <div style={{width: "100%"}}>
                        <Extras
                            type={props.type}
                            refresh={props.refresh}
                            label={t("extra2")}
                            extras={rows.filter(row => row.ledgerAccountId === 8)}
                            id={8}
                        />
                    </div>
                </Paper>
            </Fade>
        </Modal>
        <Modal open={openExtra3}
               onClose={() => setOpenExtra3(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openExtra3}>
                <Paper style={{width: "90%", margin: "auto", marginTop: "5%"}}>
                    <div style={{width: "100%"}}>
                        <Extras
                            type={props.type}
                            refresh={props.refresh}
                            label={t("extra3")}
                            extras={rows.filter(row => row.ledgerAccountId === 9)}
                            id={9}
                        />
                    </div>
                </Paper>
            </Fade>
        </Modal>
        <Modal open={openAddQuote}
               onClose={() => setOpenAddQuote(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openAddQuote}>
                <Paper style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                    <div>
                        <div style={{margin: "auto", display: "flex"}}>
                            <DialogTitle>{t("earn")}</DialogTitle>
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                            <Autocomplete
                                id="ledgerAccount"
                                size="small"
                                style={{marginInline: "auto", width: "50%"}}
                                options={ledgerAccounts}
                                getOptionLabel={option => option.name}
                                value={ledgerAccount}
                                defaultValue={ledgerAccount}
                                noOptionsText={t("no_options")}
                                onChange={(event, ledgerAccount) => {
                                    setLedgerAccount(ledgerAccount);
                                }}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("ledger_account")}
                                        variant="outlined"
                                        margin="dense"
                                        value={ledgerAccount}
                                        defaultValue={ledgerAccount}
                                        required
                                        error={!ledgerAccount}
                                    />
                                )}
                            />
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                            <Autocomplete
                                id="customer"
                                size="small"
                                style={{marginInline: "auto", width: "50%"}}
                                options={customers}
                                getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                value={customer}
                                defaultValue={customer}
                                noOptionsText={t("no_options")}
                                onChange={(event, customer) => {
                                    setCustomer(customer);
                                    setContract(null)
                                }}
                                onOpen={fetchCustomers}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("customer")}
                                        variant="outlined"
                                        margin="dense"
                                        value={customer}
                                        defaultValue={customer}
                                        required
                                        error={!customer}
                                    />
                                )}
                            />
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                            <Autocomplete
                                id="contract"
                                size="small"
                                disabled={!customer}
                                style={{marginInline: "auto", width: "50%"}}
                                options={customerContracts}
                                onOpen={() => fetchContracts(customer)}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={contract}
                                defaultValue={contract}
                                noOptionsText={t("no_options")}
                                onChange={(event, contract) => setContract(contract)}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("contract")}
                                        variant="outlined"
                                        margin="dense"
                                        value={contract}
                                        defaultValue={contract}
                                        required
                                        error={!contract}
                                    />
                                )}
                            />
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                            <DateField
                                style={{margin: "auto", width: "25%"}}
                                label={t("date")}
                                value={date}
                                onChange={event => setDate(event)}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={false}
                            />
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                            <CurrencyTextField
                                style={{marginInline: "auto", width: "25%"}}
                                label={t("amount")}
                                required
                                error={!amount}
                                variant="outlined"
                                size={"small"}
                                onChange={(event, value) => setAmount(value)}
                                value={amount}
                                currencySymbol="€"
                                outputFormat="number"
                                digitGroupSeparator={"."}
                                decimalCharacter={","}
                            />
                        </div>
                        <div style={{display: "flex", width: "100%", marginBlock: "2%"}}>
                            <TextField
                                style={{marginInline: "auto", width: "50%"}}
                                label={t("description")}
                                variant="outlined"
                                size={"small"}
                                margin="dense"
                                value={description}
                                defaultValue={description}
                                required={true}
                                error={!description}
                                onChange={event => setDescription(event.target.value)}
                            />
                        </div>
                        <div style={{display: "flex", width: "fit-content", margin: "auto"}}>
                            <StyledButton onClick={saveNewQuote}
                                          disabled={savingQuote}
                                          variant={"contained"}
                                          style={{marginBottom: "5%"}}
                                          color={"primary"}
                                          icon={
                                              <SaveOutlined/>}
                            >{t("save")}</StyledButton>
                        </div>

                    </div>
                </Paper>
            </Fade>
        </Modal>

    </div>


}

export default AdminRowsTab;