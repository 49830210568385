import {Button, Paper, Table, TableCell, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, DialogTitle, IconButton, TableRow, Typography} from "@mui/material";
import WarehouseService from "../../warehouses/WarehouseService";
import {CancelRounded, SaveOutlined} from "@material-ui/icons";
import theme from "../../theme/theme";
import RouteService from "./RouteService";
import {useHistory} from "react-router-dom";
import Notification from "../../components/notifications/Notification";


function RoutesForm(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [name, setName] = useState('');
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [refresh, setRefresh] = useState(false);

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handleWarehouseChange(event, ware) {

        if (null !== ware && selectedWarehouses.length > 0 && selectedWarehouses[selectedWarehouses.length - 1].code === ware.code) {


        } else {
            setWarehouse(ware);

            if (null !== ware) {
                addWarehouse(ware);
            }
        }

    }

    function addWarehouse(warehouse) {
        let wares = selectedWarehouses;
        wares.push(warehouse);
        setSelectedWarehouses(wares);

        setRefresh(!refresh);

    }

    function removeWarehouse(index) {
        let wares = selectedWarehouses;

        wares.splice(index, 1);

        setSelectedWarehouses(wares);

        setRefresh(!refresh);
    }


    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }


    async function saveRoute() {

        let route = {};

        route.name = name;
        route.warehouses = [];

        for (let i = 0; i < selectedWarehouses.length; i++) {
            route.warehouses.push(selectedWarehouses[i].id);
        }

        await new RouteService().saveRoute(route)
            .then(result => {
                history.push("/routes")
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });

    }

    useEffect(() => {

    }, [refresh, selectedWarehouses])


    return <Paper style={{width: "100%"}}>
        <div style={{display: "flex"}}>
            <DialogTitle style={{margin: "auto"}}>{t("new_route")}</DialogTitle>
        </div>
        <div style={{display: "flex"}}>
            <TextField
                required
                error={!name}
                label={t("name")}
                variant="outlined"
                type="text"
                margin="dense"
                onChange={handleNameChange}
                value={name}
                style={{width: "15%", margin: "auto"}}
            />
        </div>

        <div style={{display: "flex"}}>
            <Autocomplete
                id="warehouse-select"
                size="small"
                style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                onOpen={fetchWarehouses}
                noOptionsText={t("no_options")}
                onChange={handleWarehouseChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
        </div>

        <div style={{display: "block"}}>
            <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                <Typography variant={"button"}>{t("route")}</Typography>
            </div>
            <div>
                <Table style={{width: "fit-content", display: "block", margin: "auto"}}>
                    {selectedWarehouses.map((warehouse, index) => {

                            return <TableRow style={{width: "100%", margin: "auto"}}>
                                <TableCell style={{width: "5%"}}>
                                    {index + 1}
                                </TableCell>
                                <TableCell style={{width: "50%"}}>
                                    {warehouse.name + " - " + warehouse.code}
                                </TableCell>
                                <TableCell style={{width: "5%"}}>
                                    <IconButton onClick={() => removeWarehouse(index)}>
                                        <CancelRounded/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        }
                    )}
                </Table>
            </div>


        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>

        <div style={{width: "100%", display: "flex"}}>
            <Button  onClick={saveRoute} style={{
                width: "fit-content",
                margin: "auto", marginBlock: "2%", backgroundColor: theme.palette.primary.main,
                color: "white",
            }} endIcon={<SaveOutlined/>}>{t("save")}</Button>
        </div>


    </Paper>

}

export default RoutesForm;