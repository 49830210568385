import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import RoleService from "./RoleService";
import {Button, Dialog, Menu, MenuItem, Table, TableCell, TableRow, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";


function Role(props) {

    const role = props.role;
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userList, setUserList] = useState([]);
    const [rolePermissionList, setRolePermissionList] = useState([]);
    const history = useHistory();


    let useStyles = makeStyles((theme) => ({


        icon: {
            marginRight: "5%"
        },
        iconCell: {
            justifyContent: "end",
            margin: "auto",
            textAlign: 'end',
            display: "block",
        },
        anchor: {
            width: "auto",
            cursor: "pointer",
            transition: "all 0.2s",

            "&:hover": {

                background: 'linear-gradient(90deg, #f5f5f5 10%, #ffffff 100%)',

            },

        },
        label: {
            ...theme.typography.button,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
        },
        userDetailTypo: {
            ...theme.typography.button,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),

        },

    }));

    const classes = useStyles();

    useEffect(() => {
        let roleService = new RoleService();
        roleService.getUsers(role.id)
            .then((response) => {
                setUserList(response);
            })
            .catch((error) => {

            })
        roleService.getPermissionsOfRole(role.id)
            .then((response) => {
                setRolePermissionList(response);
            })
            .catch((error) => {

            })

    }, []);


    if (!role) {
        return
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openRoleModal = () => {
        setOpen(true);
    }

    const closeRoleModal = () => {
        setOpen(false);
    }

    const editRole = (roleId) => {
        setAnchorEl(null);
        history.push(`/roles/${roleId}/edit/`);
    }

    function deleteRole(roleId) {
        let roleService = new RoleService();
        roleService.deleteRole(roleId)
            .then(() => {
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then((response) => {
                });
            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    return <TableRow>

        <TableCell className={classes.anchor} onClick={() => openRoleModal(role.id)}>{role.name}</TableCell>

        <TableCell className={classes.iconCell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>

            <Dialog open={open} keepMounted scroll={"body"} onClose={closeRoleModal}>
                <Table size="small">

                    <div className={classes.label}>{t("permission_list_of")}&nbsp;{role.name}</div>

                    {rolePermissionList.map((permission, index) => {

                        return <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                <Typography variant="subtitle2" display="block"
                                            gutterBottom>{permission.name}</Typography>
                            </TableCell>
                            <TableCell align="left"><Typography variant="caption">{permission.description}</Typography></TableCell>
                        </TableRow>

                    })}
                </Table>
                <div className={classes.label}>{t("user_list_with_role")}{role.name}</div>
                {userList.map((user, index) => {

                    return <TableCell align="center">
                        <Typography variant="subtitle2" display="block"
                                    gutterBottom>[&nbsp;{user.username}&nbsp;]</Typography>
                    </TableCell>

                })}

            </Dialog>
            <Menu
                id="role-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => editRole(role.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

                <MenuItem onClick={() => deleteRole(role.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
                </MenuItem>
            </Menu>
        </TableCell>
    </TableRow>

}


export default Role;