import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import PaymentToCustomerService from "./PaymentToCustomerService";
import {Button, Card, DialogTitle, Grid, Table, TableCell, TableRow} from "@material-ui/core";
import BankAccountService from "../../bank_accounts/BankAccountService";
import {Autocomplete, IconButton, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CompanyService from "../../companies/CompanyService";
import {AddCircleOutlined, ArrowBackRounded, RemoveCircleOutlined, SaveOutlined} from "@material-ui/icons";
import DateField from "../../components/fields/DateField";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Notification from "../../components/notifications/Notification";


function ConfirmPaymentToCustomerPage(props) {


    const [paymentToCustomer, setPaymentToCustomer] = useState(null);

    const [transactions, setTransactions] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();

    async function confirmPayment() {

        let input = [];

        let totalOfTransactions = 0;

        for (let i = 0; i < transactions.length; i++) {
            let transaction = {};

            transaction.valueDate = transactions[i].valueDate
            transaction.accountingDate = transactions[i].accountingDate
            transaction.senderControlInternalNumber = transactions[i].senderControlInternalNumber
            transaction.senderBankCode = transactions[i].senderBankCode
            transaction.senderBranchCode = transactions[i].senderBranchCode
            transaction.senderAccountNumber = transactions[i].senderAccountNumber
            transaction.reason = transactions[i].reason
            transaction.reference = transactions[i].reference
            transaction.chequeNumber = transactions[i].chequeNumber
            transaction.amount = transactions[i].amount
            transaction.bankAccountId = transactions[i].bankAccount?.id

            transaction.splits = [];

            let split = {amount: transactions[i].amount, paymentToCustomerId: paymentToCustomer.id};

            totalOfTransactions += transactions[i].amount;

            transaction.splits.push(split);

            input.push(transaction);
        }

        if (totalOfTransactions !== paymentToCustomer.totalAmount) {
            setNotificationMessage(t("total_transactions_not_equal_total_paid") + totalOfTransactions + " : " + paymentToCustomer.totalAmount);
            setNotifySeverity("error");
            setOpenNotify(true);
            return;
        }


        await new PaymentToCustomerService().confirmPaymentToCustomer(paymentToCustomer.id, input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity("success");
                setOpenNotify(true);
                setTimeout(()=>{
                    history.push("/payments/customer")
                }, 1000)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchPaymentToCustomer() {


        await new PaymentToCustomerService().getPaymentToCustomerById(id)
            .then(result => {
                setPaymentToCustomer(result);
            })
            .catch(error => {

            })

    }

    async function fetchCompanies() {

        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {

            })
    }

    async function fetchBankAccounts(company) {

        let filters = {};

        filters.companyId = company?.id;

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result);
            })
            .catch(error => {

            })
    }


    async function addTransaction() {

        let filters = {};

        filters.companyId = paymentToCustomer?.company.id;

        let bankAccounts = await new BankAccountService().getBankAccountsByFilters(filters)
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            // setNotificationMessage(response.status + " - " + response.message);
                            // setNotifySeverity('error');
                            // setOpenNotify(true);
                        })
                } else {
                    // setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    // setNotifySeverity('error');
                    // setOpenNotify(true);
                }
            })

        let totalExistingTransactions = 0;

        paymentToCustomer?.transactions?.map(transaction => {

            for (let i = 0; i < transaction.splits.length; i++) {
                if (transaction.splits[i].paymentToCustomerId === paymentToCustomer.id) {
                    totalExistingTransactions += transaction.splits[i].amount;
                }
            }

        })


        let totalOfActualTransactions = 0;

        transactions.map(transaction => totalOfActualTransactions += transaction.amount);

        let cin;
        let bankCode;
        let branchCode;
        let accountNumber;


        cin = paymentToCustomer?.customerBankAccount?.iban.substr(2, 3);
        bankCode = paymentToCustomer?.customerBankAccount?.iban.substr(5, 5);
        branchCode = paymentToCustomer?.customerBankAccount?.iban.substr(10, 5);
        accountNumber = paymentToCustomer?.customerBankAccount?.iban.substr(15, 12);


        let transaction = {
            valueDate: null,
            accountingDate: null,
            senderControlInternalNumber: cin,
            senderBankCode: bankCode,
            senderBranchCode: branchCode,
            senderAccountNumber: accountNumber,
            reason: "Saldo distinta ",
            reference: null,
            chequeNumber: null,
            amount: paymentToCustomer ? paymentToCustomer.totalAmount - totalExistingTransactions - totalOfActualTransactions : 0,
            company: companies.length >= 1 ? companies.filter(company => company.id === paymentToCustomer?.company?.id)[0] : null,
            bankAccount: bankAccounts.length >= 1 ? bankAccounts.filter(bankAccount => bankAccount.id === paymentToCustomer?.companyBankAccount?.id)[0] : null
        }

        setTransactions([...transactions, transaction]);

        // console.log(transactions);
    }

    function removeTransaction(index) {

        transactions.splice(index, 1);
        setRefresh(!refresh);

    }


    useEffect(() => {
        fetchPaymentToCustomer();
        fetchCompanies();
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }, [])

    useEffect(() => {

    }, [refresh])


    return <div>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={() => history.goBack()}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>
        </div>
        <Card style={{width: "95%", margin: "auto"}}>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("payment_to_customer")}</DialogTitle>
            <Table>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("payment_to_customer_number")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{paymentToCustomer?.id}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("total")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{paymentToCustomer?.currency + " " + paymentToCustomer?.totalAmount?.toFixed(2)}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("company")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{paymentToCustomer?.company?.companyName}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("bank_name")}</Typography>
                        <Typography variant={"subtitle2"}>{t("agency_name")}</Typography>
                        <Typography variant={"subtitle2"}>{t("iban")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{paymentToCustomer?.companyBankAccount?.bankName}</Typography>
                        <Typography>{paymentToCustomer?.companyBankAccount?.agencyName}</Typography>
                        <Typography>{paymentToCustomer?.companyBankAccount?.iban}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("created_at")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{new Date(paymentToCustomer?.createdAt).toLocaleDateString() + " - " + new Date(paymentToCustomer?.createdAt).toLocaleTimeString()}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("contract")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{paymentToCustomer?.contract?.code}</Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </Card>
        <Button startIcon={<AddCircleOutlined/>} disabled={!paymentToCustomer}
                style={{marginBlock: "3%"}}
                variant="contained"
                color="primary" type="button" onClick={addTransaction}>
            {t("add_transaction")}
        </Button>
        <Grid item xs={12} style={{marginTop: "5%"}}>
            <Grid container justifyContent="center" spacing={2}>
                {transactions?.map((transaction, index) => {
                    return <Grid item style={{width: "50%", margin: "auto", marginBottom: "2%"}}>
                        <Card>
                            <div style={{display: "flex"}}>
                                <DialogTitle>{t("transaction")} {index + 1}</DialogTitle>
                                <div style={{width: "100%", display: "flex"}}>
                                    <IconButton style={{marginLeft: "auto", background: "none"}}
                                                onClick={() => removeTransaction(index)}>
                                        <RemoveCircleOutlined/>
                                    </IconButton>
                                </div>
                            </div>
                            <div>
                                <Autocomplete
                                    id="companies"
                                    size="small"
                                    style={{width: "60%", margin: "auto"}}
                                    options={companies}
                                    getOptionLabel={option => option.companyName}
                                    value={transaction.company}
                                    defaultValue={transaction.company}
                                    noOptionsText={t("no_options")}
                                    onOpen={fetchCompanies}
                                    onChange={(event, value) => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["company"] = value;
                                        if (value === null) {
                                            newTransactions[index]["bankAccount"] = null;
                                        }
                                        setTransactions(newTransactions);
                                    }}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("company")}
                                            variant="outlined"
                                            margin="dense"
                                            value={transaction.company}
                                            defaultValue={transaction.company}
                                            required
                                            error={!transaction.company}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="bank-account"
                                    size="small"
                                    disabled={!transaction.company}
                                    options={bankAccounts}
                                    style={{width: "60%", margin: "auto"}}
                                    getOptionLabel={option => option.bankName + " - " + option.iban}
                                    value={transaction.bankAccount}
                                    defaultValue={transaction.bankAccount}
                                    noOptionsText={t("no_options")}
                                    onOpen={() => fetchBankAccounts(transaction.company)}
                                    onChange={(event, value) => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["bankAccount"] = value;
                                        setTransactions(newTransactions);
                                    }}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("bank_account")}
                                            variant="outlined"
                                            margin="dense"
                                            value={transaction.bankAccount}
                                            defaultValue={transaction.bankAccount}
                                            required
                                            error={!transaction.bankAccount}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{width: "100%", display: "flex"}}>
                                <DateField
                                    style={{width: "40%", margin: "auto"}}
                                    label={t("value_date")}
                                    value={transaction.valueDate}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["valueDate"] = event;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.valueDate}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                    clearable={false}
                                />
                                <DateField
                                    style={{width: "40%", margin: "auto"}}
                                    label={t("accounting_date")}
                                    value={transaction.accountingDate}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["accountingDate"] = event;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.accountingDate}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                    clearable={false}
                                />
                            </div>
                            <div style={{marginTop: "2%", display: "flex"}}>
                                <TextField
                                    style={{width: "60%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("sender_account_number")}
                                    value={transaction.senderAccountNumber}
                                    defaultValue={transaction.senderAccountNumber}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["senderAccountNumber"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.senderAccountNumber}
                                />
                            </div>
                            <div style={{marginTop: "4%", display: "flex"}}>
                                <TextField
                                    style={{width: "30%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("sender_cin")}
                                    value={transaction.senderControlInternalNumber}
                                    defaultValue={transaction.senderControlInternalNumber}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["senderControlInternalNumber"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.senderControlInternalNumber}
                                />
                                <TextField
                                    style={{width: "30%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("sender_bank_code")}
                                    value={transaction.senderBankCode}
                                    defaultValue={transaction.senderBankCode}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["senderBankCode"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.senderBankCode}
                                />
                                <TextField
                                    style={{width: "30%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("sender_branch_code")}
                                    value={transaction.senderBranchCode}
                                    defaultValue={transaction.senderBranchCode}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["senderBranchCode"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.senderBranchCode}
                                />
                            </div>
                            <div style={{marginTop: "4%", display: "flex"}}>
                                <TextField
                                    style={{width: "40%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("transaction_reference")}
                                    value={transaction.reference}
                                    defaultValue={transaction.reference}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["reference"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                />
                                <TextField
                                    style={{width: "40%", margin: "auto"}}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("cheque_number")}
                                    value={transaction.chequeNumber}
                                    defaultValue={transaction.chequeNumber}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["chequeNumber"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                />
                            </div>
                            <div style={{marginTop: "4%", display: "flex"}}>
                                <TextField
                                    style={{width: "60%", margin: "auto"}}
                                    multiline
                                    minRows={1}
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("reason")}
                                    value={transaction.reason}
                                    defaultValue={transaction.reason}
                                    onChange={event => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["reason"] = event.target.value;
                                        setTransactions(newTransactions);
                                    }}
                                    required={true}
                                    error={!transaction.reason}
                                />
                            </div>
                            <div style={{marginTop: "4%", display: "flex", marginBottom: "2%"}}>
                                <CurrencyTextField
                                    style={{width: "40%", margin: "auto"}}
                                    label={t("amount")}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    digitGroupSeparator={"."}
                                    decimalCharacter={","}
                                    decimalPlaces={2}
                                    value={transaction.amount}
                                    currencySymbol={"€"}
                                    onChange={(event, value) => {
                                        let newTransactions = [...transactions];
                                        newTransactions[index]["amount"] = value;
                                        setTransactions(newTransactions);
                                    }}
                                    required
                                    error={!transaction.amount}
                                />
                            </div>
                        </Card>
                    </Grid>
                })
                }
            </Grid>
        </Grid>

        <Button startIcon={<SaveOutlined/>} disabled={!paymentToCustomer || transactions.length < 1}
                style={{marginBlock: "3%"}}
                variant="contained"
                color="primary" type="button" onClick={confirmPayment}>
            {t("confirm_payment")}
        </Button>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>


}

export default ConfirmPaymentToCustomerPage;