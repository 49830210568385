import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

const userService = new UserService();

export default class RoutingRuleService {

    getRoutingRulesByFilters(filters) {


        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/routing-rules`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    saveNewRoutingRule(countryId, administrativeLevel1Id, administrativeLevel2Id, administrativeLevel3Id, cityId, postalCodeId, hamletId, serviceId, districtId) {


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                countryId: countryId,
                administrativeLevel1Id: administrativeLevel1Id,
                administrativeLevel2Id: administrativeLevel2Id,
                administrativeLevel3Id: administrativeLevel3Id,
                cityId: cityId,
                postalCodeId: postalCodeId,
                hamletId: hamletId,
                serviceId: serviceId,
                districtId: districtId
            })
        };

        return fetch('/api/v1/routing-rules', requestOptions)
            .then(checkStatus)
    }

    deleteRoutingRule(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/routing-rules/${id}`, requestOptions)
            .then(checkStatus)
    }

}