import {Backdrop, Button, Modal, TableCell, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {CircularProgress, DialogTitle, Fade, IconButton, Paper, Typography} from "@mui/material";
import {ArrowCircleDown, ArrowCircleUp, CloudDownload} from "@mui/icons-material";
import ReportWarehouse from "./ReportWarehouse";
import InfoChip from "../../components/chips/InfoChip";
import CodService from "../../shipments/services/CodService";
import theme from "../../theme/theme";


const useStyles = makeStyles({
    root: {
        // cursor: "pointer",
        // transition: "all 0.2s",
        //
        // "&:hover": {
        //
        //     background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'
        //
        //
        // },
        // '& > *': {
        //     borderBottom: 'unset',
        // },
    },
    iconCell: {
        justifyContent: "end",
        margin: "auto",
        textAlign: 'end',
        display: "block",
    },
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }


});

function ReportSupplier(props) {


    let supplier = props.supplier;

    const {t} = useTranslation();

    let classes = useStyles();

    const [openRows, setOpenRows] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [shipments, setShipments] = useState([]);

    const [loadingCSV, setLoadingCSV] = useState(false)

    function openModalAndSetShipments(shipments) {

        if (shipments.length > 0) {
            setShipments(shipments)
            setOpenModal(true);
        }

    }

    async function downloadCSV() {

        setLoadingCSV(true);

        let input = {};
        input.shipmentIds = []

        for (let i = 0; i < shipments.length; i++) {
            input.shipmentIds.push(shipments[i]);
        }


        await new CodService().getReportCsv(input)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch(error => {

            })

        setLoadingCSV(false);

    }

    function getShipmentListAndTotalCodValue(list, keys) {

        let output = {};

        output.shipments = [];
        output.totalValue = 0;

        let total = 0;
        let shipments = [];


        for (let i = 0; i < list?.warehouses.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                supplier.warehouses[i][keys[j]].map((shipment) => {
                    total += shipment.value;
                    shipments.push(shipment.shipmentId);
                })
            }

        }


        output.shipments = shipments;
        output.totalValue = total;

        return output;


    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }


    return <React.Fragment>
        <TableRow className={classes.root}>
            <TableCell>
                <IconButton button onClick={() => setOpenRows(!openRows)}>
                    {openRows ?
                        <ArrowCircleUp/>
                        :
                        <ArrowCircleDown/>
                    }
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
                {supplier.supplierId ? supplier.supplierCompanyName : t("undefined")}
            </TableCell>
            <TableCell align="left">
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["toCollectWithoutCheckin"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["toCollectWithoutCheckin"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["toCollectWithCheckin"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["toCollectWithCheckin"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["collected"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["collected"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["verifiedInWarehouse"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["verifiedInWarehouse"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["paidOnBankAccount"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["paidOnBankAccount"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    onClick={() => openModalAndSetShipments(getShipmentListAndTotalCodValue(supplier, ["collected", "verifiedInWarehouse", "paidOnBankAccount"]).shipments)}
                    className={classes.link}> {getShipmentListAndTotalCodValue(supplier, ["collected", "verifiedInWarehouse", "paidOnBankAccount"]).totalValue.toFixed(2)} </Typography>
            </TableCell>
        </TableRow>
        {supplier.warehouses.length === 0 ?
            <InfoChip>{t("no_data")}</InfoChip>
            :
            <>
                {supplier.warehouses?.map(warehouse => {
                    return <ReportWarehouse warehouse={warehouse} in={openRows} openModal={openModalAndSetShipments}/>
                })}
            </>

        }
        <Modal open={openModal}
               onClose={() => setOpenModal(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openModal}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "10%",
                    height: "80%",
                    // overflow: "hidden",
                    overflow: "auto",
                    paddingBottom: "1%",
                    // border: `1px solid black`
                }}>
                    <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("shipments")}</DialogTitle>
                    <div style={{display: "flex"}}>
                        <Button style={{width: "fit-content", margin: "auto", backgroundColor: theme.palette.primary.main, color: "white", marginBottom: "5%"}} disabled={loadingCSV || shipments.length < 1} onClick={downloadCSV}
                                endIcon={loadingCSV ?
                                    <CircularProgress size={24} style={{color: "white"}}/> :
                                    <CloudDownload/>}>{loadingCSV ? t("please_wait") : t("download_csv")}</Button>
                    </div>

                    {shipments.map(shipment => {
                        return <div style={{width: "fit-content", margin: "auto", marginBottom: "2%"}}>
                            <Typography variant={"subtitle2"} onClick={() => goToAboutMe(shipment)}
                                        className={classes.link}>
                                {shipment}
                            </Typography>
                        </div>

                    })}


                </Paper>
            </Fade>

        </Modal>

    </React.Fragment>


}

export default ReportSupplier;