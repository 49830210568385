import React, {useEffect, useState} from "react";
import {
    Container,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import CityService from "./CityService";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import City from "./City";
import {PrivateRoute} from "../../routes/PrivateRoute";
import AdministrativeLevel1Service from "../administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../administrative_level_3/AdministrativeLevel3Service";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import CountryService from "../countries/CountryService";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import CityForm from "./CityForm";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Notification from "../../components/notifications/Notification";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));


function CitiesTable(props) {

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [countrySelected, setCountrySelected] = useState(null);
    const [adminL1Selected, setAdminL1Selected] = useState(null);
    const [adminL2Selected, setAdminL2Selected] = useState(null);
    const [adminL3Selected, setAdminL3Selected] = useState(null);
    const [cityLike, setCityLike] = useState(null);
    const [cities, setCities] = useState([]);
    const [admin1, setAdmin1] = useState([]);
    const [admin2, setAdmin2] = useState([]);
    const [admin3, setAdmin3] = useState([]);
    const [admin1Filter, setAdmin1Filter] = useState([]);
    const [admin2Filter, setAdmin2Filter] = useState([]);
    const [admin3Filter, setAdmin3Filter] = useState([]);
    const [pagedAdminL1, setPagedAdminL1] = useState([]);
    const [pagedAdminL2, setPagedAdminL2] = useState([]);
    const [pagedAdminL3, setPagedAdminL3] = useState([]);

    const [filterShow, setFilterShow] = useState(false);

    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [pages, setPages] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");

        setRefreshTable(!refreshTable);

    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };

    function closeNotification() {
        setOpenNotify(false);
    }


    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }


    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then((countryData) => {
                setCountries(countryData.countries)
            })
            .catch((fetchingError) => {
                setError(fetchingError.message);
            })
    }

    function toggleFilterMask() {
        setCountrySelected(null);
        setAdminL1Selected(null);
        setAdminL2Selected(null);
        setAdminL3Selected(null);
        setCityLike(null);
        setFilterShow(!filterShow);
        setPage(0);
        fetchCities();
    }

    function applyFilter() {
        setPage(0);
        fetchCities();
    }

    function fetchCities() {

        let filters = {};
        if (adminL1Selected) {
            filters.level1Id = adminL1Selected.id;
        }
        if (adminL2Selected) {
            filters.level2Id = adminL2Selected.id;
        }
        if (adminL3Selected) {
            filters.level3Id = adminL3Selected.id;
        }
        if (countrySelected) {
            filters.countryId = countrySelected.id;
        }
        if (cityLike) {
            filters.cityNameLike = cityLike;
        }

        setFetching(true);
        let cityService = new CityService();
        let adminL1service = new AdministrativeLevel1Service();
        let adminL2service = new AdministrativeLevel2Service();
        let adminL3service = new AdministrativeLevel3Service();

        cityService.getCities(page, rowsPerPage, filters, null)
            .then(async data => {
                setCities(data.cities);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                let filters = {}
                filters.idList = [];
                data.cities.map(city => {
                    if (!filters.idList.includes(city.level_1_id)) {
                        filters.idList.push(city.level_1_id)
                    }
                })


                await adminL1service.getAllAdminLevel1WithFilters(null, null, filters, null)
                    .then(data => {
                        setPagedAdminL1(data.admins1)
                    })
                filters.idList = [];
                data.cities.map(city => {
                    if (!filters.idList.includes(city.level_2_id)) {
                        filters.idList.push(city.level_2_id)
                    }
                })


                await adminL2service.getAllAdminLevel2WithFilters(null, null, filters, null)
                    .then(data => {
                        setPagedAdminL2(data.admins2)
                    })

                filters.idList = [];
                data.cities.map(city => {
                    if (!filters.idList.includes(city.level_3_id)) {
                        filters.idList.push(city.level_3_id)
                    }
                })


                await adminL3service.getAllAdminLevel3WithFilters(null, null, filters, null)
                    .then(data => {
                        setPagedAdminL3(data.admins3)
                        setFetching(false);
                    })
            })
            .catch(error => {
                setError(error.message)
            })

    }


    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();


        if (countrySelected) {
            let filters = {};
            filters.countryId = countrySelected.id;

            adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
                .then(filteredAdminL1 => {
                    setAdmin1Filter(filteredAdminL1.admins1);

                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        } else {
            adminL1Service.getAllAdminLevel1WithFilters(null, null, null, null)
                .then(filteredAdminL1 => {
                    setAdmin1(filteredAdminL1.admins1);

                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        }

    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        if (adminL1Selected) {
            let filters = {};
            filters.adminL1 = adminL1Selected.id;
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(filteredAdminL2 => {
                    setAdmin2Filter(filteredAdminL2.admins2);
                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        } else {
            adminL2Service.getAllAdminLevel2WithFilters(null, null, null, null)
                .then(filteredAdminL2 => {
                    setAdmin2(filteredAdminL2.admins2);

                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        }

    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();
        if (adminL2Selected) {
            let filters = {};
            filters.adminL2 = adminL2Selected.id;
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(filteredAdminL3 => {
                    setAdmin3Filter(filteredAdminL3.admins3);
                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        } else {
            adminL3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(filteredAdminL3 => {
                    setAdmin3(filteredAdminL3.admins3);

                })
                .catch(fetchingError => {
                    setError(fetchingError.message);
                })
        }

    }


    function goToCityForm() {
        history.push("cities/new");
    }

    function handleCountryChange(event, country) {

        setCountrySelected(country);
        setAdminL3Selected(null);
        setAdminL2Selected(null);
        setAdminL1Selected(null);
    }


    function handleAdminL1Change(event, adminL1) {

        setAdminL1Selected(adminL1);
        setAdminL3Selected(null)
        setAdminL2Selected(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2Selected(adminL2);
        setAdminL3Selected(null);

    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3Selected(adminL3);

    }


    useEffect(() => {

        if (admin1.length === 0) {
            fetchCountries();
            fetchAdminL1();
            fetchAdminL2();
            fetchAdminL3();
        }
        fetchCities();

    }, [location, page, rowsPerPage, refreshTable])

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    return <Container className={classes.root}>

        <Switch>
            <PrivateRoute exact path={"/cities"}>

                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToCityForm}
                        className={classes.button}>
                    {t("add_new_city")}
                </Button>
                <div className={classes.filterContainer}>
                    <Button startIcon={<FilterListIcon/>} className={classes.button} variant="contained" type="button"
                            onClick={toggleFilterMask}>
                        {filterShow ? t("cancel_filter") : t("apply_filter")}
                    </Button>
                    {filterShow ? (
                        <Paper elevation={3} className={classes.filterMask}>
                            <div className={classes.label}/>

                            <Autocomplete
                                id="countries"
                                className={classes.searchField}
                                options={countries}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                onChange={handleCountryChange}
                                onBlur={fetchAdminL1}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <span>{countryToFlag(option.iso3166Alpha2)}</span>
                                        {option.name} ({option.iso3166Alpha3}) +{option.iso3166Numeric}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("country")}
                                        variant="outlined"
                                        margin="dense"
                                        value={countrySelected}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="adminL1"
                                className={classes.searchField}
                                options={admin1Filter}
                                disabled={!countrySelected}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                onChange={handleAdminL1Change}
                                onBlur={fetchAdminL2}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("administrative1")}
                                        variant="outlined"
                                        margin="dense"
                                        value={adminL1Selected}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />

                            <Autocomplete
                                id="administrative2"
                                className={classes.searchField}
                                options={admin2Filter}
                                disabled={!adminL1Selected}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={adminL2Selected}
                                onChange={handleAdminL2Change}
                                onBlur={fetchAdminL3}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative2")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={adminL2Selected}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="administrative3"
                                className={classes.searchField}
                                options={admin3Filter}
                                disabled={!adminL2Selected}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={adminL3Selected}
                                onChange={handleAdminL3Change}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative3")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={adminL3Selected}

                                    />
                                )}
                            />

                            <TextField
                                className={classes.field}
                                label={t("city_name")}
                                variant="outlined"
                                type="text"
                                margin="dense"
                                onChange={event => setCityLike(event.target.value)}
                                value={cityLike}
                            />


                            <Button startIcon={<CheckCircleOutlineIcon/>} className={classes.filterbutton}
                                    variant="contained"
                                    color="primary" type="button" onClick={applyFilter}>
                                {t("apply_filter")}
                            </Button>

                        </Paper>

                    ) : null}
                </div>


                <TableContainer className={classes.container}>
                    {(fetching) ?
                        <Table>
                            <LoadingComponent/>
                        </Table>

                        :
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableCell className={classes.cell}>{t("city")}</TableCell>
                                <TableCell>{t("administrative3")}</TableCell>
                                <TableCell>{t("administrative2")}</TableCell>
                                <TableCell>{t("administrative1")}</TableCell>
                                <TableCell/>

                            </TableHead>

                            <TableBody>


                                {cities.map((city, index) => {

                                    return <City deleteCallback={deleteCallback} city={city} level1={pagedAdminL1}
                                                 level2={pagedAdminL2}
                                                 level3={pagedAdminL3}/>
                                })}
                            </TableBody>

                            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                          onClose={closeNotification}>{notificationMessage}</Notification>
                        </Table>
                    }

                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    count={-1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                    }}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={() => {
                        return t("page")
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </PrivateRoute>
            <PrivateRoute strict path={`/cities/:id/edit/`} children={<CityForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/cities/new/`}>
                <CityForm city={null}/>
            </PrivateRoute>
        </Switch>
    </Container>

}

export default CitiesTable;