import UserService from "../../users/UserService";
import {checkStatus} from "../../api/ApiUtils";
import fetch from "unfetch";


export default class CostCenterService{


    getCostCenterById(id){
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/cost-centers/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getAllCostCenters() {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/cost-centers`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addNewCostCenter(name, code){
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({name:name, code:code})
        };
        return fetch(`/api/v1/cost-centers`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    editCostCenter(id, name, code){
         const userService = new UserService();
         const apiKey = userService.getApiKey();

         const requestOptions = {
             method: "PUT",
             headers: {
                 'Authorization': 'Bearer ' + apiKey,
                 'Content-Type': 'application/json',
                 'Accept': 'application/json'
             },
             body: JSON.stringify({name:name, code:code})
         };
         return fetch(`/api/v1/cost-centers/${id}`, requestOptions)
             .then(checkStatus)
    }

    deleteCostCenter(id){
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/cost-centers/${id}`, requestOptions)
            .then(checkStatus)
    }




}