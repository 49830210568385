import {Chip} from "@material-ui/core";
import {WarningOutlined} from "@mui/icons-material";
import React from "react";


function WarningChip(props){

    return <div style={{width: "50%", display: props.display? props.display : "flex", margin: "auto", marginBlock: "5%",}}>
        {/*<Typography style={{margin: "auto"}}><WarningOutlined color={"warning"}/>{t("no_events")}</Typography>*/}
        <Chip style={{margin: "auto", paddingInline: "3%"}}
              icon={<WarningOutlined color={"warning"}/>} label={props.children}/>
    </div>

} export default WarningChip
