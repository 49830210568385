import React, {useEffect, useState} from "react";
import {Button, MenuItem, Select, TablePagination, TextField, useTheme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Card, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AppStyles} from "../../theme/AppStyles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import InfoChip from "../../components/chips/InfoChip";
import PrimaEdicolaQueueRecord from "./PrimaEdicolaQueueRecord";
import PrimaEdicolaQueueService from "./PrimaEdicolaQueueService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))

function PrimaEdicolaQueueTable(props) {


    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [shipmentId, setShipmentId] = useState('');

    const [refresh, setRefresh] = useState(false);
    const [fetching, setFetching] = useState(false);

    const classes = AppStyles();

    const {t} = useTranslation();

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);
    }

    function clearFilters() {
        setShipmentId('');
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }

    function handleShipmentIdChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleChangePage(event) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    async function fetchRecords() {

        setFetching(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if(shipmentId){
            filters.shipmentId = shipmentId;
        }

        await new PrimaEdicolaQueueService().getBookingQueueRecords(filters)
            .then(result => {
                setRecords(result.records)
                setPages(Array.from(Array(Math.ceil(result.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                setFetching(false);
            })
    }

    function refreshCallback(){
        setRefresh(!refresh);
    }

    useEffect(()=>{

        fetchRecords();

    }, [refresh, page, rowsPerPage])

    return <Paper>

        <Card elevation={1} defaultExpanded={true}
              style={{width: "60%", margin: "auto", marginBottom: "2%"}}>
            <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                <Typography variant={"button"}>{t("filters")}</Typography>
            </DialogTitle>
            <div style={{alignItems: 'center', display: "flex"}}>
                <TextField
                    className={classes.filter_field}
                    label={t("shipment_id")}
                    value={shipmentId}
                    onChange={handleShipmentIdChange}
                    margin={"dense"}
                    variant={"outlined"}
                />
            </div>
            <div style={{textAlign: 'center'}}>
                <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
            </div>
        </Card>


        {records.length > 0 ?
            <div>

                {fetching ?
                    <LoadingComponent/>
                    :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("shipment_id")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"inherit"}>  {t("last_try")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("error_count")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("last_error_message")} </Typography>
                                </TableCell>
                                <TableCell style={{width: "10%"}}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.map((record, index) => {
                                return <PrimaEdicolaQueueRecord refreshCallback={refreshCallback} record={record}/>
                            })}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            // SelectProps={{
                            //     inputProps: {'aria-label': 'rows per page'},
                            //     variant: "outlined"
                            //
                            // }}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                variant: "outlined",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: {paper: classes.menuPaper}
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Table>
                }
            </div>
            :
            <InfoChip>{t("no_data")}</InfoChip>
        }

    </Paper>


}

export default PrimaEdicolaQueueTable;