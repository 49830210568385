import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TableRow} from "@mui/material";
import {TableCell, TextField} from "@material-ui/core";

export default function RateServiceWeightModelSlot(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.slot);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    function onChangeToWeight(event) {
        const newState = {...state, toWeight: event.target.value};
        setNewState(newState);
    }

    function onChangeFee(event) {
        const newState = {...state, fee: event.target.value};
        setNewState(newState);
    }

    return <div>
        <TableRow>
            <TableCell>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="toWeight"
                    label={t("to_weight")}
                    type="text"
                    value={state.toWeight}
                    onChange={onChangeToWeight}
                    autoComplete={t("to_weight")}
                />
            </TableCell>

            <TableCell>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="fee"
                    label={t("fee")}
                    type="text"
                    value={state.fee}
                    onChange={onChangeFee}
                    autoComplete={t("fee")}
                />
            </TableCell>
        </TableRow>
    </div>;
}