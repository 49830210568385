import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PostalCodeService from "./PostalCodeService";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


function PostalCode(props){

    let postalCode = props.postalCode;
    const [cities, setCities] = useState([]);
    const [hamlets, setHamlets] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = useTranslation();
    const history = useHistory();



    const useStyles = makeStyles({
        cell: {
            paddingLeft: "3%",
            width: "20%"

        }
    });

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    function deletePostalCode(){
        let postalCodeService = new PostalCodeService();
        postalCodeService.deletePostalCode(postalCode.id)
            .then((response) => {
                props.deleteCallback();
            })
            .catch(error => {

            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    function editPostalCode(){
        setAnchorEl(null);
        history.push(`/postal-codes/${postalCode.id}/edit/`);
    }


    function fetch(){
        let postalCodeService = new PostalCodeService();
        postalCodeService.getCitiesOfPostalCode(postalCode.id)
            .then(data => {
                setCities(data)
            })
        postalCodeService.getHamletsOfPostalCode(postalCode.id)
            .then(hamlets => {
                setHamlets(hamlets)
            })
    }





    useEffect(()=>{
        fetch();
    },[postalCode])


    return <TableRow>
        <TableCell className={classes.cell}>{postalCode.postalCode}</TableCell>

        <TableCell className={classes.cell}>

            {cities.map(city => {
                return <Typography display={"block"}>{city.name}</Typography>
        })}
            {hamlets.map(hamlet => {
                return <Typography>{hamlet.name}</Typography>
            })}

        </TableCell>

        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={() => editPostalCode()}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>*/}

            <MenuItem onClick={() => deletePostalCode()}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>


    </TableRow>

} export default PostalCode;