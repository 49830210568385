import {Card} from "@material-ui/core";
import {Autocomplete, CircularProgress, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AccountService from "../../accounts/AccountService";
import DateField from "./DateField";
import {SaveOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../theme/theme";
import ContractService from "../../accounts/contracts/ContractService";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ShipmentService from "../../shipments/ShipmentService";
import {useParams} from "react-router-dom";
import {LoadingComponent} from "../loading/LoadingComponent";
import moment from "moment";
import Notification from "../notifications/Notification";

const useStyles = makeStyles({
    saveIcon: {
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    }
});

function Extras(props) {

    let extras = props.extras;
    let type = props.type;
    const {t} = useTranslation();

    let ledgerAccountId = props.id;
    const {id} = useParams();
    const classes = useStyles();

    const [loadingEarn, setLoadingEarn] = useState(false);
    const [loadingCost, setLoadingCost] = useState(false);

    const [loadingData, setLoadingData] = useState(false);

    const [amountCost, setAmountCost] = useState('');
    const [supplierCost, setSupplierCost] = useState(null);
    const [contractCost, setContractCost] = useState(null);
    const [dateCost, setDateCost] = useState(new Date());
    const [descCost, setDescCost] = useState('');

    const [amountEarn, setAmountEarn] = useState('');
    const [customerEarn, setCustomerEarn] = useState(null);
    const [contractEarn, setContractEarn] = useState(null);
    const [dateEarn, setDateEarn] = useState(new Date());
    const [descEarn, setDescEarn] = useState('');

    const [contracts, setContracts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);

    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    async function fetchSuppliers() {
        let filters = {};
        filters.isSupplier = true;
        let suppl = await new AccountService().getAccounts(filters);
        setSuppliers(suppl);
    }

    async function fetchCustomers() {
        let filters = {};
        filters.isCustomer = true;
        let cust = await new AccountService().getAccounts(filters);
        setCustomers(cust);
    }

    async function fetchContracts(supplier) {
        let filters = {};
        filters.accountId = supplier.id

        let contr = await new ContractService().getContractsByFilters(filters);
        setContracts(contr);
    }

    async function handleData() {
        setLoadingData(true);

        let cost = extras.find(extra => extra.type === "COST");
        let earn = extras.find(extra => extra.type === "EARN");

        if (cost) {
            setAmountCost(cost.amount)
            setDateCost(cost.dateTime)
            setDescCost(cost.description)

            let supplier = await new AccountService().getById(cost.accountId)
                .catch(error => {

                })
            setSupplierCost(supplier)

            if(cost.contractId){
                let contract = await new ContractService().getContractById(cost.contractId)
                    .catch(error => {

                    })
                setContractCost(contract)
            }
        }

        if (earn) {
            setAmountEarn(earn.amount)
            setDateEarn(earn.dateTime)
            setDescEarn(earn.description)

            let supplier = await new AccountService().getById(earn.accountId)
                .catch(error => {

                })
            setCustomerEarn(supplier)

            if(earn.contractId){
                let contract = await new ContractService().getContractById(earn.contractId)
                    .catch(error => {

                    })
                setContractEarn(contract)
            }
        }

        setLoadingData(false);
    }

    async function saveExtraCost() {
        setLoadingCost(true);

        let extraCost = {};

        extraCost.ledgerAccountId = ledgerAccountId;
        extraCost.description = descCost;
        extraCost.amount = amountCost;
        extraCost.quoteType = "COST";
        extraCost.currencyCode = "EUR";
        extraCost.accountCorporateCode = supplierCost?.accountCorporateCode;
        extraCost.contractCode = contractCost?.code;
        extraCost.date = moment(dateCost).format('yyyy-MM-DD');

        await new ShipmentService().saveExtra(id, extraCost, type)
            .then(()=>{
                setTimeout(() => {
                    setLoadingCost(false)
                    props.refresh();
                }, 1000)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setLoadingCost(false);
                    setNotificationSeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotification(true);
                });
            })


    }

    async function saveExtraEarn() {
        setLoadingEarn(true);

        let extraEarn = {};

        extraEarn.ledgerAccountId = ledgerAccountId;
        extraEarn.description = descEarn;
        extraEarn.amount = amountEarn;
        extraEarn.quoteType = "EARN";
        extraEarn.currencyCode = "EUR";
        extraEarn.accountCorporateCode = customerEarn?.accountCorporateCode;
        extraEarn.contractCode = contractEarn?.code;
        extraEarn.date = moment(dateEarn).format('yyyy-MM-DD');

        await new ShipmentService().saveExtra(id, extraEarn, type)
            .then(()=>{
                setTimeout(() => {
                    setLoadingEarn(false)
                    props.refresh();
                }, 1000)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setLoadingEarn(false)
                    setNotificationSeverity('error');
                    setNotificationMessage(response.status + " - " + (response.message? response.message : response.path));
                    setOpenNotification(true);
                });
            })
    }

    useEffect(() => {
        handleData();
    }, [])


    return <div>
        {loadingData ?
            <LoadingComponent/>
            :
            <div>
                <Notification open={openNotification} severity={notificationSeverity} duration={3500}
                              onClose={()=>setOpenNotification(false)}>{notificationMessage}</Notification>
                <div style={{display: "flex", width: "100%"}}>
                    <Typography variant={"button"} style={{
                        marginLeft: "5%",
                        fontSize: 18,
                        fontWeight: "bold",
                        fontStyle: "italic",
                        marginBlock: "1%"
                    }}>{props.label}</Typography>
                </div>
                <div style={{display: "flex", width: "100%"}}>
                    <Card style={{width: "45%", margin: "auto", marginBottom: "3%"}}>
                        <div>
                            <div style={{margin: "auto", display: "flex"}}>
                                <DialogTitle>{t("earn")}</DialogTitle> <IconButton onClick={saveExtraEarn}
                                                                                   style={{backgroundColor: 'transparent'}}>{loadingEarn ?
                                <CircularProgress size={25}/> :
                                <SaveOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <Autocomplete
                                    id="supplier"
                                    size="small"
                                    style={{marginInline: "auto", width: "50%"}}
                                    options={customers}
                                    getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                    value={customerEarn}
                                    defaultValue={customerEarn}
                                    noOptionsText={t("no_options")}
                                    onChange={(event, customer) => {setCustomerEarn(customer); setContractEarn(null)}}
                                    onOpen={fetchCustomers}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("customer")}
                                            variant="outlined"
                                            margin="dense"
                                            value={customerEarn}
                                            defaultValue={customerEarn}
                                            required
                                            error={!customerEarn}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <Autocomplete
                                    id="contract"
                                    size="small"
                                    disabled={!customerEarn}
                                    style={{marginInline: "auto", width: "50%"}}
                                    options={contracts}
                                    onOpen={() => fetchContracts(customerEarn)}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={contractEarn}
                                    defaultValue={contractEarn}
                                    noOptionsText={t("no_options")}
                                    onChange={(event, contract) => setContractEarn(contract)}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("contract")}
                                            variant="outlined"
                                            margin="dense"
                                            value={contractEarn}
                                            defaultValue={contractEarn}
                                            required
                                            error={!contractEarn}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <DateField
                                    style={{margin: "auto", width: "25%"}}
                                    label={t("date")}
                                    value={dateEarn}
                                    onChange={event => setDateEarn(event)}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <CurrencyTextField
                                    style={{marginInline: "auto", width: "25%"}}
                                    label={t("amount")}
                                    variant="outlined"
                                    size={"small"}
                                    onChange={(event, value) => setAmountEarn(value)}
                                    value={amountEarn}
                                    currencySymbol="€"
                                    outputFormat="number"
                                    digitGroupSeparator={"."}
                                    decimalCharacter={","}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginBlock: "2%"}}>
                                <TextField
                                    style={{marginInline: "auto", width: "50%"}}
                                    label={t("description")}
                                    variant="outlined"
                                    size={"small"}
                                    margin="dense"
                                    value={descEarn}
                                    defaultValue={descEarn}
                                    onChange={event => setDescEarn(event.target.value)}
                                />
                            </div>

                        </div>
                    </Card>
                    <Card style={{width: "45%", margin: "auto", marginBottom: "3%"}}>
                        <div>
                            <div style={{margin: "auto", display: "flex"}}>
                                <DialogTitle>{t("cost")}</DialogTitle> <IconButton onClick={saveExtraCost}
                                                                                   style={{backgroundColor: 'transparent'}}>{loadingCost ?
                                <CircularProgress size={25}/> :
                                <SaveOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <Autocomplete
                                    id="supplier"
                                    size="small"
                                    style={{marginInline: "auto", width: "50%"}}
                                    options={suppliers}
                                    getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                                    value={supplierCost}
                                    defaultValue={supplierCost}
                                    noOptionsText={t("no_options")}
                                    onChange={(event, supplier) =>{ setSupplierCost(supplier); setContractCost(null)}}
                                    onOpen={fetchSuppliers}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("supplier")}
                                            variant="outlined"
                                            margin="dense"
                                            value={supplierCost}
                                            defaultValue={supplierCost}
                                            required
                                            error={!supplierCost}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <Autocomplete
                                    id="contract"
                                    size="small"
                                    disabled={!supplierCost}
                                    style={{marginInline: "auto", width: "50%"}}
                                    options={contracts}
                                    onOpen={() => fetchContracts(supplierCost)}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={contractCost}
                                    defaultValue={contractCost}
                                    noOptionsText={t("no_options")}
                                    onChange={(event, contract) => setContractCost(contract)}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("contract")}
                                            variant="outlined"
                                            margin="dense"
                                            value={contractCost}
                                            defaultValue={contractCost}
                                            required
                                            error={!contractCost}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                <DateField
                                    style={{marginInline: "auto", width: "25%"}}
                                    label={t("date")}
                                    value={dateCost}
                                    onChange={event => setDateCost(event)}
                                    format={"dd/MM/yyyy"}
                                    disableFuture={true}
                                />

                            </div>
                            <div style={{display: "flex", width: "100%", marginTop: "2%"}}>
                                {/*<TextField*/}
                                {/*    style={{marginInline: "2%", width: "30%"}}*/}
                                {/*    label={t("amount")}*/}
                                {/*    variant="outlined"*/}
                                {/*    size={"small"}*/}
                                {/*    margin="dense"*/}
                                {/*    value={amountCost}*/}
                                {/*    defaultValue={amountCost}*/}
                                {/*    onChange={event => setAmountCost(event.target.value)}*/}
                                {/*/>*/}
                                <CurrencyTextField
                                    style={{marginInline: "auto", width: "25%"}}
                                    label={t("amount")}
                                    variant="outlined"
                                    size={"small"}
                                    onChange={(event, value) => setAmountCost(value)}
                                    value={amountCost}
                                    currencySymbol="€"
                                    outputFormat="number"
                                    digitGroupSeparator={"."}
                                    decimalCharacter={","}
                                />
                            </div>
                            <div style={{display: "flex", width: "100%", marginBlock: "2%"}}>
                                <TextField
                                    style={{marginInline: "auto", width: "50%"}}
                                    label={t("description")}
                                    variant="outlined"
                                    size={"small"}
                                    margin="dense"
                                    value={descCost}
                                    defaultValue={descCost}
                                    onChange={event => setDescCost(event.target.value)}
                                />
                            </div>

                        </div>
                    </Card>
                </div>
            </div>
        }
    </div>


}

export default Extras;