import {Autocomplete, Box, DialogTitle, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Paper} from "@material-ui/core";
import DateField from "../components/fields/DateField";
import WarehouseService from "../warehouses/WarehouseService";
import DriverService from "../drivers/DriverService";
import {format} from "date-fns";
import DriverWorkListService from "./DriverWorkListService";
import ParcelService from "../shipments/services/ParcelService";
import ShipmentService from "../shipments/ShipmentService";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import PickupService from "../pickups/PickupService";
import CodService from "../shipments/services/CodService";
import CloseWorkListStepper from "./CloseWorkListStepper";
import {Switch, useHistory} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import WarehouseStorageLabelPage from "../warehouse_storages/WarehouseStorageLabelPage";

function CloseDriverWorkListPage(props) {
    const {t} = useTranslation();

    const history = useHistory();

    const [warehouse, setWarehouse] = useState(null);
    const [driver, setDriver] = useState(null);
    const [date, setDate] = useState(null);
    const [workList, setWorkList] = useState(null);
    const [workListData, setWorkListData] = useState([]);

    const [pickupsData, setPickupsData] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [workLists, setWorkLists] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [warehouseStorageIds, setWarehouseStorageIds] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    //const deliveryEventCodesThatOpensWarehouseStorages = {
    //    40: {openingReasonCodeId: 1},
    //    45: {openingReasonCodeId: 1},
    //    41: {openingReasonCodeId: 3},
    //    42: {openingReasonCodeId: 4},
    //    30: {openingReasonCodeId: 9},
    //    66: {openingReasonCodeId: 10},
    //    29: {openingReasonCodeId: 8},
    //    56: {openingReasonCodeId: 7},
    //    27: {openingReasonCodeId: 18},
    //    28: {openingReasonCodeId: 18},
    //};

    //const deliveryEventCodesWithMultipleAttempts = [27, 28, 46];

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
        if(wares?.warehouses?.length === 1){
            setWarehouse(wares.warehouses[0]);
        }
    }

    async function fetchDrivers() {
        let filters = {}
        filters.workListDate = format(date, "yyyy-MM-dd");
        filters.warehouseCode = warehouse.code;

        let drivers = await new DriverService().getDriversByFilters(filters);
        setDrivers(drivers.drivers);
    }

    async function fetchDriverWorkLists() {
        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (driver) {
            filters.driverId = driver.id;
        }
        filters.date = format(date, "yyyy-MM-dd");

        let worklists = await new DriverWorkListService().getDriverWorkListsForClosure(filters);
        setWorkLists(worklists.driverWorkLists);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        if (null == warehouse) {
            setWorkList(null);
            setDriver(null);
            setWorkListData([])
            setPickupsData([]);
            setDate(null)
        }
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
        if (null == driver) {
            setWorkList(null);
            setWorkListData([])
            setPickupsData([]);
        }
    }

    function handleDateChange(event) {
        setDate(event);
        setDriver(null)
        setWorkList(null)
        setWorkListData([])
        setPickupsData([]);

    }

    function handleWorkListChange(event, workList) {
        setWorkList(workList);

        if (workList == null) {
            setWorkListData([]);
            setPickupsData([]);
        } else {
            fetchWorkListData(workList.parcels, workList.pickupIds);
        }
    }

    async function fetchWorkListData(driverWorkListParcels, pickupIds) {
        setLoading(true);

        let pickups = [];
        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del ritiro: " + response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            let data = {};
            data.pickup = pickup;

            //data.time = new Date(now());
            //data.time.setHours(19, 0, 0, 0);

            pickups.push(data);
        }

        setPickupsData(pickups);

        let wrklst = [];
        for (let i = 0; i < driverWorkListParcels?.length; i++) {
            let parcelService = new ParcelService();
            let parcel = await parcelService.getParcelById(driverWorkListParcels[i].id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                });

            const parcelIsInSomeShipment = wrklst.some(data => {
                return data.shipment.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode === parcel.barcode);
                })
            });
            if (parcelIsInSomeShipment) {
                wrklst.forEach(data => {
                    data.shipment.parcels.forEach(async _parcel => {
                        if (_parcel.parcel.barcode === parcel.barcode) {
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                });
            } else {
                if (parcel) {
                    let shipmentId = parcel.shipmentId;

                    let shipmentService = new ShipmentService();
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + " - " + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        });

                    if(shipment){
                        let shipmentParcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + " - " + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })
                            });

                        let cod = null;
                        if (shipment?.codId) {
                            const codService = new CodService();
                            cod = await codService.getCodById(shipment?.codId)
                                .catch(error => {
                                    error.response.json().then(response => {
                                        setNotificationMessage("Si è verificato un errore nel recupero del contrassegno: " + response.status + " - " + response.message);
                                        setNotifySeverity('error');
                                        setOpenNotify(true);
                                    })
                                });
                        }
                        let data = {};
                        data.shipment = shipment;
                        data.shipment.cod = cod;

                        data.shipment.parcels = [];
                        data.driverWorkListParcels = driverWorkListParcels;
                        for (let i = 0; i < shipmentParcels?.length; i++) {
                            let parcelData = {};
                            parcelData.parcel = shipmentParcels[i];
                            if (shipmentParcels[i].barcode === parcel.barcode) {
                                parcelData.checked = true;
                                setRefresh(!refresh);
                            }

                            data.shipment.parcels.push(parcelData);
                        }

                        wrklst.push(data);
                    }
                }
            }

            setRefresh(!refresh);
        }

        setWorkListData(wrklst);
        setLoading(false);
    }

    const onCloseWorkListSave = async (results) => {
        //setRefresh(!refresh);
        //fetchWorkListData(workList.parcels, workList.pickupIds);
    }

    useEffect(() => {
        fetchWarehouses();
    }, [refresh]);

    function renderStepper() {
        const shipments = [];
        for (let i = 0; i < workListData.length; ++i) {
            const record = workListData[i];
            const shipment = record.shipment;

            for (let j = 0; j < shipment.parcels.length; ++j) {
                const parcel = shipment.parcels[j].parcel;
                const parcelDataInDriverWorkList = record.driverWorkListParcels.find(parcelData => parcel.id === parcelData.id);
                if (parcelDataInDriverWorkList) {
                    parcel.lastDriverWorkListEvent = parcelDataInDriverWorkList.lastEvent;
                    parcel.numOfDeliveryAttempts = parcelDataInDriverWorkList.numOfDeliveryAttempts;
                    parcel.numOfAllowedDeliveryAttempts = parcelDataInDriverWorkList.numOfAllowedDeliveryAttempts;
                }
            }

            shipments.push(shipment);
        }

        return <CloseWorkListStepper
            onSave={onCloseWorkListSave}
            driverWorkListId={workList.id}
            pickups={pickupsData.map(data => data.pickup)}
            shipments={shipments}
            warehouse={warehouse}
            driver={driver}
            date={date}
        />
    }

    return <Paper>
        <Switch>
            <PrivateRoute>
                <div>
                    <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("driver_work_lists")}</DialogTitle>
                    <Autocomplete
                        id="warehouse-select"
                        size="small"
                        style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                        options={warehouses}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        noOptionsText={t("no_options")}
                        onChange={handleWarehouseChange}
                        onOpen={fetchWarehouses}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("warehouse")}
                                variant="outlined"
                                margin="dense"
                                value={warehouse}
                                defaultValue={warehouse}
                            />
                        )}
                    />
                    <DateField
                        style={{width: "20%", margin: "auto"}}
                        label={t("date")}
                        value={date}
                        onChange={handleDateChange}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                    />
                    <Autocomplete
                        id="driver-select"
                        size="small"
                        style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                        options={drivers}
                        disabled={!warehouse || !date} F
                        getOptionLabel={option => option.name + " " + option.surname}
                        value={driver}
                        defaultValue={driver}
                        noOptionsText={t("no_options")}
                        onChange={handleDriverChange}
                        onOpen={fetchDrivers}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("driver")}
                                variant="outlined"
                                margin="dense"
                                value={driver}
                                defaultValue={driver}
                            />
                        )}
                    />
                    <Autocomplete
                        id="wrkList"
                        size="small"
                        style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                        options={workLists}
                        disabled={!driver}
                        getOptionLabel={option => (option.delayed ? option.id + "/D" : option.id) + " del " + new Date(option.dateTime).toLocaleDateString() + " ore " + new Date(option.dateTime).toLocaleTimeString()}
                        value={workList}
                        defaultValue={workList}
                        noOptionsText={t("no_options")}
                        onChange={handleWorkListChange}
                        onOpen={fetchDriverWorkLists}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("driver_work_list")}
                                variant="outlined"
                                margin="dense"
                                value={workList}
                                defaultValue={workList}
                            />
                        )}
                    />
                </div>
                <div>
                    {loading ?

                        <LoadingComponent/>

                        :

                        <>
                            {workListData.length > 0 || pickupsData.length > 0 ?
                                <Box sx={{width: "100%"}}>
                                    {renderStepper()}
                                </Box>
                                :
                                null
                            }
                        </>

                    }
                </div>
                <Notification open={openNotify} severity={notifySeverity} duration={4000}
                              onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            </PrivateRoute>
            <PrivateRoute exact path={`/warehouse-storages/:id/label`}>
                <WarehouseStorageLabelPage ids={warehouseStorageIds}/>
            </PrivateRoute>
        </Switch>


    </Paper>

}

export default CloseDriverWorkListPage;