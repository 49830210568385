import {useEffect} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Flag from 'react-world-flags';


function Country(props){

    const country = props.country;

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
           paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    useEffect(() => {

    },[])

    return <TableRow>
        <TableCell><Flag code={country.iso3166Alpha2} height="42" width="42"/></TableCell>
        <TableCell >{country.name}</TableCell>
        <TableCell className={classes.cell}>{countryToFlag(country.iso3166Alpha2)}</TableCell>
        <TableCell className={classes.cell}>{country.iso3166Alpha3}</TableCell>
        <TableCell className={classes.cell}>{country.iso3166Numeric}</TableCell>

    </TableRow>


}

export default Country;