import React, {useEffect, useState} from "react";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Button, DialogTitle, TableCell, Typography} from "@material-ui/core";
import {ArrowBackIosRounded, Cancel, Edit, Print, Save, Shortcut} from "@mui/icons-material";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Autocomplete, Grid, Paper, Table, TableRow, TextField} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import ShipmentService from "../shipments/ShipmentService";
import WarehouseStorageService from "./WarehouseStorageService";
import WarehouseService from "../warehouses/WarehouseService";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import itLocale from "date-fns/locale/it";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%"
    },
    paper: {
        width: 600,
        marginBottom: "2%",
        minHeight: "100%"
    },
    pod: {
        width: 900,
        marginBottom: "2%"
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        width: '100%',
        maxHeight: 840,
    },
    table: {
        display: "inline-block",
        width: '40%',
        margin: "auto"
    },
    button: {
        marginBlock: "3%"
    },
    field: {
        margin: "auto",
        marginBottom: "2%",
        width: "60%"
    },
    actionButton: {
        marginInline: "3%",
        marginBlock: "3%"
    }
}));


function WarehouseStorageDetails(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");

    const [shipment, setShipment] = useState({});
    const [warehouseStorage, setWarehouseStorage] = useState({});
    const [endUserAddress, setEndUserAddress] = useState({});
    const [provision, setProvision] = useState({});

    const [openDossierDate, setOpenDossierDate] = useState('');
    const [notes, setNotes] = useState('');
    const [reason, setReason] = useState({});
    const [warehouse, setWarehouse] = useState({});

    const [warehouses, setWarehouses] = useState([]);
    const [reasons, setReasons] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [editing, setEditing] = useState(false);


    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    const [loading, setLoading] = useState(true);

    const classes = useStyles();

    const {id} = useParams();


    function handleReasonChange(event, reason) {
        setReason(reason);
    }

    function goToLabelPage(){
        history.push(`/warehouse-storages/${id}/label`)
    }

    function fetchProvision() {

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getProvisionByWarehouseStorageId(warehouseStorage.id)
            .then(response => {
                setProvision(response);
            })
            .catch(error => {

            })
    }

    function fetchShipment(shipmentId) {
        let shipmentService = new ShipmentService();
        shipmentService.getShipmentById(shipmentId, null)
            .then(data => {
                setShipment(data);
                setEndUserAddress(data.endUserAddress);
            })
            .catch(error => {

            })
    }

    function fetchWarehouseStorage() {
        setLoading(true);

        let warehouseService = new WarehouseService();
        let warehouseStorageService = new WarehouseStorageService();


        warehouseStorageService.getWarehouseStorageById(id)
            .then(data => {

                warehouseStorageService.getProvisionByWarehouseStorageId(id)
                    .then(response => {
                        setProvision(response);
                    })
                    .catch(error => {

                    })

                warehouseService.getWarehousesByFilters(0, 1000)
                    .then(warehouses => {
                        setWarehouses(warehouses.warehouses);
                        setWarehouse(warehouses.warehouses.filter(value => value.code === data.warehouseCode)[0]);
                    })

                warehouseStorageService.getWarehouseStorageReasons()
                    .then(reasons => {
                        setReasons(reasons);
                        setReason(reasons.filter(value => value.description === data.reason)[0]);
                    })
                setWarehouseStorage(data);
                setOpenDossierDate(new Date(data.createdAt));
                setNotes(data.notes);
                fetchShipment(data.storedShipmentId);

                setTimeout(() => {
                    setLoading(false);
                }, 1500)
            })
            .catch(error => {

            })


    }

    function getPdfLabel() {

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getWarehouseStorageLabel(warehouseStorage.id)
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:application/pdf;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
                // window.open('data:' + data.contentType + ";base64," + data.content);
            })
            .catch(error => {

            })
    }

    function saveEdit() {

        let input = {};

        input.warehouseCode = warehouse.code;
        input.reasonId = reason.id;
        input.openDossierDate = openDossierDate;
        input.notes = notes;

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.updateWarehouseStorage(warehouseStorage.id, input)
            .then(response => {

            })
            .catch(error => {

            })
        setEditing(false);
        setRefresh(!refresh);

    }

    function cancelEdit() {

        setReason(reasons.filter(value => value.description === warehouseStorage.reason)[0]);
        setWarehouse(warehouses.filter(value => value.code === warehouseStorage.warehouseCode)[0]);
        setOpenDossierDate(new Date(warehouseStorage.createdAt));
        setNotes(warehouseStorage.notes);
        setRefresh(!refresh);
        setEditing(false);
    }

    function handleEditingChange() {
        setLoading(true);
        setEditing(!editing);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }


    function handleNotesChange(event) {
        setNotes(event.target.value);
    }

    function goToProvisionPage() {
        history.push(`/warehouse-storages/${warehouseStorage.id}/provision`)
    }


    useEffect(() => {
        fetchWarehouseStorage();
    }, [refresh])


    return <div className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

        {loading ?
            <LoadingComponent/>

            :

            <div>
                <Button className={classes.button} startIcon={<ArrowBackIosRounded/>}
                        onClick={() => history.push(`/warehouse-storages`)}>{t("go_back")}</Button>

                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Paper elevation={1} className={classes.paper}>

                                <DialogTitle style={{display: "inline"}}>{t("warehouse_storage_details")}
                                </DialogTitle>


                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("datetime_open_dossier")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {editing ?
                                                <LocalizationProvider dateFormats={"dd/MM/yyyy"}
                                                                      dateAdapter={AdapterDateFns}
                                                                      locale={localeMap[locale]}>
                                                    <DateTimePicker
                                                        renderInput={(props) => <TextField {...props} />}
                                                        label={t("open_dossier_date")}
                                                        value={openDossierDate}
                                                        onChange={(newValue) => {
                                                            setOpenDossierDate(newValue);
                                                        }}
                                                        disableFuture
                                                    />
                                                </LocalizationProvider>

                                                :

                                                <Typography>{new Date(warehouseStorage.createdAt).toLocaleDateString() + " " + new Date(warehouseStorage.createdAt).toLocaleTimeString()}</Typography>

                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("opened_by")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{warehouseStorage.user ? warehouseStorage.user : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("reason")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {editing ?

                                                <Autocomplete
                                                    id="reason"
                                                    size="small"
                                                    style={{
                                                        minWidth: "100%",
                                                        width: "fit-content",
                                                        marginRight: "auto"
                                                    }}
                                                    options={reasons}
                                                    getOptionLabel={option => option.description}
                                                    value={reason}
                                                    defaultValue={reason}
                                                    onChange={handleReasonChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("reason")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={reason}
                                                            defaultValue={reason}
                                                        />
                                                    )}
                                                />

                                                :
                                                <Typography>{warehouseStorage.reason ? warehouseStorage.reason : ""}</Typography>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("warehouse")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {editing ?
                                                <Autocomplete
                                                    id="warehouse"
                                                    size="small"
                                                    style={{
                                                        minWidth: "100%",
                                                        width: "fit-content",
                                                        marginRight: "auto"
                                                    }}
                                                    options={warehouses}
                                                    getOptionLabel={option => option.code + " - " + option.name}
                                                    value={warehouse}
                                                    defaultValue={warehouse}
                                                    onChange={handleWarehouseChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("warehouse")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={warehouse}
                                                            defaultValue={warehouse}
                                                        />
                                                    )}
                                                />
                                                :
                                                <Typography>{warehouseStorage.warehouseCode ? warehouseStorage.warehouseCode : ""}</Typography>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("notes")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {editing ?
                                                <TextField
                                                    style={{width: "100%"}}
                                                    label={t("notes")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    onChange={handleNotesChange}
                                                    value={notes}
                                                    multiline
                                                    maxRows={4}
                                                    // className={classes.field}
                                                />

                                                :
                                                <Typography>{warehouseStorage.notes ? warehouseStorage.notes : ""}</Typography>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <DialogTitle>{t("provisions")}</DialogTitle>
                                {provision.id ?
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{t("user")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{provision.user ? provision.user : t("customer")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{t("created_at")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{provision ? new Date(provision.createdAt).toLocaleDateString() + " - " + new Date(provision.createdAt).toLocaleTimeString() : null}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{t("release_mode")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{provision.releaseMode ? provision.releaseMode.description : null}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {provision.releaseMode?.id === 3 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>{t("new_delivery_address")}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{provision.deliveryAddress.companyName || null}</Typography>
                                                    <Typography>{provision.deliveryAddress.referencePerson || null}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.deliveryAddress.street || null) + " "}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.deliveryAddress.buildingNr || null)}</Typography>
                                                    <Typography/>
                                                    <Typography
                                                        display={"inline"}>{(provision.deliveryAddress.postalCode || null) + " "}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.deliveryAddress.city ? provision.deliveryAddress.city : null) + " " + (provision.deliveryAddress.administrative_level_3 ? "(" + provision.deliveryAddress.administrative_level_3 + ")" : "")}</Typography>
                                                    <div>
                                                        <Typography
                                                            display={"inline"}>{(provision.deliveryAddress.administrative_level_2 ? provision.deliveryAddress.administrative_level_2 + " " : null)}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(provision.deliveryAddress.administrative_level_1 ? provision.deliveryAddress.administrative_level_1 + " " : null)}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(provision.deliveryAddress.country || null)}</Typography>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            null
                                        }
                                        {provision.releaseMode?.id === 3 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Typography>{t("new_end_user_address")}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{provision.endUserAddress.companyName || null}</Typography>
                                                    <Typography>{provision.endUserAddress.referencePerson || null}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.endUserAddress.street || null) + " "}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.endUserAddress.buildingNr || null)}</Typography>
                                                    <Typography/>
                                                    <Typography
                                                        display={"inline"}>{(provision.endUserAddress.postalCode || null) + " "}</Typography>
                                                    <Typography
                                                        display={"inline"}>{(provision.endUserAddress.city ? provision.endUserAddress.city : null) + " " + (provision.endUserAddress.administrative_level_3 ? "(" + provision.endUserAddress.administrative_level_3 + ")" : "")}</Typography>
                                                    <div>
                                                        <Typography
                                                            display={"inline"}>{(provision.endUserAddress.administrative_level_2 ? provision.endUserAddress.administrative_level_2 + " " : null)}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(provision.endUserAddress.administrative_level_1 ? provision.endUserAddress.administrative_level_1 + " " : null)}</Typography>
                                                        <Typography
                                                            display={"inline"}>{(provision.endUserAddress.country || null)}</Typography>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            null
                                        }
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{t("notes")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{provision ? provision.notes : null}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </Table>

                                    :

                                    <Typography style={{paddingBlock: "10%"}}>{t("no_provision_yet")}</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paper}>
                                <DialogTitle>{t("shipment_details")}</DialogTitle>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("shipment_id")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment ? shipment.id : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("customer_code")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment ? shipment.customerCode : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("references")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment ? shipment.reference : ""}</Typography>
                                            <Typography>{shipment ? shipment.customerReference : ""}</Typography>
                                            <Typography>{shipment ? shipment.orderNumber : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("service")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{shipment ? shipment.service.code : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("end_user_address")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{endUserAddress.companyName || null}</Typography>
                                            <Typography>{endUserAddress.referencePerson || null}</Typography>
                                            <Typography
                                                display={"inline"}>{(endUserAddress.street || null) + " "}</Typography>
                                            <Typography
                                                display={"inline"}>{(endUserAddress.buildingNr || null)}</Typography>
                                            <Typography/>
                                            <Typography
                                                display={"inline"}>{(endUserAddress.postalCode || null) + " "}</Typography>
                                            <Typography
                                                display={"inline"}>{(endUserAddress.city ? endUserAddress.city : null) + " " + (endUserAddress.administrative_level_3 ? "(" + endUserAddress.administrative_level_3 + ")" : "")}</Typography>
                                            <div>
                                                <Typography
                                                    display={"inline"}>{(endUserAddress.administrative_level_2 ? endUserAddress.administrative_level_2 + " " : null)}</Typography>
                                                <Typography
                                                    display={"inline"}>{(endUserAddress.administrative_level_1 ? endUserAddress.administrative_level_1 + " " : null)}</Typography>
                                                <Typography
                                                    display={"inline"}>{(endUserAddress.country || null)}</Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{t("contacts")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {endUserAddress.phone || endUserAddress.mobile || endUserAddress.email ?
                                                null
                                                :
                                                <Typography>{t("no_contacts_available")}</Typography>
                                            }
                                            <Typography>{endUserAddress.phone ? "TEL:" + endUserAddress.phone : null}</Typography>
                                            <Typography>{endUserAddress.mobile ? "MOBILE:" + endUserAddress.mobile : null}</Typography>
                                            <Typography>{endUserAddress.email ? "EMAIL:" + endUserAddress.email : null}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                    <div>
                        {!editing ? <Button className={classes.actionButton} onClick={goToLabelPage} startIcon={
                            <Print/>}>{t("download_warehouse_storage_label")}</Button> : null}

                        {!editing ? <Button className={classes.actionButton} onClick={handleEditingChange}
                                            startIcon={<Edit/>}>{t("edit")}</Button> : null}
                        {editing ? <div style={{display: "block", width: "100%"}}>
                            <Button className={classes.actionButton} onClick={saveEdit}
                                    startIcon={<Save/>}>{t("save")}</Button>
                            <Button className={classes.actionButton} onClick={cancelEdit}
                                    startIcon={<Cancel/>}>{t("cancel")}</Button>

                        </div> : null}

                        {!editing ?
                            <Button disabled={provision.id} className={classes.actionButton} onClick={goToProvisionPage}
                                    startIcon={
                                        <Shortcut/>}>{t("insert_warehouse_storage_provision")}</Button> : null}
                    </div>
                </Grid>
            </div>
        }

    </div>

}

export default WarehouseStorageDetails;