import {Box} from "@material-ui/core";
import {Table, TableCell, TableHead, TableRow} from "@mui/material";
import {formatDateTime} from "../../utils/utils";
import {useTranslation} from "react-i18next";


function ShipmentLogsTab(props){

    const {t} = useTranslation();

    let logs = props.logs;

    return <Box
        width={"100%"}
        sx={{flexGrow: 0, display: "flex"}}>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {t("ID")}
                    </TableCell>
                    <TableCell>
                        {t("datetime")}
                    </TableCell>
                    <TableCell>
                        {t("user")}
                    </TableCell>
                    <TableCell>
                        {t("logs")}
                    </TableCell>
                </TableRow>
            </TableHead>
            {logs.map(log => {

                return <TableRow>
                    <TableCell>
                        {log.id}
                    </TableCell>
                    <TableCell>
                        {formatDateTime(log.createdAt)}
                    </TableCell>
                    <TableCell>
                        {log.username}
                    </TableCell>
                    <TableCell>
                        {log.log}
                    </TableCell>
                </TableRow>

            })}
        </Table>

    </Box>





} export default ShipmentLogsTab;