import React, {useEffect, useState} from "react";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Button, CardActions, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {DialogTitle} from "@mui/material";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";
import HandheldService from "./HandheldService";
import WarehouseService from "../warehouses/WarehouseService";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
        field: {
            display: "flex",
            margin: "auto",
            marginBottom: "2%",
            marginTop: "2%",
            width: '25%',
        },
        button_container: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            marginBlock: "4%",
            paddingBottom: "2%"
        },
        confirm_button: {
            marginInline: "4%",

            width: "25%",
            backgroundColor: "green",
            color: "white",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                background: 'rgba(24,147,0,0.84)',
                // color: "black",
                // border:  "2px solid green",

                "& $confirm_icon": {
                    // color: "green"
                }
            },
        },
        confirm_icon: {
            color: "white",
            transition: "all 0.3s",
        },
        cancel_button: {
            marginInline: "4%",

            width: "25%",
            background: 'rgba(186,186,186,0.84)',

            color: "#000000",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                backgroundColor: "#d5d5d5",
            },
        },
        button: {
            marginBottom: "2%"
        }
    }
));

export default function HandheldForm(props) {
    const {t} = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    const {id} = useParams();

    const [loading, setLoading] = useState(true);

    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [imei, setImei] = useState('');
    const [description, setDescription] = useState('');
    const [model, setModel] = useState('');
    const [operator, setOperator] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    async function fetchDataAndHandleEdit() {
        setLoading(true);

        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null);
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);

        if (!id) {

            if(wares?.length === 1){
                setSelectedWarehouse(wares[0]);
            }

            setLoading(false);
            return;
        }

        try {
            let handheldService = new HandheldService();
            const handheld = await handheldService.getById(id);

            setImei(handheld.imei);
            setDescription(handheld.description);
            setModel(handheld.model);
            setOperator(handheld.operator);

            if (handheld.warehouseId) {
                for (let i = 0; i < wares.length; ++i) {
                    if (handheld.warehouseId === wares[i].id) {
                        setSelectedWarehouse(wares[i]);
                        break;
                    }
                }
            }
        } catch (error) {
            error.response.json().then((response) => {
                setNotifySeverity('error');
                setNotificationMessage(response.message);
                setOpenNotify(true);
            });
        }

        setLoading(false);
    }

    function handleClose() {
        history.goBack();
    }

    function handleSubmit(event) {
        event.preventDefault();

        let handheld = {
            warehouseId: selectedWarehouse?.id,
            imei: imei,
            description: description,
            model: model,
            operator: operator
        }

        let handheldService = new HandheldService();

        if (id) {
            handheldService.updateHandheld(id, handheld)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/handhelds`);
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            handheldService.createHandheld(handheld)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/handhelds`);
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }


    }

    useEffect(() => {
        fetchDataAndHandleEdit();
    }, [])

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleWarehouseChange(event, warehouse) {
        setSelectedWarehouse(warehouse);
    }

    return <div>

        {loading ?

            <LoadingComponent/>

            :

            <div>
                <DialogTitle>{t("handheld")}</DialogTitle>

                <Autocomplete
                    id="warehouseId"
                    size="small"
                    style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                    value={selectedWarehouse}
                    defaultValue={selectedWarehouse}
                    onChange={handleWarehouseChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        required
                        error={!selectedWarehouse}
                        helperText={!selectedWarehouse ? t("required") : null}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={selectedWarehouse}
                        defaultValue={selectedWarehouse}
                    />}
                />

                <TextField
                    required
                    label={t("imei")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setImei(event.target.value)}
                    value={imei}
                    error={!imei}
                    className={classes.field}
                    helperText={!imei ? t("required") : null}
                />

                <TextField
                    label={t("description")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setDescription(event.target.value)}
                    value={description}
                    className={classes.field}
                />

                <TextField
                    label={t("model")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setModel(event.target.value)}
                    value={model}
                    className={classes.field}
                />

                <TextField
                    label={t("operator")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setOperator(event.target.value)}
                    value={operator}
                    className={classes.field}
                />

                <CardActions className={classes.button_container}>
                    <Button
                        className={classes.cancel_button}
                        endIcon={<CancelOutlined/>}
                        onClick={handleClose}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>
            </div>
        }

    </div>
};