import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

const userService  = new UserService();


export default class SlotService{

    getSlotsByFilters(filters){

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/slots`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getById(slotId){


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/slots/${slotId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    addSlot(fromTime, toTime, days){


        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                fromTime:fromTime,
                toTime:toTime,
                weekdays:days
            })
        };

        return fetch('/api/v1/slots', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    deleteSlot(slotId){


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/slots/${slotId}`, requestOptions)
            .then(checkStatus)
    }

    getBookingSlotsByAddress(dates, serviceCode, address, availability){


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                dates:dates,
                serviceCode:serviceCode,
                address:address
            })
        };

        let queryString = ``;


        if (null != availability) {
            queryString += `availability=${availability}`;
        }

        let pathVariable = `/api/v1/slots/address`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getBookingSlotsByShipment(dates, shipmentId, guestKey){


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                dates:dates,
                shipmentId:shipmentId
            })
        };

        let queryString = ``;


        if (null != guestKey) {
            queryString += `guestKey=${guestKey}`;
        }

        let pathVariable = `/api/v1/slots/shipment`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getBookingSlotsAvailability(districtId, datesAndSlots){


        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                datesAndSlots:datesAndSlots,
                districtId:districtId
            })
        };

        return fetch(`/api/v1/slots/booking/availability`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }



}
