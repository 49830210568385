import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

export default class PassiveTaxQueueService {
    getPassiveTaxQueueRecords(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {
            if (filters.accountId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `accountId=${filters.accountId}`;
            }
            if (filters.contractId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `contractId=${filters.contractId}`;
            }
            if (filters.fromDate) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `fromDate=${filters.fromDate}`;
            }
            if (filters.toDate) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `toDate=${filters.toDate}`;
            }
        }

        let pathVariable = `/api/v1/passive-tax-queue`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addToPassiveTaxQueue(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/passive-tax-queue`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    workSynchro(input){
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/passive-tax-queue/synchronously`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }
}