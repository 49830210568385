import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Container,
    DialogActions,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    TextField
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import RoleService from "./RoleService";
import {makeStyles} from "@material-ui/core/styles";
import Notification from "../components/notifications/Notification";
import UserService from "../users/UserService";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline',
        width: '100%',
        textAlign: 'center',
        justifyItems: 'center'
    },
    formControl: {
        margin: theme.spacing(3),
        // width: "100%",
        display: 'flex',
        // textAlign:'center',
        // alignItems: "center",
        // justifyItems: "flex-start"

    },

    checkbox: {
        margin: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
        justifyItems: 'flex-start',
        textAlign: 'justify',

    },
    container: {

        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    div: {
        width: "25%",
        display: "flex",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',


    }


}));

function RoleForm(props) {
    const [roleName, setRoleName] = useState('');
    const {id} = useParams();
    const role = props.role;
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [fetchPermissionsError, setFetchPermissionsError] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const [refreshPage, setRefreshPage] = useState(false);


    const [loggedUserPermissions, setLoggedUserPermissions] = useState([]);

    const {t} = useTranslation();

    let roleId = props.id;

    if (!roleId) {
        roleId = id;
    }


    const handleRoleNameChange = (event) => {
        setRoleName(event.target.value);
    }
    // function changePermission(permId){
    //
    //     if (permissions.map.equal(permId)) {
    //         permissions.splice(permissions.indexOf(permId), 1);
    //     } else {
    //         permissions.push(permId);
    //     }
    // }

    const handleClose = event => {
        history.push('/roles');
    }

    const handleSubmit = event => {
        event.preventDefault();

        let rolePermissions = [];
        for (let i = 0; i < loggedUserPermissions.length; ++i) {
            if (loggedUserPermissions[i].checked) {
                rolePermissions.push(parseInt(loggedUserPermissions[i].permission.id))
            }
        }
        let roleService = new RoleService();
        if(!roleId) {

            roleService.createRole(roleName, rolePermissions)
                .then((response) => {

                    setSubmitSuccess(true);
                    setTimeout(() => {

                        history.push({
                            pathname: '/roles',
                            state: {detail: ''}
                        });
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setSubmitError(response.message);
                    });
                })
        } else {
            roleService.editRole(roleId, roleName, rolePermissions)
                .then( () => {

                    setSubmitSuccess(true);
                    setTimeout(() => {

                        history.push({
                            pathname: '/roles',
                            state: { detail: '' }

                        });
                    }, 3000)
                })
                .catch(error => console.log(error))
                .catch(error => alert("EDIT ERROR"))
        }

    }


    const handleChange = (index, event) => {

        let newItems = loggedUserPermissions.slice();
        newItems[index].checked = !newItems[index].checked
        setLoggedUserPermissions(newItems);

    }

    const classes = useStyles();


    useEffect(() => {
        let userService = new UserService();
        return userService.getUserPermissions()
            .then(data => {
                let permissionsWithChecked = [];
                data.map((permission, index) => {
                    const checked = false;
                    permissionsWithChecked[index] = {
                        permission: permission,
                        checked: checked
                    };
                });
                setLoggedUserPermissions(permissionsWithChecked);

                if (roleId) {
                    let roleService = new RoleService();
                    roleService.getRoleById(roleId)
                        .then(data => {


                            setRoleName(data.name);

                        }).catch(error => {

                    })

                    roleService.getPermissionsOfRole(roleId)
                        .then(rolePermissions => {


                            let newArray = permissionsWithChecked.slice();
                            for (let i = 0; i < newArray.length; i++) {
                                for (let j = 0; j < rolePermissions.length; j++) {
                                    if (newArray[i].permission.id === rolePermissions[j].id) {
                                        newArray[i].checked = true;
                                    }
                                }

                            }
                            setLoggedUserPermissions(newArray);
                        })
                        .catch(error => {

                        })
                }
            }).catch((error) => {
                // error.response.json()
                //     .then((response) => {
                //         setFetchPermissionsError(response.message);
                //     })
                //     .catch(() => {
                //         setFetchPermissionsError("Si è verificato un errore nel recupero dei permessi");
                //     });
            });


    },[]);

    return (
        <Container className={classes.root}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{t("role_data")}</DialogTitle>
                <TextField

                    label={t("role_name")}
                    variant="outlined"

                    onChange={handleRoleNameChange}
                    value={roleName}
                    margin="dense"


                />


                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">{t("assign_permissions")}</FormLabel>
                    <FormGroup className={classes.checkbox}>

                        {loggedUserPermissions.map((loggedUserPermission, index) => {


                            return (
                                <Container className={classes.container}>
                                    <div className={classes.div}>
                                        <FormControlLabel key={index}
                                                          control={<Checkbox checked={loggedUserPermission.checked}
                                                                             color="primary"
                                                                             onChange={handleChange.bind(this, index)}
                                                                             name={loggedUserPermission.permission.id}/>}
                                                          label={loggedUserPermission.permission.name}
                                        />
                                    </div>
                                    <div className={classes.div}>
                                        <FormHelperText
                                            key={index}>{loggedUserPermission.permission.description}</FormHelperText>
                                    </div>
                                </Container>
                            )
                        })}
                    </FormGroup>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button type="submit" color="primary">
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </form>
            <Notification open={submitError} severity="error"
                          onClose={() => setSubmitError(null)}>{submitError}</Notification>
            <Notification open={submitSuccess} severity="success"
                          onClose={() => setSubmitSuccess(false)}>{"Operazione avvenuta con successo"}</Notification>

        </Container>
    )

}

export default RoleForm;