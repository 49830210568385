import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ShortLinkService from "./ShortLinkService";


function RedirectPage(props) {

    let {key} = useParams();
    const [url, setUrl] = useState(null);


    function fetchUrl() {

        let shortLinkService = new ShortLinkService();
        shortLinkService.getUrlByKey(key)
            .then(shortLink => {
                setUrl(shortLink.url);
                window.location.href = shortLink.url;
            })
            .catch(error => {
                window.location.href =  "https://typ.delivery"
            })
    }

     useState(() => {
        fetchUrl();
    })

    return <div style={{margin : "auto"}}>{"redirecting..."}</div>



}

export default RedirectPage;