import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    Typography
} from "@material-ui/core";
import {PrivateRoute} from "../../routes/PrivateRoute";
import TaxRateService from "./TaxRateService";
import TaxRate from "./TaxRate";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import TaxRateForm from "./TaxRateForm";

export function TaxRateTable(props) {

    let {t} = useTranslation();
    let history = useHistory();
    let location = useLocation();
    const [taxRates, setTaxRates] = useState([]);

    const [fetching, setFetching] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            // maxHeight: 1840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            width: "27%"

        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();

    function fetchTaxRates() {
        let taxRateService = new TaxRateService();
        taxRateService.getTaxRates()
            .then(data => {
                setTaxRates(data);
                setFetching(false);
            })
    }

    useEffect(() => {
        fetchTaxRates();
    }, [location])

    function goToNewTaxRateForm() {
        history.push('/tax-rates/new')
    }

    return <Paper className={classes.root}>
        {(fetching) ?
        <LoadingComponent/>
            :
            <Switch>
                <PrivateRoute exact path={"/tax-rates"}>


                    <Button startIcon={<AddCircleIcon/>} onClick={goToNewTaxRateForm} variant="contained" color="primary" className={classes.button}>
                        {t("create_new_tax_rate")}
                    </Button>


                    {(taxRates.length > 0) ?
                        <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableCell>ID</TableCell>
                                    <TableCell className={classes.cell}>{t("code")}</TableCell>
                                    <TableCell className={classes.cell}>{t("rate")}</TableCell>

                                </TableHead>
                                <TableBody>
                                    {taxRates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((taxRate, index) => {
                                        return <TaxRate taxRate={taxRate}/>
                                    })}
                                </TableBody>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={taxRates.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={
                                        ({from, to, count}) => {
                                            return '' + from + '-' + to + ' di ' + count
                                        }
                                    }
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Table>

                        </TableContainer>

                        : <div>

                            <Typography variant="overline" align="center">{t("empty_table_message")}</Typography>
                        </div>}
                </PrivateRoute>
                <PrivateRoute exact path={`/tax-rates/new/`}>
                    <TaxRateForm taxRate={null}/>
                </PrivateRoute>
            </Switch>

        }



    </Paper>


} export default TaxRateTable;