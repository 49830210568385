import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import {PrivateRoute} from "../../routes/PrivateRoute";
import {Switch, useHistory, useLocation} from "react-router-dom";
import CountryService from "./CountryService";
import Country from "./Country";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function CountriesTable(props) {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [countries, setCountries] = useState([]);
    const [pages, setPages] = useState([]);

    const [fetching, setFetching] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",
            minHeight: 480,

        },
        container: {
            width: '100%',


        },
        table: {
            width: '100%',
            margin: "auto"

        },
        cell: {
            width: "27%"

        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
        loading: {
            width: '100%',
            margin: "auto",
            marginBottom: "3%",
            display: "block",

        }
    });

    const classes = useStyles();

    function fetchCountries() {
        setFetching(true)
        let countryService = new CountryService();

        countryService.getCountriesByFilters(page, rowsPerPage, null, null)
            .then((data) => {

                setCountries(data.countries);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false);
    })
            .catch((fetchingError) => {

            })
    }

    function goToCountryForm() {
        history.push("/countries/new");
    }


    useEffect(() => {
        fetchCountries();

    }, [location, rowsPerPage, page])


    return <Paper className={classes.root} elevation={3}>

        {
            (fetching) ?

                <LoadingComponent/>

                :

                <Switch>
                    <PrivateRoute exact path={"/countries"}>

                        <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableCell></TableCell>
                                    <TableCell className={classes.cell}>{t("name")}</TableCell>
                                    <TableCell>ISO Alpha2</TableCell>
                                    <TableCell>ISO Alpha3</TableCell>
                                    <TableCell>ISO Numeric</TableCell>

                                </TableHead>
                                <TableBody>
                                    {countries.map((country, index) => {
                                        return <Country country={country}/>
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                count={countries.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={t("rows_per_page")}
                                labelDisplayedRows={
                                    ({from, to, count}) => {
                                        return '' + from + '-' + to + ' di ' + count
                                    }
                                }
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableContainer>
                    </PrivateRoute>
                </Switch>
        }

    </Paper>

}

export default CountriesTable;