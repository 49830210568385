import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";

const userService = new UserService();

export default class PaymentToCustomerService{


    getPaymentToCustomerById(id){


        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/payments-to-customers/${id}`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getPaymentsByFilters(filters){

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/payments-to-customers`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }


    newPaymentToCustomer(input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };
        let pathVariable = `/api/v1/payments-to-customers`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getPdf(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/payments-to-customers/${id}/pdf`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getCsv(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/payments-to-customers/${id}/csv`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    confirmPaymentToCustomer(id, input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                input
            )
        };
        let pathVariable = `/api/v1/payments-to-customers/${id}/confirm`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }













}