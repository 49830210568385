import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


const userService = new UserService();


export default class GoogleApiService {


    getDistanceMatrix(origins, destinations) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (null != origins) {
            queryString += `origin=${origins}`;
        }
        if (destinations) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `destination=${destinations}`;
        }

        // queryString += `&key=AIzaSyAgQzHFRIeDkxpSZ62qAMgIBR9EHrffw94`

        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/google/distance`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOption)
            .then(checkStatus)
            .then(response => response.json())

    }


}