import {CardActions, DialogContent, DialogTitle, Paper, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import CountryService from "../countries/CountryService";
import {Autocomplete} from "@material-ui/lab";
import AdministrativeLevel1Service from "../administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../administrative_level_3/AdministrativeLevel3Service";
import ChipInput from 'material-ui-chip-input'
import CityService from "./CityService";
import Notification from "../../components/notifications/Notification";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import Button from "@material-ui/core/Button";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "30%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        display: "block",
        margin: "auto"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    cancel_button: {
        marginInline: "4%",

        width: "15%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "15%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));


function CityForm(props) {

    const [fetched, setFetched] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [hamlets] = useState([]);
    const location = useLocation();
    const {t} = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    const [cityName, setCityName] = useState('');
    const [postalCodes, setPostalCodes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countrySelected, setCountrySelected] = useState(null);
    const [adminL1Selected, setAdminL1Selected] = useState(null);
    const [adminL2Selected, setAdminL2Selected] = useState(null);
    const [adminL3Selected, setAdminL3Selected] = useState(null);
    const [adminL1, setAdminL1] = useState([]);
    const [adminL2, setAdminL2] = useState([]);
    const [adminL3, setAdminL3] = useState([]);
    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const classes = useStyles();

    function handleClose() {
        history.push('/cities');
    }

    function handleSubmit(event) {
        event.preventDefault()
        let cityService = new CityService()
        if (id) {
            cityService.editCity(id, cityName, adminL1Selected.id, adminL2Selected.id, adminL3Selected.id, postalCodes, hamlets)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {

                        history.push({
                            pathname: '/cities',
                            state: {detail: ''}
                        });
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            cityService.addNewCity(cityName, adminL1Selected.id, adminL2Selected.id, adminL3Selected.id, postalCodes, hamlets)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {

                        history.push({
                            pathname: '/cities',
                            state: {detail: ''}
                        });
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }


    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleCountryChange(event, country) {

        setCountrySelected(country);
        setAdminL3Selected(null);
        setAdminL2Selected(null);
        setAdminL1Selected(null);


    }

    function handleAdminL1Change(event, adminL1) {

        setAdminL1Selected(adminL1);
        setAdminL3Selected(null)
        setAdminL2Selected(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2Selected(adminL2);
        setAdminL3Selected(null);

    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3Selected(adminL3);

    }


    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }


    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL1() {
        if (countrySelected) {
            let filters = {}
            let adminL1Service = new AdministrativeLevel1Service();

            filters.countryId = countrySelected.id;
            adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
                .then(data => {
                    setAdminL1(data.admins1);
                })
        }
    }

    function fetchAdminL2() {

        if (adminL1Selected) {
            let filters = {}
            filters.adminL1 = adminL1Selected.id;
            let adminL2Service = new AdministrativeLevel2Service();
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(data => {
                    setAdminL2(data.admins2);
                })
        }
    }

    function fetchAdminL3() {

        if (adminL2Selected) {
            let filters = {}
            filters.adminL2 = adminL2Selected.id;
            let adminL3Service = new AdministrativeLevel3Service();
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(data => {
                    setAdminL3(data.admins3);
                })
        }
    }

    function addPostalCode(postalCode) {
        postalCodes.push(postalCode)
    }

    function removePostalCode(postalCode, index) {
        postalCodes.splice(index, 1);
        setRefresh(!refresh)
    }

    function fetchCity(id) {
        setFetching(true);
        let cityService = new CityService();
        let adminL1Service = new AdministrativeLevel1Service();
        let adminL2Service = new AdministrativeLevel2Service();
        let adminL3Service = new AdministrativeLevel3Service();

        cityService.getCityById(id)
            .then(data => {
                setCityName(data.name)
                adminL1Service.getAdminLevel1ByID(data.level_1_id)
                    .then(data => {
                        setAdminL1Selected(data)

                    })
                adminL2Service.getAdminLevel2ByID(data.level_2_id)
                    .then(data => {
                        setAdminL2Selected(data)
                    })
                adminL3Service.getAdminLevel3ByID(data.level_3_id)
                    .then(data => {
                        setAdminL3Selected(data)
                    })
            })
        cityService.getCityPostalCodes(id)
            .then(data => {
                data.map(postalCode => {
                    addPostalCode(postalCode.postalCode);
                })
            })
        cityService.getCityCountry(id)
            .then(data => {
                handleCountryChange(null, data)
            })
        setFetched(true);
        setTimeout(() => {
            setFetching(false);
        }, 2000)


    }

    function handleNameChange(event) {
        setCityName(event.target.value);
    }


    useEffect(() => {
        fetchCountries();
        fetchAdminL1();
        fetchAdminL2();
        fetchAdminL3();
        if (id && !fetched) {
            fetchCity(id);

        }
    }, [refresh, location, countrySelected, adminL1Selected, adminL2Selected])


    return <Paper>
        <form>
            <DialogTitle>{t("city_data")}</DialogTitle>

            {(fetching) ?

                <LoadingComponent/>

                :

                <DialogContent>

                    <Autocomplete
                        id="country"
                        className={classes.field}
                        options={countries}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option.name}
                        value={countrySelected}
                        defaultValue={countrySelected}
                        onChange={handleCountryChange}
                        onBlur={fetchAdminL1}
                        renderOption={(option) => (
                            <React.Fragment>
                                <span>{countryToFlag(option.iso3166Alpha2)}</span>
                                {option.name} ({option.iso3166Alpha3}) +{option.iso3166Numeric}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("country")}
                                variant="outlined"
                                margin="dense"
                                value={countrySelected}
                                defaultValue={countrySelected}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="administrative1"
                        freeSolo
                        className={classes.field}
                        options={adminL1}
                        disabled={!countrySelected}
                        getOptionLabel={option => option.name}
                        value={adminL1Selected}
                        defaultValue={adminL1Selected}
                        onChange={handleAdminL1Change}
                        onBlur={fetchAdminL2}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("administrative1")}
                                variant="outlined"
                                margin="dense"
                                value={adminL1Selected}
                                defaultValue={adminL1Selected}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="administrative2"
                        freeSolo
                        className={classes.field}
                        options={adminL2}
                        disabled={!adminL1Selected}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.name === value.name}
                        value={adminL2Selected}
                        onChange={handleAdminL2Change}
                        onBlur={fetchAdminL3}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t("administrative2")}
                                variant="outlined"
                                margin="dense"
                                defaultValue={adminL2Selected}
                            />
                        )}
                    />
                    <Autocomplete
                        id="administrative3"
                        freeSolo
                        className={classes.field}
                        options={adminL3}
                        disabled={!adminL2Selected}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.name === value.name}
                        value={adminL3Selected}
                        onChange={handleAdminL3Change}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t("administrative3")}
                                variant="outlined"
                                margin="dense"
                                defaultValue={adminL3Selected}
                            />
                        )}
                    />

                    <TextField
                        label={t("city_name")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleNameChange}
                        value={cityName}
                        className={classes.field}/>
                    <ChipInput
                        className={classes.postal_field}
                        key={"chipsPostalCodes"}
                        label={t("postalCodes")}
                        value={postalCodes}
                        onAdd={(cPostalCode) => addPostalCode(cPostalCode)}
                        onDelete={((cPostalCode, index) => removePostalCode(cPostalCode, index))}
                        variant={"outlined"}
                        helperText={t("press_enter_to_add")}
                        blurBehavior="clear"
                        newChipKeys={KeyboardEvent.ENTER}
                    />
                    <CardActions className={classes.button_container}>
                        <Button
                            className={classes.cancel_button}
                            endIcon={<CancelOutlined/>}
                            onClick={handleClose}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className={classes.confirm_button}
                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                            onClick={handleSubmit}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </CardActions>

                </DialogContent>

            }
        </form>


        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>
}

export default CityForm;