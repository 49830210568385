import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


const userService = new UserService();


export default class CityService {

    getCityById(cityId) {
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };
        return fetch(`/api/v1/geo/cities/${cityId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getCities(pageNumber, pageSize, filters, guestKey) {
        let queryString = ``;
        const apiKey = userService.getApiKey();

        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if (filters) {
            if (filters.level1Id) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `level1Id=${filters.level1Id}`;
            }
            if (filters.level2Id) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `level2Id=${filters.level2Id}`;
            }
            if (filters.level3Id) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `level3Id=${filters.level3Id}`;
            }
            if (filters.countryId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `countryId=${filters.countryId}`;
            }
            if (filters.postalCodeId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `postalCodeId=${filters.postalCodeId}`;
            }
            if (filters.cityNameLike) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `name=${filters.cityNameLike}`;
            }
            if (filters.idList) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `ids=${filters.idList}`;
            }
            if (filters.name) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `exactName=${filters.name}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if(guestKey !== null){
            requestOptions.headers['key'] = guestKey;
        }



        let pathVariable = `/api/v1/geo/cities`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCityCountry(cityId) {
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/geo/cities/${cityId}/country`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCityHamlets(cityId) {
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/geo/cities/${cityId}/hamlets`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCityPostalCodes(cityId) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/geo/cities/${cityId}/postal-codes`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addNewCity(name, level1, level2, level3, postalCodes, hamlets) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                level1Id: level1,
                level2Id: level2,
                level3Id: level3,
                postalCodes: postalCodes,
                hamlets: hamlets
            })
        }
        return fetch(`/api/v1/geo/cities`, requestOptions)
            .then(checkStatus)
    }

    editCity(id, name, level1, level2, level3, postalCodes, hamlets) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                level1Id: level1,
                level2Id: level2,
                level3Id: level3,
                postalCodes: postalCodes,
                hamlets: hamlets
            })
        }
        return fetch(`/api/v1/geo/cities/${id}`, requestOptions)
            .then(checkStatus)
    }

    deleteCity(id) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/geo/cities/${id}`, requestOptions)
            .then(checkStatus)
    }

}