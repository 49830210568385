import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";


const userService = new UserService();


export default class PodService {


    getPod(id) {

        const apiKey = userService.getApiKey();
        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/pods/${id}`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }

    getPodPdf(id) {

        const apiKey = userService.getApiKey();
        const requestOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/pods/${id}/pdf`, requestOption)
            .then(checkStatus)
            .then(response => response.json())
    }

}