import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

export default class TimingService {
    getByFilters(pageNumber, pageSize, filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/timings`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getCSV(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/timings/csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getById(id) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/timings/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    addTiming(timing) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(timing)
        };

        return fetch('/api/v1/timings', requestOptions)
            .then(checkStatus)
    }

    importTimingsFromCsv(csv) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(csv)
        };

        return fetch('/api/v1/timings/import-from-csv', requestOptions)
            .then(checkStatus)
    }

    editTiming(id, timing) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(timing)
        };

        return fetch(`/api/v1/timings/${id}`, requestOptions)
            .then(checkStatus)
    }

    deleteTiming(id) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/timings/${id}`, requestOptions)
            .then(checkStatus)
    }
}