import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ParcelService from "../shipments/services/ParcelService";
import ShipmentService from "../shipments/ShipmentService";
import WarehouseService from "../warehouses/WarehouseService";
import {Card, Paper, TableCell, TableRow} from "@material-ui/core";
import {Autocomplete, DialogTitle, Table, TableHead, TextField, Typography} from "@mui/material";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import theme from "../theme/theme";
import InfoChip from "../components/chips/InfoChip";
import Notification from "../components/notifications/Notification";
import moment from "moment";


function WarehouseCheckPage(props) {

    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [parcelEvent, setParcelEvent] = useState(null);
    const [barcode, setBarcode] = useState('');
    const [greenBarcodes, setGreenBarcodes] = useState([]);
    const [unknownBarcodes, setUnknownBarcodes] = useState([]);

    const [shipmentData, setShipmentData] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [parcelQueue, setParcelQueue] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [fetched, setFetched] = useState(false);

    let events = [{
        id: 48,
        description: "In Transito"
    }, {
        id: 49,
        description: "In Gestione"
    }];

    function handleWarehouseChange(event, warehouse) {
        event.preventDefault();
        setWarehouse(warehouse);
        setFetched(false);
        setShipmentData([])
        setGreenBarcodes([])
    }

    function handleEventChange(event, parcelEvent) {
        setParcelEvent(parcelEvent);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value.trim());
    }

    function handleBarcodePaste(event) {
        const text = event.clipboardData.getData('Text')
        const _txt = text.split(' ').join('');
        setBarcode(_txt);

        event.preventDefault();
    }

    function handleKeyDown(event) {
        if (event.code === "Enter" && barcode !== '') {

            if (!warehouse || !parcelEvent) {
                setNotificationMessage(t("select_required_fields"));
                setNotifySeverity('error');
                setOpenNotify(true);
            } else {
                if (greenBarcodes.some(greenBarcode => greenBarcode.barcode.toUpperCase() === barcode.toUpperCase() || greenBarcode.customerBarcode.toUpperCase() === barcode.toUpperCase())) {
                    setNotificationMessage(t("Collo già selezionato"));
                    setNotifySeverity('warning');
                    setOpenNotify(true);
                    setBarcode('');
                } else {
                    let queue = parcelQueue;
                    queue.push(barcode)
                    setParcelQueue(queue);
                    setBarcode('');

                    if (parcelQueue.length === 1) {
                        addEventToParcel(parcelQueue.pop());
                    }
                }
            }
        }
    }

    async function addEventToParcel(barcode) {
        setLoading(true);
        let parcelService = new ParcelService();
        let parcel = null;
        parcel = await parcelService.getParcelByBarcode(barcode)
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);

                    let errbarcodes = unknownBarcodes;
                    if (!errbarcodes.some(brco => brco.toUpperCase() === barcode.toUpperCase())) {
                        errbarcodes.push(barcode);
                    }
                    setUnknownBarcodes(errbarcodes);

                    setFetched(true);
                })
            })

        if (shipmentData.some(data => {
            return data.parcels.some(parcel => {
                return (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase());
            })
        })) {
            shipmentData.map(data => {
                data.parcels.map(async _parcel => {
                        if (_parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || _parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase()) {

                            let request = {};

                            request.warehouseId = warehouse.id;
                            request.eventCodeId = parcelEvent.id;
                            request.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

                            if (parcel) {
                                await parcelService.addEventToParcel(parcel.id, request)
                                    .then(response => {
                                        _parcel.checked = true;
                                        let greens = greenBarcodes;
                                        let data = {};
                                        data.barcode = _parcel.parcel.barcode;
                                        data.customerBarcode = _parcel.parcel.customerBarcode;
                                        greens.push(data);
                                        setGreenBarcodes(greens);
                                        setRefresh(!refresh);
                                        setNotificationMessage(t("successful"));
                                        setNotifySeverity('success');
                                        setOpenNotify(true);
                                    })
                                    .catch(error => {
                                        error.response.json().then(response => {
                                            setNotificationMessage(response.status + "-" + response.message);
                                            setNotifySeverity('error');
                                            setOpenNotify(true);
                                        })
                                    })
                            }
                        }
                    }
                )
            })
        } else {
            let shipmentService = new ShipmentService();
            let shipmentId = parcel?.shipmentId;
            if (parcel) {
                let shipment = await shipmentService.getShipmentById(shipmentId, null)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let data = {};
                data.shipment = shipment;
                data.parcels = [];
                for (let i = 0; i < parcels?.length; i++) {
                    let parcelData = {};
                    parcelData.parcel = parcels[i];
                    if (parcels[i].barcode.toUpperCase() === barcode.toUpperCase() || parcels[i].customerBarcode.toUpperCase() === barcode.toUpperCase()) {
                        let request = {};

                        request.warehouseId = warehouse.id;
                        request.eventCodeId = parcelEvent.id;
                        request.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

                        if (parcel) {
                            await parcelService.addEventToParcel(parcel.id, request)
                                .then(response => {
                                    parcelData.checked = true;
                                    let greens = greenBarcodes;
                                    let data = {};
                                    data.barcode = parcels[i].barcode;
                                    data.customerBarcode = parcels[i].customerBarcode;
                                    greens.push(data);
                                    setGreenBarcodes(greens);
                                    setRefresh(!refresh);
                                    setNotificationMessage(t("successful"));
                                    setNotifySeverity('success');
                                    setOpenNotify(true);
                                })
                                .catch(error => {
                                    error.response.json().then(response => {
                                        setNotificationMessage(response.status + "-" + response.message);
                                        setNotifySeverity('error');
                                        setOpenNotify(true);
                                    })
                                })
                        }
                    } else {
                        parcelData.checked = false;
                    }

                    data.parcels.push(parcelData);
                }

                setShipmentData([...shipmentData, data]);

            }

        }

        setRefresh(!refresh);
        setTrigger(!trigger);
        setFetched(true);
        setLoading(false);


    }


    function fetchWarehouses() {
        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters(0, 1000, null)
            .then(response => {
                setWarehouses(response.warehouses);
                if(response.warehouses?.length === 1){
                    setWarehouse(response.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function calcSumOfParcels() {
        let parNum = 0;

        shipmentData.map(shipment => {
            shipment.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum++;
                }
            })
        })

        return parNum;

    }

    function calcSumOfShipments(){

        let sum = 0;

        shipmentData.map(shipment => {
            if(shipment.parcels.some(parcel => parcel.checked)){
                sum++;
            }
        });

        return sum;


    }


    useEffect(() => {
        fetchWarehouses();
    }, [refresh])

    useEffect(() => {
        if (parcelQueue.length > 0) {
            addEventToParcel(parcelQueue.pop());
        }
    }, [trigger])


    return <div>
        <Paper style={{marginBottom: "5%"}}>
            <div>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("warehouse_check")}</DialogTitle>
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={handleWarehouseChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!warehouse}
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <Autocomplete
                    id="event-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={events}
                    getOptionLabel={option => option.id + " - " + option.description}
                    value={parcelEvent}
                    defaultValue={parcelEvent}
                    noOptionsText={t("no_options")}
                    onChange={handleEventChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("event")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!parcelEvent}
                            value={parcelEvent}
                            defaultValue={parcelEvent}
                        />
                    )}
                />
                <div style={{width: "100%", marginTop: "2%", display: "flex"}}>
                    <Typography align={"center"} style={{width: "50%", margin: "auto"}}
                                variant={"caption"}>{t("warehouse_check_instructions")}</Typography>
                </div>
                <div style={{width: "100%", marginBottom: "3%", display: "flex"}}>
                    <TextField
                        style={{width: "20%", margin: "auto", marginBlock: "2%"}}
                        label={t("barcode")}
                        variant="outlined"
                        margin="dense"
                        value={barcode}
                        defaultValue={barcode}
                        onChange={handleBarcodeChange}
                        // onPaste={handleBarcodePaste}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
            {fetched ?
                <div>
                    {loading ?

                        <LoadingComponent/>

                        :
                        <div>

                            {shipmentData.length > 0 || unknownBarcodes.length > 0 ?
                                <div>
                                    <Table style={{width: "20%", margin: "auto"}}>
                                        <TableHead>
                                            <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                                                {unknownBarcodes.length > 0 ?
                                                    <TableCell
                                                        style={{border: "2px solid", width: "50%"}}><Typography
                                                        variant={"button"}
                                                        alignSelf={"center"}
                                                        fontWeight={"bold"}>{t("unknown_parcels")}</Typography></TableCell>
                                                    : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                    fontSize: 30
                                                }}>{calcSumOfShipments()}</Typography></TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"button"}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                        fontSize: 30
                                                    }}>
                                                    {calcSumOfParcels()}</Typography>
                                            </TableCell>
                                            {unknownBarcodes.length > 0 ?
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                        fontSize: 30
                                                    }}>{unknownBarcodes.length}</Typography></TableCell>
                                                : null
                                            }
                                        </TableRow>
                                    </Table>
                                    <div style={{paddingBottom: "5%"}}>
                                        <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                                            <TableHead>
                                                <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography variant={"button"} style={{
                                                            fontWeight: 'bold',
                                                            margin: "auto",
                                                        }}>{t("shipment_details")}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography variant={"button"} style={{
                                                            fontWeight: 'bold',
                                                            margin: "auto",
                                                        }}> {t("barcode")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {shipmentData.map(data => {
                                                return <TableRow>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("destination")} : {data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        {data.parcels.map(parcel => {
                                                            return <div
                                                                style={{display: "flex", alignItems: "center"}}>
                                                                <Typography
                                                                    color={parcel.checked ? "green" : "darkgrey"}>{parcel.parcel.barcode + (parcel.parcel.customerBarcode ? " - " + parcel.parcel.customerBarcode : null)}</Typography>
                                                            </div>
                                                        })}
                                                    </TableCell>

                                                </TableRow>
                                            })}
                                            {unknownBarcodes.length > 0 ?
                                                <TableRow>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("unknown_parcels")}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        {unknownBarcodes.map(barcode => {
                                                            return <div
                                                                style={{display: "flex", alignItems: "center"}}>
                                                                <Typography
                                                                    color={"darkgrey"}>{barcode}</Typography>
                                                            </div>
                                                        })}
                                                    </TableCell>

                                                </TableRow>
                                                :
                                                null
                                            }
                                        </Table>
                                    </div>
                                </div>
                                :
                                <InfoChip>{t("no_parcels")}</InfoChip>}

                        </div>
                    }
                </div>
                :
                null
            }
            <Notification open={openNotify} severity={notifySeverity} duration={4000}
                          onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        </Paper>
        <Card style={{marginTop: "5%", width: "30%", margin: "auto"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow style={{backgroundColor: theme.palette.primary.main, width: "100%"}}>
                        <div style={{width: "fit-content", margin: "auto"}}>
                            <Typography variant={"button"} align={"center"} style={{
                                fontWeight: "bold",
                                width: "fit-content",
                                margin: "auto",
                                color: "white"
                            }}>{t("parcel_queue")}</Typography>
                        </div>
                    </TableRow>
                </TableHead>
                {parcelQueue.map(item => {
                    return <TableRow style={{width: "100%"}}>
                        <Typography style={{width: "fit-content", margin: "auto"}}>{item}</Typography>
                    </TableRow>
                })}
            </Table>

        </Card>
    </div>


}

export default WarehouseCheckPage;