import React, {useEffect, useState} from "react";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
} from "@material-ui/core";
import RoleService from "./RoleService";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import RoleForm from "../roles/RoleForm";
import {PrivateRoute} from "../routes/PrivateRoute";
import {Switch, useHistory, useLocation} from "react-router-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Notification from "../components/notifications/Notification";
import Role from "./Role";
import {LoadingComponent} from "../components/loading/LoadingComponent";


function RolesTable(props) {
    const [roles, setRoles] = useState([]);
    const [fetchRolesError, setFetchRolesError] = useState(null);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [refreshTable, setRefreshTable] = useState(true);


    let history = useHistory();
    const location = useLocation();

    let useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",
        },
        container: {
            width: '100%',
            maxHeight: 840,
        },
        table: {
            width: '50%',
            margin: "auto"
        },
        iconCell: {
            justifyContent: "end",
            margin: "auto",
            textAlign: 'end',
            display: "block",
        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",
        },
        icon: {
            marginRight: "5%"
        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [deleteError, setDeleteError] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function deleteCallback() {

        setRefreshTable(!refreshTable);
        setDeleteSuccess(true);

    }

    const fetchRoles = () => {
        const rolesService = new RoleService();
        rolesService.getAllRoles()
            .then(data => {
                setRoles(data)
                setLoading(false);
            })
            .catch((err) => {
                // err.response.json()
                //     .then((response) => {
                //         setFetchRolesError(response.message);
                //     })
                //     .catch(() => {
                //         setFetchRolesError("Si è verificato un errore nel recupero dei ruoli");
                //     });
                //TODO error settings

            });
    }
    useEffect(() => {
        fetchRoles();
    }, [location]);

    const goToRolesForm = () => {
        history.push("/roles/new");
    }

    return <div>

        {loading ? <LoadingComponent/> :
            <Paper className={classes.root}>
                <Switch>
                    <PrivateRoute exact path={"/roles"}>
                        <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToRolesForm}
                                className={classes.button}>
                            {t("add_new_role")}
                        </Button>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="" className={classes.table}>
                                <TableHead>

                                    <TableCell>{t("name")}</TableCell>
                                    <TableCell/>
                                </TableHead>
                                <TableBody>
                                    {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role, index) => {

                                        return <Role deleteCallback={deleteCallback} role={role}/>

                                    })}
                                </TableBody>
                                <TablePagination
                                    rowsPerPageOptions={[1]}
                                    component="div"
                                    count={roles.length}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={
                                        ({from, to, count}) => {
                                            return '' + from + '-' + to + ' di ' + count
                                        }
                                    }
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Table>
                            <Notification open={deleteError} severity="error" autoHideDuration={3000}
                                          onClose={() => setDeleteError(null)}>{deleteError}</Notification>
                            <Notification open={deleteSuccess} severity="success" autoHideDuration={3000}
                                          onClose={() => setDeleteSuccess(false)}>{"Eliminazione avvenuta con successo"}</Notification>

                        </TableContainer>
                    </PrivateRoute>
                    <PrivateRoute strict path={`/roles/:id/edit/`} children={<RoleForm/>}>
                    </PrivateRoute>
                    <PrivateRoute exact path={"/roles/new/"}>
                        <RoleForm role={null}/>
                    </PrivateRoute>
                </Switch>

            </Paper>
        }
    </div>

}


export default RolesTable;