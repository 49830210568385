class AccountAddressValues {


    constructor() {
        this.address = null;
        this.type = null;
        this.default = null;
    }


} export default AccountAddressValues;