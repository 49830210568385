import {CircularProgress, TableCell, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckCircleOutline, ErrorOutline} from "@material-ui/icons";


function Status(props){

    const {t} = useTranslation();

    let data = props.data;
    let warehousesMap = props.warehousesMap;
    let codesMap = props.codesMap;

    return <>
        <TableCell>
            {new Date(data.event.dateTime).toLocaleDateString() + " - " + new Date(data.event.dateTime).toLocaleTimeString()}
        </TableCell>

        <TableCell>
            {data.event.parcelId}
        </TableCell>
        <TableCell>
            {warehousesMap.find(warehouse => warehouse.id === data.event.warehouseId)?.code || null}
        </TableCell>
        <TableCell>
            {data.event.notes}
        </TableCell>
        <TableCell>
            {codesMap.find(code => code.id === data.event.eventCodeId).id + " - " + codesMap.find(code => code.id === data.event.eventCodeId).name}
        </TableCell>
        <TableCell style={{textAlign: "center"}}>
          {props.data.pending === true? <CircularProgress color={"primary"} size={24}/> : null}
            {props.data.deleted === true?  <CheckCircleOutline style={{color: "green"}}/> : null}
            {props.data.deleted === false? <ErrorOutline style={{color: "red"}}/> : null}<Typography variant={"subtitle2"} style={{color: "red"}}>{props.data.errorMessage}</Typography>
        </TableCell>
    </>


} export default Status;