import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

const userService = new UserService();

export default class PaymentCardService {


    getCardById(id) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards/${id}`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCardsByFilters(filters) {

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/payment-cards`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    saveCard(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    updateCard(id, input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards/${id}`;

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deleteCard(id) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards/${id}`;

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }


    restoreCard(id) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards/${id}/restore`;

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    matchCardFromString(string) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/payment-cards/match`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(string)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


}