import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CostCenterService from "./CostCenterService";


function CostCenter(props){


    let costCenter = props.costCenter;

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();


    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            width: "15%",
            paddingLeft: "3%"

        },
        IDCell: {
            width: "5%",
            paddingLeft: "3%"

        },
        icon: {
            marginRight: "5%",
        },
        iconCell: {
            justifyContent: "end",
            margin: "auto",
            textAlign:'end',
            display: "block",
        },
    });

    const classes = useStyles();

    function handleMenuClose(){
        setAnchorEl(false);
    }
    function handleMenuClick(event){
        setAnchorEl(event.currentTarget);
    }

    function editCostCenter(costCenterID){
        setAnchorEl(null);
        history.push(`/cost-centers/${costCenterID}/edit/`);
    }

    function deleteCostCenter(id){
        let costCenterService = new CostCenterService();
        costCenterService.deleteCostCenter(id)
            .then(()=>{
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then((response) => {
                });
            })
            .finally( () => {
                setAnchorEl(null);
            })
    }

    useEffect(() => {

    },[])

    return <TableRow>
        <TableCell className={classes.IDCell}>{costCenter.id}</TableCell>
        <TableCell className={classes.cell}>{costCenter.name}</TableCell>
        <TableCell className={classes.cell}>{costCenter.code}</TableCell>
        <TableCell className={classes.iconCell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editCostCenter(costCenter.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

            <MenuItem onClick={() => deleteCostCenter(costCenter.id)}><DeleteIcon className={classes.icon}/>{t("delete")}</MenuItem>
        </Menu></TableCell>
    </TableRow>

}

export default CostCenter;

