import React, {useEffect, useState} from "react";
import {
    Container,
    Divider,
    Icon,
    InputBase,
    MuiThemeProvider,
    Paper,
    responsiveFontSizes,
    Typography
} from "@material-ui/core";
import logoTyp from "../images/TYP.jpg";
import IconButton from "@material-ui/core/IconButton";
import {LocalShipping, Search} from "@material-ui/icons";
import {Route, Switch, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import TrackingService from "./TrackingService";
import TrackingPage from "./TrackingPage";
import Notification from "../components/notifications/Notification";
import {createTheme} from "@mui/material";

let theme = createTheme({
    palette: {
        primary: {
            main: "#1B6031",
        },
        secondary: {
            main: "#00000f"
        }
    },
    typography: {
        fontFamily: [
            'Bebas Neue',
            'cursive',
        ].join(','),
    },
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "150%",
        minHeight: 1360,
        maxWidth: 1024,
        textAlign: "center",
        backgroundColor: "#f6f6f4"
    },
    img: {
        width: "100%",
        marginTop: "5%",
    },
    label: {
        width: "100%",
        // marginTop: "5%",
        paddingTop: "5%"
    },
    searchField: {
        marginTop: "5%",
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 50,
        margin: 6,
    },
    paper: {
        width: "100%",
        display: "inline",
        marginTop: "5%",
    },
    button: {
        width: "75%",
        backgroundColor: "lightgrey",
        marginBottom: "5%",
        marginTop: "5%"

    },
    cardWrap: {
        marginBottom: "2%",

    },
    card: {
        width: "75%",
        display: "inline",
    },
    itemImg: {
        width: "50%",
        marginTop: "5%",
    },
    typo: {
        marginTop: "3%",
        paddingBottom: "2%"
    },
    hidden: {
        display: "none"
    },
    stepperDiv: {
        display: "block",
        margin: "auto"
    },
    step: {
        marginBottom: "5%"
    },
    icon:{
        marginInline: "3%"
    }


}));

function TrackingPageIndex(props){


    const [ID, setID] = useState(null);
    const [trackedId, setTrackedId] = useState(null);
    const [tracking, setTracking] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [guestKey, setGuestKey] = useState(null);
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [companyName, setCompanyName] = useState("TYP")

    function goToNewTracking() {
        history.push(`/tracking/${ID}`)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function changeShipmentId(event) {
        setID(event.target.value);
    }

    function trackShipment(){
        setNotFound(false);
        let trackingService = new TrackingService();
        trackingService.getShipmentTracking(ID)
            .then(data => {
                setTracking(data);
                goToNewTracking()
            })
            .catch(error => {
                error.response.json().then(response => {
                    if(response.status === 404){
                        setNotFound(true);
                    } else {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    }
                })

            })
        setTrackedId(ID);
    }

    useEffect(()=>{

    })

    return (
        <Switch>
            <MuiThemeProvider theme={theme}>

                <Container className={classes.root}>
                    <Route exact path={"/tracking"}>
                        <div className={classes.label}>
                            <Typography variant="h4">{companyName}</Typography>
                        </div>
                        <img className={classes.img} src={logoTyp} alt={""}/>
                        <Paper className={classes.searchField}>
                            <Icon className={classes.icon}>
                                <LocalShipping/>
                            </Icon>
                            <InputBase
                                className={classes.input}
                                placeholder={t("insert_tracking_number")}
                                type="text"
                                value={ID}
                                onChange={changeShipmentId}
                            />
                            <Divider className={classes.divider} orientation="vertical" flexItem/>
                            <IconButton onClick={trackShipment} aria-label="search">
                                <Search/>
                            </IconButton>
                        </Paper>
                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{notificationMessage}</Notification>

                        {notFound?
                            <div>

                                <Typography className={classes.label} variant="body1">{t("tracking_not_found") + trackedId}</Typography>
                            </div>
                            :
                            null
                        }
                    </Route>
                    <Route path={`/tracking/:id/:guestKey?`}>
                        <TrackingPage trackingData={tracking}/>
                    </Route>
                </Container>
            </MuiThemeProvider>
        </Switch>
    )

} export default TrackingPageIndex;