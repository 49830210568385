import {Backdrop, Button, Fade, Menu, MenuItem, Paper, TableRow} from "@material-ui/core";
import {Chip, Modal, TableCell} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Circle, Download} from "@mui/icons-material";
import MenuIcon from "@material-ui/icons/Menu";
import {Delete, Lock} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import LoadingListsService from "./LoadingListsService";
import Notification from "../components/notifications/Notification";


function LoadingList(props) {

    const {t} = useTranslation();

    let loadingList = props.loadingList;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [openPdf, setOpenPdf] = useState(false);
    const [label, setLabel] = useState(null);


    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    async function closeLoadingList() {

        await new LoadingListsService().closeLoadingList(props.loadingList.id)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));

                setTimeout(()=>{
                    props.callback();
                }, 200)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }
     async function deleteLoadingList() {

        await new LoadingListsService().deleteLoadingList(props.loadingList.id)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));

                setTimeout(()=>{
                    props.callback();
                }, 200)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }



    async function fetchLabel(type){

        await new LoadingListsService().getLabel(loadingList.id, type)
            .then(response => {
                setLabel(response[0].content);
                setOpenPdf(true);
            })


    }

    useEffect(()=>{

    }, [openPdf])



    return <TableRow>
        <TableCell>
            {loadingList.id}
        </TableCell>
        <TableCell>
            {new Date(loadingList.createdAt).toLocaleDateString() + " - " + new Date(loadingList.createdAt).toLocaleTimeString()}
        </TableCell>
        <TableCell>
            {loadingList.closed ?
                <>
                    {new Date(loadingList.closedAt).toLocaleDateString() + " - " + new Date(loadingList.closedAt).toLocaleTimeString()}
                </>

                :

                <>
                    <Chip icon={<Circle style={{color: "green"}}/>} label={t("open")}/>
                </>
            }
        </TableCell>
        <TableCell>
            {loadingList.warehouseCode}
        </TableCell>
        <TableCell>
            {loadingList.destinationWarehouseCode}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem disabled={loadingList.closed} onClick={closeLoadingList}><Lock/>&nbsp;{t("close")}</MenuItem>
                <MenuItem onClick={deleteLoadingList}><Delete/>&nbsp;{t("delete")}</MenuItem>
                <MenuItem disabled={!loadingList.closed} onClick={() => fetchLabel("PDF")}><Download/>&nbsp;{t("download_label_pdf")}</MenuItem>
                {/*<MenuItem onClick={() => fetchLabel("ZPL")}><Download/>&nbsp;{t("download_label_zpl")}</MenuItem>*/}

            </Menu>
        </TableCell>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
         <Modal open={openPdf}
                      onClose={()=>setOpenPdf(false)}
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                          timeout: 500,
                      }}
                      disableEnforceFocus
        >
            <Fade in={openPdf}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "fit-content",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "fit-content",
                    minHeight: 600,
                    minWidth: 600,
                    display: "flex"
                }}>
                    <div style={{margin: "auto", marginTop: "15%"}}>
                        <object style={{margin: "auto"}} width={500} height={500} type={'application/pdf'} data={"data:application/pdf;base64," + label}>";
                            html += "</object>
                    </div>
                </Paper>
            </Fade>
        </Modal>

    </TableRow>


}

export default LoadingList;