import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


const userService = new UserService();


export default class LoadingListsService{


    getLoadingLists(filters) {

        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getLoadingListByBarcode(barcode) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists/barcode/${barcode}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getLoadingListParcels(id) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists/${id}/parcels`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getLabel(id, type) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists/${id}/label/${type}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }


    saveLoadingList(input) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                input
            )
        };

        let pathVariable = `/api/v1/loading_lists`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }


    closeLoadingList(id) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists/${id}/close`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            // .then(response => response.json())
    }


    deleteLoadingList(id) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/loading_lists/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            // .then(response => response.json())
    }






}