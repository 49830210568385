import fetch from 'unfetch';
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

export default class AccountService {

    getAccounts(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/accounts`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getById(accountId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/accounts/${accountId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    saveNewAccount(accountObj) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(accountObj)
        };

        return fetch(`/api/v1/accounts`, requestOptions)
            .then(checkStatus)
    }

    editAccount(accountObj, id){
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(accountObj)
        };

        return fetch(`/api/v1/accounts/${id}`, requestOptions)
            .then(checkStatus)
    }
}