import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DocumentService from "../documents/DocumentService";
import {CircularProgress, IconButton} from "@mui/material";
import {Download} from "@mui/icons-material";
import {Delete} from "@material-ui/icons";
import Notification from "../../components/notifications/Notification";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";


function SupplierPreInvoice(props) {

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [loadingCSV, setLoadingCSV] = useState(false);

    const {t} = useTranslation();

    const preInvoice = props.preInvoice;


    async function downloadCSV() {

        setLoadingCSV(true);

        await new DocumentService().getCSV(props.preInvoice.documentId)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCSV(false);
                })
            });
    }

    async function deleteDocument() {

        await new DocumentService().deleteDocument(props.preInvoice.documentId)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setConfirmDeleteDialogOpen(false);
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });

    }


    useEffect(() => {

    }, [])

    return <React.Fragment>
        <TableRow>
            <TableCell component="th" scope="row">
                {preInvoice.documentNumber}
            </TableCell>
            {/*<TableCell align="left">{preInvoice.contractCode} </TableCell>*/}
            <TableCell align="left">{preInvoice.contractName}</TableCell>
            <TableCell align="left">{new Date(preInvoice.date).toLocaleDateString()}</TableCell>
            <TableCell align="left">{preInvoice.totalAmount}</TableCell>
            <TableCell align="left">
                <IconButton style={{marginInline: "2%"}} onClick={downloadCSV}>
                    {loadingCSV?
                        <CircularProgress size={24}/>
                    :
                        <Download/>
                    }
                </IconButton>
                <IconButton style={{marginInline: "2%"}} onClick={()=>setConfirmDeleteDialogOpen(true)}><Delete/></IconButton>
            </TableCell>
        </TableRow>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteDocument}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>
    </React.Fragment>
}

export default SupplierPreInvoice;