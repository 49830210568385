import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TableRow} from "@mui/material";
import {TableCell, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

export default function RateServiceRouteModelVehicleType(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.vehicleType);
    const [vehicleTypes, setVehicleTypes] = useState(props.vehicleTypes);
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    useEffect(() => {
        if (state?.vehicleTypeId) {
            for (let i = 0; i < vehicleTypes.length; ++i) {
                if (state?.vehicleTypeId === vehicleTypes[i].id) {
                    setSelectedVehicleType(vehicleTypes[i]);
                }
            }
        }
    }, []);

    function onChangeRouteModelVehicleType(event, vehicleType) {
        setSelectedVehicleType(vehicleType);

        const newState = {...state, vehicleTypeId: vehicleType?.id};
        setNewState(newState);
    }

    function onChangeFee(event) {
        const newState = {...state, fee: event.target.value};
        setNewState(newState);
    }

    return <div>
        <TableRow>
            <TableCell>
                <Autocomplete
                    id="routeModelVehicleTypeId"
                    value={selectedVehicleType}
                    options={vehicleTypes}
                    autoHighlight
                    fullWidth
                    getOptionLabel={(option) => option.type}
                    onChange={onChangeRouteModelVehicleType}
                    renderOption={(option) => (
                        <React.Fragment>
                            {option.type}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("vehicle_type")}
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </TableCell>

            <TableCell>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="fee"
                    label={t("fee")}
                    type="text"
                    value={state.fee}
                    onChange={onChangeFee}
                    autoComplete={t("fee")}
                />
            </TableCell>
        </TableRow>
    </div>;
}