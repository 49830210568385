import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


const userService = new UserService();

export default class AdministrativeLevel3Service {


    getAdminLevel3ByID(id) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };
        return fetch(`/api/v1/geo/adminL3/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getAllAdminLevel3WithFilters(pageNumber, pageSize, filters, guestKey) {
        const apiKey = userService.getApiKey();

        let queryString = ``;
        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if (filters) {
            if (filters.idList) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `ids=${filters.idList}`;
            }
            if (filters.adminL2) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `adminL2Id=${filters.adminL2}`;
            }
            if (filters.countryId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `countryId=${filters.countryId}`;
            }
            if (filters.name) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `name=${filters.name}`;
            }
        }


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };


        if(guestKey !== null){
            requestOptions.headers['key'] = guestKey;
        }

        let pathVariable = `/api/v1/geo/adminL3`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


}