import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

const userService = new UserService();


function addToCache(cacheName, url, response) {

    const data = new Response(JSON.stringify(response));

    if (`caches` in window) {
        caches.open(cacheName)
            .then((cache) => {
                cache.put(url, data);
                alert('Data added into cache!')
            });
    }
}

export default class DistrictService {

    getDistrictsByFilters(pageNumber, pageSize, filters) {

        const apiKey = userService.getApiKey();
        let queryString = ``;
        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if (filters) {
            if (filters.name) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `name=${filters.idList}`;
            }
            if (filters.areaIds) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `areaIds=${filters.areaIds}`;
            }
            if (filters.slotIds) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `slotIds=${filters.slotIds}`;
            }

        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/districts`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getById(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/districts/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    addDistrict(code, maximumCapacityPerSlot, routingRules, slots) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                code: code,
                maximumCapacityPerSlot: maximumCapacityPerSlot,
                routingRules: routingRules,
                slots: slots
            })
        };

        return fetch('/api/v1/districts', requestOptions)
            .then(checkStatus)
    }

    editDistrict(id, code, maximumCapacityPerSlot, routingRules, slots) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                code: code,
                maximumCapacityPerSlot: maximumCapacityPerSlot,
                routingRules: routingRules,
                slots: slots
            })
        };

        return fetch(`/api/v1/districts/${id}`, requestOptions)
            .then(checkStatus)
    }

    deleteDistrict(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/districts/${id}`, requestOptions)
            .then(checkStatus)
    }

}