import {
    Autocomplete,
    Box,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableHead,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Chip, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import theme from "../theme/theme";
import MyTimeField from "../components/fields/MyTimeField";
import InfoChip from "../components/chips/InfoChip";
import WarehouseStorageService from "../warehouse_storages/WarehouseStorageService";
import WarehouseService from "../warehouses/WarehouseService";
import DriverService from "../drivers/DriverService";
import DateTimeField from "../components/fields/DateTimeField";
import CodService from "../shipments/services/CodService";
import ParcelService from "../shipments/services/ParcelService";
import moment from "moment";
import PickupService from "../pickups/PickupService";
import DateField from "../components/fields/DateField";
import {CheckRounded} from "@material-ui/icons";
import {MinimalLoadingComponent} from "../components/loading/MinimalLoadingComponent";
import CodEventCodeService from "../shipments/services/CodEventCodeService";
import ShipmentService from "../shipments/ShipmentService";

const steps = ['Esiti', 'Contrassegni', 'Giacenze'];

let shipmentEvents = [
    {id: 1, description: "Spedizione Consegnata"},
    {id: 28, description: "Assente"},
    // {id: 30, description: "Collo Danneggiato"},
    {id: 40, description: "Indirizzo Errato"},
    {id: 41, description: "Chiuso per ferie"},
    {id: 42, description: "Merce Respinta"},
    {id: 43, description: "Consegna non eseguita"},
    {id: 44, description: "Indisponibilità contrassegno"},
    {id: 45, description: "Indirizzo non trovato"},
    {id: 46, description: "Chiuso"},
    // {id: 54, description: "Collo smarrito"},
    {id: 94, description: "Autenticazione fallita"}
];

let shipmentEventsForPudo = [
    {id: 81, description: "Spedizione consegnata al pudo"},
    {id: 28, description: "Assente"},
    // {id: 30, description: "Collo Danneggiato"},
    {id: 40, description: "Indirizzo Errato"},
    {id: 41, description: "Chiuso per ferie"},
    {id: 42, description: "Merce Respinta"},
    {id: 43, description: "Consegna non eseguita"},
    {id: 44, description: "Indisponibilità contrassegno"},
    {id: 45, description: "Indirizzo non trovato"},
    {id: 46, description: "Chiuso"},
    // {id: 54, description: "Collo smarrito"},
    {id: 94, description: "Autenticazione fallita"}
]

let pickupEvents = [
    {id: 37, description: "Ritirato"},
    {id: 9, description: "Merce non pronta"},
    {id: 5, description: "Non ritirato per mancanza di tempo"},
    {id: 7, description: "Ritiro annullato"},
    {id: 8, description: "Nulla da ritirare"},
    {id: 16, description: "Assente"},
    {id: 12, description: "Indirizzo errato"},
    {id: 13, description: "Chiuso"},
    {id: 14, description: "Imballaggio non idoneo"}
];

const deliveryEventCodesThatOpensWarehouseStorages = {
    40: {openingReasonCodeId: 1},
    44: {openingReasonCodeId: 16},
    45: {openingReasonCodeId: 1},
    46: {openingReasonCodeId: 18},
    41: {openingReasonCodeId: 3},
    42: {openingReasonCodeId: 4},
    30: {openingReasonCodeId: 9},
    66: {openingReasonCodeId: 10},
    29: {openingReasonCodeId: 8},
    54: {openingReasonCodeId: 7},
    27: {openingReasonCodeId: 18},
    28: {openingReasonCodeId: 18},
};

const deliveryEventCodesWithMultipleAttempts = [27, 28, 46];

const koEventCodesForUrbaneThatOpensWarehouseStorage = {
    28: {openingReasonCodeId: 18},
    40: {openingReasonCodeId: 1},
    42: {openingReasonCodeId: 4},
    66: {openingReasonCodeId: 10},
    30: {openingReasonCodeId: 9}
}

const koEventCodesForUrbaneServiceChange = {
    75: {},
    43: {}
}

export default function CloseWorkListStepper(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [shipments, setShipments] = useState(props.shipments || []);
    const [pickups, setPickups] = useState(props.pickups || []);
    const [state, setState] = useState({time: new Date(), shipments: [], pickups: []});
    const [allWarehouseStoragesChecked, setAllWarehouseStoragesChecked] = useState(true);
    const [warehouseStorageReasons, setWarehouseStorageReasons] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [results, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [codEventCodes, setCodEventCodes] = useState([]);

    const {t} = useTranslation();

    useEffect(() => {
        initialize();
        fetchWarehouseStorageReasons();
        fetchWarehouses();
    }, []);


    useEffect(() => {

    }, [])

    async function fetchCodEventCodes(id) {

        await new CodEventCodeService().getCodEventCodesForCodMode(id)
            .then(result => {
                setCodEventCodes(result);
            })
            .catch(error => {

            })

    }

    function allDone() {

        setLoading(true);

        const newState = {...state};

        for (let i = 0; i < newState.pickups.length; i++) {
            if (!pickupEvents.some(event => event.id === newState.pickups[i].pickup.events[newState.pickups[i].pickup.events.length - 1].pickupEventCode.id)) {
                newState.pickups[i].status = pickupEvents[0];

                if (newState.pickups[i].pickup.urbaneEventRef && !!koEventCodesForUrbaneServiceChange[newState.pickups[i].pickup.urbaneEventRef]) {
                    newState.pickups[i].urbaneToStandard = {
                        checked: true
                    }
                }

                if (newState.pickups[i].pickup.urbaneEventRef && !!koEventCodesForUrbaneThatOpensWarehouseStorage[newState.pickups[i].pickup.urbaneEventRef]) {
                    newState.pickups[i].warehouseStorage = {
                        checked: true,
                        reasonId: koEventCodesForUrbaneThatOpensWarehouseStorage[newState.pickups[i].pickup.urbaneEventRef].openingReasonCodeId
                    }
                }

            }
        }

        for (let i = 0; i < newState.shipments.length; i++) {
            if (!shipmentEvents.some(event => event.id === newState.shipments[i].shipment.lastEvent.eventCode) && newState.shipments[i].shipment.lastEvent.eventCode !== 11) {
                if (newState.shipments[i].shipment.service.code === "CP") {
                    newState.shipments[i].status = shipmentEventsForPudo[0];
                } else {
                    newState.shipments[i].status = shipmentEvents[0];
                }
            }
        }

        setTimeout(() => {
            setLoading(false);
        }, 1);

    }

    async function mapUrbaneToStandardForPickup(pickup) {

        let urbaneToStandard = null;

        const needToChangeUrbaneToStandard = pickup.urbaneEventRef && !!koEventCodesForUrbaneServiceChange[pickup.urbaneEventRef] && pickup.events.some(event => event.pickupEventCode.id === 37);

        if (needToChangeUrbaneToStandard) {
            urbaneToStandard = {
                checked: true
            }
        }

        return urbaneToStandard;
    }


    async function mapWarehouseStorageForPickup(pickup) {

        let warehouseStorage = null;

        const needToOpenWarehouseStorage = pickup.urbaneEventRef && !!koEventCodesForUrbaneThatOpensWarehouseStorage[pickup.urbaneEventRef] && pickup.events.some(event => event.pickupEventCode.id === 37);

        if (needToOpenWarehouseStorage) {
            warehouseStorage = {
                checked: true,
                reasonId: koEventCodesForUrbaneThatOpensWarehouseStorage[pickup.urbaneEventRef].openingReasonCodeId
            }

            const warehouseStorageService = new WarehouseStorageService();
            const restWarehouseStorages = await warehouseStorageService.getFilteredWarehouseStorages({
                shipmentId: pickup.shipments[0], isCanceled: false
            });

            if (restWarehouseStorages && 0 < restWarehouseStorages.warehouseStorages.length) {
                warehouseStorage = restWarehouseStorages.warehouseStorages[0];
            }
        }

        return warehouseStorage;

    }

    async function mapWarehouseStorage(shipment) {
        let warehouseStorage = null;
        //const parcelThatCauseWarehouseStorage = shipment.parcels.find(parcelData => !!deliveryEventCodesThatOpensWarehouseStorages[parcelData.parcel.lastEvent?.eventCodeId]);
        const parcelThatCauseWarehouseStorage = shipment.parcels.find(parcelData => {
            // I'm allowed to open a warehouse storage only if the parcel's last event linked to this work list is the
            // same as the parcel's global last event
            if (parcelData.parcel.lastDriverWorkListEvent && parcelData.parcel.lastDriverWorkListEvent.id !== parcelData.parcel.lastEvent?.id) {
                return false;
            }

            return !!deliveryEventCodesThatOpensWarehouseStorages[parcelData.parcel.lastEvent?.eventCodeId];
        });

        if (parcelThatCauseWarehouseStorage) {
            let needToOpenWarehouseStorage = true;
            let warehouseStorageReasonId = deliveryEventCodesThatOpensWarehouseStorages[parcelThatCauseWarehouseStorage?.parcel.lastEvent?.eventCodeId].openingReasonCodeId;
            if (deliveryEventCodesWithMultipleAttempts.some(value => value === parcelThatCauseWarehouseStorage?.parcel.lastEvent?.eventCodeId)) {
                // got an event code with multiple attempts
                warehouseStorageReasonId = 2; // Done multiple attempts
                needToOpenWarehouseStorage = shipment.parcels.some(shipmentParcel => {
                    return shipmentParcel.parcel.numOfDeliveryAttempts >= shipmentParcel.parcel.numOfAllowedDeliveryAttempts;
                });
            }

            if (needToOpenWarehouseStorage) {
                warehouseStorage = {
                    checked: true,
                    reasonId: warehouseStorageReasonId
                };

                const warehouseStorageService = new WarehouseStorageService();
                const restWarehouseStorages = await warehouseStorageService.getFilteredWarehouseStorages({
                    shipmentId: shipment.id, isCanceled: false
                });

                if (restWarehouseStorages && 0 < restWarehouseStorages.warehouseStorages.length) {
                    warehouseStorage = restWarehouseStorages.warehouseStorages[0];
                }
            }
        }

        return warehouseStorage;
    }

    const initialize = async () => {
        const newState = {...state};

        const today = new Date();
        let time = new Date(props.date);
        if (today.toLocaleDateString() !== time.toLocaleDateString()) {
            time.setHours(19, 0, 0, 0);
        } else {
            time = new Date(today);
        }

        newState.shipments = await Promise.all(shipments.map(async (shipment) => {
            return {
                shipment: shipment,
                time: new Date(time),
                warehouseStorage: await mapWarehouseStorage(shipment),
                warehouse: props.warehouse,
                driver: props.driver
            };
        }));

        newState.pickups = await Promise.all(pickups.map(async (pickup) => {
            return {
                pickup: pickup,
                time: new Date(time),
                warehouseStorage: await mapWarehouseStorageForPickup(pickup),
                urbaneToStandard: await mapUrbaneToStandardForPickup(pickup),
                warehouse: props.warehouse,
                driver: props.driver
            };
        }));

        setState(newState);
    }

    async function fetchWarehouses() {
        if (props.driverWorkListId) {
            // If I have a driver work list, I have no need to choose a warehouse or a driver
            return;
        }

        const warehouseService = new WarehouseService();
        const warehouses = await warehouseService.getWarehousesByFilters();
        setWarehouses(warehouses.warehouses);
    }

    const isStepOptional = (step) => {
        return false;
        //return step === 1;
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        if (activeStep === steps.length - 1) {
            save(state);
        }
    };

    async function fetchWarehouseStorageReasons() {
        let warehouseStorageReasons = await new WarehouseStorageService().getWarehouseStorageReasons();
        setWarehouseStorageReasons(warehouseStorageReasons);
    }

    function renderPickupEvent(pickup, index) {
        const pickupState = state.pickups[index];

        return <TableRow style={{backgroundColor: checkPickedUp(pickup)}}>
            <TableCell style={{border: "2px solid"}}>
                <Typography
                    variant={"subtitle2"}>{t("pickupId")} : {pickup?.id}</Typography>
                <Typography
                    variant={"subtitle2"}>{t("warehouseCode")} : {pickup?.warehouseCode}</Typography>
                <Typography
                    variant={"subtitle2"}>{t("contract_code")} : {pickup?.contractCode}</Typography>
                <Typography
                    variant={"subtitle2"}>{t("pickup_date")} : {new Date(pickup?.date).toLocaleDateString()}</Typography>
            </TableCell>
            <TableCell style={{border: "2px solid"}}>
                <Typography
                    variant={"subtitle2"}>{pickup?.address?.companyName} {pickup?.address?.referencePerson}</Typography>
                <Typography
                    variant={"subtitle2"}>{pickup?.address?.street} {pickup?.address?.buildingNr}</Typography>
                <Typography
                    variant={"subtitle2"}>{pickup?.address?.postalCode} {pickup?.address?.city} {pickup?.address?.administrative_level_3}</Typography>
            </TableCell>
            <TableCell style={{border: "2px solid"}}>
            </TableCell>
            <TableCell style={{border: "2px solid"}}>
            </TableCell>
            <TableCell style={{border: "2px solid"}}>
                {pickup?.events[pickup?.events.length - 1].pickupEventCode.id !== 10 ?
                    <div style={{width: "100%"}}>
                        <Typography variant={"button"} style={{
                            width: "100%",
                            margin: "auto"
                        }}>{pickup?.events[pickup?.events.length - 1].pickupEventCode.name}</Typography>
                        <Typography variant={"subtitle2"} style={{
                            width: "100%",
                            margin: "auto"
                        }}>{new Date(pickup?.events[pickup?.events.length - 1].dateTime).toLocaleDateString()}</Typography>
                        <Typography variant={"subtitle2"} style={{
                            width: "100%",
                            margin: "auto"
                        }}>{new Date(pickup?.events[pickup?.events.length - 1].dateTime).toLocaleTimeString()}</Typography>

                    </div>

                    :
                    <div>
                        {loading ?
                            <MinimalLoadingComponent loadingItemSize={24}/>
                            :
                            <div>

                                <Autocomplete
                                    id="status"
                                    size="small"
                                    style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                    options={pickupEvents}
                                    disabled={pickup?.events[pickup?.events.length - 1].pickupEventCode.id === 37}
                                    getOptionLabel={option => option.id + " - " + option.description}
                                    value={pickupState?.status}
                                    defaultValue={pickupState?.status}
                                    noOptionsText={t("no_options")}
                                    onChange={onPickupStatusChange(pickupState, index)}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("status")}
                                            variant="outlined"
                                            margin="dense"
                                            value={pickupState?.status}
                                            defaultValue={pickupState?.status}
                                        />
                                    )}
                                />
                                <div style={{width: "30%", margin: "auto"}}>
                                    <MyTimeField
                                        disabled={pickup?.events[pickup?.events.length - 1].pickupEventCode.id === 37}
                                        value={pickupState?.time}
                                        onChange={onPickupTimeChange(pickupState, index)}
                                        defaultValue={pickupState?.time}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                }
            </TableCell>

        </TableRow>
    }

    function renderDriverWorkDataStatus(shipment, index) {
        const shipmentState = state.shipments[index];

        let parcelObjWithEventNotInThisDriverWorkList = null;

        if (!!props.driverWorkListId) {
            parcelObjWithEventNotInThisDriverWorkList = shipment.parcels
                .filter(parcel => parcel.checked) // filter only on parcels that are in this work list
                .find(parcel => {
                    let lastEvent = parcel.parcel.lastEvent;
                    if (parcel.parcel.lastDriverWorkListEvent) {
                        lastEvent = parcel.parcel.lastDriverWorkListEvent;
                    }

                    return lastEvent?.eventCodeId !== 52 || lastEvent?.driverWorkListId !== props.driverWorkListId;
                });
        }

        //const parcelObjWithEventNotInThisDriverWorkList = !!props.driverWorkListId &&
        //    shipment.parcels
        //        .filter(parcel => parcel.checked) // filter only on parcels that are in this work list
        //        .find(parcel => {
        //            let lastEvent = parcel.parcel.lastEvent;
        //            if (parcel.parcel.lastDriverWorkListEvent) {
        //                lastEvent = parcel.parcel.lastDriverWorkListEvent;
        //            }
//
        //            return lastEvent?.eventCodeId !== 52 || lastEvent?.driverWorkListId !== props.driverWorkListId;
        //        });

        const lastEvent = parcelObjWithEventNotInThisDriverWorkList?.parcel.lastDriverWorkListEvent ?
            parcelObjWithEventNotInThisDriverWorkList?.parcel.lastDriverWorkListEvent :
            parcelObjWithEventNotInThisDriverWorkList?.parcel.lastEvent;

        return <>
            {parcelObjWithEventNotInThisDriverWorkList ?
                <div style={{width: "100%"}}>
                    <Typography variant={"button"} style={{
                        width: "100%",
                        margin: "auto"
                    }}>{lastEvent?.eventCodeName}</Typography>
                    <Typography variant={"subtitle2"} style={{
                        width: "100%",
                        margin: "auto"
                    }}>{new Date(lastEvent?.dateTime).toLocaleDateString()}</Typography>
                    <Typography variant={"subtitle2"} style={{
                        width: "100%",
                        margin: "auto"
                    }}>{new Date(lastEvent?.dateTime).toLocaleTimeString()}</Typography>
                </div>

                :
                <div>
                    {loading ?
                        <MinimalLoadingComponent loadingItemSize={24}/>
                        :
                        <div>
                            <Autocomplete
                                id="status"
                                size="small"
                                style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                options={shipment.service.code === "CP" ||
                                shipment.service.code === "U"
                                    ? shipmentEventsForPudo : shipmentEvents}
                                disabled={!!parcelObjWithEventNotInThisDriverWorkList}
                                getOptionLabel={option => option.id + " - " + option.description}
                                value={shipmentState?.status}
                                defaultValue={shipmentState?.status}
                                noOptionsText={t("no_options")}
                                onChange={onParcelStatusChange(shipmentState, index)}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("status")}
                                        variant="outlined"
                                        margin="dense"
                                        value={shipmentState?.status}
                                        defaultValue={shipmentState?.status}
                                    />
                                )}
                            />
                            {0 < warehouses.length ?
                                <Autocomplete
                                    id="status"
                                    size="small"
                                    style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                    options={warehouses}
                                    disabled={!!parcelObjWithEventNotInThisDriverWorkList}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={shipmentState?.warehouse}
                                    defaultValue={shipmentState?.warehouse}
                                    noOptionsText={t("no_options")}
                                    onChange={onParcelWarehouseChange(shipmentState, index)}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("warehouse")}
                                            variant="outlined"
                                            margin="dense"
                                            value={shipmentState?.warehouse}
                                            defaultValue={shipmentState?.warehouse}
                                        />
                                    )}
                                /> : null}
                            {0 < drivers.length ?
                                <Autocomplete
                                    id="status"
                                    size="small"
                                    style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                    options={drivers}
                                    disabled={!!parcelObjWithEventNotInThisDriverWorkList}
                                    getOptionLabel={option => option.id + " - " + option.name}
                                    value={shipmentState?.driver}
                                    defaultValue={shipmentState?.driver}
                                    noOptionsText={t("no_options")}
                                    onChange={onParcelDriverChange(shipmentState, index)}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("driver")}
                                            variant="outlined"
                                            margin="dense"
                                            value={shipmentState?.driver}
                                            defaultValue={shipmentState?.driver}
                                        />
                                    )}
                                /> : null}
                            {props.driverWorkListId ?
                                <div style={{width: "30%", margin: "auto"}}>
                                    <MyTimeField
                                        disabled={!!parcelObjWithEventNotInThisDriverWorkList}
                                        value={shipmentState?.time}
                                        onChange={onShipmentTimeChange(shipmentState, index)}
                                        defaultValue={shipmentState?.time}
                                        error={!shipmentState?.time}
                                    />
                                </div>
                                :
                                <div style={{width: "50%", margin: "auto"}}>
                                    <DateTimeField
                                        disabled={!!parcelObjWithEventNotInThisDriverWorkList}
                                        value={shipmentState?.time}
                                        onChange={onShipmentTimeChange(shipmentState, index)}
                                        defaultValue={shipmentState?.time}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>

            }
        </>;
    }

    function checkAllCodsWithStatus() {


        let shipments = state.shipments.filter(data => data.shipment.cod && (data.shipment.lastEvent.eventCode === 1 || data.status?.id === 1));

        console.log(shipments);

        for (let i = 0; i < shipments.length; i++) {
            if (shipments[i].codMode == null && shipments[i].shipment.cod.collectionDateTime == null) {
                return false;
            }
        }

        return true;
    }

    function returnCodsCashTotal(shipmentsWithCod) {

        let total = 0;

        for (let i = 0; i < shipmentsWithCod.length; i++) {

            let collectedWithCash = shipmentsWithCod[i].shipment.cod?.events.filter(value => value.eventCodeId === 2);


            if (shipmentsWithCod[i].codMode?.id === 2 || collectedWithCash?.length > 0) {
                total += shipmentsWithCod[i].shipment.cod?.value;
            }
        }

        return total;
    }

    function returnCodsChequeTotal(shipmentsWithCod) {

        let totalValue = 0;
        let totalNumber = 0;

        for (let i = 0; i < shipmentsWithCod.length; i++) {

            let collectedWithCheque = shipmentsWithCod[i].shipment.cod?.events.filter(value => value.eventCodeId === 3 || value.eventCodeId === 4 || value.eventCodeId === 5 || value.eventCodeId === 6);

            if (shipmentsWithCod[i].codMode?.id === 3 || shipmentsWithCod[i].codMode?.id === 4 || shipmentsWithCod[i].codMode?.id === 5 || shipmentsWithCod[i].codMode?.id === 6 || collectedWithCheque?.length > 0) {
                totalValue += shipmentsWithCod[i].shipment.cod?.value;
                totalNumber++;
            }
        }

        let output = {};
        output.totalValue = totalValue;
        output.totalNumber = totalNumber;

        return output;
    }


    function onCodStatusChange(data, index) {
        return (event, codMode) => {
            const newState = {...state};
            newState.shipments[index].codMode = codMode;
            setState(newState);
        };
    }

    function onVerifiedStatusChange(data, index) {
        return (event) => {
            const newState = {...state};
            newState.shipments[index].verified = event.target.checked;
            setState(newState);
        };
    }

    function onBankNameStatusChange(data, index) {

        if (state.shipments[index].shipment.cod.cheque === null) {
            state.shipments[index].shipment.cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            const newState = {...state};
            newState.shipments[index].shipment.cod.cheque.bankName = event.target.value;
            setState(newState);
        };
    }

    function onChequeNumberStatusChange(data, index) {

        if (state.shipments[index].shipment.cod.cheque === null) {
            state.shipments[index].shipment.cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            const newState = {...state};
            newState.shipments[index].shipment.cod.cheque.chequeNumber = event.target.value;
            setState(newState);
        };
    }

    function onDateStatusChange(data, index) {

        if (state.shipments[index].shipment.cod.cheque === null) {
            state.shipments[index].shipment.cod.cheque = {bankName: null, chequeNumber: null, chequeDate: null};
        }

        return (event) => {
            const newState = {...state};
            newState.shipments[index].shipment.cod.cheque.chequeDate = event;
            setState(newState);
        };
    }

    function checkDelivered(shipment) {

        if (shipment.lastEvent.eventCode === 1 || shipment.lastEvent.eventCode === 81) {
            return "#baffba"
        } else {
            return null;
        }
    }


    function checkPickedUp(pickup) {

        if (pickup.events[pickup.events.length - 1].pickupEventCode.id === 37) {
            return "#baffba"
        } else {
            return null;
        }

    }

    function renderStepEventsTable() {
        return <div>
            <Table style={{width: "95%", margin: "auto", marginBlock: "5%"}}>
                <TableHead>
                    <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                        <TableCell style={{border: "2px solid"}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}>{t("shipment_details")}</Typography>
                        </TableCell>
                        <TableCell style={{border: "2px solid", width: "25%"}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("address")}</Typography>
                        </TableCell>
                        <TableCell style={{border: "2px solid"}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("cod")}</Typography>
                        </TableCell>
                        <TableCell style={{border: "2px solid"}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("parcels")}</Typography>
                        </TableCell>
                        <TableCell style={{border: "2px solid", width: "30%"}}>
                            <div style={{display: "flex"}}>
                                <Typography variant={"button"} style={{
                                    fontWeight: 'bold',
                                    margin: "auto",
                                    color: "whitesmoke"
                                }}> {t("status")}</Typography>
                                <Button style={{
                                    backgroundColor: "whitesmoke",
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    marginLeft: "25%",
                                    color: "black"
                                }} onClick={allDone}>{t("all_done")}</Button>
                            </div>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shipments.map((shipment, index) => {
                        return <TableRow style={{backgroundColor: checkDelivered(shipment)}}>
                            <TableCell style={{border: "2px solid"}}>
                                <Typography
                                    variant={"subtitle2"}>{t("shipmentId")} : {shipment.id}</Typography>
                                <Typography
                                    variant={"subtitle2"}>{t("reference")} : {shipment.reference}</Typography>
                                <Typography
                                    variant={"subtitle2"}>{t("contract_code")} : {shipment.contract.code}</Typography>
                            </TableCell>
                            <TableCell style={{border: "2px solid"}}>
                                <Typography
                                    variant={"subtitle2"}>{shipment.deliveryAddress.companyName} {shipment.deliveryAddress.referencePerson}</Typography>
                                <Typography
                                    variant={"subtitle2"}>{shipment.deliveryAddress.street} {shipment.deliveryAddress.buildingNr}</Typography>
                                <Typography
                                    variant={"subtitle2"}>{shipment.deliveryAddress.postalCode} {shipment.deliveryAddress.city} {shipment.deliveryAddress.administrative_level_3}</Typography>
                            </TableCell>
                            <TableCell style={{border: "2px solid"}}>
                                {shipment.cod ?
                                    <>
                                        <Typography
                                            variant={"subtitle2"}>{shipment.cod?.currency} {shipment.cod?.value.toFixed(2)}</Typography>
                                        <Typography
                                            variant={"subtitle2"}
                                            fontStyle={"italic"}>{shipment.cod?.codMode}</Typography>
                                    </>
                                    :
                                    <Chip
                                        style={{width: "100%"}}
                                        label={t("no_cod")}/>
                                }
                            </TableCell>
                            <TableCell style={{border: "2px solid"}}>
                                <Typography
                                    variant={"h5"}
                                    fontWeight={"bold"}>{shipment.parcels.filter(parcel => parcel.checked).length + "/" + shipment.parcels.length}</Typography>
                            </TableCell>
                            <TableCell style={{border: "2px solid"}}>
                                {renderDriverWorkDataStatus(shipment, index)}
                            </TableCell>
                        </TableRow>
                    })}

                    {pickups.map((pickup, index) => {
                        return renderPickupEvent(pickup, index);
                    })}
                </TableBody>
            </Table>
        </div>;
    }

    function onPickupStatusChange(data, index) {
        return (event, status) => {
            const newState = {...state};

            newState.pickups[index].status = status;

            if (status?.id === 37) {
                const needToOpenWarehouseStorage = newState.pickups[index].pickup.urbaneEventRef && !!koEventCodesForUrbaneThatOpensWarehouseStorage[newState.pickups[index].pickup.urbaneEventRef];
                const needToChangeToStandard = newState.pickups[index].pickup.urbaneEventRef && !!koEventCodesForUrbaneServiceChange[newState.pickups[index].pickup.urbaneEventRef];

                if (needToOpenWarehouseStorage) {
                    newState.pickups[index].warehouseStorage = {
                        checked: true,
                        reasonId: koEventCodesForUrbaneThatOpensWarehouseStorage[newState.pickups[index].pickup.urbaneEventRef].openingReasonCodeId
                    }
                }

                if (needToChangeToStandard) {
                    newState.pickups[index].urbaneToStandard = {
                        checked: true
                    }
                }
            } else {
                delete newState.pickups[index].warehouseStorage;
                delete newState.pickups[index].urbaneToStandard;
            }

            console.log(newState);

            setState(newState);
        }
    }


    function renderStepCodCollectAndVerify() {

        // let shipments = state.shipments.filter(data => data.shipment.cod && (data.shipment.lastEvent.eventCode === 1 || data.status?.id === 1));
        let shipments = state.shipments;

        if (0 === shipments.filter(data => data.shipment.cod && (data.shipment.lastEvent.eventCode === 1 || data.status?.id === 1)).length) {
            return <InfoChip><Typography
                variant={"subtitle2"}>{t("no_cod_to_collect")}</Typography></InfoChip>;
        }

        return <div>
            <Table style={{width: "95%", margin: "auto", marginBlock: "5%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{border: "2px solid", width: "25%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}>{t("shipment_details")}</Typography>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "25%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("address")}</Typography>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "10%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("cod")}</Typography>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "40%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("status")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {shipments.map((shipment, index) => {
                    return <>
                        {shipment.shipment.cod && (shipment.shipment.lastEvent.eventCode === 1 || shipment.status?.id === 1) ?
                            <TableRow>
                                <TableCell style={{border: "2px solid"}}>
                                    <Typography
                                        variant={"subtitle2"}>{t("shipmentId")} : {shipment.shipment.id}</Typography>
                                    <Typography
                                        variant={"subtitle2"}>{t("reference")} : {shipment.shipment.reference}</Typography>
                                    <Typography
                                        variant={"subtitle2"}>{t("contract_code")} : {shipment.shipment.contract.code}</Typography>
                                </TableCell>
                                <TableCell style={{border: "2px solid"}}>
                                    <Typography
                                        variant={"subtitle2"}>{shipment.shipment.deliveryAddress.companyName} {shipment.shipment.deliveryAddress.referencePerson}</Typography>
                                    <Typography
                                        variant={"subtitle2"}>{shipment.shipment.deliveryAddress.street} {shipment.shipment.deliveryAddress.buildingNr}</Typography>
                                    <Typography
                                        variant={"subtitle2"}>{shipment.shipment.deliveryAddress.postalCode} {shipment.shipment.deliveryAddress.city} {shipment.shipment.deliveryAddress.administrative_level_3}</Typography>
                                </TableCell>
                                <TableCell style={{border: "2px solid"}}>
                                    {shipment.shipment.cod ?
                                        <>
                                            <Typography
                                                variant={"subtitle2"}>{shipment.shipment.cod?.currency} {shipment.shipment.cod?.value.toFixed(2)}</Typography>
                                            <Typography
                                                variant={"subtitle2"}
                                                fontStyle={"italic"}>{shipment.shipment.cod?.codMode}</Typography>
                                        </>
                                        :
                                        <Chip
                                            style={{width: "100%"}}
                                            label={t("no_cod")}/>
                                    }
                                </TableCell>
                                <TableCell style={{border: "2px solid", width: "fit-content"}}>
                                    {renderCodEvent(shipment, index)}
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                    </>
                })}
                <TableRow>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("cash_total_value")}</Typography>
                        <Typography variant={"subtitle2"}>{returnCodsCashTotal(shipments).toFixed(2)}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("cheque_total_number")}</Typography>
                        <Typography variant={"subtitle2"}>{returnCodsChequeTotal(shipments).totalNumber}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("cheque_total_value")}</Typography>
                        <Typography
                            variant={"subtitle2"}>{returnCodsChequeTotal(shipments).totalValue.toFixed(2)}</Typography>
                    </TableCell>

                </TableRow>
            </Table>
        </div>

    }


    function renderCodEvent(shipment, index) {
        let shipmentState = shipment;

        let codEventCollected = shipmentState.shipment.cod.events.filter(event => event.eventCodeId === 2 || event.eventCodeId === 3 || event.eventCodeId === 4 || event.eventCodeId === 5 || event.eventCodeId === 6 || event.eventCodeId === 7)[0] || null;
        let codEventVerified = shipmentState.shipment.cod.events.filter(event => event.eventCodeId === 8)[0] || null;

        if (codEventVerified) {
            state.shipments[index].verified = true;
        }

        return <div style={{display: "block"}}>
            <div>
                <div style={{width: "100%", margin: "auto", marginBottom: "5%"}}>
                    {codEventCollected ?

                        <div>
                            <Typography>{codEventCollected.eventCodeName}</Typography>
                        </div>

                        :

                        <Autocomplete
                            id="codMode"
                            size="small"
                            style={{
                                marginBottom: "2%",
                                width: "95%",
                                margin: "auto"
                            }}
                            disabled={codEventCollected !== null}
                            options={codEventCodes}
                            getOptionLabel={option => option.id + " - " + option.name}
                            value={shipmentState?.codMode}
                            defaultValue={shipmentState?.codMode}
                            noOptionsText={t("no_options")}
                            onOpen={() => fetchCodEventCodes(shipmentState.shipment.cod.codModeId)}
                            onChange={onCodStatusChange(shipmentState, index)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("cod")}
                                    variant="outlined"
                                    multiline
                                    margin="dense"
                                    value={shipmentState?.codMode}
                                    defaultValue={shipmentState?.codMode}
                                />
                            )}
                        />
                    }
                </div>

                <div style={{margin: "auto", width: "fit-content"}}>
                    {codEventVerified || shipmentState.shipment.cod.chequeForwardingId ?
                        <Chip style={{margin: "auto", width: "fit-content"}}
                              icon={<CheckRounded style={{color: "green"}}/>} label={t("verified")}/>
                        :
                        <FormControlLabel
                            label={<Typography fontSize={12}
                                               variant={"button"}>{t("verified")}</Typography>}
                            control={
                                <Checkbox
                                    disabled={codEventVerified}
                                    color={"primary"}
                                    checked={shipmentState?.verified}
                                    onChange={onVerifiedStatusChange(shipmentState, index)}
                                />
                            }
                            labelPlacement={"right"}
                        />
                    }
                </div>

            </div>
            {shipmentState?.codMode?.id === 3 || shipmentState?.codMode?.id === 4 || shipmentState?.codMode?.id === 5 || shipmentState?.codMode?.id === 6 || codEventCollected?.eventCodeId === 3 || codEventCollected?.eventCodeId === 4 || codEventCollected?.eventCodeId === 5 || codEventCollected?.eventCodeId === 6 ?
                <div style={{display: "block", marginTop: "5%"}}>
                    <div style={{display: "block"}}>
                        <TextField
                            style={{
                                width: "100%",
                                margin: "auto",
                                marginBlock: "1%"
                            }}
                            label={t("bank_name")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            size={"small"}
                            disabled={shipmentState?.shipment?.cod?.chequeForwardingId}
                            onChange={onBankNameStatusChange(shipmentState, index)}
                            value={shipmentState.shipment.cod?.cheque?.bankName}
                        />
                        <TextField
                            style={{
                                width: "100%",
                                margin: "auto",
                                marginBlock: "1%"
                            }}
                            label={t("cheque_number")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            size={"small"}
                            disabled={shipmentState?.shipment?.cod?.chequeForwardingId}
                            onChange={onChequeNumberStatusChange(shipmentState, index)}
                            value={shipmentState.shipment.cod?.cheque?.chequeNumber}
                        />
                    </div>
                    <div>
                        <DateField
                            style={{width: "50%", margin: "auto"}}
                            label={t("date")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            format={"dd/MM/yyyy"}
                            clearable={true}
                            disableFuture={true}
                            disabled={shipmentState?.shipment?.cod?.chequeForwardingId}
                            onChange={onDateStatusChange(shipmentState, index)}
                            value={shipmentState.shipment.cod?.cheque?.chequeDate}
                        />
                    </div>
                </div>
                :
                null
            }

        </div>
    }

    function renderStepWarehouseStorageTable() {
        const shipmentWithWarehouseStorages = state.shipments.filter((data) => data.warehouseStorage && !data.warehouseStorage.id);
        const pickupsWithWarehouseStorages = state.pickups.filter((data) => (data.warehouseStorage && !data.warehouseStorage.id) || data.urbaneToStandard);
        if (0 === shipmentWithWarehouseStorages.length && 0 === pickupsWithWarehouseStorages.length) {
            return <InfoChip><Typography
                variant={"subtitle2"}>{t("no_warehouse_storage_to_register")}</Typography></InfoChip>;
        }

        return <div>
            <Table style={{width: "95%", margin: "auto", marginBlock: "5%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{border: "2px solid", backgroundColor: "white", width: "5%"}}>
                            <Checkbox color="primary"
                                      style={{margin: "auto", width: "100%"}}
                                      onChange={handleAllWarehouseStoragesChecked}
                                      checked={allWarehouseStoragesChecked}/>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "25%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}>{t("shipment_details")}</Typography>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "25%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}> {t("address")}</Typography>
                        </TableCell>
                        <TableCell
                            style={{border: "2px solid", width: "40%", backgroundColor: theme.palette.primary.main}}>
                            <Typography variant={"button"} style={{
                                fontWeight: 'bold',
                                margin: "auto",
                                color: "whitesmoke"
                            }}>{t("reason")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.shipments.map((shipmentWithWarehouseStorage, index) => {
                        return <>
                            {shipmentWithWarehouseStorage.warehouseStorage && !shipmentWithWarehouseStorage.warehouseStorage.id ?
                                <TableRow>
                                    <TableCell style={{border: "2px solid", backgroundColor: "white", width: "5%"}}>
                                        <Checkbox
                                            color="primary"
                                            style={{margin: "auto", width: "100%"}}
                                            onChange={event => handleWarehouseStorageChecked(event, index, false)}
                                            checked={!!shipmentWithWarehouseStorage.warehouseStorage.checked}/>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{t("shipmentId")} : {shipments[index].id}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("reference")} : {shipments[index].reference}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("contract_code")} : {shipments[index].contract.code}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{shipments[index].deliveryAddress.companyName} {shipments[index].deliveryAddress.referencePerson}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{shipments[index].deliveryAddress.street} {shipments[index].deliveryAddress.buildingNr}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{shipments[index].deliveryAddress.postalCode} {shipments[index].deliveryAddress.city} {shipments[index].deliveryAddress.administrative_level_3}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <div>
                                            <Autocomplete
                                                id="warehouseStorageOpeningReasonCodeId"
                                                size="small"
                                                style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                                options={warehouseStorageReasons}
                                                getOptionLabel={option => option.id + " - " + option.description}
                                                value={warehouseStorageReasons.find(value => value.id === shipmentWithWarehouseStorage.warehouseStorage.reasonId)}
                                                defaultValue={warehouseStorageReasons.find(value => value.id === shipmentWithWarehouseStorage.warehouseStorage.reasonId)}
                                                noOptionsText={t("no_options")}
                                                onChange={onWarehouseStorageReasonIdChange(shipmentWithWarehouseStorage, index, false)}
                                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("reason")}
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={warehouseStorageReasons.find(value => value.id === shipmentWithWarehouseStorage.warehouseStorage.reasonId)}
                                                        defaultValue={warehouseStorageReasons.find(value => value.id === shipmentWithWarehouseStorage.warehouseStorage.reasonId)}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="warehouseStorageNote"
                                                label={t("notes")}
                                                type="text"
                                                value={shipmentWithWarehouseStorage.note}
                                                onChange={onWarehouseStorageNoteChange(shipmentWithWarehouseStorage, index, false)}
                                                autoComplete={t("notes")}
                                            />
                                        </div>
                                    </TableCell>

                                </TableRow>
                                :
                                null
                            }
                        </>
                    })}
                    {state.pickups.map((pickupWithWarehouseStorage, index) => {
                        return <>
                            {pickupWithWarehouseStorage.warehouseStorage && !pickupWithWarehouseStorage.warehouseStorage.id ?
                                <TableRow>
                                    <TableCell style={{border: "2px solid", backgroundColor: "white", width: "5%"}}>
                                        <Checkbox
                                            color="primary"
                                            style={{margin: "auto", width: "100%"}}
                                            onChange={event => handleWarehouseStorageChecked(event, index, true)}
                                            checked={!!pickupWithWarehouseStorage.warehouseStorage.checked}/>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{t("pickup_id")} : {pickups[index].id}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("shipment_id")} : {pickups[index].shipments.map(id => id)}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{t("contract_code")} : {pickups[index].contractCode}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <Typography
                                            variant={"subtitle2"}>{pickups[index].address.companyName} {pickups[index].address.referencePerson}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{pickups[index].address.street} {pickups[index].address.buildingNr}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{pickups[index].address.postalCode} {pickups[index].address.city} {pickups[index].address.administrative_level_3}</Typography>
                                    </TableCell>
                                    <TableCell style={{border: "2px solid"}}>
                                        <div>
                                            <Autocomplete
                                                id="warehouseStorageOpeningReasonCodeId"
                                                size="small"
                                                style={{marginBottom: "2%", width: "80%", margin: "auto"}}
                                                options={warehouseStorageReasons}
                                                getOptionLabel={option => option.id + " - " + option.description}
                                                value={warehouseStorageReasons.find(value => value.id === pickupWithWarehouseStorage.warehouseStorage.reasonId)}
                                                defaultValue={warehouseStorageReasons.find(value => value.id === pickupWithWarehouseStorage.warehouseStorage.reasonId)}
                                                noOptionsText={t("no_options")}
                                                onChange={onWarehouseStorageReasonIdChange(pickupWithWarehouseStorage, index, true)}
                                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("reason")}
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={warehouseStorageReasons.find(value => value.id === pickupWithWarehouseStorage.warehouseStorage.reasonId)}
                                                        defaultValue={warehouseStorageReasons.find(value => value.id === pickupWithWarehouseStorage.warehouseStorage.reasonId)}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="warehouseStorageNote"
                                                label={t("notes")}
                                                type="text"
                                                value={pickupWithWarehouseStorage.note}
                                                onChange={onWarehouseStorageNoteChange(pickupWithWarehouseStorage, index, true)}
                                                autoComplete={t("notes")}
                                            />
                                        </div>
                                    </TableCell>

                                </TableRow>
                                :
                                <>
                                    {pickupWithWarehouseStorage.urbaneToStandard ?
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        border: "2px solid",
                                                        backgroundColor: "white",
                                                        width: "5%"
                                                    }}>
                                                    <Checkbox
                                                        color="primary"
                                                        style={{margin: "auto", width: "100%"}}
                                                        onChange={event => handleWarehouseStorageChecked(event, index, true)}
                                                        checked={!!pickupWithWarehouseStorage.urbaneToStandard.checked}/>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("pickup_id")} : {pickups[index].id}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("shipment_id")} : {pickups[index].shipments.map(id => id)}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("contract_code")} : {pickups[index].contractCode}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{pickups[index].address.companyName} {pickups[index].address.referencePerson}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{pickups[index].address.street} {pickups[index].address.buildingNr}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{pickups[index].address.postalCode} {pickups[index].address.city} {pickups[index].address.administrative_level_3}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("update_to_standard_service")}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            null
                                    }
                                </>
                            }
                        </>
                    })}
                </TableBody>
            </Table>
        </div>;
    }

    function onWarehouseStorageReasonIdChange(data, index, isPickup) {
        return (event, reason) => {
            const newState = {...state};

            if (isPickup) {
                if (!newState.pickups[index].warehouseStorage) {
                    newState.pickups[index].warehouseStorage = {checked: true};
                }
                newState.pickups[index].warehouseStorage.reasonId = reason.id;
            } else {
                if (!newState.shipments[index].warehouseStorage) {
                    newState.shipments[index].warehouseStorage = {checked: true};
                }
                newState.shipments[index].warehouseStorage.reasonId = reason.id;
            }


            setState(newState);
        };
    }

    function onWarehouseStorageNoteChange(data, index, isPickup) {
        return (event, reason) => {
            const newState = {...state};

            if (isPickup) {
                if (!newState.pickups[index].warehouseStorage) {
                    newState.pickups[index].warehouseStorage = {checked: true};
                }
                newState.pickups[index].warehouseStorage.note = event.target.value;
            } else {
                if (!newState.shipments[index].warehouseStorage) {
                    newState.shipments[index].warehouseStorage = {checked: true};
                }
                newState.shipments[index].warehouseStorage.note = event.target.value;
            }

            setState(newState);
        };
    }

    function onParcelStatusChange(data, index) {
        return (event, status) => {
            const newState = {...state};
            newState.shipments[index].status = status;

            if (status && deliveryEventCodesThatOpensWarehouseStorages[status.id]) {
                let anyParcelHasOtherEvents = shipments[index].parcels?.some(parcelData => {
                    return parcelData.parcel.lastDriverWorkListEvent &&
                        parcelData.parcel.lastDriverWorkListEvent.id !== parcelData.parcel.lastEvent?.id;
                });

                let needToOpenWarehouseStorage = !anyParcelHasOtherEvents;
                let warehouseStorageReasonId;
                if (needToOpenWarehouseStorage) {
                    warehouseStorageReasonId = deliveryEventCodesThatOpensWarehouseStorages[status.id].openingReasonCodeId;
                    if (deliveryEventCodesWithMultipleAttempts.some(value => value === status.id)) {
                        // got an event code with multiple attempts
                        warehouseStorageReasonId = 2; // Done multiple attempts
                        needToOpenWarehouseStorage = shipments[index].parcels?.some(parcelData => {
                            return parcelData.parcel.numOfDeliveryAttempts + 1 >= parcelData.parcel.numOfAllowedDeliveryAttempts;
                        });
                    }
                }

                if (needToOpenWarehouseStorage) {
                    newState.shipments[index].warehouseStorage = {
                        checked: true,
                        reasonId: warehouseStorageReasonId
                    };
                } else {
                    delete newState.shipments[index].warehouseStorage;
                }
            } else {
                delete newState.shipments[index].warehouseStorage;
            }

            setState(newState);
        };
    }

    function onParcelWarehouseChange(data, index) {
        return async (event, warehouse) => {
            const newState = {...state};
            newState.shipments[index].warehouse = warehouse;

            if (warehouse) {
                let filters = {};
                filters.warehouseCode = warehouse.code;

                let driverService = new DriverService();
                const drivers = await driverService.getDriversByFilters(filters);
                setDrivers(drivers.drivers)
            } else {
                delete newState.shipments[index].warehouse;
                delete newState.shipments[index].driver;

                setDrivers([]);
            }

            setState(newState);
        };
    }

    function onParcelDriverChange(data, index) {
        return (event, driver) => {
            const newState = {...state};

            if (driver) {
                newState.shipments[index].driver = driver;
            } else {
                delete newState.shipments[index].driver;
            }

            setState(newState);
        };
    }

    function onShipmentTimeChange(data, index) {
        return (value) => {
            const newState = {...state};
            newState.shipments[index].time = new Date(value);
            setState(newState);
        };
    }

    function onPickupTimeChange(data, index) {
        return (value) => {
            const newState = {...state};
            newState.pickups[index].time = new Date(value);

            setState(newState);
        };
    }

    function handleAllWarehouseStoragesChecked(event) {
        setAllWarehouseStoragesChecked(event.target.checked);

        const newState = {...state};
        for (let i = 0; i < newState.shipments.length; i++) {
            if (newState.shipments[i].warehouseStorage) {
                newState.shipments[i].warehouseStorage.checked = !!event.target.checked;
            } else if (newState.pickups[i].warehouseStorage) {
                newState.pickups[i].warehouseStorage.checked = !!event.target.checked;
            } else if (newState.pickups[i].urbaneToStandard) {
                newState.pickups[i].urbaneToStandard.checked = !!event.target.checked;
            }
        }
        setState(newState);
    }

    function handleWarehouseStorageChecked(event, index, isPickup) {
        const newState = {...state};

        if (isPickup) {
            if (newState.pickups[index].warehouseStorage) {
                newState.pickups[index].warehouseStorage.checked = !!event.target.checked;
            } else if (newState.pickups[index].urbaneToStandard) {
                newState.pickups[index].urbaneToStandard.checked = !!event.target.checked;
            }
        } else {
            newState.shipments[index].warehouseStorage.checked = !!event.target.checked;
        }

        if (newState.shipments.filter(record => !!record.warehouseStorage).some(record => !record.warehouseStorage.checked) ||
            newState.pickups.filter(record => !!record.warehouseStorage).some(record => !record.warehouseStorage.checked)
        ) {
            setAllWarehouseStoragesChecked(false);
        } else {
            setAllWarehouseStoragesChecked(true);
        }

        setState(newState);
    }

    async function save(state) {
        const newResults = {shipments: {}, pickups: {}};

        let ids = [];
        let shipmentIds = []

        if (state.shipments && 0 < state.shipments.length) {
            for (let i = 0; i < state.shipments.length; i++) {
                let shipmentState = state.shipments[i];
                let shipment = shipments.filter((tmpShipment) => tmpShipment.id === shipmentState.shipment?.id)[0];

                newResults.shipments[shipment.id] = {error: false, errorMsg: ""};

                let codEventCollected = shipmentState.shipment.cod?.events.filter(event => event.eventCodeId === 2 || event.eventCodeId === 3 || event.eventCodeId === 5 || event.eventCodeId === 7)[0] || null;
                let codEventVerified = shipmentState.shipment.cod?.events.filter(event => event.eventCodeId === 8)[0] || null;

                if (shipmentState.status) {
                    for (let j = 0; j < shipment.parcels.length; j++) {
                        let parcel = shipment.parcels[j];

                        if (parcel.checked) {

                            let request = {};

                            if (state.driverWorkListId) {
                                let date = new Date(props.date.getFullYear(), props.date.getMonth(), props.date.getDate(), shipmentState.time.getHours(), shipmentState.time.getMinutes(), shipmentState.time.getSeconds());

                                request.dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
                            } else {
                                request.dateTime = moment(shipmentState.time).format('YYYY-MM-DDTHH:mm:ssZ');
                            }

                            request.eventCodeId = shipmentState?.status?.id;
                            request.driverId = shipmentState?.driver?.id;
                            request.warehouseId = shipmentState?.warehouse?.id;
                            request.driverWorkListId = props.driverWorkListId;
                            if (shipment.codId && shipmentState.codMode && codEventCollected === null) {
                                request.codCollectingEventCodeId = shipmentState.codMode?.id;
                            }

                            let parcelService = new ParcelService();
                            await parcelService.addEventToParcel(parcel.parcel.id, request)
                                .then(result => {

                                })
                                .catch(error => {
                                    error.response.json().then(err => {
                                        newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                                    })
                                })
                        }
                    }
                }

                if (shipment.codId && shipmentState.codMode && shipmentState?.shipment?.cod?.chequeForwardingId === null &&
                    (
                        null !== shipmentState.shipment.cod?.cheque?.bankName ||
                        null !== shipmentState.shipment.cod?.cheque?.chequeNumber ||
                        shipmentState.shipment.cod?.cheque?.chequeDate
                    )) {

                    await new CodService().updateChequeData(
                        shipmentState.shipment.cod.id,
                        {
                            bankName: shipmentState.shipment.cod?.cheque?.bankName,
                            chequeNumber: shipmentState.shipment.cod?.cheque?.chequeNumber,
                            chequeDate: shipmentState.shipment.cod?.cheque?.chequeDate ? moment(shipmentState.shipment.cod?.cheque?.chequeDate).format('yyyy-MM-DD') : null
                        }
                    ).then(result => {

                    }).catch(error => {
                        error.response.json().then(err => {
                            newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                        })
                    })

                }


                //
                // if (shipment.codId && shipmentState.codMode && codEventCollected === null) {
                //
                //     const codService = new CodService();
                //     await codService.collectCod(
                //         shipment.codId,
                //         {
                //             dateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                //             codEventCodeId: shipmentState.codMode.id,
                //             verified: shipmentState.verified,
                //             bankName: shipmentState.shipment.cod?.cheque?.bankName,
                //             chequeNumber: shipmentState.shipment.cod?.cheque?.chequeNumber,
                //             chequeDate: shipmentState.shipment.cod?.cheque?.chequeDate ? moment(shipmentState.shipment.cod?.cheque?.chequeDate).format('yyyy-MM-DD') : null
                //         }
                //     ).then(result => {
                //
                //     }).catch(error => {
                //             error.response.json().then(err => {
                //                 newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                //             })
                //         })
                // } else {
                if (shipment.codId && shipmentState.verified && codEventVerified === null) {

                    const codService = new CodService();
                    await codService.verifyInWarehouse(
                        {
                            cods: [shipment.codId]
                        }
                    ).then(result => {

                    }).catch(error => {
                        error.response.json().then(err => {
                            newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                        })
                    })
                }

                // if (shipmentState?.shipment?.cod?.chequeForwardingId === null) {
                //
                //     await new CodService().updateChequeData(
                //         shipmentState.shipment.cod.id,
                //         {
                //             bankName: shipmentState.shipment.cod?.cheque?.bankName,
                //             chequeNumber: shipmentState.shipment.cod?.cheque?.chequeNumber,
                //             chequeDate: shipmentState.shipment.cod?.cheque?.chequeDate ? moment(shipmentState.shipment.cod?.cheque?.chequeDate).format('yyyy-MM-DD') : null
                //         }
                //     ).then(result => {
                //
                //     }).catch(error => {
                //         error.response.json().then(err => {
                //             newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                //         })
                //     })
                // }
                // }

                if (shipmentState.warehouseStorage && !shipmentState.warehouseStorage.id && shipmentState.warehouseStorage.checked) {
                    const warehouseStorageService = new WarehouseStorageService();
                    await warehouseStorageService.createWarehouseStorage({
                            shipment: {id: shipment.id},
                            openingDateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                            reasonId: shipmentState.warehouseStorage.reasonId,
                            warehouseCode: shipmentState?.warehouse?.code,
                            notes: shipmentState.warehouseStorage.note,
                        }
                    ).then(result => {
                        ids.push(result.id);
                    }).catch(error => {
                        error.response.json().then(err => {
                            newResults.shipments[shipment.id] = {error: true, errorMsg: err.message};
                        })
                    })
                }
            }
        }

        if (state.pickups && 0 < state.pickups.length) {
            for (let i = 0; i < state.pickups.length; i++) {
                let pickupState = state.pickups[i];
                let pickup = pickups.filter((tmpPickup) => tmpPickup.id === pickupState.pickup?.id)[0];
                newResults.pickups[pickup.id] = {error: false, errorMsg: ""};

                if (pickupState.status) {
                    let request = {};
                    request.dateTime = moment(pickupState.time).format('YYYY-MM-DDTHH:mm:ssZ');
                    request.pickupEventCodeId = pickupState.status.id;
                    request.warehouseCode = pickupState?.warehouse?.code;
                    request.driverId = pickupState?.driver?.id;

                    let pickupService = new PickupService();
                    await pickupService.addEventToPickup(pickup.id, request)
                        .then(result => {

                        }).catch(error => {
                            error.response.json().then(err => {
                                newResults.pickups[pickup.id] = {error: true, errorMsg: err.message};
                            })
                        })
                }

                if (pickupState.warehouseStorage && !pickupState.warehouseStorage.id && pickupState.warehouseStorage.checked) {
                    const warehouseStorageService = new WarehouseStorageService();
                    await warehouseStorageService.createWarehouseStorage({
                            shipment: {id: pickupState.pickup?.shipments[0]},
                            openingDateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                            reasonId: pickupState.warehouseStorage.reasonId,
                            warehouseCode: pickupState?.warehouse?.code,
                            notes: pickupState.warehouseStorage.note,
                        }
                    ).then(result => {
                        ids.push(result.id);
                    }).catch(error => {
                        error.response.json().then(err => {
                            newResults.shipments[pickupState.pickup?.shipments[0]] = {
                                error: true,
                                errorMsg: err.message
                            };
                        })
                    })
                }

                //STEP CAMBIO SERVIZIO URBANE TO ESPRESSO.
                if (pickupState.urbaneToStandard && pickupState.urbaneToStandard.checked) {

                    await new ShipmentService().updateUrbaneToStandard(pickupState.pickup?.shipments[0])
                        .then(result => {
                            shipmentIds.push(pickupState.pickup?.shipments[0]);
                        })
                        .catch(error => {
                            error.response.json().then(err => {
                                newResults.shipments[pickupState.pickup?.shipments[0]] = {
                                    error: true,
                                    errorMsg: err.message
                                };
                            })
                        })
                }
            }
        }

        newResults.labels = [];

        if (ids.length > 0) {
            let input = {};
            input.ids = ids;

            await new WarehouseStorageService().getWarehouseStorageLabels(input)
                .then(response => {
                    newResults.labels.push(response.content);
                })
                .catch(error => {

                })
        }

        for (let i = 0; i < shipmentIds.length; i++) {

            await new ShipmentService().getLabel(shipmentIds[i], "PDF")
                .then(response => {
                    newResults.labels.push(response[0].content);
                })
                .catch(error => {

                })

        }

        // for (let i = 0; i < ids.length; i++) {
        //     await new WarehouseStorageService().getWarehouseStorageLabel(ids[i])
        //         .then(response => {
        //             newResults.labels.push(response.content);
        //         })
        //         .catch(error => {
        //
        //         })
        // }

        setResult(newResults);
        if ("function" === typeof props.onSave) {
            props.onSave(newResults);
        }
    }

    function renderResults() {
        const shipmentIds = results.shipments ? Object.keys(results.shipments) : [];
        const pickupIds = results.pickups ? Object.keys(results.pickups) : [];

        const labels = results.labels;

        return <div>
            {shipmentIds?.map((shipmentId) =>
                <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                    <Chip label={<Typography style={{
                        color: results.shipments[shipmentId].error ? "red" : "green",
                        margin: "auto"
                    }}>{t("shipment") + " " + shipmentId + ": " + (results.shipments[shipmentId].error ? results.shipments[shipmentId].errorMsg : "OK")}</Typography>}/>
                </div>
            )}
            {pickupIds?.map((pickupId) =>
                <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                    <Chip label={<Typography style={{
                        color: results.pickups[pickupId].error ? "red" : "green",
                        margin: "auto"
                    }}>{t("pickup") + " " + pickupId + ": " + (results.pickups[pickupId].error ? results.pickups[pickupId].errorMsg : "OK")}</Typography>}/>
                </div>
            )}
            {labels?.map(label =>
                <div style={{display: "flex"}}>
                    <div style={{width: "fit-content", margin: "auto"}}>
                        <object style={{marginBlock: "5%"}} width={400} height={400} type={'application/pdf'}
                                data={"data:application/pdf;base64," + label}>";
                            html += "
                        </object>
                        {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                    </div>
                </div>
            )}
        </div>
    }

    return <>
        <Stepper style={{width: "30%", margin: "auto", marginTop: "5%"}}
                 connector={<StepConnector/>} activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                    labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                    );
                }
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }

                return (
                    <Step style={{margin: "auto"}} key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
        {activeStep === steps.length ? (
            <React.Fragment>
                {renderResults()}
                {/*<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>*/}
                {/*    <Box sx={{flex: '1 1 auto'}}/>*/}
                {/*    <Button onClick={handleReset}>Reset</Button>*/}
                {/*</Box>*/}
            </React.Fragment>
        ) : (
            <React.Fragment>
                {0 === activeStep ? renderStepEventsTable() : <div/>}
                {1 === activeStep ? renderStepCodCollectAndVerify() : <div/>}
                {2 === activeStep ? renderStepWarehouseStorageTable() : <div/>}

                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        style={{marginLeft: "5%", marginBottom: "1%"}}
                        onClick={handleBack}
                        sx={{mr: 1}}
                    >
                        {t("back")}
                    </Button>
                    <Box sx={{flex: '1 1 auto'}}/>
                    {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                            Skip
                        </Button>
                    )}

                    <Button disabled={activeStep === 1 && !checkAllCodsWithStatus()}
                            style={{marginRight: "5%", marginBottom: "1%"}} onClick={handleNext}>
                        {activeStep === steps.length - 1 ? t("save") : t("next")}
                    </Button>
                </Box>
            </React.Fragment>
        )}
    </>;
}