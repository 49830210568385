import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {Dashboard, ExitToApp, Language, Person, Settings} from "@material-ui/icons";
import {Switch, useHistory, useRouteMatch} from "react-router-dom";
import UserService from "../users/UserService";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import {PrivateRoute} from "../routes/PrivateRoute";
import ShipmentsTable from "../shipments/ShipmentsTable";
import AccountsTable from "../accounts/AccountsTable";
import RolesTable from "../roles/RolesTable";
import UsersTable from "../users/UsersTable";
import BackofficeMenu from "./BackofficeMenu";
import {useTranslation} from "react-i18next";
import CustomerPreInvoicesTable from "../accounting/customer-pre-invoices/CustomerPreInvoicesTable";
import CountriesTable from "../geo/countries/CountriesTable";
import ListItemText from "@material-ui/core/ListItemText";
import Flag from 'react-world-flags';
import i18next from "i18next";
import CostCenterTable from "../accounting/cost_center/CostCenterTable";
import TaxRateTable from "../accounting/tax_rate/TaxRateTable";
import LedgerAccountTable from "../accounting/ledger_accounts/LedgerAccountTable";
import PostalCodesTable from "../geo/postal_codes/PostalCodesTable";
import CitiesTable from "../geo/cities/CitiesTable";
import HamletsTable from "../geo/hamlets/HamletsTable";
import WarehousesTable from "../warehouses/WarehousesTable";
import DistrictsTable from "../districts/DistrictsTable";
import SlotsTable from "../districts/slot/SlotsTable";
import ZoneTable from "../zones/ZoneTable";
import BookingQueueTable from "../booking_queue/BookingQueueTable";
import WarehouseStoragesTable from "../warehouse_storages/WarehouseStoragesTable";
import ContractsTable from "../accounts/contracts/ContractsTable";
import RatesTable from "../rates/RatesTable";
import PickupsTable from "../pickups/PickupsTable";
import DriverTable from "../drivers/DriverTable";
import TruckLoadPage from "../truck_load/TruckLoadPage";
import VehicleTable from "../vehicles/VehicleTable";
import NewDriverWorkListPage from "../driver_work_list/NewDriverWorkListPage";
import CloseDriverWorkListPage from "../driver_work_list/CloseDriverWorkListPage";
import ArrivalsPage from "../arrivals/ArrivalsPage";
import ActiveTaxQueueTable from "../active-tax-queue/ActiveTaxQueueTable";
import PassiveTaxQueueTable from "../passive-tax-queue/PassiveTaxQueueTable";
import SupplierPreInvoicesTable from "../accounting/supplier-pre-invoices/SupplierPreInvoicesTable";
import HandheldTable from "../handhelds/HandheldTable";
import DriverWorkListsTable from "../driver_work_list/DriverWorkListsTable";
import AddToDriverWorkListPage from "../driver_work_list/AddToDriverWorkListPage";
import DeleteStatusPage from "../delete_status/DeleteStatusPage";
import TripsTable from "../departures/trips/TripsTable";
import RoutesTable from "../departures/routes/RoutesTable";
import DeparturesPage from "../departures/departures/DeparturesPage";
import SummaryOutPage from "../departures/summary_out/SummaryOutPage";
import CustomersMissingShipmentsPage from "../arrivals/missing/CustomersMissingShipmentsPage";
import VerifyCodsPage from "../cod/VerifyCodsPage";
import SupplierReportPage from "../cod/suppliers_report/SupplierReportPage";
import WarehouseMissingShipmentsPage from "../arrivals/missing/WarehouseMissingShipmentsPage";
import WarehouseCheckPage from "../warehouse_check/WarehouseCheckPage";
import DistrictsRoutingRuleTable from "../routing_rules/DistrictsRoutingRuleTable";
import ZonesRoutingRuleTable from "../routing_rules/ZonesRoutingRuleTable";
import SettingsPage from "../settings/SettingsPage";
import TimingsTable from "../timings/TimingTable";
import PaymentSlipsTable from "../cod/payments/PaymentSlipsTable";
import PaymentSlipForm from "../cod/payments/PaymentSlipForm";
import PlanningAndControl from "../planning_and_control/PlanningAndControl";
import ConfirmDelayedDriverWorkListPage from "../driver_work_list/ConfirmDelayedDriverWorkListPage";
import DriverWorkListDocumentPage from "../driver_work_list/DriverWorkListDocumentPage";
import RoundDetails from "../planning_and_control/RoundDetails";
import LoadingListTable from "../loading_lists/LoadingListTable";
import TimingsRoutingRuleTable from "../routing_rules/TimingsRoutingRuleTable";
import ServiceLevels from "../timings/ServiceLevels";
import VerifyPaymentSlipPage from "../cod/payments/VerifyPaymentSlipPage";
import TaskQueueTable from "../timings/TaskQueueTable";
import PaymentsToCustomerTable from "../cod/payments_to_customers/PaymentsToCustomerTable";
import NewChequeForwarding from "../cod/cheque_forwarding_to_administrative_office/NewChequeForwarding";
import ChequeForwardingTable from "../cod/cheque_forwarding_to_administrative_office/ChequeForwardingTable";
import VerifyChequeForwarding from "../cod/cheque_forwarding_to_administrative_office/VerifyChequeForwarding";
import NewChequePaymentToCustomerPage from "../cod/cheque_payments_to_customers/NewChequePaymentToCustomerPage";
import ChequePaymentsToCustomersTable from "../cod/cheque_payments_to_customers/ChequePaymentsToCustomersTable";
import PasswordChangePage from "../settings/PasswordChangePage";
import PostalCodeExtraction from "../zones/PostalCodeExtraction";
import PudoTable from "../pudos/PudoTable";
import BankTransactionTable from "../bank_transactions/BankTransactionTable";
import ImportFromCbiPage from "../cod/ImportFromCbiPage";
import AddressChangeRequestTable from "../pudos/address_change_requests/AddressChangeRequestTable";
import DeleteCodEventRequestTable from "../cod/delete_cod_events_requests/DeleteCodEventRequestTable";
import PrimaEdicolaQueueTable from "../shipments/queues/PrimaEdicolaQueueTable";
import WarehouseCollectionTable from "../shipments/warehouse_collection/WarehouseCollectionTable";
import RatingOptionTable from "../ratings/options/RatingOptionTable";
import RatingPage from "../ratings/RatingPage";
import SalesOrdersPage from "../sales_orders/SalesOrdersPage";
import CostsOrdersPage from "../costs_orders/CostsOrdersPage";
import ImportShipmentFromCsvPage from "../shipments/ImportShipmentFromCsvPage";
import TripsOrdersPage from "../trips_orders/TripsOrdersPage";
import ParcelsQueueTable from "../parcels_queue/ParcelsQueueTable";
import CheckInDeparturesPage from "../departures/check_in_departures/CheckInDeparturesPage";
import PaymentCardTable from "../payment_cards/PaymentCardTable";
import PaymentCardForm from "../payment_cards/PaymentCardForm";
import OptimizeDriverWorkList from "../driver_work_list/OptimizeDriverWorkList";
import SubContractsTable from "../accounts/contracts/sub-contracts/SubContractsTable";
import MonthExtraction from "../accounting/month_extraction/MonthExtraction";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// const drawerWidth = 'fit-content';
const drawerWidth = 285;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: 1280,
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    logoImg: {
        height: 50,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 0,
        height: '100%',
        width: '100%',
        // overflow: 'auto'
    },
    container: {
        maxWidth: 1920,
        marginLeft: "auto",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    flag: {
        width: "1%",
        height: "1%"
    }
}));

export default function DashboardPage() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
    const [confirmLogoutDialogOpen, setConfirmLogoutDialogOpen] = React.useState(false);
    const [isLanguageOpened, setLanguageOpened] = useState(false);
    const profileBtnAnchorRef = React.useRef(null);
    const languageBtnAnchorRef = React.useRef(null);
    const history = useHistory();
    const {path, url} = useRouteMatch();
    const {t} = useTranslation();

    let user = localStorage.getItem("user");


    const handleDrawerOpen = () => {
        setOpen(true);

    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const flipProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    }
    const closeProfileMenu = () => {
        setProfileMenuOpen(false);
    }

    function openLanguageSelector() {
        setLanguageOpened(!isLanguageOpened);
    }

    const goToDashboard = () => {
        history.push("/");
    }

    const goToSettings = () => {
        history.push("/settings");
    }

    const goToPasswordChange = () => {
        history.push("/change-password");
    }

    const askLogout = () => {
        setConfirmLogoutDialogOpen(true);
    }

    const doLogout = () => {
        const userService = new UserService();
        userService.logout();
        history.push("/login");
        localStorage.clear();
    }


    function changeLanguage(lang) {
        i18next.changeLanguage(lang);
    }

    useEffect(() => {
    }, [])


    return (
        <div className={classes.root}>
            <CssBaseline/>
            <ConfirmDialog open={confirmLogoutDialogOpen}
                           confirmText={t("confirm")}
                           cancelText={t("cancel")}
                           onConfirm={doLogout}
                           onCancel={() => setConfirmLogoutDialogOpen(false)}>
                {t("confirm_logout_msg")}
            </ConfirmDialog>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography className={classes.title}>
                        <img alt="logo" src={process.env.PUBLIC_URL + "/logo_white.png"} className={classes.logoImg}/>
                    </Typography>
                    <Typography style={{marginRight: 5}} variant={"subtitle2"}>{user?.replaceAll("\"", "")}</Typography>
                    {/*<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>*/}
                    {/*    Arcese*/}
                    {/*</Typography>*/}
                    {/*<IconButton color="inherit">*/}
                    {/*    <Badge badgeContent={4} color="secondary">*/}
                    {/*        <NotificationsIcon/>*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <IconButton ref={profileBtnAnchorRef}
                                color="inherit"
                                onClick={flipProfileMenu}
                                aria-controls={profileMenuOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true">
                        <Person/>
                    </IconButton>
                    <Popper open={profileMenuOpen} anchorEl={profileBtnAnchorRef.current} role={undefined}
                            transition
                            disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={closeProfileMenu}>
                                        <MenuList id="menu-list-grow">
                                            <MenuItem onClick={goToDashboard}><Dashboard/>&nbsp;{t("dashboard")}
                                            </MenuItem>
                                            {/*<MenuItem onClick={goToPasswordChange}><Password/>&nbsp;{t("change_password")}*/}
                                            {/*</MenuItem>*/}
                                            <MenuItem onClick={goToSettings}><Settings/>&nbsp;{t("settings")}</MenuItem>
                                            <MenuItem onClick={askLogout}><ExitToApp/>&nbsp;{t("logout")}</MenuItem>

                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <IconButton ref={languageBtnAnchorRef}
                                color="inherit"
                                onClick={openLanguageSelector}
                                aria-controls={profileMenuOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true">
                        <Language/>
                    </IconButton>
                    <Popper open={isLanguageOpened} anchorEl={languageBtnAnchorRef.current} role={undefined}
                            transition
                            disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => setLanguageOpened(false)}>
                                        <MenuList id="menu-list-grow">
                                            <MenuItem button onClick={() => {
                                                changeLanguage('it')
                                            }}>

                                                <Flag classname={classes.flag} code={"ITA"} height="22"
                                                      width="22"/>&nbsp;&nbsp;
                                                <ListItemText primary={t("italian")}/>
                                            </MenuItem>
                                            <MenuItem button onClick={() => {
                                                changeLanguage('en')
                                            }}>
                                                <Flag classname={classes.flag} code={"GBR"} height="22"
                                                      width="22"/>&nbsp;&nbsp;
                                                <ListItemText primary={t("english")}/>
                                            </MenuItem>
                                            <MenuItem button onClick={() => {
                                                changeLanguage('es')
                                            }}>
                                                <Flag classname={classes.flag} code={"ESP"} height="22"
                                                      width="22"/>&nbsp;&nbsp;
                                                <ListItemText primary={t("spanish")}/>
                                            </MenuItem>

                                            <MenuItem button onClick={() => {
                                                changeLanguage('de')
                                            }}>

                                                <Flag classname={classes.flag} code={"DE"} height="22"
                                                      width="22"/>&nbsp;&nbsp;
                                                <ListItemText primary={t("german")}/>
                                            </MenuItem>

                                            <MenuItem button onClick={() => {
                                                changeLanguage('fr')
                                            }}>

                                                <Flag classname={classes.flag} code={"FRA"} height="22"
                                                      width="22"/>&nbsp;&nbsp;
                                                <ListItemText primary={t("french")}/>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <BackofficeMenu itemWidth={drawerWidth}/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container className={classes.container}>
                    <Switch>
                        <PrivateRoute exact path={path}>
                            {t("dashboard")}
                        </PrivateRoute>
                        <PrivateRoute path={`/roles`}>
                            <RolesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/users`}>
                            <UsersTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/accounts`}>
                            <AccountsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/contracts`}>
                            <ContractsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/sub-contracts`}>
                            <SubContractsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/rates`}>
                            <RatesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/drivers`}>
                            <DriverTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/handhelds`}>
                            <HandheldTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/truck-load`}>
                            <TruckLoadPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/vehicles`}>
                            <VehicleTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/booking-queue`}>
                            <BookingQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/parcels-queue`}>
                            <ParcelsQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/shipments`}>
                            <ShipmentsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/pickups`}>
                            <PickupsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/warehouse-storages`}>
                            <WarehouseStoragesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/warehouses`}>
                            <WarehousesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/countries`}>
                            <CountriesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/cities`}>
                            <CitiesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/hamlets`}>
                            <HamletsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/postal-codes`}>
                            <PostalCodesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/customer-pre-invoices`}>
                            <CustomerPreInvoicesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/supplier-pre-invoices`}>
                            <SupplierPreInvoicesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/tax-queue`}>
                            <ActiveTaxQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/passive-tax-queue`}>
                            <PassiveTaxQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/cost-centers`}>
                            <CostCenterTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/tax-rates`}>
                            <TaxRateTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/ledger-accounts`}>
                            <LedgerAccountTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/districts`}>
                            <DistrictsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/zones`}>
                            <ZoneTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/extraction`}>
                            <PostalCodeExtraction/>
                        </PrivateRoute>
                        <PrivateRoute path={`/timings`}>
                            <TimingsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/tasks-queue`}>
                            <TaskQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/service-levels`}>
                            <ServiceLevels/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/routing-rules/districts`}>
                            <DistrictsRoutingRuleTable/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/routing-rules/zones`}>
                            <ZonesRoutingRuleTable/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/routing-rules/timings`}>
                            <TimingsRoutingRuleTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/slots`}>
                            <SlotsTable/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/driver-work-lists`}>
                            <DriverWorkListsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/driver-work-lists/save`}>
                            <NewDriverWorkListPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/driver-work-lists/confirm`}>
                            <ConfirmDelayedDriverWorkListPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/driver-work-lists/add`}>
                            <AddToDriverWorkListPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/driver-work-lists/close`}>
                            <CloseDriverWorkListPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/arrivals`}>
                            <ArrivalsPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/warehouse-check`}>
                            <WarehouseCheckPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/trips`}>
                            <TripsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/departures`}>
                            <DeparturesPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/summaries`}>
                            <SummaryOutPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/routes`}>
                            <RoutesTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/delete-status`}>
                            <DeleteStatusPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/customers-missing`}>
                            <CustomersMissingShipmentsPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/warehouse-missing`}>
                            <WarehouseMissingShipmentsPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/cods/verify`}>
                            <VerifyCodsPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/cods/supplier-report`}>
                            <SupplierReportPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/settings`}>
                            <SettingsPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/change-password`}>
                            <PasswordChangePage/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/cheque-forwarding`}>
                            <ChequeForwardingTable/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/cheque-forwarding/new`}>
                            <NewChequeForwarding/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/cheque-forwarding/verify`}>
                            <VerifyChequeForwarding/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/cheque/payments`}>
                            <ChequePaymentsToCustomersTable/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/cheque/payments/new`}>
                            <NewChequePaymentToCustomerPage/>
                        </PrivateRoute>
                        <PrivateRoute exact path={`/payments`}>
                            <PaymentSlipsTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/payments/new`}>
                            <PaymentSlipForm/>
                        </PrivateRoute>
                        <PrivateRoute path={`/payments/verify`}>
                            <VerifyPaymentSlipPage/>
                        </PrivateRoute>
                        <PrivateRoute path={`/payments/customer`}>
                            <PaymentsToCustomerTable/>
                        </PrivateRoute>
                        <PrivateRoute path={`/planning-and-control`}>
                            <PlanningAndControl/>
                        </PrivateRoute>
                        <PrivateRoute path={"/driver-work-lists/:id/document"}>
                            <DriverWorkListDocumentPage/>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/round-details"}>
                            <RoundDetails/>
                        </PrivateRoute>
                        <PrivateRoute path={"/loading-lists"}>
                            <LoadingListTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/pudo"}>
                            <PudoTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/bank-transactions"}>
                            <BankTransactionTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/import-from-cbi"}>
                            <ImportFromCbiPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/address-change-requests"}>
                            <AddressChangeRequestTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/delete-cod-event-requests"}>
                            <DeleteCodEventRequestTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/primaedicola-queue"}>
                            <PrimaEdicolaQueueTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/warehouse-collection"}>
                            <WarehouseCollectionTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/ratings"}>
                            <RatingPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/rating-options"}>
                            <RatingOptionTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/sales-orders"}>
                            <SalesOrdersPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/costs-orders"}>
                            <CostsOrdersPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/trips-orders"}>
                            <TripsOrdersPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/import-shipments"}>
                            <ImportShipmentFromCsvPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/check-in-departures"}>
                            <CheckInDeparturesPage/>
                        </PrivateRoute>
                        <PrivateRoute path={"/payment-cards"}>
                            <PaymentCardTable/>
                        </PrivateRoute>
                        <PrivateRoute path={"/payment-cards/new"}>
                            <PaymentCardForm/>
                        </PrivateRoute>
                        <PrivateRoute path={"/driver-work-lists/optimize"}>
                            <OptimizeDriverWorkList/>
                            {/*<MapWithMarkersAndPolyline/>*/}
                        </PrivateRoute>
                        <PrivateRoute path={"/month_extraction"}>
                            <MonthExtraction/>
                        </PrivateRoute>
                    </Switch>
                </Container>
            </main>

        </div>

    );
}