import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CityService from "./CityService";

function City(props){

    const city = props.city;
    const level1 = props.level1;
    const level2 = props.level2;
    const level3 = props.level3;

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    const {t} = useTranslation();

    function editCity(cityId){
        setAnchorEl(null);
        history.push(`/cities/${cityId}/edit/`);
    }

    function deleteCity(cityId){
        let cityService = new CityService();
        cityService.deleteCity(cityId)
            .then(() => {
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then((response) => {
                });
            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    useEffect(()=>{

    },[])

    return <TableRow>
        <TableCell >{city.name}</TableCell>
        {level3.map(admin3 => {
            if(admin3.id === city.level_3_id){
                return <TableCell className={classes.cell}>{admin3.name}</TableCell>
            }
        })}
        {level2.map(admin2 => {
            if(admin2.id === city.level_2_id){
                return <TableCell className={classes.cell}>{admin2.name}</TableCell>
            }
        })}
        {level1.map(admin1 => {
            if(admin1.id === city.level_1_id){
                return <TableCell className={classes.cell}>{admin1.name}</TableCell>
            }
        })}
        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editCity(city.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

            <MenuItem onClick={() => deleteCity(city.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>


    </TableRow>

}export default City;