import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
import TimingService from "./TimingService";
import ContractService from "../accounts/contracts/ContractService";
import WarehouseService from "../warehouses/WarehouseService";

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    container: {
        width: '100%',
        maxHeight: 840,

    },
    table: {
        width: '75%',
        margin: "auto"

    },
    cell: {
        margin: "auto"

    }
});


export default function Timing(props) {
    let timing = props.timing;

    const {t} = useTranslation();

    const [contract, setContract] = useState(null);
    const [warehouse, setWarehouse] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const classes = useStyles();

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function editTiming(timingId) {
        setAnchorEl(null);
        history.push(`/timings/${timingId}/edit/`);
    }

    function deleteTiming(timingId) {
        let timingService = new TimingService();
        timingService.deleteTiming(timingId)
            .then(() => {
                props.deleteCallback();
            });
    }

    async function fetchContract(contractId) {
        const contractService = new ContractService();
        const contract = await contractService.getContractById(contractId);
        setContract(contract);
    }

    async function fetchWarehouse(warehouseId) {
        if(!warehouseId) {
            setWarehouse(null);
            return;
        }

        const warehouseService = new WarehouseService();
        const warehouse = await warehouseService.getWarehouseById(warehouseId);
        setWarehouse(warehouse);
    }

    useEffect(() => {
        fetchContract(timing.contractId);
        fetchWarehouse(timing.warehouseId);
    }, []);

    return <TableRow>
        <TableCell>
            {contract ? contract.code + " - " + contract.name : ""}
        </TableCell>
        <TableCell>
            {warehouse ? warehouse.code + " - " + warehouse.name : ""}
        </TableCell>
        <TableCell>
            {timing.type}
        </TableCell>
        <TableCell>
            {timing.timing}
        </TableCell>
        <TableCell>
            {timing.routingRules.map(routingRule => {
                return <div>
                    <Typography variant={"caption"}>{routingRule.name}</Typography>
                </div>
                // return <div>
                //     <Typography variant={"caption"}>{routingRule.service}</Typography>
                //     <Typography variant={"caption"}>{" - "}</Typography>
                //     <Typography variant={"caption"}>{routingRule.country}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel1 ? ", " + routingRule.administrativeLevel1 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel2 ? ", " + routingRule.administrativeLevel2 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel3 ? ", " + routingRule.administrativeLevel3 : ""}</Typography>
                //     <Typography variant={"caption"}>{routingRule.city ? ", " + routingRule.city : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.postalCode ? ", " + routingRule.postalCode : ""}</Typography>
                // </div>
            })}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editTiming(timing.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>
            <MenuItem onClick={() => deleteTiming(timing.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>
    </TableRow>
}