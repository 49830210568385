import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

const userService  = new UserService();

export default class TrackingService {


    getShipmentTracking(trackingId, guestKey) {

        const apiKey = userService.getApiKey();

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if(apiKey){
            headers.Authorization = 'Bearer ' + apiKey;
        }

        let requestOptions = {
            method: "GET",
            headers: headers
        };

        let queryString = ``;


        if (null != guestKey) {
            queryString += `key=${guestKey}`;
        }

        let pathVariable = `/api/v1/tracking/${trackingId}`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

}