import {IconButton, Popover, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import NoteTooltip from "../components/NoteTooltip";
import {useTranslation} from "react-i18next";
import {formatDateTime} from "../utils/utils";
import {Place} from "@material-ui/icons";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import variables from "../configuration/variables.json";
import {DialogTitle} from "@mui/material";

function ParcelEvent(props) {

    let event = props.event;
    let warehouses = props.warehouses;
    let eventCodes = props.eventCodes;
    let drivers = props.drivers

    let destination = props.destination;

    const [waypoint, setWaypoint] = useState(null);
    const [mapAnchorEl, setMapAnchorEl] = useState(null);
    const [noteAnchorEl, setNoteAnchorEl] = useState(null);

    const open = Boolean(mapAnchorEl);
    const openNote = Boolean(noteAnchorEl);

    function openMiniMap(event) {
        setMapAnchorEl(event.currentTarget);
    }

    function closeMiniMap() {
        setMapAnchorEl(null);
    }

    function getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
        let R = 6371; // Radius of the earth in km
        let dLat = deg2rad(lat2 - lat1);  // deg2rad below
        let dLon = deg2rad(lon2 - lon1);
        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        // Distance in km
        return R * c * 1000;
    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    const {t} = useTranslation();

    useLoadScript({
        googleMapsApiKey: variables.googleAPIKey // ,
        // ...otherOptions
    })

    const renderMap = () => {
        return <GoogleMap
            zoom={10}
            center={{lat: event.latitude, lng: event.longitude}}
            mapContainerStyle={{width: 500, height: 300}}
        >
            <Marker key={"destination"}
                    position={{lat: destination.latitude, lng: destination.longitude}}
            />
            <Marker key={"waypoint"}
                    position={{lat: event.latitude, lng: event.longitude}}
                    icon={{url: '/icons/truckMarker.png', scale: 7}}
            />

        </GoogleMap>

    }

    useEffect(() => {

        if (null !== event.latitude && null !== event.longitude) {
            setWaypoint({latitude: event.latitude, longitude: event.longitude});
        }

    }, [event.latitude, event.longitude])

    return <TableBody>
        <TableRow>
            <TableCell style={{color: event.deleted ? "grey" : null, fontStyle: event.deleted ? "italic" : null}}>
                <Typography>{formatDateTime(event.dateTime)}</Typography>
            </TableCell>
            <TableCell style={{color: event.deleted ? "grey" : null, fontStyle: event.deleted ? "italic" : null}}>
                <Typography>
                    {event.eventCodeId ? eventCodes[event.eventCodeId].id + " - " + eventCodes[event.eventCodeId].name : ""}
                </Typography>
                <Typography>
                    {event.destinationWarehouseName ? t("departing_to") + event.destinationWarehouseName : ""}
                </Typography>
                <Typography>
                    {event.tripId ? t("trip") + " " + event.tripId : ""}
                </Typography>
                <Typography variant={"subtitle2"} color={event.deleted ? "grey" : "primary"}>
                    {event.driverId && drivers[event.driverId] ? drivers[event.driverId].name + " " + drivers[event.driverId].surname : ""}
                </Typography>
                {event.deleted && event.deletionUser !== null ?
                    <Typography>{t("deleted_by") + " " + event.deletionUser}</Typography>
                    :
                    null
                }
            </TableCell>
            <TableCell style={{
                color: event.deleted ? "grey" : null,
                fontStyle: event.deleted ? "italic" : null
            }}>
                {waypoint ?
                    <div>
                        <IconButton
                            onClick={openMiniMap}
                            aria-label="first page"
                        >
                            <Place/>
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={mapAnchorEl}
                            onClose={closeMiniMap}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <DialogTitle><Typography variant={"subtitle2"}
                                                     style={{fontSize: 16}}>{t("event_destination_distance") + getDistanceFromLatLonInM(destination.latitude, destination.longitude, event.latitude, event.longitude).toFixed(0) + "m"}</Typography></DialogTitle>
                            {renderMap()}
                        </Popover>
                    </div>

                    :
                    null
                }
            </TableCell>
            <TableCell style={{
                color: event.deleted ? "grey" : null,
                fontStyle: event.deleted ? "italic" : null
            }}> {event.warehouseName}</TableCell>
            <TableCell style={{
                color: event.deleted ? "grey" : null,
                fontStyle: event.deleted ? "italic" : null
            }}> {event.notes ?
                <NoteTooltip>{event.notes}</NoteTooltip>
                : null
            }</TableCell>
        </TableRow>
    </TableBody>


}

export default ParcelEvent;