import React, {useEffect, useState} from "react";
import AccountService from "../accounts/AccountService";
import WarehouseService from "../warehouses/WarehouseService";
import {Autocomplete, Button, DialogTitle, Paper, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import DateField from "../components/fields/DateField";
import theme from "../theme/theme";
import {ArrowBackRounded, SaveOutlined} from "@material-ui/icons";
import moment from "moment";
import PaymentCardService from "./PaymentCardService";
import Notification from "../components/notifications/Notification";
import {LoadingComponent} from "../components/loading/LoadingComponent";


export default function PaymentCardForm(props) {

    const {id} = useParams();

    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [cardNumber, setCardNumber] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [releaseDate, setReleaseDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notifySeverity, setNotifySeverity] = useState(null);
    const [openNotify, setOpenNotify] = useState(false);

    async function fetchSuppliers() {

        let filters = {};
        // I want to see both customers and accounts
        filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchWarehouses(all) {

        // setLoading(true);

        setWarehouses([]);

        let filters = {};

        if (all) {
            filters.showAll = all;
        }

        if (supplier) {
            filters.accountId = supplier?.id;
        }


        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setWarehouses(result.warehouses)
                if (result.warehouses?.length === 1) {
                    setWarehouse(result.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

        // setLoading(false);
    }

    async function fetchCardData() {

        setLoading(true);

        if (id) {

            await new PaymentCardService().getCardById(id)
                .then(async response => {

                    await new AccountService().getAccounts({accountCorporateCode: response.accountCorporateCode})
                        .then(result => {
                            setSupplier(result[0])
                        })
                        .catch(error => {

                        })

                    await new WarehouseService().getWarehousesByFilters(null, null, {code: response.warehouseCode})
                        .then(result => {
                            setWarehouse(result.warehouses[0]);
                        })
                        .catch(error => {

                        })

                    setCardNumber(response.cardNumber);
                    setName(response.name);
                    setSurname(response.surname);
                    setReleaseDate(response.releaseDate);
                    setExpirationDate(response.expirationDate);

                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }
        setLoading(false);

    }

    function handleReleaseDateChange(event) {
        setReleaseDate(event)
    }

    function handleExpirationDateChange(event) {
        setExpirationDate(event);
    }


    async function savePaymentCard() {

        if ('' === cardNumber || '' === name || '' === surname || !releaseDate || !expirationDate || !supplier || !warehouse) {
            setNotificationMessage(t("void_required_fields"));
            setNotifySeverity('error');
            setOpenNotify(true);
            return;
        }

        let input = {};

        input.cardNumber = cardNumber;
        input.name = name;
        input.surname = surname;
        if (releaseDate) {
            input.releaseDate = moment(releaseDate).format("yyyy-MM-DD");
        }
        if (expirationDate) {
            input.expirationDate = moment(expirationDate).format("yyyy-MM-DD");
        }
        input.supplierId = supplier?.id;
        input.warehouseId = warehouse?.id;


        if (id) {
            await new PaymentCardService().updateCard(id, input)
                .then(result => {
                    setNotificationMessage(t("successful"));
                    setNotifySeverity('success');
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push("/payment-cards")
                    }, 2000)

                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        } else {
            await new PaymentCardService().saveCard(input)
                .then(result => {
                    setNotificationMessage(t("successful"));
                    setNotifySeverity('success');
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push("/payment-cards")
                    }, 2000)

                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }


    }

    useEffect(() => {
        if (id) {
            fetchSuppliers();
            fetchWarehouses(false);
        }
        fetchCardData();
    }, [])


    return <Paper style={{display: "block"}}>
        {loading ?
            <LoadingComponent/>
            :
            <div>
                <div style={{display: "flex"}}>
                    <Button
                        style={{margin: "2%", background: "transparent"}}
                        onClick={() => history.goBack()}
                        startIcon={<ArrowBackRounded/>}
                        variant={"filled"}
                    >
                        {t("back")}
                    </Button>

                </div>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>
                    {t("new_payment_card")}
                </DialogTitle>

                <div style={{display: "flex", marginTop: "5%"}}>
                    <TextField
                        style={{margin: "auto", width: "25%"}}
                        label={t("card_number")}
                        size={"small"}
                        variant="outlined"
                        margin="dense"
                        value={cardNumber}
                        defaultValue={cardNumber}
                        onChange={(event) => setCardNumber(event.target.value)}
                        required
                        error={!cardNumber}

                    />
                </div>
                <div style={{display: "flex", marginTop: "2%"}}>
                    <TextField
                        style={{margin: "auto"}}
                        label={t("name")}
                        size={"small"}
                        variant="outlined"
                        margin="dense"
                        value={name}
                        defaultValue={name}
                        onChange={(event) => setName(event.target.value)}
                        required
                        error={!name}
                    />
                    <TextField
                        style={{margin: "auto"}}
                        label={t("surname")}
                        size={"small"}
                        variant="outlined"
                        margin="dense"
                        value={surname}
                        defaultValue={surname}
                        onChange={(event) => setSurname(event.target.value)}
                        required
                        error={!surname}
                    />
                </div>
                <div style={{display: "flex", marginTop: "2%"}}>
                    <DateField
                        style={{margin: "auto", width: "15%"}}
                        label={t("release_date")}
                        value={releaseDate}
                        onChange={handleReleaseDateChange}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                        required
                        error={!releaseDate}
                    />
                    <DateField
                        style={{margin: "auto", width: "15%"}}
                        label={t("expiration_date")}
                        value={expirationDate}
                        onChange={handleExpirationDateChange}
                        format={"dd/MM/yyyy"}
                        required
                        error={!expirationDate}
                    />
                </div>
                <div style={{display: "flex", marginTop: "2%"}}>
                    <Autocomplete
                        id="supplier"
                        size="small"
                        style={{marginInline: "auto", width: "25%"}}
                        options={accounts}
                        getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                        value={supplier}
                        defaultValue={supplier}
                        noOptionsText={t("no_options")}
                        onOpen={fetchSuppliers}
                        onChange={(event, supplier) => {setSupplier(supplier); setWarehouse(null)}}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("supplier")}
                                variant="outlined"
                                margin="dense"
                                value={supplier}
                                defaultValue={supplier}
                                required
                                error={!supplier}
                            />
                        )}
                    />
                </div>
                <div style={{display: "flex", marginTop: "2%"}}>
                    <Autocomplete
                        id="warehouse"
                        size="small"
                        style={{marginInline: "auto", width: "25%"}}
                        disabled={!supplier}
                        options={warehouses}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        noOptionsText={t("no_options")}
                        onOpen={() => fetchWarehouses(false)}
                        onChange={(event, warehouse) => setWarehouse(warehouse)}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("warehouse")}
                                variant="outlined"
                                margin="dense"
                                value={warehouse}
                                defaultValue={warehouse}
                                required
                                error={!warehouse}
                            />
                        )}
                    />
                </div>

                <div style={{display: "flex"}}>
                    <Button
                        style={{
                            margin: "auto",
                            marginBlock: "2%",
                            backgroundColor: theme.palette.primary.main
                        }}
                        startIcon={<SaveOutlined/>} variant="contained" onClick={savePaymentCard}
                        color="primary">
                        {t("save")}
                    </Button>
                </div>
            </div>
        }

        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>

    </Paper>

}