import React, {useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";

function Rate(props) {
    let rate = props.rate;

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function editRate(rateId) {
        setAnchorEl(null);
        history.push(`/rates/${rateId}/edit`);
    }

    return <TableRow>
        <TableCell>{rate.id}</TableCell>
        <TableCell>{rate.code}</TableCell>
        <TableCell>{rate.currencyCode}</TableCell>

        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editRate(rate.id)}><EditIcon/>{t("edit")}</MenuItem>

        </Menu>
    </TableRow>
}

export default Rate;