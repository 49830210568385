import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TableRow} from "@mui/material";
import {TableCell, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import RouteService from "../departures/routes/RouteService";

export default function RateServiceRouteForm(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.route);
    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(null);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    useEffect(() => {
        async function fetchRoutes() {
            let routeService = new RouteService();
            const restRoutes = await routeService.getRoutes(null);
            setRoutes(restRoutes.routes);

            if (state?.routeId) {
                for (let i = 0; i < restRoutes.routes.length; ++i) {
                    if (state.routeId === restRoutes.routes[i].id) {
                        setSelectedRoute(restRoutes.routes[i]);
                    }
                }
            }
        }

        fetchRoutes();
    }, []);

    function handleRouteChange(event, route) {
        setSelectedRoute(route);

        const newState = {
            ...state,
            routeId: route?.id,
        };
        setNewState(newState);
    }

    return <TableRow>
        <TableCell>
            <Autocomplete
                id="routeId"
                value={selectedRoute}
                options={routes}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleRouteChange}
                // onBlur={fetchAdminL1}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("route")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell/>
    </TableRow>;
}