import {Backdrop, Button, Fade, Input, Menu, MenuItem, Modal, TableCell, TableRow, Tooltip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import {Upload} from "@mui/icons-material";
import styled from "@emotion/styled";
import {Paper, Typography} from "@mui/material";
import ContractService from "./ContractService";
import Notification from "../../components/notifications/Notification";
import {useHistory} from "react-router-dom";
import {useStyles} from "../../theme/useStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";

function Contract(props) {
    const contract = props.contract;
    const {t} = useTranslation();

    const classes = useStyles();
    const history = useHistory();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [logo, setLogo] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);

    const Input = styled('input')({
        display: 'none',
    });

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    async function deleteLogo(contract) {
        const contractService = new ContractService();
        await contractService.deleteLogo(contract.id)
            .catch(error => {
                setNotifySeverity('error');
                setNotificationMessage(error);
                setOpenNotify(true);
                setRefresh(!refresh);
                handleMenuClose();
            });

        setLogo(null);
        setOpenConfirmDialog(false);
        setRefresh(!refresh);
        handleMenuClose();
    }

    function editContract(contract) {
        history.push(`/contracts/${contract.id}/edit`);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function fetchLogo() {
        let contractService = new ContractService();

        contractService.getLogo(contract.id)
            .then(response => {
                setLogo(response);
            })
            .catch(error => {

            })
    }

    const LogoImg = ({data, alt}) => <img style={{margin: 'auto'}}
                                          src={`data:image/jpeg;base64,${data}`} alt={alt}/>

    async function readFile(event) {

        let _file = event.target.files[0];

        let dataURI = await get_file_array(_file);

        let indexOfComma = dataURI.indexOf(',');

        let base64;

        if (indexOfComma === -1) {
            //TODO gestire errore
        } else {
            base64 = dataURI.substring(indexOfComma + 1);
        }

        let contractService = new ContractService();


        contractService.uploadLogo(contract.id, base64, _file.type)
            .then(response => {
                setNotifySeverity('success');
                setNotificationMessage('successful');
                setOpenNotify(true);
                setRefresh(!refresh);
                handleMenuClose();

            })
            .catch((err) => {
                err.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setRefresh(!refresh);
                    handleMenuClose();
                });
            })


    }

    function get_file_array(file) {
        return new Promise(resolve => {

            let baseURL = "";

            const reader = new FileReader();
            // reader.onload = (event) => { acc(event.target.result) };
            reader.onload = () => {
                // Make a fileInfo Object

                baseURL = reader.result;
                resolve(baseURL);
            };
            reader.onerror = (err) => {
                err(err)
            };
            reader.readAsDataURL(file);
        });
    }

    useEffect(() => {
        fetchLogo();
    }, [refresh])

    return <TableRow>
        <TableCell>
            {contract.code}
        </TableCell>
        <TableCell>
            {contract.name}
        </TableCell>
        <TableCell>
            {contract.accountCorporateCode}
        </TableCell>
        <TableCell>
            {new Date(contract.validFrom).toLocaleDateString()}
        </TableCell>
        <TableCell>
            {contract.validUntil ? new Date(contract.validUntil).toLocaleDateString() : "---"}
        </TableCell>
        {/*<TableCell>*/}
        {/*    {contract.companyId}*/}
        {/*</TableCell>*/}
        <TableCell style={{width: 300, height: 60}}>
            {logo ?
                <LogoImg data={logo.content}
                         alt={logo.fileName}/>
                :
                t("no_logo")
            }
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem>
                <Input accept="image/png" id={"icon-button-file" + contract.id} type="file" onInput={readFile}/>
                <label htmlFor={"icon-button-file" + contract.id} style={{display: "flex"}}>
                    <Upload/>
                    <Tooltip title={t("max_dimension_and_allowed_type")} placement="bottom-start">
                        <Typography variant={"subtitle2"}
                                    style={{marginLeft: "4%"}}> {t("upload_logo")} </Typography>
                    </Tooltip>
                </label>
            </MenuItem>
            {logo ?
                <MenuItem onClick={() => setOpenConfirmDialog(true)}><DeleteIcon
                    className={classes.icon}/>
                    <Typography variant={"subtitle2"}
                                style={{marginLeft: "4%"}}> {t("delete_logo")} </Typography>
                </MenuItem>
                : null}
            <MenuItem onClick={() => editContract(contract)}><EditIcon className={classes.icon}/>
                <Typography variant={"subtitle2"}
                            style={{marginLeft: "4%"}}> {t("edit")} </Typography>
            </MenuItem>
        </Menu>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

        <Modal open={openConfirmDialog}
            // className={classes.modal}
               onClose={() => setOpenConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openConfirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        {/*<WarningOutlined color={"warning"}/>*/}
                        <Typography
                            style={{margin: "auto"}}>{t("question_mark_open") + t("confirm_delete_logo") + "?"}</Typography>
                        {/*<WarningOutlined color={"warning"}/>*/}
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={() => deleteLogo(contract)}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>
        </Modal>

    </TableRow>
}

export default Contract;