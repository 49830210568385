import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

const userService = new UserService();

export default class ArrivalService{


    saveArrival(request){


        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body :JSON.stringify({
                barcode: request.barcode,
                warehouseCode: request.warehouseCode
            })
        };
        let pathVariable = `/api/v1/arrival`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


}