import React, {useEffect, useState} from "react";
import ChequeForwardingService from "./ChequeForwardingService";
import {
    Autocomplete,
    Card,
    CircularProgress,
    DialogTitle,
    TableBody,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {Button, Checkbox, Divider, Slide, Table, TableCell, TableHead, TablePagination} from "@material-ui/core";
import DateField from "../../components/fields/DateField";
import {CheckCircleOutline, CheckOutlined, ErrorOutline, SearchOutlined} from "@material-ui/icons";
import InfoChip from "../../components/chips/InfoChip";
import {AppStyles} from "../../theme/AppStyles";
import {useTranslation} from "react-i18next";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import CodService from "../../shipments/services/CodService";
import moment from "moment";


function VerifyChequeForwarding(props) {


    const classes = AppStyles();

    const {t} = useTranslation();

    const [chequesForwarding, setChequesForwarding] = useState([]);
    const [chequeForwarding, setChequeForwarding] = useState(null);

    const [fetched, setFetched] = useState(false);

    const [cods, setCods] = useState([]);

    const [allChecked, setAllChecked] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [shipmentIdFilter, setShipmentIdFilter] = useState('');

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const collectionEventCodes = [2, 3, 4, 5, 6, 7];

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleSupplierChange(event, supplier) {
        setSupplier(supplier);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleChequeForwardingChange(event, cheque) {
        setChequeForwarding(cheque);
        setCods([]);
    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);

        let rows = cods
            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)


        for (let i = 0; i < rows.length; i++) {
            rows[i].checked = !!event.target.checked;
        }

        setRefresh(!refresh);

    }

    function handleShipmentFilterChange(event) {
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setShipmentIdFilter(amount);
        }
    }


    async function fetchCheques() {

        let filters = {};
        filters.forAdminCenterVerification = true;

        if (supplier) {
            filters.accountId = supplier.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (dateFrom) {
            filters.dateFrom = moment(dateFrom).format('yyyy-MM-DD');
        }

        if (dateTo) {
            filters.dateTo = moment(dateTo).format('yyyy-MM-DD');
        }

        await new ChequeForwardingService().getChequeForwardingByFilters(filters)
            .then(result => {
                setChequesForwarding(result.chequesForwarding);
            })
    }

    async function fetchSuppliers() {

        let filters = {};
        filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(response => {
                setSuppliers(response);
            })
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = supplier.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }

    async function fetchCods() {

        let filters = {};

        filters.chequeForwardingId = chequeForwarding?.id
        filters.isAdminCenterVerified = false;

        await new CodService().getCods(filters)
            .then(result => {

                let data = [];

                for (let i = 0; i < result.length; i++) {
                    let codData = {};
                    codData.checked = true;
                    codData.cod = result[i];

                    data.push(codData);
                }

                setAllChecked(true);

                setCods(data);
                setLoading(false);
                setFetched(true);
                setRefresh(!refresh);
            })
            .catch(error => {

            })
    }

    function setAllPending(bool) {

        cods.forEach(value => value.pending = bool);
    }

    async function verify() {

        let codIds = [];

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked === true) {

                let newStatus = [...cods];
                newStatus[i].pending = true;
                setCods(newStatus);

                codIds.push(cods[i].cod.id);

                setRefresh(!refresh);

            }
        }

        let input = {};

        input.cods = codIds;

        let output = [];

        await new CodService().verifyInAdminCenter(input)
            .then(response => {
                output = response;

                for (let i = 0; i < cods.length; i++) {
                    for (let j = 0; j < output.length; j++) {
                        if (output[j].codId === cods[i].cod.id) {

                            let newCod = [...cods];
                            newCod[i].pending = false;
                            newCod[i].verified = false;
                            newCod[i].errorMessage = output[j].warning.code + " - " + output[j].warning.message;
                            setCods(newCod);

                            // cods[i].pending = false;
                            // cods[i].verified = false;
                            // cods[i].errorMessage = output[j].warning.code + " - " + output[j].warning.message;

                            setRefresh(!refresh);
                        }
                    }

                }

                for (let i = 0; i < cods.length; i++) {
                    if (cods[i].pending) {

                        let newCod = [...cods];
                        newCod[i].pending = false;
                        newCod[i].verified = true;

                        setCods(newCod);

                        setTimeout(() => {

                            let newCod = [...cods];
                            newCod[i].fadeOut = true;

                            setCods(newCod);

                            setRefresh(!refresh);

                        }, 1000)

                    }

                    cods[i].checked = false;
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })

                setAllPending(false);
            })

        setRefresh(!refresh);

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleChecked(event, key) {

        cods
            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].checked = event.target.checked;

        if (cods.some(row => row.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }


    useEffect(() => {

    }, [refresh, page, rowsPerPage]);


    return <div className={classes.root}>


        <Card>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("verify_cheque_forwarding")}</DialogTitle>
            <div className={classes.select_div}>
                <Autocomplete
                    id="customer"
                    size="small"
                    options={suppliers}
                    className={classes.select}
                    getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                    value={supplier}
                    defaultValue={supplier}
                    noOptionsText={t("no_options")}
                    onOpen={fetchSuppliers}
                    onChange={handleSupplierChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("supplier")}
                            variant="outlined"
                            margin="dense"
                            value={supplier}
                            defaultValue={supplier}
                        />
                    )}
                />
                <Autocomplete
                    id="contract-select"
                    size="small"
                    disabled={!supplier}
                    options={contracts}
                    className={classes.select}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={contract}
                    defaultValue={contract}
                    noOptionsText={t("no_options")}
                    onOpen={fetchContracts}
                    onChange={handleContractChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contract")}
                            variant="outlined"
                            margin="dense"
                            value={contract}
                            defaultValue={contract}
                        />
                    )}
                />
            </div>
            <Divider className={classes.divider}/>
            <div style={{display: "flex", margin: "auto", width: "90%"}}>
                <DateField
                    className={classes.select}
                    label={t("payment_date_from")}
                    value={dateFrom}
                    onChange={handleDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
                <DateField
                    className={classes.select}
                    label={t("payment_date_to")}
                    value={dateTo}
                    onChange={handleDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
            </div>
            <div className={classes.select_div}>
                <Autocomplete
                    id="chequesForwarding"
                    size="small"
                    className={classes.select}
                    options={chequesForwarding}
                    getOptionLabel={option => option.id + " " + option.warehouseCode + " " + t("of") + new Date(option.createdAt).toLocaleDateString()}
                    value={chequeForwarding}
                    defaultValue={chequeForwarding}
                    noOptionsText={t("no_options")}
                    onOpen={fetchCheques}
                    onChange={handleChequeForwardingChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("cheque_forwarding")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!chequeForwarding}
                            value={chequeForwarding}
                            defaultValue={chequeForwarding}
                        />
                    )}
                />
            </div>
            <Button disabled={!chequeForwarding} startIcon={loading ? <CircularProgress size={20}/> : <SearchOutlined/>}
                    className={classes.button} variant="contained"
                    color="primary" type="button" onClick={fetchCods}>
                {t("search")}
            </Button>
        </Card>
        {fetched ?
            <div>
                {cods.length > 0 ?
                    <div>
                        <Table stickyHeader style={{width: "95%", margin: "auto", marginTop: "2%"}}>

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox color="primary"
                                                  onChange={handleAllChecked}
                                                  checked={allChecked}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant={"outlined"}
                                            size={"small"}
                                            label={t("shipment")}
                                            value={shipmentIdFilter}
                                            defaultValue={shipmentIdFilter}
                                            onChange={handleShipmentFilterChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {t("contract")}
                                    </TableCell>
                                    <TableCell>
                                        {t("cod_mode")}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle2"}>{t("bank_name")}</Typography>
                                        <Typography variant={"subtitle2"}>{t("cheque_number")}</Typography>
                                        <Typography variant={"subtitle2"}>{t("cheque_date")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {t("value")}
                                    </TableCell>
                                    <TableCell>
                                        {t("collection_datetime")}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? cods
                                            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : cods
                                            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
                                ).map((data, key) => {
                                    return <Slide direction="left" in={!data.fadeOut} appear={false} unmountOnExit>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    color="primary"
                                                    onChange={event => handleChecked(event, key)}
                                                    checked={data.checked}/>
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.shipmentId}
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.contractCode}
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.events?.filter(event => collectionEventCodes.includes(event.eventCodeId))[0]?.eventCodeName}
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{data.cod.cheque?.bankName}</Typography>
                                                <Typography>{data.cod.cheque?.chequeNumber}</Typography>
                                                <Typography>{data.cod.cheque?.chequeDate ? new Date(data.cod.cheque?.chequeDate).toLocaleDateString() : null}</Typography>
                                            </TableCell>
                                            <TableCell style={{width: "10%", textAlign: "right"}}>
                                                {data.cod.currency + " " + data.cod.value.toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(data.cod.collectionDateTime).toLocaleDateString() + " - " + new Date(data.cod.collectionDateTime).toLocaleTimeString()}
                                            </TableCell>
                                            <TableCell>
                                                {data.pending === true ?
                                                    <CircularProgress color={"primary"} size={24}/> : null}
                                                {data.verified === false ?
                                                    <ErrorOutline style={{color: "red"}}/> : null}
                                                {data.verified === true ?
                                                    <CheckCircleOutline style={{color: "green"}}/> : null}
                                                <div>
                                                    <Typography variant={"caption"}
                                                                color={"red"}>{data.errorMessage}</Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </Slide>

                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            style={{width: "80%", margin: "auto"}}
                            rowsPerPageOptions={[5, 10, 25, {label: t("all"), value: -1}]}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return (t("total_selected") + ": " + cods.filter(acc => !!acc.checked)?.length)
                            }}
                            component="div"
                            count={cods.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <Button startIcon={loading ? <CircularProgress size={20}/> : <CheckOutlined/>}
                                className={classes.button} variant="contained"
                                color="primary" type="button" onClick={verify}>
                            {t("verify")}
                        </Button>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }

            </div>

            :
            null
        }
    </div>


}

export default VerifyChequeForwarding;