import {Switch} from "react-router-dom";
import React, {useState} from "react";
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {
    Card,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import ActiveTaxQueueService from "./ActiveTaxQueueService";
import Notification from "../components/notifications/Notification";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));

export default function ActiveTaxQueueTable(props) {
    const [taxQueueRecords, setTaxQueueRecords] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");

    const {t} = useTranslation();
    const classes = useStyles();

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isCustomer: true})
            .then(data => {
                setAccountList(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContractList(response);
            })

    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function clearFilters() {
        setAccount(null);
        setContract(null);
        setFromDate(null);
        setToDate(null);
    }

    function applyFilters() {

        setLoading(true);

        const filters = {};
        if (account) {
            filters.accountId = account.id;
        }
        if (contract) {
            filters.contractId = contract.id;
        }
        if (fromDate) {
            filters.fromDate = moment(fromDate).format("yyyy-MM-DD");
        }
        if (toDate) {
            filters.toDate = moment(toDate).format("yyyy-MM-DD");
        }

        const quoteService = new ActiveTaxQueueService();
        quoteService.getTaxQueueRecords(filters).then((taxQueueRecords) => {
            setTaxQueueRecords(taxQueueRecords);
            setLoading(false);
        }).catch((response) => {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(response.message);
            setLoading(false);
        });
    }

    function addToTaxQueue() {

        setLoading(true);

        const filters = {};
        if (account) {
            filters.accountId = account.id;
        }
        if (contract) {
            filters.contractId = contract.id;
        }
        if (fromDate) {
            filters.fromDate = moment(fromDate).format("yyyy-MM-DD");
        }
        if (toDate) {
            filters.toDate = moment(toDate).format("yyyy-MM-DD");
        }

        const quoteService = new ActiveTaxQueueService();
        quoteService.addToTaxQueue(filters).then(() => {
            setOpenNotify(true);
            setNotifySeverity('success');
            setNotificationMessage("Record aggiunti in coda tassazione");
            setLoading(false)
            applyFilters();

        }).catch((response) => {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(response.message);
            setLoading(false)
        });
    }

    return <Paper>



            <Switch>
                <PrivateRoute exact path={"/tax-queue"}>
                    <Card elevation={1} defaultExpanded={true}
                          style={{width: "60%", margin: "auto", marginBottom: "2%"}}>
                        <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                            <Typography variant={"button"}>{t("active_tax_queue")}</Typography>
                        </DialogTitle>
                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="account"
                                options={accountList}
                                className={classes.filter}
                                getOptionLabel={option => option.companyName}
                                value={account}
                                defaultValue={account}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchAccounts}
                                onChange={handleAccountChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("account")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={account}
                                        defaultValue={account}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="contract"
                                disabled={!account}
                                options={contractList}
                                className={classes.filter}
                                getOptionLabel={option => option.code}
                                value={contract}
                                defaultValue={contract}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchContracts}
                                onChange={handleContractChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("contract")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={contract}
                                        defaultValue={contract}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                            <Grid container justify="space-around">
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    inputVariant="outlined"
                                    label={t("manifest_from_date")}
                                    clearable
                                    value={fromDate}
                                    onChange={(value) => {
                                        setFromDate(value);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    label={t("manifest_to_date")}
                                    inputVariant="outlined"
                                    clearable
                                    value={toDate}
                                    onChange={(value) => {
                                        setToDate(value)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div style={{textAlign: 'center'}}>
                            <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                            <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
                            <Button style={{margin: "5%"}} onClick={addToTaxQueue}>{t("add_to_queue")}</Button>
                        </div>

                        {loading ?

                            <LoadingComponent/>

                            :

                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableCell>
                                            <Typography variant={"inherit"}>  {t("shipment_id")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"inherit"}>  {t("last_try")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("error_count")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("last_error_message")} </Typography>
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {taxQueueRecords.map((record, index) => {
                                            return <TableRow>
                                                <TableCell>{record.shipmentId}</TableCell>
                                                <TableCell>{record.lastDateTimeTry ? new Date(record.lastDateTimeTry).toLocaleDateString() + " " + new Date(record.lastDateTimeTry).toLocaleTimeString() : ""}</TableCell>
                                                <TableCell>{record.errorCount}</TableCell>
                                                <TableCell>{record.errorMessage}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                    </Card>
                </PrivateRoute>
            </Switch>


    </Paper>
}