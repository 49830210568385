import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserService from "../users/UserService";
import {useHistory} from "react-router-dom";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {setToLS} from "../utils/storage";
import Cookies from "universal-cookie/es6";
import moment from "moment";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    logoImg: {
        marginLeft: "24%",
        marginTop: theme.spacing(20),
        height: "50%",
        width: "50%",

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginPage() {
    const classes = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(null);
    const {t} = useTranslation();

    const userService = new UserService();

    async function onFormSubmit(e) {
        e.preventDefault();

        // Stop if form is invalid
        if (!username || !password) {
            return;
        }

        //setLoading(true);
        //login(username, password).then(() => {
        //    // store user details and basic auth credentials in local storage
        //    // to keep user logged in between page refreshes
        //    const user = window.btoa(username + ':' + password);
        //    localStorage.setItem('user', JSON.stringify(user));
        //});
        userService
            .login(username, password)
            .then(async () => {
                await userService.getUserPermissions()
                    .then(data => {
                        // localStorage.removeItem("permissions")
                        // localStorage.setItem("permissions", JSON.stringify(data));
                        setToLS("permissions", data)
                    })

                await userService.me()
                    .then(data => {
                        setToLS("user", data.username)
                    })

                const cookies = new Cookies();
                cookies.set('lastTimeStamp', moment(), {maxAge: 60*60})

                history.push("/");

            })
            .catch((err) => {
                //// setLoginError("Errore nel login");
                err.response.json().then((response) => {
                    setLoginError(response.message);
                });
            });
    }


    const onChangeUsername = (e) => {
        setUsername(e.target.value);
    };
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    if (userService.isLoggedIn()) {
        history.push("/");
    }

    return (
        <Container component="main" maxWidth="xs">
            <img alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} className={classes.logoImg}/>
            <Notification open={loginError} severity="error"
                          onClose={() => setLoginError(null)}>{loginError}</Notification>
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("reserved_area")}
                </Typography>
                <form className={classes.form} noValidate onSubmit={onFormSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={t("username")}
                        name="username"
                        autoComplete={t("username")}
                        value={username}
                        onChange={onChangeUsername}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("password")}
                        type="password"
                        id="password"
                        value={password}
                        onChange={onChangePassword}
                        autoComplete={t("password")}
                    />
                    {/*<FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t("sign_in")}
                    </Button>
                    {/*<Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>*/}
                </form>
            </div>
            {/*<Box mt={8}>
                <Copyright/>
            </Box>*/}
        </Container>
    );
}