import {makeStyles} from "@material-ui/core/styles";
import {useEffect} from "react";
import {TableCell, TableRow} from "@material-ui/core";

function LedgerAccount(props){



    let ledgerAccount = props.ledgerAccount;

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        cell: {
            width: "15%",
            paddingLeft: "3%"

        },
        IDCell: {
            width: "5%",
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    useEffect(() => {

    },[])

    return <TableRow>
        <TableCell className={classes.IDCell}>{ledgerAccount.id}</TableCell>
        <TableCell className={classes.cell}>{ledgerAccount.code}</TableCell>
        <TableCell className={classes.cell}>{ledgerAccount.name}</TableCell>
    </TableRow>

}

export default LedgerAccount;