import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, CardActions, DialogTitle, FormControlLabel, Switch, TextField} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {makeStyles} from "@material-ui/core/styles";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import TaxRateService from "./TaxRateService";
import Notification from "../../components/notifications/Notification";


function TaxRateForm(prosp) {

    const location = useLocation();
    const history = useHistory();
    const {t} = useTranslation();

    const [code, setCode] = useState('');
    const [rate, setRate] = useState(0);
    const [isDefault, setDefault] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');


    const [notificationMessage, setNotificationMessage] = useState(null);

    let useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            display: "block",
            margin: "auto"

        },
        field: {
            display: "flex",
            margin: "auto",
            marginBottom: "4%",
            marginTop: "1%",
            width: '25%',

        },

        button_container: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: "5%",

        },
        cancel_button: {
            marginInline: "4%",

            width: "15%",
            background: 'rgba(186,186,186,0.84)',
            color: "#000000",
            transition: "all 0.2s",


            "&:hover": {
                backgroundColor: "#d5d5d5",
            },
        },
        confirm_button: {
            marginInline: "4%",
            width: "15%",
            backgroundColor: "green",
            color: "white",
            transition: "all 0.2s",

            "&:hover": {
                background: 'rgba(24,147,0,0.84)',
            },
        },
        confirm_icon: {
            color: "white",
            transition: "all 0.3s",
        }

    }));

    const classes = useStyles();

    function closeNotification(){
        setOpenNotify(false);
    }



    useEffect(() => {

    }, [])

    function handleSubmit(event) {
        event.preventDefault();

        let taxRateService = new TaxRateService();
        taxRateService.addNewTaxRate(code, rate, isDefault)
            .then(() => {
                setNotifySeverity('success');
                setNotificationMessage("successful");
                setOpenNotify(true);

                setTimeout(() => {

                    history.push({
                        pathname: '/tax-rates',
                        state: {detail: ''}
                    });
                }, 2000)
            })
            .catch((err) => {
                err.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    function handleClose() {
        history.push("/tax-rates")
    }

    function handleRateChange(event, value) {
        setRate(value);

        if (!value) {
            setRate(0);
        }
    }

    function handleDefaultChange(event, value) {
        setDefault(value);
    }




    return <div className={classes.root}>

        <form onSubmit={handleSubmit}>
            {location.pathname === "/tax-rates/new" ?
                <DialogTitle>{t("add_new_tax_rate")}</DialogTitle>
                :
                <DialogTitle>{t("edit_tax_rate")}</DialogTitle>
            }

            <TextField
                label={t("code") + " " +t("tax_rate")}
                variant="outlined"
                margin="dense"
                value={code}
                onChange={event => setCode(event.target.value)}
                className={classes.field}
            />


            <CurrencyTextField
                label={t("tax_rate")}
                variant="outlined"
                margin="dense"
                value={rate}
                currencySymbol="%"
                minimumValue="0"
                outputFormat="string"
                digitGroupSeparator={"."}
                decimalCharacter={","}
                onChange={handleRateChange}
                className={classes.field}
            />
            <FormControlLabel
                labelPlacement="bottom"
                label={t("set_as_default")}
                control={
                    <Switch
                        checked={isDefault}
                        onChange={handleDefaultChange}
                        name="isDefault"

                    />
                }

                className={classes.field}
                />

            <CardActions className={classes.button_container}>
                <Button
                    className={classes.cancel_button}
                    endIcon={<CancelOutlined/>}
                    onClick={handleClose}
                    variant={"contained"}>
                    {t("cancel")}
                </Button>
                <Button
                    className={classes.confirm_button}
                    endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                    onClick={handleSubmit}
                    color="primary"
                    variant={"contained"}>
                    {t("confirm")}
                </Button>
            </CardActions>

        </form>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </div>


}


export default TaxRateForm;