import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {
    Card,
    CardActions,
    Checkbox,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    TextField
} from "@material-ui/core";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Button from "@material-ui/core/Button";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import Notification from "../components/notifications/Notification";
import {makeStyles} from "@material-ui/core/styles";
import WarehouseService from "../warehouses/WarehouseService";
import ContractService from "../accounts/contracts/ContractService";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import {Typography} from "@mui/material";
import PickupService from "./PickupService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    field: {
        minWidth: "20%",
        width: "fit-content",
        marginBlock: "2%",
        margin: "auto"
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "30%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        display: "block",
        margin: "auto"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
    },
    cancel_button: {
        marginInline: "4%",

        width: "15%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "15%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));

function PickupForm(props) {

    const classes = useStyles();

    const [refresh, setRefresh] = useState(false);

    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const {id} = useParams();

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");


    const [fetching, setFetching] = useState(false);
    const [warehouse, setWarehouse] = useState(null);
    const [code, setCode] = useState('');
    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freeCity, setFreeCity] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');
    const [city, setCity] = useState(null);
    const [addressCompanyName, setAddressCompanyName] = useState('');
    const [postalCode, setPostalCode] = useState(null);
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [date, setDate] = useState(null);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [enableSlot, setEnableSlot] = useState(false);

    let hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

    const [contract, setContract] = useState(null);
    const [warehouses, setWarehouses] = useState(null);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function handleDateChange(event) {
        setDate(event)
    }

    function handleFromTimeChange(event) {
        setFromTime(event.target.value);
    }

    function handleToTimeChange(event) {
        setToTime(event.target.value);
    }

    function handleEnableSlotChange(event) {
        setEnableSlot(event.target.checked);
        // if(event.target.checked === false){
        //     setFromTime(null);
        //     setToTime(null);
        // }
    }

    function handleClose() {
        history.goBack();
    }

    function handleSubmit() {

        let pickup = {};
        let address = {};

        if(country !== null){
            address.country = country.iso3166Alpha2;
        }
        if(freeAdministrativeLevel1 !== '') {
            address.administrativeLevel1 = freeAdministrativeLevel1;
        }

        if(freeAdministrativeLevel2 !== ''){
            address.administrativeLevel2 = freeAdministrativeLevel2;
        }

        if(freeAdministrativeLevel3 !== ''){
            address.administrativeLevel3 = freeAdministrativeLevel3;
        } else if(adminL3 !== null){
            address.administrativeLevel3 = adminL3.code;
        }

        if(freeCity !== ''){
            address.city = freeCity;
        } else if(city !== null){
            address.city = city.name;
        }

        if(freePostalCode !== ''){
            address.postalCode = freePostalCode;
        } else if(postalCode !== null){
            address.postalCode = postalCode.postalCode;
        }

        if(street !== ''){
            address.street = street;
        }

        if(buildingNr !== ''){
            address.buildingNr = buildingNr;
        }

        if(addressCompanyName !== ''){
            address.companyName = addressCompanyName;
        }

        if(phone !== ''){
            address.phone = phone;
        }

        if(mobile !== ''){
            address.mobile = mobile;
        }

        if(email !== ''){
            address.email = email;
        }

        pickup.address = address;
        pickup.contractId = contract?.id;
        pickup.warehouseCode = warehouse?.code;
        pickup.date = date;

        if(false !== enableSlot){
            pickup.fromTime = fromTime;
            pickup.toTime = toTime;
        }

        pickup.notes = notes;


        const pickupService = new PickupService();
        pickupService.createNewPickup(pickup)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setTimeout(()=>{
                    history.push("/pickups")
                }, 1000)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }


    function handleDepartureWarehouseChange(event, warehouse) {
        // setDepartureWarehouse(event.target.value);
        setWarehouse(warehouse);
    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);

    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);

    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);

    }

    function handlePostalCodeChange(event, postalCode) {

        setPostalCode(postalCode);
    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function handleStreetChange(event) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event) {
        setBuildingNr(event.target.value)
    }

    function handleAddressCompanyNameChange(event) {
        setAddressCompanyName(event.target.value)
    }

    function handleEmailChange(event) {
        setEmail(event.target.value)
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value)
    }

    function handleMobileChange(event) {
        setMobile(event.target.value)
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleNotesChange(event){
        setNotes(event.target.value);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL3(country) {
        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, null, null)
            .then(data => {
                setAdmin3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {
        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {
        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }


    function fetchContracts() {
        let contractService = new ContractService();
        contractService.getContractsByFilters()
            .then(contracts => {
                setContracts(contracts)
            })
            .catch(error => {

            })
    }

    async function fetchWarehouses() {

        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null)
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);
    }


    function generateAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="address_adml3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={admin3List}
                    disabled={!country}
                    getOptionLabel={option => option.name}
                    value={adminL3}
                    defaultValue={adminL3}
                    noOptionsText={t("no_options")}
                    onChange={handleAdminL3Change}
                    onOpen={() => fetchAdminL3(country)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={adminL3}
                            defaultValue={adminL3}
                        />
                    )}
                />
                <Autocomplete
                    id="add_cy"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!adminL3}
                    getOptionLabel={option => option.name}
                    value={city}
                    defaultValue={city}
                    noOptionsText={t("no_options")}
                    onChange={handleCityChange}
                    onOpen={() => fetchCities(adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            required
                            error={!city}
                            helperText={!adminL3 ? t("select_admin3_first") : (!city ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    // id="add_pc"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={postalCode}
                    defaultValue={postalCode}
                    autoComplete={"new-password"}
                    noOptionsText={t("no_options")}
                    onChange={handlePostalCodeChange}
                    onOpen={() => fetchPostalCodes(city, adminL3)}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={postalCode}
                            autoComplete={"new-password"}
                            defaultValue={postalCode}
                            required
                            error={!postalCode}
                            helperText={!postalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        autoComplete="new-password"
                        value={freeAdministrativeLevel1}
                        onChange={handleFreeAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        autoComplete="new-password"
                        size={"small"}
                        value={freeAdministrativeLevel2}
                        onChange={handleFreeAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        autoComplete="new-password"
                        variant={"outlined"}
                        size={"small"}
                        value={freeAdministrativeLevel3}
                        onChange={handleFreeAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeCity}
                        required
                        error={!freeCity}
                        helperText={!freeCity ? t("required") : null}
                        onChange={handleFreeCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freePostalCode}
                        onChange={handleFreePostalCodeChange}
                        required
                        error={!freePostalCode}
                        helperText={!freePostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    useEffect(() => {
        fetchCountries();
        fetchWarehouses();
        fetchContracts();
        // if (id) {
        //
        // }
    }, [refresh, location])


    return <Paper className={classes.root}>
        <DialogTitle>{t("pickup_data")}</DialogTitle>
        {(fetching) ?

            <LoadingComponent/>

            :

            <DialogContent>
                <div className={classes.field}>
                    <Autocomplete
                        id="contract"
                        size="small"
                        required
                        options={contracts}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={contract}
                        defaultValue={contract}
                        onChange={handleContractChange}
                        noOptionsText={t("no_contracts_for_account")}
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("contract")}
                                variant="outlined"
                                margin="dense"
                                value={contract}
                                defaultValue={contract}
                                required
                                error={!contract}
                                helperText={!contract ? t("required") : null}
                            />
                        )}
                    />
                </div>
                <div className={classes.field}>
                    <Autocomplete
                        id="departureWarehouseId"
                        size={"medium"}
                        style={{width: "100%"}}
                        options={warehouses}
                        getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        required
                        error={!warehouse}
                        helperText={!warehouse ? t("required") : null}
                        onChange={handleDepartureWarehouseChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        noOptionsText={t("no_options")}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                            required
                            error={!warehouse}
                            helperText={!warehouse ? t("required") : null}
                        />}
                    />
                </div>
                <div className={classes.field}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                        <Grid container justify="space-around">
                            <DatePicker
                                // autoOk
                                disableToolbar
                                style={{width: "100%"}}
                                disablePast
                                required
                                error={!date}
                                helperText={!date ? t("required") : null}
                                format="dd/MM/yyyy"
                                okLabel={t("confirm")}
                                clearLabel={t("reset")}
                                cancelLabel={t("cancel")}
                                margin="dense"
                                inputVariant="outlined"
                                label={t("pickup_date")}
                                clearable
                                value={date}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
                <div className={classes.field}>
                    <FormControlLabel
                        style={{margin: "auto"}}
                        label={<Typography fontSize={12}
                                           variant={"button"}>{t("time_slot")}</Typography>}
                        control={
                            <Checkbox
                                checked={enableSlot}
                                onChange={handleEnableSlotChange}
                            />
                        }
                    />
                </div>
                <div className={classes.field}>
                    <TextField
                        disabled={!enableSlot}
                        style={{width: "100%"}}
                        value={fromTime}
                        size={"small"}
                        onChange={handleFromTimeChange}
                        select // tell TextField to render select
                        label={t("from_time")}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        variant={"outlined"}
                    >
                        {hours.map((hour, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={hour}
                                >
                                    {hour + ":00"}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
                <div className={classes.field}>
                    <TextField
                        disabled={!enableSlot}
                        style={{width: "100%"}}
                        value={toTime}
                        size={"small"}
                        onChange={handleToTimeChange}
                        select // tell TextField to render select
                        label={t("to_time")}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        variant={"outlined"}
                    >
                        {hours.map((hour, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={hour}
                                >
                                    {hour + ":00"}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>

                <Card style={{width: "60%", margin: "auto", marginBlock: "5%"}}>
                    <DialogTitle>{t("address")}</DialogTitle>
                    <Autocomplete
                        id="add_cou"
                        size="small"
                        style={{minWidth: "30%", width: "fit-content", marginBlock: "4%", marginLeft: "2%"}}
                        options={countries}
                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                        value={country}
                        defaultValue={country}
                        onChange={handleCountryChange}
                        onSelect={fetchCountries}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        noOptionsText={t("no_options")}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("country")}
                            variant="outlined"
                            margin="dense"
                            value={country}
                            defaultValue={country}
                            required
                            error={!country}
                            helperText={!country ? t("required") : null}
                        />}
                    />

                    {generateAddress(country)}

                    <div style={{display: "flex", marginBlock: "5%"}}>
                        {/*<div style={{display: "flex", marginBlock: "2%"}}>*/}
                        <TextField
                            required
                            error={!addressCompanyName}
                            helperText={!addressCompanyName ? t("required") : null}
                            style={{marginInline: "2%"}}
                            label={t("address_company_name")}
                            variant={"outlined"}
                            size={"small"}
                            value={addressCompanyName}
                            onChange={handleAddressCompanyNameChange}
                        />
                        {/*</div>*/}
                        <TextField
                            style={{marginInline: "2%"}}
                            label={t("street")}
                            variant={"outlined"}
                            autoComplete={"new-password"}
                            size={"small"}
                            value={street}
                            required
                            error={!street}
                            helperText={!street ? t("required") : null}
                            onChange={handleStreetChange}
                        />
                        <TextField
                            style={{marginInline: "2%", width: "10%"}}
                            label={t("buildingNr")}
                            autoComplete={"new-password"}
                            variant={"outlined"}
                            size={"small"}
                            value={buildingNr}
                            onChange={handleBuildingNrChange}
                        />
                    </div>
                    <div style={{display: "flex", marginBottom: "5%"}}>
                        <TextField
                            style={{marginInline: "2%"}}
                            label={t("email")}
                            autoComplete={"new-password"}
                            variant={"outlined"}
                            size={"small"}
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <TextField
                            style={{marginInline: "2%"}}
                            label={t("phone")}
                            variant={"outlined"}
                            autoComplete={"new-password"}
                            size={"small"}
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        <TextField
                            style={{marginInline: "2%"}}
                            label={t("mobile")}
                            variant={"outlined"}
                            autoComplete={"new-password"}
                            size={"small"}
                            value={mobile}
                            onChange={handleMobileChange}
                        />
                    </div>
                </Card>


                <div className={classes.field}>
                    <TextField
                        style={{width : "100%", marginInline: "2%"}}
                        size={"small"}
                        variant={"outlined"}
                        id="pickup-multiline-notes"
                        label={t("notes")}
                        multiline
                        minRows={3}
                        maxRows={5}
                        value={notes}
                        onChange={handleNotesChange}
                    />
                </div>

                <CardActions className={classes.button_container}>
                    <Button
                        className={classes.cancel_button}
                        endIcon={<CancelOutlined/>}
                        onClick={handleClose}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>

            </DialogContent>

        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>


}

export default PickupForm;