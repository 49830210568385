import {Container, DialogTitle} from "@mui/material";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import * as React from "react";
import {useEffect, useState} from "react";
import WarehouseService from "../warehouses/WarehouseService";
import Notification from "../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {Button, CardActions, TextField, Typography} from "@material-ui/core";
import VehicleService from "./VehicleService";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "3%",
        marginTop: "3%",
        width: '25%',
    },

    label: {
        width: "15%",
        marginTop: "5%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        // width: "fit-content",
        // minWidth: "30%"
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "4%",
        paddingBottom: "2%"
    },
    confirm_button: {
        marginInline: "4%",

        width: "20%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    },
    cancel_button: {
        marginInline: "4%",

        width: "20%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    accountForm: {
        width: "75%",
        margin: "auto",
        marginBottom: "3%",
        marginTop: "3%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        backgroundColor: "primary",
        display: "block",
        margin: "auto"
    }

}));

function VehicleForm(props) {

    const {t} = useTranslation();
    const theme = useTheme();
    const history = useHistory();

    const classes = useStyles();

    const {id} = useParams();


    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState('');
    const [plate, setPlate] = useState('');
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const [warehouses, setWarehouses] = useState([]);

    async function fetchDataAndHandleEdit() {
        setLoading(true)


        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null)
            .catch(error => {
                error.response.json().then(response => {
                    setLoading(false)
                    setNotificationMessage("Si è verificato un errore nel recupero delle filiali: " + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);


        if (!id) {
            if(wares?.length === 1){
                let selWarehouses = [];
                selWarehouses.push(wares[0].id);
                setSelectedWarehouses(selWarehouses);
            }
            setLoading(false);
            return;
        }

        try {
            let vehicleService = new VehicleService();
            const vehicle = await vehicleService.getVehicleById(id);


            setModel(vehicle.model)
            setPlate(vehicle.plate)

            let selWarehouses = [];
            for (let i = 0; i < vehicle.warehouses.length; ++i) {
                for (let j = 0; j < wares.length; j++) {
                    if (vehicle.warehouses[i] === wares[j].code) {
                        selWarehouses.push(wares[j].id)
                        break;
                    }
                }
            }

            setSelectedWarehouses(selWarehouses);

        } catch (error) {
            error.response.json().then((response) => {
                setNotifySeverity('error');
                setNotificationMessage(response.message);
                setOpenNotify(true);
            });
        }

        setLoading(false);

    }

    function getStyles(role, userRoles, theme) {
        return {
            fontWeight:
                userRoles.indexOf(role) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    function handleChangeMultiple(event) {
        setSelectedWarehouses(event.target.value);
    }

    function submit(event) {

        event.preventDefault();

        let vehicle = {};

        vehicle.model = model;
        vehicle.plate = plate;
        vehicle.warehouses = selectedWarehouses;

        let vehicleService = new VehicleService();

        if (id) {
            vehicleService.updateVehicle(id, vehicle)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {
                        history.push(`/vehicles`);
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            vehicleService.createVehicle(vehicle)
                .then(response => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);
                    setTimeout(() => {
                        history.push(`/vehicles`);
                    }, 2000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }

    }

    useEffect(() => {
        fetchDataAndHandleEdit();
    }, [])

    return <Container>

        {loading ?

            <LoadingComponent/>

            :

            <div>

                <DialogTitle>{t("vehicle_data")}</DialogTitle>
                <TextField
                    required
                    label={t("vehicle_model")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setModel(event.target.value)}
                    value={model}
                    error={!model}
                    className={classes.field}
                />
                <TextField
                    required
                    label={t("vehicle_plate")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setPlate(event.target.value.toUpperCase())}
                    value={plate}
                    error={!plate}
                    className={classes.field}
                />
                <div style={{width: "fit-content", margin: "auto"}}>

                    <FormControl className={classes.formControl}>
                        {/*<InputLabel id="demo-multiple-chip-label">{t("warehouses")}</InputLabel>*/}
                        <Typography variant={"caption"} style={{margin: "auto"}}>{t("select_warehouses")}</Typography>
                        <Select
                            // labelId="demo-multiple-chip-label"
                            // id="demo-multiple-chip"
                            multiple
                            label={t("warehouses")}
                            value={selectedWarehouses}
                            onChange={handleChangeMultiple}
                            input={<OutlinedInput/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((warehouseId) => {
                                        let warehouse = {};
                                        for (let i = 0; i < warehouses.length; ++i) {
                                            if (warehouses[i].id === warehouseId) {
                                                warehouse = warehouses[i];
                                            }
                                        }
                                        return (
                                            <Chip key={warehouse.id} label={warehouse.code} className={classes.chip}/>
                                        )
                                    })}
                                </Box>
                            )}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {warehouses.map((warehouse, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={warehouse.id}
                                        style={getStyles(warehouse, selectedWarehouses, theme)}>

                                        {warehouse.code + " - " + warehouse.name}

                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>


                </div>
                <CardActions className={classes.button_container}>
                    <Button
                        className={classes.cancel_button}
                        endIcon={<CancelOutlined/>}
                        onClick={() => history.goBack()}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={submit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
            </div>

        }

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>


    </Container>


}

export default VehicleForm;