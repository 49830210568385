import UserService from "../../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../../api/ApiUtils";

const userService = new UserService();


export default class SubContractService{

    getSubContractsByFilters(filters) {

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let queryString = ``;

        if (filters) {
            if (filters.contractId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `contractId=${filters.contractId}`;
            }
        }

        let pathVariable = `/api/v1/contracts/sub-contracts`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    getSubContractById(id){

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/contracts/sub-contracts/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    saveSubContract(subContract) {
        const apiKey = userService.getApiKey();

        let contractObj = {
            code: subContract.code,
            contractId: subContract.contractId,
            addresses: subContract.addresses,
        };

        let pathVariable = `/api/v1/contracts/sub-contracts`;

        let requestOptions;
        if (subContract.id) {
            pathVariable = `${pathVariable}/${subContract.id}`
            requestOptions = {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(contractObj)
            };
        } else {
            requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(contractObj)
            };
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }

}