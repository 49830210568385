import React, {useEffect, useState} from "react";
import {Paper, Table, TableBody, TableCell, TableHead} from "@material-ui/core";
import ParcelsTabItem from "./ParcelsTabItem";
import {useTranslation} from "react-i18next";
import ShipmentService from "../ShipmentService";
import Notification from "../../components/notifications/Notification";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {Card, IconButton, TextField, Typography} from "@mui/material";
import {CancelOutlined, SaveOutlined} from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StyledButton from "../../components/buttons/StyledButton";


function ParcelsTab(props) {


    let parcels = props.parcels;

    const [newLength, setNewLength] = useState(0);
    const [newDepth, setNewDepth] = useState(0);
    const [newHeight, setNewHeight] = useState(0);
    const [newWeight, setNewWeight] = useState(0);
    const [newCustomerBarcode, setNewCustomerBarcode] = useState('');

    const [addParcelForm, setAddParcelForm] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const {t} = useTranslation();

    async function addParcel() {

        let input = {};

        input.weight = newWeight;
        input.length = newLength;
        input.depth = newDepth;
        input.height = newHeight;
        input.customerBarcode = newCustomerBarcode;


        await new ShipmentService().addParcel(input, props.shipmentId)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.trigger();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }

    function handleLengthChange(event, length) {
        setNewLength(length);
    }

    function handleHeightChange(event, height) {
        setNewHeight(height);
    }

    function handleDepthChange(event, depth) {
        setNewDepth(depth);
    }

    function handleWeightChange(event, weight) {
        setNewWeight(weight);
    }

    function handleCustomerBarcodeChange(event){
        setNewCustomerBarcode(event.target.value);
    }


    useEffect(() => {

    }, [])


    return <div>
        <Paper>
            <Table stickyHeader>

                <TableHead>
                    <TableCell>{t("parcel_id")}</TableCell>
                    <TableCell>{t("barcode")}</TableCell>
                    <TableCell>{t("customer_barcode")}</TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("declared_length")}</Typography>
                        <Typography variant={"subtitle2"}>{t("measured_length")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("declared_height")}</Typography>
                        <Typography variant={"subtitle2"}>{t("measured_height")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("declared_depth")}</Typography>
                        <Typography variant={"subtitle2"}>{t("measured_depth")}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant={"subtitle2"}>{t("declared_weight")}</Typography>
                        <Typography variant={"subtitle2"}>{t("measured_weight")}</Typography>
                    </TableCell>
                    <TableCell/>
                </TableHead>
                <TableBody>
                    {parcels.map(parcel => {
                        return <ParcelsTabItem trigger={props.trigger} parcel={parcel} warehouses={props.warehouses}
                                               eventCodes={props.eventCodes} drivers={props.drivers}/>
                    })}
                </TableBody>
            </Table>
            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                          onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        </Paper>

        {!addParcelForm ?
            <StyledButton onClick={()=>setAddParcelForm(true)} icon={<AddCircleIcon/>}
                          style={{marginTop: "2%"}}>{t("add_parcel")}</StyledButton>

            :
            <Card style={{marginTop: "10%"}}>
                <IconButton onClick={()=>setAddParcelForm(false)}
                              style={{marginTop: "2%"}}><CancelOutlined/></IconButton>
                <div style={{marginTop: "5%"}}>
                    <TextField
                        style={{marginInline: "1%", width: "20%"}}
                        label={t("customer_barcode")}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        value={newCustomerBarcode}
                        type={"text"}
                        onChange={handleCustomerBarcodeChange}
                    />
                    <CurrencyTextField
                        style={{marginInline: "1%", width: "10%"}}
                        label={t("length")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={newLength}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleLengthChange}
                    />
                    <CurrencyTextField
                        style={{marginInline: "1%", width: "10%"}}
                        label={t("height")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={newHeight}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleHeightChange}
                    />
                    <CurrencyTextField
                        style={{marginInline: "1%", width: "10%"}}
                        label={t("depth")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={0}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={newDepth}
                        currencySymbol={<Typography variant={"caption"}>{"cm"}</Typography>}
                        onChange={handleDepthChange}
                    />
                    <CurrencyTextField
                        style={{marginInline: "1%", width: "10%"}}
                        label={t("weight")}
                        variant={"outlined"}
                        margin={"dense"}
                        decimalPlaces={2}
                        digitGroupSeparator={"."}
                        decimalCharacter={","}
                        minimumValue={0}
                        value={newWeight}
                        currencySymbol={<Typography variant={"caption"}>{"kg"}</Typography>}
                        onChange={handleWeightChange}
                    />

                </div>
                <StyledButton onClick={addParcel} icon={<SaveOutlined/>}
                              style={{marginTop: "2%"}}>{t("save")}</StyledButton>
            </Card>
        }
    </div>



}

export default ParcelsTab;
