import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import WarehouseCollectionService from "./WarehouseCollectionService";
import {AppStyles} from "../../theme/AppStyles";
import {Button, Paper} from "@material-ui/core";
import {DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ArrowBackRounded} from "@material-ui/icons";


function WarehouseCollectionDocumentPage(){


    const [document, setDocument]   = useState(null);

    const {t} = useTranslation();

    const classes = AppStyles();
    const {id} = useParams();
    const history = useHistory();

    async function fetchDocument() {

        await new WarehouseCollectionService().getWarehouseCollectionDocument(id)
            .then(result => {
                setDocument(result.content);
            })
            .catch(error => {


            })

    }

    function goBack(){
        history.goBack();
    }

    useEffect(()=>{

        fetchDocument();
    }, [])

    return <Paper className={classes.root}>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={goBack}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>
        <DialogTitle>{t("warehouse_collection")}</DialogTitle>
        <div style={{margin: "auto", marginTop: "5%"}}>
            <object style={{margin: "auto"}} width={800} height={1200} type={'application/pdf'} data={"data:application/pdf;base64," + document}>";
                html += "</object>
        </div>

    </Paper>


} export default WarehouseCollectionDocumentPage;