export function formatDateTime(date) {

    if (null != date) {
        return new Date(date).toLocaleDateString() + " - " + new Date(date).toLocaleTimeString();
    } else {
        return ""
    }


}

export function formatDate(date) {

    if (null != date) {
        return new Date(date).toLocaleDateString();
    } else {
        return ""
    }


}

export function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}


export function formatAddressFirstLine(address){

    let line = "";

    line += address?.companyName? address.companyName : "";

    line += address?.referencePerson? ("" !== line? " - " :  "") + address?.referencePerson : "";

    return line;
}

export function formatAddressSecondLine(address){

    let line = "";

    line += address?.postalCode? address?.postalCode : "";

    line += address?.city? " " + address?.city : "";

    line += address?.administrative_level_3? " (" + address?.administrative_level_3 + ")" : "";

    return line;
}


export function get_file_array(file) {
    return new Promise(resolve => {

        let baseURL = "";

        const reader = new FileReader();
        // reader.onload = (event) => { acc(event.target.result) };
        reader.onload = () => {
            // Make a fileInfo Object

            baseURL = reader.result;
            resolve(baseURL);
        };
        reader.onerror = (err) => {
            err(err)
        };
        reader.readAsDataURL(file);
    });
}