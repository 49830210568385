import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Paper, TextField} from "@material-ui/core";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {Card, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import itLocale from "date-fns/locale/it";
import Notification from "../components/notifications/Notification";
import HandheldService from "./HandheldService";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Handheld from "./Handheld";
import HandheldForm from "./HandheldForm";
import {Autocomplete} from "@material-ui/lab";
import WarehouseService from "../warehouses/WarehouseService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));

export default function HandheldTable(props) {
    const [fetching, setFetching] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [handhelds, setHandhelds] = useState([]);

    const [noDataMessage, setNoDataMessage] = useState('');
    const [errorDetails, setErrorDetails] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const location = useLocation();
    const history = useHistory();

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        fetchWarehouses();
    }, []);

    async function fetchWarehouses() {
        let warehouseService = new WarehouseService();
        const warehouseResponse = await warehouseService.getWarehousesByFilters(0, 1000, null);
        const wares = warehouseResponse.warehouses;
        setWarehouses(wares);

        if(wares?.length === 1){
            setSelectedWarehouse(wares[0]);
        }

    }

    useEffect(() => {

        fetchWarehouses();
        fetchHandhelds();

    }, [refresh, location]);

    function clearFilters() {
        setSelectedWarehouse(null);

        setRefresh(!refresh);
    }

    function fetchHandhelds() {
        const filters = {};
        if (selectedWarehouse) {
            filters.warehouseId = selectedWarehouse.id;
        }

        const handheldService = new HandheldService();
        handheldService.getByFilters(filters).then((records) => {
            setHandhelds(records);
        }).catch((response) => {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(response.message);
        });
    }

    function goToForm() {
        history.push(`/handhelds/new`)
    }

    function deleteCallback(type, response) {
        setOpenNotify(true);
        setNotifySeverity(type);
        setNotificationMessage(response.message);
        //setPage(0);
        setRefresh(!refresh)
    }

    function handleWarehouseChange(event, warehouse) {
        setSelectedWarehouse(warehouse);
    }

    return <Paper className={classes.root}>

        {(fetching) ?

            <LoadingComponent/>

            :

            <Switch>
                <PrivateRoute exact path={"/handhelds"}>
                    <Card elevation={1} defaultExpanded={true}
                          style={{width: "60%", margin: "auto", marginBottom: "2%"}}>
                        <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                            <Typography variant={"button"}>{t("handhelds")}</Typography>
                        </DialogTitle>

                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="warehouseId"
                                size="small"
                                style={{minWidth: "35%", width: "fit-content", marginBottom: "2%", margin: "auto"}}
                                options={warehouses}
                                getOptionLabel={warehouse => warehouse.code + " - " + warehouse.name}
                                value={selectedWarehouse}
                                defaultValue={selectedWarehouse}
                                onChange={handleWarehouseChange}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedWarehouse}
                                    defaultValue={selectedWarehouse}
                                />}
                            />
                        </div>

                        <div style={{textAlign: 'center'}}>
                            <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                            <Button style={{margin: "5%"}} onClick={fetchHandhelds}>{t("apply_filter")}</Button>
                        </div>

                        <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                                onClick={goToForm}
                                className={classes.button}>
                            {t("create_new_handheld")}
                        </Button>
                    </Card>
                    {handhelds?.length > 0 ?
                            <TableContainer className={classes.container}>
                                <Table stickyHeader className={classes.table}>
                                    <TableHead>
                                        <TableCell>
                                            <Typography variant={"inherit"}>{t("warehouse")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"inherit"}>{t("imei")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"inherit"}>{t("description")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("model")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("operator")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("app_version")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("last_access_date_time")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant={"inherit"}>  {t("last_access_driver")} </Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {handhelds.map((record, index) => {
                                            return <Handheld deleteCallback={deleteCallback} handheld={record} warehouses={warehouses}/>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            :

                            <div className={classes.paper}/>
                    }

                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                  onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                </PrivateRoute>

                <PrivateRoute exact path={`/handhelds/:id/edit/`} children={<HandheldForm/>}>
                </PrivateRoute>
                <PrivateRoute exact path={`/handhelds/new`}>
                    <HandheldForm handheld={null}/>
                </PrivateRoute>
            </Switch>
        }

    </Paper>
}