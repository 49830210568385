import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";


function Warehouse(props){

    let warehouse = props.warehouse;

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function editWarehouse(warehouseId){
        setAnchorEl(null);
        history.push(`/warehouses/${warehouseId}/edit/`);
    }

    useEffect(()=>{

    })

    return <TableRow>
        <TableCell>{warehouse.id}</TableCell>
        <TableCell>{warehouse.code}</TableCell>
        <TableCell>{warehouse.name}</TableCell>
        <TableCell>{warehouse.companyName}</TableCell>
        <TableCell>{warehouse.contractCode}</TableCell>
        <TableCell>
            <Typography>{warehouse.address.street}  {warehouse.address.buildingNr}</Typography>
            <Typography> {warehouse.address.postalCode} {warehouse.address.city} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{warehouse.address.phone}</Typography>
            <Typography> {warehouse.address.mobile}</Typography>
            <Typography> {warehouse.address.email}</Typography>
        </TableCell>

        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editWarehouse(warehouse.id)}><EditIcon/>{t("edit")}</MenuItem>

        </Menu>



    </TableRow>

}export default Warehouse;