import UserService from "../../users/UserService";
import {checkStatus} from "../../api/ApiUtils";


export default class LedgerAccountService {



    getLedgerAccounts(){

        const userService = new UserService();
        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch('/api/v1/ledger-accounts', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }






}