import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

const userService = new UserService();

export default class CompanyService{


    getCompanies(filters) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let queryString = ``;

        if (filters) {
            // if (filters.accountId) {
            //     if (queryString.length > 0) {
            //         queryString += `&`;
            //     }
            //     queryString += `accountId=${filters.accountId}`;
            // }
        }

        let pathVariable = `/api/v1/companies`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }


};