import {Box, Button, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import LedgerAccountService from "../../accounting/ledger_accounts/LedgerAccountService";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import AccountService from "../../accounts/AccountService";
import theme from "../../theme/theme";
import AdminRowsTab from "./AdminRowsTab";
import ContractService from "../../accounts/contracts/ContractService";
import ActiveTaxQueueService from "../../active-tax-queue/ActiveTaxQueueService";
import {Cached} from "@material-ui/icons";
import Notification from "../../components/notifications/Notification";
import PassiveTaxQueueService from "../../passive-tax-queue/PassiveTaxQueueService";
import PickupService from "../../pickups/PickupService";
import AdministrativeGroupService from "../../accounting/administrative-groups/AdministrativeGroupService";
import AdministrativeGroupsTab from "../../accounting/administrative-groups/AdministrativeGroupsTab";


function AdminTab(props) {


    const {t} = useTranslation();

    let quotes = props.quotes ? props.quotes : [];
    let accountingRows = props.accountingRows ? props.accountingRows : [];
    let preInvoiceRows = props.preInvoiceRows ? props.preInvoiceRows : [];

    let id = props.id;

    let isPudo = props.isPudo;

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);

    const [tabNumber, setTabNumber] = useState(0);
    const [ledgerAccounts, setLedgerAccounts] = useState([]);

    const [pickups, setPickups] = useState([]);

    const [accounts, setAccounts] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [administrativeGroups, setAdministrativeGroups] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function handleTabNumberChange(event, newValue) {
        setTabNumber(newValue);
    }

    function VerticalTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                style={{width: "100%"}}
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 1}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    VerticalTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    async function fetchLedgerAccounts() {

        setLoading(true);
        let acc = await new LedgerAccountService().getLedgerAccounts()
            .catch(error => {

            })
        setLedgerAccounts(acc);

        setLoading(false);
    }

    async function fetchAccounts() {
        setLoading(true);
        let acc = await new AccountService().getAccounts()
            .catch(error => {

            })
        setAccounts(acc);

        setLoading(false);
    }

    async function fetchContracts() {
        setLoading(true);
        let contracts = await new ContractService().getContractsByFilters()
            .catch(error => {

            })
        setContracts(contracts);

        setLoading(false);
    }

    async function refreshActive() {

        if (pickups.length > 0) {
            for (let i = 0; i < pickups.length; i++) {
                let input = {};
                input.pickupId = pickups[i];

                await new ActiveTaxQueueService().workSynchro(input)
                    .then(response => {

                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })
            }
        }
        let input = {};
        input.shipmentId = id;

        await new ActiveTaxQueueService().workSynchro(input)
            .then(response => {
                props.refresh();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }

    async function refreshPassive() {


        if (pickups.length > 0) {
            for (let i = 0; i < pickups.length; i++) {
                let input = {};
                input.pickupId = pickups[i];

                await new PassiveTaxQueueService().workSynchro(input)
                    .then(response => {

                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })
            }
        }

        if (isPudo) {
            let input = {};
            input.pudoShipmentId = id;

            await new PassiveTaxQueueService().workSynchro(input)
                .then(response => {
                    // props.refresh();
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })

        }

        let input = {};
        input.shipmentId = id;

        await new PassiveTaxQueueService().workSynchro(input)
            .then(response => {
                props.refresh();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function fetchPickups() {

        await new PickupService().getPickupsByShipmentId(id)
            .then(response => {
                setPickups(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })


    }

    async function fetchAdministrativeGroups() {

        let administrativeGroupsIds = [];

        for (let i = 0; i < quotes.length; i++) {
            if (quotes[i].administrativeGroupId && !administrativeGroupsIds.some(id => id === quotes[i].administrativeGroupId)) {
                administrativeGroupsIds.push(quotes[i].administrativeGroupId)
            }
        }

        for (let i = 0; i < accountingRows.length; i++) {
            if (accountingRows[i].administrativeGroupId && !administrativeGroupsIds.some(id => id === accountingRows[i].administrativeGroupId)) {
                administrativeGroupsIds.push(accountingRows[i].administrativeGroupId)
            }
        }

        for (let i = 0; i < administrativeGroupsIds.length; i++) {
            await new AdministrativeGroupService().getAdministrativeGroupById(administrativeGroupsIds[i])
                .then(result => {
                    let adminGroups = administrativeGroups;
                    adminGroups.push(result);
                    setAdministrativeGroups(adminGroups);
                })
                .catch(error => {

                })
        }


    }

    useEffect(() => {
        fetchAccounts();
        fetchContracts();
        fetchLedgerAccounts();
        fetchPickups();
        fetchAdministrativeGroups();
    }, [refresh])


    return <div>

        <Box
            width={"100%"}
            sx={{flexGrow: 0, display: "flex"}}

        >
            <Notification open={openNotify} severity={notifySeverity} duration={3000}
                          onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                style={{width: "fit-content"}}
                value={tabNumber}
                onChange={handleTabNumberChange}
                sx={{borderRight: 1, borderColor: theme.palette.primary.main}}
            >

                <Tab label={t("quotes")} {...a11yProps(0)} />
                <Tab label={t("accounting_rows")} {...a11yProps(1)} />
                <Tab label={t("pre-invoices")} {...a11yProps(2)} />
                <Tab label={t("administrative_groups")} {...a11yProps(3)} />


            </Tabs>
            {loading ?
                <LoadingComponent/>

                :
                <Paper style={{width: "100%"}}>
                    <VerticalTabPanel value={tabNumber} index={0}>
                        <AdminRowsTab
                            type={"quotes"}
                            ledgerAccounts={ledgerAccounts}
                            rows={quotes}
                            accounts={accounts}
                            contracts={contracts}
                            refresh={props.refresh}
                            extra={true}
                        />
                    </VerticalTabPanel>
                    <VerticalTabPanel value={tabNumber} index={1}>
                        <AdminRowsTab
                            type={"accounting-rows"}
                            ledgerAccounts={ledgerAccounts}
                            rows={accountingRows}
                            accounts={accounts}
                            contracts={contracts}
                            refresh={props.refresh}
                        />
                    </VerticalTabPanel>
                    <VerticalTabPanel value={tabNumber} index={2}>
                        <AdminRowsTab
                            type={"pre-invoices"}
                            ledgerAccounts={ledgerAccounts}
                            rows={preInvoiceRows}
                            accounts={accounts}
                            contracts={contracts}
                            refresh={props.refresh}
                        />
                    </VerticalTabPanel>
                    <VerticalTabPanel value={tabNumber} index={3}>
                        <AdministrativeGroupsTab
                            administrativeGroups={administrativeGroups}
                        />
                    </VerticalTabPanel>
                    {tabNumber === 3?
                        null
                        :
                    <div style={{display: "flex", marginBottom: "2%"}}>
                        <Button variant={"outlined"} style={{margin: "auto"}} endIcon={<Cached/>}
                                onClick={refreshActive}>{t("active_tax_refresh")}</Button>
                        <Button variant={"outlined"} style={{margin: "auto"}} endIcon={<Cached/>}
                                onClick={refreshPassive}>{t("passive_tax_refresh")}</Button>
                    </div>
                    }

                </Paper>
            }
        </Box>
    </div>


}

export default AdminTab;