import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

const userService = new UserService();

export default class ParcelService {


    getParcelByBarcode(barcode) {

        const apiKey = userService.getApiKey();

        let queryString = "barcode=" + barcode;

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/parcels`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getParcelById(id) {
        const apiKey = userService.getApiKey();

        let queryString = "parcelId=" + id;

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/parcels`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addEventToParcel(parcelId, request) {

        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                dateTime: request.dateTime,
                eventCodeId: request.eventCodeId,
                driverId: request.driverId,
                warehouseId: request.warehouseId,
                driverWorkListId: request.driverWorkListId,
                codCollectingEventCodeId: request.codCollectingEventCodeId
            })
        };
        let pathVariable = `/api/v1/parcels/${parcelId}/events`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    updateParcel(input, parcelId) {
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                weight : input.weight,
                length : input.length,
                depth : input.depth,
                height : input.height,
            })
        };
        let pathVariable = `/api/v1/parcels/${parcelId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

}