import {Chip} from "@material-ui/core";
import React from "react";
import {CheckOutlined} from "@material-ui/icons";


function CheckChip(props){

    return <div style={{width: "100%", display: "flex", margin: "auto", marginBlock: "5%", paddingBottom: "2%"}}>
        {/*<Typography style={{margin: "auto"}}><WarningOutlined color={"warning"}/>{t("no_events")}</Typography>*/}
        <Chip style={{margin: "auto"}}
              icon={<CheckOutlined style={{color: "green"}}/>} label={props.children}/>
    </div>

} export default CheckChip;