import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import Zone from "./Zone";
import ZoneService from "./ZoneService";
import {
    Container,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {PrivateRoute} from "../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import ZoneForm from "./ZoneForm";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        // width: '100%',
    },
    table: {
        // margin: "auto"

    },
    cell: {
        width: "35%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function ZonesTable(props){

    const [fetching, setFetching] = useState(true);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [districts, setDistricts] = useState([])

    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [zones, setZones] = useState([]);

    const classes = useStyles();


    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };


    function closeNotification() {
        setOpenNotify(false);
    }

    function goToZoneForm() {
        history.push("/zones/new")
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

        setRefreshTable(!refreshTable);

    }

    function failedDelete(error) {


        error.response.json().then((response) => {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage(t(response.message));

            setRefreshTable(!refreshTable);
        });

    }

    function fetchZones(){

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        let zoneService = new ZoneService();
        zoneService.getZonesByFilters(filters)
            .then(zones => {
                setZones(zones.zones);
                setPages(Array.from(Array(Math.ceil(zones.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {

            })
    }

    useEffect(()=>{
        fetchZones();
    }, [location, page, rowsPerPage, refreshTable])



    return <Container className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/zones`}>
                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToZoneForm}
                        className={classes.button}>
                    {t("create_new_zone")}
                </Button>
                <TableContainer className={classes.container}>
                    {(fetching) ?
                        <Table>
                            <LoadingComponent/>
                        </Table>

                        :
                        <Table className={classes.table}>
                            <TableHead>
                                <TableCell>{t("ID")}</TableCell>
                                <TableCell>{t("name")}</TableCell>
                                <TableCell>{t("warehouse")}</TableCell>
                                <TableCell>{t("routing_rules")}</TableCell>
                                <TableCell/>

                            </TableHead>

                            <TableBody>
                                {zones.map((zone, index)=>{
                                    return <Zone zone={zone} deleteCallback={deleteCallback} failedDeleteCallback={failedDelete}/>
                                })}


                            </TableBody>

                            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                          onClose={closeNotification}>{notificationMessage}</Notification>
                        </Table>
                    }

                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    count={-1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'}
                    }}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={() => {
                        return t("page")
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />

            </PrivateRoute>

            <PrivateRoute strict path={`/zones/:id/edit/`} children={<ZoneForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/zones/new/`}>
                <ZoneForm zone={null}/>
            </PrivateRoute>

        </Switch>
    </Container>


} export default ZonesTable;
