import {Button, Menu, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import {Download} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {IconButton, TextField} from "@mui/material";
import {Edit, Save} from "@material-ui/icons";
import TripService from "./TripService";
import Notification from "../../components/notifications/Notification";


function Trip(props) {

    let trip = props.trip;
    const [anchorEl, setAnchorEl] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [plateNumber, setPlateNumber] = useState('');
    const [driverName, setDriverName] = useState('');
    const [accountingRow, setAccountingRow] = useState('');

    const [editing, setEditing] = useState(false);

    let permissions = localStorage.getItem("permissions");

    const {t} = useTranslation();

    // async function generateSummaryOut() {
    //
    //     let input = {};
    //
    //     input.dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
    //     input.warehouseCode = warehouseCode;
    //     input.tripId = trip.id;
    //
    //     await new TripService().generateSummaryOut(input)
    //         .catch(error => {
    //
    //         })
    // }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handlePlateNumberChange(event) {
        setPlateNumber(event.target.value)
    }

    function handleDriverNameChange(event) {
        setDriverName(event.target.value)
    }

    function handleAccountingRowChange(event) {
        setAccountingRow(event.target.value);
    }

    async function updateTrip() {

        let input = {};

        input.driverName = driverName;
        input.plateNumber = plateNumber;
        input.accountingRow = accountingRow;

        await new TripService().updateTrip(trip.id, input)
            .then(result => {
                setEditing(false);
                props.reload();
            })
            .catch(error => {

            })
    }

    function setData() {
        if (trip.plateNumber) {
            setPlateNumber(trip.plateNumber);
        }
        if (trip.driverName) {
            setDriverName(trip.driverName);
        }
        if (trip.accountingRow && permissions.includes("accounting-rows:read")) {
            setAccountingRow(trip.accountingRow);
        }
    }

    async function downloadSummary(id, reduced) {

        await new TripService().downloadSummary(id, reduced)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }


    useEffect(() => {
        setData();
    }, [])


    return <TableRow>
        <TableCell>{trip.id}</TableCell>
        <TableCell>{trip.routeName}</TableCell>
        <TableCell>{trip.supplierAcc}</TableCell>
        <TableCell>{new Date(trip.departureDateTime).toLocaleDateString()}</TableCell>
        <TableCell>
            {editing ?
                <TextField
                    label={t("plate_number")}
                    size={"small"}
                    variant="outlined"
                    margin="dense"
                    value={plateNumber}
                    defaultValue={plateNumber}
                    onChange={handlePlateNumberChange}
                />
                :
                <div>
                    {trip.plateNumber}
                </div>
            }
        </TableCell>
        <TableCell>
            {editing ?
                <TextField
                    label={t("driver_name")}
                    size={"small"}
                    variant="outlined"
                    margin="dense"
                    value={driverName}
                    defaultValue={driverName}
                    onChange={handleDriverNameChange}
                />
                :
                <div>
                    {trip.driverName}
                </div>
            }
        </TableCell>
        {permissions.includes("accounting-rows:read") ?
            <>
                <TableCell>{trip.quote ? trip.quoteCurrencyCode + " " + trip.quote.toFixed(2) : ""}</TableCell>
                <TableCell>
                    {editing ?
                        <TextField
                            label={t("cost")}
                            size={"small"}
                            variant="outlined"
                            margin="dense"
                            value={accountingRow}
                            defaultValue={accountingRow}
                            onChange={handleAccountingRowChange}
                        />
                        :
                        trip.accountingRow ? trip.accountingRowCurrency + " " + trip.accountingRow.toFixed(2) : ""}
                </TableCell>
            </>
            :
            null
        }
        <TableCell>
            <Button disabled={trip.summaries.length === 0} aria-controls="roles-menu" aria-haspopup="true"
                    onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
            {editing ?
                <IconButton onClick={updateTrip}>
                    <Save/>
                </IconButton>
                :
                <IconButton onClick={() => setEditing(true)}>
                    <Edit/>
                </IconButton>
            }
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {/*<MenuItem*/}
                {/*          onClick={generateSummaryOut}><Autorenew/>&nbsp;{t("generate_summary_out")}</MenuItem>*/}
                {/*<MenuItem disabled={!permissions.includes("su:admin")} onClick={openConfirmDialog}><Warning*/}
                {/*    color={"error"}/>{t("force_delete")}</MenuItem>*/}
                {trip.summaries.map(summary => {
                    return <div>
                        <MenuItem
                            onClick={() => downloadSummary(summary.id, false)}
                        ><Download/>&nbsp;{t("download_summary")}&nbsp;{"N."}&nbsp;{summary.number}</MenuItem>
                        <MenuItem
                            onClick={() => downloadSummary(summary.id, true)}
                        ><Download/>&nbsp;{t("download_reduced_summary")}&nbsp;{"N."}&nbsp;{summary.number}</MenuItem>
                    </div>
                })}
            </Menu>
        </TableCell>
        <TableCell/>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </TableRow>


}

export default Trip;