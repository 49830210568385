import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


export default class CodEventService {


    deleteCodEvent(codEventId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/cod-events/${codEventId}`;

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }


    updateCodEvent(input) {

        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/cod-events/update`;

        let requestOptions = {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(input)
            }
        ;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)


    }


}