import React, {useEffect, useState} from "react";
import {Card, Checkbox, FormControlLabel, TableCell, TextField} from "@material-ui/core";
import {Button, CircularProgress, IconButton, Table, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import theme from "../theme/theme";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import ContractService from "../accounts/contracts/ContractService";
import {Autocomplete} from "@material-ui/lab";
import DateField from "../components/fields/DateField";
import {SearchOutlined} from "@material-ui/icons";
import moment from "moment/moment";
import WarningChip from "../components/chips/WarningChip";
import Notification from "../components/notifications/Notification";
import {CloudDownload} from "@mui/icons-material";
import TripsOrderService from "./TripsOrderService";


export default function TripsOrdersPage(props) {

    const [contractList, setContractList] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [tripDepartureFromDate, setTripDepartureFromDate] = useState(null);
    const [tripDepartureToDate, setTripDepartureToDate] = useState(null);

    const [excludeAlreadyInInvoice, setExcludeAlreadyInInvoice] = useState(false);

    const [fetched, setFetched] = useState(false);

    const [tripOrders, setTripOrders] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);

    const {t} = useTranslation();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    // const ledgerAccounts = [
    //     "Nolo",
    //     "Commissioni contrassegno",
    //     "Commissioni assicurazione",
    //     "Commissioni giacenza",
    //     "Commissione carburante",
    //     "Commissioni booking",
    //     "Commissioni extra 1",
    //     "Commissioni extra 2",
    //     "Commissioni extra 3",
    //     "Ritiro",
    //     "Commissioni PUDO",
    //     "Facchinaggio arrivi"
    // ]

    async function fetchTripsOrders() {

        setLoading(true);

        let filters = getFilters();

        await new TripsOrderService().getTripsOrdersByFilters(filters)
            .then(response => {
                setTripOrders(response);
                setFetched(true);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })

    }

    async function getCsv() {

        setLoadingCsv(true);

        let filters = getFilters();

        await new TripsOrderService().getCsv(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCsv(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCsv(false);
                })
            })

    }

    function getFilters() {

        let filters = {};

        if (contracts.length > 0) {

            let filterContracts = [];

            for (let i = 0; i < contracts.length; i++) {
                filterContracts.push(contracts[i].id)
            }

            filters.contracts = filterContracts;

        }

        if (null != tripDepartureFromDate) {
            filters.tripDepartureFromDate = moment(tripDepartureFromDate).format('yyyy-MM-DD');
        }

        if (null != tripDepartureToDate) {
            filters.tripDepartureToDate = moment(tripDepartureToDate).format('yyyy-MM-DD');
        }

        if (excludeAlreadyInInvoice) {
            filters.excludeAlreadyInInvoice = true;
        }

        return filters;
    }

    async function fetchContracts() {

        let filters = {};

        filters.direction = "BUY";

        await new ContractService().getContractsByFilters(filters)
            .then(response => {
                setContractList(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function handleContractsChange(event, contracts) {
        setContracts(contracts);
    }

    // function calculateTotal(ledgerAccountName) {
    //
    //     let total = 0;
    //
    //     for (let i = 0; i < tripOrders?.contractsTripsOrders.length; i++) {
    //         total += tripOrders?.contractsTripsOrders[i].ledgerAccountsTripsOrders.find(la => la.ledgerAccountName === ledgerAccountName)?.total || 0;
    //     }
    //
    //     return total.toFixed(2);
    // }

    useEffect(() => {
        fetchContracts();
    }, [])


    return <div>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <Card>
            <div style={{width: "100%", marginTop: "3%"}}>
                <Autocomplete
                    style={{width: "30%", margin: "auto"}}
                    multiple
                    options={contractList}
                    getOptionLabel={(option) => option.code + " - " + option.name}
                    defaultValue={contracts}
                    autoHighlight
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    onChange={handleContractsChange}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contracts")}
                            variant={"outlined"}
                            multiline
                            maxRows={2}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", marginTop: "2%"}}>
                <DateField
                    style={{margin: "auto", width: "20%"}}
                    label={t("departure_from_date")}
                    value={tripDepartureFromDate}
                    onChange={event => {
                        setTripDepartureFromDate(event)
                    }}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
                <DateField
                    style={{margin: "auto", width: "20%"}}
                    label={t("departure_to_date")}
                    value={tripDepartureToDate}
                    onChange={event => {
                        setTripDepartureToDate(event)
                    }}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={true}
                />
            </div>
            <div style={{display: "flex", marginTop: "2%"}}>
                <FormControlLabel
                    style={{margin: "auto"}}
                    control={<Checkbox color={"primary"} checked={excludeAlreadyInInvoice}
                                       onChange={event => setExcludeAlreadyInInvoice(event.target.checked)}/>}
                    label={t("exclude_rows_invoiced")}
                />
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <IconButton onClick={fetchTripsOrders}
                            disabled={loading}
                            style={{backgroundColor: 'transparent', margin: "auto"}}>{loading ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>

        <div style={{display: "flex", marginTop: "2%"}}>
            <Button style={{
                width: "fit-content",
                margin: "auto",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                marginBottom: "5%"
            }} disabled={loadingCsv} onClick={getCsv}
                    endIcon={loadingCsv ?
                        <CircularProgress size={24} style={{color: "white"}}/> :
                        <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_csv")}</Button>
        </div>

        {tripOrders?.contractsTripsOrders.length < 1 && fetched ?
            <WarningChip>{t("no_records_found")}</WarningChip>
            :
            null
        }


        {loading ?
            <LoadingComponent/>

            :
            <div style={{marginTop: "2%"}}>
                <Table>
                    <TableRow>
                        <TableCell/>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("total")}</Typography>
                        </TableCell>
                        {/*{ledgerAccounts.map(la => {*/}
                        {/*    return <TableCell>*/}
                        {/*        <Typography variant={"subtitle2"}>{la}</Typography>*/}
                        {/*    </TableCell>*/}
                        {/*})}*/}
                    </TableRow>
                    {tripOrders?.contractsTripsOrders?.map(contract => {
                        return <TableRow>
                            <TableCell>
                                <Typography>{contract.contractCode}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{contract.total.toFixed(2)}</Typography>
                            </TableCell>
                            {/*{ledgerAccounts.map(la => {*/}
                            {/*    return <TableCell>{contract.ledgerAccountsTripsOrders.find(tripOrder => tripOrder.ledgerAccountName === la)?.total.toFixed(2)}</TableCell>*/}
                            {/*})}*/}
                        </TableRow>
                    })}
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("total")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{tripOrders?.total.toFixed(2)}</Typography>
                        </TableCell>
                        {/*{ledgerAccounts.map(la => {*/}
                        {/*    return <TableCell>*/}
                        {/*        <Typography variant={"subtitle2"}>{calculateTotal(la)}</Typography>*/}
                        {/*    </TableCell>*/}
                        {/*})}*/}
                    </TableRow>
                </Table>
            </div>
        }

    </div>
}