import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


export default class ShortLinkService {



    getUrlByKey(key) {
        let requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/shortLinks/${key}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

}