import UserService from "../../users/UserService";
import {checkStatus} from "../../api/ApiUtils";

const userService = new UserService();

export default class TaxRateService {



    getTaxRates(){

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        };

        return fetch(`/api/v1/tax-rates`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    addNewTaxRate(code, rate, isDefault){

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({code: code, rate: rate, isDefault: isDefault})
        };

        return fetch(`/api/v1/tax-rates`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    editTaxRate(id, code, rate, isDefault){

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json',
                'Content-type': 'application/json'

            },
            body: JSON.stringify({code: code, rate: rate, isDefault: isDefault})
        };

        return fetch(`/api/v1/tax-rates/${id}`, requestOptions)
            .then(checkStatus)
    }

    deleteTaxRate(id){

        const apiKey = userService.getApiKey();
        const requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            },
        };

        return fetch(`/api/v1/tax-rates/${id}`, requestOptions)
            .then(checkStatus)
    }
}