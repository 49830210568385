import React, {useEffect, useState} from "react";
import {
    Button,
    CardActions,
    Checkbox,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {weekdays} from "moment";
import SlotService from "./SlotService";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Notification from "../../components/notifications/Notification";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    name_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    div: {
        display: "inline"
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        marginLeft: "auto",
        marginRight: "auto",
        margin: theme.spacing(1),
        width: "75%",
    },
    chips: {
        display: 'block',
        // flexWrap: 'wrap',
    },
    chip: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "2.5%",
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    select: {
        width: `50%`,
        marginTop: `1%`
    },
    typo: {
        marginTop: `3%`
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "4%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));



function SlotForm(props){


    const location = useLocation();
    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();

    let order = {Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7};

    const [newSlotFromTime, setNewSlotFromTime] = useState(null);
    const [newSlotToTime, setNewSlotToTime] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    const [slotDays, setSlotDays] = useState([]);


    const [slot, setSlot] = useState(null);

    let days = weekdays().sort(function (a, b) {
        return order[a] - order[b];
    });

    function changeFromTime(event, value) {
        setNewSlotFromTime(value);
    }

    function changeToTime(event, value) {
        setNewSlotToTime(value);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function cancelNewSlot() {
        history.goBack();
    }

    function submitNewSlot(){

        slotDays.sort(function (a, b) {
            return order[a] - order[b];
        });


        let slotService = new SlotService();
        slotService.addSlot(newSlotFromTime, newSlotToTime, slotDays)
            .then(() => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)

                setTimeout(()=>{
                    history.goBack();
                    }, 3000
                )
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    //
    // function fetchSlot(){
    //     let slotService = new SlotService();
    //     slotService.getById(id)
    //         .then(data => {
    //             setSlot(data)
    //         })
    //         .catch(error => {
    //
    //             })
    //
    //     let daysWithChecked = [];
    //     days.map((day, index) => {
    //         const checked = false;
    //         daysWithChecked[index] = {
    //             day:day,
    //             isChecked: checked
    //         };
    //     });
    //
    //     setSlotDays(daysWithChecked.slice());
    //
    //     // if(slot){
    //     //     let newArray = daysWithChecked.slice();
    //     //     for(let i = 0; i < newArray.length; i++){
    //     //         for(let j = 0; j < slotDays.length; j++){
    //     //             if(newArray[i].day.toUpperCase() === slotDays[j]) {
    //     //                 newArray[i].isChecked = true;
    //     //             }
    //     //         }
    //     //     }
    //     //
    //     //     setSlotDays(newArray);
    //     // }
    // }



    function handleCheckboxChange(event){
        let newArray = slotDays;
        if (newArray.includes(event.target.name)) {
            newArray = newArray.filter(day => day !== event.target.name);
        } else {
            newArray.push(event.target.name);
        }

        setSlotDays(newArray);
    }





    useEffect(()=>{
        // fetchSlot();

        }, [location])

    return <form>

        <DialogTitle>{t("new_slot")}</DialogTitle>
        <div>
            <FormControl required error={null} component="fieldset">

                <FormGroup row>
                    {days.map((day, index) => {

                        return (

                            <div className={classes.div}>
                                <FormControlLabel key={index}
                                                  labelPlacement="bottom"
                                                  control={<Checkbox color="primary"
                                                                     onChange={handleCheckboxChange}
                                                                     name={day}/>}
                                                  label={t(`${day}`)}
                                />
                            </div>
                        )
                    })
                    }
                </FormGroup>
                <FormHelperText/>
            </FormControl>
        </div>
        <CurrencyTextField
            label={t("fromTime")}
            variant="outlined"
            outputFormat="number"
            margin="dense"
            minimumValue={0}
            maximumValue={23}
            currencySymbol={''}
            onChange={changeFromTime}
            value={newSlotFromTime}
            decimalPlaces={0}
            className={classes.field}
        />
        <CurrencyTextField
            label={t("toTime")}
            variant="outlined"
            outputFormat="number"
            margin="dense"
            minimumValue={1}
            maximumValue={24}
            currencySymbol={''}
            onChange={changeToTime}
            value={newSlotToTime}
            decimalPlaces={0}
            className={classes.field}

        />
        <CardActions className={classes.button_container}>
            <Button
                className={classes.cancel_button}
                endIcon={<CancelOutlined/>}
                onClick={cancelNewSlot}
                variant={"contained"}>
                {t("cancel")}
            </Button>
            <Button
                className={classes.confirm_button}
                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                onClick={submitNewSlot}
                color="primary"
                variant={"contained"}>
                {t("add_slot")}
            </Button>
        </CardActions>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

    </form>

} export default SlotForm;