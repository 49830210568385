import React, {useEffect, useState} from "react";
import {MenuItem, Select, Table, TablePagination, TextField, useTheme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, SearchOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {compareAsc} from "date-fns";
import ParcelsQueueService from "./ParcelsQueueService";
import {
    Card,
    CircularProgress,
    DialogTitle,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {useTranslation} from "react-i18next";
import itLocale from "date-fns/locale/it";
import moment from "moment";
import {formatDateTime} from "../utils/utils";
import Notification from "../components/notifications/Notification";
import InfoChip from "../components/chips/InfoChip";
import StyledButton from "../components/buttons/StyledButton";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}));

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    container: {},
    button: {
        marginTop: "3%",
        marginBottom: "3%",

    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    }


});

function ParcelsQueueTable(props) {

    const localeMap = {
        it: itLocale,
    };

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [loadingAddToQueue, setLoadingAddToQueue] = useState(false);

    const [count, setCount] = useState(0);

    const [locale, setLocale] = useState("it");

    const [records, setRecords] = useState([]);

    const {t} = useTranslation();

    const classes = useStyles();

    const [shipmentId, setShipmentId] = useState('');

    const today = moment();
    const aMonthAgo = moment().subtract(1, 'months');

    const [manifestDateFrom, setManifestDateFrom] = useState(aMonthAgo);
    const [manifestDateTo, setManifestDateTo] = useState(today);

    const [pages, setPages] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);

    function handleShipmentIdChange(event) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleFromDateChange(event) {
        if (compareAsc(event, manifestDateTo) === 1) {
            setManifestDateFrom(event);
            setManifestDateTo(null);
        } else {
            setManifestDateFrom(event)
        }
    }

    function handleToDateChange(event) {
        if (compareAsc(manifestDateFrom, event) === 1) {
            setManifestDateFrom(null);
            setManifestDateTo(event);
        } else {
            setManifestDateTo(event);
        }
    }

    function handlePageChange(event, page) {
        setPageNumber(page);
    }

    function handlePageSizeChange(event) {
        setPageSize(event.target.value);
        setPageNumber(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPageNumber(0)
        };

        const handleBackButtonClick = (event) => {
            setPageNumber(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPageNumber(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPageNumber(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handlePageChange}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    async function fetchRecords() {

        setLoading(true);
        let filters = {};

        filters.pageNumber = pageNumber;
        filters.pageSize = pageSize;

        if ('' !== shipmentId) {
            filters.shipmentId = shipmentId;
        }

        if (manifestDateFrom !== null) {
            filters.manifestDateFrom = moment(manifestDateFrom).format("yyyy-MM-DD");

        }
        if (manifestDateTo !== null) {
            filters.manifestDateTo = moment(manifestDateTo).format("yyyy-MM-DD");
        }

        await new ParcelsQueueService().getRecords(filters)
            .then(result => {
                setRecords(result.records);
                setPages(Array.from(Array(Math.ceil(result.count / pageSize)).keys()));
                setCount(result.count);
                setLoading(false);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setLoading(false);
                    })
            });


    }

    function search() {
        setPageNumber(0);
        setRefresh(!refresh);
    }

    async function addToQueue(){

        setLoadingAddToQueue(true);

        let filters = {}

        if ('' !== shipmentId) {
            filters.shipmentId = shipmentId;
        }

        if (manifestDateFrom !== null) {
            filters.manifestDateFrom = moment(manifestDateFrom).format("yyyy-MM-DD");

        }
        if (manifestDateTo !== null) {
            filters.manifestDateTo = moment(manifestDateTo).format("yyyy-MM-DD");
        }

        await new ParcelsQueueService().addToQueue(filters)
            .then(result => {
                setLoadingAddToQueue(false);
                fetchRecords();
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setLoadingAddToQueue(false);
                    })
            });



    }


    async function resetQueue() {

        await new ParcelsQueueService().resetQueue()
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                search();
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            });

    }

    async function emptyQueue() {

        await new ParcelsQueueService().deleteQueue()
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                search();
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            });

    }

    useEffect(() => {

        fetchRecords();

    }, [pageSize, pageNumber, refresh]);


    return <div>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={()=>setOpenNotify(false)}>{t(notificationMessage)}</Notification>
        <Card elevation={1} defaultExpanded={true}
              style={{width: "60%", margin: "auto", marginBottom: "1%"}}>
            <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                <Typography variant={"button"}>{t("filters")}</Typography>
            </DialogTitle>
            <div style={{alignItems: 'center', display: "flex"}}>
                <TextField
                    className={classes.filter}
                    label={t("shipment_id")}
                    value={shipmentId}
                    onChange={handleShipmentIdChange}
                    margin={"dense"}
                    variant={"outlined"}
                />
            </div>
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                    <Grid container justify="space-around">
                        <DatePicker
                            autoOk
                            disableToolbar
                            format="dd/MM/yyyy"
                            okLabel={null}
                            clearLabel={t("reset")}
                            cancelLabel={t("cancel")}
                            margin="dense"
                            inputVariant="outlined"
                            label={t("manifest_from_date")}
                            disableFuture
                            value={manifestDateFrom}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <DatePicker
                            autoOk
                            disableToolbar
                            format="dd/MM/yyyy"
                            okLabel={null}
                            clearLabel={t("reset")}
                            cancelLabel={t("cancel")}
                            margin="dense"
                            label={t("manifest_to_date")}
                            inputVariant="outlined"
                            disableFuture
                            value={manifestDateTo}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>

            </div>
            <div style={{textAlign: 'center'}}>
                <StyledButton icon={<SearchOutlined/>} style={{marginTop: "5%"}} onClick={search}>{t("search")}</StyledButton>
            </div>
        </Card>
        <div style={{textAlign: 'center'}}>
            <StyledButton style={{marginInline: "5%"}} onClick={emptyQueue}>{t("empty_queue")}</StyledButton>
            <StyledButton style={{marginInline: "5%"}} onClick={resetQueue}>{t("reset_queue")}</StyledButton>
            <StyledButton icon={loadingAddToQueue? <CircularProgress size={16}/> : null} disabled={loadingAddToQueue} style={{marginInline: "5%"}} onClick={addToQueue}>{t("add_to_queue")}</StyledButton>
        </div>
        <div>
            <InfoChip><Typography
                variant={"subtitle2"}>{t("shipment_count")} {count}</Typography></InfoChip>
        </div>
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>

                {(loading) ?
                    <Table>
                        <LoadingComponent/>
                    </Table>

                    :
                    <>

                        {records.length > 0 ?
                            <div>


                                <Table stickyHeader aria-label="sticky table">

                                    <TableHead>
                                        <TableRow>

                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("parcel_id")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("created_at")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={"inherit"}>  {t("last_try")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("error_count")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("last_error_message")} </Typography>
                                            </TableCell>
                                            <TableCell style={{width: "10%"}}>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.map((record, index) => {
                                            return <TableRow>
                                                <TableCell>
                                                    <Typography>{record.parcelId} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{formatDateTime(record.createdAt)} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{formatDateTime(record.lastTryDateTime)}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{record.errorCount} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{record.errorMessage} </Typography>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>

                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={-1}
                                    rowsPerPage={pageSize}
                                    page={pageNumber}

                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        variant: "outlined",
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        classes: {paper: classes.menuPaper}
                                    }}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={() => {
                                        return t("page")
                                    }}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    ActionsComponent={TablePaginationActions}
                                />

                            </div>
                            :
                            <div style={{height: 100}}>
                                <Typography style={{marginTop: "5%"}}>{t("no_records_found")}</Typography>
                            </div>
                        }
                    </>
                }

            </TableContainer>

        </Paper>
    </div>


}

export default ParcelsQueueTable;
