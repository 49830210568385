import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CostCenterService from "./CostCenterService";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination
} from "@material-ui/core";
import {PrivateRoute} from "../../routes/PrivateRoute";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CostCenter from "./CostCenter";
import Typography from "@material-ui/core/Typography";
import CostCenterForm from "./CostCenterForm";
import Notification from "../../components/notifications/Notification";
import {LoadingComponent} from "../../components/loading/LoadingComponent";


export function CostCenterTable(props) {

    let {t} = useTranslation();
    let history = useHistory();
    let location = useLocation();
    const [costCenters, setCostCenters] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [fetching, setFetching] = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            // maxHeight: 1840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            width: "27%"

        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();

    function deleteCallback()  {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");
        setRefresh(!refresh)

    }

    function closeNotification(){
        setOpenNotify(false);
    }

    function fetchCostCenters(){
        let costCenterService = new CostCenterService();
        costCenterService.getAllCostCenters()
            .then(data => {
                setCostCenters(data)
                setFetching(false);
            })
    }

    useEffect(()=>{
        fetchCostCenters();
    },[location, refresh])

    function goToCostCenterForm() {
        history.push("/cost-centers/new");
    }

    return <Paper className={classes.root}>

        {(fetching) ?
        <LoadingComponent/>
            :
            <Switch>
                <PrivateRoute exact path={"/cost-centers"}>



                    <Button startIcon={<AddCircleIcon/>} variant="contained" onClick={goToCostCenterForm} color="primary" className={classes.button}>
                        {t("create_new_cost_center")}
                    </Button>

                    {(costCenters.length > 0) ?
                        <TableContainer className={classes.container}>



                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableCell>ID</TableCell>
                                    <TableCell className={classes.cell}>{t("name")}</TableCell>
                                    <TableCell className={classes.cell}>{t("code")}</TableCell>
                                    <TableCell className={classes.cell}/>

                                </TableHead>
                                <TableBody>
                                    {costCenters.slice(page*rowsPerPage, page* rowsPerPage + rowsPerPage).map((costCenter, index) => {
                                        return <CostCenter deleteCallback={deleteCallback} costCenter = {costCenter}/>
                                    })}
                                </TableBody>
                                <TablePagination
                                    rowsPerPageOptions={[10,25,100]}
                                    count={costCenters.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' di ' + count
                                        }
                                    }
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Table>
                            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                          onClose={closeNotification}>{notificationMessage}</Notification>

                        </TableContainer>

                        : <div>

                            <Typography variant="overline" align="center">{t("empty_table_message")}</Typography>
                        </div>}

                </PrivateRoute>
                <PrivateRoute exact path={`/cost-centers/new/`}>
                    <CostCenterForm costCenter={null}/>
                </PrivateRoute>
                <PrivateRoute strict path={`/cost-centers/:id/edit`} children={<CostCenterForm/>}/>
            </Switch>
        }


    </Paper>


} export default CostCenterTable;