import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {formatDateTime} from "../utils/utils";


function Rating(props){

    let rating = props.rating;

    return <TableRow>
        <TableCell>
            {rating.id}
        </TableCell>
        <TableCell>
            {formatDateTime(rating.createdAt)}
        </TableCell>
        <TableCell>
            {rating.shipmentId}
        </TableCell>
        <TableCell>
            {rating.score}
        </TableCell>
        <TableCell>
            {rating.comment}
        </TableCell>
        <TableCell>
            {rating.ratingOption?.locale?.text}
        </TableCell>
        <TableCell>
        </TableCell>
    </TableRow>



} export default Rating;