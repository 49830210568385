import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActionArea,
    CardHeader,
    DialogTitle,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import ShipmentService from "./ShipmentService";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Parcel from "./Parcel";
import WarehouseService from "../warehouses/WarehouseService";
import SummaryInService from "./services/SummaryInService";
import {ArrowBackIosRounded, Create, Download, LocationOn, SendToMobile} from "@mui/icons-material";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import PodService from "./services/PodService";
import {
    Autocomplete,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Modal,
    TextField
} from "@mui/material";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import Notification from "../components/notifications/Notification";
import {MinimalLoadingComponent} from "../components/loading/MinimalLoadingComponent";
import {LoadingButton} from "@mui/lab";
import theme from "../theme/theme";
import WarehouseStorageService from "../warehouse_storages/WarehouseStorageService";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import NoteTooltip from "../components/NoteTooltip";
import QuoteService from "../quotes/QuoteService";
import AdminTab from "./tabs/AdminTab";
import {
    AddCircleOutlineOutlined,
    ArrowBackIos,
    ArrowBackOutlined,
    ArrowForwardIos,
    Cached,
    CalendarToday,
    Cancel,
    CancelOutlined,
    Edit,
    EditOutlined,
    SaveOutlined
} from "@material-ui/icons";
import {format, isBefore, isEqual} from "date-fns";
import SlotService from "../districts/slot/SlotService";
import BookingService from "../tracking/BookingService";
import variables from "../configuration/variables.json";
import CodService from "./services/CodService";
import CodEventCodeService from "./services/CodEventCodeService";
import DriverService from "../drivers/DriverService";
import ServiceService from "./services/ServiceService";
import ParcelsTab from "./tabs/ParcelsTab";
import ServiceLevelsService from "../timings/ServiceLevelsService";
import TaskQueueService from "../timings/TaskQueueService";
import AmountChip from "../components/chips/AmountChip";
import InfoChip from "../components/chips/InfoChip";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import CodEventService from "./services/CodEventService";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import DeleteCodEventRequestsService from "../cod/delete_cod_events_requests/DeleteCodEventRequestsService";
import ShipmentLogsTab from "./tabs/ShipmentLogsTab";
import DialogContentText from "@material-ui/core/DialogContentText";
import StyledButton from "../components/buttons/StyledButton";
import {formatDateTime} from "../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    paper: {
        width: "100%",
        maxWidth: 1024,
        minWidth: 600,
        marginBottom: "2%"
    },
    pod: {
        width: "100%",
        marginBottom: "2%"
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        width: '100%',
        maxHeight: 840,
    },
    table: {
        display: "inline-block",
        width: '40%',
        margin: "auto"
    },
    button: {
        marginTop: "3%",
        marginBottom: "3%"
    },
    field: {
        margin: "auto",
        marginBottom: "2%",
        width: "60%"
    },
    comCard: {
        width: "60%",
        margin: "auto",
        marginBlock: "3%"

    },
    noteCard: {
        width: "60%",
        margin: "auto",
        marginBlock: "3%"
    },
    commColumn: {
        width: 600,
        marginBottom: "2%",
        justifyContent: "center"
    },
    noteColumn: {
        marginBottom: "2%",
        justifyContent: "center",
        paddingBottom: "4%"
    },
    noteIcon: {
        cursor: "pointer",
        transition: "all 0.2s",

        "&:hover": {

            background: '#ebebeb'


        },
        '& > *': {
            borderBottom: 'unset',
        },
    },
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    },
    calendar: {
        width: "80%",
        marginInline: "auto",
        marginBlock: "15%",
        height: "100%",
        overflow: "hidden"
    },
    calendarTable: {
        width: "100%",
        marginInline: "auto",
        marginBlock: "5%",
        height: "100%",
        overflow: "hidden"
    },
}));


function AboutMe(props) {

    const {id} = useParams();
    const history = useHistory();
    const classes = useStyles();

    let permissions = localStorage.getItem("permissions");

    const location = useLocation();

    const [newNote, setNewNote] = useState('');
    const [noteType, setNoteType] = useState("GENERIC")

    let types = ["GENERIC", "DELIVERY", "PICKUP", "INTERNAL"]

    let codDeleteEventRequestAllowed = [2, 3, 4, 5, 6, 10, 14];
    let codUpdateEventRequestAllowed = [2, 3, 4, 5, 6];
    let codCannotBeDeletedAnymoreEvents = [9, 11, 12, 13, 15]

    const [codEventCodesAvailable, setCodEventCodesAvailable] = useState([]);

    const [openNewNote, setOpenNewNote] = useState(false);

    const [loadingDeliveryAddressUpdate, setLoadingDeliveryAddressUpdate] = useState(false);
    const [loadingEndUserAddressUpdate, setLoadingEndUserAddressUpdate] = useState(false);
    const [loadingSenderAddressUpdate, setLoadingSenderAddressUpdate] = useState(false);
    const [isUpdatingDeliveryAddress, setUpdatingDeliveryAddress] = useState(false);
    const [isUpdatingEndUserAddress, setUpdatingEndUserAddress] = useState(false);
    const [isUpdatingSenderAddress, setUpdatingSenderAddress] = useState(false);
    const [isUpdatingService, setUpdatingService] = useState(false);

    const [loadingSenderAddress, setLoadingSenderAddress] = useState(false);
    const [loadingDeliveryAddress, setLoadingDeliveryAddress] = useState(false);
    const [loadingEndUserAddress, setLoadingEndUserAddress] = useState(false);
    const [loadingServiceUpdate, setLoadingServiceUpdate] = useState(false);

    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);

    const [warehouseStorages, setWarehouseStorages] = useState([]);

    const [tabNumber, setTabNumber] = useState('1');

    const [smsCommunications, setSmsCommunications] = useState([]);
    const [emailCommunications, setEmailCommunications] = useState([]);

    const [logs, setLogs] = useState([]);

    // const window = (new JSDOM('')).window
    // const DOMPurify = createDOMPurify(window);

    const [openBooking, setOpenBooking] = useState(false);
    const [bookingDate, setBookingDate] = useState('');
    const [bookingSlot, setBookingSlot] = useState(null);
    const [calendar, setCalendar] = useState([]);
    const [calendarBatchCount, setCalendarBatchCount] = useState(0);
    const [today, setToday] = useState(Date.now());
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [districtId, setDistrictId] = useState(null);

    const [codEventIdToDelete, setCodEventIdToDelete] = useState(null);

    const [refresh, setRefresh] = useState(true);

    const [destination, setDestination] = useState({});

    const [shipmentData, setShipmentData] = useState({});
    const [quotes, setQuotes] = useState([]);
    const [accountingRows, setAccountingRows] = useState([]);
    const [preInvoiceRows, setPreInvoiceRows] = useState([]);
    const [senderAddress, setSenderAddress] = useState({});
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [endUserAddress, setEndUserAddress] = useState({});
    const [auth, setAuth] = useState(null);
    const [parcels, setParcels] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [events, setEvents] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [createdDate, setCreatedDate] = useState(null);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [summary, setSummary] = useState(null);
    const [pod, setPod] = useState(null);
    const [gpsVisible, setGpsVisible] = useState(false);
    const [cod, setCod] = useState(null);
    const [codEvents, setCodEvents] = useState([]);
    const [partialDepartureDisabled, setPartialDepartureDisabled] = useState(false);
    const [partialDeliveryDisabled, setPartialDeliveryDisabled] = useState(false);

    const [updateCodValue, setUpdateCodValue] = useState(0);
    const [updateCodMode, setUpdateCodMode] = useState(null);
    const [updateCodCurrency, setUpdateCodCurrency] = useState(null);

    const [codModes, setCodModes] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [updateCod, setUpdateCod] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');

    const [countries, setCountries] = useState([]);
    const [administrativeLevel3, setAdministrativeLevel3] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [drivers, setDrivers] = useState([]);

    const [loadingSMS, setLoadingSMS] = useState(false);

    const [freeDeliveryCity, setFreeDeliveryCity] = useState('');
    const [freeDeliveryAdministrativeLevel1, setFreeDeliveryAdministrativeLevel1] = useState('');
    const [freeDeliveryAdministrativeLevel2, setFreeDeliveryAdministrativeLevel2] = useState('');
    const [freeDeliveryAdministrativeLevel3, setFreeDeliveryAdministrativeLevel3] = useState('');
    const [freeDeliveryPostalCode, setFreeDeliveryPostalCode] = useState('');

    const [deliveryCountry, setDeliveryCountry] = useState({});
    const [deliveryCompanyName, setDeliveryCompanyName] = useState('');
    const [deliveryReferencePerson, setDeliveryReferencePerson] = useState('');
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryBuildingNr, setDeliveryBuildingNr] = useState('');
    const [deliveryPostalCode, setDeliveryPostalCode] = useState({});
    const [deliveryCity, setDeliveryCity] = useState({});
    const [deliveryAdministrativeLevel3, setDeliveryAdministrativeLevel3] = useState({});
    const [deliveryPhone, setDeliveryPhone] = useState('');
    const [deliveryMobile, setDeliveryMobile] = useState('');
    const [deliveryEmail, setDeliveryEmail] = useState('');

    const [freeEndUserCity, setFreeEndUserCity] = useState('');
    const [freeEndUserAdministrativeLevel1, setFreeEndUserAdministrativeLevel1] = useState('');
    const [freeEndUserAdministrativeLevel2, setFreeEndUserAdministrativeLevel2] = useState('');
    const [freeEndUserAdministrativeLevel3, setFreeEndUserAdministrativeLevel3] = useState('');
    const [freeEndUserPostalCode, setFreeEndUserPostalCode] = useState('');

    const [endUserCountry, setEndUserCountry] = useState({});
    const [endUserCompanyName, setEndUserCompanyName] = useState('');
    const [endUserReferencePerson, setEndUserReferencePerson] = useState('');
    const [endUserStreet, setEndUserStreet] = useState('');
    const [endUserBuildingNr, setEndUserBuildingNr] = useState('');
    const [endUserPostalCode, setEndUserPostalCode] = useState({});
    const [endUserCity, setEndUserCity] = useState({});
    const [endUserAdministrativeLevel3, setEndUserAdministrativeLevel3] = useState({});
    const [endUserPhone, setEndUserPhone] = useState('');
    const [endUserMobile, setEndUserMobile] = useState('');
    const [endUserEmail, setEndUserEmail] = useState('');

    const [openDeleteCodEventConfirm, setOpenDeleteCodEventConfirm] = useState(false);
    const [openRequestDeleteConfirm, setOpenRequestDeleteConfirm] = useState(false);
    const [openUpdateCodEvent, setOpenUpdateCodEvent] = useState(false);

    const [codCollectionVariationType, setCodCollectionVariationType] = useState('DELETE');
    const [newCodCollectionEventCode, setNewCodCollectionEventCode] = useState(null);

    const [freeSenderCity, setFreeSenderCity] = useState('');
    const [freeSenderAdministrativeLevel1, setFreeSenderAdministrativeLevel1] = useState('');
    const [freeSenderAdministrativeLevel2, setFreeSenderAdministrativeLevel2] = useState('');
    const [freeSenderAdministrativeLevel3, setFreeSenderAdministrativeLevel3] = useState('');
    const [freeSenderPostalCode, setFreeSenderPostalCode] = useState('');

    const [senderCountry, setSenderCountry] = useState({});
    const [senderCompanyName, setSenderCompanyName] = useState('');
    const [senderReferencePerson, setSenderReferencePerson] = useState('');
    const [senderStreet, setSenderStreet] = useState('');
    const [senderBuildingNr, setSenderBuildingNr] = useState('');
    const [senderPostalCode, setSenderPostalCode] = useState({});
    const [senderCity, setSenderCity] = useState({});
    const [senderAdministrativeLevel3, setSenderAdministrativeLevel3] = useState({});
    const [senderPhone, setSenderPhone] = useState('');
    const [senderMobile, setSenderMobile] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    const [warehouses, setWarehouses] = useState([]);
    const [shipmentEventCodes, setShipmentEventCodes] = useState({});
    const [parcelEventCodes, setParcelEventCodes] = useState({});

    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();

    const [mapHeight, setMapHeight] = useState(300);

    const PodImg = ({data, alt}) => <img style={{width: "100%", height: "100%", margin: 'auto'}}
                                         src={`data:image/jpeg;base64,${data}`} alt={alt}/>

    function handleTypeChange(event) {
        setNewCodCollectionEventCode(null);
        setCodCollectionVariationType(event.target.value);
    }

    function handleNewCodCollectionEventCodeChange(event, code) {
        setNewCodCollectionEventCode(code);
    }

    const GMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{lat: props.latitude, lng: props.longitude}}
        >
            {<Marker position={{lat: props.latitude, lng: props.longitude}}/>}
        </GoogleMap>
    ))

    function handleMapHeight(pod) {
        if (pod.signature !== null) {
            setMapHeight(400);
        }
    }

    async function fetchCodEventsAvailable(cod) {

        if (cod) {
            await new CodEventCodeService().getCodEventCodesForCodMode(cod?.codModeId)
                .then(result => {
                    setCodEventCodesAvailable(result);
                })
                .catch(error => {
                    // error.response.json().then(response => {
                    //     setNotifySeverity('error');
                    //     setNotificationMessage(response.message);
                    //     setOpenNotify(true);
                    // })
                })
        }

    }


    async function updateBlocks(){

        let input = {};

        input.partialDepartureDisabled = partialDepartureDisabled;
        input.partialDeliveryDisabled = partialDeliveryDisabled;

        await new ShipmentService().updateBlocks(id, input)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

    }

    async function fetchLogs() {


        await new ShipmentService().getShipmentLogs(id)
            .then(result => {
                setLogs(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })


    }

    function openConfirmDelete(id) {
        setCodEventIdToDelete(id);
        setOpenDeleteCodEventConfirm(true);
    }

    function openRequestDelete(id) {
        setCodEventIdToDelete(id);
        setOpenRequestDeleteConfirm(true);
    }

    function openUpdate(id) {
        setCodEventIdToDelete(id);
        setOpenUpdateCodEvent(true);
    }

    function checkRequestDisabled(codEvent, codEvents) {

        let chequeEventCodes = [3, 4, 5, 6];

        if (codEvent.eventCodeId === 2 && codEvents.find(codEvent => codEvent.eventCodeId === 9)) {
            return true;
        }

        if (codEvent.eventCodeId === 10 && codEvents.find(codEvent => codEvent.eventCodeId === 9)) {
            return true;
        }

        if (codEvent.eventCodeId === 14 && codEvents.find(codEvent => codEvent.eventCodeId === 13)) {
            return true;
        }

        return chequeEventCodes.includes(codEvent.eventCodeId) && codEvents.find(codEvent => codEvent.eventCodeId === 13);

    }

    async function deleteCodEvent() {

        await new CodEventService().deleteCodEvent(codEventIdToDelete)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setOpenDeleteCodEventConfirm(false);
                setRefresh(!refresh)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })


    }

    async function sendDeleteRequest() {

        let input = {};

        input.codEventId = codEventIdToDelete;

        if (newCodCollectionEventCode) {
            input.newCodEventCodeId = newCodCollectionEventCode.id;
        }

        await new DeleteCodEventRequestsService().sendDeleteCodEventIdRequest(input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setOpenRequestDeleteConfirm(false);
                setRefresh(!refresh)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

    }

    function openCalendar() {
        setOpenBooking(true);
        fetchNewWeek(calendarBatchCount);
    }

    function closeCalendar() {
        setOpenBooking(false);
        setCalendarBatchCount(0);
    }

    async function fetchNewWeek(batch) {
        setCalendarLoading(true);

        let dates = [];

        for (let i = 0; i < 7; i++) {
            let date = today + (7 * batch * 86400000) + 86400000 + i * 86400000;
            let formattedDate = format(date, "yyyy-MM-dd");
            dates.push(formattedDate);
        }

        let slotService = new SlotService();

        let address = shipmentData.deliveryAddress;

        if (shipmentData.service.code === "R") {
            address = shipmentData.pickupAddress;
        }

        await slotService.getBookingSlotsByAddress(dates, shipmentData.service.code, address, false)
            .then(data => {
                let datesAndSlotsInputDto = [];


                data.dateAndSlots.map(value => {
                    let auxDateAndSlots = {};
                    auxDateAndSlots.date = value.date;
                    auxDateAndSlots.slots = [];
                    value.slots.map(slot => {
                        let auxSlot = {};
                        auxSlot.fromTime = slot.fromTime;
                        auxSlot.toTime = slot.toTime;

                        auxDateAndSlots.slots.push(auxSlot);

                    })
                    datesAndSlotsInputDto.push(auxDateAndSlots);
                })

                setDistrictId(data.district.id);

                slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                    .then(responseData => {
                        setCalendar(responseData);
                    })
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotifySeverity('error');
                            setNotificationMessage(response.message);
                            setOpenNotify(true);
                        })
                    })
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

        setTimeout(() => {
            setCalendarLoading(false);
        }, 1000);

    }

    function fetchPreviousWeek() {
        let batch = calendarBatchCount;
        batch -= 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function fetchNextWeek() {
        let batch = calendarBatchCount;
        batch += 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function formatDate(date) {
        if (null !== date) {
            let parsedDate = new Date(date);
            return parsedDate.toLocaleDateString();
        } else {
            return "";
        }


    }

    function returnCodDocumentLabel(codEvent){

        switch (codEvent.eventCodeId){
            case 10: return t("payment_slip") + " N. " + cod?.paymentSlipId;
            case 11: {
                return cod.paymentToCustomerId !== null ?
                    t("payment_to_customer") + " N. " + cod?.paymentToCustomerId :
                    t("cheque_payment_to_customer") + " N. " + cod?.chequePaymentToCustomerId;
            }
            case 14: return t("cheque_forwarding") + " N. " + cod?.chequeForwardingId;
            case 15: return t("cheque_payment_to_customer") + " N. " + cod?.chequePaymentToCustomerId;
            default:
                break;
        }
    }

    async function confirmBooking(date, slot) {

        setCalendarLoading(true);

        await new BookingService().createBooking(date, shipmentData.id, slot.id, districtId)
            .then(result => {
                setOpenNotify(true);
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setRefresh(!refresh);
                setOpenBooking(false);

            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

        setCalendarLoading(false);
    }

    async function cancelBooking(bookingId) {

        await new BookingService().cancelBooking(bookingId)
            .then(result => {
                setOpenNotify(true);
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })


    }

    function handleTabChange(event, newValue) {
        setTabNumber(newValue);
        setOpenNewNote(false);
    }

    function goToAboutMe(id) {
        history.push(`/shipments/${id}/aboutMe`)
    }

    function fetchCodModes() {

        let filters = {};

        if (shipmentData) {
            filters.contractId = shipmentData.contract.id;
        }

        let shipmentService = new ShipmentService();
        shipmentService.getCodModes(filters)
            .then(codModes => {
                setCodModes(codModes);
            })
            .then(error => {

            })
    }

    function fetchCurrencies() {
        let shipmentService = new ShipmentService();
        shipmentService.getCurrencies({isEnabled: true})
            .then(currencies => {
                setCurrencies(currencies);
                if (updateCodCurrency === null) {
                    setUpdateCodCurrency(currencies[0]);
                }
            })
            .then(error => {

            })
    }

    function fetchShipmentCommunications() {

        let shipmentService = new ShipmentService();
        shipmentService.getShipmentCommunications(id)
            .then(response => {
                setSmsCommunications(response.filter(communication => communication.type === "SMS"));
                setEmailCommunications(response.filter(communication => communication.type === "Email"))
            })
            .catch(error => {

            })
    }

    async function fetchAccountingRows() {


        let accRows = await new ShipmentService().getAccountingRows(id)
            .catch(error => {

            })
        setAccountingRows(accRows)
    }

    async function fetchPreInvoiceRows() {
        let preInv = [];
        let actives = await new ShipmentService().getActivePreInvoiceRows(id)
            .catch(error => {

            })

        if (actives.length > 0) {
            for (let i = 0; i < actives.length; i++) {
                preInv.push(actives[i]);
            }

        }

        let passives = await new ShipmentService().getPassivePreInvoiceRows(id)
            .catch(error => {

            })
        if (passives.length > 0) {
            for (let i = 0; i < passives.length; i++) {
                preInv.push(passives[i]);
            }

        }

        setPreInvoiceRows(preInv)
    }

    async function fetchQuotes() {

        let quotes = await new QuoteService().getQuotesOfShipment(id)
            .catch(error => {

            })
        setQuotes(quotes);

    }

    function setUpdateSenderAddress() {
        setLoadingSenderAddressUpdate(true)

        setTimeout(() => {
            setUpdatingSenderAddress(true)
            setLoadingSenderAddressUpdate(false);
        }, 1500)
    }

    function setUpdateDeliveryAddress() {
        setLoadingDeliveryAddressUpdate(true)

        setTimeout(() => {
            setUpdatingDeliveryAddress(true)
            setLoadingDeliveryAddressUpdate(false);
        }, 1500)
    }

    function setUpdateEndUserAddress() {
        setLoadingEndUserAddressUpdate(true)

        setTimeout(() => {
            setUpdatingEndUserAddress(true)
            setLoadingEndUserAddressUpdate(false);
        }, 1500)
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    function fetchAdministrativeLevel3(country) {

        let filters = {}

        if (country) {
            filters.countryId = country.id;
        }
        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdministrativeLevel3(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function handleSenderCountryChange(event, country) {
        setLoadingSenderAddress(true)
        setSenderCountry(country);
        setSenderAdministrativeLevel3(null);
        setSenderCity(null);
        setSenderPostalCode(null);
        setSenderStreet('');
        setSenderBuildingNr('');
        setTimeout(() => {
            setLoadingSenderAddress(false);
        }, 1000)

    }

    function handleSenderAdministrativeLevel3Change(event, adminl3) {
        setSenderAdministrativeLevel3(adminl3);
        setSenderCity(null);
        setSenderPostalCode(null);
    }

    function handleSenderCityChange(event, city) {
        setSenderCity(city);
        setSenderPostalCode(null);
    }

    function handleSenderPostalCodeChange(event, postalCode) {
        setSenderPostalCode(postalCode);
    }

    function handleSenderStreetChange(event, street) {
        setSenderStreet(event.target.value);
    }

    function handleSenderBuildingNrChange(event, buildingNr) {
        setSenderBuildingNr(event.target.value);
    }

    function handleSenderCompanyNameChange(event, companyName) {
        setSenderCompanyName(event.target.value);
    }

    function handleSenderReferencePerson(event, referencePerson) {
        setSenderReferencePerson(event.target.value);
    }

    function handleSenderEmail(event) {
        setSenderEmail(event.target.value);
    }

    function handleSenderPhone(event) {
        setSenderPhone(event.target.value);
    }

    function handleSenderMobile(event) {
        setSenderMobile(event.target.value);
    }

    function handleFreeSenderAdministrativeLevel1Change(event) {
        setFreeSenderAdministrativeLevel1(event.target.value);
    }

    function handleFreeSenderAdministrativeLevel2Change(event) {
        setFreeSenderAdministrativeLevel2(event.target.value);
    }

    function handleFreeSenderAdministrativeLevel3Change(event) {
        setFreeSenderAdministrativeLevel3(event.target.value);
    }

    function handleFreeSenderCityChange(event) {
        setFreeSenderCity(event.target.value);
    }

    function handleFreeSenderPostalCodeChange(event) {
        setFreeSenderPostalCode(event.target.value);
    }


    function handleDeliveryCountryChange(event, country) {
        setLoadingDeliveryAddress(true)
        setDeliveryCountry(country);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);
        setDeliveryStreet('');
        setDeliveryBuildingNr('');
        setTimeout(() => {
            setLoadingDeliveryAddress(false);
        }, 1000)

    }

    function handleDeliveryAdministrativeLevel3Change(event, adminl3) {
        setDeliveryAdministrativeLevel3(adminl3);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);
    }

    function handleDeliveryCityChange(event, city) {
        setDeliveryCity(city);
        setDeliveryPostalCode(null);
    }

    function handleDeliveryPostalCodeChange(event, postalCode) {
        setDeliveryPostalCode(postalCode);
    }

    function handleDeliveryStreetChange(event, street) {
        setDeliveryStreet(event.target.value);
    }

    function handleDeliveryBuildingNrChange(event, buildingNr) {
        setDeliveryBuildingNr(event.target.value);
    }

    function handleDeliveryCompanyNameChange(event, companyName) {
        setDeliveryCompanyName(event.target.value);
    }

    function handleDeliveryReferencePerson(event, referencePerson) {
        setDeliveryReferencePerson(event.target.value);
    }

    function handleDeliveryEmail(event) {
        setDeliveryEmail(event.target.value);
    }

    function handleDeliveryPhone(event) {
        setDeliveryPhone(event.target.value);
    }

    function handleDeliveryMobile(event) {
        setDeliveryMobile(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel1Change(event) {
        setFreeDeliveryAdministrativeLevel1(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel2Change(event) {
        setFreeDeliveryAdministrativeLevel2(event.target.value);
    }

    function handleFreeDeliveryAdministrativeLevel3Change(event) {
        setFreeDeliveryAdministrativeLevel3(event.target.value);
    }

    function handleFreeDeliveryCityChange(event) {
        setFreeDeliveryCity(event.target.value);
    }

    function handleFreeDeliveryPostalCodeChange(event) {
        setFreeDeliveryPostalCode(event.target.value);
    }

    function handleEndUserCountryChange(event, country) {
        setLoadingEndUserAddress(true)
        setEndUserCountry(country);
        setEndUserAdministrativeLevel3(null);
        setEndUserCity(null);
        setEndUserPostalCode(null);
        setEndUserStreet('');
        setEndUserBuildingNr('');
        setTimeout(() => {
            setLoadingEndUserAddress(false);
        }, 1000)

    }

    function handleEndUserAdministrativeLevel3Change(event, adminl3) {
        setEndUserAdministrativeLevel3(adminl3);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleEndUserCityChange(event, city) {
        setEndUserCity(city);
        setEndUserPostalCode(null);
    }

    function handleEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(postalCode);
    }

    function handleEndUserStreetChange(event, street) {
        setEndUserStreet(event.target.value);
    }

    function handleEndUserBuildingNrChange(event, buildingNr) {
        setEndUserBuildingNr(event.target.value);
    }

    function handleEndUserCompanyNameChange(event, companyName) {
        setEndUserCompanyName(event.target.value);
    }

    function handleEndUserReferencePerson(event, referencePerson) {
        setEndUserReferencePerson(event.target.value);
    }

    function handleEndUserEmail(event) {
        setEndUserEmail(event.target.value);
    }

    function handleEndUserPhone(event) {
        setEndUserPhone(event.target.value);
    }

    function handleEndUserMobile(event) {
        setEndUserMobile(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel1Change(event) {
        setFreeEndUserAdministrativeLevel1(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel2Change(event) {
        setFreeEndUserAdministrativeLevel2(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel3Change(event) {
        setFreeEndUserAdministrativeLevel3(event.target.value);
    }

    function handleFreeEndUserCityChange(event) {
        setFreeEndUserCity(event.target.value);
    }

    function handleFreeEndUserPostalCodeChange(event) {
        setFreeEndUserPostalCode(event.target.value);
    }

    function handleNewNoteChange(event) {
        setNewNote(event.target.value)
    }

    function handleNoteTypeChange(event) {
        setNoteType(event.target.value)
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    async function refreshTasks() {
        await new TaskQueueService().refreshSynchronously(id)
            .then(result => {
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    function taskColor(task) {
        let expectedClosingDate = task.expectedEndDateTime ? new Date(task.expectedEndDateTime) : null;
        let closingDate = task.endDateTime ? new Date(task.endDateTime) : null;

        if (!closingDate || !expectedClosingDate) {
            return "grey";
        }

        if (isBefore(closingDate, expectedClosingDate) || isEqual(closingDate, expectedClosingDate)) {
            return "green";
        } else {
            return "red";
        }
    }

    async function saveNote() {
        setLoadingDeliveryAddressUpdate(true);

        let input = {};

        input.note = newNote;
        input.noteType = noteType;

        await new ShipmentService().saveNote(id, input)
            .then(success => {
                setRefresh(!refresh);
                setLoadingDeliveryAddressUpdate(false);
                setOpenNewNote(false);
                setNewNote('');
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoadingDeliveryAddressUpdate(false);
                });
            })
    }

    function openNoteForm() {
        setOpenNewNote(true);
    }

    function fetchWarehouses() {

        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters()
            .then(data => {
                let map = {}
                for (let i = 0; i < data.warehouses.length; i++) {
                    map[data.warehouses[i].id] = data.warehouses[i];
                }
                setWarehouses(map);

            })
            .catch(error => {

            })

    }

    async function fetchDrivers() {

        let filters = {};

        filters.includeDeleted = true;

        await new DriverService().getDriversByFilters(filters)
            .then(data => {
                let map = {}
                for (let i = 0; i < data.drivers.length; i++) {
                    map[data.drivers[i].id] = data.drivers[i];
                }
                setDrivers(map);

            })
            .catch(error => {

            })

    }

    function fetchBookings() {
        let shipmentService = new ShipmentService();
        shipmentService.getBookings(id)
            .then(data => {
                setBookings(data);
            })
    }

    function fetchEventCodes() {
        let shipmentService = new ShipmentService();

        shipmentService.getParcelEventCodes()
            .then(data => {

                    let map = {}
                    for (let i = 0; i < data.length; i++) {
                        map[data[i].id] = data[i];
                    }
                    setParcelEventCodes(map)
                }
            )
            .catch(error => {

            })

        shipmentService.getShipmentEventCodes()
            .then(data => {

                let map = {}
                for (let i = 0; i < data.length; i++) {
                    map[data[i].id] = data[i];
                }
                setShipmentEventCodes(map);
            })
            .catch(error => {

            })
    }

    async function fetchShipmentTasks() {


        await new ServiceLevelsService().getByShipmentId(id)
            .then(response => {
                setTasks(response);
            })
            .catch(error => {

            })

    }


    function fetchParcels() {

        let shipmentService = new ShipmentService();
        shipmentService.getParcelsOfShipment(id)
            .then(response => {
                setParcels(response)

            })
            .catch(error => {

            })
    }

    function fetchEvents() {
        let shipmentService = new ShipmentService();
        shipmentService.getEventsOfShipment(id)
            .then(response => {
                setEvents(response)
            })
            .catch(error => {

            })
    }

    function fetchSummaryIn() {
        let filters = {};
        filters.shipmentId = id;

        let summaryInService = new SummaryInService();
        summaryInService.getSummary(filters)
            .then(data => {
                setSummary(data)
            })
            .catch(error => {

            })
    }

    async function refreshDate(type) {

        await new ShipmentService().refreshEstimatedDeliveryDate(id, type)
            .then(response => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }

    function sendSMS() {
        setLoadingSMS(true);
        let shipmentService = new ShipmentService();
        shipmentService.sendSMS(id)
            .then(response => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);
                setLoadingSMS(false)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoadingSMS(false);
                });
            })
    }

    async function setSenderAddressInputs(address) {
        let country = {};
        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null)
            .then(data => {
                if (address.country.length === 2) {
                    country = data.countries.filter(country => country.iso3166Alpha2 === address.country)[0];
                } else {
                    country = data.countries.filter(country => country.iso3166Alpha3 === address.country)[0]
                }

            })

        setSenderCountry(country);

        let city = {}
        let administrative3 = {}
        let postalCode = {}


        if (address.country === "IT" || address.country === "ITA" || address.country === "ES" || address.country === "ESP" || address.country === "DE" || address.country === "DEU") {

            if (null !== address.administrative_level_3) {
                let admin3Service = new AdministrativeLevel3Service();
                await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                    .then(data => {
                        if (address.administrative_level_3 !== null) {
                            administrative3 = data.admins3.filter(admin3 => admin3.code === address.administrative_level_3)[0]
                        } else {
                            administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                        }
                    })
            }


            if (null !== address.city) {
                let cityService = new CityService();
                await cityService.getCities(null, null, null, null)
                    .then(data => {
                        city = data.cities.filter(city => city.name.toLowerCase() === address.city.toLowerCase())[0]
                    })
            }

            if (null !== address.postalCode) {
                let postalCodeService = new PostalCodeService();
                await postalCodeService.getAllPostalCodes(null, null, null, null)
                    .then(data => {
                        postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === address.postalCode)[0];
                    })
            }


            setSenderCity(city);
            setSenderAdministrativeLevel3(administrative3);
            setSenderPostalCode(postalCode);
        } else {
            setFreeSenderAdministrativeLevel1(address.administrative_level_1);
            setFreeSenderAdministrativeLevel2(address.administrative_level_2);
            setFreeSenderAdministrativeLevel3(address.administrative_level_3);
            setFreeSenderCity(address.city);
            setFreeSenderPostalCode(address.postalCode);
        }

        setSenderCompanyName(address.companyName);
        setSenderStreet(address.street);
        setSenderBuildingNr(address.buildingNr);
        setSenderPhone(address.phone);
        setSenderMobile(address.mobile);
        setSenderEmail(address.email);
        setSenderReferencePerson(address.referencePerson);
        setSenderCompanyName(address.companyName);

    }

    async function setDeliveryAddressInputs(address) {
        let country = {};
        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null)
            .then(data => {
                if (address.country.length === 2) {
                    country = data.countries.filter(country => country.iso3166Alpha2 === address.country)[0];
                } else {
                    country = data.countries.filter(country => country.iso3166Alpha3 === address.country)[0]
                }

            })

        setDeliveryCountry(country);

        let city = {}
        let administrative3 = {}
        let postalCode = {}


        if (address.country === "IT" || address.country === "ITA" || address.country === "ES" || address.country === "ESP" || address.country === "DE" || address.country === "DEU") {

            if (null !== address.administrative_level_3) {
                let admin3Service = new AdministrativeLevel3Service();
                await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                    .then(data => {
                        if (address.administrative_level_3 !== null) {
                            administrative3 = data.admins3.filter(admin3 => admin3.code === address.administrative_level_3)[0]
                        } else {
                            administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                        }
                    })
            }


            if (null !== address.city) {
                let cityService = new CityService();
                await cityService.getCities(null, null, null, null)
                    .then(data => {
                        city = data.cities.filter(city => city.name.toLowerCase() === address.city.toLowerCase())[0]
                    })
            }

            if (null !== address.postalCode) {
                let postalCodeService = new PostalCodeService();
                await postalCodeService.getAllPostalCodes(null, null, null, null)
                    .then(data => {
                        postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === address.postalCode)[0];
                    })
            }


            setDeliveryCity(city);
            setDeliveryAdministrativeLevel3(administrative3);
            setDeliveryPostalCode(postalCode);
        } else {
            setFreeDeliveryAdministrativeLevel1(address.administrative_level_1);
            setFreeDeliveryAdministrativeLevel2(address.administrative_level_2);
            setFreeDeliveryAdministrativeLevel3(address.administrative_level_3);
            setFreeDeliveryCity(address.city);
            setFreeDeliveryPostalCode(address.postalCode);
        }

        setDeliveryCompanyName(address.companyName);
        setDeliveryStreet(address.street);
        setDeliveryBuildingNr(address.buildingNr);
        setDeliveryPhone(address.phone);
        setDeliveryMobile(address.mobile);
        setDeliveryEmail(address.email);
        setDeliveryReferencePerson(address.referencePerson);
        setDeliveryCompanyName(address.companyName);

    }

    async function setEndUserAddressInputs(address) {
        let country = {};
        let countryService = new CountryService();
        await countryService.getCountriesByFilters(null, null, null, null)
            .then(data => {
                if (address.country.length === 2) {
                    country = data.countries.filter(country => country.iso3166Alpha2 === address.country)[0];
                } else {
                    country = data.countries.filter(country => country.iso3166Alpha3 === address.country)[0]
                }

            })

        setEndUserCountry(country);

        let city = {}
        let administrative3 = {}
        let postalCode = {}


        if (address.country === "IT" || address.country === "ITA" || address.country === "ES" || address.country === "ESP" || address.country === "DE" || address.country === "DEU") {

            if (null !== address.administrative_level_3) {
                let admin3Service = new AdministrativeLevel3Service();
                await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                    .then(data => {
                        if (address.administrative_level_3 !== null) {
                            administrative3 = data.admins3.filter(admin3 => admin3.code === address.administrative_level_3)[0]
                        } else {
                            administrative3 = data.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                        }
                    })
            }


            if (null !== address.city) {
                let cityService = new CityService();
                await cityService.getCities(null, null, null, null)
                    .then(data => {
                        city = data.cities.filter(city => city.name.toLowerCase() === address.city.toLowerCase())[0]
                    })
            }

            if (null !== address.postalCode) {
                let postalCodeService = new PostalCodeService();
                await postalCodeService.getAllPostalCodes(null, null, null, null)
                    .then(data => {
                        postalCode = data.postalCodes.filter(postalCode => postalCode.postalCode === address.postalCode)[0];
                    })
            }


            setEndUserCity(city);
            setEndUserAdministrativeLevel3(administrative3);
            setEndUserPostalCode(postalCode);
        } else {
            setFreeEndUserAdministrativeLevel1(address.administrative_level_1);
            setFreeEndUserAdministrativeLevel2(address.administrative_level_2);
            setFreeEndUserAdministrativeLevel3(address.administrative_level_3);
            setFreeEndUserCity(address.city);
            setFreeEndUserPostalCode(address.postalCode);
        }

        setEndUserCompanyName(address.companyName);
        setEndUserStreet(address.street);
        setEndUserBuildingNr(address.buildingNr);
        setEndUserPhone(address.phone);
        setEndUserMobile(address.mobile);
        setEndUserEmail(address.email);
        setEndUserReferencePerson(address.referencePerson);
        setEndUserCompanyName(address.companyName);

    }

    function fetchShipment() {
        setLoading(true);
        let warehouseStorageService = new WarehouseStorageService();
        let shipmentService = new ShipmentService();
        shipmentService.getShipmentById(id, null)
            .then(data => {

                if (data.warehouseStorageIdList !== null) {
                    let filters = {}
                    filters.shipmentId = id;
                    warehouseStorageService.getFilteredWarehouseStorages(filters)
                        .then(response => {
                            setWarehouseStorages(response.warehouseStorages);
                        })
                }


                setShipmentData(data);
                setAuth(data.authId);
                setCreatedDate(new Date(data.createdAt).toLocaleDateString() + " - " + new Date(data.createdAt).toLocaleTimeString());
                setUpdatedDate(new Date(data.updatedAt).toLocaleDateString() + " - " + new Date(data.updatedAt).toLocaleTimeString());
                setSenderAddress(data.senderAddress)
                setDeliveryAddress(data.deliveryAddress)
                setDestination({latitude: data.deliveryAddress?.latitude, longitude: data.deliveryAddress?.longitude})
                setEndUserAddress(data.endUserAddress);
                setPartialDepartureDisabled(data.partialDepartureDisabled)
                setPartialDeliveryDisabled(data.partialDeliveryDisabled)
                setSenderAddressInputs(data.senderAddress);
                setDeliveryAddressInputs(data.deliveryAddress);
                setEndUserAddressInputs(data.endUserAddress);
                fetchCod(data.codId, data);
                setTimeout(() => {
                    setLoading(false);
                }, 1500)

            })
            .catch(error => {

            })
    }

    function returnMap() {
        let googleAPIKey = variables.googleAPIKey;

        return <GMap
            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleAPIKey}
            loadingElement={<div style={{height: `100%`}}/>}
            containerElement={<div
                style={{height: mapHeight, width: (mapHeight * 1.2), padding: "2%"}}/>}
            mapElement={<div style={{height: `100%`, marginBottom: "10%"}}/>}
            latitude={pod.latitude}
            longitude={pod.longitude}
        />
    }

    function fetchPod() {
        let podService = new PodService();
        podService.getPod(id)
            .then(pod => {
                setPod(pod);
                handleMapHeight(pod);
            })
            .catch(error => {

            })
    }

    function handleCodModeChange(event, codMode) {
        setUpdateCodMode(codMode);
    }

    function handleCodCurrencyChange(event, currency) {
        setUpdateCodCurrency(currency);
    }

    function handleCodValueChange(event, value) {
        setUpdateCodValue(value);
    }

    async function updateOrDeleteCod() {

        let shipmentService = new ShipmentService();

        if (updateCodValue > 0) {
            let input = {
                modeId: updateCodMode?.id,
                value: updateCodValue,
                currency: updateCodCurrency?.code
            }

            await shipmentService.updateCod(shipmentData.id, input)
                .then(result => {
                    setUpdateCod(false);
                    setNotificationMessage(t("successful"));
                    setNotifySeverity("success");
                    setOpenNotify(true);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            await new CodService().deleteCod(cod?.id)
                .then(result => {
                    setUpdateCod(false);
                    setNotificationMessage(t("successful"));
                    setNotifySeverity("success");
                    setOpenNotify(true);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }

    }

    async function fetchCod(codId, shipmentData) {
        if (!codId) {
            setCod(null);
            return;
        }

        let codService = new CodService();
        let shipmentService = new ShipmentService();

        const cod = await codService.getCodById(codId);
        setCod(cod);

        await fetchCodEventsAvailable(cod);

        const codEvents = await codService.getEventsByCodId(codId);
        setCodEvents(codEvents);

        let filters = {};

        filters.contractId = shipmentData?.contract?.id;

        await shipmentService.getCodModes(filters)
            .then(codModes => {
                setCodModes(codModes);
                setUpdateCodMode(codModes.find(codMode => codMode.id === cod.codModeId))
            })
            .then(error => {

            })

        await shipmentService.getCurrencies({isEnabled: true})
            .then(currencies => {
                setCurrencies(currencies);
                setUpdateCodCurrency(currencies.find(currency => currency.symbol = cod.currency));
            })
            .then(error => {

            })

        // await new CodEventCodeService().getCodEventCodes()
        //     .then(result => {
        //         const mapCodEventCodes = {};
        //         for (let i = 0; i < result.length; ++i) {
        //             mapCodEventCodes[result[i].id] = result[i];
        //         }
        //         setMapCodEventCodes(mapCodEventCodes);
        //     })
        //     .catch(error => {
        //         error.response.json().then((response) => {
        //             setNotifySeverity('error');
        //             setNotificationMessage(response.message);
        //             setOpenNotify(true);
        //         });
        //     })

        setUpdateCodValue(cod.value);

    }

    function fetchPdf() {
        let podService = new PodService();
        podService.getPodPdf(id)
            .then(data => {
                // window.open('data:application/pdf;base64,' + data.content)
                // setHref('data:application/pdf;base64,' + data.content);
                // setFileName(data.fileName + "." + data.contentType);
                let tempLink = document.createElement('a');
                tempLink.href = "data:application/pdf;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
            })
            .catch(error => {

            })


    }

    async function fetchServices() {

        let filters = {}

        filters.accountCorporateCode = shipmentData.account.accountCorporateCode;
        filters.contractCode = shipmentData.contract.code;

        await new ServiceService().getServicesByFilters(filters)
            .then(data => {
                setServices(data);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    async function updateService() {

        await new ShipmentService().updateService(service.id, id)
            .then(success => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setRefresh(!refresh);
                setUpdatingService(false);
                setLoadingServiceUpdate(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    function cancelSenderUpdate() {
        setUpdatingSenderAddress(false);
        setSenderAddressInputs(senderAddress).then(r => {

        });
    }

    function cancelDeliveryUpdate() {
        setUpdatingDeliveryAddress(false);
        setDeliveryAddressInputs(deliveryAddress).then(r => {

        });
    }

    function cancelEndUserUpdate() {
        setUpdatingEndUserAddress(false);
        setEndUserAddressInputs(endUserAddress).then(r => {

        });
    }

    function updateSenderAddress() {
        let addressId = senderAddress.id;
        let shipmentId = id;

        let address = {};
        if (senderCountry != null) {
            address.country = senderCountry.iso3166Alpha2;
        }
        if (address.country === "IT" || address.country === "ES") {
            if (senderAdministrativeLevel3 !== null) {
                address.administrativeLevel3 = senderAdministrativeLevel3.code;
            }
            if (senderCity !== null) {
                address.city = senderCity.name;
            }
            if (senderPostalCode !== null) {
                address.postalCode = senderPostalCode.postalCode
            }
        } else {
            address.administrativeLevel3 = freeSenderAdministrativeLevel3;
            address.administrativeLevel2 = freeSenderAdministrativeLevel2;
            address.administrativeLevel1 = freeSenderAdministrativeLevel1;
            address.city = freeSenderCity;
            address.postalCode = freeSenderPostalCode;
        }

        address.street = senderStreet;
        address.buildingNr = senderBuildingNr;
        address.companyName = senderCompanyName;
        address.referencePerson = senderReferencePerson;
        address.phone = senderPhone;
        address.mobile = senderMobile;
        address.email = senderEmail;

        let shipmentService = new ShipmentService();
        shipmentService.updateAddress(addressId, shipmentId, address)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setRefresh(!refresh);
                setUpdatingSenderAddress(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }


    function updateEndUserAddress() {
        let addressId = endUserAddress.id;
        let shipmentId = id;

        let address = {};
        if (endUserCountry != null) {
            address.country = endUserCountry.iso3166Alpha2;
        }
        if (address.country === "IT" || address.country === "ES") {
            if (endUserAdministrativeLevel3 !== null) {
                address.administrativeLevel3 = endUserAdministrativeLevel3.code;
            }
            if (endUserCity !== null) {
                address.city = endUserCity.name;
            }
            if (endUserPostalCode !== null) {
                address.postalCode = endUserPostalCode.postalCode
            }
        } else {
            address.administrativeLevel3 = freeEndUserAdministrativeLevel3;
            address.administrativeLevel2 = freeEndUserAdministrativeLevel2;
            address.administrativeLevel1 = freeEndUserAdministrativeLevel1;
            address.city = freeEndUserCity;
            address.postalCode = freeEndUserPostalCode;
        }

        address.street = endUserStreet;
        address.buildingNr = endUserBuildingNr;
        address.companyName = endUserCompanyName;
        address.referencePerson = endUserReferencePerson;
        address.phone = endUserPhone;
        address.mobile = endUserMobile;
        address.email = endUserEmail;

        let shipmentService = new ShipmentService();
        shipmentService.updateAddress(addressId, shipmentId, address)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setRefresh(!refresh);
                setUpdatingEndUserAddress(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }


    function updateDeliveryAddress() {
        let addressId = deliveryAddress.id;
        let shipmentId = id;

        let address = {};
        if (deliveryCountry != null) {
            address.country = deliveryCountry.iso3166Alpha2;
        }
        if (address.country === "IT" || address.country === "ES") {
            if (deliveryAdministrativeLevel3 !== null) {
                address.administrativeLevel3 = deliveryAdministrativeLevel3.code;
            }
            if (deliveryCity !== null) {
                address.city = deliveryCity.name;
            }
            if (deliveryPostalCode !== null) {
                address.postalCode = deliveryPostalCode.postalCode
            }
        } else {
            address.administrativeLevel3 = freeDeliveryAdministrativeLevel3;
            address.administrativeLevel2 = freeDeliveryAdministrativeLevel2;
            address.administrativeLevel1 = freeDeliveryAdministrativeLevel1;
            address.city = freeDeliveryCity;
            address.postalCode = freeDeliveryPostalCode;
        }

        address.street = deliveryStreet;
        address.buildingNr = deliveryBuildingNr;
        address.companyName = deliveryCompanyName;
        address.referencePerson = deliveryReferencePerson;
        address.phone = deliveryPhone;
        address.mobile = deliveryMobile;
        address.email = deliveryEmail;

        let shipmentService = new ShipmentService();
        shipmentService.updateAddress(addressId, shipmentId, address)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setRefresh(!refresh);
                setUpdatingDeliveryAddress(false);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }

    function generateDeliveryAddress() {

        if (deliveryCountry && (deliveryCountry.id === 106 || deliveryCountry.id === 67 || deliveryCountry.id === 56)) {
            return <>
                {loadingDeliveryAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    /> :
                    <>
                        <Autocomplete
                            id="administrativeLevel3"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={administrativeLevel3}
                            disabled={!deliveryCountry}
                            getOptionLabel={option => option.name}
                            value={deliveryAdministrativeLevel3}
                            defaultValue={deliveryAdministrativeLevel3}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryAdministrativeLevel3Change}
                            onOpen={() => fetchAdministrativeLevel3(deliveryCountry)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrative3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryAdministrativeLevel3}
                                    defaultValue={deliveryAdministrativeLevel3}
                                />
                            )}
                        />
                        <Autocomplete
                            id="city"
                            size="small"
                            options={cities}
                            style={{marginBottom: "2%", width: "100%"}}
                            disabled={!deliveryAdministrativeLevel3}
                            getOptionLabel={option => option.name}
                            value={deliveryCity}
                            defaultValue={deliveryCity}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryCityChange}
                            onOpen={() => fetchCities(deliveryAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryCity}
                                    defaultValue={deliveryCity}
                                    helperText={!deliveryAdministrativeLevel3 ? t("select_admin3_first") : null}
                                />
                            )}
                        />
                        <Autocomplete
                            id="postalCode"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            value={deliveryPostalCode}
                            defaultValue={deliveryPostalCode}
                            noOptionsText={t("no_options")}
                            onChange={handleDeliveryPostalCodeChange}
                            onOpen={() => fetchPostalCodes(deliveryCity, deliveryAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={deliveryPostalCode}
                                    defaultValue={deliveryPostalCode}
                                    required
                                    error={!deliveryPostalCode}
                                    helperText={!deliveryPostalCode ? t("required") : ""}
                                />
                            )}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "70%"}}
                            label={t("street")}
                            variant={"outlined"}
                            size={"small"}
                            value={deliveryStreet}
                            onChange={handleDeliveryStreetChange}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "22%"}}
                            label={t("buildingNr")}
                            variant={"outlined"}
                            size={"small"}
                            value={deliveryBuildingNr}
                            onChange={handleDeliveryBuildingNrChange}
                        />

                    </>
                }
            </>
        } else {
            return <>
                {loadingDeliveryAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    />
                    :
                    <>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative1")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel1}
                                onChange={handleFreeDeliveryAdministrativeLevel1Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative2")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel2}
                                onChange={handleFreeDeliveryAdministrativeLevel2Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative3")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryAdministrativeLevel3}
                                onChange={handleFreeDeliveryAdministrativeLevel3Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("city")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryCity}
                                onChange={handleFreeDeliveryCityChange}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("postalCode")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeDeliveryPostalCode}
                                onChange={handleFreeDeliveryPostalCodeChange}
                                required
                                error={!freeDeliveryPostalCode}
                                helperText={!freeDeliveryPostalCode ? t("required") : null}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginRight: "2%", marginBottom: "2%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryStreet}
                                onChange={handleDeliveryStreetChange}
                            />
                            <TextField
                                style={{marginLeft: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={deliveryBuildingNr}
                                onChange={handleDeliveryBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        }

    }

    function generateSenderAddress() {

        if (senderCountry && (senderCountry.id === 106 || senderCountry.id === 67 || senderCountry.id === 56)) {
            return <>
                {loadingSenderAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    /> :
                    <>
                        <Autocomplete
                            id="administrativeLevel3"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={administrativeLevel3}
                            disabled={!senderCountry}
                            getOptionLabel={option => option.name}
                            value={senderAdministrativeLevel3}
                            defaultValue={senderAdministrativeLevel3}
                            noOptionsText={t("no_options")}
                            onChange={handleSenderAdministrativeLevel3Change}
                            onOpen={() => fetchAdministrativeLevel3(senderCountry)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrative3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={senderAdministrativeLevel3}
                                    defaultValue={senderAdministrativeLevel3}
                                />
                            )}
                        />
                        <Autocomplete
                            id="city"
                            size="small"
                            options={cities}
                            style={{marginBottom: "2%", width: "100%"}}
                            disabled={!senderAdministrativeLevel3}
                            getOptionLabel={option => option.name}
                            value={senderCity}
                            defaultValue={senderCity}
                            noOptionsText={t("no_options")}
                            onChange={handleSenderCityChange}
                            onOpen={() => fetchCities(senderAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={senderCity}
                                    defaultValue={senderCity}
                                    helperText={!senderAdministrativeLevel3 ? t("select_admin3_first") : null}
                                />
                            )}
                        />
                        <Autocomplete
                            id="postalCode"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            value={senderPostalCode}
                            defaultValue={senderPostalCode}
                            noOptionsText={t("no_options")}
                            onChange={handleSenderPostalCodeChange}
                            onOpen={() => fetchPostalCodes(senderCity, senderAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={senderPostalCode}
                                    defaultValue={senderPostalCode}
                                    required
                                    error={!senderPostalCode}
                                    helperText={!senderPostalCode ? t("required") : ""}
                                />
                            )}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "70%"}}
                            label={t("street")}
                            variant={"outlined"}
                            size={"small"}
                            value={senderStreet}
                            onChange={handleSenderStreetChange}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "22%"}}
                            label={t("buildingNr")}
                            variant={"outlined"}
                            size={"small"}
                            value={senderBuildingNr}
                            onChange={handleSenderBuildingNrChange}
                        />

                    </>
                }
            </>
        } else {
            return <>
                {loadingSenderAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    />
                    :
                    <>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative1")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeSenderAdministrativeLevel1}
                                onChange={handleFreeSenderAdministrativeLevel1Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative2")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeSenderAdministrativeLevel2}
                                onChange={handleFreeSenderAdministrativeLevel2Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative3")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeSenderAdministrativeLevel3}
                                onChange={handleFreeSenderAdministrativeLevel3Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("city")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeSenderCity}
                                onChange={handleFreeSenderCityChange}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("postalCode")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeSenderPostalCode}
                                onChange={handleFreeSenderPostalCodeChange}
                                required
                                error={!freeSenderPostalCode}
                                helperText={!freeSenderPostalCode ? t("required") : null}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginRight: "2%", marginBottom: "2%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={senderStreet}
                                onChange={handleSenderStreetChange}
                            />
                            <TextField
                                style={{marginLeft: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={senderBuildingNr}
                                onChange={handleSenderBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        }

    }


    function generateEndUserAddress() {

        if (endUserCountry && (endUserCountry.id === 106 || endUserCountry.id === 67 || endUserCountry.id === 56)) {
            return <>
                {loadingEndUserAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    /> :
                    <>
                        <Autocomplete
                            id="administrativeLevel3"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={administrativeLevel3}
                            disabled={!endUserCountry}
                            getOptionLabel={option => option.name}
                            value={endUserAdministrativeLevel3}
                            defaultValue={endUserAdministrativeLevel3}
                            noOptionsText={t("no_options")}
                            onChange={handleEndUserAdministrativeLevel3Change}
                            onOpen={() => fetchAdministrativeLevel3(endUserCountry)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrative3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={endUserAdministrativeLevel3}
                                    defaultValue={endUserAdministrativeLevel3}
                                />
                            )}
                        />
                        <Autocomplete
                            id="city"
                            size="small"
                            options={cities}
                            style={{marginBottom: "2%", width: "100%"}}
                            disabled={!endUserAdministrativeLevel3}
                            getOptionLabel={option => option.name}
                            value={endUserCity}
                            defaultValue={endUserCity}
                            noOptionsText={t("no_options")}
                            onChange={handleEndUserCityChange}
                            onOpen={() => fetchCities(endUserAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={endUserCity}
                                    defaultValue={endUserCity}
                                    helperText={!endUserAdministrativeLevel3 ? t("select_admin3_first") : null}
                                />
                            )}
                        />
                        <Autocomplete
                            id="postalCode"
                            size="small"
                            style={{marginBottom: "2%", width: "100%"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            value={endUserPostalCode}
                            defaultValue={endUserPostalCode}
                            noOptionsText={t("no_options")}
                            onChange={handleEndUserPostalCodeChange}
                            onOpen={() => fetchPostalCodes(endUserCity, endUserAdministrativeLevel3)}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={endUserPostalCode}
                                    defaultValue={endUserPostalCode}
                                    required
                                    error={!endUserPostalCode}
                                    helperText={!endUserPostalCode ? t("required") : ""}
                                />
                            )}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "70%"}}
                            label={t("street")}
                            variant={"outlined"}
                            size={"small"}
                            value={endUserStreet}
                            onChange={handleEndUserStreetChange}
                        />
                        <TextField
                            style={{marginInline: "2%", marginBottom: "2%", width: "22%"}}
                            label={t("buildingNr")}
                            variant={"outlined"}
                            size={"small"}
                            value={endUserBuildingNr}
                            onChange={handleEndUserBuildingNrChange}
                        />

                    </>
                }
            </>
        } else {
            return <>
                {loadingEndUserAddress ? <MinimalLoadingComponent
                        loadingItemSize={30}
                    />
                    :
                    <>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative1")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeEndUserAdministrativeLevel1}
                                onChange={handleFreeEndUserAdministrativeLevel1Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative2")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeEndUserAdministrativeLevel2}
                                onChange={handleFreeEndUserAdministrativeLevel2Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("administrative3")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeEndUserAdministrativeLevel3}
                                onChange={handleFreeEndUserAdministrativeLevel3Change}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("city")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeEndUserCity}
                                onChange={handleFreeEndUserCityChange}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginBlock: "2%", width: "100%"}}
                                label={t("postalCode")}
                                variant={"outlined"}
                                size={"small"}
                                value={freeEndUserPostalCode}
                                onChange={handleFreeEndUserPostalCodeChange}
                                required
                                error={!freeEndUserPostalCode}
                                helperText={!freeEndUserPostalCode ? t("required") : null}
                            />
                        </div>
                        <div>
                            <TextField
                                style={{marginRight: "2%", marginBottom: "2%", width: "70%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={endUserStreet}
                                onChange={handleEndUserStreetChange}
                            />
                            <TextField
                                style={{marginLeft: "2%", marginBottom: "2%", width: "22%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={endUserBuildingNr}
                                onChange={handleEndUserBuildingNrChange}
                            />
                        </div>
                    </>
                }
            </>
        }

    }


    function generateDeliveryAddressInputField() {

        return <div>
            <>
                <Autocomplete
                    id="country"
                    size="small"
                    // className={classes.field}
                    // style={{marginBottom: "2%", width: "100%"}}
                    options={countries}
                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                    value={deliveryCountry}
                    defaultValue={deliveryCountry}
                    onChange={handleDeliveryCountryChange}
                    onSelect={fetchCountries}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        style={{marginBottom: "2%", width: "100%"}}
                        label={t("country")}
                        variant="outlined"
                        margin="dense"
                        value={deliveryCountry}
                        defaultValue={deliveryCountry}
                        required
                        error={!deliveryCountry}
                        helperText={!deliveryCountry ? t("required") : ""}
                    />}
                />
            </>
            {generateDeliveryAddress()}
        </div>
    }

    function generateSenderAddressInputField() {

        return <div>
            <>
                <Autocomplete
                    id="country"
                    size="small"
                    // className={classes.field}
                    // style={{marginBottom: "2%", width: "100%"}}
                    options={countries}
                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                    value={senderCountry}
                    defaultValue={senderCountry}
                    onChange={handleSenderCountryChange}
                    onSelect={fetchCountries}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        style={{marginBottom: "2%", width: "100%"}}
                        label={t("country")}
                        variant="outlined"
                        margin="dense"
                        value={senderCountry}
                        defaultValue={senderCountry}
                        required
                        error={!senderCountry}
                        helperText={!senderCountry ? t("required") : ""}
                    />}
                />
            </>
            {generateSenderAddress()}
        </div>
    }

    function filteredCodes() {

        let codes = [];

        let event = cod?.events.filter(event => [2, 3, 4, 5, 6, 7].includes(event.eventCodeId))[0];

        for (let i = 0; i < codEventCodesAvailable.length; i++) {

            if (codEventCodesAvailable[i].id !== event?.eventCodeId) {
                codes.push(codEventCodesAvailable[i]);
            }

        }

        return codes;

    }

    async function updateCodEvent() {

        let input = {};

        input.codEventId = codEventIdToDelete;

        if (newCodCollectionEventCode) {
            input.newCodEventCodeId = newCodCollectionEventCode.id;
        }

        await new CodEventService().updateCodEvent(input)
            .then(response => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setOpenUpdateCodEvent(false);
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }


    function generateEndUserAddressInputField() {

        return <div>
            <>
                <Autocomplete
                    id="country"
                    size="small"
                    // className={classes.field}
                    // style={{marginBottom: "2%", width: "100%"}}
                    options={countries}
                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                    value={endUserCountry}
                    defaultValue={endUserCountry}
                    onChange={handleEndUserCountryChange}
                    onSelect={fetchCountries}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    noOptionsText={t("no_options")}
                    renderInput={(params) => <TextField
                        {...params}
                        style={{marginBottom: "2%", width: "100%"}}
                        label={t("country")}
                        variant="outlined"
                        margin="dense"
                        value={endUserCountry}
                        defaultValue={endUserCountry}
                        required
                        error={!endUserCountry}
                        helperText={!endUserCountry ? t("required") : ""}
                    />}
                />
            </>
            {generateEndUserAddress()}
        </div>
    }

    useEffect(() => {
        fetchCurrencies();
        fetchParcels();
        fetchShipmentTasks();
        fetchEvents();
        fetchEventCodes();
        fetchWarehouses();
        fetchDrivers();
        fetchSummaryIn();
        fetchPod();
        fetchBookings();
        fetchShipmentCommunications();
        fetchAccountingRows();
        fetchQuotes();
        fetchPreInvoiceRows();

        fetchCountries();
        fetchCities();
        fetchAdministrativeLevel3();
        fetchPostalCodes();

        fetchLogs();

        fetchShipment();
    }, [refresh, location])

    return <div className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <ConfirmDialog open={openDeleteCodEventConfirm}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteCodEvent}
                       onCancel={() => setOpenDeleteCodEventConfirm(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
        <Dialog
            style={{width: "100%"}}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                },
            }}
            maxWidth={500}
            fullWidth={useMediaQuery(theme.breakpoints.down('md'))}
            open={openUpdateCodEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><Typography
                variant={"button"}>{t("cod_collection_variation")}</Typography></DialogTitle>
            <DialogContent style={{width: "100%"}}>
                <DialogContentText id="alert-dialog-description">
                    <div style={{display: "flex", marginBlock: "4%"}}>
                        <Autocomplete
                            id="newCodCollectionEventCode"
                            size="small"
                            // className={classes.field}
                            style={{marginBottom: "2%", width: "100%"}}
                            options={filteredCodes()}
                            getOptionLabel={option => option.id + " - " + option.name}
                            value={newCodCollectionEventCode}
                            defaultValue={newCodCollectionEventCode}
                            onChange={handleNewCodCollectionEventCodeChange}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            noOptionsText={t("no_options")}
                            renderInput={(params) => <TextField
                                {...params}
                                style={{marginBottom: "2%", width: "100%"}}
                                label={t("cod_event")}
                                variant="outlined"
                                margin="dense"
                                value={newCodCollectionEventCode}
                                defaultValue={newCodCollectionEventCode}
                                required
                                error={!newCodCollectionEventCode}
                                helperText={!newCodCollectionEventCode ? t("required") : ""}
                            />}
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenUpdateCodEvent(false)} color={"default"}>
                    {t("cancel")}
                </Button>
                <StyledButton onClick={updateCodEvent} color="primary">
                    {t("confirm")}
                </StyledButton>
            </DialogActions>
        </Dialog>
        <Dialog
            style={{width: "100%"}}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                },
            }}
            maxWidth={500}
            fullWidth={useMediaQuery(theme.breakpoints.down('md'))}
            open={openRequestDeleteConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><Typography
                variant={"button"}>{t("cod_collection_variation")}</Typography></DialogTitle>
            <DialogContent style={{width: "100%"}}>
                <DialogContentText id="alert-dialog-description">
                    <div style={{display: "flex", marginBlock: "4%"}}>
                        <RadioGroup
                            value={codCollectionVariationType}
                            onChange={handleTypeChange}
                            style={{margin: "auto", width: "100%"}}
                        >
                            <FormControlLabel value={"DELETE"} control={<Radio color={"primary"}/>}
                                              label={t("delete")}/>
                            <FormControlLabel disabled={codEventCodesAvailable.length < 2} value={"UPDATE"}
                                              control={<Radio color={"primary"}/>}
                                              label={t("update_cod_collection_mode")}/>
                            {codEventCodesAvailable.length < 2 || codCollectionVariationType === "DELETE" ?
                                null
                                :
                                <Autocomplete
                                    id="newCodCollectionEventCode"
                                    size="small"
                                    // className={classes.field}
                                    style={{marginBottom: "2%", width: "100%"}}
                                    options={filteredCodes()}
                                    getOptionLabel={option => option.id + " - " + option.name}
                                    value={newCodCollectionEventCode}
                                    defaultValue={newCodCollectionEventCode}
                                    onChange={handleNewCodCollectionEventCodeChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    noOptionsText={t("no_options")}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        style={{marginBottom: "2%", width: "100%"}}
                                        label={t("cod_event")}
                                        variant="outlined"
                                        margin="dense"
                                        value={newCodCollectionEventCode}
                                        defaultValue={newCodCollectionEventCode}
                                        required
                                        error={!newCodCollectionEventCode}
                                        helperText={!newCodCollectionEventCode ? t("required") : ""}
                                    />}
                                />
                            }
                        </RadioGroup>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenRequestDeleteConfirm(false)} color={"default"}>
                    {t("cancel")}
                </Button>
                <StyledButton onClick={sendDeleteRequest} color="primary">
                    {t("confirm")}
                </StyledButton>
            </DialogActions>
        </Dialog>

        {loading ?
            <LoadingComponent/>

            :
            <div>
                <Button className={classes.button} startIcon={<ArrowBackIosRounded/>}
                        onClick={() => history.push(`/shipments`)}>{t("go_back")}</Button>


                <Box sx={{width: '102%', typography: 'body1'}}>

                    <TabContext value={tabNumber}>
                        <Box sx={{width: "fit-content", borderBottom: 1, borderColor: 'divider', margin: "auto"}}>
                            <TabList onChange={handleTabChange} aria-label="tabs-about-me">
                                <Tab label={t("shipment_details")} value="1"/>
                                <Tab label={t("parcels")} value="2"/>
                                {permissions.includes("communications:read") ?
                                    <Tab label={t("communications")} value="3"/>
                                    :
                                    <></>
                                }
                                <Tab label={t("annotations")} value="4"/>
                                {permissions.includes("su:admin") ?
                                    <Tab label={t("administration")} value="5"/>
                                    :
                                    <></>
                                }
                                <Tab label={t("tasks")} value={"6"}/>
                                {permissions.includes("su:admin") ?
                                    <Tab label={t("logs")} value="7"/>
                                    :
                                    <></>
                                }
                            </TabList>
                        </Box>
                        <TabPanel style={{width: "100%"}} value="1">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={1}>
                                    <Grid item>
                                        <Paper elevation={1} className={classes.paper}>
                                            <DialogTitle>{t("shipment_details")}</DialogTitle>
                                            <Table>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("shipment_id")}</Typography>
                                                        <Typography>{t("gateway_shipment_id")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.id || "-"}</Typography>
                                                        <Typography>{shipmentData.gatewayShipmentId || "-"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("reference")}</Typography>
                                                        <Typography>{t("customer_reference")}</Typography>
                                                        <Typography>{t("customer_code")}</Typography>
                                                        <Typography>{t("orderNumber")}</Typography>
                                                        {shipmentData.orders?.map((item, index) => {
                                                            return <Typography>{"-"}</Typography>
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.reference || "-"}</Typography>
                                                        <Typography>{shipmentData.customerReference || "-"}</Typography>
                                                        <Typography>{shipmentData.customerCode || "-"}</Typography>
                                                        <Typography>{shipmentData.orderNumber || "-"}</Typography>
                                                        {shipmentData.orders?.map(item => {
                                                            return <Typography>{item.orderNumber || "-"}</Typography>
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("account")}</Typography>
                                                        <Typography>{t("contract")}</Typography>
                                                        <Typography>{t("sub-contract")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.account?.accountCorporateCode + " - " + shipmentData.account?.companyName || "-"}</Typography>
                                                        <Typography>{shipmentData.contract?.code || "-"}</Typography>
                                                        <Typography>{shipmentData.subContract?.code || "-"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("departure_warehouse")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.departureWarehouseCode || null}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("summary")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{summary ? summary.reference : t("summary_not_ready")}</Typography>
                                                        <Typography>{summary ? t("of") + new Date(summary.createdAt).toLocaleDateString() : ""}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("created_at")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{createdDate || "-"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("updated_at")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{updatedDate || "-"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("estimated_delivery_date")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{formatDate(shipmentData.estimatedDeliveryDate) || "-"}<IconButton
                                                            onClick={() => refreshDate("estimated")}><Cached/></IconButton></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("theoretical_estimated_delivery_date")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{formatDate(shipmentData.theoreticalEstimatedDeliveryDate) || "-"}<IconButton
                                                            onClick={() => refreshDate("theoretical")}><Cached/></IconButton></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("updated_estimated_delivery_date")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{formatDate(shipmentData.updatedEstimatedDeliveryDate) || "-"}<IconButton
                                                            onClick={() => refreshDate("updated")}><Cached/></IconButton></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {!isUpdatingSenderAddress ?
                                                    <TableRow>
                                                        <TableCell>
                                                            <div>
                                                                <Typography>{t("sender_address")}<IconButton
                                                                    disabled={loadingSenderAddressUpdate}
                                                                    style={{marginLeft: "2%"}}
                                                                    onClick={setUpdateSenderAddress}>
                                                                    {loadingSenderAddressUpdate ?
                                                                        <CircularProgress size={25}/> : <Create/>}
                                                                </IconButton></Typography>


                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{senderAddress.companyName || null}</Typography>
                                                            <Typography>{senderAddress.referencePerson || null}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(senderAddress.street || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(senderAddress.buildingNr || null)}</Typography>
                                                            <Typography/>
                                                            <Typography
                                                                display={"inline"}>{(senderAddress.postalCode || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(senderAddress.city ? senderAddress.city : null) + " " + (senderAddress.administrative_level_3 ? "(" + senderAddress.administrative_level_3 + ")" : "")}</Typography>
                                                            <div>
                                                                <Typography
                                                                    display={"inline"}>{(senderAddress.administrative_level_2 ? senderAddress.administrative_level_2 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(senderAddress.administrative_level_1 ? senderAddress.administrative_level_1 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(senderAddress.country || null)}</Typography>
                                                            </div>

                                                            <Typography>{senderAddress.phone ? senderAddress.phone : null}</Typography>
                                                            <Typography>{senderAddress.mobile ? senderAddress.mobile : null}</Typography>
                                                            <Typography>{senderAddress.email ? senderAddress.email : null}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell width={"50%"}>
                                                            <Typography variant={"subtitle2"}
                                                                        style={{marginBottom: "3%"}}>{t("sender_address")}</Typography>
                                                            {generateSenderAddressInputField()}
                                                        </TableCell>
                                                        <TableCell>

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_company_name")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={senderCompanyName}
                                                                onChange={handleSenderCompanyNameChange}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_reference_person")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={senderReferencePerson}
                                                                onChange={handleSenderReferencePerson}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("email")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={senderEmail}
                                                                onChange={handleSenderEmail}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("phone")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                type={"tel"}
                                                                value={senderPhone}
                                                                onChange={handleSenderPhone}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("mobile")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={senderMobile}
                                                                onChange={handleSenderMobile}

                                                            />
                                                            <div style={{display: "flex"}}>
                                                                <Button onClick={cancelSenderUpdate}
                                                                        style={{margin: "auto"}}>{t("cancel")}</Button>
                                                                <Button onClick={updateSenderAddress}
                                                                        style={{margin: "auto"}}>{t("confirm")}</Button>
                                                            </div>
                                                        </TableCell>


                                                    </TableRow>

                                                }
                                                {!isUpdatingDeliveryAddress ?
                                                    <TableRow>
                                                        <TableCell>
                                                            <div>
                                                                <Typography>{t("delivery_address")}<IconButton
                                                                    disabled={loadingDeliveryAddressUpdate || shipmentData?.service?.code === "CP"}
                                                                    style={{marginLeft: "2%"}}
                                                                    onClick={setUpdateDeliveryAddress}>
                                                                    {loadingDeliveryAddressUpdate ?
                                                                        <CircularProgress size={25}/> : <Create/>}
                                                                </IconButton></Typography>


                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{deliveryAddress.companyName || null}</Typography>
                                                            <Typography>{deliveryAddress.referencePerson || null}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(deliveryAddress.street || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(deliveryAddress.buildingNr || null)}</Typography>
                                                            <Typography/>
                                                            <Typography
                                                                display={"inline"}>{(deliveryAddress.postalCode || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(deliveryAddress.city ? deliveryAddress.city : null) + " " + (deliveryAddress.administrative_level_3 ? "(" + deliveryAddress.administrative_level_3 + ")" : "")}</Typography>
                                                            <div>
                                                                <Typography
                                                                    display={"inline"}>{(deliveryAddress.administrative_level_2 ? deliveryAddress.administrative_level_2 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(deliveryAddress.administrative_level_1 ? deliveryAddress.administrative_level_1 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(deliveryAddress.country || null)}</Typography>
                                                            </div>

                                                            <Typography>{deliveryAddress.phone ? deliveryAddress.phone : null}</Typography>
                                                            <Typography>{deliveryAddress.mobile ? deliveryAddress.mobile : null}</Typography>
                                                            <Typography>{deliveryAddress.email ? deliveryAddress.email : null}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell width={"50%"}>
                                                            <Typography variant={"subtitle2"}
                                                                        style={{marginBottom: "3%"}}>{t("delivery_address")}</Typography>
                                                            {generateDeliveryAddressInputField()}
                                                        </TableCell>
                                                        <TableCell>

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_company_name")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={deliveryCompanyName}
                                                                onChange={handleDeliveryCompanyNameChange}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_reference_person")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={deliveryReferencePerson}
                                                                onChange={handleDeliveryReferencePerson}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("email")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={deliveryEmail}
                                                                onChange={handleDeliveryEmail}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("phone")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                type={"tel"}
                                                                value={deliveryPhone}
                                                                onChange={handleDeliveryPhone}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("mobile")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={deliveryMobile}
                                                                onChange={handleDeliveryMobile}

                                                            />
                                                            <div style={{display: "flex"}}>
                                                                <Button onClick={cancelDeliveryUpdate}
                                                                        style={{margin: "auto"}}>{t("cancel")}</Button>
                                                                <Button onClick={updateDeliveryAddress}
                                                                        style={{margin: "auto"}}>{t("confirm")}</Button>
                                                            </div>
                                                        </TableCell>


                                                    </TableRow>

                                                }
                                                {!isUpdatingEndUserAddress ?
                                                    <TableRow>
                                                        <TableCell>
                                                            <div>
                                                                <Typography>{t("end_user_address")}<IconButton
                                                                    disabled={loadingEndUserAddressUpdate}
                                                                    style={{marginLeft: "2%"}}
                                                                    onClick={setUpdateEndUserAddress}>
                                                                    {loadingEndUserAddressUpdate ?
                                                                        <CircularProgress size={25}/> : <Create/>}
                                                                </IconButton></Typography>


                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{endUserAddress.companyName || null}</Typography>
                                                            <Typography>{endUserAddress.referencePerson || null}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(endUserAddress.street || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(endUserAddress.buildingNr || null)}</Typography>
                                                            <Typography/>
                                                            <Typography
                                                                display={"inline"}>{(endUserAddress.postalCode || null) + " "}</Typography>
                                                            <Typography
                                                                display={"inline"}>{(endUserAddress.city ? endUserAddress.city : null) + " " + (endUserAddress.administrative_level_3 ? "(" + endUserAddress.administrative_level_3 + ")" : "")}</Typography>
                                                            <div>
                                                                <Typography
                                                                    display={"inline"}>{(endUserAddress.administrative_level_2 ? endUserAddress.administrative_level_2 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(endUserAddress.administrative_level_1 ? endUserAddress.administrative_level_1 + " " : null)}</Typography>
                                                                <Typography
                                                                    display={"inline"}>{(endUserAddress.country || null)}</Typography>
                                                            </div>

                                                            <Typography>{endUserAddress.phone ? endUserAddress.phone : null}</Typography>
                                                            <Typography>{endUserAddress.mobile ? endUserAddress.mobile : null}</Typography>
                                                            <Typography>{endUserAddress.email ? endUserAddress.email : null}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell width={"50%"}>
                                                            <Typography variant={"subtitle2"}
                                                                        style={{marginBottom: "3%"}}>{t("end_user_address")}</Typography>
                                                            {generateEndUserAddressInputField()}
                                                        </TableCell>
                                                        <TableCell>

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_company_name")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={endUserCompanyName}
                                                                onChange={handleEndUserCompanyNameChange}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("shipment_reference_person")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={endUserReferencePerson}
                                                                onChange={handleEndUserReferencePerson}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("email")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={endUserEmail}
                                                                onChange={handleEndUserEmail}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("phone")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                type={"tel"}
                                                                value={endUserPhone}
                                                                onChange={handleEndUserPhone}
                                                            />

                                                            <TextField
                                                                style={{
                                                                    marginBottom: "2%",
                                                                    marginTop: "2%",
                                                                    width: "100%"
                                                                }}
                                                                label={t("mobile")}
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                value={endUserMobile}
                                                                onChange={handleEndUserMobile}

                                                            />
                                                            <div style={{display: "flex"}}>
                                                                <Button onClick={cancelEndUserUpdate}
                                                                        style={{margin: "auto"}}>{t("cancel")}</Button>
                                                                <Button onClick={updateEndUserAddress}
                                                                        style={{margin: "auto"}}>{t("confirm")}</Button>
                                                            </div>
                                                        </TableCell>


                                                    </TableRow>

                                                }
                                                {isUpdatingService ?
                                                    <TableRow>
                                                        <TableCell>
                                                            <Autocomplete
                                                                id="service"
                                                                className={classes.field}
                                                                options={services}
                                                                getOptionLabel={option => option.name}
                                                                value={service}
                                                                defaultValue={service}
                                                                onOpen={fetchServices}
                                                                onChange={handleServiceChange}
                                                                loading={true}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={t("service")}
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        value={service}
                                                                        defaultValue={service}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: 'off', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {/*<div style={{display: "flex"}}>*/}
                                                            {/*    <Button onClick={cancelEndUserUpdate}*/}
                                                            {/*            style={{margin: "auto"}}>{t("cancel")}</Button>*/}
                                                            {/*    <Button onClick={updateEndUserAddress}*/}
                                                            {/*            style={{margin: "auto"}}>{t("confirm")}</Button>*/}
                                                            {/*</div>*/}
                                                            <IconButton style={{marginLeft: "3%"}} onClick={() => {
                                                                setLoadingServiceUpdate(false);
                                                                setUpdatingService(false);
                                                                setService(null)
                                                            }}><ArrowBackOutlined/></IconButton>
                                                            <IconButton style={{marginLeft: "3%"}}
                                                                        onClick={updateService}><SaveOutlined/></IconButton>
                                                        </TableCell>


                                                    </TableRow>
                                                    :

                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>{t("service")}<IconButton
                                                                disabled={loadingServiceUpdate || shipmentData?.service?.code === "CP"}
                                                                style={{marginLeft: "2%"}}
                                                                onClick={() => {
                                                                    setLoadingServiceUpdate(true);
                                                                    setUpdatingService(true)
                                                                }}>
                                                                {loadingServiceUpdate ?
                                                                    <CircularProgress size={25}/> : <Create/>}
                                                            </IconButton></Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{shipmentData.service ? shipmentData.service.code + " - " + shipmentData.service.name : ""}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("insurance")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={"subtitle2"}>{shipmentData.insuranceValue? shipmentData.insuranceValue.toFixed(2) + " " + shipmentData.insuranceCurrency : "---"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                            <Typography>{t("mandatory_booking")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={"button"}>{shipmentData.mandatoryBooking? t("yes") : t("no")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("channel")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.channel || "---"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("authentication")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {auth ?
                                                            <LoadingButton
                                                                style={{color: theme.palette.primary.main}}
                                                                endIcon={<SendToMobile/>}
                                                                loading={loadingSMS}
                                                                onClick={sendSMS}
                                                            >

                                                                {t("send_text_message")}
                                                            </LoadingButton>

                                                            :
                                                            t("no_auth")
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>{t("total_parcel_number")}</Typography>
                                                        <Typography>{t("total_weight")}</Typography>
                                                        <Typography>{t("total_volume")}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{shipmentData.totalParcelNumber}</Typography>
                                                        <Typography>{shipmentData.totalWeight} {("kg")}</Typography>
                                                        <Typography>{shipmentData.totalVolume} {"m\u00B3"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/*<TableCell>*/}
                                                    {/*    <Typography>{t("partial_delivery_disabled")}</Typography>*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell>
                                                        <div>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            color={"primary"}
                                                                            checked={partialDepartureDisabled}
                                                                                onChange={(event) => setPartialDepartureDisabled(event.target.checked)}/>
                                                                    }
                                                                    label={t("partial_departure_disabled")}
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            color={"primary"}
                                                                            checked={partialDeliveryDisabled}
                                                                                onChange={(event) => setPartialDeliveryDisabled(event.target.checked)}/>
                                                                    }
                                                                    label={t("partial_delivery_disabled")}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={updateBlocks}><SaveOutlined color={"primary"}/></IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                            {bookings.length > 0 ?
                                                <>
                                                    <DialogTitle>{t("bookings_history")}</DialogTitle>

                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableCell>{t("booking_date")}</TableCell>
                                                            <TableCell>{t("time_range")}</TableCell>
                                                            <TableCell>{t("last_status")}</TableCell>
                                                            <TableCell>{t("last_status_date_time")}</TableCell>
                                                            <TableCell>{t("user")}</TableCell>
                                                            <TableCell/>
                                                        </TableHead>
                                                        <TableBody>
                                                            {bookings.map((booking, index) => {
                                                                return <TableRow>
                                                                    <TableCell>
                                                                        {new Date(booking.bookingDate).toLocaleDateString()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.slotTimeRange}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.events[booking.events.length - 1].eventCodeName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {new Date(booking.events[booking.events.length - 1].dateTime).toLocaleDateString() + " - " + new Date(booking.events[booking.events.length - 1].dateTime).toLocaleTimeString()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.events[booking.events.length - 1].username ? booking.events[booking.events.length - 1].username : t("customer")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {booking.events[booking.events.length - 1].eventCodeId === 3 ? null :
                                                                            <IconButton
                                                                                onClick={() => cancelBooking(booking.id)}><Cancel/></IconButton>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                    <div>
                                                        <Typography style={{margin: "auto", marginBlock: "2%"}}
                                                                    variant={"subtitle2"}>{t("reschedule")}
                                                            <IconButton
                                                                onClick={openCalendar}><CalendarToday/></IconButton>
                                                        </Typography>
                                                    </div>
                                                </>
                                                :
                                                <div style={{paddingBottom: "2%"}}>
                                                    <DialogTitle>{t("booking")}</DialogTitle>
                                                    <IconButton onClick={openCalendar}><CalendarToday/></IconButton>
                                                    <Typography variant={"subtitle2"}>{t("schedule")}</Typography>
                                                </div>
                                            }

                                            <Modal
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={openBooking}
                                                onClose={closeCalendar}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                            >
                                                <Fade in={openBooking}>
                                                    <div className={classes.calendar}>

                                                        <Paper elevation={3}>
                                                            <div style={{
                                                                marginBottom: "2%",
                                                                margin: "auto",
                                                                height: "20%",
                                                                backgroundColor: theme.palette.primary.main,
                                                                display: "flex"
                                                            }}>
                                                                <DialogTitle style={{
                                                                    width: "fit-content",
                                                                    margin: "auto"
                                                                }}><Typography
                                                                    variant={"button"}
                                                                    style={{color: "white"}}
                                                                >{t("calendar_title")}</Typography>
                                                                </DialogTitle>
                                                            </div>
                                                            {/*<DialogTitle style={{*/}
                                                            {/*    textAlign: "center",*/}
                                                            {/*    paddingTop: "3%"*/}
                                                            {/*}}><Typography*/}
                                                            {/*    variant={"caption"} fontSize={22}>{t("calendar_title")}</Typography>*/}
                                                            {/*</DialogTitle>*/}
                                                            {calendarLoading ?
                                                                <LoadingComponent/>
                                                                :
                                                                <div style={{display: "flex"}}>
                                                                    <Button
                                                                        style={{
                                                                            height: "20%",
                                                                            margin: "auto",
                                                                            color: calendarBatchCount !== 0 ? theme.palette.primary.main : null,
                                                                            backgroundColor: calendarBatchCount !== 0 ? 'transparent' : null
                                                                        }}
                                                                        disabled={calendarBatchCount === 0}
                                                                        onClick={fetchPreviousWeek}><ArrowBackIos/></Button>
                                                                    <Table
                                                                        className={classes.calendarTable}>
                                                                        {calendar.map((dateAndSlot, i) =>
                                                                            <TableCell
                                                                                style={{width: "5%"}}>
                                                                                <div>
                                                                                    <Typography
                                                                                        align="center">{formatDate(dateAndSlot.date)}</Typography>
                                                                                </div>
                                                                                {dateAndSlot.availableSlots.map((slots, index) => {
                                                                                        return <Card
                                                                                            style={{
                                                                                                marginTop: "15%",
                                                                                                backgroundColor: slots.available ? `#35BC64` : "lightgrey"
                                                                                            }}>
                                                                                            <CardActionArea
                                                                                                onClick={() => confirmBooking(dateAndSlot.date, slots.slot)}
                                                                                                disabled={!slots.available}>
                                                                                                <div>
                                                                                                    <Typography
                                                                                                        variant={"subtitle1"}
                                                                                                        style={{marginInline: "5%"}}
                                                                                                        align={"center"}>
                                                                                                        {slots.slot.fromTime + ":00"}
                                                                                                    </Typography>
                                                                                                </div>

                                                                                                <Typography
                                                                                                    variant={"subtitle1"}
                                                                                                    style={{marginInline: "5%"}}
                                                                                                    align={"center"}>
                                                                                                    {slots.slot.toTime + ":00"}
                                                                                                </Typography>
                                                                                            </CardActionArea>
                                                                                        </Card>
                                                                                    }
                                                                                )}
                                                                            </TableCell>
                                                                        )}
                                                                    </Table>
                                                                    <Button style={{
                                                                        backgroundColor: "transparent",
                                                                        color: theme.palette.primary.main,
                                                                        height: "20%",
                                                                        margin: "auto"
                                                                    }}
                                                                            onClick={fetchNextWeek}><ArrowForwardIos/></Button>

                                                                </div>
                                                            }
                                                        </Paper>

                                                    </div>
                                                </Fade>
                                            </Modal>


                                            {warehouseStorages.length > 0 ?
                                                <>
                                                    <DialogTitle>{t("warehouse_storages")}</DialogTitle>
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableCell>{t("canceled")}</TableCell>
                                                            <TableCell>{t("warehouse_storage_id")}</TableCell>
                                                            <TableCell>{t("open_dossier_date")}</TableCell>
                                                            <TableCell>{t("warehouse")}</TableCell>
                                                            <TableCell>{t("reason")}</TableCell>
                                                            <TableCell>{t("release")}</TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {warehouseStorages.map((warehouseStorage, index) => {
                                                                return <TableRow>
                                                                    <TableCell>
                                                                        {warehouseStorage.canceled ? t("yes") : t("no")}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {warehouseStorage.id}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {new Date(warehouseStorage.openingDateTime).toLocaleDateString() + " - " + new Date(warehouseStorage.openingDateTime).toLocaleTimeString()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {warehouseStorage.warehouseCode}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {warehouseStorage.reason}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography
                                                                            variant={"subtitle2"}>{warehouseStorage.warehouseStorageReleaseModeDescription}</Typography>
                                                                        {/*<Typography*/}
                                                                        {/*    onClick={() => goToAboutMe(warehouseStorage.releasedShipmentId)}*/}
                                                                        {/*    className={classes.link}> {warehouseStorage.releasedShipmentId} </Typography>*/}
                                                                        <Typography>
                                                                            <a className={classes.link} target="_blank" href={`/shipments/${warehouseStorage.releasedShipmentId}/aboutMe`}>
                                                                                {warehouseStorage.releasedShipmentId}
                                                                            </a>
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </>
                                                :

                                                null
                                            }

                                        </Paper>
                                    </Grid>
                                    <Grid item>
                                        <Paper className={classes.paper}>
                                            <DialogTitle>{t("shipment_events")}</DialogTitle>
                                            <Table stickyHeader>

                                                <TableHead>
                                                    <TableCell>{t("date")}</TableCell>
                                                    <TableCell>{t("time")}</TableCell>
                                                    <TableCell>{t("event")}</TableCell>
                                                    <TableCell>{t("warehouse")}</TableCell>
                                                    <TableCell>{t("notes")}</TableCell>
                                                </TableHead>
                                                <TableBody>
                                                    {events.map((event, index) => {
                                                        return <TableRow>
                                                            <TableCell>
                                                                {new Date(event.dateTime).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell>
                                                                {new Date(event.dateTime).toLocaleTimeString()}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>
                                                                    {event.eventCodeId ? shipmentEventCodes[event.eventCodeId].id + " - " + shipmentEventCodes[event.eventCodeId].name : ""}
                                                                </Typography>
                                                                <Typography variant={"subtitle2"}>
                                                                    {event.destinationWarehouseCode? t("departing_to") + event.destinationWarehouseCode : ""}
                                                                </Typography>
                                                                <Typography variant={"subtitle2"} color={"primary"}>
                                                                    {event.driverId && drivers[event.driverId] ? drivers[event.driverId].name + " " + drivers[event.driverId].surname : ""}
                                                                </Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                {event.warehouseName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.notes ?
                                                                    <NoteTooltip>{event.notes}</NoteTooltip>

                                                                    :

                                                                    null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>

                                            </Table>
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            <DialogTitle>{t("parcels")}</DialogTitle>
                                            <Table stickyHeader>

                                                <TableHead>
                                                    <TableCell>{t("parcel_id")}</TableCell>
                                                    <TableCell>{t("barcode")}</TableCell>
                                                    <TableCell>{t("customer_barcode")}</TableCell>
                                                    <TableCell style={{width: "fit-content"}}>{t("measures")} (W x L x H
                                                        x D)</TableCell>
                                                </TableHead>
                                                <TableBody>
                                                    {parcels.map((parcel, index) => {
                                                        return <Parcel warehouses={warehouses}
                                                                       eventCodes={parcelEventCodes}
                                                                       parcel={parcel}
                                                                       drivers={drivers}
                                                                       destination={destination}
                                                        />
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Paper>


                                        <Paper className={classes.paper}>
                                            <DialogTitle>{t("cod")}</DialogTitle>
                                            {updateCod ?
                                                <Card elevation={0}>
                                                    <CurrencyTextField
                                                        label={t("value")}
                                                        variant={"outlined"}
                                                        margin={"dense"}
                                                        decimalPlaces={2}
                                                        digitGroupSeparator={"."}
                                                        decimalCharacter={","}
                                                        minimumValue={0}
                                                        value={updateCodValue}
                                                        currencySymbol={updateCodCurrency?.symbol}
                                                        onChange={handleCodValueChange}
                                                    />
                                                    <Autocomplete
                                                        id="currencies"
                                                        size="small"
                                                        style={{minWidth: "40%", width: "fit-content", margin: "auto"}}
                                                        options={currencies}
                                                        getOptionLabel={option => option.name + " (" + option.symbol + ")"}
                                                        value={updateCodCurrency}
                                                        defaultValue={updateCodCurrency}
                                                        noOptionsText={t("no_options")}
                                                        onChange={handleCodCurrencyChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t("currency")}
                                                                variant="outlined"
                                                                margin="dense"
                                                                value={updateCodCurrency}
                                                                defaultValue={updateCodCurrency}
                                                            />
                                                        )}
                                                    />
                                                    <Autocomplete
                                                        id="codMode"
                                                        size="small"
                                                        style={{width: "40%", margin: "auto"}}
                                                        options={codModes}
                                                        getOptionLabel={option => option.name}
                                                        value={updateCodMode}
                                                        defaultValue={updateCodMode}
                                                        onOpen={fetchCodModes}
                                                        onChange={handleCodModeChange}
                                                        noOptionsText={t("no_options")}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t("cod_mode")}
                                                                variant="outlined"
                                                                margin="dense"
                                                                value={updateCodMode}
                                                                defaultValue={updateCodMode}
                                                            />
                                                        )}
                                                    />
                                                    <div>
                                                        <Button
                                                            style={{
                                                                margin: "auto",
                                                                marginTop: "3%",
                                                                marginBottom: "10%",
                                                                marginInline: "2%"
                                                            }}
                                                            endIcon={<CancelOutlined/>}
                                                            onClick={() => {
                                                                setUpdateCodMode(codModes.find(codMode => codMode.id === cod.codModeId))
                                                                setUpdateCod(false);
                                                            }}
                                                            variant={"contained"}>
                                                            {t("cancel")}
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                margin: "auto",
                                                                marginTop: "3%",
                                                                marginBottom: "10%",
                                                                marginInline: "2%"
                                                            }}
                                                            endIcon={<SaveOutlined/>}
                                                            onClick={updateOrDeleteCod}
                                                            color="primary"
                                                            variant={"contained"}>
                                                            {t("save")}
                                                        </Button>
                                                    </div>

                                                </Card>
                                                :

                                                <IconButton onClick={() => setUpdateCod(true)}><Edit/></IconButton>
                                            }
                                            {cod ?
                                                <div>
                                                    <div style={{display: "flex"}}>
                                                        <AmountChip><Typography
                                                            variant={"button"}>{cod.currency + " " + cod.value.toFixed(2) + " - " + cod.codMode}</Typography></AmountChip>
                                                    </div>

                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableCell>{t("datetime")}</TableCell>
                                                            <TableCell>{t("event_code")}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {codEvents.map((codEvent, index) => {


                                                                return <TableRow key={index}>
                                                                    <TableCell>{new Date(codEvent.dateTime).toLocaleDateString() + " " + new Date(codEvent.dateTime).toLocaleTimeString()}</TableCell>
                                                                    <TableCell>
                                                                        <Typography variant={"subtitle2"}>{codEvent.eventCodeName}</Typography>
                                                                        <Typography variant={"caption"}>{returnCodDocumentLabel(codEvent)}</Typography>
                                                                    </TableCell>
                                                                    {permissions.includes("cod-events:delete") && codDeleteEventRequestAllowed.includes(codEvent.eventCodeId) ?
                                                                        <TableCell>
                                                                            <IconButton
                                                                                onClick={() => openConfirmDelete(codEvent.id)}><CancelOutlined
                                                                                style={{color: "darkred"}}/></IconButton>
                                                                            <>
                                                                                {codUpdateEventRequestAllowed.includes(codEvent.eventCodeId) ?
                                                                                    <IconButton
                                                                                        onClick={() => openUpdate(codEvent.id)}><EditOutlined
                                                                                        style={{color: "darkred"}}/></IconButton>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </>
                                                                        </TableCell>
                                                                        :
                                                                        <>
                                                                            {permissions.includes("parcel-events:write") && codDeleteEventRequestAllowed.includes(codEvent.eventCodeId) ?
                                                                                <TableCell>
                                                                                    <IconButton
                                                                                        disabled={checkRequestDisabled(codEvent, codEvents)}
                                                                                        onClick={() => openRequestDelete(codEvent.id)}><EditOutlined
                                                                                        style={{color: checkRequestDisabled(codEvent, codEvents) ? "grey" : "darkred"}}/>
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                :
                                                                                <TableCell/>
                                                                            }
                                                                        </>
                                                                    }
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </div>

                                                :

                                                <>
                                                    {updateCod ?
                                                        null
                                                        :
                                                        <InfoChip>{t("no_cod")}</InfoChip>
                                                    }
                                                </>

                                            }
                                        </Paper>
                                    </Grid>

                                    <Grid item>
                                        {pod ?
                                            <Paper className={classes.pod}>
                                                <DialogTitle>{t("pod")} <IconButton
                                                    color={"info"}
                                                    onClick={fetchPdf}
                                                ><Download/></IconButton></DialogTitle>
                                                <div>
                                                    <Table stickyHeader
                                                           className={classes.table}>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography>{t("consigneeName")}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{pod ? pod.consigneeName : ""}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography>{t("dateTime")}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{pod ? new Date(pod.dateTime).toLocaleDateString() : ""}</Typography>
                                                                <Typography>{pod ? new Date(pod.dateTime).toLocaleTimeString() : ""}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography>{t("reservedAcceptance")}</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{pod.reservedAcceptance ? pod.reservedAcceptanceMessage : t("none")}</Typography>

                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow style={{display: "flex"}}>
                                                            {pod.signature ?
                                                                <PodImg
                                                                    data={pod.signature ? pod.signature.content : null}
                                                                    alt={pod.signature ? pod.signature.fileName : ""}/>
                                                                :
                                                                t("impossibile recuperare la firma")
                                                            }
                                                        </TableRow>


                                                    </Table>
                                                    <Table className={classes.table}>
                                                        {pod ?
                                                            <Button endIcon={<LocationOn/>}
                                                                    style={gpsVisible ? {display: "none"} : {marginLeft: "40%"}}
                                                                    onClick={() => setGpsVisible(true)}>
                                                                {t("show_map")}
                                                            </Button>
                                                            :
                                                            t("no gps message")
                                                        }

                                                        {gpsVisible ?
                                                            returnMap()
                                                            :
                                                            null
                                                        }


                                                    </Table>

                                                </div>


                                            </Paper>
                                            :
                                            null

                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <ParcelsTab trigger={() => setRefresh(!refresh)}
                                        parcels={parcels}
                                        warehouses={warehouses}
                                        eventCodes={parcelEventCodes}
                                        drivers={drivers} shipmentId={id}/>
                        </TabPanel>
                        {
                            permissions.includes("communications:read") ?
                                <TabPanel value="3">
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center"
                                              spacing={1}>
                                            <Grid item>
                                                <div
                                                    className={classes.commColumn}>
                                                    <DialogTitle>{t("emails_sent")}</DialogTitle>
                                                    {emailCommunications.length > 0 ?
                                                        <div>
                                                            {emailCommunications.map((communication, index) => {
                                                                return <Grid
                                                                    item>

                                                                    <Card
                                                                        id={index}
                                                                        className={classes.comCard}>
                                                                        <CardHeader
                                                                            subheader={"Data/ora invio: " + formatDateTime(communication.sentDateTime)}
                                                                        />
                                                                        <Typography>{t("sent_to")}</Typography>
                                                                        {communication.sentTo.map(sent => {
                                                                            return <Typography>{sent}</Typography>
                                                                        })}
                                                                        <CardContent>
                                                                            <div
                                                                                align={"left"}
                                                                                // dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(communication.message)}}/>
                                                                                dangerouslySetInnerHTML={{__html: communication.message}}/>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            })}
                                                        </div>

                                                        :
                                                        <Typography>{t("no_emails_sent")}</Typography>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div
                                                    className={classes.commColumn}>
                                                    <DialogTitle>{t("text_messages_sent")}</DialogTitle>
                                                    {smsCommunications.length > 0 ?
                                                        <div>
                                                            {smsCommunications.map((communication, index) => {
                                                                return <Card
                                                                    className={classes.comCard}>
                                                                    <CardHeader
                                                                        subheader={"Data/ora invio: " + new Date(communication.sentDateTime).toLocaleDateString() + " " + new Date(communication.sentDateTime).toLocaleTimeString()}
                                                                    />
                                                                    <CardContent>
                                                                        {communication.message}
                                                                    </CardContent>
                                                                </Card>
                                                            })}
                                                        </div>

                                                        :

                                                        <Typography>{t("no_text_messages_sent")}</Typography>

                                                    }

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                :
                                null
                        }
                        <TabPanel value="4">
                            <Paper className={classes.noteColumn}>
                                <DialogTitle>{t("notes")}</DialogTitle>
                                {shipmentData.notes ? <div>
                                        {shipmentData.notes.map(note => {
                                            return <Card
                                                className={classes.noteCard}>
                                                <CardHeader
                                                    subheader={t("note_type") + ": " + t(note.noteType.toLowerCase())}
                                                />
                                                <CardContent>
                                                    {note.note}
                                                </CardContent>
                                            </Card>
                                        })}
                                    </div>

                                    :

                                    <Typography>
                                        {t("no_notes")}
                                    </Typography>
                                }

                                {!openNewNote ?
                                    <Button style={{
                                        margin: "auto",
                                        marginBlock: "3%"
                                    }}
                                            endIcon={
                                                <AddCircleOutlineOutlined/>}
                                            onClick={openNoteForm}
                                            variant={"contained"}>
                                        {t("add")}
                                    </Button>
                                    :

                                    null
                                }

                                {openNewNote ?
                                    <Card style={{
                                        width: "50%",
                                        margin: "auto",
                                        marginBlock: "3%"
                                    }}>
                                        <DialogTitle>{t("new_note")}</DialogTitle>
                                        <div>
                                            <FormControl
                                                variant={"outlined"}
                                                // margin={"dense"}
                                                style={{
                                                    margin: "auto",
                                                    marginBlock: "5%",
                                                    width: "30%"
                                                }}
                                            >
                                                <InputLabel>{t("note_type")}</InputLabel>
                                                <Select
                                                    value={noteType}
                                                    label={t("address_type") + "*"}
                                                    onChange={handleNoteTypeChange}
                                                    options={types}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {types.map((type, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={type}
                                                            >
                                                                {t(type.toLowerCase())}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <TextField
                                            style={{
                                                width: "30%",
                                                margin: "auto",
                                                marginBottom: "3%"
                                            }}
                                            size={"small"}
                                            id="pickup-multiline-notes"
                                            label={t("notes")}
                                            multiline
                                            maxRows={5}
                                            value={newNote}
                                            onChange={handleNewNoteChange}
                                        />

                                        <IconButton onClick={saveNote}
                                                    style={{backgroundColor: 'transparent'}}>{loadingDeliveryAddressUpdate ?
                                            <CircularProgress size={25}/> :
                                            <SaveOutlined
                                                style={{color: theme.palette.primary.main}}/>}</IconButton>
                                    </Card>

                                    :

                                    null
                                }

                            </Paper>
                        </TabPanel>
                        {
                            permissions.includes("su:admin") ?
                                <TabPanel value="5">

                                    <AdminTab
                                        refresh={() => {
                                            setRefresh(!refresh);
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth' // for smoothly scrolling
                                            });
                                        }}
                                        quotes={quotes}
                                        accountingRows={accountingRows}
                                        preInvoiceRows={preInvoiceRows}
                                        id={id}
                                        isPudo={shipmentData.service.code === "CP"}
                                    />


                                </TabPanel>

                                :
                                null
                        }
                        <TabPanel value="6">
                            <Paper className={classes.noteColumn}>
                                <DialogTitle>{t("tasks")}</DialogTitle>
                                {tasks.length > 0 ?

                                    //Filiale	Modalità risoluzione prevista	Data prevista apertura	Data ora apertura	Data Prevista risoluzione	Data Ora risoluzione
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {t("warehouse")}
                                            </TableCell>
                                            <TableCell>
                                                {t("expected_task_type")}
                                            </TableCell>
                                            <TableCell>
                                                {t("expected_opening_date")}
                                            </TableCell>
                                            <TableCell>
                                                {t("opening_date")}
                                            </TableCell>
                                            <TableCell>
                                                {t("expected_closing_date")}
                                            </TableCell>
                                            <TableCell>
                                                {t("closing_date")}
                                            </TableCell>
                                        </TableRow>
                                        {tasks.map(task => {
                                            return <TableRow style={{
                                                color: taskColor(task),
                                                fontWeight: "bold"
                                            }}>
                                                <TableCell>
                                                    {task.warehouseCode}
                                                </TableCell>
                                                <TableCell>
                                                    {task.expectedType ? t(task.expectedType) : ""}
                                                </TableCell>
                                                <TableCell>
                                                    {task.expectedStartDateTime ? (new Date(task.expectedStartDateTime).toLocaleDateString() + " - " + new Date(task.expectedStartDateTime).toLocaleTimeString()) : null}
                                                </TableCell>
                                                <TableCell>
                                                    {task.startDateTime ? (new Date(task.startDateTime).toLocaleDateString() + " - " + new Date(task.startDateTime).toLocaleTimeString()) : null}
                                                </TableCell>
                                                <TableCell>
                                                    {task.expectedEndDateTime ? (new Date(task.expectedEndDateTime).toLocaleDateString() + " - " + new Date(task.expectedEndDateTime).toLocaleTimeString()) : null}
                                                </TableCell>
                                                <TableCell>
                                                    {task.endDateTime ? (new Date(task.endDateTime).toLocaleDateString() + " - " + new Date(task.endDateTime).toLocaleTimeString()) : null}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </Table>

                                    :
                                    null
                                }

                                <Button style={{marginTop: "5%"}}
                                        onClick={refreshTasks}><Cached/> {t("refresh")}
                                </Button>
                            </Paper>
                        </TabPanel>
                        <TabPanel value="7">
                            <ShipmentLogsTab logs={logs}/>
                        </TabPanel>
                    </TabContext>
                </Box>

            </div>
        }
    </div>


}

export default AboutMe;