import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Card,
    DialogTitle,
    FormControlLabel,
    TextField,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {Button, Checkbox, Paper, Table, TableCell, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import WarehouseStorageService from "./WarehouseStorageService";
import ShipmentService from "../shipments/ShipmentService";
import {useHistory, useParams} from "react-router-dom";
import {ArrowBackIosRounded} from "@mui/icons-material";
import Notification from "../components/notifications/Notification";
import ServiceService from "../shipments/services/ServiceService";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {LoadingButton} from "@mui/lab";
import theme from "../theme/theme";
import {Check} from "@material-ui/icons";
import {ToggleButton} from "@material-ui/lab";
import DateField from "../components/fields/DateField";
import CodEventCodeService from "../shipments/services/CodEventCodeService";
import moment, {now} from "moment";


function WarehouseStorageReleasePage(props) {

    const [warehouseStorage, setWarehouseStorage] = useState(null);
    const [shipment, setShipment] = useState(null);
    const [provision, setProvision] = useState(null);
    const [address, setAddress] = useState(null);
    const [cod, setCod] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(true);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [releaseModes, setReleaseModes] = useState([]);
    const [releaseMode, setReleaseMode] = useState(null);

    const [internalNotes, setInternalNotes] = useState('');

    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);

    const [countries, setCountries] = useState([]);
    const [administrativeLevel3, setAdministrativeLevel3] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [changeEndUser, setChangeEndUser] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [referencePerson, setReferencePerson] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [documentType, setDocumentType] = useState(null);

    const [documentTypes, setDocumentTypes] = useState([]);

    const [codEventCodes, setCodEventCodes] = useState([]);

    const [codEvent, setCodEvent] = useState(null);
    const [bankName, setBankName] = useState('');
    const [chequeNumber, setChequeNumber] = useState('');
    const [chequeDate, setChequeDate] = useState(null);

    const [collectionDocument, setCollectionDocument] = useState(null);
    const [openCollectionDocument, setOpenCollectionDocument] = useState(false);


    const [deliveryCountry, setDeliveryCountry] = useState(null);
    const [deliveryAdministrativeLevel3, setDeliveryAdministrativeLevel3] = useState(null);
    const [deliveryCity, setDeliveryCity] = useState(null);
    const [deliveryPostalCode, setDeliveryPostalCode] = useState(null);
    const [freeDeliveryAdministrativeLevel1, setFreeDeliveryAdministrativeLevel1] = useState('');
    const [freeDeliveryAdministrativeLevel2, setFreeDeliveryAdministrativeLevel2] = useState('');
    const [freeDeliveryAdministrativeLevel3, setFreeDeliveryAdministrativeLevel3] = useState('');
    const [freeDeliveryCity, setFreeDeliveryCity] = useState('');
    const [freeDeliveryPostalCode, setFreeDeliveryPostalCode] = useState('');
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryBuildingNr, setDeliveryBuildingNr] = useState('');
    const [deliveryCompanyName, setDeliveryCompanyName] = useState('');
    const [deliveryReferencePerson, setDeliveryReferencePerson] = useState('');
    const [deliveryEmail, setDeliveryEmail] = useState('');
    const [deliveryPhone, setDeliveryPhone] = useState('');
    const [deliveryMobile, setDeliveryMobile] = useState('');


    const [endUserCountry, setEndUserCountry] = useState(null);
    const [endUserAdministrativeLevel3, setEndUserAdministrativeLevel3] = useState(null);
    const [endUserCity, setEndUserCity] = useState(null);
    const [endUserPostalCode, setEndUserPostalCode] = useState(null);
    const [freeEndUserAdministrativeLevel1, setFreeEndUserAdministrativeLevel1] = useState('');
    const [freeEndUserAdministrativeLevel2, setFreeEndUserAdministrativeLevel2] = useState('');
    const [freeEndUserAdministrativeLevel3, setFreeEndUserAdministrativeLevel3] = useState('');
    const [freeEndUserCity, setFreeEndUserCity] = useState('');
    const [freeEndUserPostalCode, setFreeEndUserPostalCode] = useState('');
    const [endUserStreet, setEndUserStreet] = useState('');
    const [endUserBuildingNr, setEndUserBuildingNr] = useState('');
    const [endUserCompanyName, setEndUserCompanyName] = useState('');
    const [endUserReferencePerson, setEndUserReferencePerson] = useState('');
    const [endUserEmail, setEndUserEmail] = useState('');
    const [endUserPhone, setEndUserPhone] = useState('');
    const [endUserMobile, setEndUserMobile] = useState('');


    const [returnAddresses, setReturnAddresses] = useState([]);
    const [selectedReturnAddress, setSelectedReturnAddress] = useState(null);

    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();

    function handleBankNameChange(event) {
        setBankName(event.target.value);
    }

    function handleChequeNumberChange(event) {
        setChequeNumber(event.target.value);
    }

    function handleChequeDateChange(event) {
        setChequeDate(event);
    }

    function handleCodEventChange(event, codEvent) {
        setCodEvent(codEvent);
    }

    async function fetchCodEventCodes(codModeId) {
        await new CodEventCodeService().getCodEventCodesForCodMode(codModeId)
            .then(result => {
                setCodEventCodes(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

    }

    function fetchDocumentTypes() {

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getDocumentTypes()
            .then(docTypes => {
                setDocumentTypes(docTypes)
                setDocumentType(docTypes[0]);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })
    }

    function submitRelease() {

        setLoadingSubmit(true);

        let data = {};

        data.releaseModeId = releaseMode.id;

        if (releaseMode && releaseMode.id === 1) {
            let returnAddress = {};

            returnAddress.country = selectedReturnAddress.country;
            returnAddress.administrativeLevel1 = selectedReturnAddress.administrative_level_1;
            returnAddress.administrativeLevel2 = selectedReturnAddress.administrative_level_2;
            returnAddress.administrativeLevel3 = selectedReturnAddress.administrative_level_3;
            returnAddress.city = selectedReturnAddress.city;
            returnAddress.postalCode = selectedReturnAddress.postalCode;
            returnAddress.street = selectedReturnAddress.street;
            returnAddress.buildingNr = selectedReturnAddress.buildingNr;
            returnAddress.companyName = selectedReturnAddress.companyName;
            returnAddress.referencePerson = selectedReturnAddress.referencePerson;
            returnAddress.phone = selectedReturnAddress.phone;
            returnAddress.mobile = selectedReturnAddress.mobile;
            returnAddress.email = selectedReturnAddress.email;

            data.returnAddress = returnAddress;
        }

        if (releaseMode && releaseMode.id === 3) {
            let deliveryAddress = {};

            if (deliveryCountry) {
                deliveryAddress.country = deliveryCountry.iso3166Alpha2;
            }
            if (freeDeliveryAdministrativeLevel1 !== '') {
                deliveryAddress.administrativeLevel1 = freeDeliveryAdministrativeLevel1;
            }

            if (freeDeliveryAdministrativeLevel2 !== '') {
                deliveryAddress.administrativeLevel2 = freeDeliveryAdministrativeLevel2;
            }

            if (freeDeliveryAdministrativeLevel3 !== '') {
                deliveryAddress.administrativeLevel3 = freeDeliveryAdministrativeLevel3;
            } else if (deliveryAdministrativeLevel3) {
                deliveryAddress.administrativeLevel3 = deliveryAdministrativeLevel3.code;
            }

            if (freeDeliveryCity !== '') {
                deliveryAddress.city = freeDeliveryCity;
            } else if (deliveryCity) {
                deliveryAddress.city = deliveryCity.name;
            }

            if (freeDeliveryPostalCode !== '') {
                deliveryAddress.postalCode = freeDeliveryPostalCode;
            } else if (deliveryPostalCode) {
                deliveryAddress.postalCode = deliveryPostalCode.postalCode;
            }

            if (deliveryStreet !== '') {
                deliveryAddress.street = deliveryStreet;
            }

            if (deliveryBuildingNr !== '') {
                deliveryAddress.buildingNr = deliveryBuildingNr;
            }

            if (deliveryCompanyName !== '') {
                deliveryAddress.companyName = deliveryCompanyName;
            }

            if (deliveryReferencePerson !== '') {
                deliveryAddress.referencePerson = deliveryReferencePerson;
            }

            if (deliveryPhone !== '') {
                deliveryAddress.phone = deliveryPhone;
            }

            if (deliveryMobile !== '') {
                deliveryAddress.mobile = deliveryMobile;
            }

            if (deliveryEmail !== '') {
                deliveryAddress.email = deliveryEmail;
            }

            data.newDeliveryAddress = deliveryAddress;

            let endUserAddress = null;
            if (changeEndUser) {
                endUserAddress = {};

                if (endUserCountry) {
                    endUserAddress.country = endUserCountry.iso3166Alpha2;
                }
                if (freeEndUserAdministrativeLevel1 !== '') {
                    endUserAddress.administrativeLevel1 = freeEndUserAdministrativeLevel1;
                }

                if (freeEndUserAdministrativeLevel2 !== '') {
                    endUserAddress.administrativeLevel2 = freeEndUserAdministrativeLevel2;
                }

                if (freeEndUserAdministrativeLevel3 !== '') {
                    endUserAddress.administrativeLevel3 = freeEndUserAdministrativeLevel3;
                } else if (endUserAdministrativeLevel3) {
                    endUserAddress.administrativeLevel3 = endUserAdministrativeLevel3.code;
                }

                if (freeEndUserCity !== '') {
                    endUserAddress.city = freeEndUserCity;
                } else if (endUserCity) {
                    endUserAddress.city = endUserCity.name;
                }

                if (freeEndUserPostalCode !== '') {
                    endUserAddress.postalCode = freeEndUserPostalCode;
                } else if (endUserPostalCode) {
                    endUserAddress.postalCode = endUserPostalCode.postalCode;
                }

                if (endUserStreet !== '') {
                    endUserAddress.street = endUserStreet;
                }

                if (endUserBuildingNr !== '') {
                    endUserAddress.buildingNr = endUserBuildingNr;
                }

                if (endUserCompanyName !== '') {
                    endUserAddress.companyName = endUserCompanyName;
                }

                if (endUserReferencePerson !== '') {
                    endUserAddress.referencePerson = endUserReferencePerson;
                }

                if (endUserPhone !== '') {
                    endUserAddress.phone = endUserPhone;
                }

                if (endUserMobile !== '') {
                    endUserAddress.mobile = endUserMobile;
                }

                if (endUserEmail !== '') {
                    endUserAddress.email = endUserEmail;
                }
            }

            data.newEndUserAddress = endUserAddress;

            if (service) {
                data.newServiceCode = service.code;
            }
        }

        if (releaseMode && releaseMode.id === 4) {
            data.documentNumber = documentNumber;
            data.documentTypeId = documentType.id;
            data.pickupReferencePerson = referencePerson;
            if (codEvent) {
                data.collectCod = {}
                data.collectCod.dateTime = moment(now()).format('YYYY-MM-DDTHH:mm:ssZ');
                data.collectCod.codEventCodeId = codEvent.id;
                if (bankName !== '') {
                    data.collectCod.bankName = bankName;
                }

                if (chequeNumber !== '') {
                    data.collectCod.chequeNumber = chequeNumber
                }

                if (null != chequeDate) {
                    data.collectCod.chequeDate = moment(chequeDate).format('yyyy-MM-DD')
                }
            }

        }

        if (releaseMode && releaseMode.id === 8) {
            data.pudoId = provision.pudoId;
        }

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.doRelease(warehouseStorage.id, data)
            .then(result => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);
                setLoadingSubmit(false);
                setTimeout(async () => {
                    if (releaseMode.id === 4) {
                        await fetchCollectionDocument();
                    } else {
                        history.push(`/shipments/${result.id}/label`)
                    }

                })
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                    setLoadingSubmit(false);
                })
            })

        setLoadingSubmit(false);
    }

    async function fetchCollectionDocument() {

        await new WarehouseStorageService().getCollectionDocument(warehouseStorage.id)
            .then(result => {
                setCollectionDocument(result.content)
                setOpenCollectionDocument(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

    }


    function fetchData() {

        setLoading(true);

        let warehouseStorageService = new WarehouseStorageService();
        let shipmentService = new ShipmentService();

        warehouseStorageService.getWarehouseStorageById(id)
            .then(async warehouseStorage => {
                setWarehouseStorage(warehouseStorage);

                await new WarehouseStorageService().getReturnAddressesForShipment(warehouseStorage?.storedShipmentId)
                    .then(result => {
                        setReturnAddresses(result);
                        setSelectedReturnAddress(result ? result[0] : null)
                    })
                    .catch(error => {

                    })
                shipmentService.getShipmentById(warehouseStorage.storedShipmentId, null)
                    .then(shipment => {
                        setShipment(shipment);
                        setAddress(shipment.deliveryAddress);

                        if (shipment.codId != null) {
                            shipmentService.getCodById(shipment.codId)
                                .then(cod => {
                                    setCod(cod);
                                })
                                .catch(error => {

                                })
                        }

                    })
                    .catch(error => {

                    })
                let warehouseStorageService = new WarehouseStorageService();
                warehouseStorageService.getReleaseModes(warehouseStorage.id)
                    .then(data => {
                        setReleaseModes(data);

                        if (warehouseStorage.provisionId != null) {
                            warehouseStorageService.getProvisionByWarehouseStorageId(warehouseStorage.id)
                                .then(provision => {
                                    setProvision(provision);
                                    setReleaseMode(data.filter(releaseMode => releaseMode.id === provision.releaseMode.id)[0]);

                                    if (provision.deliveryAddress != null) {

                                        let countryService = new CountryService();
                                        countryService.getCountriesByFilters(null, null, null, null)
                                            .then(countries => {
                                                setCountries(countries.countries);
                                                setDeliveryCountry(countries.countries.filter(country => String(country.iso3166Alpha3).toUpperCase() === String(provision.deliveryAddress.country).toUpperCase())[0]);
                                                setEndUserCountry(countries.countries.filter(country => String(country.iso3166Alpha3).toUpperCase() === String(provision.endUserAddress.country).toUpperCase())[0]);
                                            })


                                        let administrativeLevel3Service = new AdministrativeLevel3Service();
                                        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                                            .then(data => {
                                                setAdministrativeLevel3(data.admins3)
                                                setDeliveryAdministrativeLevel3(data.admins3.filter(admin3 => String(admin3.code).toUpperCase() === String(provision.deliveryAddress.administrative_level_3).toUpperCase())[0]);
                                                setEndUserAdministrativeLevel3(data.admins3.filter(admin3 => String(admin3.code).toUpperCase() === String(provision.endUserAddress.administrative_level_3).toUpperCase())[0]);
                                            })

                                        let filters = {};

                                        let cityService = new CityService();
                                        cityService.getCities(null, null, filters, null)
                                            .then(data => {
                                                setCities(data.cities);
                                                setDeliveryCity(data.cities.filter(city => String(city.name).toUpperCase() === String(provision.deliveryAddress.city).toUpperCase())[0]);
                                                setEndUserCity(data.cities.filter(city => String(city.name).toUpperCase() === String(provision.endUserAddress.city).toUpperCase())[0]);
                                            })
                                            .catch(error => {

                                            })

                                        let postalCodeService = new PostalCodeService();
                                        postalCodeService.getAllPostalCodes(null, null, filters, null)
                                            .then(data => {
                                                setPostalCodes(data.postalCodes);
                                                setDeliveryPostalCode(data.postalCodes.filter(postalCode => String(postalCode.postalCode).toUpperCase() === String(provision.deliveryAddress.postalCode).toUpperCase())[0]);
                                                setEndUserPostalCode(data.postalCodes.filter(postalCode => String(postalCode.postalCode).toUpperCase() === String(provision.endUserAddress.postalCode).toUpperCase())[0]);

                                            })
                                            .catch(error => {

                                            })


                                        setDeliveryStreet(provision.deliveryAddress.street);
                                        setDeliveryBuildingNr(provision.deliveryAddress.buildingNr);
                                        setDeliveryCompanyName(provision.deliveryAddress.companyName);
                                        setDeliveryReferencePerson(provision.deliveryAddress.referencePerson);
                                        setDeliveryEmail(provision.deliveryAddress.email);
                                        setDeliveryMobile(provision.deliveryAddress.mobile);
                                        setDeliveryPhone(provision.deliveryAddress.phone);

                                        setEndUserStreet(provision.endUserAddress.street);
                                        setEndUserBuildingNr(provision.endUserAddress.buildingNr);
                                        setEndUserCompanyName(provision.endUserAddress.companyName);
                                        setEndUserReferencePerson(provision.endUserAddress.referencePerson);
                                        setEndUserEmail(provision.endUserAddress.email);
                                        setEndUserMobile(provision.endUserAddress.mobile);
                                        setEndUserPhone(provision.endUserAddress.phone);

                                    }
                                    if (provision.serviceCode != null) {

                                        let filters = {};

                                        // filters.contractCode = shipment.contract.code;
                                        // filters.accountCorporateCode = shipment.account.accountCorporateCode;

                                        let service = new ServiceService();
                                        service.getServicesByFilters(filters)
                                            .then(data => {
                                                setServices(data);
                                                setService(data.filter(serv => serv.code === provision.serviceCode)[0]);
                                            })
                                            .catch(error => {

                                            })

                                    }
                                })
                        }

                    })
                    .catch(error => {

                    })


            })
            .catch(error => {

            })

        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }

    function closeNotification() {
        setOpenNotify(false);
    }


    function fetchReleaseModes() {
        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getReleaseModes(id)
            .then(data => {
                setReleaseModes(data);
            })
            .catch(error => {

            })
    }

    function fetchServices() {

        setServices([]);

        let filters = {}

        if (shipment) {
            filters.contractCode = shipment.contract.code;
            filters.accountCorporateCode = shipment.account.accountCorporateCode;
        }

        let service = new ServiceService();
        service.getServicesByFilters(filters)
            .then(data => {
                setServices(data);
            })
            .catch(error => {

            })
    }

    function handleDeliveryCountryChange(event, country) {

        setDeliveryCountry(country);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (changeEndUser) {

            setEndUserCountry(country);
            setEndUserAdministrativeLevel3(null);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }
    }

    function handleDeliveryAdministrativeLevel3Change(event, adminl3) {
        setDeliveryAdministrativeLevel3(adminl3);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (changeEndUser) {
            setEndUserAdministrativeLevel3(adminl3);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }

    }

    function handleFreeDeliveryAdministrativeLevel1Change(event) {
        setFreeDeliveryAdministrativeLevel1(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel1(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel2Change(event) {
        setFreeDeliveryAdministrativeLevel2(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel2(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel3Change(event) {
        setFreeDeliveryAdministrativeLevel3(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel3(event.target.value);
        }
    }

    function handleDeliveryCityChange(event, city) {
        setDeliveryCity(city);
        setDeliveryPostalCode(null);
        if (changeEndUser) {
            setEndUserCity(city);
            setEndUserPostalCode(null);
        }
    }

    function handleFreeDeliveryCityChange(event) {
        setFreeDeliveryCity(event.target.value);
        if (changeEndUser) {
            setFreeEndUserCity(event.target.value);
        }
    }

    function handleFreeDeliveryPostalCodeChange(event) {
        setFreeDeliveryPostalCode(event.target.value);
        if (changeEndUser) {
            setFreeEndUserPostalCode(event.target.value);
        }
    }

    function handleDeliveryPostalCodeChange(event, postalCode) {
        setDeliveryPostalCode(postalCode);
        if (changeEndUser) {
            setEndUserPostalCode(postalCode);
        }
    }

    function handleDeliveryStreetChange(event) {
        setDeliveryStreet(event.target.value);
        if (changeEndUser) {
            setEndUserStreet(event.target.value);
        }
    }

    function handleDeliveryBuildingNrChange(event) {
        setDeliveryBuildingNr(event.target.value);
        if (changeEndUser) {
            setEndUserBuildingNr(event.target.value);
        }
    }

    function handleDeliveryCompanyNameChange(event) {
        setDeliveryCompanyName(event.target.value);
        if (changeEndUser) {
            setEndUserCompanyName(event.target.value);
        }
    }

    function handleDeliveryReferencePerson(event) {
        setDeliveryReferencePerson(event.target.value);
        if (changeEndUser) {
            setEndUserReferencePerson(event.target.value);
        }
    }

    function handleDeliveryEmail(event) {
        setDeliveryEmail(event.target.value);
        if (changeEndUser) {
            setEndUserEmail(event.target.value);
        }
    }

    function handleDeliveryPhone(event) {
        setDeliveryPhone(event.target.value);
        if (changeEndUser) {
            setEndUserPhone(event.target.value);
        }
    }

    function handleDeliveryMobile(event) {
        setDeliveryMobile(event.target.value);
        if (changeEndUser) {
            setEndUserMobile(event.target.value);
        }
    }


    function handleEndUserCountryChange(event, country) {
        setEndUserCountry(country);
        setEndUserAdministrativeLevel3(null);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleEndUserAdministrativeLevel3Change(event, adminl3) {
        setEndUserAdministrativeLevel3(adminl3);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserAdministrativeLevel1Change(event) {
        setFreeEndUserAdministrativeLevel1(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel2Change(event) {
        setFreeEndUserAdministrativeLevel2(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel3Change(event) {
        setFreeEndUserAdministrativeLevel3(event.target.value);
    }

    function handleEndUserCityChange(event, city) {
        setEndUserCity(city);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserCityChange(event, city) {
        setEndUserCity(event.target.value);
    }

    function handleFreeEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(event.target.value);
    }

    function handleEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(postalCode);
    }

    function handleEndUserStreetChange(event, street) {
        setEndUserStreet(street);
    }

    function handleEndUserBuildingNrChange(event, buildingNr) {
        setEndUserBuildingNr(buildingNr);
    }

    function handleEndUserCompanyNameChange(event, companyName) {
        setEndUserCompanyName(companyName);
    }

    function handleEndUserReferencePerson(event, referencePerson) {
        setEndUserReferencePerson(referencePerson);
    }

    function handleEndUserEmail(event) {
        setEndUserEmail(event.target.value);
    }

    function handleEndUserPhone(event) {
        setEndUserPhone(event.target.value);
    }

    function handleEndUserMobile(event) {
        setEndUserMobile(event.target.value);
    }

    function handleReferencePersonChange(event) {
        setReferencePerson(event.target.value);
    }

    function handleDocumentNumberChange(event) {
        setDocumentNumber(event.target.value);
    }

    function handleDocumentTypeChange(event, documentType) {
        setDocumentType(documentType);
    }

    function setEndUserAddress() {
        setEndUserCountry(deliveryCountry);
        setEndUserAdministrativeLevel3(deliveryAdministrativeLevel3);
        setEndUserCity(deliveryCity);
        setEndUserPostalCode(deliveryPostalCode);
        setEndUserStreet(deliveryStreet);
        setEndUserBuildingNr(deliveryBuildingNr);
        setEndUserCompanyName(deliveryCompanyName);
        setEndUserReferencePerson(deliveryReferencePerson);
        setEndUserEmail(deliveryEmail);
        setEndUserPhone(deliveryPhone);
        setEndUserMobile(deliveryMobile);

        setFreeEndUserAdministrativeLevel1(freeDeliveryAdministrativeLevel1);
        setFreeEndUserAdministrativeLevel2(freeDeliveryAdministrativeLevel2);
        setFreeEndUserAdministrativeLevel3(freeDeliveryAdministrativeLevel3);
        setFreeEndUserCity(freeDeliveryCity);
        setFreeEndUserPostalCode(freeDeliveryPostalCode);

    }

    function changeEndUserEqualDelivery() {
        setChangeEndUser(!changeEndUser);
        if (!changeEndUser) {
            setEndUserAddress();
        }
        // else {
        //     clearEndUserAddress();
        // }
    }

    function handleReturnAddressChange(event, address) {
        if (null !== address) {
            setSelectedReturnAddress(address);
        }
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    function fetchAdministrativeLevel3() {
        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, null, null)
            .then(data => {
                setAdministrativeLevel3(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function generateDeliveryAddress(country) {

        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!deliveryCountry}
                    getOptionLabel={option => option.name}
                    value={deliveryAdministrativeLevel3}
                    defaultValue={deliveryAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(deliveryCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryAdministrativeLevel3}
                            defaultValue={deliveryAdministrativeLevel3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!deliveryAdministrativeLevel3}
                    getOptionLabel={option => option.name}
                    value={deliveryCity}
                    defaultValue={deliveryCity}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryCityChange}
                    onOpen={() => fetchCities(deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryCity}
                            defaultValue={deliveryCity}
                            required
                            error={!deliveryCity}
                            helperText={!deliveryAdministrativeLevel3 ? t("select_admin3_first") : (!deliveryCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={deliveryPostalCode}
                    defaultValue={deliveryPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryPostalCodeChange}
                    onOpen={() => fetchPostalCodes(deliveryCity, deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryPostalCode}
                            defaultValue={deliveryPostalCode}
                            required
                            error={!deliveryPostalCode}
                            helperText={!deliveryPostalCode ? t("required") : null}
                        />
                    )}
                />
            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel1}
                        onChange={handleFreeDeliveryAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel2}
                        onChange={handleFreeDeliveryAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel3}
                        onChange={handleFreeDeliveryAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryCity}
                        onChange={handleFreeDeliveryCityChange}
                        required
                        error={!freeDeliveryCity}
                        helperText={!freeDeliveryCity ? t("required") : null}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryPostalCode}
                        onChange={handleFreeDeliveryPostalCodeChange}
                        required
                        error={!freeDeliveryPostalCode}
                        helperText={!freeDeliveryPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }

    }

    function generateEndUserAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!endUserCountry || !changeEndUser}
                    getOptionLabel={option => option.name}
                    value={endUserAdministrativeLevel3}
                    defaultValue={endUserAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(endUserCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={endUserAdministrativeLevel3}
                            defaultValue={endUserAdministrativeLevel3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!endUserAdministrativeLevel3 || !changeEndUser}
                    getOptionLabel={option => option.name}
                    value={endUserCity}
                    defaultValue={endUserCity}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserCityChange}
                    onOpen={() => fetchCities(endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={endUserCity}
                            defaultValue={endUserCity}
                            required
                            error={!endUserCity}
                            helperText={!endUserAdministrativeLevel3 ? t("select_admin3_first") : (!endUserCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    disabled={!changeEndUser}
                    getOptionLabel={option => option.postalCode}
                    value={endUserPostalCode}
                    defaultValue={endUserPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserPostalCodeChange}
                    onOpen={() => fetchPostalCodes(endUserPostalCode, endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={endUserPostalCode}
                            defaultValue={endUserPostalCode}
                            required
                            error={!endUserPostalCode}
                            helperText={!endUserPostalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel1}
                        onChange={handleFreeEndUserAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel2}
                        onChange={handleFreeEndUserAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel3}
                        onChange={handleFreeEndUserAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        disabled={!changeEndUser}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserCity}
                        required
                        error={!freeEndUserCity}
                        helperText={!freeEndUserCity ? t("required") : null}
                        onChange={handleFreeEndUserCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserPostalCode}
                        onChange={handleFreeEndUserPostalCodeChange}
                        required
                        error={!freeEndUserPostalCode}
                        helperText={!freeEndUserPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function handleServiceChange(event, service) {
        setService(service);
    }


    function handleInternalNotes(event) {
        setInternalNotes(event.target.value);
    }

    function handleReleaseModeChange(event, releaseMode) {
        setReleaseMode(releaseMode);
    }

    useEffect(() => {
        fetchServices();
        fetchReleaseModes();
        fetchAdministrativeLevel3();
        fetchCities();
        fetchCountries();
        fetchPostalCodes();
        fetchDocumentTypes();
        fetchData();
    }, [refresh])

    return <>

        <Button style={{margin: "auto", marginBottom: "2%"}} startIcon={<ArrowBackIosRounded/>}
                onClick={() => history.goBack()}>{t("go_back")}</Button>

        {collectionDocument ?

            <div style={{margin: "auto", marginTop: "5%"}}>
                <object style={{margin: "auto"}} width={800} height={800} type={'application/pdf'}
                        data={"data:application/pdf;base64," + collectionDocument}>";
                    html += "
                </object>
            </div>
            :
            <Paper style={{width: "100%", maxWidth: 1280, margin: "auto", marginTop: "5%"}}>
                {loading ?
                    <LoadingComponent/>
                    :
                    <>
                        <DialogTitle style={{margin: "5%"}}>{t("release_warehouse_storage")}</DialogTitle>
                        <Table>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("warehouse_storage_id")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("shipment_id")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("customer_code")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("service")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("delivery")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("cod")}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>{warehouseStorage ? warehouseStorage.id : null}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{shipment ? shipment.id : null}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{shipment ? shipment.customerCode : null}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{shipment ? shipment.service.code + " - " + shipment.service.name : null}</Typography>
                                </TableCell>
                                <TableCell>
                                    {address ?
                                        <div>
                                            <Typography>{address.referencePerson ? address.referencePerson : null}{address.companyName ? " - " + address.companyName : null}</Typography>
                                            <Typography>{address.street ? address.street : null}{address.buildingNr ? ", " + address.buildingNr : null}</Typography>
                                            <Typography>{address.postalCode ? address.postalCode : null} {address.city ? address.city : null} {address.administrativeLevel3 ? "(" + address.administrativeLevel3 + ")" : null}</Typography>
                                        </div>
                                        :
                                        null
                                    }
                                </TableCell>
                                <TableCell>
                                    <Typography>{cod ? cod.value + " " + cod.currency : t("no_cod")}</Typography>
                                </TableCell>
                            </TableRow>
                        </Table>

                        <Table style={{
                            width: "fit-content",
                            margin: "auto",
                            marginBlock: "5%",
                            border: "1px solid",
                            borderColor: "lightgrey"
                        }}>
                            <TableRow>
                                <TableCell style={{width: "50%", textAlign: "right"}}>
                                    <Typography variant={"subtitle2"}>{t("warehouse_storage_notes")}</Typography>
                                </TableCell>
                                <TableCell style={{width: "50%", textAlign: "left"}}>
                                    <Typography>{warehouseStorage ? warehouseStorage.notes : null}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "50%", textAlign: "right"}}>
                                    <Typography variant={"subtitle2"}>{t("provision_notes")}</Typography>
                                </TableCell>
                                <TableCell style={{width: "50%", textAlign: "left"}}>
                                    <Typography>{provision?.notes}</Typography>
                                </TableCell>


                            </TableRow>
                        </Table>
                        <TableRow style={{display: "flex", margin: "5%"}}>
                            <TextField
                                style={{width: "60%", margin: "auto"}}
                                label={t("internal_notes")}
                                variant="outlined"
                                margin="dense"
                                onChange={handleInternalNotes}
                                value={internalNotes}
                                multiline
                                minRows={4}
                                maxRows={4}
                                // className={classes.field}
                            />
                        </TableRow>
                        <TableRow style={{display: "flex", margin: "5%"}}>
                            <Autocomplete
                                id="releaseMode"
                                size="small"
                                style={{
                                    minWidth: "30%",
                                    width: "fit-content",
                                    margin: "auto"
                                }}
                                options={releaseModes}
                                getOptionLabel={option => option.description}
                                value={releaseMode}
                                defaultValue={releaseMode}
                                onChange={handleReleaseModeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("release_mode")}
                                        variant="outlined"
                                        margin="dense"
                                        value={releaseMode}
                                        defaultValue={releaseMode}
                                    />
                                )}
                            />
                        </TableRow>
                        {releaseMode && releaseMode.id === 1 ?
                            <div>
                                <DialogTitle><Typography variant={"subtitle1"}>{t("select_return_address")}</Typography>
                                </DialogTitle>
                                <ToggleButtonGroup
                                    value={selectedReturnAddress}
                                    exclusive
                                    onChange={handleReturnAddressChange}
                                >
                                    {returnAddresses.map(address => {

                                        return <ToggleButton value={address} aria-label="left aligned">
                                            <Card style={{
                                                minWidth: 300,
                                                minHeight: 150,
                                                textAlign: "left",
                                                padding: "3%"
                                            }}>
                                                <Typography
                                                    variant={"subtitle2"}>{address.companyName} {address.referencePerson}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{address.postalCode} {address.city} ({address.administrative_level_3}), {address.country}</Typography>
                                                <Typography
                                                    variant={"subtitle2"}>{address.street}, {address.buildingNr}</Typography>
                                                <Typography variant={"subtitle2"}>{address.email}</Typography>
                                                <Typography variant={"subtitle2"}>{address.phone}</Typography>
                                                <Typography variant={"subtitle2"}>{address.mobile}</Typography>
                                            </Card>
                                        </ToggleButton>
                                    })}
                                </ToggleButtonGroup>
                            </div>

                            :
                            null
                        }
                        {releaseMode && releaseMode.id === 3 ?
                            <div>
                                <TableRow style={{display: "flex", margin: "5%"}}>
                                    <Autocomplete
                                        id="service"
                                        size="small"
                                        style={{
                                            minWidth: "30%",
                                            width: "fit-content",
                                            margin: "auto",
                                            marginBottom: "3%"
                                        }}
                                        options={services}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        value={service}
                                        defaultValue={service}
                                        onOpen={fetchServices}
                                        onChange={handleServiceChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("service")}
                                                variant="outlined"
                                                margin="dense"
                                                value={service}
                                                defaultValue={service}
                                            />
                                        )}
                                    />
                                </TableRow>
                                <TableRow style={{display: "flex", margin: "5%"}}>

                                    <div style={{width: "100%", display: "flex"}}>
                                        <Card elevation={1} style={{width: "70%", margin: "auto"}}>
                                            <DialogTitle
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between"
                                                }}>

                                                <Typography style={{marginTop: "2%"}} variant={"button"}>
                                                    {t("delivery_address")}
                                                </Typography>

                                                {/*<Stack direction="row" spacing={0.5} alignItems="center">*/}
                                                {/*    <Tooltip title={t("Imposta destinatario uguale a consegna")}>*/}
                                                {/*        <ContentCopy/>*/}
                                                {/*    </Tooltip>*/}

                                                {/*    <FormControlLabel*/}
                                                {/*        control={<Switch style={{color: "primary"}}*/}
                                                {/*                         checked={!changeEndUser}*/}
                                                {/*                         onClick={changeEndUserEqualDelivery}*/}
                                                {/*                         inputProps={{'aria-label': 'ant design'}}/>}*/}

                                                {/*        // <Typography style={{width : "30%", textAlign: "left"}} variant={"caption"}>{t("Imposta indirizzo destinatario uguale all'indirizzo di consegna")}</Typography>*/}
                                                {/*        label={""}/>*/}

                                                {/*    <Tooltip title={t("Modifica destinatario")}>*/}
                                                {/*        <Edit/>*/}
                                                {/*    </Tooltip>*/}
                                                {/*</Stack>*/}
                                            </DialogTitle>
                                            <div>

                                                <Autocomplete
                                                    id="country"
                                                    size="small"
                                                    style={{
                                                        minWidth: "35%",
                                                        width: "fit-content",
                                                        marginBottom: "2%",
                                                        marginLeft: "2%"
                                                    }}
                                                    options={countries}
                                                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                    value={deliveryCountry}
                                                    defaultValue={deliveryCountry}
                                                    onChange={handleDeliveryCountryChange}
                                                    onSelect={fetchCountries}
                                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                                    noOptionsText={t("no_options")}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label={t("country")}
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={deliveryCountry}
                                                        defaultValue={deliveryCountry}
                                                        required
                                                        error={!deliveryCountry}
                                                        helperText={!deliveryCountry ? t("required") : null}
                                                    />}
                                                />

                                                {generateDeliveryAddress(deliveryCountry)}

                                                <div style={{display: "flex", marginBottom: "5%"}}>

                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("street")}
                                                        variant={"outlined"}

                                                        size={"small"}
                                                        value={deliveryStreet}
                                                        required
                                                        error={!deliveryStreet}
                                                        helperText={!deliveryStreet ? t("required") : null}
                                                        onChange={handleDeliveryStreetChange}
                                                    />
                                                    <TextField
                                                        style={{marginInline: "2%", width: "10%"}}
                                                        label={t("buildingNr")}
                                                        variant={"outlined"}

                                                        size={"small"}
                                                        value={deliveryBuildingNr}
                                                        onChange={handleDeliveryBuildingNrChange}
                                                    />

                                                </div>
                                                <div style={{display: "flex", marginBottom: "5%"}}>
                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("shipment_company_name")}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        value={deliveryCompanyName}
                                                        onChange={handleDeliveryCompanyNameChange}
                                                    />
                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("shipment_reference_person")}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        value={deliveryReferencePerson}
                                                        onChange={handleDeliveryReferencePerson}
                                                    />
                                                </div>
                                                <div style={{display: "flex", marginBottom: "5%"}}>
                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("email")}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        value={deliveryEmail}
                                                        onChange={handleDeliveryEmail}
                                                    />
                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("phone")}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        value={deliveryPhone}
                                                        onChange={handleDeliveryPhone}
                                                    />
                                                    <TextField
                                                        style={{marginInline: "2%"}}
                                                        label={t("mobile")}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        value={deliveryMobile}
                                                        onChange={handleDeliveryMobile}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </TableRow>
                                <TableRow style={{display: "flex", margin: "5%"}}>
                                    <div style={{width: "100%", display: "flex"}}>
                                        <FormControlLabel
                                            style={{margin: "auto"}}
                                            label={<Typography fontSize={12}
                                                               variant={"button"}>{t("set_destination")}</Typography>}
                                            control={
                                                <Checkbox
                                                    checked={changeEndUser}
                                                    onChange={changeEndUserEqualDelivery}
                                                />
                                            }
                                        />
                                    </div>

                                </TableRow>
                                <TableRow style={{display: "flex", margin: "5%"}}>
                                    <div style={{width: "100%", display: "flex"}}>
                                        {changeEndUser ?
                                            <Card elevation={1} style={{width: "70%", margin: "auto"}}>
                                                <DialogTitle
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-between"
                                                    }}>

                                                    <Typography style={{marginTop: "2%"}} variant={"button"}>
                                                        {t("end_user")}
                                                    </Typography>

                                                    {/*<Stack direction="row" spacing={0.5} alignItems="center">*/}
                                                    {/*    <Tooltip title={t("Imposta destinatario uguale a consegna")}>*/}
                                                    {/*        <ContentCopy/>*/}
                                                    {/*    </Tooltip>*/}

                                                    {/*    <FormControlLabel*/}
                                                    {/*        control={<Switch style={{color: "primary"}}*/}
                                                    {/*                         checked={!endUserEqualDelivery}*/}
                                                    {/*                         onClick={changeEndUserEqualDelivery}*/}
                                                    {/*                         inputProps={{'aria-label': 'ant design'}}/>}*/}

                                                    {/*        // <Typography style={{width : "30%", textAlign: "left"}} variant={"caption"}>{t("Imposta indirizzo destinatario uguale all'indirizzo di consegna")}</Typography>*/}
                                                    {/*        label={""}/>*/}

                                                    {/*    <Tooltip title={t("Modifica destinatario")}>*/}
                                                    {/*        <Edit/>*/}
                                                    {/*    </Tooltip>*/}
                                                    {/*</Stack>*/}
                                                </DialogTitle>
                                                <div>

                                                    <Autocomplete
                                                        id="country"
                                                        size="small"
                                                        style={{
                                                            minWidth: "35%",
                                                            width: "fit-content",
                                                            marginBottom: "2%",
                                                            marginLeft: "2%"
                                                        }}
                                                        options={countries}
                                                        disabled={!changeEndUser}
                                                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                        value={endUserCountry}
                                                        defaultValue={endUserCountry}
                                                        onChange={handleEndUserCountryChange}
                                                        onSelect={fetchCountries}
                                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                                        noOptionsText={t("no_options")}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            label={t("country")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={endUserCountry}
                                                            defaultValue={endUserCountry}
                                                            required
                                                            error={!endUserCountry}
                                                            helperText={!endUserCountry ? t("required") : null}
                                                        />}
                                                    />

                                                    {generateEndUserAddress(endUserCountry)}

                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                        <TextField
                                                            style={{marginInline: "2%"}}
                                                            disabled={!changeEndUser}
                                                            label={t("street")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserStreet}
                                                            required
                                                            error={!endUserStreet}
                                                            helperText={!endUserStreet ? t("required") : null}
                                                            onChange={handleEndUserStreetChange}
                                                        />
                                                        <TextField
                                                            style={{marginInline: "2%", width: "10%"}}
                                                            label={t("buildingNr")}
                                                            disabled={!changeEndUser}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserBuildingNr}
                                                            onChange={handleEndUserBuildingNrChange}
                                                        />
                                                    </div>
                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                        <TextField
                                                            style={{marginInline: "2%"}}
                                                            disabled={!changeEndUser}
                                                            label={t("shipment_company_name")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserCompanyName}
                                                            onChange={handleEndUserCompanyNameChange}
                                                        />

                                                        <TextField
                                                            style={{marginInline: "2%"}}
                                                            disabled={!changeEndUser}
                                                            label={t("shipment_reference_person")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserReferencePerson}
                                                            onChange={handleEndUserReferencePerson}
                                                        />
                                                    </div>
                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                        <TextField
                                                            disabled={!changeEndUser}
                                                            style={{marginInline: "2%"}}
                                                            label={t("email")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserEmail}
                                                            onChange={handleEndUserEmail}
                                                        />
                                                        <TextField
                                                            disabled={!changeEndUser}
                                                            style={{marginInline: "2%"}}
                                                            label={t("phone")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserPhone}
                                                            onChange={handleEndUserPhone}
                                                        />
                                                        <TextField
                                                            disabled={!changeEndUser}
                                                            style={{marginInline: "2%"}}
                                                            label={t("mobile")}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            value={endUserMobile}
                                                            onChange={handleEndUserMobile}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                            :
                                            null
                                        }
                                    </div>
                                </TableRow>
                            </div>
                            :
                            null
                        }
                        {releaseMode && releaseMode.id === 4 ?
                            <div>
                                <div>
                                    <TextField
                                        style={{marginBlock: "2%", width: "20%"}}
                                        label={t("reference_person")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={referencePerson}
                                        onChange={handleReferencePersonChange}
                                    />
                                </div>
                                <div>
                                    <Autocomplete
                                        id="documentType"
                                        size="small"
                                        style={{
                                            minWidth: "20%",
                                            width: "fit-content",
                                            margin: "auto"
                                        }}
                                        options={documentTypes}
                                        getOptionLabel={option => option.description}
                                        value={documentType}
                                        defaultValue={documentType}
                                        onChange={handleDocumentTypeChange}
                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                        noOptionsText={t("no_options")}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label={t("document_type")}
                                            variant="outlined"
                                            margin="dense"
                                            value={documentType}
                                            defaultValue={documentType}
                                            required
                                            error={!documentType}
                                            helperText={!documentType ? t("required") : null}
                                        />}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        style={{marginBlock: "2%", width: "20%"}}
                                        label={t("document_number")}
                                        variant={"outlined"}
                                        size={"small"}
                                        value={documentNumber}
                                        onChange={handleDocumentNumberChange}
                                    />
                                </div>
                                {cod != null ?
                                    <div style={{display: "block"}}>
                                        <div style={{width: "100%", margin: "auto", marginBottom: "5%"}}>
                                            <Autocomplete
                                                id="codMode"
                                                size="small"
                                                style={{
                                                    marginBottom: "2%",
                                                    width: "20%",
                                                    margin: "auto"
                                                }}
                                                options={codEventCodes}
                                                getOptionLabel={option => option.id + " - " + option.name}
                                                value={codEvent}
                                                defaultValue={codEvent}
                                                noOptionsText={t("no_options")}
                                                onOpen={() => fetchCodEventCodes(cod.codModeId)}
                                                onChange={handleCodEventChange}
                                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("cod_event")}
                                                        variant="outlined"
                                                        multiline
                                                        required={true}
                                                        error={!codEvent}
                                                        margin="dense"
                                                        value={codEvent}
                                                        defaultValue={codEvent}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {/*<div style={{margin: "auto", width: "fit-content"}}>*/}
                                        {/*    {codEventVerified || shipmentState.shipment.cod.chequeForwardingId ?*/}
                                        {/*        <Chip style={{margin: "auto", width: "fit-content"}}*/}
                                        {/*              icon={<CheckRounded style={{color: "green"}}/>}*/}
                                        {/*              label={t("verified")}/>*/}
                                        {/*        :*/}
                                        {/*        <FormControlLabel*/}
                                        {/*            label={<Typography fontSize={12}*/}
                                        {/*                               variant={"button"}>{t("verified")}</Typography>}*/}
                                        {/*            control={*/}
                                        {/*                <Checkbox*/}
                                        {/*                    disabled={codEventVerified}*/}
                                        {/*                    color={"primary"}*/}
                                        {/*                    checked={shipmentState?.verified}*/}
                                        {/*                    onChange={onVerifiedStatusChange(shipmentState, index)}*/}
                                        {/*                />*/}
                                        {/*            }*/}
                                        {/*            labelPlacement={"right"}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                        {codEvent?.id === 3 || codEvent?.id === 4 || codEvent?.id === 5 || codEvent?.id === 6 ?
                                            <div style={{display: "block", marginTop: "5%"}}>
                                                <div style={{display: "block"}}>
                                                    <TextField
                                                        style={{
                                                            width: "20%",
                                                            margin: "1%"
                                                        }}
                                                        label={t("bank_name")}
                                                        variant="outlined"
                                                        type="text"
                                                        margin="dense"
                                                        size={"small"}
                                                        disabled={cod?.chequeForwardingId}
                                                        onChange={handleBankNameChange}
                                                        value={bankName}
                                                    />
                                                    <TextField
                                                        style={{
                                                            width: "20%",
                                                            margin: "1%"
                                                        }}
                                                        label={t("cheque_number")}
                                                        variant="outlined"
                                                        type="text"
                                                        margin="dense"
                                                        size={"small"}
                                                        disabled={cod?.chequeForwardingId}
                                                        onChange={handleChequeNumberChange}
                                                        value={chequeNumber}
                                                    />
                                                </div>
                                                <div>
                                                    <DateField
                                                        style={{width: "20%", margin: "auto"}}
                                                        label={t("date")}
                                                        variant="outlined"
                                                        type="text"
                                                        margin="dense"
                                                        format={"dd/MM/yyyy"}
                                                        clearable={true}
                                                        disableFuture={true}
                                                        disabled={cod?.chequeForwardingId}
                                                        onChange={handleChequeDateChange}
                                                        value={chequeDate}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                    :
                                    null

                                }
                            </div>
                            :
                            null
                        }


                        <div style={{margin: "5%", paddingBottom: "2%"}}>
                            <LoadingButton
                                style={{color: theme.palette.primary.main}}
                                endIcon={<Check/>}
                                loading={loadingSubmit}
                                onClick={submitRelease}
                            >
                                {t("confirm")}
                            </LoadingButton>

                        </div>
                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{notificationMessage}</Notification>

                    </>
                }
            </Paper>
        }


    </>


}

export default WarehouseStorageReleasePage;