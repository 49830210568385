import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination
} from "@material-ui/core";
import {PrivateRoute} from "../../routes/PrivateRoute";
import LedgerAccountService from "./LedgerAccountService";
import LedgerAccount from "./LedgerAccount";
import {AddCircle} from "@material-ui/icons";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import LedgerAccountForm from "./LedgerAccountForm";


function LedgerAccountTable(props) {

    let {t} = useTranslation();
    let history = useHistory();
    let location = useLocation();
    const [ledgerAccounts, setLedgerAccounts] = useState([]);
    const [fetching, setFetching] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
            container: {
                width: '100%',
                maxHeight: 840,
            },
            table: {
                width: '80%',
                margin: "auto",
            },

        cell: {
            width: "27%"

        },
        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();

    function fetchLedgerAccounts(){
        let ledgerAccountService = new LedgerAccountService();
        ledgerAccountService.getLedgerAccounts()
            .then(data => {
                setLedgerAccounts(data);
                setFetching(false);
            })
    }
    function goToLedgerAccountForm() {
        history.push("/ledger-accounts/new")
    }

    useEffect(()=>{
        fetchLedgerAccounts();
    },[location])

    return <Paper className={classes.root}>

        {(fetching) ?
        <LoadingComponent/>
            :
            <Switch>
                <PrivateRoute exact path={"/ledger-accounts"}>

                    <Button startIcon={<AddCircle/>} onClick={goToLedgerAccountForm} variant="contained" color="primary"  className={classes.button}>
                        {t("create_new_ledger_account")}
                    </Button>

                    <TableContainer className={classes.container}>
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableCell>ID</TableCell>
                                <TableCell>{t("code")}</TableCell>
                                <TableCell>{t("name")}</TableCell>


                            </TableHead>
                            <TableBody>
                                {ledgerAccounts.slice(page*rowsPerPage, page* rowsPerPage + rowsPerPage).map((ledgerAccount, index) => {
                                    return <LedgerAccount ledgerAccount = {ledgerAccount}/>
                                })}
                            </TableBody>
                            {(ledgerAccounts.length>0) ?
                                <TablePagination
                                    rowsPerPageOptions={[10,25,100]}

                                    count={ledgerAccounts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t("rows_per_page")}
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' di ' + count
                                        }
                                    }
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                                : null}

                        </Table>

                    </TableContainer>

                </PrivateRoute>
                <PrivateRoute exact path={`/ledger-accounts/new`}>
                    <LedgerAccountForm ledgerAccount={null}/>
                </PrivateRoute>
            </Switch>
        }

    </Paper>






} export default LedgerAccountTable;