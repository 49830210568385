import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardActions,
    Checkbox,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {ArrowBack, ArrowBackOutlined, CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import SlotService from "./slot/SlotService";
import Notification from "../components/notifications/Notification";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {weekdays} from "moment";
import ServiceService from "../shipments/services/ServiceService";
import DistrictService from "./DistrictService";
import CountryService from "../geo/countries/CountryService";
import HamletService from "../geo/hamlets/HamletService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {IconButton} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        minWidth: 920,
        marginTop: "5%",
        paddingBottom: "2%"
    },
    name_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    div: {
        display: "inline"
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        marginLeft: "auto",
        marginRight: "auto",
        margin: theme.spacing(1),
        width: "75%",
    },
    chips: {
        display: 'block',
        // flexWrap: 'wrap',
    },
    chip: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "4%",
        marginInline: "4%",
        width: "25%",
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    select: {
        width: `50%`,
        marginTop: `1%`
    },
    typo: {
        marginTop: `3%`
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "4%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    create_button: {
        marginInline: "4%",
        width: "25%",
        backgroundColor: "whitesmoke"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));

function DistrictForm(props) {


    const {t} = useTranslation();
    const classes = useStyles();

    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();

    const {id} = useParams();


    let days = weekdays();

    let order = {Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7};

    const [slot, setSlot] = useState(null);

    const [newlyCreatedSlotId, setNewlyCreatedSlotId] = useState(null);

    const [code, setCode] = useState('');
    const [allAreas, setAllAreas] = useState([]);

    const [district, setDistrict] = useState({});

    const [maximumCapacityPerSlot, setMaximumCapacityPerSlot] = useState('');

    const regexp = /^[0-9]*$|^NULL$/

    const [allSlots, setAllSlots] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [newAreaShow, setNewAreaShow] = useState(false);
    const [newSlotShow, setNewSlotShow] = useState(false);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [newSlotFromTime, setNewSlotFromTime] = useState(null);
    const [newSlotToTime, setNewSlotToTime] = useState(null);
    const [addSlotShow, setAddSlotShow] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [hamlets, setHamlets] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [services, setServices] = useState([]);

    const [adminL3, setAdminL3] = useState(null);
    const [adminL2, setAdminL2] = useState(null);
    const [adminL1, setAdminL1] = useState(null);
    const [city, setCity] = useState(null);
    const [selectedPostalCodes, setSelectedPostalCodes] = useState([]);
    const [hamlet, setHamlet] = useState(null);
    const [service, setService] = useState(null);
    const [country, setCountry] = useState(null);
    const [slotDays, setSlotDays] = useState([]);
    const [loading, setLoading] = useState(false);

    async function fetchDistrict() {

        setLoading(true);

        if (id) {
            let districtService = new DistrictService();
            await districtService.getById(id)
                .then(response => {
                    setDistrict(response);
                    setSelectedSlots(response.slots);
                    setSelectedAreas(response.routingRules);
                    setCode(response.code);
                    setMaximumCapacityPerSlot(response.maximumCapacityPerSlot);
                })
        }

        setLoading(false);
    }


    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function validateSlotSelect(slot, slotList) {
        if (slotList.some(value => value.id === slot.id)) {
            return false;
        }
        let n = slot.weekDays.length;
        for (let i = 0; i < n; i++) {
            let auxSlot;
            slotList.filter(value => value.weekDays.map(day => {
                if (day === slot.weekDays[i]) {
                    auxSlot = value;
                }
            }));
            if (auxSlot != null) {
                if (slot.fromTime < auxSlot.toTime && slot.fromTime > auxSlot.fromTime) {
                    return false;
                }
                if (slot.toTime > auxSlot.fromTime && slot.toTime < auxSlot.toTime) {
                    return false;
                }
                if (slot.fromTime === auxSlot.fromTime) {
                    return false;
                }
                if (slot.toTime === auxSlot.toTime) {
                    return false;
                }
                if (slot.toTime > auxSlot.toTime && slot.fromTime < auxSlot.fromTime) {
                    return false;
                }
            }
        }

        return true;
    }

    function addToSelectedSlots(event) {
        let slot = event.target.value;

        let array = selectedSlots;

        if (validateSlotSelect(slot, array)) {
            array.push(slot)
        } else {
            setNotificationMessage(t("invalid_selection"));
            setNotifySeverity("error")
            setOpenNotify(true);
        }
        // if (!array.some(value => value.id === slot.id)) {
        //     array.push(slot);
        // }
        setSelectedSlots(array);
        setRefresh(!refresh);
    }

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }


    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();

        let filters = {}

        filters.countryId = country.id;

        adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdmin1List(data.admins1);
            })
    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        if (adminL1) {
            let filters = {}
            filters.adminL1 = adminL1.id;
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        } else {
            adminL2Service.getAllAdminLevel2WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        }
    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();
        if (adminL2) {
            let filters = {};
            filters.adminL2 = adminL2.id;
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        } else {
            adminL3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        }
    }

    function fetchCities() {
        let cityService = new CityService();

        if (adminL3) {
            let filters = {}
            filters.level3Id = adminL3.id;
            cityService.getCities(null, null, filters, null)
                .then(data => {
                    setCities(data.cities)
                })
        } else {
            cityService.getCities(null, null, null, null)
                .then(data => {
                    setCities(data.cities)
                })
        }
    }

    function fetchCAP() {
        let postalCodeService = new PostalCodeService();

        if (city) {
            let filters = {}
            filters.cityId = city.id;
            postalCodeService.getAllPostalCodes(null, null, filters, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        } else {
            postalCodeService.getAllPostalCodes(null, null, null, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        }
    }

    function fetchHamlets() {
        let hamletService = new HamletService();

        if (city) {
            let filters = {}
            filters.cityId = city.id;

            hamletService.getHamlets(null, null, filters)
                .then(data => {
                    setHamlets(data.hamlets);
                })

        } else {
            hamletService.getHamlets()
                .then(data => {
                    setHamlets(data.hamlets);
                })
        }
    }

    function handleCheckboxChange(event) {
        let newArray = slotDays;
        if (newArray.includes(event.target.name)) {
            newArray = newArray.filter(day => day !== event.target.name);
        } else {
            newArray.push(event.target.name);
        }

        setSlotDays(newArray);
    }

    function saveTempRoutingRule(_continue) {

        if(selectedPostalCodes.length > 0){
            for(let i = 0; i < selectedPostalCodes.length; i++){

                let newArea = {};

                if (null != service) {
                    newArea.service = service.name;
                    newArea.serviceId = service.id;
                }
                if (null != country) {
                    newArea.country = country.name;
                    newArea.countryId = country.id;
                }
                if (null != adminL1) {
                    newArea.administrativeLevel1 = adminL1.name;
                    newArea.administrativeLevel1Id = adminL1.id;
                }
                if (null != adminL2) {
                    newArea.administrativeLevel2 = adminL2.name;
                    newArea.administrativeLevel2Id = adminL2.id;
                }
                if (null != adminL3) {
                    newArea.administrativeLevel3 = adminL3.name;
                    newArea.administrativeLevel3Id = adminL3.id;
                }
                if (null != city) {
                    newArea.city = city.name;
                    newArea.cityId = city.id;
                }
                if (selectedPostalCodes.length > 0) {
                    newArea.postalCode = selectedPostalCodes[i].postalCode;
                    newArea.postalCodeId = selectedPostalCodes[i].id;
                }
                if (null != hamlet) {
                    newArea.hamlet = hamlet.name;
                    newArea.hamletId = hamlet.id;
                }

                if (newArea.service == null) {
                    setNotificationMessage(t("service_is_mandatory"));
                    setNotifySeverity("error")
                    setOpenNotify(true);
                } else if (checkRoutingRuleAlreadySelected(selectedAreas, newArea)) {
                    setNotificationMessage(t("area_already_exists"));
                    setNotifySeverity("error")
                    setOpenNotify(true);
                } else {
                    selectedAreas.push(newArea);
                    if (_continue) {
                        setNotificationMessage(t("area_successfully_added"));
                        setNotifySeverity("success")
                        setOpenNotify(true);
                    } else {
                        setNewAreaShow(false);
                    }


                    setRefresh(!refresh);
                }

            }
        } else {
            let newArea = {};

            if (null != service) {
                newArea.service = service.name;
                newArea.serviceId = service.id;
            }
            if (null != country) {
                newArea.country = country.name;
                newArea.countryId = country.id;
            }
            if (null != adminL1) {
                newArea.administrativeLevel1 = adminL1.name;
                newArea.administrativeLevel1Id = adminL1.id;
            }
            if (null != adminL2) {
                newArea.administrativeLevel2 = adminL2.name;
                newArea.administrativeLevel2Id = adminL2.id;
            }
            if (null != adminL3) {
                newArea.administrativeLevel3 = adminL3.name;
                newArea.administrativeLevel3Id = adminL3.id;
            }
            if (null != city) {
                newArea.city = city.name;
                newArea.cityId = city.id;
            }

            if (newArea.service == null) {
                setNotificationMessage(t("service_is_mandatory"));
                setNotifySeverity("error")
                setOpenNotify(true);
            } else if (checkRoutingRuleAlreadySelected(selectedAreas, newArea)) {
                setNotificationMessage(t("area_already_exists"));
                setNotifySeverity("error")
                setOpenNotify(true);
            } else {
                selectedAreas.push(newArea);
                if (_continue) {
                    setNotificationMessage(t("area_successfully_added"));
                    setNotifySeverity("success")
                    setOpenNotify(true);
                } else {
                    setNewAreaShow(false);
                }


                setRefresh(!refresh);
            }
        }






    }

    function minLength(obj1, obj2) {
        if (obj1.length < obj2.length) {
            return obj1;
        } else {
            return obj2;
        }
    }

    //confronto oggetti. Gli oggetti devono avere gli stessi attributi, altrimenti risultano non uguali
    function deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        const minKeys = minLength(keys1, keys2);

        for (const key of minKeys) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (
                (areObjects && !deepEqual(val1, val2)) ||
                (!areObjects && val1.toString().toLowerCase() !== val2.toString().toLowerCase())
            ) {
                return false;
            }
        }
        return true;
    }

    function isObject(object) {
        return object != null && typeof object === 'object';
    }


    function checkRoutingRuleAlreadySelected(selectedRoutingRules, newRoutingRule) {

        for (let i = 0; i < selectedRoutingRules.length; i++) {
            let compareRoutingRule = selectedRoutingRules[i];

            if (deepEqual(compareRoutingRule, newRoutingRule)) {
                return true;
            }

            if (compareRoutingRule.service.id === newRoutingRule.service.id &&
                compareRoutingRule.country.id === newRoutingRule.country.id
                && newRoutingRule.postalCodes?.length > 0
            ) {
                for (let j = 0; j < newRoutingRule.postalCodes.length; j++) {
                    if (newRoutingRule.postalCodes[j].id === compareRoutingRule.postalCode.id) {
                        return true;
                    }
                }
            }

        }

        return false;

    }

    function clearRoutingRuleForm() {
        handleServiceChange(null, null);
        handleCountryChange(null, null);
    }

    function handleMaxCapacityChange(event) {

        if (regexp.test(event.target.value)) {
            setMaximumCapacityPerSlot(event.target.value);
        }

    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setAdminL2(null);
        setAdminL1(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL1Change(event, adminL1) {

        setAdminL1(adminL1);
        setAdminL2(null)
        setAdminL3(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2(adminL2);
        setAdminL3(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setHamlet(null);
        setSelectedPostalCodes([]);


    }

    function handleCityChange(event, city) {
        setCity(city);
        setSelectedPostalCodes([]);
        setHamlet(null);
    }

    function handleHamletChange(event, hamlet) {
        setHamlet(hamlet);
    }

    function handlePostalCodeChange(event, postalCode) {
        setSelectedPostalCodes(postalCode);
        setHamlet(null);


    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function changeFromTime(event, value) {
        setNewSlotFromTime(value);
    }

    function changeToTime(event, value) {
        setNewSlotToTime(value);
    }

    function cancelNewArea() {
        setNewAreaShow(false);
    }

    function cancelNewSlot() {
        setNewSlotFromTime(null);
        setNewSlotToTime(null);
        setSlotDays([]);
        setNewSlotShow(false);
    }

    function submitNewSlot(event) {
        event.preventDefault();

        slotDays.sort(function (a, b) {
            return order[a] - order[b];
        });

        let slotService = new SlotService();
        slotService.addSlot(newSlotFromTime, newSlotToTime, slotDays)
            .then(() => {
                setNotifySeverity('success')
                setNotificationMessage(t("successful"))
                setOpenNotify(true)
                setNewSlotToTime(null)
                setNewSlotFromTime(null)
                setSlotDays([]);
                setNewSlotShow(false);
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })
    }

    function getStyles(area, areas, theme) {
        return {
            fontWeight:
                areas.indexOf(area) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    function fetchSlots() {
        let slotService = new SlotService();
        slotService.getSlotsByFilters()
            .then(data => {
                setAllSlots(data.slots);
            })
    }

    function removeArea(area) {
        let array = selectedAreas;
        let index = array.indexOf(area)
        if (index !== -1) {
            array.splice(index, 1);
            setSelectedAreas(array);
        }
        setRefresh(!refresh)
    }

    function removeSlot(slot) {
        let array = selectedSlots;
        let index = array.indexOf(slot);
        if (index !== -1) {
            array.splice(index, 1);
            setSelectedSlots(array);
        }
        setRefresh(!refresh);
    }

    useEffect(() => {
        fetchDistrict();
    }, []);


    useEffect(() => {
        fetchSlots();

    }, [location, refresh])

    function cancelNewDistrict() {
        history.push("/districts")
    }

    function createAreasInput(areas) {
        let newAreas = [];

        areas.map((area) => {
            let newArea = {};
            newArea.serviceId = null;
            if (null != area.serviceId) {
                newArea.serviceId = area.serviceId;
            }
            newArea.countryId = null;
            if (null != area.countryId) {
                newArea.countryId = area.countryId;
            }
            newArea.administrativeLevel1Id = null;
            if (null != area.administrativeLevel1Id) {
                newArea.administrativeLevel1Id = area.administrativeLevel1Id;
            }
            newArea.administrativeLevel2Id = null;
            if (null != area.administrativeLevel2Id) {
                newArea.administrativeLevel2Id = area.administrativeLevel2Id;
            }
            newArea.administrativeLevel3Id = null;
            if (null != area.administrativeLevel3Id) {
                newArea.administrativeLevel3Id = area.administrativeLevel3Id;
            }
            newArea.cityId = null;
            if (null != area.cityId) {
                newArea.cityId = area.cityId;
            }
            // newArea.postalCodes = [];
            // if (area.postalCodes?.length > 0) {
            //     newArea.postalCodes = area.postalCodes.map(postalCode => postalCode.id);
            if (null != area.postalCodeId) {
                newArea.postalCodeId = area.postalCodeId;
            }
            newArea.hamletId = null;
            if (null != area.hamletId) {
                newArea.hamletId = area.hamletId;
            }

            newAreas.push(newArea);
        })

        return newAreas;
    }

    function createSlotsInput(slots) {
        let newSlots = [];

        slots.map((slot) => {
            let slotId = slot.id;
            newSlots.push(slotId);
        })

        return newSlots;
    }

    function formatArea(area) {

        let obj = {};

        obj.service = t("service") + ": " + area.service?.code;


        if (area.hamlet) {
            obj.area = t("hamlet") + ": " + area.hamlet.name;
        } else if (area.postalCode) {
            obj.area = t("postalCode") + ": " + area.postalCode.postalCode;
        } else if (area.postalCodes?.length > 0) {
            obj.area = t("postalCode") + ": ";
            for (let i = 0; i < area.postalCodes.length; i++) {
                if (i > 0) {
                    obj.area += ", ";
                }
                obj.area += area.postalCodes[i]?.postalCode;
            }

        } else if (area.city) {
            obj.area = t("city") + ": " + area.city.name;
        } else if (area.administrativeLevel3) {
            obj.area = t("all_admin_3") + ": " + area.administrativeLevel3.name;
        } else if (area.administrativeLevel2) {
            obj.area = t("all_admin_2") + ": " + area.administrativeLevel2.name;
        } else if (area.administrativeLevel1) {
            obj.area = t("all_admin_1") + ": " + area.administrativeLevel1.name;
        } else if (area.country) {
            obj.area = t("all_country") + ": " + area.country.name;
        } else {
            obj.area = t("all_world");
        }

        return obj;
    }

    function submitNewDistrict(event) {
        event.preventDefault();

        let areas = createAreasInput(selectedAreas);
        let slots = createSlotsInput(selectedSlots);

        let districtService = new DistrictService();

        if (maximumCapacityPerSlot === '') {
            setMaximumCapacityPerSlot(null);
        }

        if (id) {
            districtService.editDistrict(id, code, maximumCapacityPerSlot, areas, slots)
                .then(() => {
                    setNotifySeverity('success')
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true)
                    setTimeout(() => {
                        history.push("/districts")
                    }, 2000)

                })
                .catch((error) => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });

                })
        } else {
            districtService.addDistrict(code, maximumCapacityPerSlot, areas, slots)
                .then(() => {
                    setNotifySeverity('success')
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true)
                    setTimeout(() => {
                        history.push("/districts")
                    }, 2000)

                })
                .catch((error) => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });

                })
        }


    }

    return <form className={classes.root}>
        {loading ?

            <LoadingComponent/>

            :

            <div>
                <DialogTitle>{t("district_data")}</DialogTitle>

                <TextField
                    required
                    label={t("code")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={event => setCode(event.target.value)}
                    value={code}
                    className={classes.name_field}
                />

                <TextField
                    label={t("maximum_capacity_per_slot")}
                    variant="outlined"
                    margin="dense"
                    onChange={handleMaxCapacityChange}
                    InputProps={{
                        inputProps: {
                            min: 0
                        }
                    }}
                    value={maximumCapacityPerSlot}
                    className={classes.name_field}
                    helperText={t("leave_blank_for_infinite_capacity")}
                />
                <div>
                    <Paper className={classes.paper} elevation={3}>
                        <DialogTitle>{`Aree Selezionate`}</DialogTitle>
                        <div>
                            {selectedAreas?.length > 0 ?
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableCell>{t("service")}</TableCell>
                                        <TableCell>{t("country")}</TableCell>
                                        <TableCell>{t("administrative1")}</TableCell>
                                        <TableCell>{t("administrative2")}</TableCell>
                                        <TableCell>{t("administrative3")}</TableCell>
                                        <TableCell>{t("city")}</TableCell>
                                        <TableCell>{t("postalCode")}</TableCell>
                                        <TableCell/>

                                    </TableHead>

                                    <TableBody>
                                        {selectedAreas.map((area, index) => {
                                            return <TableRow>
                                                <TableCell>
                                                    {area.service || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.country || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.administrativeLevel1 || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.administrativeLevel2 || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.administrativeLevel3 || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.city || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    {area.postalCode || `*`}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color={"primary"} onClick={() => removeArea(area)}><CancelOutlined/></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        })}


                                    </TableBody>
                                </Table>
                                :
                                null
                            }
                        </div>
                    </Paper>

                    {newAreaShow || addSlotShow ? null : <Button
                        variant={"contained"}
                        className={classes.button}
                        color={"primary"}
                        endIcon={<AddCircleIcon/>}
                        onClick={() => setNewAreaShow(!newAreaShow)}>

                        {t("add_new_area")}

                    </Button>}


                    {newAreaShow ?
                        <Card className={classes.formControl}>
                            <DialogTitle>{t("new_area")}</DialogTitle>

                            <Autocomplete
                                id="service"

                                className={classes.field}
                                options={services}
                                getOptionLabel={option => option.name}
                                value={service}
                                defaultValue={service}
                                onOpen={fetchServices}
                                onChange={handleServiceChange}
                                loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("service")}
                                        variant="outlined"
                                        margin="dense"
                                        value={service}
                                        defaultValue={service}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="country"
                                className={classes.field}
                                options={countries}
                                classes={{
                                    option: classes.option,
                                }}
                                getOptionLabel={(option) => option.name}
                                value={country}
                                defaultValue={country}
                                onOpen={fetchCountries}
                                onChange={handleCountryChange}
                                onBlur={fetchAdminL1}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <span>{countryToFlag(option.iso3166Alpha2)}</span>
                                        {option.name} ({option.iso3166Alpha3}) +{option.iso3166Numeric}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("country")}
                                        variant="outlined"
                                        margin="dense"
                                        value={option => option.iso3166Alpha3}
                                        defaultValue={country}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="administrative1"
                                disabled={!country}
                                className={classes.field}
                                options={admin1List}
                                getOptionLabel={option => option.name}
                                value={adminL1}
                                defaultValue={adminL1}
                                onOpen={fetchAdminL1}
                                onChange={handleAdminL1Change}
                                loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("administrative1")}
                                        variant="outlined"
                                        margin="dense"
                                        value={adminL1}
                                        defaultValue={adminL1}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="administrative2"
                                disabled={!adminL1}
                                className={classes.field}
                                options={admin2List}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={adminL2}
                                onOpen={fetchAdminL2}
                                onChange={handleAdminL2Change}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative2")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={adminL2}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="administrative3"
                                disabled={!adminL2}
                                className={classes.field}
                                options={admin3List}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={adminL3}
                                onOpen={fetchAdminL3}
                                onChange={handleAdminL3Change}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("administrative3")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={adminL3}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="city"
                                disabled={!adminL3}
                                className={classes.field}
                                options={cities}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={city}
                                onOpen={fetchCities}
                                onChange={handleCityChange}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("city")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={city}
                                    />
                                )}
                            />
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={postalCodes}
                                style={{width: "50%", margin: "auto"}}
                                disabled={!city}
                                disableCloseOnSelect={true}
                                getOptionLabel={(option) => option.postalCode}
                                value={selectedPostalCodes}
                                onChange={handlePostalCodeChange}
                                onOpen={fetchCAP}
                                autoHighlight
                                isOptionEqualToValue={(option, value) => option.postalCode === value.postalCode}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t("postalCodes")}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="hamlet"
                                disabled={!city}
                                className={classes.field}
                                options={hamlets}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                value={hamlet}
                                onOpen={fetchHamlets}
                                onChange={handleHamletChange}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t("hamlet")}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={hamlet}
                                    />
                                )}
                            />
                            <CardActions className={classes.button_container}>
                                <Button
                                    className={classes.cancel_button}
                                    startIcon={<ArrowBackOutlined/>}
                                    onClick={cancelNewArea}
                                    variant={"contained"}>
                                    {t("back")}
                                </Button>
                                <Button
                                    className={classes.confirm_button}
                                    endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                    onClick={() => saveTempRoutingRule(true)}
                                    color="primary"
                                    variant={"contained"}>
                                    {t("add_area_and_continue")}
                                </Button>
                                <Button
                                    className={classes.confirm_button}
                                    endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                    onClick={() => saveTempRoutingRule(false)}
                                    color="primary"
                                    variant={"contained"}>
                                    {t("add_area")}
                                </Button>
                            </CardActions>
                        </Card>
                        :
                        null
                    }<Paper className={classes.paper} elevation={3}>
                    <DialogTitle>{`Slots Selezionati`}</DialogTitle>
                    {selectedSlots.length !== 0 ?
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableCell>{t("fromTime")}</TableCell>
                                <TableCell>{t("toTime")}</TableCell>
                                <TableCell>{t("days")}</TableCell>
                                <TableCell/>
                            </TableHead>
                            <TableBody>
                                {selectedSlots.map((slot, index) => {
                                    return <TableRow>
                                        <TableCell>{slot.fromTime}</TableCell>
                                        <TableCell>{slot.toTime}</TableCell>
                                        <TableCell>{slot.weekDays.map(day => {
                                            return t(`${day.toLowerCase()}`) + " "
                                        })
                                        }</TableCell>
                                        <TableCell>
                                            <Button color={"primary"} endIcon={<CancelOutlined/>}
                                                    onClick={() => removeSlot(slot)}/>
                                        </TableCell>

                                    </TableRow>
                                })}
                            </TableBody>
                        </Table> :
                        null
                    }
                    {/*<ActiveSlotsTable slots={selectedSlots}/>*/}
                    {/*TODO*/}

                </Paper>
                    {addSlotShow || newAreaShow ? null :
                        <div>
                            <Button
                                variant={"contained"}
                                className={classes.button}
                                color={"primary"}
                                endIcon={<AddCircleIcon/>}
                                onClick={() => setAddSlotShow(!addSlotShow)}>

                                {t("add_slot")}

                            </Button>
                        </div>
                    }


                    {newAreaShow || !addSlotShow ? null : <div style={{marginTop: "5%"}}>

                        <Typography variant={"caption"}>{t("select_a_slot")}</Typography>
                        <div>
                            <Select
                                className={classes.select}
                                variant={"outlined"}
                                value={slot}
                                onChange={addToSelectedSlots}
                            >
                                {allSlots.map((slot, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={slot}
                                            style={getStyles(slot, allSlots, theme)}>
                                            {slot.fromTime + " - " + slot.toTime + " - [ " + slot.weekDays.map(day => {
                                                return " " + t(`${day.toLowerCase()}`) + " "
                                            }) + " ] "}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <div className={classes.button_container}>
                                <Button className={classes.cancel_button} startIcon={<ArrowBack/>} color={"secondary"}
                                        onClick={() => setAddSlotShow(false)}>
                                    {t("back")}
                                </Button>
                                <Button className={classes.create_button} startIcon={<AddCircleIcon/>} color={"primary"}
                                        onClick={() => setNewSlotShow(true)}>
                                    {t("create_new_slot")}
                                </Button>
                            </div>

                        </div>

                        {newSlotShow ?
                            <Card className={classes.formControl}>
                                <DialogTitle>{t("new_slot")}</DialogTitle>
                                <div>
                                    <FormControl required error={null} component="fieldset">

                                        <FormGroup row>
                                            {days.map((day, index) => {

                                                return (

                                                    <div className={classes.div}>
                                                        <FormControlLabel key={index}
                                                                          labelPlacement="bottom"
                                                                          control={<Checkbox color="primary"
                                                                                             onChange={handleCheckboxChange}
                                                                                             name={day}/>}
                                                                          label={t(`${day}`)}
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </FormGroup>
                                        <FormHelperText/>
                                    </FormControl>
                                </div>
                                <CurrencyTextField
                                    label={t("fromTime")}
                                    variant="outlined"
                                    outputFormat="number"
                                    margin="dense"
                                    minimumValue={0}
                                    maximumValue={23}
                                    currencySymbol={''}
                                    onChange={changeFromTime}
                                    value={newSlotFromTime}
                                    decimalPlaces={0}
                                    className={classes.field}
                                />
                                <CurrencyTextField
                                    label={t("toTime")}
                                    variant="outlined"
                                    outputFormat="number"
                                    margin="dense"
                                    minimumValue={1}
                                    maximumValue={24}
                                    currencySymbol={''}
                                    onChange={changeToTime}
                                    value={newSlotToTime}
                                    decimalPlaces={0}
                                    className={classes.field}

                                />
                                <CardActions className={classes.button_container}>
                                    <Button
                                        className={classes.cancel_button}
                                        endIcon={<CancelOutlined/>}
                                        onClick={cancelNewSlot}
                                        variant={"contained"}>
                                        {t("cancel")}
                                    </Button>
                                    <Button
                                        className={classes.confirm_button}
                                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                        onClick={submitNewSlot}
                                        color="primary"
                                        variant={"contained"}>
                                        {t("add_slot")}
                                    </Button>
                                </CardActions>
                            </Card>
                            :
                            null
                        }
                    </div>
                    }

                    {newAreaShow || addSlotShow ? null : <CardActions className={classes.button_container}>
                        <Button
                            className={classes.cancel_button}
                            endIcon={<CancelOutlined/>}
                            onClick={cancelNewDistrict}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className={classes.confirm_button}
                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                            onClick={submitNewDistrict}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </CardActions>}
                </div>


                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>
            </div>

        }
    </form>


} export default DistrictForm;