import React, {useEffect, useState} from "react";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../routes/PrivateRoute";
import PickupForm from "./PickupForm";
import {
    Card,
    FormControlLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme
} from "@material-ui/core";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Pickup from "./Pickup";
import PickupService from "./PickupService";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import PickupDetails from "./PickupDetails";
import {Autocomplete} from "@material-ui/lab";
import moment from "moment";
import WarehouseService from "../warehouses/WarehouseService";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import DateField from "../components/fields/DateField";
import InfoChip from "../components/chips/InfoChip";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    },

}))


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        margin: "auto",
        marginTop: "3%",
        marginBottom: "3%"
    },

}));


function PickupsTable(props){

    const history = useHistory();
    const {t} = useTranslation();
    const location = useLocation();

    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [pickupId, setPickupId] = useState('');
    const [shipmentId, setShipmentId] = useState('');
    const [warehouse, setWarehouse] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [assigned, setAssigned] = useState("null");
    const [pickedUp, setPickedUp] = useState("null");
    const [date, setDate] = useState(null);

    const [warehouses, setWarehouses] = useState([])
    const [contracts, setContracts] = useState([])
    const [accounts, setAccounts] = useState([])

    const [count, setCount] = useState(0);

    const [fetching, setFetching] = useState(true);
    const [pickups, setPickups] = useState([]);


    function handleChangePage (event) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage (event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function goToDetails(id) {
        history.push(`/pickups/${id}/details`)

        // let url = `/pickups/${id}/details`;
        //
        // window.open(url, "_blank")
    }

    function handlePickupIdChange(event){
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setPickupId(event.target.value);
        }
    }
    function handleShipmentIdChange(event){
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setShipmentId(event.target.value);
        }
    }

    function handleWarehouseChange(event, warehouse){
        setWarehouse(warehouse);
    }
    function handleContractChange(event, contract){
        setContract(contract);
    }
    function handleAccountChange(event, account){
        setAccount(account);
        if(null === account){
            setContract(null);
        }
    }

    function handleAssignedChange(event){
        setAssigned(event.target.value);
    }

    function handlePickedUpChange(event){
        setPickedUp(event.target.value);
    }

    function handleDateChange(event){
        setDate(event)
    }


    function fetchPickups(){

        setFetching(true);

        let filters = {};

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        if (null !== account) {
            filters.accountId = account.id;
        }
        if (null !== contract) {
            filters.contractId = contract.id;
        }

        if(null !== warehouse) {
            filters.warehouseCode = warehouse.code
        }

        if(null !== date) {
            filters.date = moment(date).format('yyyy-MM-DD');
        }

        if(pickupId !== ''){
            filters.pickupId = pickupId
        }

        if(shipmentId !== ''){
            filters.shipmentId = shipmentId
        }
        switch (assigned) {
            case "true" :
                filters.isAssignedToDriver = true
                break;
            case "false":
                filters.isAssignedToDriver = false
                break;
            default:
        }
        switch (pickedUp) {
            case "true" :
                filters.isPickedUp = true
                break;
            case "false":
                filters.isPickedUp = false
                break;
            default:
        }

        const pickupService = new PickupService();
        pickupService.getPickupsByFilters(filters)
            .then(data => {
                setPickups(data.pickups);
                setFetching(false);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setCount(data.count);
            })
            .catch(error => {
                setFetching(false);
            })

    }

    async function fetchWarehouses() {

        let wares = await new WarehouseService().getWarehousesByFilters(0, 1000, null)
            .catch(error => {

            })

        setWarehouses(wares.warehouses);
    }

    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts()
            .then(data => {
                setAccounts(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContracts(response);
            })

    }

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);

    }

    function clearFilters() {

        setAccount(null);
        setContract(null);
        setAssigned("null");
        setPickedUp("null");
        setPickupId('')
        setShipmentId('')
        setDate(null);
        setWarehouse(null);
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }



    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function goToPickupForm(){
        history.push("/pickups/new")
    }


    useEffect(()=>{

        fetchPickups();

    }, [rowsPerPage, page, location, refresh])


    return <div>
        <Switch>
            <PrivateRoute exact path={"/pickups"}>
                <Card elevation={1} defaultExpanded={true}
                      style={{width: "60%", margin: "auto", marginBlock: "2%"}}>
                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                        <Typography variant={"button"}>{t("filters")}</Typography>
                    </DialogTitle>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <TextField
                            className={classes.filter}
                            style={{marginBottom: "3%"}}
                            label={t("pickupId")}
                            value={pickupId}
                            onChange={handlePickupIdChange}
                            margin={"dense"}
                            variant={"outlined"}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <TextField
                            className={classes.filter}
                            label={t("shipmentId")}
                            value={shipmentId}
                            onChange={handleShipmentIdChange}
                            margin={"dense"}
                            variant={"outlined"}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <DateField
                            style={{margin: "auto", width: "30%", marginBottom: "2%"}}
                            label={t("date")}
                            value={date}
                            onChange={handleDateChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={false}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="warehouse"
                            options={warehouses}
                            className={classes.filter}
                            getOptionLabel={option => option.code}
                            value={warehouse}
                            defaultValue={warehouse}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchWarehouses}
                            onChange={handleWarehouseChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="account"
                            options={accounts}
                            className={classes.filter}
                            getOptionLabel={option => option.companyName}
                            value={account}
                            defaultValue={account}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchAccounts}
                            onChange={handleAccountChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("account")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={account}
                                    defaultValue={account}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="contract"
                            disabled={!account}
                            options={contracts}
                            className={classes.filter}
                            getOptionLabel={option => option.code}
                            value={contract}
                            defaultValue={contract}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchContracts}
                            onChange={handleContractChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("contract")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <TableRow style={{display: "flex"}}>
                        {/*<FormControl style={{*/}
                        {/*    margin: "auto",*/}
                        {/*    marginBottom: "2%",*/}
                        {/*    width: "100%",*/}
                        {/*}}>*/}
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("is_assigned_to_driver")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={assigned}
                                onChange={handleAssignedChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>


                        {/*</FormControl>*/}
                    </TableRow>
                    <TableRow style={{ display: "flex"}}>
                        {/*<FormControl style={{*/}
                        {/*    margin: "auto",*/}
                        {/*    marginBottom: "2%",*/}
                        {/*    width: "100%",*/}
                        {/*}}>*/}
                        <TableCell style={{borderBottom: "none", margin: "auto", width: "50%"}}>
                            <Typography disabled={true}
                                        style={{color: "black"}}>{t("is_picked_up")}</Typography>
                        </TableCell>

                        <TableCell style={{borderBottom: "none"}}>
                            <RadioGroup
                                row
                                value={pickedUp}
                                onChange={handlePickedUpChange}
                                style={{margin: "auto"}}
                            >
                                <FormControlLabel value={"true"} control={<Radio color={"primary"}/>}
                                                  label={t("yes")}/>
                                <FormControlLabel value={"false"} control={<Radio color={"primary"}/>}
                                                  label={t("no")}/>
                                <FormControlLabel value={"null"} control={<Radio color={"primary"}/>}
                                                  label={t("any")}/>
                            </RadioGroup>
                        </TableCell>


                        {/*</FormControl>*/}
                    </TableRow>
                    <div style={{textAlign: 'center'}}>
                        <Button style={{margin: "5%"}}
                                onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button style={{margin: "5%"}}
                                onClick={applyFilters}>{t("apply_filter")}</Button>
                    </div>
                </Card>
                <div style={{display: "flex"}}>
                    <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToPickupForm}
                            className={classes.button}>
                        {t("create_new_pickup")}
                    </Button>
                </div>
                <div>
                    <div style={{width: "100%", marginBottom: "-5%"}}>
                        <InfoChip><Typography variant={"subtitle2"}>{t("shipment_count")} {count}</Typography></InfoChip>
                    </div>
                </div>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>

                        {(fetching) ?
                            <Table>
                                <LoadingComponent/>
                            </Table>

                            :
                            <>

                                {pickups.length > 0 ?
                                    <div>

                                        <Table stickyHeader aria-label="sticky table">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("pickup_id")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("contract")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("address")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("manifest_datetime")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("pickup_date")} </Typography>
                                                        <Typography
                                                            variant={"inherit"}>  {t("pickup_time_slot")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("warehouse")} </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant={"inherit"}>  {t("assign_driver")} </Typography>
                                                    </TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pickups.map((pickup, index) => {
                                                return <Pickup key={index} pickup={pickup} warehouses={warehouses} goToDetails={goToDetails} refreshCallback={()=>setRefresh(!refresh)}/>
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={-1}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            // SelectProps={{
                                            //     inputProps: {'aria-label': 'rows per page'},
                                            //     variant: "outlined"
                                            //
                                            // }}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                variant: "outlined",
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                classes: {paper: classes.menuPaper}
                                            }}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return t("page")
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>

                                    :

                                    // <div style={{height: 100}}>
                                    //     <Typography style={{marginTop: "5%"}}>{t("no_shipments_found")}</Typography>
                                    // </div>
                                    null
                                }

                            </>
                        }
                    </TableContainer>


                </Paper>
            </PrivateRoute>
            <PrivateRoute exact path={"/pickups/new"}>
                <PickupForm/>
            </PrivateRoute>
            <PrivateRoute exact path={"/pickups/:id/details"}>
                <PickupDetails/>
            </PrivateRoute>
        </Switch>

    </div>


} export default PickupsTable;