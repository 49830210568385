import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

export default class UserService {
    isLoggedIn() {
        const apiKey = localStorage.getItem("apiKey") || null;
        return apiKey ? true : false;
    }

    getApiKey() {
        return localStorage.getItem("apiKey");
    }

    getUserById(userId){
        let apiKey = this.getApiKey();

        let requestOptions = {
            method : "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/users/${userId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    deleteUser(){
        let apiKey = this.getApiKey();

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch('', requestOptions)
            .then(checkStatus)
    }

    createUser(username, password, enabled, roles, contracts, warehouses) {
        let apiKey = this.getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                username:username,
                password:password,
                enabled:enabled,
                roles:roles,
                contracts: contracts,
                warehouses: warehouses
            })
        };

            return fetch("/api/v1/users", requestOptions)
                .then(checkStatus)
                .then(response => response.json())
    }

    editUser(userId, username, password, enabled, roles, contracts, warehouses) {

        let apiKey = this.getApiKey();


        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                username:username,
                password:password,
                enabled:enabled,
                roles:roles,
                contracts: contracts,
                warehouses: warehouses
            })
        };

        return fetch(`/api/v1/users/${userId}`, requestOptions)
            .then(checkStatus)



    }

    getUserContracts(userId){
        const apiKey = this.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/users/${userId}/contracts`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getUserWarehouses(userId){
        const apiKey = this.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/users/${userId}/warehouses`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getUserPermissions() {
        const apiKey = this.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch("/api/v1/users/me/permissions", requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getUserRole(userId) {
        let apiKey = this.getApiKey();

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/users/${userId}/roles`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getAllUsers(filters) {
        const apiKey = this.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/users`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({username: username, password: password})
        };

        return fetch("/api/v1/users/login", requestOptions)
            .then(checkStatus)
            .then(response => response.json())
            .then((response) => {
                const apiKey = response.apiKey;
                localStorage.setItem("apiKey", apiKey);
            });
    }

    logout() {
        localStorage.removeItem("apiKey");
    }

    getUserApiKey(userId){
        let apiKey = this.getApiKey()

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/users/${userId}/apiKey`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    me(){
        let apiKey = this.getApiKey()

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/users/me`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    updatePassword(input){
        let apiKey = this.getApiKey()

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)

        };
        let pathVariable = `/api/v1/users/password`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }
}