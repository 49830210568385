import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


export default class RatingOptionService {


    getOptions(filters){

        let apiKey = new UserService().getApiKey();

        let queryString = "";

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/rating-options/locales`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getLocalesOfOption(optionId){

        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/rating-options/${optionId}/locales`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getOptionById(optionId){

        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/rating-options/${optionId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    saveNewRatingOption(input){
        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };
        let pathVariable = `/api/v1/rating-options`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    updateRatingOption(id, input){
        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };
        let pathVariable = `/api/v1/rating-options/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }


    deleteRatingOption(id){
        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/rating-options/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }






}