import React, {useEffect, useState} from "react";
import {
    Card,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import DriverService from "./DriverService";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {PrivateRoute} from "../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import Driver from "./Driver";
import DriverForm from "./DriverForm";
import {DialogTitle, Typography} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import WarehouseService from "../warehouses/WarehouseService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    container: {
        width: '100%'
    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        margin: "auto",
        display: "block"
    }
}));

function DriverTable(props) {


    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pages, setPages] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [noDataMessage, setNoDataMessage] = useState('');
    const [errorDetails, setErrorDetails] = useState('');

    const [drivers, setDrivers] = useState([]);

    function handleChangePage(event, newPage) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function search(){
        setRefresh(!refresh);
        setPage(0);
    }

    function clearFilters() {

        setWarehouse(null);
        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }

    function fetchDrivers() {

        setLoading(true);

        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;

        if(warehouse){
            filters.warehouseCode = warehouse.code;
        }

        const driverService = new DriverService();
        driverService.getDriversByFilters(filters)
            .then(data => {
                setDrivers(data.drivers)
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setLoading(false);
                setNoDataMessage("no_data")
            })
            .catch(error => {
                error.response.json()
                    .then((err) => {
                        setLoading(false);
                        setNoDataMessage("fetching_error");
                        setErrorDetails(err.status + " " + err.error + " - " + err.message);
                    })
            })

    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(response => {
                setWarehouses(response.warehouses)
                if(response.warehouses?.length === 1){
                    setWarehouse(response.warehouses[0]);
                }
            })
            .catch(error => {

            })
    }

    function deleteCallback(type, response)  {

        setOpenNotify(true);
        setNotifySeverity(type);
        setNotificationMessage(response.message);
        setPage(0);
        setRefresh(!refresh)

    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function goToDriverForm() {
        history.push(`/drivers/new`)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    useEffect(()=>{
        fetchWarehouses();
    }, [])

    useEffect(() => {

        fetchDrivers();

    }, [page, rowsPerPage, location, refresh])

    return <div>
        <Paper className={classes.root}>
            <Switch>
                <PrivateRoute exact path={`/drivers`}>
                    <Card elevation={1} defaultExpanded={true}
                          style={{width: "60%", margin: "auto", marginBlock: "2%"}}>

                        <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                            <Typography variant={"button"}>{t("filters")}</Typography>
                        </DialogTitle>

                        <div style={{alignItems: 'center', display: "flex"}}>
                            <Autocomplete
                                id="warehouse"
                                options={warehouses}
                                size={"small"}
                                style={{
                                    margin: "auto",
                                    marginBottom: "2%",
                                    width: "30%"
                                }}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={warehouse}
                                defaultValue={warehouse}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchWarehouses}
                                onChange={handleWarehouseChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("warehouse")}
                                        InputLabelProps={{shrink: true}}
                                        variant="outlined"
                                        placeholder={t("any")}
                                        margin="dense"
                                        size={"small"}
                                        value={warehouse}
                                        defaultValue={warehouse}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />

                        </div>
                        <div style={{textAlign: 'center'}}>
                            <Button style={{margin: "5%"}}
                                    onClick={clearFilters}>{t("cancel_filter")}</Button>
                            <Button style={{margin: "5%"}}
                                    onClick={search}>{t("apply_filter")}</Button>
                        </div>

                    </Card>
                    <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToDriverForm}
                            className={classes.button}>
                        {t("create_new_driver")}
                    </Button>
                    {(loading) ?
                        <Table>
                            <LoadingComponent/>
                        </Table>
                        :
                        <div>
                            {drivers?.length > 0 ?
                                <div>
                                    <TableContainer className={classes.container}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableCell>{t("ID")}</TableCell>
                                                <TableCell>{t("warehouse")}</TableCell>
                                                <TableCell>{t("supplier")}</TableCell>
                                                <TableCell>{t("contract")}</TableCell>
                                                <TableCell>{t("name")}</TableCell>
                                                <TableCell>{t("mobile_number")}</TableCell>
                                                <TableCell/>
                                            </TableHead>

                                            <TableBody>


                                                {drivers.map((driver, index) => {

                                                    return <Driver deleteCallback={deleteCallback} driver={driver}/>
                                                })}
                                            </TableBody>

                                            <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                                          onClose={closeNotification}>{notificationMessage}</Notification>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={-1}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: true,
                                        }}
                                        labelRowsPerPage={t("rows_per_page")}
                                        labelDisplayedRows={() => {
                                            return t("page")
                                        }}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        onPageChange={handleChangePage}
                                    />
                                </div>

                                :

                                <div className={classes.paper}>
                                    <Typography className={classes.field} variant={"button"}>{t(noDataMessage)}</Typography>
                                    <Typography className={classes.field} variant={"button"}>{t(errorDetails)}</Typography>
                                </div>

                            }
                        </div>


                    }

                </PrivateRoute>

                <PrivateRoute exact path={`/drivers/:id/edit/`} children={<DriverForm/>}>
                </PrivateRoute>
                <PrivateRoute exact path={`/drivers/new/`}>
                    <DriverForm driver={null}/>
                </PrivateRoute>

            </Switch>
        </Paper>
    </div>


}

export default DriverTable;