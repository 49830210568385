import UserService from "../../users/UserService";
import {checkStatus} from "../../api/ApiUtils";
import fetch from "unfetch";


const userService = new UserService();


export default class PostalCodeService {

    getAllPostalCodes(pageNumber, pageSize, filters, guestKey) {


        const apiKey = userService.getApiKey();
        let queryString = ``;
        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if (filters) {
            if (filters.idList) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `ids=${filters.idList}`;
            }
            if (filters.cityId) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `cityId=${filters.cityId}`;
            }
            if (filters.postalCode) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `exactCode=${filters.postalCode}`;
            }
            if (filters.administrativeLevel3Id) {
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `administrativeLevel3Id=${filters.administrativeLevel3Id}`;
            }

        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };


        if(guestKey !== null){
            requestOptions.headers['key'] = guestKey;
        }

        let pathVariable = `/api/v1/geo/postal-codes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getById(postalCodeId) {


        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/postal-codes/${postalCodeId}`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }


    getCitiesOfPostalCode(postalCodeId) {

        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/postal-codes/${postalCodeId}/cities`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getHamletsOfPostalCode(postalCodeId) {


        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/postal-codes/${postalCodeId}/hamlets`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    editPostalCode(postalCodeId, postalCode, cities, hamlets) {


        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/postal-codes/${postalCodeId}`

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                postalCode: postalCode,
                cities: cities,
                hamlets: hamlets
            })
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }

    deletePostalCode(postalCodeId) {


        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/postal-codes/${postalCodeId}`

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);
    }


}