import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

const userService = new UserService();

export default class PickupService {


    getPickupsByFilters(filters) {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/pickups`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    createNewPickup(pickup) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                pickup
            )


        };

        return fetch('/api/v1/pickups', requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getPickupById(id) {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }

        };

        return fetch(`/api/v1/pickups/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getPickupByBarcode(barcode) {
        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };

        return fetch(`/api/v1/pickups/barcode/${barcode}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    addShipmentsToPickup(pickupId, shipments) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                shipments
            )


        };

        return fetch(`/api/v1/pickups/${pickupId}/shipments`, requestOptions)
            .then(checkStatus)
        // .then(response => response.json())

    }

    getPickupDocument(id) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };

        return fetch(`/api/v1/pickups/${id}/document`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    addEventToPickup(id, request) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                pickupEventCodeId: request.pickupEventCodeId,
                warehouseCode: request.warehouseCode,
                driverId: request.driverId,
                dateTime: request.dateTime
            })
        };

        return fetch(`/api/v1/pickups/${id}/events`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }


    deletePickup(id) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/pickups/${id}`, requestOptions)
            .then(checkStatus)
    }


    changeWarehouse(id, warehouseId) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                warehouseId: warehouseId
            })
        };

        return fetch(`/api/v1/pickups/${id}/warehouse`, requestOptions)
            .then(checkStatus)
    }

    assignToDriver(pickupId, driverId) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/pickups/${pickupId}/assign-driver/${driverId}`, requestOptions)
            .then(checkStatus)
    }

    updateAddress(pickupId, input) {
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(`/api/v1/pickups/${pickupId}/update-address`, requestOptions)
            .then(checkStatus)
    }

    getPickupsByShipmentId(id) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };

        return fetch(`/api/v1/pickups/shipment/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

}