import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

export default class TaskQueueService {
    getRecords(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/tasks-queue`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addToQueue(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/tasks-queue`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    refreshSynchronously(shipmentId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                shipmentId: shipmentId,
            })
        };

        let pathVariable = `/api/v1/tasks-queue/synchronously`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    clearQueue() {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/tasks-queue`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }
}