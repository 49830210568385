import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {DownloadRounded} from "@mui/icons-material";
import {ArrowBackRounded, Delete, SaveOutlined} from "@material-ui/icons";
import PickupService from "./PickupService";
import Notification from "../components/notifications/Notification";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import DriverService from "../drivers/DriverService";
import theme from "../theme/theme";
import CheckChip from "../components/chips/CheckChip";
import EditIcon from "@material-ui/icons/Edit";
import WarehouseService from "../warehouses/WarehouseService";


function Pickup(props) {


    const pickup = props.pickup;

    const [anchorEl, setAnchorEl] = useState(null);

    const [loading, setLoading] = useState(false);

    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState(null);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [editWarehouse, setEditWarehouse] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const {t} = useTranslation();

    const history = useHistory();

    const useStyles = makeStyles({
        link: {
            cursor: "pointer",
            transition: "all 0.2s",
            color: "#144dff",
            '& > *': {
                borderBottom: 'unset',
            },
        }
    });

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }


    function isAssigned() {

        return pickup.events?.some(event => event.pickupEventCode.id === 36);

    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function downloadDocument() {
        let pickupService = new PickupService();
        pickupService.getPickupDocument(pickup.id)
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:application/pdf;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }
                )
            })

    }

    function deletePickup() {
        let pickupService = new PickupService();
        pickupService.deletePickup(pickup.id)
            .then(data => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setTimeout(() => {
                    props.refreshCallback();
                }, 2500)

            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }
                )
            })
    }

    function handleDriverChange(event, driver) {
        setDriver(driver)
    }

    async function fetchDrivers() {
        let filters = {};
        if (null !== filters.warehouseCode) {
            filters.warehouseCode = pickup.warehouseCode;
        }

        let driv = await new DriverService().getDriversByFilters(filters)
        setDrivers(driv.drivers);
    }

    async function assignDriver() {
        setLoading(true);

        // let barcodes = [];
        // barcodes.push(pickup.barcode);

        // await new DriverWorkListService().loadParcel(driver.id, barcodes)
        //     .then(result => {
        //         setNotificationMessage(t("successful"));
        //         setNotifySeverity('success');
        //         setOpenNotify(true);
        //     })
        //     .catch(error => {
        //         error.response.json().then(response => {
        //                 setNotificationMessage(response.status + " - " + response.message);
        //                 setNotifySeverity('error');
        //                 setOpenNotify(true);
        //             }
        //         )
        //     });
        await new PickupService().assignToDriver(pickup.id, driver.id)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }
                )
            })

        setTimeout(() => {
            setLoading(false);
            props.refreshCallback();
        }, 3000)
    }

    async function fetchWarehouses() {

        let wares = await new WarehouseService().getWarehousesByFilters(0, 1000, null)
            .catch(error => {

            })

        setWarehouses(wares.warehouses);
    }

    async function changeWarehouse() {
        await new PickupService().changeWarehouse(pickup.id, warehouse.id)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setTimeout(() => {
                    props.refreshCallback();
                    setEditWarehouse(false);
                }, 1500)
            })
            .catch(error => {
                error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    }
                )
            })
    }

    async function setStartingWarehouse() {

        let wares = await new WarehouseService().getWarehousesByFilters(0, 1000, null)
            .catch(error => {

            })

        setWarehouse(wares.warehouses.find(warehouse => warehouse.code === pickup.warehouseCode))

    }


    useEffect(() => {
        setStartingWarehouse();
    }, [])

    let classes = useStyles();


    return <React.Fragment>
        <TableRow>
            <TableCell>
                <Typography className={classes.link}
                            onClick={() => props.goToDetails(pickup.id)}> {pickup.id} </Typography>
            </TableCell>
            <TableCell align="left">{pickup.contractCode}</TableCell>
            <TableCell align="left">
                <Typography> {pickup.address.companyName}</Typography>
                <Typography> {pickup.address.street}{pickup.address.buildingNr ? ", " + pickup.address.buildingNr : ""} </Typography>
                <Typography> {pickup.address.postalCode} {" " + pickup.address.city} {pickup?.address?.administrative_level_3? " (" + pickup.address.administrative_level_3 + ")" : ""} </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography>{new Date(pickup.createdAt).toLocaleDateString()}</Typography>
                <Typography>{new Date(pickup.createdAt).toLocaleTimeString()}</Typography>
            </TableCell>
            <TableCell align="left">
                <Typography>{new Date(pickup.date).toLocaleDateString()}</Typography>
                {!pickup.fromTime && !pickup.toTime ?

                    <Typography>{t("no_time_slot")}</Typography>

                    :

                    <Typography>{pickup.fromTime ? pickup.fromTime : "/"} {" - "} {pickup.toTime ? pickup.toTime : "/"}</Typography>
                }
            </TableCell>
            {/*<TableCell align="left">{pickup.warehouseCode}</TableCell>*/}
            <TableCell align="left">
                {editWarehouse ?
                    <div style={{display: "flex"}}>
                        <IconButton style={{height: "fit-content", margin: "auto", background: "transparent"}}
                                    onClick={() => {
                                        setEditWarehouse(false)
                                    }}><ArrowBackRounded/></IconButton>
                        <Autocomplete
                            id="warehouse"
                            options={warehouses}
                            style={{width: "90%"}}
                            getOptionLabel={option => option.code}
                            value={warehouse}
                            defaultValue={warehouse}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchWarehouses}
                            onChange={handleWarehouseChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <IconButton disabled={!warehouse} onClick={changeWarehouse} size={"small"}
                                    style={{height: "fit-content", margin: "auto", background: "transparent"}}>

                            {loading ?
                                <CircularProgress style={{color: theme.palette.primary.main}} size={24}/>
                                :
                                <SaveOutlined color={"primary"}/>
                            }

                        </IconButton>
                    </div>


                    :
                    <div>
                        <IconButton onClick={() => setEditWarehouse(true)} style={{marginRight: "1%"}}
                                    size={"small"}><EditIcon/></IconButton>
                        {pickup.warehouseCode}
                    </div>

                }

            </TableCell>
            <TableCell>
                {isAssigned() ?
                    <CheckChip>{t("pickup_assigned")}</CheckChip>

                    :
                    <div style={{display: "flex"}}>
                        <Autocomplete
                            disabled={isAssigned()}
                            style={{width: "90%"}}
                            options={drivers}
                            getOptionLabel={option => option.name + " " + option.surname}
                            value={driver}
                            defaultValue={driver}
                            noOptionsText={t("no_options")}
                            onChange={handleDriverChange}
                            onOpen={fetchDrivers}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("driver")}
                                    variant="outlined"
                                    margin="dense"
                                    size={"small"}
                                    value={driver}
                                    defaultValue={driver}
                                />
                            )}
                        />
                        <IconButton disabled={isAssigned() || !driver} onClick={assignDriver} size={"small"}
                                    style={{height: "fit-content", margin: "auto", background: "transparent"}}>

                            {loading ?
                                <CircularProgress style={{color: theme.palette.primary.main}} size={24}/>
                                :
                                <SaveOutlined color={"primary"}/>
                            }

                        </IconButton>
                    </div>
                }
            </TableCell>
            <TableCell>
                <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                    <MenuIcon/>
                </Button>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem
                        onClick={downloadDocument}><DownloadRounded/>&nbsp;{t("download_pickup_document")}</MenuItem>
                    <MenuItem
                        onClick={deletePickup}><Delete/>&nbsp;{t("delete")}</MenuItem>

                </Menu>
            </TableCell>
        </TableRow>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </React.Fragment>

}

export default Pickup;