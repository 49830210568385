import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Backdrop, Button, Fade, Menu, MenuItem, Modal, Paper, TableCell, TableRow, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
import ZoneService from "./ZoneService";
import {WarningOutlined} from "@mui/icons-material";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {DialogTitle} from "@mui/material";

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    container: {
        width: '100%',
        maxHeight: 840,

    },
    table: {
        width: '75%',
        margin: "auto"

    },
    cell: {
        margin:"auto"

    }
});



function Zone(props) {
    let zone = props.zone;
    const {t} = useTranslation();

    const [confirmDialog, setConfirmDialog] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const classes = useStyles();

    function handleMenuClick(event){
        setAnchorEl(event.currentTarget);
    }
    function handleMenuClose(){
        setAnchorEl(null);
    }

    function editZone(zoneId){
        setAnchorEl(null);
        history.push(`/zones/${zoneId}/edit/`);
    }

    function deleteZone(zoneId){
        let zoneService = new ZoneService();
        zoneService.deleteZone(zoneId)
            .then(()=>{
                props.deleteCallback();
            })
            .catch(error => {
                props.failedDeleteCallback(error);
            })

    }


    useEffect(() => {

    }, [])

    return <TableRow>
        <TableCell>
            {zone.id}
        </TableCell>
        <TableCell>
            {zone.code}
        </TableCell>
        <TableCell>
            {zone.warehouseCode}
        </TableCell>
        <TableCell>
            {zone.routingRules.map(routingRule => {
                return <div>
                    <Typography variant={"caption"}>{routingRule.name}</Typography>
                </div>
                // return <div>
                //     <Typography variant={"caption"}>{routingRule.service}</Typography>
                //     <Typography variant={"caption"}>{" - "}</Typography>
                //     <Typography variant={"caption"}>{routingRule.country}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel1 ? ", " + routingRule.administrativeLevel1 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel2 ? ", " + routingRule.administrativeLevel2 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel3 ? ", " + routingRule.administrativeLevel3 : ""}</Typography>
                //     <Typography variant={"caption"}>{routingRule.city ? ", " + routingRule.city : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.postalCode ? ", " + routingRule.postalCode : ""}</Typography>
                // </div>
            })}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editZone(zone.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

            <MenuItem onClick={() => setConfirmDialog(true)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>
        <Modal open={confirmDialog}
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               className={classes.modal}
               onClose={() => setConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={confirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <DialogTitle style={{width: "fit-content", margin: "auto", marginTop: "10%", display: "flex"}}>
                        <WarningOutlined color={"warning"}/>
                        <Typography variant={"button"}>{t("confirm_delete")}</Typography>
                        <WarningOutlined color={"warning"}/>
                    </DialogTitle>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={() => deleteZone(zone.id)}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
    </TableRow>


} export default Zone;