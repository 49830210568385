import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardActions, DialogContent, DialogTitle, Paper, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Button from "@material-ui/core/Button";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {useHistory, useLocation, useParams} from "react-router-dom";
import WarehouseService from "./WarehouseService";
import Notification from "../components/notifications/Notification";
import {Autocomplete} from "@material-ui/lab";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import Typography from "@material-ui/core/Typography";
import CompanyService from "../companies/CompanyService";
import ContractService from "../accounts/contracts/ContractService";
import Chip from "@mui/material/Chip";
import MyTimeField from "../components/fields/MyTimeField";
import moment from "moment/moment";
import CarrierService from "../carriers/CarrierService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "30%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        display: "block",
        margin: "auto"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "3%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "15%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    confirm_button: {
        marginInline: "4%",

        width: "15%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    },
    menuPaper: {
        maxHeight: 250
    },

}));


function WarehouseForm(props) {

    const [refresh, setRefresh] = useState(false);
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [fetched, setFetched] = useState(false)

    const {id} = useParams();

    const [fetching, setFetching] = useState(false);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [carrier, setCarrier] = useState(null);
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freeCity, setFreeCity] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');
    const [city, setCity] = useState(null);
    const [addressCompanyName, setAddressCompanyName] = useState('');
    const [postalCode, setPostalCode] = useState(null);
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const [warehouseStorageEmail, setWarehouseStorageEmail] = useState('');
    const [pickupEmail, setPickupEmail] = useState('');

    const [arrivalsCutOffTime, setArrivalsCutOffTime] = useState(null);
    const [departuresCutOffTime, setDeparturesCutOffTime] = useState(null);

    const [company, setCompany] = useState(null);
    const [contract, setContract] = useState(null);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [carriers, setCarriers] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouseCodesInHub, setWarehouseCodesInHub] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();

    function handleDeparturesTimeChange(event) {
        setDeparturesCutOffTime(event);
    }

    function handleArrivalsTimeChange(event) {
        setArrivalsCutOffTime(event);
    }

    async function fetchCarriers() {

        await new CarrierService().getCarriersByFilters()
            .then(result => {
                setCarriers(result.carriers);
            })
            .catch(error => {

            })

    }

    function fetchCompanies() {
        let companyService = new CompanyService();
        companyService.getCompanies()
            .then(response => {
                setCompanies(response)
            })
            .catch(error => {

            })
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL3(country) {

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdmin3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {
        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {
        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function fetchContracts() {

        let filters = {};

        filters.direction = "BUY";

        let contractService = new ContractService();
        contractService.getContractsByFilters(filters)
            .then(result => {
                setContracts(result);
            })
            .catch(error => {

            })
    }

    function fetchWarehouse(id) {
        setFetching(true);

        let countryService = new CountryService();
        let warehouseService = new WarehouseService();

        warehouseService.getWarehouseById(id)
            .then(async data => {
                setName(data.name);
                setCode(data.code);
                setWarehouseCodesInHub(data.warehouseCodesInHub);
                setStreet(data.address.street);
                setBuildingNr(data.address.buildingNr);
                setEmail(data.address.email);
                setPhone(data.address.phone);
                setMobile(data.address.mobile);
                setAddressCompanyName(data.address.companyName);
                setWarehouseStorageEmail(data.warehouseStorageEmail);
                setPickupEmail(data.pickupEmail);

                let depTime = moment(data.departuresCutOffTime, 'HH:mm:ss');
                let arrTime = moment(data.arrivalsCutOffTime, 'HH:mm:ss');

                setDeparturesCutOffTime(depTime);
                setArrivalsCutOffTime(arrTime);

                let companies = await new CompanyService().getCompanies();
                setCompany(companies.filter(company => company.companyName === data.companyName)[0])

                if (data.contractCode !== null) {
                    let contracts = await new ContractService().getContractsByFilters();
                    setContract(contracts.filter(contract => contract.code === data.contractCode)[0])
                }

                if (data.carrierId !== null) {

                    let carriers = await new CarrierService().getCarriersByFilters();
                    setCarrier(carriers.carriers.filter(carrier => carrier.id === data.carrierId)[0]);
                }

                let countr = {};
                if (data.address.country != null) {
                    let countries = await countryService.getCountriesByFilters(null, null, null, null);

                    if (data.address.country.length === 2) {
                        countr = countries.countries.filter(country => country.iso3166Alpha2 === data.address.country)[0];
                    } else {
                        countr = countries.countries.filter(country => country.iso3166Alpha3 === data.address.country)[0]
                    }

                    // .then(countries => {
                    //     if (data.address.country.length === 2) {
                    //         countr = countries.countries.filter(country => country.iso3166Alpha2 === data.address.country)[0];
                    //     } else {
                    //         countr = countries.countries.filter(country => country.iso3166Alpha3 === data.address.country)[0]
                    //     }
                    // })
                }

                setCountry(countr);

                let city = {}
                let administrative3 = {}
                let postalCode = {}

                if (data.address.country === "IT" || data.address.country === "ITA" || data.address.country === "ES" || data.address.country === "ESP" || data.address.country === "DE" || data.address.country === "DEU") {
                    let cityService = new CityService();
                    let cities = await cityService.getCities(null, null, null, null)
                    city = cities.cities.filter(_city => _city.name.toLowerCase() === data.address.city.toLowerCase())[0]


                    let admin3Service = new AdministrativeLevel3Service();
                    let admin3s = await admin3Service.getAllAdminLevel3WithFilters(null, null, null, null)

                    if (data.address.administrativeLevel3 !== null) {
                        administrative3 = admin3s.admins3.filter(admin3 => admin3.code === data.address.administrative_level_3)[0]
                    } else {
                        administrative3 = admin3s.admins3.filter(admin3 => admin3.id === city.level_3_id)[0]
                    }


                    let postalCodeService = new PostalCodeService();
                    let codes = await postalCodeService.getAllPostalCodes(null, null, null, null)

                    postalCode = codes.postalCodes.filter(postalCode => postalCode.postalCode === data.address.postalCode)[0];

                    setAdminL3(administrative3);
                    setCity(city);
                    setPostalCode(postalCode);
                } else {
                    setFreeAdministrativeLevel1(data.address.administrativeLevel1);
                    setFreeAdministrativeLevel2(data.address.administrativeLevel2);
                    setFreeAdministrativeLevel3(data.address.administrativeLevel3);
                    setFreeCity(data.address.city);
                    setFreePostalCode(data.address.postalCode);
                }

                setFetched(true);
                setTimeout(() => {
                    setFetching(false);
                }, 2000)
            })
    }


    function handleClose() {
        history.push('/warehouses');
    }

    function handleCompanyChange(event, company) {
        setCompany(company);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleCarrierChange(event, carrier) {
        setCarrier(carrier);
    }

    function handleWarehouseStorageEmailChange(event) {
        setWarehouseStorageEmail(event.target.value);
    }

    function handlePickupEmailChange(event) {
        setPickupEmail(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        let input = {};

        input.name = name;
        input.code = code;
        if (company) {
            input.companyId = company.id;
        }

        if (contract) {
            input.contractId = contract.id;
        }

        if (carrier) {
            input.carrierId = carrier.id;
        }

        input.warehouseCodesInHub = warehouseCodesInHub;

        let address = {};

        address.companyName = addressCompanyName;
        address.country = country.iso3166Alpha3;
        address.administrativeLevel3 = adminL3.code;
        address.city = city.name;
        address.postalCode = postalCode.postalCode;

        address.street = street;
        address.buildingNr = buildingNr;
        address.phone = phone;
        address.mobile = mobile;
        address.email = email;

        input.address = address;

        input.departuresCutOffTime = moment(departuresCutOffTime).format("HH:mm:ss");
        input.arrivalsCutOffTime = moment(arrivalsCutOffTime).format("HH:mm:ss");
        input.warehouseStorageEmail = warehouseStorageEmail;
        input.pickupEmail = pickupEmail;

        let warehouseService = new WarehouseService();

        if (id) {
            warehouseService.editWarehouse(id, input)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {

                        history.push({
                            pathname: '/warehouses',
                            state: {detail: ''}
                        });
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            warehouseService.addWarehouse(input)
                .then(() => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"));
                    setOpenNotify(true);

                    setTimeout(() => {

                        history.push({
                            pathname: '/warehouses',
                            state: {detail: ''}
                        });
                    }, 2000)
                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }


    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);

    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);

    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);

    }

    function handlePostalCodeChange(event, postalCode) {

        setPostalCode(postalCode);
    }

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handleCodeChange(event) {
        setCode(event.target.value);
    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function handleStreetChange(event) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event) {
        setBuildingNr(event.target.value)
    }

    function handleAddressCompanyNameChange(event) {
        setAddressCompanyName(event.target.value)
    }

    function handleEmailChange(event) {
        setEmail(event.target.value)
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value)
    }

    function handleMobileChange(event) {
        setMobile(event.target.value)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function addWarehouseCodeInHub(newWarehouseCodesInHub) {
        setWarehouseCodesInHub(newWarehouseCodesInHub);
    }

    function generateAddress(country) {
        // if (country) {
        return <div style={{display: "flex", marginBottom: "3%"}}>
            <Autocomplete
                id="administrativeLevel3"
                size="small"
                style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                options={admin3List}
                disabled={!country}
                getOptionLabel={option => option.name}
                value={adminL3}
                defaultValue={adminL3}
                noOptionsText={t("no_options")}
                onChange={handleAdminL3Change}
                onOpen={() => fetchAdminL3(country)}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("administrative3")}
                        variant="outlined"
                        margin="dense"
                        value={adminL3}
                        defaultValue={adminL3}
                    />
                )}
            />
            <Autocomplete
                id="city"
                size="small"
                style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                options={cities}
                disabled={!adminL3}
                getOptionLabel={option => option.name}
                value={city}
                defaultValue={city}
                noOptionsText={t("no_options")}
                onChange={handleCityChange}
                onOpen={() => fetchCities(adminL3)}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("city")}
                        variant="outlined"
                        margin="dense"
                        value={city}
                        defaultValue={city}
                        required
                        error={!city}
                        helperText={!adminL3 ? t("select_admin3_first") : (!city ? t("required") : null)}
                    />
                )}
            />
            <Autocomplete
                id="postalCode"
                size="small"
                disabled={!city}
                style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                options={postalCodes}
                getOptionLabel={option => option.postalCode}
                value={postalCode}
                defaultValue={postalCode}
                noOptionsText={t("no_options")}
                onChange={handlePostalCodeChange}
                onOpen={() => fetchPostalCodes(city, adminL3)}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("postalCode")}
                        variant="outlined"
                        margin="dense"
                        value={postalCode}
                        defaultValue={postalCode}
                        required
                        error={!postalCode}
                        helperText={!postalCode ? t("required") : null}
                    />
                )}
            />

        </div>
        // } else {
        //     return <>
        //         <div style={{display: "flex", marginBottom: "5%"}}>
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative1")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel1}
        //                 onChange={handleFreeAdministrativeLevel1Change}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative2")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel2}
        //                 onChange={handleFreeAdministrativeLevel2Change}
        //             />
        //         </div>
        //         <div style={{display: "flex", marginBottom: "1%"}}>
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative3")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel3}
        //                 onChange={handleFreeAdministrativeLevel3Change}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("city")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeCity}
        //                 required
        //                 error={!freeCity}
        //                 helperText={!freeCity ? t("required") : null}
        //                 onChange={handleFreeCityChange}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("postalCode")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freePostalCode}
        //                 onChange={handleFreePostalCodeChange}
        //                 required
        //                 error={!freePostalCode}
        //                 helperText={!freePostalCode ? t("required") : null}
        //             />
        //         </div>
        //     </>
        // }
    }

    useEffect(() => {
        fetchWarehouses();
    }, []);

    useEffect(() => {
        fetchCountries();
        if (id && !fetched) {
            fetchWarehouse(id);
        }
    }, [refresh, location]);

    async function fetchWarehouses() {
        const warehouseService = new WarehouseService();
        const result = await warehouseService.getWarehousesByFilters();
        setWarehouses(result.warehouses);
    }

    return <Paper className={classes.root}>
        <form>
            <DialogTitle>{t("warehouse_data")}</DialogTitle>
            {(fetching) ?

                <LoadingComponent/>

                :

                <DialogContent>
                    <TextField
                        required
                        error={!name}
                        label={t("name")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleNameChange}
                        value={name}
                        className={classes.field}
                    />
                    <TextField
                        required
                        error={!code}
                        label={t("code")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleCodeChange}
                        value={code}
                        className={classes.field}
                    />

                    <div>
                        <Autocomplete
                            id="company"
                            size="small"
                            options={companies}
                            getOptionLabel={option => option.shortName + " - " + option.companyName}
                            value={company}
                            defaultValue={company}
                            noOptionsText={t("no_options")}
                            onChange={handleCompanyChange}
                            onOpen={fetchCompanies}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.field}
                                    label={t("company")}
                                    variant="outlined"
                                    margin="dense"
                                    value={company}
                                    defaultValue={company}
                                    required
                                    error={!company}
                                />
                            )}
                        />
                        <Autocomplete
                            id="contract"
                            size="small"
                            options={contracts}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={contract}
                            defaultValue={contract}
                            noOptionsText={t("no_options")}
                            onChange={handleContractChange}
                            onOpen={fetchContracts}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.field}
                                    label={t("contract")}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                />
                            )}
                        />
                        <Autocomplete
                            id="carrier"
                            size="small"
                            options={carriers}
                            getOptionLabel={option => option.name}
                            value={carrier}
                            defaultValue={carrier}
                            noOptionsText={t("no_options")}
                            onChange={handleCarrierChange}
                            onOpen={fetchCarriers}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.field}
                                    label={t("carrier")}
                                    variant="outlined"
                                    margin="dense"
                                    value={carrier}
                                    defaultValue={carrier}
                                    required
                                    error={!carrier}
                                />
                            )}
                        />
                    </div>
                    <div style={{width: "25%", margin: "auto", display: "flex", marginBottom: "2%"}}>
                        <MyTimeField
                            label={t("departures_cutoff_time")}
                            value={departuresCutOffTime}
                            onChange={handleDeparturesTimeChange}
                            defaultValue={departuresCutOffTime}
                            error={!departuresCutOffTime}
                        />
                        <Typography style={{display: "flex", margin: "auto"}}
                                    variant={"overline"}>{"GMT " + moment().format('Z')}</Typography>
                    </div>
                    <div style={{width: "25%", margin: "auto", display: "flex"}}>
                        <MyTimeField
                            label={t("arrivals_cutoff_time")}
                            value={arrivalsCutOffTime}
                            onChange={handleArrivalsTimeChange}
                            defaultValue={arrivalsCutOffTime}
                            error={!arrivalsCutOffTime}
                        />
                        <Typography style={{display: "flex", margin: "auto"}}
                                    variant={"overline"}>{"GMT " + moment().format('Z')}</Typography>
                    </div>
                    <div style={{width: "100%", margin: "auto", display: "flex"}}>
                        <TextField
                            label={t("warehouse_storages_email")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            onChange={handleWarehouseStorageEmailChange}
                            value={warehouseStorageEmail}
                            className={classes.field}
                        />
                    </div>
                    <div style={{width: "100%", margin: "auto", display: "flex"}}>
                        <TextField
                            label={t("pickups_email")}
                            variant="outlined"
                            type="text"
                            margin="dense"
                            onChange={handlePickupEmailChange}
                            value={pickupEmail}
                            className={classes.field}
                        />
                    </div>

                    <Card style={{width: "60%", margin: "auto", marginTop: "5%"}}>
                        <DialogTitle><Typography
                            variant={"subtitle2"}>{t("warehouse_address")}</Typography></DialogTitle>
                        <Autocomplete
                            id="country"
                            size="small"
                            style={{minWidth: "30%", width: "fit-content", marginBlock: "4%", marginLeft: "2%"}}
                            options={countries}
                            getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                            value={country}
                            defaultValue={country}
                            onChange={handleCountryChange}
                            onSelect={fetchCountries}
                            ListboxProps={{style: {maxHeight: '15rem'},}}
                            noOptionsText={t("no_options")}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t("country")}
                                variant="outlined"
                                margin="dense"
                                value={country}
                                defaultValue={country}
                                required
                                error={!country}
                                helperText={!country ? t("required") : null}
                            />}
                        />

                        {generateAddress(country)}

                        <div style={{display: "flex", marginBlock: "5%"}}>
                            {/*<div style={{display: "flex", marginBlock: "2%"}}>*/}
                            <TextField
                                required
                                error={!addressCompanyName}
                                helperText={!addressCompanyName ? t("required") : null}
                                style={{marginInline: "2%"}}
                                label={t("address_company_name")}
                                variant={"outlined"}
                                size={"small"}
                                value={addressCompanyName}
                                onChange={handleAddressCompanyNameChange}
                            />
                            {/*</div>*/}
                            <TextField
                                style={{marginInline: "2%"}}
                                label={t("street")}
                                variant={"outlined"}
                                size={"small"}
                                value={street}
                                required
                                error={!street}
                                helperText={!street ? t("required") : null}
                                onChange={handleStreetChange}
                            />
                            <TextField
                                style={{marginInline: "2%", width: "10%"}}
                                label={t("buildingNr")}
                                variant={"outlined"}
                                size={"small"}
                                value={buildingNr}
                                onChange={handleBuildingNrChange}
                            />
                        </div>
                        <div style={{display: "flex", marginBottom: "5%"}}>
                            <TextField
                                style={{marginInline: "2%"}}
                                label={t("email")}
                                variant={"outlined"}
                                size={"small"}
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <TextField
                                style={{marginInline: "2%"}}
                                label={t("phone")}
                                variant={"outlined"}
                                size={"small"}
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                            <TextField
                                style={{marginInline: "2%"}}
                                label={t("mobile")}
                                variant={"outlined"}
                                size={"small"}
                                value={mobile}
                                onChange={handleMobileChange}
                            />
                        </div>
                    </Card>

                    <div style={{width: "60%", margin: "auto", marginTop: "5%"}}>
                        <Autocomplete
                            multiple
                            defaultValue={warehouseCodesInHub.filter(warehouseCodeInHub => code !== warehouseCodeInHub)}
                            onChange={(e, values) => addWarehouseCodeInHub(values)}
                            id="warehouseCodesInHub"
                            options={warehouses.map(value => value.code)}
                            renderTags={(value, getTagProps) => value.map((value1, index) => (
                                <Chip variant="filled" label={value1} {...getTagProps({index})} />
                            ))}
                            renderInput={params => (
                                <TextField {...params} variant="outlined" label={t("warehousesInHub")}/>
                            )}
                        />
                    </div>

                    <CardActions className={classes.button_container}>
                        <Button
                            className={classes.cancel_button}
                            endIcon={<CancelOutlined/>}
                            onClick={handleClose}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            className={classes.confirm_button}
                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                            onClick={handleSubmit}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </CardActions>

                </DialogContent>

            }


        </form>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>


}

export default WarehouseForm;