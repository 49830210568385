import {Popover, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {IconButton} from "@mui/material";
import {SpeakerNotesOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    noteIcon: {
        cursor: "pointer",
        transition: "all 0.2s",

        "&:hover": {

            background: '#ebebeb'


        },
        '& > *': {
            borderBottom: 'unset',
        },
    }
}));

function NoteTooltip(props){



    const [noteAnchorEl, setNoteAnchorEl] = useState(null);

    const openNote = Boolean(noteAnchorEl);

    const classes = useStyles();


   return <div>
       <IconButton onClick={event => setNoteAnchorEl(event.currentTarget)}><SpeakerNotesOutlined/></IconButton>
       <Popover
           open={openNote}
           anchorEl={noteAnchorEl}
           onClose={()=>setNoteAnchorEl(null)}
           anchorOrigin={{
               vertical: 'top',
               horizontal: 'right',
           }}
           transformOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
           }}
       >
           <div>
               <Typography style={{margin: "5%"}} variant={"subtitle2"}>{props.children}</Typography>
           </div>

       </Popover>
        </div>

} export default NoteTooltip;