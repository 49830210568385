import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import HamletService from "./HamletService";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    container: {
        width: '100%',
        maxHeight: 840,

    },
    table: {
        width: '50%',
        margin: "auto"

    },
    cell: {},
});


function Hamlet(props) {

    const hamlet = props.hamlet;
    const [postalCodes, setPostalCodes] = useState([])
    const cities = props.cities;
    const location = useLocation();
    const [fetching, setFetching] = useState(true);

    const {t} = useTranslation();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function editHamlet(hamletId) {
        setAnchorEl(null);
        history.push(`/hamlets/${hamletId}/edit/`);
    }

    function deleteHamlet(hamletId) {
        let hamletService = new HamletService();
        hamletService.deleteHamlet(hamletId)
            .then(() => {
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then((response) => {
                });
            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    const classes = useStyles();




    useEffect(() => {
    }, [location])

    return <TableRow>


        <TableCell>{hamlet.name}</TableCell>

        {cities.map(city => {
            if (city.id === hamlet.cityId) {
                return <TableCell>{city.name}</TableCell>
            }
        })}
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => editHamlet(hamlet.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

            <MenuItem onClick={() => deleteHamlet(hamlet.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>

    </TableRow>


}

export default Hamlet;