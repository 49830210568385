import React, {useEffect, useState} from "react";
import {Card, CardContent, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import RateServiceForm from "./RateServiceForm";
import {AddCircleOutlineOutlined, DeleteForever, RemoveCircleOutlineOutlined} from "@mui/icons-material";
import {generateUUID} from "../utils/utils";

export default function RateServiceBlockForm(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.rateServiceBlock);
    const [refresh, setRefresh] = useState(false);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    function onRateServiceChange(index, rateService) {
        const newServices = [...state.services];
        newServices[index] = rateService;

        const newState = {...state, services: newServices};
        setNewState(newState);
    }

    function addRateService() {

        const newServices = [...state.services];
        const newRateService = {
            uuid: generateUUID(),
            indexOrder: newServices.length + 1,
            consolidation: "NO_CONSOLIDATION",
            serviceCode: state.serviceCode
        };

        newServices.push(newRateService);
        const newState = {...state, services: newServices};
        setNewState(newState);

        console.log(newState);
    }

    function removeRateService(index) {
        const newServices = [...state.services];

        // newServices.splice(index, 1);

        newServices.pop();

        const newState = {...state, services: newServices};
        setNewState(newState);

        console.log(newState);

    }

    function swapIndexOrder(origin, target) {

        const newServices = [...state.services];

        if (origin < target) {
            newServices[origin].indexOrder++;
            newServices[target].indexOrder--;
        } else {
            newServices[origin].indexOrder--;
            newServices[target].indexOrder++;
        }


        newServices.sort((a, b) => a.indexOrder - b.indexOrder);

        const newState = {...state, services: newServices};
        setNewState(newState);
    }

    function setUuids() {
        const services = [...state.services];

        for (let i = 0; i < services.length; i++) {
            services[i].uuid = generateUUID();
        }
        const newState = {...state, services: services};
        setNewState(newState);

    }

    useEffect(() => {

        setUuids();

    }, [])

    return <div>
        <Card>
            <CardContent>
                <div style={{display: "flex"}}>
                    <IconButton style={{marginInline: "2%"}} onClick={() => props.removeBlock(state.serviceCode)}>
                        <DeleteForever/>
                    </IconButton>
                    <Typography style={{
                        fontWeight: 'bold',
                        alignSelf: "center",
                        marginRight: "2%"
                    }}>{t("service") + " " + state.serviceCode}</Typography>
                    <IconButton disabled={state?.services?.length < 2} onClick={removeRateService}>
                        <RemoveCircleOutlineOutlined/>
                    </IconButton>
                    <IconButton disabled={state.serviceCode === "HANDLING"} onClick={addRateService}>
                        <AddCircleOutlineOutlined/>
                    </IconButton>
                </div>

                {state.services?.map((rateService, index) => {
                    return <RateServiceForm
                        style={{marginTop: "15px"}}
                        swapIndexOrder={swapIndexOrder}
                        index={index}
                        upDisabled={rateService.indexOrder === 1}
                        downDisabled={rateService.indexOrder === state.services.length}
                        key={rateService.uuid}
                        rateService={rateService}
                        onStateChange={(newRateService) => {
                            onRateServiceChange(index, newRateService);
                        }}
                        removeRateService={removeRateService}
                    />
                })}
            </CardContent>
        </Card>
    </div>
}