import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

const userService = new UserService();


export default class HamletService {

    getHamlets(pageNumber, pageSize, filters){

        const apiKey = userService.getApiKey();

        let queryString = ``;
        if(null != pageNumber){
            queryString += `pageNumber=${pageNumber}`;
        }
        if(pageSize){
            if(queryString.length > 0){
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if(filters){
            if(filters.idList){
                if(queryString.length > 0){
                    queryString += `&`;
                }
                queryString += `ids=${filters.idList}`;
            }
            if(filters.cityId){
                if(queryString.length > 0){
                    queryString += `&`;
                }
                queryString += `cityId=${filters.cityId}`;
            }
        }

        let pathVariable = `/api/v1/geo/hamlets`;

        if(queryString.length>0){
            pathVariable+=`?`+queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    getHamletByID(hamletId){

        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/hamlets/${hamletId}`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getPostalCodesOfHamlet(hamletId){

        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/geo/hamlets/${hamletId}/postalCodes`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    addHamlet(name, cityId, postalCodes){

        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/hamlets`;

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                cityId: cityId,
                postalCodes: postalCodes
            })
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    editHamlet(hamletId, name, cityId, postalCodes){

        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/hamlets/${hamletId}`;

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                cityId: cityId,
                postalCodes: postalCodes
            })
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deleteHamlet(hamletId){

        const apiKey = userService.getApiKey();
        let pathVariable = `/api/v1/geo/hamlets/${hamletId}`;

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

}