import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import React, {useState} from "react";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {useTranslation} from "react-i18next";


function DateField(props){

    const {t} = useTranslation();

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");


    return <div style={props.style} className={props.className}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>

            <DatePicker
                style={{width: "100%"}}
                autoOk
                disableToolbar
                format={props.format}
                okLabel={null}
                clearLabel={t("delete")}
                cancelLabel={t("cancel")}
                // margin="normal"
                margin={props.margin? props.margin : "normal"}
                inputVariant="outlined"
                label={props.label}
                clearable={props.clearable}
                size={"small"}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                disabled={props.disabled}
                value={props.value}
                error={props.error}
                required={props.required || false}
                onChange={props.onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />

        </MuiPickersUtilsProvider>
    </div>


} export default DateField;