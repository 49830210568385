import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Table,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CircularProgress,
    DialogTitle,
    IconButton,
    TableCell
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {AppStyles} from "../../theme/AppStyles";
import DateField from "../../components/fields/DateField";
import moment from "moment";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import {Autocomplete} from "@material-ui/lab";
import ShipmentService from "../../shipments/ShipmentService";
import CodService from "../../shipments/services/CodService";
import theme from "../../theme/theme";
import {CheckCircleOutlined, ErrorOutlined, ExpandMore, SaveOutlined, SearchOutlined} from "@material-ui/icons";
import {format} from "date-fns";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import InfoChip from "../../components/chips/InfoChip";
import AmountChip from "../../components/chips/AmountChip";
import CompanyService from "../../companies/CompanyService";
import BankAccountService from "../../bank_accounts/BankAccountService";
import PaymentToCustomerService from "./PaymentToCustomerService";
import Notification from "../../components/notifications/Notification";
import Chip from "@mui/material/Chip";
import SubContractService from "../../accounts/contracts/sub-contracts/SubContractService";


function NewPaymentToCustomerPage(props) {

    const [inError, setInError] = useState(false);

    const classes = AppStyles();

    const {t} = useTranslation();

    const [expanded, setExpanded] = React.useState("null");

    function handleChange(panel) {

        if(panel === expanded){
            setExpanded("null")
        } else {
            setExpanded(panel);
        }

    }

    const [refresh, setRefresh] = useState(false);

    const [loading, setLoading] = useState(false);

    const [dataset, setDataset] = useState(null);

    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);

    const [subContract, setSubContract] = useState(null);
    const [subContracts, setSubContracts] = useState([]);

    const [currency, setCurrency] = useState(null);
    const [currencies, setCurrencies] = useState([]);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [codStatus, setCodStatus] = useState('bankAccountVerified');

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState(null);

    const [paymentDate, setPaymentDate] = useState(new Date());

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        setSubContract(null);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
        setSubContract(null);
    }

    function handleSubContractChange(event, subContract) {
        setSubContract(subContract);
    }

    function handleCurrencyChange(event, currency) {
        setCurrency(currency);
    }

    function handleCodStatusChange(event) {
        setCodStatus(event.target.value);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handleBankAccountChange(event, account) {
        setBankAccount(account);
    }

    function handleCompanyChange(event, company) {
        setCompany(company);
    }

    function handlePaymentDateChange(event) {
        setPaymentDate(event);
    }


    function fetchAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isCustomer: true})
            .then(data => {
                setAccounts(data)
            })
            .catch((err) => {

            })
    }

    function fetchContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (account != null) {
            filters.accountId = account.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setContracts(response);
            })

    }

    async function fetchSubContracts() {

        let filters = {}
        if (contract != null) {
            filters.contractId = contract.id
        }

        await new SubContractService().getSubContractsByFilters(filters)
            .then(response => {
                setSubContracts(response);
            })

    }

    function fetchCurrencies() {
        let shipmentService = new ShipmentService();
        shipmentService.getCurrencies()
            .then(currencies => {
                setCurrencies(currencies);
                setCurrency(currencies[0]);
            })
            .then(error => {

            })
    }

    function fetchCompanies() {
        let companyService = new CompanyService();
        companyService.getCompanies()
            .then(response => {
                setCompanies(response)
            })
            .catch(error => {

            })
    }

    async function fetchBankAccounts() {

        let filters = {};

        filters.companyId = company.id;

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {

                        })
                } else {

                }
            })
    }

    async function fetchCods() {

        setLoading(true);

        setExpanded("null")

        let filters = {};

        if (account) {
            filters.accountId = account.id;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        if (subContract) {
            filters.subContractId = subContract.id;
        }

        if (currency) {
            filters.currency = currency.code
        }

        filters.isInPaymentToCustomer = false;

        if (codStatus === 'bankAccountVerified') {
            filters.isBankAccountVerified = true;
            filters.hasEventWithCode = [2, 3, 4];

            if (dateFrom) {
                filters.bankAccountVerificationDateFrom = format(dateFrom, "yyyy-MM-dd");
            }
            if (dateTo) {
                filters.bankAccountVerificationDateTo = format(dateTo, "yyyy-MM-dd");
            }
        }
        if (codStatus === 'paidToCompany') {
            filters.isPaidToCompany = true;
            filters.hasEventWithCode = [2];
        }
        if (codStatus === 'warehouseVerified') {
            filters.isWarehouseVerified = true;
            filters.hasEventWithCode = [2];
        }
        if (codStatus === 'collected') {
            filters.hasEventWithCode = [2];
        }

        await new CodService().getCods(filters)
            .then(result => {

                const map = {};

                for (let i = 0; i < result.length; i++) {

                    let mapRecord = map[result[i].contractCode]

                    if (!mapRecord) {
                        mapRecord = {
                            error: null,
                            success: false,
                            allChecked: true,
                            contractCode: result[i].contractCode,
                            contractId: result[i].contractId,
                            accountCompanyName: result[i].accountCompanyName,
                            cods: []
                        };
                    }

                    let item = result[i];

                    item.checked = true;

                    mapRecord.cods.push(item);

                    map[result[i].contractCode] = mapRecord;
                }

                let data = [];

                for (const item in map) {

                    data.push(map[item]);

                }

                setDataset(data);

                setLoading(false);

            })
            .catch(error => {


                setLoading(false);

            })


    }

    function calculateDynamicTotal(dataset) {

        let total = 0;

        if (dataset) {
            for (let i = 0; i < dataset.length; i++) {

                for (let j = 0; j < dataset[i].cods.length; j++) {

                    if (dataset[i].cods[j].checked) {
                        total += dataset[i].cods[j].value;
                    }
                }

            }
        }


        return total;
    }


    function calculateTotal(cods) {

        let total = 0;

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked) {
                total += cods[i].value;
            }
        }

        return total;
    }

    function handleAllChecked(event, data) {
        data.allChecked = !!event.target.checked

        for (let i = 0; i < data.cods.length; i++) {
            data.cods[i].checked = !!event.target.checked;

        }

        setRefresh(!refresh);

    }

    function handleChecked(event, cod) {

        cod.checked = !!event.target.checked;

        // if (cods.some(row => row.checked === false)) {
        //     setAllChecked(false);
        // } else {
        //     setAllChecked(true);
        // }

        setRefresh(!refresh);

    }

    async function savePayments() {

        for (let i = 0; i < dataset.length; i++) {

            if(!dataset[i].success){
                let input = {};

                input.contractId = dataset[i].contractId;
                if (company) {
                    input.companyId = company.id;
                }

                if (bankAccount) {
                    input.companyBankAccountId = bankAccount.id;
                }

                if (currency) {
                    input.currencyId = currency.id;
                }

                if (paymentDate) {
                    input.dateTime = moment(paymentDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }


                let codIds = [];

                for (let j = 0; j < dataset[i].cods.length; j++) {
                    if (dataset[i].cods[j].checked) {
                        codIds.push(dataset[i].cods[j].id)
                    }
                }

                input.cods = codIds;

                if(input.cods.length > 0){
                    await new PaymentToCustomerService().newPaymentToCustomer(input)
                        .then(success => {
                            dataset[i].success = true;
                            dataset[i].error = null;
                        })
                        .catch(error => {
                            setInError(true);
                            error.response.json().then(response => {
                                setNotificationMessage(response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                                dataset[i].error = response.message;
                                setRefresh(!refresh);
                            })
                        })
                }
            }

        }

    setRefresh(!refresh);

    }


    useEffect(() => {
        if (currencies.length === 0) {
            fetchCurrencies();
        }
    }, [refresh])

    return <div className={classes.root}>
        <Card style={{width: "90%", margin: "auto"}}>
            <div className={classes.select_div}>
                <Autocomplete
                    id="account"
                    options={accounts}
                    className={classes.select}
                    getOptionLabel={option => option.companyName}
                    value={account}
                    defaultValue={account}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchAccounts}
                    onChange={handleAccountChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("account")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={account}
                            defaultValue={account}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="contract"
                    disabled={!account}
                    options={contracts}
                    className={classes.select}
                    getOptionLabel={option => option.code}
                    value={contract}
                    defaultValue={contract}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchContracts}
                    onChange={handleContractChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("contract")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={contract}
                            defaultValue={contract}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="contract"
                    disabled={!contract}
                    options={subContracts}
                    className={classes.select}
                    getOptionLabel={option => option.code}
                    value={subContract}
                    defaultValue={subContract}
                    noOptionsText={t("no_options_available")}
                    onOpen={fetchSubContracts}
                    onChange={handleSubContractChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("sub-contract")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={subContract}
                            defaultValue={subContract}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    id="currency"
                    options={currencies}
                    className={classes.select}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={currency}
                    defaultValue={currency}
                    noOptionsText={t("no_options_available")}
                    // onOpen={fetchCurrencies}
                    onChange={handleCurrencyChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("currency")}
                            InputLabelProps={{shrink: true}}
                            placeholder={t("any")}
                            variant="outlined"
                            margin="dense"
                            value={currency}
                            defaultValue={currency}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <FormControl className={classes.select_div}>
                <FormLabel style={{margin: "auto"}}>{t("cod_status")}</FormLabel>
                <RadioGroup
                    className={classes.flex_div}
                    value={codStatus}
                    onChange={handleCodStatusChange}
                >
                    <FormControlLabel value="bankAccountVerified" control={<Radio/>}
                                      label={t("bank_account_verified")}/>
                    {codStatus === "bankAccountVerified" ?

                        <div style={{display: "flex", margin: "auto", width: "100%"}}>
                            <DateField
                                style={{width: "30%", margin: "auto"}}
                                label={t("date_from")}
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={false}
                            />
                            <DateField
                                style={{width: "30%", margin: "auto"}}
                                label={t("date_to")}
                                value={dateTo}
                                onChange={handleDateToChange}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={false}
                            />
                        </div>
                        :
                        null

                    }
                    <FormControlLabel value="paidToCompany" control={<Radio/>} label={t("paid_to_company")}/>
                    <FormControlLabel value="warehouseVerified" control={<Radio/>} label={t("warehouse_verified")}/>
                    <FormControlLabel value="collected" control={<Radio/>} label={t("collected_filter")}/>
                </RadioGroup>
            </FormControl>
            <IconButton onClick={fetchCods}
                        disabled={loading}
                        style={{
                            display: "flex",
                            backgroundColor: 'transparent',
                            margin: "auto",
                            marginBottom: "2%"
                        }}>{loading ?
                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
        </Card>
        {loading ?

            <LoadingComponent/>
            :
            <div>

                {dataset && dataset.length > 0 ?
                    <div>
                        <Card style={{width: "90%", margin: "auto", marginTop: "2%"}}>
                            <DialogTitle
                                style={{width: "fit-content", margin: "auto"}}>{t("payment_data")}</DialogTitle>
                            <div>
                                <Autocomplete
                                    style={{width: "40%", margin: "auto"}}
                                    id="companies"
                                    size="small"
                                    options={companies}
                                    getOptionLabel={option => option.companyName}
                                    value={company}
                                    defaultValue={company}
                                    noOptionsText={t("no_options")}
                                    onOpen={fetchCompanies}
                                    onChange={handleCompanyChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("company")}
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            error={!company}
                                            value={company}
                                            defaultValue={company}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    style={{width: "40%", margin: "auto"}}
                                    id="bank-account"
                                    size="small"
                                    disabled={!company}
                                    options={bankAccounts}
                                    getOptionLabel={option => option.bankName + " - " + option.iban}
                                    value={bankAccount}
                                    defaultValue={bankAccount}
                                    noOptionsText={t("no_options")}
                                    onOpen={fetchBankAccounts}
                                    onChange={handleBankAccountChange}
                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("bank_account")}
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            error={!bankAccount}
                                            value={bankAccount}
                                            defaultValue={bankAccount}
                                        />
                                    )}
                                />
                            </div>
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("payment_date")}
                                value={paymentDate}
                                onChange={handlePaymentDateChange}
                                format={"dd/MM/yyyy"}
                                disablePast={true}
                            />

                            <AmountChip><Typography>{t("total")} {dataset ? dataset[0]?.cods[0]?.currency : null} {calculateDynamicTotal(dataset).toFixed(2)}</Typography></AmountChip>
                        </Card>


                        {dataset?.map((data, key) => {
                            return <Accordion style={{width: "90%", margin: "auto", marginTop: "1%"}}
                                              expanded={expanded === key.toString()}
                                              onChange={() => handleChange(key.toString())}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography sx={{width: '33%', flexShrink: 0}}>
                                        <Typography variant={"subtitle2"}>{data.accountCompanyName} - {data.contractCode} - {data.cods[0].currency} {calculateTotal(data.cods).toFixed(2)}</Typography>
                                    </Typography>
                                    {data.error ?
                                        <Chip style={{width: "fit-content", margin: "auto", marginBlock: "1%"}} icon={<ErrorOutlined style={{color: "red"}}/>} label={data.error}/>
                                        :
                                        null
                                    }
                                    {data.success?
                                        <Chip style={{width: "fit-content", margin: "auto", marginBlock: "1%"}} icon={<CheckCircleOutlined style={{color: "green"}}/>} label={t("successful")}/>
                                        :
                                        null
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    disabled={data.success}
                                                    color="primary"
                                                    onChange={event => handleAllChecked(event, data)}
                                                    checked={data.allChecked}/>
                                            </TableCell>
                                            <TableCell>
                                                {t("contract")}
                                            </TableCell>
                                            <TableCell>
                                                {t("shipment_id")}
                                            </TableCell>
                                            <TableCell>
                                                {t("reference")}
                                            </TableCell>
                                            <TableCell>
                                                {t("amount")}
                                            </TableCell>
                                            <TableCell>
                                                {t("last_event")}
                                            </TableCell>
                                        </TableRow>
                                        {data.cods.map(cod => {
                                            return <TableRow>
                                                <TableCell>
                                                    <Checkbox
                                                        disabled={data.success}
                                                        color="primary"
                                                        onChange={event => handleChecked(event, cod)}
                                                        checked={cod.checked}/>
                                                </TableCell>
                                                <TableCell>
                                                    {cod.contractCode}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.shipmentId}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.shipmentReference}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.currency} {cod.value.toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    {cod.events[cod.events.length - 1]?.eventCodeName}
                                                </TableCell>

                                            </TableRow>
                                        })}
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        })}
                        <div style={{width: "100%", display: "flex"}}>
                            <Button
                                style={{width: "fit-content", margin: "auto", marginBlock: "5%"}}
                                endIcon={<SaveOutlined/>}
                                onClick={savePayments}
                                disabled={!company || !bankAccount}
                                color="primary"
                                variant={"contained"}>
                                {t("save_payments")}
                            </Button>
                        </div>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }


            </div>
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>


}

export default NewPaymentToCustomerPage;