import UserService from "../../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../../api/ApiUtils";


export default class MessageTemplateService{


    getTemplateById(id){

        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/message-templates/${id}`;


        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getTemplates(filters) {


        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/message-templates`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getPreview(message){

        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: message

        };
        let pathVariable = `/api/v1/emails/preview`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    saveTemplate(template){
        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(template)

        };
        let pathVariable = `/api/v1/message-templates`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    editTemplate(templateId, template){
        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(template)

        };

        let pathVariable = `/api/v1/message-templates/${templateId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            // .then(response => response.json())
    }


    deleteTemplate(templateId){
        const userService = new UserService();
        const apiKey = userService.getApiKey();


        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };
        let pathVariable = `/api/v1/message-templates/${templateId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

}