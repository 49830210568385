import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

export default class CodService {
    getCodById(codId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cods/${codId}`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getEventsByCodId(codId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cods/${codId}/events`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    collectCod(codId, request) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(request)
        };
        let pathVariable = `/api/v1/cods/${codId}/collect`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCods(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/cods`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getReportBySupplier(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {
            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/cods/report/bySupplier`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCodsForPayment(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/cods/payment`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    verifyInWarehouse(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";

        let pathVariable = `/api/v1/cods/verify`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    verifyOnBankAccount(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";

        let pathVariable = `/api/v1/cods/bank-verify`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    verifyInAdminCenter(input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";

        let pathVariable = `/api/v1/cods/admin-center-verify`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    updateChequeData(codID, input) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";

        let pathVariable = `/api/v1/cods/${codID}/cheque`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deleteCod(codID) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/cods/${codID}`;

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    getReportCsv(input) {

        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";

        let pathVariable = `/api/v1/cods/report/bySupplier/csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

}