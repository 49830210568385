import {useEffect, useState} from "react";
import {DialogTitle, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";


function PostalCodeForm(props){

    const {t} = useTranslation();
    const [postalCode, setPostalCode] = useState('');


    function handleCodeChange(event){
        setPostalCode(event.target.value)
    }


    useEffect(()=>{

    })

    return<form>
        <DialogTitle>{t("postalcode_data")}</DialogTitle>

        <TextField
        margin="dense"
        variant="outlined"
        label={t("postalCode")}
        value={postalCode}
        onChange={handleCodeChange}
        />





    </form>

} export default PostalCodeForm;