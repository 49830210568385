import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import Timing from "./Timing";
import TimingService from "./TimingService";
import {
    Card,
    Container,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TextField,
    useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {PrivateRoute} from "../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Notification from "../components/notifications/Notification";
import TimingForm from "./TimingForm";
import {CircularProgress, DialogTitle, Typography} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import ContractService from "../accounts/contracts/ContractService";
import {CloudDownload, UploadFile} from "@mui/icons-material";
import {get_file_array} from "../utils/utils";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        // width: '100%',
    },
    table: {
        // margin: "auto"

    },
    cell: {
        width: "35%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

export default function TimingsTable(props) {
    const [fetching, setFetching] = useState(true);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [types, setTypes] = useState(["LAST_MILE", "HUB", "HAULING"]);
    const [type, setType] = useState(null);

    const [openImport, setOpenImport] = useState(false);

    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [loadingCsv, setLoadingCsv] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [timings, setTimings] = useState([]);

    const classes = useStyles();

    async function fetchCSV() {
        setLoadingCsv(true);

        let filters = {};

        if (type) {
            filters.type = type;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        await new TimingService().getCSV(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCsv(false);
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                        setLoadingCsv(false);
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
            });

    }


    function importTimingsFromCsv() {

    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };

    async function fetchContracts() {
        let contracts = await new ContractService().getContractsByFilters()
            .catch(error => {

            })
        setContracts(contracts);
    }

    function handleContractChange(event, contract) {
        setContract(contract)
    }

    function handleTypeChange(event, type) {
        setType(type);
    }


    function closeNotification() {
        setOpenNotify(false);
    }

    function goToTimingForm() {
        history.push("/timings/new")
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage(t("successful"));

        setRefreshTable(!refreshTable);

    }

    function clearFilters() {
        setType(null);
        setContract(null);

        setRefreshTable(!refreshTable)
    }

    async function readFile(event) {

        setOpenImport(true);

        let _file = event.target.files[0];

        if (_file) {
            let dataURI = await get_file_array(_file);

            let indexOfComma = dataURI.indexOf(',');

            let base64;

            if (indexOfComma === -1) {
                //TODO gestire errore
            } else {
                base64 = dataURI.substring(indexOfComma + 1);
            }

            let file = {};

            file.content = base64;

            await new TimingService().importTimingsFromCsv(file)
                .then(response => {
                    setNotifySeverity('success');
                    setNotificationMessage(t('successful'));
                    setOpenNotify(true);
                    setOpenImport(false);
                    setRefreshTable(!refreshTable);

                })
                .catch((err) => {
                    err.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                        setOpenImport(false);
                        // setRefreshTable(!refreshTable);
                    });
                })
        }

    }

    function fetchTimings() {

        setFetching(true);

        let filters = {};

        if (type) {
            filters.type = type;
        }

        if (contract) {
            filters.contractId = contract.id;
        }

        let timingService = new TimingService();
        timingService.getByFilters(page, rowsPerPage, filters)
            .then(timings => {
                setTimings(timings.timings);
                setPages(Array.from(Array(Math.ceil(timings.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                setFetching(false);
            })
    }

    useEffect(() => {
        fetchTimings();
    }, [location, page, rowsPerPage, refreshTable])

    return <Container className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/timings`}>
                {openImport ?
                    <LoadingComponent/>
                    :
                    <div>
                        <Card elevation={1} defaultExpanded={true}
                              style={{width: "60%", margin: "auto", marginBlock: "2%"}}>

                            <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                                <Typography variant={"button"}>{t("filters")}</Typography>
                            </DialogTitle>

                            <div style={{alignItems: 'center', display: "flex"}}>
                                <Autocomplete
                                    id="contracts"
                                    options={contracts}
                                    size={"small"}
                                    style={{
                                        margin: "auto",
                                        marginBottom: "2%",
                                        width: "30%"
                                    }}
                                    getOptionLabel={option => option.code + " - " + option.name}
                                    value={contract}
                                    defaultValue={contract}
                                    noOptionsText={t("no_options_available")}
                                    onOpen={fetchContracts}
                                    onChange={handleContractChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("contract")}
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            placeholder={t("any")}
                                            margin="dense"
                                            size={"small"}
                                            value={contract}
                                            defaultValue={contract}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />

                            </div>
                            <div style={{alignItems: 'center', display: "flex"}}>
                                <Autocomplete
                                    id="types"
                                    options={types}
                                    size={"small"}
                                    style={{
                                        margin: "auto",
                                        marginBottom: "2%",
                                        width: "30%"
                                    }}
                                    getOptionLabel={option => option}
                                    value={type}
                                    defaultValue={type}
                                    noOptionsText={t("no_options_available")}
                                    onChange={handleTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("type")}
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            placeholder={t("any")}
                                            margin="dense"
                                            size={"small"}
                                            value={type}
                                            defaultValue={type}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />

                            </div>
                            <div style={{textAlign: 'center'}}>
                                <Button style={{margin: "5%"}}
                                        onClick={clearFilters}>{t("cancel_filter")}</Button>
                                <Button style={{margin: "5%"}}
                                        onClick={() => setRefreshTable(!refreshTable)}>{t("apply_filter")}</Button>
                            </div>

                        </Card>
                        <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                                onClick={goToTimingForm}
                                className={classes.button}>
                            {t("create_new_timing")}
                        </Button>
                        <Button disabled={loadingCsv || timings.length < 1} onClick={fetchCSV}
                                variant={"contained"}
                                color={"primary"}
                                startIcon={loadingCsv ? <CircularProgress size={24}/> :
                                    <CloudDownload/>}>{loadingCsv ? t("please_wait") : t("download_csv")}</Button>
                        <input
                            accept="text/csv"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onInput={readFile}
                            style={{display: "none"}}
                        />
                        <label htmlFor="contained-button-file">
                            <Button disabled={loadingCsv || timings.length < 1}
                                    variant={"contained"}
                                    color={"primary"}
                                    component={"span"}
                                    className={classes.button}
                                    startIcon={loadingCsv ? <CircularProgress size={24}/> :
                                        <UploadFile/>}>{t("import_from_csv")}
                            </Button>
                        </label>
                        <TableContainer className={classes.container}>
                            {(fetching) ?
                                <Table>
                                    <LoadingComponent/>
                                </Table>

                                :
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableCell>{t("contract")}</TableCell>
                                        <TableCell>{t("warehouse")}</TableCell>
                                        <TableCell>{t("type")}</TableCell>
                                        <TableCell>{t("timing")}</TableCell>
                                        <TableCell>{t("areas")}</TableCell>
                                        <TableCell/>

                                    </TableHead>

                                    <TableBody>
                                        {timings.map((timing, index) => {
                                            return <Timing timing={timing} deleteCallback={deleteCallback}/>
                                        })}

                                    </TableBody>

                                    <Notification open={openNotify} severity={notifySeverity}
                                                  onClose={closeNotification}>{notificationMessage}</Notification>
                                </Table>
                            }

                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'}
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                }


            </PrivateRoute>

            <PrivateRoute strict path={`/timings/:id/edit/`} children={<TimingForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/timings/new/`}>
                <TimingForm timing={null}/>
            </PrivateRoute>

        </Switch>
    </Container>
}
