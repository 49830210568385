import React, {useState} from "react";
import {IconButton, Paper, TableRow, Typography} from "@mui/material";
import {Backdrop, Button, Fade, Modal, TableCell} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import VehicleService from "./VehicleService";
import {CancelOutlined, CheckCircleOutlined, DeleteForever, Edit} from "@material-ui/icons";
import {useHistory} from "react-router-dom";


function Vehicle(props) {

    const [vehicle, setVehicle] = useState(props.vehicle);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const history = useHistory();

    const {t} = useTranslation();

    function deleteVehicle(){

        const vehicleService = new VehicleService();
        vehicleService.deleteVehicle(vehicle.id)
            .then(result => {
                let response = {};
                response.message = t("successful");
                props.deleteCallback('success', response)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.deleteCallback('error', response)
                });
            })
            .finally(()=>{
                setOpenConfirmDialog(false);
            })
    }

    function goEditVehicle(){
        history.push(`/vehicles/${vehicle.id}/edit`)
    }

    return <TableRow>
        <TableCell>
            {vehicle.model}
        </TableCell>
        <TableCell>
            {vehicle.plate}
        </TableCell>
        <TableCell>
            {vehicle.warehouses?.map((warehouse, index) => {
                return <Typography variant={"subtitle2"}>{warehouse}</Typography>
            })}
        </TableCell>
        <TableCell>
            <IconButton onClick={goEditVehicle}><Edit/></IconButton>
            <IconButton onClick={()=>setOpenConfirmDialog(true)}><DeleteForever/></IconButton>
        </TableCell>
        <Modal open={openConfirmDialog}
            // className={classes.modal}
               onClose={() => setOpenConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openConfirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        {/*<WarningOutlined color={"warning"}/>*/}
                        <Typography style={{margin:"auto"}}>{t("confirm_vehicle_delete") + vehicle.model + " - " + vehicle.plate + "?"}</Typography>
                        {/*<WarningOutlined color={"warning"}/>*/}
                    </div>
                    <div style={{ display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"}}>
                        <Button
                            style={{marginInline: "4%",
                                width: "25%"}}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{marginInline: "4%",
                                width: "25%"}}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteVehicle}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
    </TableRow>


}

export default Vehicle;