import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Card,
    CardActionArea,
    CardContent,
    Container,
    createTheme,
    Dialog,
    Fade,
    Modal,
    Paper,
    responsiveFontSizes,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import logoTyp from '../images/TYP.jpg';
import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {
    ArrowBackIos,
    ArrowForwardIos,
    AssignmentTurnedIn,
    CheckCircle,
    ExpandMore,
    Explore,
    LocalShipping,
    Schedule,
    Warning
} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import LastMile from "./LastMile";
import clsx from "clsx";
import PropTypes from "prop-types";
import TrackingService from "./TrackingService";
import Notification from "../components/notifications/Notification";
import SlotService from "../districts/slot/SlotService";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import BookingService from "./BookingService";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import UserService from "../users/UserService";

let theme = createTheme({
    palette: {
        primary: {
            main: "#1B6031",
        },
        secondary: {
            main: "#00000f"
        }
    },
    typography: {
        fontFamily: [
            'Bebas Neue',
            'cursive',
        ].join(','),
    },
});
theme = responsiveFontSizes(theme);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        "@media (max-width: 449px)": {
            marginLeft: "41%"
        }
    },
    active: {
        backgroundImage:
            'linear-gradient( 15deg,#35BC64 0%,#35BC64 50%,#35BC64 100%)',
    },
    warning: {
        backgroundImage:
            'linear-gradient( 15deg,#f2fb66 0%,#f2fb66 50%,#f2fb66 100%)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 15deg,#35BC64 0%,#35BC64 50%,#35BC64 100%)',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "150%",
        maxWidth: 1024,
        textAlign: "center",
        backgroundColor: "#f6f6f4",
        margin: "auto"
    },
    img: {
        width: "100%",
        marginTop: "5%",
    },
    label: {
        width: "100%",
        // marginTop: "5%",
        paddingTop: "5%"
    },
    searchField: {
        marginTop: "5%",
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 50,
        margin: 6,
    },
    paper: {
        width: "100%",
        marginInline: "auto",
        marginTop: "5%",
        height: "100%",
        overflow: "hidden"
    },
    button: {
        width: "75%",
        backgroundColor: "lightgrey",
        marginBottom: "5%",
        marginTop: "5%"

    },
    cardWrap: {
        marginBottom: "2%",

    },
    card: {
        width: "100%",
        display: "inline",
    },
    itemImg: {
        width: "50%",
        marginTop: "5%",
    },
    typo: {
        marginTop: "1%",
        paddingBottom: "1%"
    },
    hidden: {
        display: "none"
    },
    stepperDiv: {
        display: "block",
        margin: "auto"
    },
    step: {
        marginBottom: "5%",
    },
    mobileStep: {
        display: "block",

    },
    cell: {
        width: "5%"
    },
    table: {
        margin: "auto"
    },
    calendar: {
        width: "90%",
        marginInline: "auto",
        marginTop: "15%",
        height: "100%",
        overflow: "hidden"
    }

}));

function TrackingPage(props) {
    const [trackingData, setTrackingData] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [companyName, setCompanyName] = useState("TYP")
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [calendar, setCalendar] = useState([]);
    const [districtId, setDistrictId] = useState('');

    const [serviceCode, setServiceCode] = useState('');
    const [address, setAddress] = useState({});

    const [bookingDate, setBookingDate] = useState(null);
    const [bookingSlot, setBookingSlot] = useState(null);

    const [calendarBatchCount, setCalendarBatchCount] = useState(0);

    const location = useLocation();

    const {id} = useParams();
    const guestKey = new URLSearchParams(location.search).get("_k");

    const [ID, setID] = useState(id);

    const isMobile = useMediaQuery('(min-width:450px)');
    const isUnderMobile = useMediaQuery('(min-width: 350px)');

    const [today, setToday] = useState(Date.now());
    const [registered, setRegistered] = useState([]);
    const [inTransit, setInTransit] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [delivered, setDelivered] = useState([]);

    function goToTrackingIndex() {
        history.push(`/tracking`);
    }

    function goToMap() {
        history.push({
            pathname: `/tracking/${ID}/lastmile`,
            search: `?_k=${guestKey}`
        })
    }

    function formatDate(date) {
        let parsedDate = new Date(date);

        return parsedDate.toLocaleDateString();

    }

    function openCalendar() {
        setCalendarOpen(true)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function fetchNewWeek(batch) {
        setLoading(true);

        let dates = [];

        for (let i = 0; i < 7; i++) {
            let date = today + (7 * batch * 86400000) + 86400000 + i * 86400000;
            let formattedDate = format(date, "yyyy-MM-dd");
            dates.push(formattedDate);
        }

        let slotService = new SlotService();
        if (guestKey) {
            slotService.getBookingSlotsByShipment(dates, id, guestKey)
                .then(data => {
                    let datesAndSlotsInputDto = [];


                    data.dateAndSlots.map(value => {
                        let auxDateAndSlots = {};
                        auxDateAndSlots.date = value.date;
                        auxDateAndSlots.slots = [];
                        value.slots.map(slot => {
                            let auxSlot = {};
                            auxSlot.fromTime = slot.fromTime;
                            auxSlot.toTime = slot.toTime;

                            auxDateAndSlots.slots.push(auxSlot);

                        })
                        datesAndSlotsInputDto.push(auxDateAndSlots);
                    })

                    setDistrictId(data.district.id)

                    slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                        .then(responseData => {
                            setCalendar(responseData);
                        })
                        .catch(error => {

                        })
                })
                .catch(error => {

                })
        } else if (validUser()) {
            slotService.getBookingSlotsByAddress(dates, serviceCode, address)
                .then(data => {
                    let datesAndSlotsInputDto = [];


                    data.dateAndSlots.map(value => {
                        let auxDateAndSlots = {};
                        auxDateAndSlots.date = value.date;
                        auxDateAndSlots.slots = [];
                        value.slots.map(slot => {
                            let auxSlot = {};
                            auxSlot.fromTime = slot.fromTime;
                            auxSlot.toTime = slot.toTime;

                            auxDateAndSlots.slots.push(auxSlot);

                        })
                        datesAndSlotsInputDto.push(auxDateAndSlots);
                    })

                    setDistrictId(data.district.id)

                    slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                        .then(responseData => {
                            setCalendar(responseData);
                        })
                        .catch(error => {

                        })
                })
                .catch(error => {
                    error.response.json().then(response => {
                        if (response.status === 404) {
                            setNotFound(true);
                        }
                        if (response.status === 401) {
                            setUnauthorized(true);
                        }
                    })
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 2000);

    }

    function setEvents() {

    }

    function createBooking(date, shipmentId, slotId, districtId, guestKey) {
        let bookingService = new BookingService();
        bookingService.createBooking(date, shipmentId, slotId, districtId, guestKey)
            .then(() => {
                setConfirmDialogOpen(false);
                setCalendarOpen(false);
                setSuccessDialogOpen(true);
                setRefresh(!refresh);

            })
            .catch(error => {
                error.response.json().then((response) => {

                })
            })
    }

    function confirmBooking(date, slot) {
        setBookingDate(date);
        setBookingSlot(slot);
        setConfirmDialogOpen(true);
    }

    function fetchPreviousWeek() {
        let batch = calendarBatchCount;
        batch -= 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function fetchNextWeek() {
        let batch = calendarBatchCount;
        batch += 1;
        setCalendarBatchCount(batch);
        fetchNewWeek(batch);
    }

    function fetchTracking(id) {
        let trackingService = new TrackingService();
        trackingService.getShipmentTracking(id, guestKey)
            .then(data => {
                setTrackingData(data)


                let dates = [];

                for (let i = 0; i < 7; i++) {
                    let date = today + (7 * calendarBatchCount * 86400000) + 86400000 + i * 86400000;
                    let formattedDate = format(date, "yyyy-MM-dd");
                    dates.push(formattedDate);
                }

                let address = {};
                if (data.deliveryAddress.country) {
                    address.country = data.deliveryAddress.country;
                }
                if (data.deliveryAddress.administrative_level_1) {
                    address.administrativeLevel1 = data.deliveryAddress.administrative_level_1;
                }
                if (data.deliveryAddress.administrative_level_2) {
                    address.administrativeLevel2 = data.deliveryAddress.administrative_level_2;
                }
                if (data.deliveryAddress.administrative_level_3) {
                    address.administrativeLevel3 = data.deliveryAddress.administrative_level_3
                }
                if (data.deliveryAddress.postalCode) {
                    address.postalCode = data.deliveryAddress.postalCode
                }
                if (data.deliveryAddress.city) {
                    address.city = data.deliveryAddress.city
                }

                let serviceCode = data.serviceCode;
                setServiceCode(data.serviceCode);
                setAddress(address);


                let slotService = new SlotService();
                if (guestKey) {
                    slotService.getBookingSlotsByShipment(dates, id, guestKey)
                        .then(data => {
                            let datesAndSlotsInputDto = [];


                            data.dateAndSlots.map(value => {
                                let auxDateAndSlots = {};
                                auxDateAndSlots.date = value.date;
                                auxDateAndSlots.slots = [];
                                value.slots.map(slot => {
                                    let auxSlot = {};
                                    auxSlot.fromTime = slot.fromTime;
                                    auxSlot.toTime = slot.toTime;

                                    auxDateAndSlots.slots.push(auxSlot);

                                })
                                datesAndSlotsInputDto.push(auxDateAndSlots);
                            })

                            setDistrictId(data.district.id)

                            slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                                .then(responseData => {
                                    setCalendar(responseData);
                                })
                                .catch(error => {

                                })
                        })
                        .catch(error => {

                        })
                } else if (validUser()) {
                    slotService.getBookingSlotsByAddress(dates, serviceCode, address)
                        .then(data => {
                            let datesAndSlotsInputDto = [];


                            data.dateAndSlots.map(value => {
                                let auxDateAndSlots = {};
                                auxDateAndSlots.date = value.date;
                                auxDateAndSlots.slots = [];
                                value.slots.map(slot => {
                                    let auxSlot = {};
                                    auxSlot.fromTime = slot.fromTime;
                                    auxSlot.toTime = slot.toTime;

                                    auxDateAndSlots.slots.push(auxSlot);

                                })
                                datesAndSlotsInputDto.push(auxDateAndSlots);
                            })

                            setDistrictId(data.district.id)

                            slotService.getBookingSlotsAvailability(data.district.id, datesAndSlotsInputDto)
                                .then(responseData => {
                                    setCalendar(responseData);
                                })
                                .catch(error => {

                                })
                        })
                }
            })
            .catch(error => {
                console.log(error);
                // error.response.json().then(response => {
                //     if (response.status === 404) {
                //         setNotFound(true);
                //     }
                //     if (response.status === 401) {
                //         setUnauthorized(true);
                //     }
                // })
            })
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }


    function validUser() {
        let userService = new UserService();

        return null != guestKey || userService.isLoggedIn();
    }

    function StepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const {active, completed, warning} = props;

        const icons = {
            1: <AssignmentTurnedIn/>,
            2: <LocalShipping/>,
            3: <Schedule/>,
            4: <CheckCircle/>
        };
        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                    [classes.warning]: warning,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    StepIcon.propTypes = {
        /**
         * Whether this step is active.
         */
        active: PropTypes.bool,
        /**
         * Mark the step as completed. Is passed to child components.
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    useEffect(() => {
        fetchTracking(id, guestKey);
    }, [id, refresh])

    return (

        <Switch>
            <MuiThemeProvider theme={theme}>

                <Container className={classes.root}>
                    {unauthorized ?

                        <Card style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: "25%"
                        }}>
                            <CardContent className={classes.card}>
                                <Typography className={classes.typo}><Warning style={{
                                    position: 'relative',
                                    top: '8px',
                                    right: '5px',
                                    color: "orangered"
                                }}/>{t("unauthorized")}<Warning style={{
                                    position: 'relative',
                                    top: '8px',
                                    left: '5px',
                                    color: "orangered"
                                }}/></Typography>
                            </CardContent>
                        </Card>


                        :
                        <div>
                            <Route path={"/tracking/:id"}>

                                <div className={classes.label}>
                                    <Typography variant="h4">{companyName}</Typography>
                                </div>
                                <img className={classes.img} src={logoTyp} alt={""}/>
                                <Paper className={classes.searchField}>
                                    <IconButton disabled aria-label="shipping">
                                        <LocalShipping/>
                                    </IconButton>
                                    <Typography>{t("shipment_number") + id}</Typography>
                                </Paper>
                                <Button className={classes.button} variant={"outlined"} onClick={goToTrackingIndex}>
                                    {t("go_to_new_tracking")}
                                </Button>
                                {notFound ?
                                    <div>

                                        <Typography className={classes.label}
                                                    variant="body1">{t("tracking_not_found") + ID}</Typography>
                                    </div>
                                    :

                                    <div className={classes.paper} aria-label={"tracking valid"}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >

                                                <Typography variant="h4">
                                                    {t("order_tracking")}
                                                </Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Paper className={classes.paper}>
                                                    <Stepper className={classes.mobileIcon}
                                                             activeStep={activeStep}
                                                             orientation="vertical"
                                                             connector={null}>
                                                        <Step className={classes.step} key={0}>
                                                            <StepLabel
                                                                className={isMobile ? null : classes.mobileStep}
                                                                StepIconComponent={StepIcon}>
                                                                {isUnderMobile ?
                                                                    <Accordion elevation={0}>
                                                                        <AccordionSummary
                                                                            disabled={registered.length === 0}

                                                                            expandIcon={<ExpandMore
                                                                                className={registered.length === 0 ? classes.hidden : null}/>}
                                                                        >
                                                                            <Typography variant="h5">
                                                                                {t("order_registered")}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <Table className={classes.table}
                                                                                   stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>{t("date")}</TableCell>
                                                                                        <TableCell>{t("time")}</TableCell>
                                                                                        <TableCell>{t("event")}</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {registered.map(status => {
                                                                                        return <TableRow>
                                                                                            <TableCell>{status.date}</TableCell>
                                                                                            <TableCell>{status.time}</TableCell>
                                                                                            <TableCell>{status.eventCodeName}</TableCell>
                                                                                        </TableRow>
                                                                                    })}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    :
                                                                    <Typography style={{marginTop: "5%"}}
                                                                                variant="h5">
                                                                        {t("order_registered")}
                                                                    </Typography>
                                                                }
                                                            </StepLabel>

                                                        </Step>
                                                        <Step className={classes.step} key={1}>
                                                            <StepLabel
                                                                className={isMobile ? null : classes.mobileStep}
                                                                StepIconComponent={StepIcon}>
                                                                {isUnderMobile ?
                                                                    <Accordion elevation={0}>
                                                                        <AccordionSummary
                                                                            disabled={inTransit.length === 0}

                                                                            expandIcon={<ExpandMore
                                                                                className={inTransit.length === 0 ? classes.hidden : null}/>}
                                                                        >

                                                                            <Typography variant="h5">
                                                                                {t("in_transit")}
                                                                            </Typography>

                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("date")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("time")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("event")}</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {inTransit.map(status => {
                                                                                        return <TableRow>
                                                                                            <TableCell>{status.date}</TableCell>
                                                                                            <TableCell>{status.time}</TableCell>
                                                                                            <TableCell>{status.eventCodeName}</TableCell>
                                                                                        </TableRow>
                                                                                    })}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    :
                                                                    <Typography style={{marginTop: "5%"}}
                                                                                variant="h5">
                                                                        {t("in_transit")}
                                                                    </Typography>

                                                                }
                                                            </StepLabel>
                                                        </Step>
                                                        <Step className={classes.step} key={2}>
                                                            <StepLabel
                                                                className={isMobile ? null : classes.mobileStep}
                                                                StepIconComponent={StepIcon}>
                                                                {isUnderMobile ?

                                                                    <Accordion elevation={0}>
                                                                        <AccordionSummary
                                                                            disabled={delivery.length === 0}

                                                                            expandIcon={<ExpandMore
                                                                                className={delivery.length === 0 ? classes.hidden : null}/>}
                                                                        >

                                                                            <Typography variant="h5">
                                                                                {t("delivery")}
                                                                            </Typography>

                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("date")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("time")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("event")}</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {delivery.map(status => {
                                                                                        return <TableRow>
                                                                                            <TableCell>{status.date}</TableCell>
                                                                                            <TableCell>{status.time}</TableCell>
                                                                                            <TableCell>{status.eventCodeName}</TableCell>
                                                                                        </TableRow>
                                                                                    })}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    :

                                                                    <Typography style={{marginTop: "5%"}}
                                                                                variant="h5">
                                                                        {t("delivery")}
                                                                    </Typography>
                                                                }
                                                            </StepLabel>
                                                        </Step>
                                                        <Step className={classes.step} key={3}>
                                                            <StepLabel
                                                                className={isMobile ? null : classes.mobileStep}
                                                                StepIconComponent={StepIcon}>
                                                                {isUnderMobile ?
                                                                    <Accordion elevation={0}>
                                                                        <AccordionSummary
                                                                            disabled={delivered.length === 0}

                                                                            expandIcon={<ExpandMore
                                                                                className={delivered.length === 0 ? classes.hidden : null}/>}
                                                                        >

                                                                            <Typography variant="h5">
                                                                                {t("delivered")}
                                                                            </Typography>

                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("date")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("time")}</TableCell>
                                                                                        <TableCell
                                                                                            className={classes.cell}>{t("event")}</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {delivered.map(status => {
                                                                                        return <TableRow>
                                                                                            <TableCell>{status.date}</TableCell>
                                                                                            <TableCell>{status.time}</TableCell>
                                                                                            <TableCell>{status.eventCodeName}</TableCell>
                                                                                        </TableRow>
                                                                                    })}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    :
                                                                    <Typography style={{marginTop: "5%"}}
                                                                                variant="h5">
                                                                        {t("delivered")}
                                                                    </Typography>
                                                                }
                                                            </StepLabel>
                                                        </Step>
                                                    </Stepper>
                                                    <Button
                                                        variant={"outlined"}
                                                        disabled={activeStep !== 2}
                                                        className={validUser() ? classes.button : classes.hidden}
                                                        endIcon={<Explore/>}
                                                        onClick={goToMap}
                                                    >
                                                        {t("last_mile_status")}
                                                    </Button>


                                                </Paper>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore/>}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                            >
                                                <Typography variant="h4">
                                                    {t("delivery_details")}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Paper className={classes.paper}>
                                                    <Typography className={classes.typo} variant="h6">
                                                        {t("delivery_scheduled")}
                                                    </Typography>
                                                    <Typography className={classes.typo} variant="h3">
                                                        {trackingData.bookingOutputDto ? formatDate(Date.parse(trackingData.bookingOutputDto.bookingDate)) : t("no_schedule")}
                                                    </Typography>
                                                    <Typography
                                                        className={validUser() ? classes.typo : classes.hidden}
                                                        variant="h6">
                                                        {t("want_to_schedule_a_new_booking")}
                                                    </Typography>
                                                    <Button
                                                        className={validUser() ? classes.button : classes.hidden}
                                                        endIcon={<Schedule/>}
                                                        onClick={openCalendar}
                                                        variant={"outlined"}
                                                    >

                                                        {t("book")}

                                                    </Button>
                                                    <Modal
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        className={classes.modal}
                                                        open={isCalendarOpen}
                                                        onClose={() => setCalendarOpen(false)}
                                                        closeAfterTransition
                                                        BackdropComponent={Backdrop}
                                                        BackdropProps={{
                                                            timeout: 500,
                                                        }}
                                                    >
                                                        {/*aaa*/}
                                                        <Fade in={isCalendarOpen}>
                                                            <div className={classes.calendar}>
                                                                {loading ?
                                                                    <LoadingComponent/>
                                                                    :
                                                                    <Paper elevation={3}>
                                                                        <DialogTitle style={{
                                                                            textAlign: "center",
                                                                            paddingTop: "3%"
                                                                        }}><Typography
                                                                            variant={"h4"}>{t("calendar_title")}</Typography>
                                                                        </DialogTitle>
                                                                        <div style={{display: "flex"}}>
                                                                            <Button
                                                                                disabled={calendarBatchCount === 0}
                                                                                startIcon={<ArrowBackIos/>}
                                                                                onClick={fetchPreviousWeek}/>
                                                                            <Table
                                                                                className={classes.paper}>
                                                                                {calendar.map((dateAndSlot, i) =>
                                                                                    <TableCell
                                                                                        style={{width: "5%"}}>
                                                                                        <div>
                                                                                            <Typography
                                                                                                align="center">{formatDate(dateAndSlot.date)}</Typography>
                                                                                        </div>
                                                                                        {dateAndSlot.availableSlots.map((slots, index) => {
                                                                                                return <Card
                                                                                                    style={{
                                                                                                        marginTop: "15%",
                                                                                                        backgroundColor: slots.available ? `#35BC64` : "lightgrey"
                                                                                                    }}>
                                                                                                    <CardActionArea
                                                                                                        onClick={() => confirmBooking(dateAndSlot.date, slots.slot)}
                                                                                                        disabled={!slots.available}>
                                                                                                        <Typography
                                                                                                            align={"center"}>
                                                                                                            {t("fromTime") + slots.slot.fromTime}
                                                                                                        </Typography>
                                                                                                        <Typography
                                                                                                            align={"center"}>
                                                                                                            {t("toTime") + slots.slot.toTime}
                                                                                                        </Typography>
                                                                                                        <Typography
                                                                                                            align={"center"}>
                                                                                                            {slots.available ? t("available") : t("not_available")}
                                                                                                        </Typography>
                                                                                                    </CardActionArea>
                                                                                                </Card>
                                                                                            }
                                                                                        )}
                                                                                    </TableCell>
                                                                                )}
                                                                            </Table>
                                                                            <Button startIcon={
                                                                                <ArrowForwardIos/>}
                                                                                    onClick={fetchNextWeek}/>

                                                                        </div>
                                                                    </Paper>
                                                                }
                                                            </div>
                                                        </Fade>
                                                    </Modal>
                                                </Paper>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Card>
                                            <CardContent className={classes.card}>
                                                <Typography className={classes.typo} variant="h6">
                                                    {t("delivery_address")}
                                                </Typography>
                                                <Typography display="block" className={classes.typo}
                                                            variant="h6">
                                                    {address.postalCode}
                                                </Typography>
                                                <Typography display="block" className={classes.typo}
                                                            variant="h6">
                                                    {address.city + " (" + address.administrativeLevel3 +")"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                }
                                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                              onClose={closeNotification}>{notificationMessage}</Notification>
                                <ConfirmDialog open={isConfirmDialogOpen}
                                               confirmText={t("confirm")}
                                               cancelText={t("cancel")}
                                               onConfirm={() => createBooking(bookingDate, id, bookingSlot.id, districtId, guestKey)}
                                               onCancel={() => setConfirmDialogOpen(false)}>
                                    {t("confirm_booking_msg")}
                                </ConfirmDialog>
                                <Dialog
                                    open={isSuccessDialogOpen}
                                    onClose={() => setSuccessDialogOpen(false)}
                                >
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {t("booking_success_msg")}
                                        </DialogContentText>
                                    </DialogContent>

                                </Dialog>


                            </Route>

                            <Route path={`/tracking/:id/lastmile`}>
                                <LastMile/>
                            </Route>
                        </div>
                    }

                </Container>

            </MuiThemeProvider>

        </Switch>

    )


}

export default TrackingPage;