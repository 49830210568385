import {Paper, TableCell, TableRow} from "@material-ui/core";
import {DialogTitle, IconButton, Table, TableHead, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import RouteService from "../routes/RouteService";
import WarehouseService from "../../warehouses/WarehouseService";
import TripService from "../trips/TripService";
import ParcelService from "../../shipments/services/ParcelService";
import Notification from "../../components/notifications/Notification";
import moment from "moment";
import ShipmentService from "../../shipments/ShipmentService";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import theme from "../../theme/theme";
import {CancelRounded} from "@material-ui/icons";
import InfoChip from "../../components/chips/InfoChip";
import LoadingListsService from "../../loading_lists/LoadingListsService";


function DeparturesPage(props) {

    const {t} = useTranslation();

    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [destinationWarehouse, setDestinationWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [shipmentData, setShipmentData] = useState([]);
    const [loadingLists, setLoadingLists] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [greenBarcodes, setGreenBarcodes] = useState([]);
    const [unknownBarcodes, setUnknownBarcodes] = useState([]);

    const [dateTime, setDateTime] = useState(new Date());

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [route, setRoute] = useState(null);
    const [routes, setRoutes] = useState([]);

    const [trip, setTrip] = useState(null);
    const [trips, setTrips] = useState([]);

    const [barcode, setBarcode] = useState('');

    async function fetchWarehouses(showAll) {

        setWarehouses([]);

        let filters = {};

        if (showAll === true) {
            filters.showAll = true;
        }

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(response => {
                setWarehouses(response.warehouses)
                if(response.warehouses?.length === 1){
                    setDepartureWarehouse(response.warehouses[0]);
                }
            })
            .catch(error => {

            })
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    async function fetchRoutes() {

        let filters = {};

        filters.departureWarehouseId = departureWarehouse.id;
        filters.destinationWarehouseId = destinationWarehouse.id;
        filters.deleted = false;

        await new RouteService().getRoutesForDeparture(filters)
            .then(response => {
                setRoutes(response)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchTrips() {

        let filters = {};

        if (null !== route) {
            filters.routeId = route.id;
        }

        await new TripService().getTrips(filters)
            .then(response => {
                setTrips(response.trips);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    async function setData(tripId) {
        setLoading(true);

        let shipmentData = [];

        let greens = [];

        let parcels = [];

        await new TripService().getParcelsInTrip(tripId)
            .then(result => {
                parcels = result;
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

        for (let i = 0; i < parcels?.length; i++) {
            let parcelService = new ParcelService();
            let parcel = null;
            parcel = await parcelService.getParcelById(parcels[i].id)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })


            if (shipmentData.some(data => {
                return data.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode.toUpperCase() === parcel.barcode.toUpperCase());
                })
            })) {
                shipmentData.map(data => {
                    data.parcels.map(async _parcel => {
                        if (_parcel.parcel.barcode.toUpperCase() === parcel.barcode.toUpperCase()) {

                            let greenData = {};
                            greenData.barcode = parcel.barcode;
                            greenData.customerBarcode = parcel.customerBarcode;
                            greens.push(greenData);
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                })
            } else {
                let shipmentService = new ShipmentService();
                let shipmentId = parcel?.shipmentId;
                if (parcel) {
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage(response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let data = {};
                    data.shipment = shipment;
                    data.parcels = [];
                    for (let i = 0; i < parcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = parcels[i];
                        if (parcels[i].barcode.toUpperCase() === parcel.barcode.toUpperCase()) {
                            let greenData = {};
                            greenData.barcode = parcel.barcode;
                            greenData.customerBarcode = parcel.customerBarcode;
                            greens.push(greenData);
                            parcelData.checked = true;
                            setRefresh(!refresh);

                        }

                        data.parcels.push(parcelData);
                    }

                    shipmentData = ([...shipmentData, data]);
                }
            }


            setRefresh(!refresh);

        }

        setShipmentData(shipmentData);
        setGreenBarcodes(greens);
        setFetched(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)

    }

    async function addParcelsToTrip(barcode) {

        let parcelService = new ParcelService();
        let parcel = null;
        parcel = await parcelService.getParcelByBarcode(barcode)
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

        if (shipmentData.some(data => {
            return data.parcels.some(parcel => {
                return (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase());
            })
        })) {
            shipmentData.map(data => {
                data.parcels.map(async parcel => {
                    if (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase()) {
                        let input = {};
                        let parcels = [];

                        parcels.push(parcel.parcel.id);

                        input.parcels = parcels;
                        input.warehouseCode = departureWarehouse.code;
                        input.destinationWarehouseCode = destinationWarehouse.code;
                        input.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

                        new TripService().addParcelsToTrip(trip.id, input)
                            .then(result => {
                                let greens = greenBarcodes;
                                let greenData = {};
                                parcel.checked = true;
                                greenData.barcode = barcode;
                                greenData.customerBarcode = null;
                                greens.push(greenData);
                                setGreenBarcodes(greens);
                                setNotificationMessage(t("successful"));
                                setNotifySeverity('success');
                                setOpenNotify(true);
                            })
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage(response.status + " - " + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                    let errbarcodes = unknownBarcodes;
                                    if (!errbarcodes.some(brco => brco.toUpperCase() === barcode.toUpperCase())) {
                                        errbarcodes.push(barcode);
                                    }
                                    setUnknownBarcodes(errbarcodes);
                                })
                            })
                    }
                })
            })
        } else {
            let shipmentService = new ShipmentService();
            let shipmentId = parcel?.shipmentId;
            if (parcel) {
                let shipment = await shipmentService.getShipmentById(shipmentId, null)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage(response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let data = {};
                data.shipment = shipment;
                data.parcels = [];
                for (let i = 0; i < parcels?.length; i++) {
                    let parcelData = {};
                    parcelData.parcel = parcels[i];
                    if (parcels[i].barcode.toUpperCase() === barcode.toUpperCase() || parcels[i].customerBarcode.toUpperCase() === barcode.toUpperCase()) {
                        let input = {};
                        let parcels = [];

                        parcels.push(parcel.id);

                        input.parcels = parcels;
                        input.warehouseCode = departureWarehouse.code;
                        input.destinationWarehouseCode = destinationWarehouse.code;
                        input.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

                        new TripService().addParcelsToTrip(trip.id, input)
                            .then(result => {
                                let greens = greenBarcodes;
                                let greenData = {};
                                parcelData.checked = true;
                                greenData.barcode = barcode;
                                greenData.customerBarcode = null;
                                greens.push(greenData);
                                setGreenBarcodes(greens);
                                setNotificationMessage(t("successful"));
                                setNotifySeverity('success');
                                setOpenNotify(true);
                            })
                            .catch(error => {
                                error.response.json().then(response => {
                                    setNotificationMessage(response.status + " - " + response.message);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                    let errbarcodes = unknownBarcodes;
                                    if (!errbarcodes.some(brco => brco.toUpperCase() === barcode.toUpperCase())) {
                                        errbarcodes.push(barcode);
                                    }
                                    setUnknownBarcodes(errbarcodes);
                                })
                            })
                    } else {
                        parcelData.checked = false;
                    }

                    data.parcels.push(parcelData);
                }

                setShipmentData([...shipmentData, data]);
            }

        }


    }

    async function fetchData() {

        setLoading(true);


        let loadingList = null;

        await new LoadingListsService().getLoadingListByBarcode(barcode)
            .then(result => {
                loadingList = result;
            })
            .catch(error => {

            })

        if (loadingList !== null) {

            let input = {};

            input.barcode = barcode;
            input.warehouseCode = departureWarehouse.code;
            input.destinationWarehouseCode = destinationWarehouse.code;
            input.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

            await new TripService().addLoadingListToTrip(trip.id, input)
                .then(result => {
                    let greens = greenBarcodes;
                    let greenData = {};
                    greenData.barcode = barcode;
                    greenData.customerBarcode = null;
                    greens.push(greenData);

                    let lists = loadingLists;
                    lists.push(barcode);
                    setLoadingLists(lists);

                    setGreenBarcodes(greens);
                    setNotificationMessage(t("successful"));
                    setNotifySeverity('success');
                    setOpenNotify(true);
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })

            // let parcels = await new LoadingListsService().getLoadingListParcels(loadingList)
            //     .catch(error => {
            //         error.response.json().then(response => {
            //             setNotificationMessage(response.status + " - " + response.message);
            //             setNotifySeverity('error');
            //             setOpenNotify(true);
            //         })
            //     })
            //
            // if(null !== parcels){
            //     for (let i = 0; i < parcels.length; i++) {
            //
            //         let barcode = parcels[i].barcode;
            //
            //         await addParcelsToTrip(barcode);
            //     }
            // }


        } else {
            await addParcelsToTrip(barcode)
        }

        setRefresh(!refresh);
        setTimeout(() => {
            setLoading(false);
        }, 500)


    }

    function calcSumOfParcels(parNum) {

        shipmentData.map(shipmentData => {
            shipmentData.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum += 1;
                }
            })
        })

        return parNum;

    }

    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
        setRoutes([]);
        setRoute(null);
    }

    function handleDestinationWarehouseChange(event, warehouse) {
        setDestinationWarehouse(warehouse);
        setRoutes([]);
        setRoute(null);
    }

    async function handleTripChange(event, trip) {
        setTrip(trip);

        if (null !== trip) {
            await setData(trip.id);

            let greens = greenBarcodes;

            let ll = [];

            for (let i = 0; i < trip.loadingLists?.length; i++) {


                ll.push(trip.loadingLists[i]);

                let greenData = {};
                greenData.barcode = trip.loadingLists[i];
                greenData.customerBarcode = trip.loadingLists[i];
                greens.push(greenData);
            }

            setGreenBarcodes(greens);

            setLoadingLists(ll);

        } else {
            setShipmentData([]);
            setFetched(false);
            setLoading(false)
        }


    }

    function handleRouteChange(event, route) {
        setRoute(route);
        setTrips([]);
        setTrip(null);

    }

    async function removeParcel(parcel) {
        setLoading(true);

        let input = {};

        input.parcels = [];

        input.parcels.push(parcel.parcel.id);

        new TripService().removeParcelFromTrip(trip.id, input)
            .then(response => {
                let greens = greenBarcodes;
                let green = greens.filter(green => green.barcode.toUpperCase() === parcel.parcel.barcode.toUpperCase())[0];
                greens.splice(greens.indexOf(green, 1));
                setGreenBarcodes(greens);
                parcel.checked = false;
                for (let i = 0; i < shipmentData.length; i++) {
                    if (!shipmentData[i].parcels.some(parcelData =>
                        parcelData.checked === true
                    )) {
                        shipmentData.splice(i, 1);
                    }
                }
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

        setLoading(false);
    }

    async function removeLoadingList(barcode) {
        setLoading(true);

        let input = {};
        input.barcode = barcode;

        await new TripService().removeLoadingListFromTrip(trip.id, input)
            .then(response => {
                let greens = greenBarcodes;
                let green = greens.filter(green => green.barcode.toUpperCase() === barcode.toUpperCase())[0];

                greens.splice(greens.indexOf(green, 1));

                setGreenBarcodes(greens)

                for (let i = 0; i < loadingLists.length; i++) {
                    if (loadingLists[i].toUpperCase() === barcode.toUpperCase()
                    ){
                        loadingLists.splice(i, 1);
                    }
                }

                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setRefresh(!refresh);
            })
            .catch(error => {
                // error.response.json().then(response => {
                //     setNotificationMessage(response.status + "-" + response.message);
                //     setNotifySeverity('error');
                //     setOpenNotify(true);
                // })
            })

        setLoading(false);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value.trim())
    }

    async function handleKeyDown(event) {

        if (event.code === "Enter" && barcode !== '') {

            if (greenBarcodes.some(green => green.barcode?.toUpperCase() === barcode.toUpperCase() || green.customerBarcode?.toUpperCase() === barcode.toUpperCase())) {
                setNotificationMessage(t("already_in_list"));
                setNotifySeverity('warning');
                setOpenNotify(true);
                setBarcode('');
            } else {

                await fetchData();
                // await addParcelsToTrip();

                setBarcode('');
            }
        }
    }

    useEffect(() => {

        fetchWarehouses(false);

    }, [])

    useEffect(()=>{

    }, [refresh])

    return <Paper>
        <div style={{display: "flex"}}>
            <DialogTitle style={{margin: "auto"}}>{t("departure")}</DialogTitle>
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                id="warehouse"
                options={warehouses}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => option.code + " - " + option.name}
                value={departureWarehouse}
                defaultValue={departureWarehouse}
                noOptionsText={t("no_options_available")}
                onOpen={() => fetchWarehouses(false)}
                onChange={handleDepartureWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("departure_warehouse")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={departureWarehouse}
                        defaultValue={departureWarehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                id="warehouse"
                options={warehouses}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => option.code + " - " + option.name}
                value={destinationWarehouse}
                defaultValue={destinationWarehouse}
                noOptionsText={t("no_options_available")}
                onOpen={() => fetchWarehouses(true)}
                onChange={handleDestinationWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("destination_warehouse")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={destinationWarehouse}
                        defaultValue={destinationWarehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                id="routes"
                disabled={!departureWarehouse || !destinationWarehouse}
                options={routes}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => option.name}
                value={route}
                defaultValue={route}
                noOptionsText={t("no_options_available")}
                onOpen={fetchRoutes}
                onChange={handleRouteChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("route")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={route}
                        defaultValue={route}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                disabled={!route}
                id="trips"
                options={trips}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => t("trip") + " " + option.id + " " + t("of") + " " + new Date(option.departureDateTime).toLocaleDateString() + " - " + option.supplierAcc + " " + option.driverName + " " + option.plateNumber}
                value={trip}
                defaultValue={trip}
                noOptionsText={t("no_options_available")}
                onOpen={fetchTrips}
                onChange={handleTripChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("trip")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={trip}
                        defaultValue={trip}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        {/*<DateField*/}
        {/*    style={{margin: "auto", width: "15%", marginBlock: "2%"}}*/}
        {/*    label={t("date")}*/}
        {/*    value={dateTime}*/}
        {/*    onChange={handleDateTimeChange}*/}
        {/*    format={"dd/MM/yyyy"}*/}
        {/*    disableFuture={false}*/}
        {/*    clearable={false}*/}
        {/*/>*/}
        <div style={{width: "100%", marginBottom: "3%", display: "flex"}}>
            <TextField
                style={{width: "20%", margin: "auto", marginBlock: "2%"}}
                label={t("barcode")}
                variant="outlined"
                margin="dense"
                value={barcode}
                defaultValue={barcode}
                disabled={loading}
                onChange={handleBarcodeChange}
                // onPaste={handleBarcodePaste}
                onKeyDown={handleKeyDown}
            />
        </div>
        {fetched ?
            <div>
                {loading ?

                    <LoadingComponent/>

                    :
                    <div>

                        {shipmentData.length > 0 || unknownBarcodes.length > 0 ?
                            <div>
                                <Table style={{width: "20%", margin: "auto"}}>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                            <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("loading_lists")}</Typography></TableCell>
                                            <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                                            <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                                variant={"button"}
                                                alignSelf={"center"}
                                                fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                                            {/*{pickupData.length > 0 ?*/}
                                            {/*    <TableCell style={{border: "2px solid", width: "50%"}}><Typography*/}
                                            {/*        variant={"button"}*/}
                                            {/*        alignSelf={"center"}*/}
                                            {/*        fontWeight={"bold"}>{t("pickups")}</Typography></TableCell>*/}
                                            {/*    : null}*/}
                                            {unknownBarcodes.length > 0 ?
                                                <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("parcels_in_error")}</Typography></TableCell>
                                                : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableRow>
                                        <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{loadingLists.length}</Typography></TableCell>
                                        <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{shipmentData.length}</Typography></TableCell>
                                        <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                            variant={"button"}
                                            style={{
                                                fontWeight: 'bold',
                                                margin: "auto",
                                                fontSize: 30
                                            }}>{calcSumOfParcels(0)}</Typography></TableCell>
                                        {/*{pickupData.length > 0 ?*/}
                                        {/*    <TableCell style={{border: "2px solid", width: "50%"}}><Typography*/}
                                        {/*        variant={"button"}*/}
                                        {/*        style={{*/}
                                        {/*            fontWeight: 'bold',*/}
                                        {/*            margin: "auto",*/}
                                        {/*            fontSize: 30*/}
                                        {/*        }}>{pickupData.length}</Typography></TableCell>*/}
                                        {/*    : null}*/}
                                        {unknownBarcodes.length > 0 ?
                                            <TableCell style={{border: "2px solid", width: "25%"}}><Typography
                                                variant={"button"}
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                    fontSize: 30
                                                }}>{unknownBarcodes.length}</Typography></TableCell>
                                            : null}
                                    </TableRow>
                                </Table>
                                <div style={{paddingBottom: "5%"}}>
                                    <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                                        <TableHead>
                                            <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    <Typography variant={"button"} style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                    }}>{t("shipment_details")}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    <Typography variant={"button"} style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                    }}> {t("parcels")}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {shipmentData.map(data => {
                                            return <TableRow>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("destination")} : {data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    {data.parcels.map(parcel => {
                                                        return <div style={{display: "flex", alignItems: "center"}}>
                                                            <Typography
                                                                color={parcel.checked ? "green" : "darkgrey"}>{parcel.parcel.barcode + (parcel.parcel.customerBarcode ? " - " + parcel.parcel.customerBarcode : null)}</Typography>
                                                            <IconButton
                                                                style={{display: parcel.checked ? null : "none"}}
                                                                color={'error'}
                                                                size={"small"}
                                                                onClick={() => removeParcel(parcel)}><CancelRounded/></IconButton>
                                                        </div>
                                                    })}
                                                </TableCell>

                                            </TableRow>
                                        })}
                                        {unknownBarcodes.length > 0 ?
                                            <TableRow>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    <Typography
                                                        variant={"subtitle2"}>{t("parcels_in_error")}</Typography>
                                                </TableCell>
                                                <TableCell style={{border: "2px solid", width: "50%"}}>
                                                    {unknownBarcodes.map(barcode => {
                                                        return <div style={{display: "flex", alignItems: "center"}}>
                                                            <Typography
                                                                color={"darkgrey"}>{barcode}</Typography>
                                                        </div>
                                                    })}
                                                </TableCell>

                                            </TableRow>
                                            : null}
                                    </Table>
                                </div>
                            </div>
                            :
                            <InfoChip>{t("no_parcels")}</InfoChip>
                        }
                        {loadingLists.length > 0 ?
                            <div>
                                <Table style={{width: "20%", margin: "auto"}}>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography variant={"button"} style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                }}>{t("loading_lists")}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography variant={"button"} style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                }}> {t("barcode")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {loadingLists.map(data => {
                                        return <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <Typography
                                                    variant={"subtitle2"}>{t("loading_list")}</Typography>
                                            </TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}>
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <Typography
                                                        color={"green"}>{data}</Typography>
                                                    <IconButton
                                                        color={'error'}
                                                        size={"small"}
                                                        onClick={() => removeLoadingList(data)}><CancelRounded/></IconButton>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    })}
                                </Table>
                            </div>
                            :
                            null
                        }
                    </div>
                }
            </div>
            :
            null
        }
        <Notification open={openNotify} severity={notifySeverity} duration={4000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </Paper>

}

export default DeparturesPage;