import React, {useEffect, useState} from "react";
import {AppStyles} from "../../theme/AppStyles";
import {
    Autocomplete,
    Card,
    CircularProgress,
    DialogTitle,
    IconButton,
    TableBody,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import CompanyService from "../../companies/CompanyService";
import BankAccountService from "../../bank_accounts/BankAccountService";
import AccountService from "../../accounts/AccountService";
import WarehouseService from "../../warehouses/WarehouseService";
import {Button, Checkbox, Divider, Grid, Slide, Table, TableCell, TableHead, TablePagination} from "@material-ui/core";
import DateField from "../../components/fields/DateField";
import moment from "moment";
import PaymentSlipsService from "./PaymentSlipsService";
import CodService from "../../shipments/services/CodService";
import InfoChip from "../../components/chips/InfoChip";
import {
    AddCircleOutlined,
    CheckCircleOutline,
    CheckOutlined,
    ErrorOutline,
    RemoveCircleOutlined,
    SearchOutlined
} from "@material-ui/icons";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Notification from "../../components/notifications/Notification";
import BankTransactionService from "../../bank_transactions/BankTransactionService";
import ContractService from "../../accounts/contracts/ContractService";


function VerifyPaymentSlipPage(props) {

    const classes = AppStyles();

    const {t} = useTranslation();

    const [cods, setCods] = useState([]);

    const [codsTotal, setCodsTotal] = useState(0.00);

    const [iban, setIban] = useState("");

    const [shipmentIdFilter, setShipmentIdFilter] = useState('');

    const [allChecked, setAllChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [company, setCompany] = useState(null);
    const [bankAccount, setBankAccount] = useState(null);

    const [supplier, setSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));
    const [dateTo, setDateTo] = useState(moment());

    const [paymentSlips, setPaymentSlips] = useState([]);
    const [paymentSlip, setPaymentSlip] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [transactions, setTransactions] = useState([]);

    const [totalExistingTransactions, setTotalExistingTransactions] = useState(0);

    function getCodsIdList() {

        let list = "";

        cods.map(cod => {
            if (cod.checked) {
                list += cod.cod.id + " ";
            }
        })


        return list;
    }


    async function addTransaction() {

        let filters = {};

        filters.companyId = paymentSlip?.companyId;

        let bankAccounts = await new BankAccountService().getBankAccountsByFilters(filters)
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })


        let totalOfActualTransactions = 0;

        transactions.map(transaction => totalOfActualTransactions += transaction.amount);

        let cin;
        let bankCode;
        let branchCode;
        let accountNumber;

        if (iban !== "" && iban.length === 27) {
            cin = iban.substr(2, 3);
            bankCode = iban.substr(5, 5);
            branchCode = iban.substr(10, 5);
            accountNumber = iban.substr(15, 12);
        }

        let transaction = {
            valueDate: null,
            accountingDate: null,
            senderControlInternalNumber: cin,
            senderBankCode: bankCode,
            senderBranchCode: branchCode,
            senderAccountNumber: accountNumber,
            reason: "Saldo distinta " + getCodsIdList(),
            reference: null,
            chequeNumber: null,
            amount: paymentSlip ? paymentSlip.totalPaid - totalExistingTransactions - totalOfActualTransactions : 0,
            company: companies.length >= 1 ? companies.filter(company => company.id === paymentSlip?.companyId)[0] : null,
            bankAccount: bankAccounts.length >= 1 ? bankAccounts.filter(bankAccount => bankAccount.id === paymentSlip?.bankAccountId)[0] : null
        }

        setTransactions([...transactions, transaction]);

        // console.log(transactions);
    }

    function removeTransaction(index) {

        transactions.splice(index, 1);
        setRefresh(!refresh);

    }


    async function fetchSuppliers() {

        let filters = {};
        filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(response => {
                setSuppliers(response);
            })


    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleChecked(event, key) {

        cods
            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
            // .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            // .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].checked = event.target.checked;

        if (cods.some(row => row.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }

    async function fetchCods() {

        let filters = {};

        filters.paymentSlipId = paymentSlip?.id
        filters.isBankAccountVerified = false;

        setTransactions([]);

        await new CodService().getCods(filters)
            .then(result => {

                let data = [];

                for (let i = 0; i < result.length; i++) {
                    let codData = {};
                    codData.checked = true;
                    codData.cod = result[i];

                    data.push(codData);
                }

                setAllChecked(true);

                setCods(data);
                setLoading(false);
                setFetched(true);
                setRefresh(!refresh);
            })
            .catch(error => {

            })
    }


    function setAllPending(bool) {

        cods.forEach(value => value.pending = bool);
    }

    async function getTransactions(paymentSlip) {

        let filters = {};

        filters.paymentSlipId = paymentSlip.id

        await new BankTransactionService().getBankTransactionsByFilters(filters)
            .then(result => {

                let total = 0;

                for (let i = 0; i < result.transactions.length; i++) {
                    total += result.transactions[i].amount;
                }

                setTotalExistingTransactions(total);
            })
            .catch(error => {

            })

    }


    async function verify() {

        let codIds = [];

        let partial = false;
        let totalOfCods = 0;

        for (let i = 0; i < cods.length; i++) {
            if (cods[i].checked === true && !cods[i].verified) {

                let newStatus = [...cods];
                newStatus[i].pending = true;
                setCods(newStatus);

                totalOfCods += cods[i].cod.value;

                codIds.push(cods[i].cod.id);

                setRefresh(!refresh);

            } else {
                partial = true;
            }
        }

        let input = {};

        input.cods = codIds;

        input.bankTransactions = [];

        let totalOfTransactions = 0;

        for (let i = 0; i < transactions.length; i++) {
            let transaction = {};

            transaction.valueDate = transactions[i].valueDate
            transaction.accountingDate = transactions[i].accountingDate
            transaction.senderControlInternalNumber = transactions[i].senderControlInternalNumber
            transaction.senderBankCode = transactions[i].senderBankCode
            transaction.senderBranchCode = transactions[i].senderBranchCode
            transaction.senderAccountNumber = transactions[i].senderAccountNumber
            transaction.reason = transactions[i].reason
            transaction.reference = transactions[i].reference
            transaction.chequeNumber = transactions[i].chequeNumber
            transaction.amount = transactions[i].amount
            transaction.bankAccountId = transactions[i].bankAccount?.id

            transaction.splits = [];

            let split = {amount : transactions[i].amount, paymentSlipId: paymentSlip?.id};

            transaction.splits.push(split);

            totalOfTransactions += transactions[i].amount;

            input.bankTransactions.push(transaction);
        }

        if (partial && totalOfTransactions !== totalOfCods) {
            setNotificationMessage(t("total_transactions_not_equal_total_cods") + totalOfTransactions + " : " + totalOfCods);
            setNotifySeverity("error");
            setOpenNotify(true);
            setAllPending(false);
            return;
        }

        if (!partial && totalOfTransactions !== paymentSlip.totalPaid) {
            setNotificationMessage(t("total_transactions_not_equal_total_paid"));
            setNotifySeverity("error");
            setOpenNotify(true);
            setAllPending(false);
            return;
        }

        let removeValFromIndex = [];
        let output = [];

        await new CodService().verifyOnBankAccount(input)
            .then(response => {
                output = response;

                for (let i = 0; i < cods.length; i++) {
                    for (let j = 0; j < output.length; j++) {
                        if (output[j].codId === cods[i].cod.id) {

                            let newCod = [...cods];
                            newCod[i].pending = false;
                            newCod[i].verified = false;
                            newCod[i].errorMessage = output[j].warning.code + " - " + output[j].warning.message;
                            setCods(newCod);

                            // cods[i].pending = false;
                            // cods[i].verified = false;
                            // cods[i].errorMessage = output[j].warning.code + " - " + output[j].warning.message;

                            setRefresh(!refresh);

                        }
                    }

                }

                for (let i = 0; i < cods.length; i++) {
                    if (cods[i].pending) {

                        let newCod = [...cods];
                        newCod[i].pending = false;
                        newCod[i].verified = true;

                        removeValFromIndex.push(i);

                        setCods(newCod);

                        setTimeout(() => {

                            let newCod = [...cods];
                            newCod[i].fadeOut = true;

                            setCods(newCod);

                            setRefresh(!refresh);

                        }, 1000)

                    }

                    cods[i].checked = false;
                }

                setRefresh(!refresh);

                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })

                setAllPending(false);
            })

        setRefresh(!refresh);

        let newCods = [...cods];

        for (let i = removeValFromIndex.length - 1; i >= 0; i--) {

            newCods.splice(removeValFromIndex[i], 1);

        }

        setCods(newCods);

        if (newCods.length < 1) {
            setTransactions([]);
            setPaymentSlip(null);
        }

        setRefresh(!refresh);

        setNotificationMessage(t("successful"));
        setNotifySeverity('success');
        setOpenNotify(true);


    }


    async function fetchWarehouses() {

        let filters = {};

        filters.accountId = supplier.id;

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setWarehouses(result.warehouses);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchCompanies() {

        await new CompanyService().getCompanies()
            .then(result => {
                setCompanies(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchBankAccounts(company) {

        let filters = {};

        filters.companyId = company?.id;

        await new BankAccountService().getBankAccountsByFilters(filters)
            .then(result => {
                setBankAccounts(result);
            })
            .catch(error => {
                if (error.response.status !== 500) {
                    error.response.json()
                        .then(response => {
                            setNotificationMessage(response.status + " - " + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                } else {
                    setNotificationMessage(error.response.status + " - " + error.response.statusText);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                }
            })
    }

    async function fetchPaymentSlips() {

        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (supplier) {
            filters.supplierId = supplier.id;
        }
        if (dateFrom) {
            filters.verifiedDateFrom = moment(dateFrom).format('yyyy-MM-DD');
        }
        if (dateTo) {
            filters.verifiedDateTo = moment(dateTo).format('yyyy-MM-DD');
        }

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }
        if (company) {
            filters.companyId = company.id;
        }

        filters.forBankAccountVerify = true;

        await new PaymentSlipsService().getPaymentSlipHeads(filters)
            .then(result => {
                setPaymentSlips(result.paymentSlips);
            })
            .catch(error => {

            })

    }

    function handleCompanyChange(event, company) {
        setCompany(company);

        if (null !== company) {
            fetchBankAccounts(company);
        }


    }

    function handleBankAccountChange(event, bankAccount) {
        setBankAccount(bankAccount);
    }

    async function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);

        if (null !== warehouse) {
            await new ContractService().getContractById(warehouse?.contractId)
                .then(result => {

                    let iban = result.bankAccounts.filter(bankAccount => bankAccount.type === "COD")[0].bankAccount.iban;

                    setIban(iban);

                    console.log(iban);

                })
                .catch(error => {

                })
        }

    }

    function handleSupplierChange(event, supplier) {
        setSupplier(supplier);
    }

    function handleDateFromChange(event) {
        setDateFrom(event);
    }

    function handleDateToChange(event) {
        setDateTo(event);
    }

    function handlePaymentSlipChange(event, paymentSlip) {
        setPaymentSlip(paymentSlip);
        setCods([]);
        setTransactions([]);

        getTransactions(paymentSlip);
    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);

        let rows = cods
            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)


        for (let i = 0; i < rows.length; i++) {
            if (!rows[i].verified) {
                rows[i].checked = !!event.target.checked;
            } else {
                rows[i].checked = false;
            }
        }

        setRefresh(!refresh);

    }

    function handleShipmentFilterChange(event) {
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setShipmentIdFilter(amount);
        }
    }

    useEffect(() => {

    }, [transactions, refresh, page, rowsPerPage])

    useEffect(() => {
        fetchCompanies();
    }, [])

    return <div className={classes.root}>

        <Card>
            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("verify_payment_slip")}</DialogTitle>
            <div className={classes.select_div}>
                <Autocomplete
                    id="companies"
                    size="small"
                    className={classes.select}
                    options={companies}
                    getOptionLabel={option => option.companyName}
                    value={company}
                    defaultValue={company}
                    noOptionsText={t("no_options")}
                    onOpen={fetchCompanies}
                    onChange={handleCompanyChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("company")}
                            variant="outlined"
                            margin="dense"
                            value={company}
                            defaultValue={company}
                        />
                    )}
                />
                <Autocomplete
                    id="bank-account"
                    size="small"
                    disabled={!company}
                    options={bankAccounts}
                    className={classes.select}
                    getOptionLabel={option => option.bankName + " - " + option.iban}
                    value={bankAccount}
                    defaultValue={bankAccount}
                    noOptionsText={t("no_options")}
                    onOpen={() => fetchBankAccounts(company)}
                    onChange={handleBankAccountChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("bank_account")}
                            variant="outlined"
                            margin="dense"
                            value={bankAccount}
                            defaultValue={bankAccount}
                        />
                    )}
                />
            </div>
            <Divider className={classes.divider}/>
            <div className={classes.select_div}>
                <Autocomplete
                    id="supplier"
                    size="small"
                    options={suppliers}
                    className={classes.select}
                    getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                    value={supplier}
                    defaultValue={supplier}
                    noOptionsText={t("no_options")}
                    onOpen={fetchSuppliers}
                    onChange={handleSupplierChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("supplier")}
                            variant="outlined"
                            margin="dense"
                            value={supplier}
                            defaultValue={supplier}
                        />
                    )}
                />
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    disabled={!supplier}
                    options={warehouses}
                    className={classes.select}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onOpen={fetchWarehouses}
                    onChange={handleWarehouseChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
            </div>
            <Divider className={classes.divider}/>
            <div style={{display: "flex", margin: "auto", width: "70%"}}>
                <DateField
                    className={classes.select}
                    label={t("payment_date_from")}
                    value={dateFrom}
                    onChange={handleDateFromChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
                <DateField
                    className={classes.select}
                    label={t("payment_date_to")}
                    value={dateTo}
                    onChange={handleDateToChange}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                    clearable={false}
                />
            </div>
            <div className={classes.select_div}>
                <Autocomplete
                    id="paymentSlip"
                    size="small"
                    className={classes.select}
                    options={paymentSlips}
                    getOptionLabel={option => option.warehouseCode + " " + option.currency + " " + option.totalPaid + " " + t("of") + new Date(option.dateTime).toLocaleDateString()}
                    value={paymentSlip}
                    defaultValue={paymentSlip}
                    noOptionsText={t("no_options")}
                    onOpen={fetchPaymentSlips}
                    onChange={handlePaymentSlipChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("payment_slip")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!paymentSlip}
                            value={paymentSlip}
                            defaultValue={paymentSlip}
                        />
                    )}
                />
            </div>
            <Button disabled={!paymentSlip} startIcon={loading ? <CircularProgress size={20}/> : <SearchOutlined/>}
                    className={classes.button} variant="contained"
                    color="primary" type="button" onClick={fetchCods}>
                {t("search")}
            </Button>
        </Card>
        {fetched ?
            <div>
                {cods?.length > 0 ?
                    <div>
                        <Table stickyHeader style={{width: "80%", margin: "auto", marginTop: "2%"}}>

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox color="primary"
                                                  onChange={handleAllChecked}
                                                  checked={allChecked}/>
                                    </TableCell>
                                    <TableCell>
                                        {t("ID")}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant={"outlined"}
                                            size={"small"}
                                            label={t("shipment")}
                                            value={shipmentIdFilter}
                                            defaultValue={shipmentIdFilter}
                                            onChange={handleShipmentFilterChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {t("cod_mode")}
                                    </TableCell>
                                    <TableCell>
                                        {t("value")}
                                    </TableCell>
                                    <TableCell>
                                        {t("collection_datetime")}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? cods
                                            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : cods
                                            .filter(row => shipmentIdFilter ? row.cod.shipmentId?.toString() === shipmentIdFilter : row)
                                ).map((data, key) => {
                                    return <Slide direction="left" in={!data.fadeOut} appear={false} unmountOnExit>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    color="primary"
                                                    onChange={event => handleChecked(event, key)}
                                                    checked={data.checked}/>
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.id}
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.shipmentId}
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.codMode}
                                            </TableCell>
                                            <TableCell>
                                                {data.cod.value.toFixed(2) + " " + data.cod.currency}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(data.cod.collectionDateTime).toLocaleDateString() + " - " + new Date(data.cod.collectionDateTime).toLocaleTimeString()}
                                            </TableCell>
                                            <TableCell>
                                                {data.pending === true ?
                                                    <CircularProgress color={"primary"} size={24}/> : null}
                                                {data.verified === false ?
                                                    <ErrorOutline style={{color: "red"}}/> : null}
                                                {data.verified === true ?
                                                    <CheckCircleOutline style={{color: "green"}}/> : null}
                                                <div>
                                                    <Typography variant={"caption"}
                                                                color={"red"}>{data.errorMessage}</Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </Slide>

                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            style={{width: "80%", margin: "auto"}}
                            rowsPerPageOptions={[5, 10, 25, {label: t("all"), value: -1}]}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return (t("total_selected") + ": " + cods.filter(acc => !!acc.checked)?.length)
                            }}
                            component="div"
                            count={cods.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <Button startIcon={<AddCircleOutlined/>} disabled={!paymentSlip || cods.length < 1}
                                className={classes.button} variant="contained"
                                color="primary" type="button" onClick={addTransaction}>
                            {t("add_transaction")}
                        </Button>


                        <Grid item xs={12} style={{marginTop: "5%"}}>
                            <Grid container justifyContent="center" spacing={2}>
                                {transactions?.map((transaction, index) => {
                                    return <Grid item style={{width: "50%", margin: "auto", marginBottom: "2%"}}>
                                        <Card>
                                            <div style={{display: "flex"}}>
                                                <DialogTitle>{t("transaction")} {index + 1}</DialogTitle>
                                                <div style={{width: "100%", display: "flex"}}>
                                                    <IconButton style={{marginLeft: "auto", background: "none"}}
                                                                onClick={() => removeTransaction(index)}>
                                                        <RemoveCircleOutlined/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div className={classes.select_div}>
                                                <Autocomplete
                                                    id="companies"
                                                    size="small"
                                                    style={{width: "60%", margin: "auto"}}
                                                    options={companies}
                                                    getOptionLabel={option => option.companyName}
                                                    value={transaction.company}
                                                    defaultValue={transaction.company}
                                                    noOptionsText={t("no_options")}
                                                    onOpen={fetchCompanies}
                                                    onChange={(event, value) => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["company"] = value;
                                                        if (value === null) {
                                                            newTransactions[index]["bankAccount"] = null;
                                                        }
                                                        setTransactions(newTransactions);
                                                    }}
                                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("company")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={transaction.company}
                                                            defaultValue={transaction.company}
                                                            required
                                                            error={!transaction.company}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="bank-account"
                                                    size="small"
                                                    disabled={!transaction.company}
                                                    options={bankAccounts}
                                                    style={{width: "60%", margin: "auto"}}
                                                    getOptionLabel={option => option.bankName + " - " + option.iban}
                                                    value={transaction.bankAccount}
                                                    defaultValue={transaction.bankAccount}
                                                    noOptionsText={t("no_options")}
                                                    onOpen={() => fetchBankAccounts(transaction.company)}
                                                    onChange={(event, value) => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["bankAccount"] = value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("bank_account")}
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={transaction.bankAccount}
                                                            defaultValue={transaction.bankAccount}
                                                            required
                                                            error={!transaction.bankAccount}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{width: "100%", display: "flex"}}>
                                                <DateField
                                                    style={{width: "40%", margin: "auto"}}
                                                    label={t("value_date")}
                                                    value={transaction.valueDate}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["valueDate"] = event;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.valueDate}
                                                    format={"dd/MM/yyyy"}
                                                    disableFuture={true}
                                                    clearable={false}
                                                />
                                                <DateField
                                                    style={{width: "40%", margin: "auto"}}
                                                    label={t("accounting_date")}
                                                    value={transaction.accountingDate}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["accountingDate"] = event;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.accountingDate}
                                                    format={"dd/MM/yyyy"}
                                                    disableFuture={true}
                                                    clearable={false}
                                                />
                                            </div>
                                            <div style={{marginTop: "2%", display: "flex"}}>
                                                <TextField
                                                    style={{width: "60%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("sender_account_number")}
                                                    value={transaction.senderAccountNumber}
                                                    defaultValue={transaction.senderAccountNumber}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["senderAccountNumber"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.senderAccountNumber}
                                                />
                                            </div>
                                            <div style={{marginTop: "4%", display: "flex"}}>
                                                <TextField
                                                    style={{width: "30%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("sender_cin")}
                                                    value={transaction.senderControlInternalNumber}
                                                    defaultValue={transaction.senderControlInternalNumber}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["senderControlInternalNumber"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.senderControlInternalNumber}
                                                />
                                                <TextField
                                                    style={{width: "30%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("sender_bank_code")}
                                                    value={transaction.senderBankCode}
                                                    defaultValue={transaction.senderBankCode}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["senderBankCode"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.senderBankCode}
                                                />
                                                <TextField
                                                    style={{width: "30%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("sender_branch_code")}
                                                    value={transaction.senderBranchCode}
                                                    defaultValue={transaction.senderBranchCode}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["senderBranchCode"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.senderBranchCode}
                                                />
                                            </div>
                                            <div style={{marginTop: "4%", display: "flex"}}>
                                                <TextField
                                                    style={{width: "40%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("transaction_reference")}
                                                    value={transaction.reference}
                                                    defaultValue={transaction.reference}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["reference"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                />
                                                <TextField
                                                    style={{width: "40%", margin: "auto"}}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("cheque_number")}
                                                    value={transaction.chequeNumber}
                                                    defaultValue={transaction.chequeNumber}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["chequeNumber"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                />
                                            </div>
                                            <div style={{marginTop: "4%", display: "flex"}}>
                                                <TextField
                                                    style={{width: "60%", margin: "auto"}}
                                                    multiline
                                                    minRows={1}
                                                    variant={"outlined"}
                                                    size={"small"}
                                                    label={t("reason")}
                                                    value={transaction.reason}
                                                    defaultValue={transaction.reason}
                                                    onChange={event => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["reason"] = event.target.value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required={true}
                                                    error={!transaction.reason}
                                                />
                                            </div>
                                            <div style={{marginTop: "4%", display: "flex", marginBottom: "2%"}}>
                                                <CurrencyTextField
                                                    style={{width: "40%", margin: "auto"}}
                                                    label={t("amount")}
                                                    variant={"outlined"}
                                                    margin={"dense"}
                                                    digitGroupSeparator={"."}
                                                    decimalCharacter={","}
                                                    decimalPlaces={2}
                                                    value={transaction.amount}
                                                    currencySymbol={"€"}
                                                    onChange={(event, value) => {
                                                        let newTransactions = [...transactions];
                                                        newTransactions[index]["amount"] = value;
                                                        setTransactions(newTransactions);
                                                    }}
                                                    required
                                                    error={!transaction.amount}
                                                />
                                            </div>
                                        </Card>
                                    </Grid>
                                })
                                }
                            </Grid>
                        </Grid>


                        <Button startIcon={loading ? <CircularProgress size={20}/> : <CheckOutlined/>}
                                style={{width: "fit-content", margin: "auto", display: "flex"}}
                                variant="contained"
                                disabled={!paymentSlip || cods.length < 1}
                                color="primary" type="button" onClick={verify}>
                            {transactions.length > 0 ? t("verify_and_save_transactions") : t("verify")}
                        </Button>

                    </div>
                    :
                    <InfoChip>{t("no_data")}</InfoChip>
                }

            </div>

            :
            null
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </div>


}

export default VerifyPaymentSlipPage;