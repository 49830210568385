import './App.css';
import './i18n';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginPage from "./login/LoginPage";
import DashboardPage from "./dashboard/DashboardPage";
import {PrivateRoute} from "./routes/PrivateRoute";
import {MuiThemeProvider} from "@material-ui/core";
import React from "react";
import LastMile from "./tracking/LastMile";
import TrackingPageIndex from "./tracking/TrackingPageIndex";
import theme from "./theme/theme";
import RedirectPage from "./redirect/RedirectPage";
import WarehouseStorageProvisionPage from "./warehouse_storages/WarehouseStorageProvisionPage";
import SessionTimeout from "./session/SessionTimeout";


export default function App() {

    return <MuiThemeProvider theme={theme}>
        <Router>
            <Switch>
                <Route path={`/tracking/:id/lastmile`}>
                    <LastMile/>
                </Route>
                <Route path={`/tracking`}>
                    <TrackingPageIndex/>
                </Route>
                <Route path={`/l/:key`}>
                    <RedirectPage/>
                </Route>
                <Route path={"/warehouse-storages/:id/provision"}>
                    <WarehouseStorageProvisionPage/>
                </Route>
                {/*Il Route per /login deve stare prima di / */}
                <Route path="/login">
                    <LoginPage/>
                </Route>
                <PrivateRoute path="/">
                    <SessionTimeout/>
                    <DashboardPage/>
                </PrivateRoute>
            </Switch>
        </Router>
    </MuiThemeProvider>
}
