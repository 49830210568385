import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";

const userService = new UserService();

export default class BookingService {


    createBooking(date, shipmentId, slotId, districtId, guestKey) {

        const apiKey = userService.getApiKey();

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (apiKey) {
            headers.Authorization = 'Bearer ' + apiKey;
        }

        let requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                date: date,
                shipmentId: shipmentId,
                slotId: slotId,
                districtId: districtId
            })

        };

        let queryString = ``;


        if (null != guestKey) {
            queryString += `guestKey=${guestKey}`;
        }

        let pathVariable = `/api/v1/bookings`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    cancelBooking(bookingID) {

        const apiKey = userService.getApiKey();

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (apiKey) {
            headers.Authorization = 'Bearer ' + apiKey;
        }

        let requestOptions = {
            method: "DELETE",
            headers: headers
        };

        let pathVariable = `/api/v1/bookings/${bookingID}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

}