import React, {useEffect, useState} from "react";
import {Backdrop, Button, Fade, Modal, TableRow} from "@material-ui/core";
import {IconButton, Paper, TableCell, Typography} from "@mui/material";
import {CancelOutlined, CheckCircleOutlined, DeleteForever, Edit} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import HandheldService from "./HandheldService";
import WarehouseService from "../warehouses/WarehouseService";
import {formatDateTime} from "../utils/utils";

export default function Handheld(props) {

    const history = useHistory();

    let handheld = props.handheld;

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [warehouse, setWarehouse] = useState(null);

    const {t} = useTranslation();

    async function fetchWarehouses() {
        console.log("aaa1");
        console.log(handheld);
        if (handheld.warehouseId) {
            console.log("got warehouse");

            const warehouseService = new WarehouseService();
            const restWarehouse = await warehouseService.getWarehouseById(handheld.warehouseId);
            console.log("restWarehouse: " + restWarehouse);
            setWarehouse(restWarehouse);
        }
    }

    useEffect(() => {
        // fetchWarehouses();
    }, []);

    function goToEdit() {
        history.push(`handhelds/${handheld.id}/edit`)
    }

    function openDialog() {
        setOpenConfirmDialog(true);
    }

    function deleteHandheld() {
        setOpenConfirmDialog(false);

        const handheldService = new HandheldService();
        handheldService.deleteHandheld(handheld.id)
            .then(result => {
                let response = {};
                response.message = t("successful");
                props.deleteCallback('success', response)
            })
            .catch(error => {
                error.response.json().then((response) => {
                    props.deleteCallback('error', response)
                });
            })
    }

    useEffect(() => {

    })

    return <TableRow>
        <TableCell>{props.warehouses.find(warehouse => warehouse.id === handheld.warehouseId)?.code}</TableCell>
        <TableCell>{handheld.imei}</TableCell>
        <TableCell>{handheld.description}</TableCell>
        <TableCell>{handheld.model}</TableCell>
        <TableCell>{handheld.operator}</TableCell>
        <TableCell>{handheld.appVersion}</TableCell>
        <TableCell>{formatDateTime(handheld.lastAccessDateTime)}</TableCell>
        <TableCell>{handheld.lastAccessDriverName}</TableCell>
        <TableCell>
            <IconButton onClick={goToEdit}><Edit/></IconButton>
            <IconButton onClick={openDialog}><DeleteForever/></IconButton>
        </TableCell>
        <Modal open={openConfirmDialog}
            // className={classes.modal}
               onClose={() => setOpenConfirmDialog(false)}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
        >
            <Fade in={openConfirmDialog}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                    // border: `1px solid black`
                }}>
                    <div style={{width: "60%", margin: "auto", marginTop: "10%", display: "flex"}}>
                        {/*<WarningOutlined color={"warning"}/>*/}
                        <Typography
                            style={{margin: "auto"}}>{t("confirm_driver_delete") + handheld.imei + "?"}</Typography>
                        {/*<WarningOutlined color={"warning"}/>*/}
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDialog(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteHandheld}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>
        </Modal>
    </TableRow>
};