import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

export default class CodEventCodeService {
    getCodEventCodes() {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cods/events/codes`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCodEventCodesForCodMode(codModeId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cods/modes/${codModeId}/codes`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }
}