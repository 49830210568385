import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MuiThemeProvider,
    Paper,
    responsiveFontSizes
} from "@material-ui/core";
import typ from "../images/typ_cropped.png"
import {makeStyles} from "@material-ui/core/styles";
import map from "../images/Map3.png"
import Typography from "@material-ui/core/Typography";
import {useHistory, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {ExpandMore, KeyboardBackspace} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import {createTheme} from "@mui/material";

let theme = createTheme({
    palette: {
        primary: {
            main: "#1B6031",
        },
    },
    typography: {
        fontFamily: [
            'Bebas Neue',
            'cursive',
        ].join(','),
    },
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {

        height: "100%",
        maxWidth: 1024,
        textAlign: "center",
        backgroundColor: "#f6f6f4"
    },
    img: {
        width: "100%",
        marginTop: "5%",
    },
    label: {
        width: "100%",
        // marginTop: "5%",
        paddingTop: "5%"
    },
    searchField: {
        marginTop: "5%",
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 50,
        margin: 6,
    },
    paper: {
        backgroundColor: "#d0d0d0",
        width: "100%",
        display: "block",
        marginTop: "5%",
    },
    button: {
        width: "75%",
        backgroundColor: "lightgrey",
        marginBottom: "5%",
        marginTop: "5%"

    },
    cardWrap: {
        marginBottom: "2%",

    },
    card: {
        width: "75%",
        display: "inline",
    },
    itemImg: {
        width: "50%",
        marginTop: "5%",
    },
    typo: {
        marginTop: "3%",
        paddingBottom: "2%"
    },
    hidden: {
        display: "none"
    },
    map: {
        width: "100%",
        height: "100%"
    },
    large: {
        display: "block",
        marginLeft: " auto",
        marginRight: "auto",
        marginBottom: "5%",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },


}));

function LastMile(props) {

    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {id} = useParams();
    const history = useHistory();

    function goToTrackingPage() {
        history.goBack();
    }

    useEffect(() => {

    })

    return <MuiThemeProvider theme={theme}>
        <Container className={classes.root}>

            <Button className={classes.button} onClick={goToTrackingPage}
                    startIcon={<KeyboardBackspace/>}>{"Torna alla pagina di tracking"}</Button>
            <img src={map} className={classes.map}/>
            <Accordion defaultExpanded className={classes.cardWrap}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography variant="h4">
                        {"Dettagli di consegna"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper className={classes.paper}>
                        <Typography className={classes.typo} variant="h6">
                            {"Consegna gestita da "}
                        </Typography>
                        <Avatar alt="Typ" src={typ} className={classes.large}/>
                    </Paper>
                </AccordionDetails>
            </Accordion>


        </Container>
    </MuiThemeProvider>
}

export default LastMile;