import React, {useEffect, useState} from "react";
import {Button, Checkbox, Table, TableCell, TableRow} from "@material-ui/core";
import WarehouseStorageService from "./WarehouseStorageService";
import ShipmentService from "../shipments/ShipmentService";
import ServiceService from "../shipments/services/ServiceService";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Autocomplete, Card, DialogTitle, FormControlLabel, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {Send} from "@mui/icons-material";
import Notification from "../components/notifications/Notification";
import theme from "../theme/theme";
import CheckChip from "../components/chips/CheckChip";
import InfoChip from "../components/chips/InfoChip";


function WarehouseStorageProvisionPage(props) {

    const location = useLocation();
    const history = useHistory();

    const {t} = useTranslation();

    //const [releaseModes, setReleaseModes] = useState([
    //    {id: 1, description: "Rientro a mittente"},
    //    {id: 2, description: "Nuovo tentativo di consegna"},
    //    {id: 3, description: "Cambio indirizzo di consegna"},
    //    {id: 4, description: "Il destinatario ritira in filiale"},
    //    {id: 5, description: "Al macero"},
    //    {id: 7, description: "Il mittente ritira in filiale"}
    //]);
    const [releaseModes, setReleaseModes] = useState([]);
    const [releaseMode, setReleaseMode] = useState(null);
    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);
    const [notes, setNotes] = useState('');
    const [hasProvision, setHasProvision] = useState(false);

    const [redirecting, setRedirecting] = useState(false);

    const [operatorNotes, setOperatorNotes] = useState('');

    const [shipment, setShipment] = useState(null);
    const [address, setAddress] = useState(null);

    const [countries, setCountries] = useState([]);
    const [administrativeLevel3, setAdministrativeLevel3] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);

    const [changeEndUser, setChangeEndUser] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const [deliveryCountry, setDeliveryCountry] = useState(null);
    const [deliveryAdministrativeLevel3, setDeliveryAdministrativeLevel3] = useState(null);
    const [deliveryCity, setDeliveryCity] = useState(null);
    const [deliveryPostalCode, setDeliveryPostalCode] = useState(null);
    const [freeDeliveryAdministrativeLevel1, setFreeDeliveryAdministrativeLevel1] = useState('');
    const [freeDeliveryAdministrativeLevel2, setFreeDeliveryAdministrativeLevel2] = useState('');
    const [freeDeliveryAdministrativeLevel3, setFreeDeliveryAdministrativeLevel3] = useState('');
    const [freeDeliveryCity, setFreeDeliveryCity] = useState('');
    const [freeDeliveryPostalCode, setFreeDeliveryPostalCode] = useState('');
    const [deliveryStreet, setDeliveryStreet] = useState('');
    const [deliveryBuildingNr, setDeliveryBuildingNr] = useState('');
    const [deliveryCompanyName, setDeliveryCompanyName] = useState('');
    const [deliveryReferencePerson, setDeliveryReferencePerson] = useState('');
    const [deliveryEmail, setDeliveryEmail] = useState('');
    const [deliveryPhone, setDeliveryPhone] = useState('');
    const [deliveryMobile, setDeliveryMobile] = useState('');


    const [endUserCountry, setEndUserCountry] = useState(null);
    const [endUserAdministrativeLevel3, setEndUserAdministrativeLevel3] = useState(null);
    const [endUserCity, setEndUserCity] = useState(null);
    const [endUserPostalCode, setEndUserPostalCode] = useState(null);
    const [freeEndUserAdministrativeLevel1, setFreeEndUserAdministrativeLevel1] = useState('');
    const [freeEndUserAdministrativeLevel2, setFreeEndUserAdministrativeLevel2] = useState('');
    const [freeEndUserAdministrativeLevel3, setFreeEndUserAdministrativeLevel3] = useState('');
    const [freeEndUserCity, setFreeEndUserCity] = useState('');
    const [freeEndUserPostalCode, setFreeEndUserPostalCode] = useState('');
    const [endUserStreet, setEndUserStreet] = useState('');
    const [endUserBuildingNr, setEndUserBuildingNr] = useState('');
    const [endUserCompanyName, setEndUserCompanyName] = useState('');
    const [endUserReferencePerson, setEndUserReferencePerson] = useState('');
    const [endUserEmail, setEndUserEmail] = useState('');
    const [endUserPhone, setEndUserPhone] = useState('');
    const [endUserMobile, setEndUserMobile] = useState('');

    const [landing, setLanding] = useState(false);

    const {id} = useParams();
    const guestKey = new URLSearchParams(location.search).get("key");
    const apiKey = window.localStorage.getItem("apiKey");

    function sendProvision() {

        let input = {};

        input.notes = notes;

        if (releaseMode != null) {
            input.releaseModeId = releaseMode.id;
        }

        if (service != null) {
            input.serviceCode = service.code;
        }

        let deliveryAddress = {};

        if (deliveryCountry !== null) {
            deliveryAddress.country = deliveryCountry.iso3166Alpha2;
        }
        if (freeDeliveryAdministrativeLevel1 !== '') {
            deliveryAddress.administrativeLevel1 = freeDeliveryAdministrativeLevel1;
        }

        if (freeDeliveryAdministrativeLevel2 !== '') {
            deliveryAddress.administrativeLevel2 = freeDeliveryAdministrativeLevel2;
        }

        if (freeDeliveryAdministrativeLevel3 !== '') {
            deliveryAddress.administrativeLevel3 = freeDeliveryAdministrativeLevel3;
        } else if (deliveryAdministrativeLevel3 !== null) {
            deliveryAddress.administrativeLevel3 = deliveryAdministrativeLevel3.code;
        }

        if (freeDeliveryCity !== '') {
            deliveryAddress.city = freeDeliveryCity;
        } else if (deliveryCity !== null) {
            deliveryAddress.city = deliveryCity.name;
        }

        if (freeDeliveryPostalCode !== '') {
            deliveryAddress.postalCode = freeDeliveryPostalCode;
        } else if (deliveryPostalCode !== null) {
            deliveryAddress.postalCode = deliveryPostalCode.postalCode;
        }

        if (deliveryStreet !== '') {
            deliveryAddress.street = deliveryStreet;
        }

        if (deliveryBuildingNr !== '') {
            deliveryAddress.buildingNr = deliveryBuildingNr;
        }

        if (deliveryCompanyName !== '') {
            deliveryAddress.companyName = deliveryCompanyName;
        }

        if (deliveryReferencePerson !== '') {
            deliveryAddress.referencePerson = deliveryReferencePerson;
        }

        if (deliveryPhone !== '') {
            deliveryAddress.phone = deliveryPhone;
        }

        if (deliveryMobile !== '') {
            deliveryAddress.mobile = deliveryMobile;
        }

        if (deliveryEmail !== '') {
            deliveryAddress.email = deliveryEmail;
        }

        input.deliveryAddress = deliveryAddress;


        let endUserAddress = null;
        if (changeEndUser) {
            endUserAddress = {};

            if (endUserCountry) {
                endUserAddress.country = endUserCountry.iso3166Alpha2;
            }
            if (freeEndUserAdministrativeLevel1 !== '') {
                endUserAddress.administrativeLevel1 = freeEndUserAdministrativeLevel1;
            }

            if (freeEndUserAdministrativeLevel2 !== '') {
                endUserAddress.administrativeLevel2 = freeEndUserAdministrativeLevel2;
            }

            if (freeEndUserAdministrativeLevel3 !== '') {
                endUserAddress.administrativeLevel3 = freeEndUserAdministrativeLevel3;
            } else if (endUserAdministrativeLevel3) {
                endUserAddress.administrativeLevel3 = endUserAdministrativeLevel3.code;
            }

            if (freeEndUserCity !== '') {
                endUserAddress.city = freeEndUserCity;
            } else if (endUserCity) {
                endUserAddress.city = endUserCity.name;
            }

            if (freeEndUserPostalCode !== '') {
                endUserAddress.postalCode = freeEndUserPostalCode;
            } else if (endUserPostalCode) {
                endUserAddress.postalCode = endUserPostalCode.postalCode;
            }

            if (endUserStreet !== '') {
                endUserAddress.street = endUserStreet;
            }

            if (endUserBuildingNr !== '') {
                endUserAddress.buildingNr = endUserBuildingNr;
            }

            if (endUserCompanyName !== '') {
                endUserAddress.companyName = endUserCompanyName;
            }

            if (endUserReferencePerson !== '') {
                endUserAddress.referencePerson = endUserReferencePerson;
            }

            if (endUserPhone !== '') {
                endUserAddress.phone = endUserPhone;
            }

            if (endUserMobile !== '') {
                endUserAddress.mobile = endUserMobile;
            }

            if (endUserEmail !== '') {
                endUserAddress.email = endUserEmail;
            }
        }

        input.endUserAddress = endUserAddress;

        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.saveNewProvision(id, guestKey, input)
            .then(result => {
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setOpenNotify(true);
                setLanding(true);
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }

    function closeNotification() {
        setOpenNotify(false);
    }


    function fetchReleaseModes() {
        let warehouseStorageService = new WarehouseStorageService();
        warehouseStorageService.getReleaseModes(id)
            .then(data => {
                setReleaseModes(data);
            })
            .catch(error => {

            })
    }

    function fetchServices() {
        let service = new ServiceService();
        service.getServicesByFilters()
            .then(data => {
                setServices(data);
            })
            .catch(error => {

            })
    }

    function handleDeliveryCountryChange(event, country) {

        setDeliveryCountry(country);
        setDeliveryAdministrativeLevel3(null);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (changeEndUser) {

            setEndUserCountry(country);
            setEndUserAdministrativeLevel3(null);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }
    }

    function handleDeliveryAdministrativeLevel3Change(event, adminl3) {
        setDeliveryAdministrativeLevel3(adminl3);
        setDeliveryCity(null);
        setDeliveryPostalCode(null);

        if (changeEndUser) {
            setEndUserAdministrativeLevel3(adminl3);
            setEndUserCity(null);
            setEndUserPostalCode(null);
        }

    }

    function handleFreeDeliveryAdministrativeLevel1Change(event) {
        setFreeDeliveryAdministrativeLevel1(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel1(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel2Change(event) {
        setFreeDeliveryAdministrativeLevel2(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel2(event.target.value);
        }
    }

    function handleFreeDeliveryAdministrativeLevel3Change(event) {
        setFreeDeliveryAdministrativeLevel3(event.target.value);
        if (changeEndUser) {
            setFreeEndUserAdministrativeLevel3(event.target.value);
        }
    }

    function handleDeliveryCityChange(event, city) {
        setDeliveryCity(city);
        setDeliveryPostalCode(null);
        if (changeEndUser) {
            setEndUserCity(city);
            setEndUserPostalCode(null);
        }
    }

    function handleFreeDeliveryCityChange(event) {
        setFreeDeliveryCity(event.target.value);
        if (changeEndUser) {
            setFreeEndUserCity(event.target.value);
        }
    }

    function handleFreeDeliveryPostalCodeChange(event) {
        setFreeDeliveryPostalCode(event.target.value);
        if (changeEndUser) {
            setFreeEndUserPostalCode(event.target.value);
        }
    }

    function handleDeliveryPostalCodeChange(event, postalCode) {
        setDeliveryPostalCode(postalCode);
        if (changeEndUser) {
            setEndUserPostalCode(postalCode);
        }
    }

    function handleDeliveryStreetChange(event) {
        setDeliveryStreet(event.target.value);
        if (changeEndUser) {
            setEndUserStreet(event.target.value);
        }
    }

    function handleDeliveryBuildingNrChange(event) {
        setDeliveryBuildingNr(event.target.value);
        if (changeEndUser) {
            setEndUserBuildingNr(event.target.value);
        }
    }

    function handleDeliveryCompanyNameChange(event) {
        setDeliveryCompanyName(event.target.value);
        if (changeEndUser) {
            setEndUserCompanyName(event.target.value);
        }
    }

    function handleDeliveryReferencePerson(event) {
        setDeliveryReferencePerson(event.target.value);
        if (changeEndUser) {
            setEndUserReferencePerson(event.target.value);
        }
    }

    function handleDeliveryEmail(event) {
        setDeliveryEmail(event.target.value);
        if (changeEndUser) {
            setEndUserEmail(event.target.value);
        }
    }

    function handleDeliveryPhone(event) {
        setDeliveryPhone(event.target.value);
        if (changeEndUser) {
            setEndUserPhone(event.target.value);
        }
    }

    function handleDeliveryMobile(event) {
        setDeliveryMobile(event.target.value);
        if (changeEndUser) {
            setEndUserMobile(event.target.value);
        }
    }


    function handleEndUserCountryChange(event, country) {
        setEndUserCountry(country);
        setEndUserAdministrativeLevel3(null);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleEndUserAdministrativeLevel3Change(event, adminl3) {
        setEndUserAdministrativeLevel3(adminl3);
        setEndUserCity(null);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserAdministrativeLevel1Change(event) {
        setFreeEndUserAdministrativeLevel1(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel2Change(event) {
        setFreeEndUserAdministrativeLevel2(event.target.value);
    }

    function handleFreeEndUserAdministrativeLevel3Change(event) {
        setFreeEndUserAdministrativeLevel3(event.target.value);
    }

    function handleEndUserCityChange(event, city) {
        setEndUserCity(city);
        setEndUserPostalCode(null);
    }

    function handleFreeEndUserCityChange(event, city) {
        setEndUserCity(event.target.value);
    }

    function handleFreeEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(event.target.value);
    }

    function handleEndUserPostalCodeChange(event, postalCode) {
        setEndUserPostalCode(postalCode);
    }

    function handleEndUserStreetChange(event, street) {
        setEndUserStreet(event.target.value);
    }

    function handleEndUserBuildingNrChange(event, buildingNr) {
        setEndUserBuildingNr(event.target.value);
    }

    function handleEndUserCompanyNameChange(event, companyName) {
        setEndUserCompanyName(event.target.value);
    }

    function handleEndUserReferencePerson(event, referencePerson) {
        setEndUserReferencePerson(event.target.value);
    }

    function handleEndUserEmail(event) {
        setEndUserEmail(event.target.value);
    }

    function handleEndUserPhone(event) {
        setEndUserPhone(event.target.value);
    }

    function handleEndUserMobile(event) {
        setEndUserMobile(event.target.value);
    }

    function handleNotesChange(event) {
        setNotes(event.target.value);
    }

    function setEndUserAddress() {
        setEndUserCountry(deliveryCountry);
        setEndUserAdministrativeLevel3(deliveryAdministrativeLevel3);
        setEndUserCity(deliveryCity);
        setEndUserPostalCode(deliveryPostalCode);
        setEndUserStreet(deliveryStreet);
        setEndUserBuildingNr(deliveryBuildingNr);
        setEndUserCompanyName(deliveryCompanyName);
        setEndUserReferencePerson(deliveryReferencePerson);
        setEndUserEmail(deliveryEmail);
        setEndUserPhone(deliveryPhone);
        setEndUserMobile(deliveryMobile);

        setFreeEndUserAdministrativeLevel1(freeDeliveryAdministrativeLevel1);
        setFreeEndUserAdministrativeLevel2(freeDeliveryAdministrativeLevel2);
        setFreeEndUserAdministrativeLevel3(freeDeliveryAdministrativeLevel3);
        setFreeEndUserCity(freeDeliveryCity);
        setFreeEndUserPostalCode(freeDeliveryPostalCode);

    }

    function changeEndUserEqualDelivery() {
        setChangeEndUser(!changeEndUser);
        if (!changeEndUser) {
            setEndUserAddress();
        }
        // else {
        //     clearEndUserAddress();
        // }
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, guestKey)
            .then(countries => {
                setCountries(countries.countries);
            })
    }

    function fetchAdministrativeLevel3() {

        let filters = {};

        if (deliveryCountry) {
            filters.countryId = deliveryCountry.id;
        }

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, guestKey)
            .then(data => {
                setAdministrativeLevel3(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {

        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, guestKey)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {

        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, guestKey)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function fetchShipment() {
        let warehouseStorageService = new WarehouseStorageService();
        let shipmentService = new ShipmentService();
        warehouseStorageService.getWarehouseStorageById(id, guestKey)
            .then(response => {

                if (response.provisionId != null) {
                    setHasProvision(true);
                }

                setOperatorNotes(response.notes);
                // if(apiKey === null && response.key !== guestKey){
                //     setRedirecting(true);
                //     window.location.href =  "https://typ.delivery"
                // }

                shipmentService.getShipmentById(response.storedShipmentId, response.shipmentKey)
                    .then(shipment => {
                        setShipment(shipment);
                        setAddress(shipment.endUserAddress);
                    })
                    .catch(error => {
                        // if(!apiKey === null && response.key !== guestKey){
                        //     setRedirecting(true);
                        //     window.location.href =  "https://typ.delivery"
                        // }
                    })
            })
            .catch(error => {
                // if(apiKey === null){
                //     setRedirecting(true);
                //     window.location.href =  "https://typ.delivery"
                // }
            })
    }

    function generateDeliveryAddress(country) {

        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!deliveryCountry}
                    getOptionLabel={option => option.name}
                    value={deliveryAdministrativeLevel3}
                    defaultValue={deliveryAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(deliveryCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryAdministrativeLevel3}
                            defaultValue={deliveryAdministrativeLevel3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!deliveryAdministrativeLevel3}
                    getOptionLabel={option => option.name}
                    value={deliveryCity}
                    defaultValue={deliveryCity}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryCityChange}
                    onOpen={() => fetchCities(deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryCity}
                            defaultValue={deliveryCity}
                            required
                            error={!deliveryCity}
                            helperText={!deliveryAdministrativeLevel3 ? t("select_admin3_first") : (!deliveryCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={deliveryPostalCode}
                    defaultValue={deliveryPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleDeliveryPostalCodeChange}
                    onOpen={() => fetchPostalCodes(deliveryCity, deliveryAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={deliveryPostalCode}
                            defaultValue={deliveryPostalCode}
                            required
                            error={!deliveryPostalCode}
                            helperText={!deliveryPostalCode ? t("required") : null}
                        />
                    )}
                />
            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel1}
                        onChange={handleFreeDeliveryAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel2}
                        onChange={handleFreeDeliveryAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryAdministrativeLevel3}
                        onChange={handleFreeDeliveryAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryCity}
                        onChange={handleFreeDeliveryCityChange}
                        required
                        error={!freeDeliveryCity}
                        helperText={!freeDeliveryCity ? t("required") : null}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeDeliveryPostalCode}
                        onChange={handleFreeDeliveryPostalCodeChange}
                        required
                        error={!freeDeliveryPostalCode}
                        helperText={!freeDeliveryPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }

    }

    function generateEndUserAddress(country) {
        if (country && (country.id === 106 || country.id === 67 || country.id === 56)) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={administrativeLevel3}
                    disabled={!endUserCountry || !changeEndUser}
                    getOptionLabel={option => option.name}
                    value={endUserAdministrativeLevel3}
                    defaultValue={endUserAdministrativeLevel3}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserAdministrativeLevel3Change}
                    onOpen={() => fetchAdministrativeLevel3(endUserCountry)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={endUserAdministrativeLevel3}
                            defaultValue={endUserAdministrativeLevel3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!endUserAdministrativeLevel3 || !changeEndUser}
                    getOptionLabel={option => option.name}
                    value={endUserCity}
                    defaultValue={endUserCity}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserCityChange}
                    onOpen={() => fetchCities(endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={endUserCity}
                            defaultValue={endUserCity}
                            required
                            error={!endUserCity}
                            helperText={!endUserAdministrativeLevel3 ? t("select_admin3_first") : (!endUserCity ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    disabled={!changeEndUser}
                    getOptionLabel={option => option.postalCode}
                    value={endUserPostalCode}
                    defaultValue={endUserPostalCode}
                    noOptionsText={t("no_options")}
                    onChange={handleEndUserPostalCodeChange}
                    onOpen={() => fetchPostalCodes(endUserCity, endUserAdministrativeLevel3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={endUserPostalCode}
                            defaultValue={endUserPostalCode}
                            required
                            error={!endUserPostalCode}
                            helperText={!endUserPostalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        } else {
            return <>
                <div style={{display: "flex", marginBottom: "5%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative1")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel1}
                        onChange={handleFreeEndUserAdministrativeLevel1Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative2")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel2}
                        onChange={handleFreeEndUserAdministrativeLevel2Change}
                    />
                </div>
                <div style={{display: "flex", marginBottom: "1%"}}>
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("administrative3")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserAdministrativeLevel3}
                        onChange={handleFreeEndUserAdministrativeLevel3Change}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        disabled={!changeEndUser}
                        label={t("city")}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserCity}
                        required
                        error={!freeEndUserCity}
                        helperText={!freeEndUserCity ? t("required") : null}
                        onChange={handleFreeEndUserCityChange}
                    />
                    <TextField
                        style={{marginInline: "2%"}}
                        label={t("postalCode")}
                        disabled={!changeEndUser}
                        variant={"outlined"}
                        size={"small"}
                        value={freeEndUserPostalCode}
                        onChange={handleFreeEndUserPostalCodeChange}
                        required
                        error={!freeEndUserPostalCode}
                        helperText={!freeEndUserPostalCode ? t("required") : null}
                    />
                </div>
            </>
        }
    }

    function formatOrderNumbers(){
        let orderNumbers = [];

        if(shipment?.orderNumber){
            orderNumbers.push(shipment?.orderNumber);
        }

        for (let i = 0; i < shipment?.orders.length; i++) {
            orderNumbers.push(shipment?.orders[i]?.orderNumber)
        }

        return orderNumbers;
    }

    function handleReleaseModeChange(event, releaseMode) {
        setReleaseMode(releaseMode);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    useEffect(() => {
        fetchShipment();
        fetchReleaseModes();
        fetchServices();

    }, [location])

    return <>
        {redirecting ?

            <div>

            </div>

            :

            <>


                <div style={{width: "100%", margin: "auto"}}>

                    <div style={{
                        marginBottom: "2%",
                        height: 70,
                        backgroundColor: theme.palette.primary.main
                    }}>
                        <Typography>
                            <img style={{height: 50, marginTop: 10}} alt="logo"
                                 src={process.env.PUBLIC_URL + "/logo_white.png"}/>
                        </Typography>
                    </div>
                    {/*<div style={{display: "flex"}}>*/}
                    {/*    {apiKey === null ?*/}
                    {/*        null :*/}
                    {/*        <Button style={{margin: "auto", marginBottom: "2%"}} startIcon={<ArrowBackIosRounded/>}*/}
                    {/*                onClick={() => history.goBack()}>{t("go_back")}</Button>*/}
                    {/*    }*/}
                    {/*</div>*/}


                    <Card style={{width: "70%", margin: "auto"}}>
                        <DialogTitle>{t("shipment_details")}</DialogTitle>
                        <Table>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("shipment_id_short")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("service")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("manifest_datetime")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("reference_short")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("customerReference_short")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("customerCode")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("orderNumber")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("account_corporate_code")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("contract")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("sub-contract")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("account_company_name")} </Typography>
                                    <Typography
                                        variant={"inherit"}>  {t("sender_company_name")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"inherit"}>  {t("delivery_address")} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography> {shipment?.id} </Typography>
                                    <Typography variant={"subtitle2"}>{shipment?.service.code} </Typography>
                                    <Typography variant={"subtitle1"} fontSize={14}
                                                fontStyle={"italic"}>{new Date(shipment?.createdAt).toLocaleDateString() + " - " + new Date(shipment?.createdAt).toLocaleTimeString()} </Typography>
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    <Typography>{shipment?.service.code} </Typography>*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    <Typography>{shipment?.reference ? shipment?.reference : "---"} </Typography>
                                    <Typography>{shipment?.customerReference ? shipment?.customerReference : "---"} </Typography>
                                    <Typography>{shipment?.customerCode ? shipment?.customerCode : "---"} </Typography>
                                </TableCell>
                                <TableCell>
                                    {formatOrderNumbers().map(item => {
                                        return <Typography>{item}</Typography>
                                    })}
                                </TableCell>
                                <TableCell>
                                    <Typography>{shipment?.account ? shipment?.account.accountCorporateCode : "---"} </Typography>
                                    <Typography>{shipment?.contract ? shipment?.contract.code : ""} </Typography>
                                    <Typography>{shipment?.subContract ? shipment?.subContract.code : "---"} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{shipment?.account ? shipment?.account.companyName : "---"} </Typography>
                                    <Typography>{shipment?.senderAddress ? shipment?.senderAddress.companyName : "---"} </Typography>
                                </TableCell>
                                <TableCell>
                                    {shipment?.deliveryAddress ?
                                        <>
                                            <Typography>{(shipment?.deliveryAddress.companyName ? shipment?.deliveryAddress.companyName : "") + (shipment?.deliveryAddress.referencePerson ? " " + shipment?.deliveryAddress.referencePerson : "")} </Typography>
                                            <Typography>{(shipment?.deliveryAddress.street ? shipment?.deliveryAddress.street : "") + (shipment?.deliveryAddress.buildingNr ? ", " + shipment?.deliveryAddress.buildingNr : "")} </Typography>
                                            <Typography>{(shipment?.deliveryAddress.postalCode ? shipment?.deliveryAddress.postalCode : "") + (shipment?.deliveryAddress.city ? " " + shipment?.deliveryAddress.city : "") + (shipment?.deliveryAddress.administrativeLevel3 ? " (" + shipment?.deliveryAddress.administrativeLevel3 + ")" : "")}</Typography>
                                        </>
                                        :
                                        null
                                    }
                                </TableCell>
                            </TableRow>
                        </Table>
                        <div>
                            <DialogTitle>{t("warehouse_storage_notes")}</DialogTitle>
                            <Typography style={{marginLeft: "2%"}}>{operatorNotes}</Typography>
                        </div>
                        <DialogTitle>{t("provisions")}</DialogTitle>

                        {landing ?
                            <div style={{width: "100%", paddingBottom: "5%", display: "flex"}}>

                                <Typography variant={"button"}
                                            style={{margin: "auto"}}><CheckChip>{t("provision_successfully_sent")}</CheckChip></Typography>

                            </div>

                            :
                            <>
                                {!hasProvision ?
                                    <>
                                        <Autocomplete
                                            id="releaseMode"
                                            size="small"
                                            style={{
                                                minWidth: "30%",
                                                width: "fit-content",
                                                margin: "auto"
                                            }}
                                            options={releaseModes}
                                            getOptionLabel={option => option.description}
                                            value={releaseMode}
                                            defaultValue={releaseMode}
                                            onChange={handleReleaseModeChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("release_mode")}
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={releaseMode}
                                                    defaultValue={releaseMode}
                                                />
                                            )}
                                        />

                                        {releaseMode && releaseMode.id === 3 ?
                                            <div>
                                                <TableRow style={{display: "flex", margin: "5%"}}>
                                                    <Autocomplete
                                                        id="service"
                                                        size="small"
                                                        style={{
                                                            minWidth: "30%",
                                                            width: "fit-content",
                                                            margin: "auto",
                                                            marginBottom: "3%"
                                                        }}
                                                        options={services}
                                                        getOptionLabel={option => option.code + " - " + option.name}
                                                        value={service}
                                                        defaultValue={service}
                                                        onOpen={fetchServices}
                                                        onChange={handleServiceChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t("service")}
                                                                variant="outlined"
                                                                margin="dense"
                                                                value={service}
                                                                defaultValue={service}
                                                            />
                                                        )}
                                                    />
                                                </TableRow>
                                                <TableRow style={{display: "flex", margin: "5%"}}>

                                                    <div style={{width: "100%", display: "flex"}}>
                                                        <Card elevation={1} style={{width: "70%", margin: "auto"}}>
                                                            <DialogTitle
                                                                style={{
                                                                    display: "flex",
                                                                    width: "100%",
                                                                    justifyContent: "space-between"
                                                                }}>

                                                                <Typography style={{marginTop: "2%"}}
                                                                            variant={"button"}>
                                                                    {t("delivery_address")}
                                                                </Typography>

                                                                {/*<Stack direction="row" spacing={0.5} alignItems="center">*/}
                                                                {/*    <Tooltip title={t("Imposta destinatario uguale a consegna")}>*/}
                                                                {/*        <ContentCopy/>*/}
                                                                {/*    </Tooltip>*/}

                                                                {/*    <FormControlLabel*/}
                                                                {/*        control={<Switch style={{color: "primary"}}*/}
                                                                {/*                         checked={!changeEndUser}*/}
                                                                {/*                         onClick={changeEndUserEqualDelivery}*/}
                                                                {/*                         inputProps={{'aria-label': 'ant design'}}/>}*/}

                                                                {/*        // <Typography style={{width : "30%", textAlign: "left"}} variant={"caption"}>{t("Imposta indirizzo destinatario uguale all'indirizzo di consegna")}</Typography>*/}
                                                                {/*        label={""}/>*/}

                                                                {/*    <Tooltip title={t("Modifica destinatario")}>*/}
                                                                {/*        <Edit/>*/}
                                                                {/*    </Tooltip>*/}
                                                                {/*</Stack>*/}
                                                            </DialogTitle>
                                                            <div>

                                                                <Autocomplete
                                                                    id="country"
                                                                    size="small"
                                                                    style={{
                                                                        minWidth: "35%",
                                                                        width: "fit-content",
                                                                        marginBottom: "2%",
                                                                        marginLeft: "2%"
                                                                    }}
                                                                    options={countries}
                                                                    getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                                    value={deliveryCountry}
                                                                    defaultValue={deliveryCountry}
                                                                    onChange={handleDeliveryCountryChange}
                                                                    onSelect={fetchCountries}
                                                                    ListboxProps={{style: {maxHeight: '15rem'},}}
                                                                    noOptionsText={t("no_options")}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        label={t("country")}
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        value={deliveryCountry}
                                                                        defaultValue={deliveryCountry}
                                                                        required
                                                                        error={!deliveryCountry}
                                                                        helperText={!deliveryCountry ? t("required") : null}
                                                                    />}
                                                                />

                                                                {generateDeliveryAddress(deliveryCountry)}

                                                                <div style={{display: "flex", marginBottom: "5%"}}>

                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("street")}
                                                                        variant={"outlined"}

                                                                        size={"small"}
                                                                        value={deliveryStreet}
                                                                        required
                                                                        error={!deliveryStreet}
                                                                        helperText={!deliveryStreet ? t("required") : null}
                                                                        onChange={handleDeliveryStreetChange}
                                                                    />
                                                                    <TextField
                                                                        style={{marginInline: "2%", width: "10%"}}
                                                                        label={t("buildingNr")}
                                                                        variant={"outlined"}

                                                                        size={"small"}
                                                                        value={deliveryBuildingNr}
                                                                        onChange={handleDeliveryBuildingNrChange}
                                                                    />

                                                                </div>
                                                                <div style={{display: "flex", marginBottom: "5%"}}>
                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("shipment_company_name")}
                                                                        variant={"outlined"}
                                                                        size={"small"}
                                                                        value={deliveryCompanyName}
                                                                        onChange={handleDeliveryCompanyNameChange}
                                                                    />
                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("shipment_reference_person")}
                                                                        variant={"outlined"}
                                                                        size={"small"}
                                                                        value={deliveryReferencePerson}
                                                                        onChange={handleDeliveryReferencePerson}
                                                                    />
                                                                </div>
                                                                <div style={{display: "flex", marginBottom: "5%"}}>
                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("email")}
                                                                        variant={"outlined"}
                                                                        size={"small"}
                                                                        value={deliveryEmail}
                                                                        onChange={handleDeliveryEmail}
                                                                    />
                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("phone")}
                                                                        variant={"outlined"}
                                                                        size={"small"}
                                                                        value={deliveryPhone}
                                                                        onChange={handleDeliveryPhone}
                                                                    />
                                                                    <TextField
                                                                        style={{marginInline: "2%"}}
                                                                        label={t("mobile")}
                                                                        variant={"outlined"}
                                                                        size={"small"}
                                                                        value={deliveryMobile}
                                                                        onChange={handleDeliveryMobile}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </TableRow>
                                                <TableRow style={{display: "flex", margin: "5%"}}>
                                                    <div style={{width: "100%", display: "flex"}}>
                                                        <FormControlLabel
                                                            style={{margin: "auto"}}
                                                            label={<Typography fontSize={12}
                                                                               variant={"button"}>{t("set_destination")}</Typography>}
                                                            control={
                                                                <Checkbox
                                                                    checked={changeEndUser}
                                                                    onChange={changeEndUserEqualDelivery}
                                                                />
                                                            }
                                                        />
                                                    </div>

                                                </TableRow>
                                                <TableRow style={{display: "flex", margin: "5%"}}>
                                                    <div style={{width: "100%", display: "flex"}}>
                                                        {changeEndUser ?
                                                            <Card elevation={1} style={{width: "70%", margin: "auto"}}>
                                                                <DialogTitle
                                                                    style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        justifyContent: "space-between"
                                                                    }}>

                                                                    <Typography style={{marginTop: "2%"}}
                                                                                variant={"button"}>
                                                                        {t("end_user")}
                                                                    </Typography>

                                                                    {/*<Stack direction="row" spacing={0.5} alignItems="center">*/}
                                                                    {/*    <Tooltip title={t("Imposta destinatario uguale a consegna")}>*/}
                                                                    {/*        <ContentCopy/>*/}
                                                                    {/*    </Tooltip>*/}

                                                                    {/*    <FormControlLabel*/}
                                                                    {/*        control={<Switch style={{color: "primary"}}*/}
                                                                    {/*                         checked={!endUserEqualDelivery}*/}
                                                                    {/*                         onClick={changeEndUserEqualDelivery}*/}
                                                                    {/*                         inputProps={{'aria-label': 'ant design'}}/>}*/}

                                                                    {/*        // <Typography style={{width : "30%", textAlign: "left"}} variant={"caption"}>{t("Imposta indirizzo destinatario uguale all'indirizzo di consegna")}</Typography>*/}
                                                                    {/*        label={""}/>*/}

                                                                    {/*    <Tooltip title={t("Modifica destinatario")}>*/}
                                                                    {/*        <Edit/>*/}
                                                                    {/*    </Tooltip>*/}
                                                                    {/*</Stack>*/}
                                                                </DialogTitle>
                                                                <div>

                                                                    <Autocomplete
                                                                        id="country"
                                                                        size="small"
                                                                        style={{
                                                                            minWidth: "35%",
                                                                            width: "fit-content",
                                                                            marginBottom: "2%",
                                                                            marginLeft: "2%"
                                                                        }}
                                                                        options={countries}
                                                                        disabled={!changeEndUser}
                                                                        getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                                                        value={endUserCountry}
                                                                        defaultValue={endUserCountry}
                                                                        onChange={handleEndUserCountryChange}
                                                                        onSelect={fetchCountries}
                                                                        ListboxProps={{style: {maxHeight: '15rem'},}}
                                                                        noOptionsText={t("no_options")}
                                                                        renderInput={(params) => <TextField
                                                                            {...params}
                                                                            label={t("country")}
                                                                            variant="outlined"
                                                                            margin="dense"
                                                                            value={endUserCountry}
                                                                            defaultValue={endUserCountry}
                                                                            required
                                                                            error={!endUserCountry}
                                                                            helperText={!endUserCountry ? t("required") : null}
                                                                        />}
                                                                    />

                                                                    {generateEndUserAddress(endUserCountry)}

                                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                                        <TextField
                                                                            style={{marginInline: "2%"}}
                                                                            disabled={!changeEndUser}
                                                                            label={t("street")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserStreet}
                                                                            required
                                                                            error={!endUserStreet}
                                                                            helperText={!endUserStreet ? t("required") : null}
                                                                            onChange={handleEndUserStreetChange}
                                                                        />
                                                                        <TextField
                                                                            style={{marginInline: "2%", width: "10%"}}
                                                                            label={t("buildingNr")}
                                                                            disabled={!changeEndUser}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserBuildingNr}
                                                                            onChange={handleEndUserBuildingNrChange}
                                                                        />
                                                                    </div>
                                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                                        <TextField
                                                                            style={{marginInline: "2%"}}
                                                                            disabled={!changeEndUser}
                                                                            label={t("shipment_company_name")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserCompanyName}
                                                                            onChange={handleEndUserCompanyNameChange}
                                                                        />

                                                                        <TextField
                                                                            style={{marginInline: "2%"}}
                                                                            disabled={!changeEndUser}
                                                                            label={t("shipment_reference_person")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserReferencePerson}
                                                                            onChange={handleEndUserReferencePerson}
                                                                        />
                                                                    </div>
                                                                    <div style={{display: "flex", marginBottom: "5%"}}>
                                                                        <TextField
                                                                            disabled={!changeEndUser}
                                                                            style={{marginInline: "2%"}}
                                                                            label={t("email")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserEmail}
                                                                            onChange={handleEndUserEmail}
                                                                        />
                                                                        <TextField
                                                                            disabled={!changeEndUser}
                                                                            style={{marginInline: "2%"}}
                                                                            label={t("phone")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserPhone}
                                                                            onChange={handleEndUserPhone}
                                                                        />
                                                                        <TextField
                                                                            disabled={!changeEndUser}
                                                                            style={{marginInline: "2%"}}
                                                                            label={t("mobile")}
                                                                            variant={"outlined"}
                                                                            size={"small"}
                                                                            value={endUserMobile}
                                                                            onChange={handleEndUserMobile}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </TableRow>
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{display: "flex"}}>
                                            <TextField
                                                style={{width: "60%", margin: "auto", marginTop: "5%"}}
                                                label={t("notes")}
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleNotesChange}
                                                value={notes}
                                                multiline
                                                maxRows={4}
                                                // className={classes.field}
                                            />

                                        </div>
                                        <div style={{margin: "5%", paddingBottom: "4%", display: "flex"}}>
                                            <Button style={{margin: "auto"}} onClick={sendProvision}
                                                    endIcon={<Send/>}>{t("send")}</Button>

                                        </div>

                                    </>

                                    :

                                    <div style={{width: "100%", paddingBottom: "5%", display: "flex"}}>
                                        <InfoChip><Typography variant={"button"}
                                                              style={{margin: "auto"}}>{t("provision_already_received")}</Typography></InfoChip>

                                    </div>

                                }

                            </>
                        }

                    </Card>


                </div>
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>
            </>
        }
    </>


}

export default WarehouseStorageProvisionPage;