import React from 'react';
import {CircularProgress, Container, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";


export const LoadingComponent = (props) => {


    const {t} = useTranslation();
    const useStyle = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "flex",
            // minHeight: 587,

        },
        loadingItem:{
            width: '30%',
            margin: "auto",
            marginTop: "3%",
            marginBottom: "3%",
            display: "block",


        },
        loadingContainer:{
            width: '100%',
            alignSelf: "center"

        }
    })

    const classes = useStyle();



    return <div className={classes.root}>
            <Container className={classes.loadingContainer} variant="outlined">

                <Typography className={classes.loadingItem} variant="button">{t("fetching_data")}</Typography>
                <Typography className={classes.loadingItem} variant="button">{t("please_wait")}</Typography>
                <CircularProgress className={classes.loadingItem} color="primary" size={70}/>

            </Container>

    </div>

};