import {
    Backdrop,
    Button,
    Card,
    CardActions,
    DialogTitle,
    Fade,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import WarehouseService from "../warehouses/WarehouseService";
import {ArrowBackOutlined, CancelOutlined, CheckCircleOutlined, SaveOutlined} from "@material-ui/icons";
import {Autocomplete, IconButton} from "@mui/material";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import ServiceService from "../shipments/services/ServiceService";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import HamletService from "../geo/hamlets/HamletService";
import {makeStyles} from "@material-ui/core/styles";
import Notification from "../components/notifications/Notification";
import ZoneService from "./ZoneService";
import {useHistory, useParams} from "react-router-dom";
import theme from "../theme/theme";
import ContractService from "../accounts/contracts/ContractService";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        minWidth: 920,
        marginTop: "5%"
    },
    name_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '25%',
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '50%',
    },
    postal_field: {
        display: "flex",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "2%",
        width: '40%',
    },
    div: {
        display: "inline"
    },
    label: {
        width: "15%",
        margin: "auto",
        display: "flex"
    },
    formControl: {
        marginLeft: "auto",
        marginRight: "auto",
        margin: theme.spacing(1),
        marginTop: "10%",
        width: "30%",
    },
    chips: {
        display: 'block',
        // flexWrap: 'wrap',
    },
    chip: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        margin: "auto",
        alignSelf: "center"
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    },
    select: {
        width: `50%`,
        marginTop: `1%`
    },
    typo: {
        marginTop: `3%`
    },
    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "4%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    create_button: {
        marginInline: "4%",
        width: "25%",
        backgroundColor: "whitesmoke"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }

}));

function ZoneForm(props) {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const {id} = useParams();

    const history = useHistory();

    const classes = useStyles();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    const [code, setCode] = useState('')
    const [warehouse, setWarehouse] = useState(null);
    const [routingRules, setRoutingRules] = useState([]);

    const [warehouses, setWarehouses] = useState([]);

    const [newAreaShow, setNewAreaShow] = useState(false);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [hamlets, setHamlets] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [services, setServices] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [adminL3, setAdminL3] = useState(null);
    const [adminL2, setAdminL2] = useState(null);
    const [adminL1, setAdminL1] = useState(null);
    const [city, setCity] = useState(null);
    const [selectedPostalCodes, setSelectedPostalCodes] = useState([]);
    const [hamlet, setHamlet] = useState(null);
    const [service, setService] = useState(null);
    const [country, setCountry] = useState(null);
    const [contract, setContract] = useState(null);

    async function fetchZone() {

        setLoading(true);

        await new ZoneService().getById(id)
            .then(async zone => {
                setCode(zone.code);

                await new WarehouseService().getWarehousesByFilters(0, 1000, null)
                    .then(result => {
                        setWarehouse(result.warehouses.filter(w => w.code === zone.warehouseCode)[0]);
                    })

                setRoutingRules(zone.routingRules);

                setLoading(false);
            })
            .catch(error => {

            })

    }

    async function fetchContracts() {
        let contr = await new ContractService().getContractsByFilters();
        setContracts(contr);
    }


    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters(0, 1000);
        setWarehouses(wares.warehouses);
        if(wares?.warehouses?.length === 1){
            setWarehouse(wares.warehouses[0]);
        }
    }

    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL1() {
        let adminL1Service = new AdministrativeLevel1Service();

        let filters = {};

        if (country) {
            filters.countryId = country.id;
        }


        adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null)
            .then(data => {
                setAdmin1List(data.admins1);
            })
    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    function fetchAdminL2() {
        let adminL2Service = new AdministrativeLevel2Service();
        if (adminL1) {
            let filters = {}
            filters.adminL1 = adminL1.id;
            adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        } else {
            adminL2Service.getAllAdminLevel2WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin2List(data.admins2);
                })
        }
    }

    function fetchAdminL3() {
        let adminL3Service = new AdministrativeLevel3Service();
        if (adminL2) {
            let filters = {};
            filters.adminL2 = adminL2.id;
            adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        } else {
            adminL3Service.getAllAdminLevel3WithFilters(null, null, null, null)
                .then(data => {
                    setAdmin3List(data.admins3);
                })
        }
    }

    function fetchCities() {
        let cityService = new CityService();

        if (adminL3) {
            let filters = {}
            filters.level3Id = adminL3.id;
            cityService.getCities(null, null, filters, null)
                .then(data => {
                    setCities(data.cities)
                })
        } else {
            cityService.getCities(null, null, null, null)
                .then(data => {
                    setCities(data.cities)
                })
        }
    }

    function fetchCAP() {
        let postalCodeService = new PostalCodeService();

        if (city) {
            let filters = {}
            filters.cityId = city.id;
            postalCodeService.getAllPostalCodes(null, null, filters, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        } else {
            postalCodeService.getAllPostalCodes(null, null, null, null)
                .then(data => {
                    setPostalCodes(data.postalCodes)
                })
        }
    }

    function fetchHamlets() {
        let hamletService = new HamletService();

        if (city) {
            let filters = {}
            filters.cityId = city.id;

            hamletService.getHamlets(null, null, filters)
                .then(data => {
                    setHamlets(data.hamlets);
                })

        } else {
            hamletService.getHamlets()
                .then(data => {
                    setHamlets(data.hamlets);
                })
        }
    }

    function cancelNewArea() {
        setNewAreaShow(false);
    }

    function saveTempRoutingRule(_continue) {

        if (selectedPostalCodes.length > 0) {
            for (let i = 0; i < selectedPostalCodes.length; i++) {

                let newArea = {};

                if (null != contract) {
                    newArea.contract = contract.code;
                    newArea.contractId = contract.id;
                }

                if (null != service) {
                    newArea.service = service.name;
                    newArea.serviceId = service.id;
                }
                if (null != country) {
                    newArea.country = country.name;
                    newArea.countryId = country.id;
                }
                if (null != adminL1) {
                    newArea.administrativeLevel1 = adminL1.name;
                    newArea.administrativeLevel1Id = adminL1.id;
                }
                if (null != adminL2) {
                    newArea.administrativeLevel2 = adminL2.name;
                    newArea.administrativeLevel2Id = adminL2.id;
                }
                if (null != adminL3) {
                    newArea.administrativeLevel3 = adminL3.name;
                    newArea.administrativeLevel3Id = adminL3.id;
                }
                if (null != city) {
                    newArea.city = city.name;
                    newArea.cityId = city.id;
                }
                if (selectedPostalCodes.length > 0) {
                    newArea.postalCode = selectedPostalCodes[i].postalCode;
                    newArea.postalCodeId = selectedPostalCodes[i].id;
                }
                if (null != hamlet) {
                    newArea.hamlet = hamlet.name;
                    newArea.hamletId = hamlet.id;
                }

                if (newArea.service == null) {
                    setNotificationMessage(t("service_is_mandatory"));
                    setNotifySeverity("error")
                    setOpenNotify(true);
                } else if (checkRoutingRuleAlreadySelected(routingRules, newArea)) {
                    setNotificationMessage(t("area_already_exists"));
                    setNotifySeverity("error")
                    setOpenNotify(true);
                } else {
                    routingRules.push(newArea);
                    if (_continue) {
                        setNotificationMessage(t("area_successfully_added"));
                        setNotifySeverity("success")
                        setOpenNotify(true);
                    } else {
                        setNewAreaShow(false);
                    }


                    setRefresh(!refresh);
                }

            }
        } else {
            let newArea = {};

            if (null != contract) {
                newArea.contract = contract.code;
                newArea.contractId = contract.id;
            }

            if (null != service) {
                newArea.service = service.name;
                newArea.serviceId = service.id;
            }
            if (null != country) {
                newArea.country = country.name;
                newArea.countryId = country.id;
            }
            if (null != adminL1) {
                newArea.administrativeLevel1 = adminL1.name;
                newArea.administrativeLevel1Id = adminL1.id;
            }
            if (null != adminL2) {
                newArea.administrativeLevel2 = adminL2.name;
                newArea.administrativeLevel2Id = adminL2.id;
            }
            if (null != adminL3) {
                newArea.administrativeLevel3 = adminL3.name;
                newArea.administrativeLevel3Id = adminL3.id;
            }
            if (null != city) {
                newArea.city = city.name;
                newArea.cityId = city.id;
            }

            if (newArea.service == null) {
                setNotificationMessage(t("service_is_mandatory"));
                setNotifySeverity("error")
                setOpenNotify(true);
            } else if (checkRoutingRuleAlreadySelected(routingRules, newArea)) {
                setNotificationMessage(t("area_already_exists"));
                setNotifySeverity("error")
                setOpenNotify(true);
            } else {
                routingRules.push(newArea);
                if (_continue) {
                    setNotificationMessage(t("area_successfully_added"));
                    setNotifySeverity("success")
                    setOpenNotify(true);
                } else {
                    setNewAreaShow(false);
                }


                setRefresh(!refresh);
            }
        }


    }

    function minLength(obj1, obj2) {
        if (obj1.length < obj2.length) {
            return obj1;
        } else {
            return obj2;
        }
    }

    //confronto oggetti. Gli oggetti devono avere gli stessi attributi, altrimenti risultano non uguali
    function deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        const minKeys = minLength(keys1, keys2);

        for (const key of minKeys) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (
                (areObjects && !deepEqual(val1, val2)) ||
                (!areObjects && (val1 !== null && val2 !== null && val1.toString().toLowerCase() !== val2.toString().toLowerCase()))
            ) {
                return false;
            }
        }
        return true;
    }

    function isObject(object) {
        return object != null && typeof object === 'object';
    }


    function checkRoutingRuleAlreadySelected(selectedRoutingRules, newRoutingRule) {

        for (let i = 0; i < selectedRoutingRules.length; i++) {
            let compareRoutingRule = selectedRoutingRules[i];

            if (deepEqual(compareRoutingRule, newRoutingRule)) {
                return true;
            }

        }

        return false;

    }

    function clearRoutingRuleForm() {
        handleServiceChange(null, null);
        handleCountryChange(null, null);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }


    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setAdminL2(null);
        setAdminL1(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL1Change(event, adminL1) {

        setAdminL1(adminL1);
        setAdminL2(null)
        setAdminL3(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL2Change(event, adminL2) {

        setAdminL2(adminL2);
        setAdminL3(null);
        setCity(null);
        setSelectedPostalCodes([]);
        setHamlet(null);


    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setHamlet(null);
        setSelectedPostalCodes([]);


    }

    function handleCityChange(event, city) {
        setCity(city);
        setSelectedPostalCodes([]);
        setHamlet(null);
    }

    function handleHamletChange(event, hamlet) {
        setHamlet(hamlet);
    }

    function handlePostalCodeChange(event, postalCodes) {

        setSelectedPostalCodes(postalCodes)

        console.log(selectedPostalCodes);
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function removeArea(area) {
        let array = routingRules;
        let index = array.indexOf(area)
        if (index !== -1) {
            array.splice(index, 1);
            setRoutingRules(array);
        }
        setRefresh(!refresh)
    }

    // function formatArea(area) {
    //
    //     let obj = {};
    //
    //     obj.service = t("service") + ": " + area.service?.code;
    //
    //
    //     if (area.hamlet) {
    //         obj.area = t("hamlet") + ": " + area.hamlet.name;
    //     } else if (area.postalCode) {
    //         obj.area = t("postalCode") + ": " + area.postalCode.postalCode;
    //     } else if (area.postalCodes?.length > 0) {
    //         obj.area = t("postalCode") + ": ";
    //         for (let i = 0; i < area.postalCodes.length; i++) {
    //             if (i > 0) {
    //                 obj.area += ", ";
    //             }
    //             obj.area += area.postalCodes[i]?.postalCode;
    //         }
    //
    //     } else if (area.city) {
    //         obj.area = t("city") + ": " + area.city.name;
    //     } else if (area.administrativeLevel3) {
    //         obj.area = t("all_admin_3") + ": " + area.administrativeLevel3.name;
    //     } else if (area.administrativeLevel2) {
    //         obj.area = t("all_admin_2") + ": " + area.administrativeLevel2.name;
    //     } else if (area.administrativeLevel1) {
    //         obj.area = t("all_admin_1") + ": " + area.administrativeLevel1.name;
    //     } else if (area.country) {
    //         obj.area = t("all_country") + ": " + area.country.name;
    //     } else {
    //         obj.area = t("all_world");
    //     }
    //
    //     return obj;
    // }

    function createAreasInput(areas) {
        let newAreas = [];

        areas.map((area) => {
            let newArea = {};

            newArea.contractId = null;
            if (null != area.contractId) {
                newArea.contractId = area.contractId;
            }
            newArea.serviceId = null;
            if (null != area.serviceId) {
                newArea.serviceId = area.serviceId;
            }
            newArea.countryId = null;
            if (null != area.countryId) {
                newArea.countryId = area.countryId;
            }
            newArea.administrativeLevel1Id = null;
            if (null != area.administrativeLevel1Id) {
                newArea.administrativeLevel1Id = area.administrativeLevel1Id;
            }
            newArea.administrativeLevel2Id = null;
            if (null != area.administrativeLevel2Id) {
                newArea.administrativeLevel2Id = area.administrativeLevel2Id;
            }
            newArea.administrativeLevel3Id = null;
            if (null != area.administrativeLevel3Id) {
                newArea.administrativeLevel3Id = area.administrativeLevel3Id;
            }
            newArea.cityId = null;
            if (null != area.cityId) {
                newArea.cityId = area.cityId;
            }
            // newArea.postalCodes = [];
            // if (area.postalCodes?.length > 0) {
            //     newArea.postalCodes = area.postalCodes.map(postalCode => postalCode.id);
            if (null != area.postalCodeId) {
                newArea.postalCodeId = area.postalCodeId;
            }
            newArea.hamletId = null;
            if (null != area.hamletId) {
                newArea.hamletId = area.hamletId;
            }

            newAreas.push(newArea);
        })

        return newAreas;
    }

    function submitNewZone(event) {
        event.preventDefault();

        let areas = createAreasInput(routingRules);

        let zoneService = new ZoneService();

        if (id) {
            zoneService.editZone(id, code, warehouse?.id, areas)
                .then(() => {
                    setNotifySeverity('success')
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true)
                    setTimeout(() => {
                        history.push("/zones")
                    }, 2000)

                })
                .catch((error) => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });

                })
        } else {
            zoneService.addZone(code, warehouse?.id, areas)
                .then(() => {
                    setNotifySeverity('success')
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true)
                    setTimeout(() => {
                        history.push("/zones")
                    }, 2000)

                })
                .catch((error) => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });

                })
        }


    }

    function handleCodeChange(event) {
        if (event.target.value.length < 5) {
            setCode(event.target.value);
        }
    }

    useEffect(() => {
        fetchWarehouses();
        if (id) {
            fetchZone();
        }

    }, [])

    return <div style={{width: "100%"}}>
        {loading ?

            <LoadingComponent/>

            :

            <Paper>
                <DialogTitle>{t("zone_data")}</DialogTitle>

                <TextField
                    required
                    label={t("code")}
                    variant="outlined"
                    type="text"
                    margin="dense"
                    onChange={handleCodeChange}
                    value={code}
                    error={!code}
                    style={{width: "25%", margin: "auto", marginBottom: "2%"}}
                />
                <Autocomplete
                    id="warehouse-select"
                    style={{width: "25%", margin: "auto", marginBottom: "2%"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={(event, warehouse) => setWarehouse(warehouse)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!warehouse}
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <div style={{width: "100%"}}>
                    <Card style={{width: "90%", margin: "auto"}}>
                        <div style={{display: "flex"}}>
                            <DialogTitle style={{margin: "auto", alignSelf: "center"}}>{t("selected_routing_rules")}
                                <IconButton
                                    variant={"contained"}
                                    style={{color: theme.palette.primary.main, marginLeft: 5}}
                                    onClick={() => setNewAreaShow(!newAreaShow)}>

                                    <AddCircleIcon/>

                                </IconButton>
                            </DialogTitle>

                        </div>

                        {routingRules?.length > 0 ?
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableCell>{t("contract")}</TableCell>
                                    <TableCell>{t("service")}</TableCell>
                                    <TableCell>{t("country")}</TableCell>
                                    <TableCell>{t("administrative1")}</TableCell>
                                    <TableCell>{t("administrative2")}</TableCell>
                                    <TableCell>{t("administrative3")}</TableCell>
                                    <TableCell>{t("city")}</TableCell>
                                    <TableCell>{t("postalCode")}</TableCell>
                                    <TableCell/>

                                </TableHead>

                                <TableBody>
                                    {routingRules.map((area, index) => {
                                        return <TableRow>
                                            <TableCell>
                                                {area.contract || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.service || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.country || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.administrativeLevel1 || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.administrativeLevel2 || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.administrativeLevel3 || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.city || `*`}
                                            </TableCell>
                                            <TableCell>
                                                {area.postalCode || `*`}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton style={{color: theme.palette.primary.main}}
                                                            onClick={() => removeArea(area)}><CancelOutlined/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    })}


                                </TableBody>
                            </Table>
                            :
                            null
                        }
                    </Card>
                    <div style={{margin: "auto", marginBottom: "2%"}}>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={newAreaShow}
                            onClose={cancelNewArea}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            {/*aaa*/}
                            <Fade in={newAreaShow}>
                                <Card className={classes.formControl}>
                                    <Autocomplete
                                        id="contract"
                                        className={classes.field}
                                        options={contracts}
                                        getOptionLabel={option => option.code + " - " + option.name}
                                        value={contract}
                                        defaultValue={contract}
                                        onOpen={fetchContracts}
                                        onChange={handleContractChange}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("contract")}
                                                variant="outlined"
                                                margin="dense"
                                                value={contract}
                                                defaultValue={contract}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="service"

                                        className={classes.field}
                                        options={services}
                                        getOptionLabel={option => option.name}
                                        value={service}
                                        defaultValue={service}
                                        onOpen={fetchServices}
                                        onChange={handleServiceChange}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("service")}
                                                variant="outlined"
                                                margin="dense"
                                                value={service}
                                                defaultValue={service}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="country"
                                        className={classes.field}
                                        options={countries}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        getOptionLabel={option => option.name}
                                        value={country}
                                        defaultValue={country}
                                        onOpen={fetchCountries}
                                        onChange={handleCountryChange}
                                        onBlur={fetchAdminL1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("country")}
                                                variant="outlined"
                                                margin="dense"
                                                value={option => option.iso3166Alpha3}
                                                defaultValue={country}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="administrative1"
                                        disabled={!country}
                                        className={classes.field}
                                        options={admin1List}
                                        getOptionLabel={option => option.name}
                                        value={adminL1}
                                        defaultValue={adminL1}
                                        onOpen={fetchAdminL1}
                                        onChange={handleAdminL1Change}
                                        loading={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("administrative1")}
                                                variant="outlined"
                                                margin="dense"
                                                value={adminL1}
                                                defaultValue={adminL1}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="administrative2"
                                        disabled={!adminL1}
                                        className={classes.field}
                                        options={admin2List}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={adminL2}
                                        onOpen={fetchAdminL2}
                                        onChange={handleAdminL2Change}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("administrative2")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={adminL2}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="administrative3"
                                        disabled={!adminL2}
                                        className={classes.field}
                                        options={admin3List}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={adminL3}
                                        onOpen={fetchAdminL3}
                                        onChange={handleAdminL3Change}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("administrative3")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={adminL3}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="city"
                                        disabled={!adminL3}
                                        className={classes.field}
                                        options={cities}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={city}
                                        onOpen={fetchCities}
                                        onChange={handleCityChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("city")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={city}
                                            />
                                        )}
                                    />

                                    {/*<Autocomplete*/}
                                    {/*    id="postalcode"*/}
                                    {/*    disabled={!city}*/}
                                    {/*    className={classes.field}*/}
                                    {/*    options={postalCodes}*/}
                                    {/*    disableCloseOnSelect*/}
                                    {/*    getOptionLabel={option => option.postalCode}*/}
                                    {/*    getOptionSelected={(option, value) => option.postalCode === value.postalCode}*/}
                                    {/*    value={selectedPostalCodes}*/}
                                    {/*    multiple={true}*/}
                                    {/*    isOptionEqualToValue={(option, value) => option.postalCode === value.postalCode}*/}
                                    {/*    onOpen={fetchCAP}*/}
                                    {/*    onChange={handlePostalCodeChange}*/}
                                    {/*    renderInput={params => (*/}
                                    {/*        <TextField*/}
                                    {/*            {...params}*/}
                                    {/*            label={t("postalCode")}*/}
                                    {/*            variant="outlined"*/}
                                    {/*            margin="dense"*/}
                                    {/*            defaultValue={selectedPostalCodes}*/}
                                    {/*        />*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={postalCodes}
                                        style={{width: "50%", margin: "auto"}}
                                        disabled={!city}
                                        disableCloseOnSelect={true}
                                        getOptionLabel={(option) => option.postalCode}
                                        value={selectedPostalCodes}
                                        onChange={handlePostalCodeChange}
                                        onOpen={fetchCAP}
                                        autoHighlight
                                        isOptionEqualToValue={(option, value) => option.postalCode === value.postalCode}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={t("postalCodes")}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="hamlet"
                                        disabled={!city}
                                        className={classes.field}
                                        options={hamlets}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        value={hamlet}
                                        onOpen={fetchHamlets}
                                        onChange={handleHamletChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t("hamlet")}
                                                variant="outlined"
                                                margin="dense"
                                                defaultValue={hamlet}
                                            />
                                        )}
                                    />


                                    <CardActions className={classes.button_container}>
                                        <Button
                                            className={classes.cancel_button}
                                            startIcon={<ArrowBackOutlined/>}
                                            onClick={cancelNewArea}
                                            variant={"contained"}>
                                            {t("back")}
                                        </Button>
                                        <Button
                                            className={classes.confirm_button}
                                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                            onClick={() => saveTempRoutingRule(true)}
                                            color="primary"
                                            variant={"contained"}>
                                            {t("add_area_and_continue")}
                                        </Button>
                                        <Button
                                            className={classes.confirm_button}
                                            endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                                            onClick={() => saveTempRoutingRule(false)}
                                            color="primary"
                                            variant={"contained"}>
                                            {t("add_area")}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Fade>
                        </Modal>
                        {/*{newAreaShow ?*/}
                        {/*    <Card className={classes.formControl}>*/}
                        {/*        <DialogTitle>{t("new_area")}</DialogTitle>*/}

                        {/*        <Autocomplete*/}
                        {/*            id="service"*/}

                        {/*            className={classes.field}*/}
                        {/*            options={services}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            value={service}*/}
                        {/*            defaultValue={service}*/}
                        {/*            onOpen={fetchServices}*/}
                        {/*            onChange={handleServiceChange}*/}
                        {/*            loading={true}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("service")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    value={service}*/}
                        {/*                    defaultValue={service}*/}
                        {/*                    inputProps={{*/}
                        {/*                        ...params.inputProps,*/}
                        {/*                        autoComplete: 'off', // disable autocomplete and autofill*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="country"*/}
                        {/*            className={classes.field}*/}
                        {/*            options={countries}*/}
                        {/*            classes={{*/}
                        {/*                option: classes.option,*/}
                        {/*            }}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            value={country}*/}
                        {/*            defaultValue={country}*/}
                        {/*            onOpen={fetchCountries}*/}
                        {/*            onChange={handleCountryChange}*/}
                        {/*            onBlur={fetchAdminL1}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("country")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    value={option => option.iso3166Alpha3}*/}
                        {/*                    defaultValue={country}*/}
                        {/*                    inputProps={{*/}
                        {/*                        ...params.inputProps,*/}
                        {/*                        autoComplete: 'off', // disable autocomplete and autofill*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="administrative1"*/}
                        {/*            disabled={country == null || country.id !== 106}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={admin1List}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            value={adminL1}*/}
                        {/*            defaultValue={adminL1}*/}
                        {/*            onOpen={fetchAdminL1}*/}
                        {/*            onChange={handleAdminL1Change}*/}
                        {/*            loading={true}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("administrative1")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    value={adminL1}*/}
                        {/*                    defaultValue={adminL1}*/}
                        {/*                    inputProps={{*/}
                        {/*                        ...params.inputProps,*/}
                        {/*                        autoComplete: 'off', // disable autocomplete and autofill*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="administrative2"*/}
                        {/*            disabled={!adminL1}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={admin2List}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            getOptionSelected={(option, value) => option.name === value.name}*/}
                        {/*            value={adminL2}*/}
                        {/*            onOpen={fetchAdminL2}*/}
                        {/*            onChange={handleAdminL2Change}*/}
                        {/*            renderInput={params => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("administrative2")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    defaultValue={adminL2}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="administrative3"*/}
                        {/*            disabled={!adminL2}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={admin3List}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            getOptionSelected={(option, value) => option.name === value.name}*/}
                        {/*            value={adminL3}*/}
                        {/*            onOpen={fetchAdminL3}*/}
                        {/*            onChange={handleAdminL3Change}*/}
                        {/*            renderInput={params => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("administrative3")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    defaultValue={adminL3}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="postalcode"*/}
                        {/*            disabled={!adminL3}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={postalCodes}*/}
                        {/*            getOptionLabel={option => option.postalCode}*/}
                        {/*            getOptionSelected={(option, value) => option.postalCode === value.postalCode}*/}
                        {/*            value={postalCode}*/}
                        {/*            onOpen={fetchCAP}*/}
                        {/*            onChange={handlePostalCodeChange}*/}
                        {/*            renderInput={params => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("postalCode")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    defaultValue={postalCode}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="city"*/}
                        {/*            disabled={!postalCode}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={cities}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            getOptionSelected={(option, value) => option.name === value.name}*/}
                        {/*            value={city}*/}
                        {/*            onOpen={fetchCities}*/}
                        {/*            onChange={handleCityChange}*/}
                        {/*            renderInput={params => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("city")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    defaultValue={city}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <Autocomplete*/}
                        {/*            id="hamlet"*/}
                        {/*            disabled={!city}*/}
                        {/*            className={classes.field}*/}
                        {/*            options={hamlets}*/}
                        {/*            getOptionLabel={option => option.name}*/}
                        {/*            getOptionSelected={(option, value) => option.name === value.name}*/}
                        {/*            value={hamlet}*/}
                        {/*            onOpen={fetchHamlets}*/}
                        {/*            onChange={handleHamletChange}*/}
                        {/*            renderInput={params => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    label={t("hamlet")}*/}
                        {/*                    variant="outlined"*/}
                        {/*                    margin="dense"*/}
                        {/*                    defaultValue={hamlet}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}


                        {/*        <CardActions className={classes.button_container}>*/}
                        {/*            <Button*/}
                        {/*                className={classes.cancel_button}*/}
                        {/*                endIcon={<CancelOutlined/>}*/}
                        {/*                onClick={cancelNewArea}*/}
                        {/*                variant={"contained"}>*/}
                        {/*                {t("cancel")}*/}
                        {/*            </Button>*/}
                        {/*            <Button*/}
                        {/*                className={classes.confirm_button}*/}
                        {/*                endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}*/}
                        {/*                onClick={saveTempRoutingRule}*/}
                        {/*                color="primary"*/}
                        {/*                variant={"contained"}>*/}
                        {/*                {t("add_area")}*/}
                        {/*            </Button>*/}
                        {/*        </CardActions>*/}
                        {/*    </Card>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}
                    </div>

                    {/*<Card style={{width: "45%", margin: "auto"}}>*/}
                    {/*    <DialogTitle>{t("selected_slots")}</DialogTitle>*/}
                    {/*    {selectedSlots.length > 0 ?*/}
                    {/*    <Table stickyHeader={true}>*/}
                    {/*        <TableHead>*/}
                    {/*            <TableCell>{t("fromTime")}</TableCell>*/}
                    {/*            <TableCell>{t("toTime")}</TableCell>*/}
                    {/*            <TableCell>{t("days")}</TableCell>*/}
                    {/*            <TableCell/>*/}
                    {/*        </TableHead>*/}
                    {/*        <TableBody>*/}
                    {/*            {selectedSlots.map((slot, index) => {*/}
                    {/*                return <TableRow>*/}
                    {/*                    <TableCell>{slot.fromTime}</TableCell>*/}
                    {/*                    <TableCell>{slot.toTime}</TableCell>*/}
                    {/*                    <TableCell>{slot.weekDays.map(day => {*/}
                    {/*                        return t(`${day.toLowerCase()}`) + " "*/}
                    {/*                    })*/}
                    {/*                    }</TableCell>*/}
                    {/*                    <TableCell>*/}
                    {/*                        <Button color={"primary"} endIcon={<CancelOutlined/>}*/}
                    {/*                                onClick={() => removeSlot(slot)}/>*/}
                    {/*                    </TableCell>*/}

                    {/*                </TableRow>*/}
                    {/*            })}*/}


                    {/*        </TableBody>*/}

                    {/*    </Table> :*/}
                    {/*    null*/}
                    {/*    }*/}
                    {/*</Card>*/}
                </div>

                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>
                <div style={{display: "flex"}}>
                    <Button style={{
                        margin: "auto", marginBlock: "2%", backgroundColor: theme.palette.primary.main,
                        color: "white",
                    }} onClick={submitNewZone} endIcon={<SaveOutlined/>}>{t("save")}</Button>
                </div>
            </Paper>

        }
    </div>


}

export default ZoneForm;