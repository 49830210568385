import {makeStyles} from "@material-ui/core/styles";

const AppStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            textAlign: 'center',
            // backgroundColor: theme.palette.background.paper,
        },
        hidden: {
            display: "none"
        },
        select: {
            width: "20%",
            margin: "auto"
        },
        select_div: {
            display: "block",
            margin: "auto",
            width: "100%",
            marginBlock: "2%"
        },
        centered_title: {
            margin: "auto"
        },
        divider: {
            width: "90%",
            marginInline: "auto",
            marginBlock: "1%",
        },
        flex_div: {
            display: "flex",
            margin: "auto",
            // width: "100%"
        },
        flex_div_2: {
            width: "fit-content",
            minWidth: "30%",
            margin: "auto"
        },
        button: {
            display: "flex",
            width: "20%",
            margin: "auto",
            marginBlock: "3%"
        },
        dark_blue_link: {
            cursor: "pointer",
            transition: "all 0.2s",
            // color: "#144dff",
            color: "#003d69",
            '& > *': {
                borderBottom: 'unset',
            },
        },
        filter_field: {
          margin: "auto"
        }

    }


));

export {AppStyles}