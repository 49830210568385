import React, {useEffect, useState} from "react";
import {
    Container,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import Notification from "../../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import SlotService from "./SlotService";
import Slot from "./Slot";
import SlotForm from "./SlotForm";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function SlotsTable() {

    const [fetching, setFetching] = useState(true);
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [slots, setSlots] = useState([])

    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const classes = useStyles();


    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };


    function closeNotification() {
        setOpenNotify(false);
    }

    function goToSlotForm() {
        history.push("/slots/new")
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");

        setRefreshTable(!refreshTable);

    }

    function fetchSlots() {

        setFetching(true);

        let filters = {};

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        let slotService = new SlotService();
        slotService.getSlotsByFilters(filters)
            .then(data => {
                setSlots(data.slots);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));
                setFetching(false);
            })
            .catch(error => {
                setFetching(false);
                alert(error)
            })
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    useEffect(() => {
        fetchSlots();
    }, [page, rowsPerPage])


    return <Container className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/slots`}>
                <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary" onClick={goToSlotForm}
                        className={classes.button}>
                    {t("create_new_slot")}
                </Button>
                {(fetching) ?
                    <LoadingComponent/>

                    :
                    <div>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableCell>{t("ID")}</TableCell>
                                <TableCell>{t("fromTime")}</TableCell>
                                <TableCell>{t("toTime")}</TableCell>
                                <TableCell>{t("days")}</TableCell>
                                <TableCell/>
                            </TableHead>
                            <TableBody>
                                {slots.map((slot, index) => {
                                    return <Slot slot={slot} deleteCallback={deleteCallback}/>
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                variant: "outlined",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: {paper: classes.menuPaper}
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>

                }
                <Notification open={openNotify} severity={notifySeverity} duration={3500}
                              onClose={closeNotification}>{notificationMessage}</Notification>
            </PrivateRoute>

            <PrivateRoute exact path={`/slots/:id/edit/`} children={<SlotForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/slots/new/`}>
                <SlotForm slot={null}/>
            </PrivateRoute>

        </Switch>
    </Container>
}

export default SlotsTable;