import WarehouseStorageService from "./WarehouseStorageService";
import React, {useEffect, useState} from "react";
import {Modal, TableRow, Typography} from "@mui/material";
import {Backdrop, Button, Fade, Menu, MenuItem, Paper, TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Notification from "../components/notifications/Notification";

const useStyles = makeStyles({
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }
});

function WarehouseStorage(props) {

    let warehouseStorage = props.warehouseStorage;
    const [provision, setProvision] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const [pdf, setPdf] = useState(null);
    const [openPdf, setOpenPdf] = useState(false);

    const {t} = useTranslation();
    const history = useHistory();

    let deliveryAddress = props.warehouseStorage.deliveryAddress;

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    let classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    async function fetchCollectionDocument() {

        await new WarehouseStorageService().getCollectionDocument(warehouseStorage.id)
            .then(result => {
                setPdf(result.content)
                setOpenPdf(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                })
            })

    }


    function release() {
        window.open(`/warehouse-storages/${warehouseStorage.id}/release`, `${warehouseStorage.id}`, null, false);
        // history.push(`/warehouse-storages/${warehouseStorage.id}/release`)
    }

    async function cancel() {

        await new WarehouseStorageService().cancelWarehouseStorage(warehouseStorage.id)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.refreshCallback();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    function goToLabelPage(){
        history.push(`/warehouse-storages/${warehouseStorage.id}/label`)
    }

    function fetchProvision() {
        if (warehouseStorage.provisionId != null) {
            let warehouseStorageService = new WarehouseStorageService();
            warehouseStorageService.getProvisionByWarehouseStorageId(warehouseStorage.id)
                .then(provision => {
                    setProvision(provision);
                })
                .catch(error => {

                })
        }
    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }

    function goToWSDetails() {
        window.open(`/warehouse-storages/${warehouseStorage.id}/details`, `${warehouseStorage.id}`, null, false);
        // history.push(`/warehouse-storages/${warehouseStorage.id}/details`)
    }

    useEffect(() => {
        fetchProvision();
    }, [refresh])

    return <TableRow>
        <TableCell>
            <Typography className={classes.link} onClick={goToWSDetails}>{warehouseStorage.id} </Typography>

            <Typography className={classes.link}
                        onClick={() => goToAboutMe(warehouseStorage.storedShipmentId)}>{warehouseStorage.storedShipmentId} </Typography>
            <Typography>{warehouseStorage.warehouseCode} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{warehouseStorage.companyName} </Typography>
            <Typography>{warehouseStorage.accountCorporateCode} </Typography>
            <Typography>{warehouseStorage.contractCode} </Typography>

        </TableCell>
        <TableCell>
            <Typography>{warehouseStorage.reason} </Typography>
        </TableCell>
        <TableCell>
            <>
                <Typography>{(deliveryAddress.companyName ? deliveryAddress.companyName : "") + (deliveryAddress.referencePerson ? " " + deliveryAddress.referencePerson : "")} </Typography>
                <Typography>{(deliveryAddress.street ? deliveryAddress.street : "") + (deliveryAddress.buildingNr ? ", " + deliveryAddress.buildingNr : "")} </Typography>
                <Typography>{(deliveryAddress.postalCode ? deliveryAddress.postalCode : "") + (deliveryAddress.city ? " " + deliveryAddress.city : "") + (deliveryAddress.administrativeLevel3 ? " (" + deliveryAddress.administrativeLevel3 + ")" : "")}</Typography>
            </>
        </TableCell>
        <TableCell>
            <Typography>{new Date(warehouseStorage.openingDateTime).toLocaleDateString() + " " + new Date(warehouseStorage.openingDateTime).toLocaleTimeString()} </Typography>
            <Typography fontStyle={"oblique"}>{warehouseStorage.user} </Typography>
            <Typography>{warehouseStorage.notes} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{provision ? provision.releaseMode.description : ""} </Typography>
            <Typography>{provision ? new Date(provision.createdAt).toLocaleDateString() + " - " + new Date(provision.createdAt).toLocaleTimeString() : ""} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{warehouseStorage.canceled ? t("yes") : ""} </Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{warehouseStorage.warehouseStorageReleaseModeDescription}</Typography>
                <Typography className={classes.link}
                    onClick={() => goToAboutMe(warehouseStorage.releasedShipmentId)}>{warehouseStorage.releasedShipmentId ? warehouseStorage.releasedShipmentId : ""} </Typography>
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem disabled={warehouseStorage.releasedShipmentId || warehouseStorage.canceled}
                      onClick={release}>{t("do_release")}</MenuItem>
            <MenuItem disabled={warehouseStorage.releasedShipmentId || warehouseStorage.canceled}
                      onClick={cancel}>{t("cancel")}</MenuItem>
            <MenuItem disabled={provision !== null}
                      onClick={() => props.goToProvisionPage(warehouseStorage.id)}>{t("insert_warehouse_storage_provision")}</MenuItem>
            <MenuItem disabled={provision !== null}
                      onClick={goToLabelPage}>{t("download_label_pdf")}</MenuItem>
            <MenuItem disabled={warehouseStorage.warehouseStorageReleaseModeId !== 4}
                      onClick={fetchCollectionDocument}>{t("download_pickup_document")}</MenuItem>

        </Menu>
        <Modal open={openPdf}
               onClose={()=>setOpenPdf(false)}
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={openPdf}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "fit-content",
                    marginInline: "auto",
                    marginTop: "5%",
                    height: "fit-content",
                    minHeight: "80%",
                    minWidth: "80%",
                    display: "flex"
                }}>
                    <div style={{margin: "auto", marginTop: "5%"}}>
                        <object style={{margin: "auto"}} width={800} height={800} type={'application/pdf'} data={"data:application/pdf;base64," + pdf}>";
                            html += "</object>
                    </div>
                </Paper>
            </Fade>
        </Modal>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>

    </TableRow>


}

export default WarehouseStorage;