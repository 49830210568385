import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";

const userService = new UserService();

export default class PaymentSlipsService{


    getPaymentSlipHeads(filters) {

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };

        let pathVariable = `/api/v1/payment-slips`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    newPayment(input){
        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/payment-slips`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getCsv(paymentSlipId){

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/payment-slips/${paymentSlipId}/csv`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getPdf(paymentSlipId){

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/payment-slips/${paymentSlipId}/pdf`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

}