import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";

const userService = new UserService();


export default class ServiceService {
    getServicesByFilters(filters) {
        const apiKey = userService.getApiKey();

        let queryParams = "";
        if (filters) {
            if ("undefined" !== typeof filters.deliveryServices) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "deliveryServices=" + filters.deliveryServices;
            }

            if ("undefined" !== typeof filters.pickupServices) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "pickupServices=" + filters.pickupServices;
            }

            if ("undefined" !== typeof filters.haulingServices) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "haulingServices=" + filters.haulingServices;
            }
            if ("undefined" !== typeof filters.handlingServices) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "handlingServices=" + filters.handlingServices;
            }
            if (filters.contractCode) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "contractCode=" + filters.contractCode;
            }
            if (filters.accountCorporateCode) {
                if (0 < queryParams.length) {
                    queryParams += "&";
                }
                queryParams += "accountCorporateCode=" + filters.accountCorporateCode;
            }
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let requestUrl = '/api/v1/services';
        if (0 < queryParams.length) {
            requestUrl += "?" + queryParams;
        }

        return fetch(requestUrl, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getById(id) {
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let requestURL = `/api/v1/services/${id}`;

        return fetch(requestURL, requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

}