import {
    CircularProgress,
    DialogTitle,
    Fade,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, Button, Modal} from "@material-ui/core";
import theme from "../../theme/theme";
import {CloudDownload} from "@mui/icons-material";

const useStyles = makeStyles({
    root: {
        // cursor: "pointer",
        // transition: "all 0.2s",
        //
        // "&:hover": {
        //
        //     background: 'linear-gradient(180deg , #ebebeb 10%, #ffffff 100%)'
        //
        //
        // },
        // '& > *': {
        //     borderBottom: 'unset',
        // },
    },
    iconCell: {
        justifyContent: "end",
        margin: "auto",
        textAlign: 'end',
        display: "block",
    },
    link: {
        cursor: "pointer",
        transition: "all 0.2s",
        color: "#144dff",
        '& > *': {
            borderBottom: 'unset',
        },
    }


});

export default function AdministrativeGroupsTab(props){

    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    let classes = useStyles();

    function mapByShipments(parcels){

        let map = new Map();
        for (let i = 0; i < parcels.length; i++) {
            if (map.has(parcels[i].shipmentId)) {
                let value = map.get(parcels[i].shipmentId);
                value.push(parcels[i]);
                map.set(parcels[i].shipmentId, value);
            } else {
                let value = [];
                value.push(parcels[i]);
                map.set(parcels[i].shipmentId, value);
            }
        }

        return map;
    }

    function mapByLedgerAccount(items){
        let map = new Map();
        for (let i = 0; i < items.length; i++) {
            if (map.has(items[i].ledgerAccount)) {
                let value = map.get(items[i].ledgerAccount);
                value.push(items[i]);
                map.set(items[i].ledgerAccount, value);
            } else {
                let value = [];
                value.push(items[i]);
                map.set(items[i].ledgerAccount, value);
            }
        }

        return map;
    }

    function calculateTotal(items){
        let total = 0;

        for (let i = 0; i < items.length; i++) {
            total += items[i].amount;
        }

        return total;
    }

    function goToAboutMe(id) {
        window.open(`/shipments/${id}/aboutMe`, `${id}`, null, false);
    }

    function openModal(){
        setOpen(true);
    }


    return <div>
        {props.administrativeGroups.map(item => {
            return <div>
                <Typography variant={"button"}>{t("administrative_group") + " " + item.id}</Typography>
                <Table style={{margin: "auto", marginTop: "2%", width: "20%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "50%"}} align={"center"}>
                                <Typography variant={"button"}>{t("shipments")}</Typography>
                            </TableCell>
                            <TableCell style={{width: "50%"}} align={"center"}>
                                <Typography variant={"button"}>{t("parcels")}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{width: "50%"}} align={"center"}>
                                <Typography onClick={openModal} className={classes.link} variant={"button"}>{[...mapByShipments(item.parcels).entries()].length}</Typography>
                            </TableCell>
                            <TableCell style={{width: "50%"}} align={"center"}>
                                <Typography variant={"button"}>{item.parcels.length}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div style={{margin: "auto", marginBlock: "2%"}}>
                    <Typography align={"center"}>
                        {item.address.companyName}
                    </Typography>
                    <Typography align={"center"}>
                        {item.address.street}
                        {item.address.buildingNr ? ", " + item.address.buildingNr : ""}
                    </Typography>
                    <Typography align={"center"}>
                        {item.address.postalCode}
                        {", " + item.address.city}
                        {" (" + item.address.administrative_level_3 + ")"}
                        {" - " + item.address.country}
                        {item.address.administrative_level_1 ? " " + item.address.administrative_level_1 : ""}
                        {item.address.administrative_level_2 ? " " + item.address.administrative_level_2 : ""}
                    </Typography>
                    <Typography align={"center"}>
                        {item.address.email ? "Email: " + item.address.email : ""}
                    </Typography>
                    <Typography align={"center"}>
                        {item.address.phone ? "Tel: " + item.address.phone : ""}
                    </Typography>
                    <Typography align={"center"}>
                        {item.address.mobile ? "Cell: " + item.address.mobile : ""}
                    </Typography>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{margin: "auto", width: "50%"}}>
                        <Typography variant={"button"} style={{fontWeight: "bold"}}>{t("quotes")}</Typography>
                        <Table style={{margin: "auto", marginTop: "2%"}}>
                            {[...mapByLedgerAccount(item.quotes).entries()].map(item => {
                                return <TableRow>
                                    <TableCell style={{width: "50%"}} align={"center"}>
                                        <Typography style={{fontWeight: "bold"}} variant={"caption"}>{item[0]}</Typography>
                                    </TableCell>
                                    <TableCell style={{width: "50%"}} align={"center"}>
                                        <Typography style={{fontWeight: "bold"}} variant={"caption"}>{calculateTotal(item[1]).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                            })}
                        </Table>
                    </div>
                    <div style={{margin: "auto", width: "50%"}}>
                        <Typography variant={"button"} style={{fontWeight: "bold"}}>{t("accounting_rows")}</Typography>
                        <Table style={{margin: "auto", marginTop: "2%"}}>
                            {[...mapByLedgerAccount(item.accountingRows).entries()].map(item => {
                                return <TableRow>
                                    <TableCell style={{width: "50%"}} align={"center"}>
                                        <Typography style={{fontWeight: "bold"}} variant={"caption"}>{item[0]}</Typography>
                                    </TableCell>
                                    <TableCell style={{width: "50%"}} align={"center"}>
                                        <Typography style={{fontWeight: "bold"}} variant={"caption"}>{calculateTotal(item[1]).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                            })}
                        </Table>
                    </div>
                </div>
                <Modal open={open}
                       onClose={() => setOpen(false)}
                       closeAfterTransition
                       BackdropComponent={Backdrop}
                       BackdropProps={{
                           timeout: 500,
                       }}
                >
                    <Fade in={open}>
                        <Paper variant={"outlined"} elevation={2} style={{
                            width: "30%",
                            marginInline: "auto",
                            marginTop: "10%",
                            height: "50%",
                            // overflow: "hidden",
                            overflow: "auto",
                            paddingBottom: "1%",
                            // border: `1px solid black`
                        }}>
                            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("shipments")}</DialogTitle>
                            {[...mapByShipments(item.parcels).entries()].map(shipment => {
                                return <div style={{width: "fit-content", margin: "auto", marginBottom: "2%"}}>
                                    <Typography variant={"subtitle2"} onClick={() => goToAboutMe(shipment[0])}
                                                className={classes.link}>
                                        {shipment[0]}
                                    </Typography>
                                </div>

                            })}
                        </Paper>
                    </Fade>

                </Modal>
            </div>

        })}
    </div>


}