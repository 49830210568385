import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";


export default class RouteService{

    getRoutesForDeparture(filters){

        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/routes/departure`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getRoutes(filters){

        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const lang = localStorage.getItem("i18nextLng");

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/routes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    saveRoute(route){


        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(route)
        };

        return fetch('/api/v1/routes', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    deleteRoute(id){


        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/routes/${id}`, requestOptions)
            .then(checkStatus)
            // .then(response => response.json())

    }



}