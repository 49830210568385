import {Button, DialogTitle, Paper, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import WarehouseService from "../warehouses/WarehouseService";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import theme from "../theme/theme";
import {Save} from "@material-ui/icons";
import LoadingListsService from "./LoadingListsService";
import Notification from "../components/notifications/Notification";
import {useHistory} from "react-router-dom";


function LoadingListForm(props) {

    const {t} = useTranslation();

    const history = useHistory();

    const [departureWarehouse, setDepartureWarehouse] = useState(null);
    const [departureWarehouses, setDepartureWarehouses] = useState([]);
    const [destinationWarehouse, setDestinationWarehouse] = useState(null);
    const [destinationWarehouses, setDestinationWarehouses] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleDepartureWarehouseChange(event, warehouse) {
        setDepartureWarehouse(warehouse);
    }

    function handleDestinationWarehouseChange(event, warehouse) {
        setDestinationWarehouse(warehouse);
    }

    async function fetchDepartureWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setDepartureWarehouses(result.warehouses);
                if(result.warehouses?.length === 1){
                    setDepartureWarehouse(result.warehouses[0]);
                }
            })

    }

    async function fetchDestinationWarehouses() {
        let filters = {showAll: true};

        await new WarehouseService().getWarehousesByFilters(null, null, filters)
            .then(result => {
                setDestinationWarehouses(result.warehouses);
                if(result.warehouses?.length === 1){
                    setDestinationWarehouse(result.warehouses[0]);
                }
            })
    }


    async function save() {

        let input = {};

        input.warehouseId = departureWarehouse.id;
        input.destinationWarehouseId = destinationWarehouse.id;

        await new LoadingListsService().saveLoadingList(input)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));

                setTimeout(() => {
                    history.push(`/loading-lists`);
                }, 2000)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    useEffect(()=>{
        fetchDestinationWarehouses();
        fetchDepartureWarehouses();
    }, [])


    return <Paper>
        <div style={{display: "flex"}}>
            <DialogTitle style={{margin: "auto"}}>{t("new_loading_list")}</DialogTitle>
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                id="warehouse"
                options={departureWarehouses}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => option.code + " - " + option.name}
                value={departureWarehouse}
                defaultValue={departureWarehouse}
                noOptionsText={t("no_options_available")}
                onOpen={fetchDepartureWarehouses}
                onChange={handleDepartureWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("departure_warehouse")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={departureWarehouse}
                        defaultValue={departureWarehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{display: "flex"}}>
            <Autocomplete
                id="warehouse"
                options={destinationWarehouses}
                size={"small"}
                style={{width: "20%", margin: "auto"}}
                getOptionLabel={option => option.code + " - " + option.name}
                value={destinationWarehouse}
                defaultValue={destinationWarehouse}
                noOptionsText={t("no_options_available")}
                onOpen={fetchDestinationWarehouses}
                onChange={handleDestinationWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("destination_warehouse")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        margin="dense"
                        size={"small"}
                        value={destinationWarehouse}
                        defaultValue={destinationWarehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
        <div style={{display: "flex"}}>
            <Button style={{
                margin: "auto",
                marginBlock: "5%",
                backgroundColor: theme.palette.primary.main,
                color: "white"
            }} onClick={save}
                    startIcon={<Save/>}>{t("save")}</Button>
        </div>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>


}

export default LoadingListForm;