import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


const userService = new UserService();


export default class WarehouseService {

    getWarehouseById(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/warehouses/${id}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }


    getWarehousesByFilters(pageNumber, pageSize, filters) {

        const apiKey = userService.getApiKey();

        let queryString = ``;


        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (null != pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }
        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/warehouses`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    addWarehouse(input) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        }
        return fetch(`/api/v1/warehouses`, requestOptions)
            .then(checkStatus)
    }

    editWarehouse(id, input){

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        }
        return fetch(`/api/v1/warehouses/${id}`, requestOptions)
            .then(checkStatus)

    }

    deleteWarehouse(id) {

        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/warehouses/${id}`, requestOptions)
            .then(checkStatus)

    }
}