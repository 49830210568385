import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TableRow} from "@mui/material";
import {TableCell, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import CountryService from "../geo/countries/CountryService";
import AdministrativeLevel1Service from "../geo/administrative_level_1/AdministrativeLevel1Service";
import AdministrativeLevel2Service from "../geo/administrative_level_2/AdministrativeLevel2Service";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import ContractService from "../accounts/contracts/ContractService";

export default function RateServiceZoneForm(props) {
    const {t} = useTranslation();

    const [state, setState] = useState(props.zone);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(props.zone?.country);
    const [administrativeLevels1, setAdministrativeLevels1] = useState([]);
    const [selectedAdministrativeLevel1, setSelectedAdministrativeLevel1] = useState(props.zone?.administrativeLevel1);
    const [administrativeLevels2, setAdministrativeLevels2] = useState([]);
    const [selectedAdministrativeLevel2, setSelectedAdministrativeLevel2] = useState(props.zone?.administrativeLevel2);
    const [administrativeLevels3, setAdministrativeLevels3] = useState([]);
    const [selectedAdministrativeLevel3, setSelectedAdministrativeLevel3] = useState(props.zone?.administrativeLevel3);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(props.zone?.city);
    const [postalCodes, setPostalCodes] = useState([]);
    const [selectedPostalCode, setSelectedPostalCode] = useState(props.zone?.postalCode);

    function setNewState(newState) {
        setState(newState);
        if ("function" === typeof props?.onStateChange) {
            props.onStateChange(newState);
        }
    }

    useEffect(() => {
        async function fetchCountries() {
            let countryService = new CountryService();
            const restCountries = await countryService.getCountriesByFilters(null, null, null, null);
            setCountries(restCountries.countries);

            if (state?.country) {
                for (let i = 0; i < restCountries.countries.length; ++i) {
                    if (state.country === restCountries.countries[i].iso3166Alpha3) {
                        setSelectedCountry(restCountries.countries[i]);
                    }
                }
            }
        }

        async function fetchContracts() {
            let contractService = new ContractService();
            const restContracts = await contractService.getContractsByFilters(null);
            setContracts(restContracts);

            if (state?.contractId) {
                for (let i = 0; i < restContracts.length; ++i) {
                    if (state.contractId === restContracts[i].id) {
                        setSelectedContract(restContracts[i]);
                    }
                }
            }
        }

        fetchCountries();
        fetchContracts();
    }, []);

    useEffect(() => {
        async function fetchAdministrativeLevels1() {
            if (!state.country) {
                setAdministrativeLevels1([]);
                return;
            }

            let adminL1Service = new AdministrativeLevel1Service();

            let filters = {};
            filters.countryId = selectedCountry.id;
            const restAdministrativeLevels1 = await adminL1Service.getAllAdminLevel1WithFilters(null, null, filters, null);
            setAdministrativeLevels1(restAdministrativeLevels1.admins1);

            if (state?.administrativeLevel1) {
                for (let i = 0; i < restAdministrativeLevels1.admins1.length; ++i) {
                    if (state.administrativeLevel1 === restAdministrativeLevels1.admins1[i].name) {
                        setSelectedAdministrativeLevel1(restAdministrativeLevels1.admins1[i]);
                    }
                }
            }
        }

        fetchAdministrativeLevels1();
    }, [state.country]);

    useEffect(() => {
        async function fetchAdministrativeLevels2() {
            if (!state.administrativeLevel1) {
                setAdministrativeLevels2([]);
                return;
            }

            let adminL2Service = new AdministrativeLevel2Service();

            let filters = {};
            filters.adminL1 = selectedAdministrativeLevel1.id;
            const restAdministrativeLevels2 = await adminL2Service.getAllAdminLevel2WithFilters(null, null, filters, null);
            setAdministrativeLevels2(restAdministrativeLevels2.admins2);

            if (state?.administrativeLevel2) {
                for (let i = 0; i < restAdministrativeLevels2.admins2.length; ++i) {
                    if (state.administrativeLevel2 === restAdministrativeLevels2.admins2[i].name) {
                        setSelectedAdministrativeLevel2(restAdministrativeLevels2.admins2[i]);
                    }
                }
            }
        }

        fetchAdministrativeLevels2();
    }, [state.administrativeLevel1]);

    useEffect(() => {
        async function fetchAdministrativeLevels3() {
            if (!state.administrativeLevel2) {
                setAdministrativeLevels3([]);
                return;
            }

            let adminL3Service = new AdministrativeLevel3Service();

            let filters = {};
            filters.adminL2 = selectedAdministrativeLevel2.id;
            const restAdministrativeLevels3 = await adminL3Service.getAllAdminLevel3WithFilters(null, null, filters, null);
            setAdministrativeLevels3(restAdministrativeLevels3.admins3);

            if (state?.administrativeLevel3) {
                for (let i = 0; i < restAdministrativeLevels3.admins3.length; ++i) {
                    if (state.administrativeLevel3 === restAdministrativeLevels3.admins3[i].code) {
                        setSelectedAdministrativeLevel3(restAdministrativeLevels3.admins3[i]);
                    }
                }
            }
        }

        fetchAdministrativeLevels3();
    }, [state.administrativeLevel2]);

    useEffect(() => {
        async function fetchCities() {
            if (!state.administrativeLevel3) {
                setCities([]);
                return;
            }

            let cityService = new CityService();

            let filters = {};
            filters.level3Id = selectedAdministrativeLevel3.id;
            const restCities = await cityService.getCities(null, null, filters, null);
            setCities(restCities.cities);

            if (state?.city) {
                for (let i = 0; i < restCities.cities.length; ++i) {
                    if (state.city === restCities.cities[i].name) {
                        setSelectedCity(restCities.cities[i]);
                    }
                }
            }
        }

        fetchCities();
    }, [state.administrativeLevel3]);

    useEffect(() => {
        async function fetchPostalCodes() {
            if (!state.city) {
                setPostalCodes([]);
                return;
            }

            let postalCodeService = new PostalCodeService();

            let filters = {};
            filters.cityId = selectedCity.id;
            const restPostalCodes = await postalCodeService.getAllPostalCodes(null, null, filters, null);
            setPostalCodes(restPostalCodes.postalCodes);

            if (state?.postalCode) {
                for (let i = 0; i < restPostalCodes.postalCodes.length; ++i) {
                    if (state.postalCode === restPostalCodes.postalCodes[i].postalCode) {
                        setSelectedPostalCode(restPostalCodes.postalCodes[i]);
                    }
                }
            }
        }

        fetchPostalCodes();
    }, [state.city]);

    function handleContractChange(event, contract) {
        setSelectedContract(contract);

        const newState = {
            ...state,
            contractId: contract?.id,
        };
        setNewState(newState);
    }

    function handleCountryChange(event, country) {
        setSelectedCountry(country);
        setSelectedAdministrativeLevel1(null);
        setSelectedAdministrativeLevel2(null);
        setSelectedAdministrativeLevel3(null);
        setSelectedCity(null);
        setSelectedPostalCode(null);

        const newState = {
            ...state,
            country: country?.iso3166Alpha3,
            administrativeLevel1: null,
            administrativeLevel2: null,
            administrativeLevel3: null,
            city: null,
            postalCode: null,
        };
        setNewState(newState);
    }

    function handleAdministrativeLevel1Change(event, administrativeLevel1) {
        setSelectedAdministrativeLevel1(administrativeLevel1);
        setSelectedAdministrativeLevel2(null);
        setSelectedAdministrativeLevel3(null);
        setSelectedCity(null);
        setSelectedPostalCode(null);

        const newState = {
            ...state,
            administrativeLevel1: administrativeLevel1?.name,
            administrativeLevel2: null,
            administrativeLevel3: null,
            city: null,
            postalCode: null,
        };
        setNewState(newState);
    }

    function handleAdministrativeLevel2Change(event, administrativeLevel2) {
        setSelectedAdministrativeLevel2(administrativeLevel2);
        setSelectedAdministrativeLevel3(null);
        setSelectedCity(null);
        setSelectedPostalCode(null);

        const newState = {
            ...state,
            administrativeLevel2: administrativeLevel2?.name,
            administrativeLevel3: null,
            city: null,
            postalCode: null,
        };
        setNewState(newState);
    }

    function handleAdministrativeLevel3Change(event, administrativeLevel3) {
        setSelectedAdministrativeLevel3(administrativeLevel3);
        setSelectedCity(null);
        setSelectedPostalCode(null);

        const newState = {
            ...state,
            administrativeLevel3: administrativeLevel3?.code,
            city: null,
            postalCode: null,
        };
        setNewState(newState);
    }

    function handleCityChange(event, city) {
        setSelectedCity(city);
        setSelectedPostalCode(null);

        const newState = {
            ...state,
            city: city?.name,
            postalCode: null,
        };
        setNewState(newState);
    }

    function handlePostalCodeChange(event, postalCode) {
        setSelectedPostalCode(postalCode);

        const newState = {...state, postalCode: postalCode?.postalCode};
        setNewState(newState);
    }

    return <TableRow>
        <TableCell>
            <Autocomplete
                id="contractId"
                value={selectedContract}
                defaultValue={selectedContract}
                options={contracts}
                autoHighlight
                getOptionLabel={(option) => option.code + " " + option.name}
                onChange={handleContractChange}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.code + " " + option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="countryCode"
                value={selectedCountry}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleCountryChange}
                // onBlur={fetchAdminL1}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name} ({option.iso3166Alpha3})
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("country")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="administrativeLevel1"
                options={administrativeLevels1}
                value={selectedAdministrativeLevel1}
                disabled={!state.country}
                getOptionLabel={(option) => option.name}
                onChange={handleAdministrativeLevel1Change}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("administrative1")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="administrativeLevel2"
                options={administrativeLevels2}
                value={selectedAdministrativeLevel2}
                disabled={!selectedAdministrativeLevel1}
                getOptionLabel={(option) => option.name}
                onChange={handleAdministrativeLevel2Change}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("administrative2")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="administrativeLevel3"
                options={administrativeLevels3}
                value={selectedAdministrativeLevel3}
                disabled={!selectedAdministrativeLevel2}
                getOptionLabel={(option) => option.name}
                onChange={handleAdministrativeLevel3Change}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("administrative3")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="city"
                options={cities}
                disabled={!selectedAdministrativeLevel3}
                value={selectedCity}
                getOptionLabel={(option) => option.name}
                onChange={handleCityChange}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("city")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell>
            <Autocomplete
                id="postalCode"
                options={postalCodes}
                value={selectedPostalCode}
                disabled={!selectedCity}
                getOptionLabel={(option) => option.postalCode}
                onChange={handlePostalCodeChange}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.postalCode}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("postalCode")}
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </TableCell>

        <TableCell/>
    </TableRow>;
}