import React from 'react';
import {Redirect, Route} from "react-router-dom";
import UserService from "../users/UserService";

export const PrivateRoute = ({children, ...rest}) => {
    const userService = new UserService();

    return <Route {...rest} render={() => {
        return userService.isLoggedIn() ? children : <Redirect to="/login"/>
    }
    }/>
}