import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, CardActions, DialogTitle, TextField} from "@material-ui/core";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import CostCenterService from "./CostCenterService";
import Notification from "../../components/notifications/Notification";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
            justifyItems: 'center'
        },
        field: {
            width: "20%",
            margin: "auto",
            marginTop: "2%",
            marginBottom: "2%",
            display: 'flex',
            justifyItems: "center"

        },
        button_container: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
        },
        cancel_button: {
            marginInline: "4%",

            width: "15%",
            background: 'rgba(186,186,186,0.84)',

            color: "#000000",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                backgroundColor: "#d5d5d5",
            },
        },
        confirm_button: {
            marginInline: "4%",

            width: "15%",
            backgroundColor: "green",
            color: "white",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                background: 'rgba(24,147,0,0.84)',
                // color: "black",
                // border:  "2px solid green",

                "& $confirm_icon": {
                    // color: "green"
                }
            },
        },
        confirm_icon: {
            color: "white",
            transition: "all 0.3s",
        }
    }
));



function CostCenterForm(props) {

    const {t} = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    const {id} = useParams();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');


    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const costCenter = props.costCenter;

    let costCenterID = props.id;

    if (!costCenterID) {
        costCenterID = id;
    }

    if(costCenter) {
        setName(costCenter.name || '');
        setCode(costCenter.code || '');
    }

    function handleClose() {
        history.push('/cost-centers');
    }
    function closeNotification(){
        setOpenNotify(false);
    }

    function handleSubmit(event) {
        event.preventDefault();

        let costCenterService = new CostCenterService();
        costCenterService.addNewCostCenter(name, code)
            .then(()=>{
                setNotifySeverity('success');
                setNotificationMessage("successful");
                setOpenNotify(true);

                setTimeout(() => {

                    history.push({
                        pathname: '/cost-centers',
                        state: {detail: ''}
                    });
                }, 2000)
            })
            .catch((err) => {
                err.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })


    }

    const handleCostCenterNameChange = (event) => {
        setName(event.target.value);
    }



    useEffect(() => {
        if(costCenterID){
            const costCenterService = new CostCenterService();
            costCenterService.getCostCenterById(costCenterID)
                .then(data => {
                    setName(data.name);
                    setCode(data.code);
                })
                .catch(error => {

                })
        }
    },[])



    return <Container className={classes.root}>
        <form onSubmit={handleSubmit}>
            <DialogTitle> {t("cost_center")}</DialogTitle>
            <TextField
                className={classes.field}
                label={t("name")}
                variant="outlined"
                margin="dense"
                onChange={handleCostCenterNameChange}
                value={name}

            />
            <TextField
                className={classes.field}
                label={t("code")}
                variant="outlined"
                onChange={event => {
                    setCode(event.target.value)
                }}
                value={code}
                margin={"dense"}
            />
            <CardActions className={classes.button_container}>
                <Button
                    className={classes.cancel_button}
                    endIcon={<CancelOutlined/>}
                    onClick={handleClose}
                    variant={"contained"}>
                    {t("cancel")}
                </Button>
                <Button
                    className={classes.confirm_button}
                    endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                    onClick={handleSubmit}
                    color="primary"
                    variant={"contained"}>
                    {t("confirm")}
                </Button>
            </CardActions>
        </form>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

    </Container>

}


export default CostCenterForm;
