import {MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {PrivateRoute} from "../../routes/PrivateRoute";
import TripsForm from "./TripsForm";
import TripService from "./TripService";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import Trip from "./Trip";
import {Autocomplete} from "@material-ui/lab";
import {Card, DialogTitle, TextField, Typography} from "@mui/material";
import WarehouseService from "../../warehouses/WarehouseService";
import moment from "moment";
import DateField from "../../components/fields/DateField";
import RouteService from "../routes/RouteService";
import AccountService from "../../accounts/AccountService";
import {CloudDownload} from "@mui/icons-material";
import StyledButton from "../../components/buttons/StyledButton";
import InfoChip from "../../components/chips/InfoChip";
import Notification from "../../components/notifications/Notification";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"

    },
    filter: {
        margin: "auto",
        width: "30%"
    },
    container: {
        width: '100%',
    },
    table: {
        width: 'fit-content',
        minWidth: "40%",
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },
    filterbutton: {
        alignSelf: "center",
        marginInline: "1%"

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "25%",
        marginInline: "1%",
        marginBlock: "2%"
    },
    filterMask: {
        width: "100%",
        display: "inline-flex"


    },
    filterContainer: {
        width: "100%",
        marginBottom: "5%"
        // textAlign: 'center',
        // justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    field: {
        width: "25%",
        marginInline: "1%",
        marginTop: "2.7%"
    }
}));

function TripsTable(props) {
    let permissions = localStorage.getItem("permissions");

    const [refresh, setRefresh] = useState(true);

    const [trips, setTrips] = useState([]);

    const today = moment();
    const aMonthAgo = moment().subtract(1, 'months');

    const [departureDateFrom, setDepartureDateFrom] = useState(aMonthAgo);
    const [departureDateTo, setDepartureDateTo] = useState(today);

    const location = useLocation();

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [route, setRoute] = useState(null);
    const [routes, setRoutes] = useState([]);

    const [supplier, setSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [plateNumber, setPlateNumber] = useState('');
    const [driverName, setDriverName] = useState('');


    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [pages, setPages] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const classes = useStyles();

    const {t} = useTranslation();

    const history = useHistory();

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
        setRoute(null);
    }

    function handleDepartureDateFromChange(event) {
        setDepartureDateFrom(event);
    }

    function handleDepartureDateToChange(event) {
        setDepartureDateTo(event);
    }

    function handleRouteChange(event, route) {
        setRoute(route);
    }

    function handleSupplierChange(event, supplier) {
        setSupplier(supplier);
    }

    function handlePlateNumberChange(event) {
        setPlateNumber(event.target.value)
    }

    function handleDriverNameChange(event) {
        setDriverName(event.target.value)
    }

    function goToTripsForm() {
        history.push("/trips/new");
    }

    function handleEnter(event) {

        if (event.code === "Enter") {
            fetchTrips();
        }

    }

    async function fetchWarehouses() {

        await new WarehouseService().getWarehousesByFilters()
            .then(response => {
                setWarehouses(response.warehouses)
                if(response.warehouses?.length === 1){
                    setWarehouse(response.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    async function fetchRoutes() {

        let filters = {};

        if(warehouse){
            filters.departureWarehouseCode = warehouse.code;
        }

        await new RouteService().getRoutes(filters)
            .then(result => {
                setRoutes(result.routes)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }


    async function fetchSuppliers() {

        let filters = {};
        filters.isSupplier = true;

        await new AccountService().getAccounts(filters)
            .then(result => {
                setSuppliers(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    function getFilters() {
        let filters = {};

        filters.pageNumber = page;
        filters.pageSize = rowsPerPage;
        if (departureDateFrom) {
            filters.departureDateFrom = moment(departureDateFrom).format("yyyy-MM-DD");
        }
        if (departureDateTo) {
            filters.departureDateTo = moment(departureDateTo).format("yyyy-MM-DD");
        }
        if (supplier) {
            filters.supplierId = supplier.id;
        }
        if (route) {
            filters.routeId = route.id;
        }
        if(warehouse){
            filters.warehouseId = warehouse.id;
        }

        return filters;
    }

    async function fetchCsv() {

        let filters = getFilters();

        await new TripService().getCsv(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

            })
            .catch((error) => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function fetchDetailsCsv() {

        let filters = getFilters();

        await new TripService().getDetailsCsv(filters)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();
            })
            .catch((error) => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }


    async function fetchTrips() {

        setFetching(true);

        let filters = getFilters();

        await new TripService().getTrips(filters)
            .then(response => {
                setTrips(response.trips);
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setFetching(false);
                setFetched(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setFetching(false);
                })
            })

    }

    function applyFilters() {
        setPage(0);
        setRefresh(!refresh);

    }

    function clearFilters() {

        setDriverName('');
        setPlateNumber('');
        setSupplier(null);
        setRoute(null);
        setDepartureDateFrom(aMonthAgo);
        setDepartureDateTo(today);
        setWarehouse(null);


        setPage(0);
        setRowsPerPage(10);
        setRefresh(!refresh);
    }

    function reload() {
        setRefresh(!refresh);
    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);

    };


    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    useEffect(() => {
        fetchWarehouses();
        fetchTrips();
    }, [refresh, location, page, rowsPerPage])

    return <div className={classes.root}>

        <Switch>
            <PrivateRoute exact path={`/trips`}>
                <Button style={{margin: "auto", marginBlock: "5%"}} onClick={goToTripsForm} startIcon={<AddCircleIcon/>}
                        variant="contained" color="primary">
                    {t("create_new_trip")}
                </Button>

                <Card elevation={1} defaultExpanded={true} onKeyPress={handleEnter}
                      style={{width: "60%", margin: "auto", marginBottom: "2%"}}>
                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                        <Typography variant={"button"}>{t("filters")}</Typography>
                    </DialogTitle>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="supplier"
                            options={suppliers}
                            className={classes.filter}
                            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                            value={supplier}
                            defaultValue={supplier}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchSuppliers}
                            onChange={handleSupplierChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("supplier")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={supplier}
                                    defaultValue={supplier}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="route"
                            options={warehouses}
                            className={classes.filter}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={warehouse}
                            defaultValue={warehouse}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchWarehouses}
                            onChange={handleWarehouseChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("warehouse")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={warehouse}
                                    defaultValue={warehouse}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="route"
                            options={routes}
                            className={classes.filter}
                            getOptionLabel={option => option.name}
                            value={route}
                            defaultValue={route}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchRoutes}
                            onChange={handleRouteChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("route")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={route}
                                    defaultValue={route}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{display: "flex", marginBottom: "3%"}}>
                        <DateField
                            style={{margin: "auto", width: "25%"}}
                            label={t("departure_date_from")}
                            value={departureDateFrom}
                            onChange={handleDepartureDateFromChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                        <DateField
                            style={{margin: "auto", width: "25%"}}
                            label={t("departure_date_to")}
                            value={departureDateTo}
                            onChange={handleDepartureDateToChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                    </div>
                    {/*<div style={{alignItems: 'center', display: "flex"}}>*/}
                    {/*<div style={{display: "flex"}}>*/}
                    {/*    <TextField*/}
                    {/*        style={{margin: "auto", width: "30%"}}*/}
                    {/*        label={t("plate_number")}*/}
                    {/*        value={plateNumber}*/}
                    {/*        onChange={handlePlateNumberChange}*/}
                    {/*        margin={"dense"}*/}
                    {/*        variant={"outlined"}*/}
                    {/*    />*/}
                    {/*    <TextField*/}
                    {/*        style={{margin: "auto", width: "30%"}}*/}
                    {/*        label={t("driver_name")}*/}
                    {/*        value={driverName}*/}
                    {/*        onChange={handleDriverNameChange}*/}
                    {/*        margin={"dense"}*/}
                    {/*        variant={"outlined"}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div style={{textAlign: 'center'}}>
                        <Button style={{margin: "5%"}} onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button style={{margin: "5%"}} onClick={applyFilters}>{t("apply_filter")}</Button>
                    </div>
                </Card>
                <div style={{width: "100%"}}>
                    <StyledButton onClick={fetchCsv} icon={<CloudDownload/>}>{t("download_csv")}</StyledButton>
                    <StyledButton onClick={fetchDetailsCsv} icon={<CloudDownload/>}>{t("download_details_csv")}</StyledButton>
                </div>

                {/*<div style={{display: "flex"}}>*/}
                {/*    <Autocomplete*/}
                {/*        id="warehouse"*/}
                {/*        options={warehouses}*/}
                {/*        size={"small"}*/}
                {/*        style={{width: "20%", margin: "auto"}}*/}
                {/*        getOptionLabel={option => option.code + " - " + option.name}*/}
                {/*        value={warehouse}*/}
                {/*        defaultValue={warehouse}*/}
                {/*        noOptionsText={t("no_options_available")}*/}
                {/*        onOpen={fetchWarehouses}*/}
                {/*        onChange={handleWarehouseChange}*/}
                {/*        renderInput={(params) => (*/}
                {/*            <TextField*/}
                {/*                {...params}*/}
                {/*                label={t("warehouse")}*/}
                {/*                InputLabelProps={{shrink: true}}*/}
                {/*                variant="outlined"*/}
                {/*                margin="dense"*/}
                {/*                size={"small"}*/}
                {/*                required*/}
                {/*                error={!warehouse}*/}
                {/*                value={warehouse}*/}
                {/*                defaultValue={warehouse}*/}
                {/*                inputProps={{*/}
                {/*                    ...params.inputProps,*/}
                {/*                    autoComplete: 'off', // disable autocomplete and autofill*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div style={{display: "flex"}}>*/}
                {/*    <DateField*/}
                {/*        style={{width: "20%", margin: "auto"}}*/}
                {/*        label={t("date")}*/}
                {/*        value={dateFilter}*/}
                {/*        onChange={handleDateFilterChange}*/}
                {/*        format={"dd/MM/yyyy"}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <IconButton onClick={search}*/}
                {/*                disabled={fetching || !warehouse}*/}
                {/*                style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{fetching ?*/}
                {/*        <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :*/}
                {/*        <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>*/}
                {/*</div>*/}


                {fetching ?

                    <LoadingComponent/>
                    :
                    <>
                        {fetched ?
                            <>
                                {trips.length > 0 ?
                                    <Table stickyHeader className={classes.table}>
                                        <TableHead>
                                            <TableCell>{t("ID")}</TableCell>
                                            <TableCell>{t("route")}</TableCell>
                                            <TableCell>{t("supplier")}</TableCell>
                                            <TableCell>{t("date")}</TableCell>
                                            <TableCell>{t("plate_number")}</TableCell>
                                            <TableCell>{t("driver_name")}</TableCell>
                                            {
                                                permissions.includes("accounting-rows:read")?
                                            <>
                                                <TableCell>{t("quote")}</TableCell>
                                                <TableCell>{t("cost")}</TableCell>
                                            </>
                                                :
                                                null
                                            }

                                            <TableCell/>
                                        </TableHead>

                                        <TableBody>


                                            {trips.map((trip, index) => {

                                                return <Trip trip={trip} reload={reload}/>

                                            })}
                                        </TableBody>

                                        <Notification open={openNotify} severity={notifySeverity} duration={10000}
                                                      onClose={()=>setOpenNotify(false)}>{notificationMessage}</Notification>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={-1}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                native: false,
                                            }}
                                            labelRowsPerPage={t("rows_per_page")}
                                            labelDisplayedRows={() => {
                                                return t("page")
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Table>
                                    :

                                    <InfoChip>{t("no_data")}</InfoChip>


                                }
                            </>

                            :
                            null}
                    </>
                }
            </PrivateRoute>
            <PrivateRoute exact path={`/trips/new`}>
                <TripsForm/>
            </PrivateRoute>
        </Switch>
    </div>


}

export default TripsTable;