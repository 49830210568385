class ParcelValues {


    constructor() {
        this.weight = null;
        this.length = null;
        this.height = null;
        this.depth = null;
        this.customer_barcode = null;
    }


} export default ParcelValues;