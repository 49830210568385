import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";


const userService = new UserService();

export default class ChequePaymentToCustomerService{



    getChequePaymentsByFilters(filters) {

        let queryString = "";
        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let pathVariable = `/api/v1/cheque-payments-to-customers`;

        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    newChequePaymentToCustomer(input) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };
        let pathVariable = `/api/v1/cheque-payments-to-customers`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getPdf(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cheque-payments-to-customers/${id}/pdf`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }


    getPdfMultiple(body) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(body)
        };
        let pathVariable = `/api/v1/cheque-payments-to-customers/pdf`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    getChequePaymentToCustomerCsv(id) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/cheque-payments-to-customers/${id}/csv`;
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }








}