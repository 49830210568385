import {Switch} from "react-router-dom";
import React, {useState} from "react";
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import {PrivateRoute} from "../routes/PrivateRoute";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import {
    Card,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import AccountService from "../accounts/AccountService";
import ContractService from "../accounts/contracts/ContractService";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import TaskQueueService from "./TaskQueueService";
import Notification from "../components/notifications/Notification";
import moment from "moment";
import InfoChip from "../components/chips/InfoChip";
import DeleteIcon from "@material-ui/icons/Delete";
import WarehouseService from "../warehouses/WarehouseService";
import DateField from "../components/fields/DateField";
import {compareAsc} from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    },
    menuPaper: {
        maxHeight: 250
    },
    filter: {
        margin: "auto",
        marginBottom: "2%",
        width: "30%"
    },
    backToTop: {
        position: "fixed",
        bottom: "5%",
        right: "10%",
    },
    button: {
        color: "primary",
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"

    },

}));

export default function TaskQueueTable(props) {
    const [taskQueueRecords, setTaskQueueRecords] = useState([]);

    const [customerAccountList, setCustomerAccountList] = useState([]);
    const [customerContractList, setCustomerContractList] = useState([]);

    const [supplierAccountList, setSupplierAccountList] = useState([]);
    const [supplierContractList, setSupplierContractList] = useState([]);

    const [customerAccount, setCustomerAccount] = useState(null);
    const [customerContract, setCustomerContract] = useState(null);
    const [supplierAccount, setSupplierAccount] = useState(null);
    const [supplierContract, setSupplierContract] = useState(null);
    const [manifestFromDate, setManifestFromDate] = useState(null);
    const [manifestToDate, setManifestToDate] = useState(null);
    const [checkInFromDate, setCheckInFromDate] = useState(null);
    const [checkInToDate, setCheckInToDate] = useState(null);
    const [anyCheckInEventDateFrom, setAnyCheckInEventDateFrom] = useState(null);
    const [anyCheckInEventDateTo, setAnyCheckInEventDateTo] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const localeMap = {
        it: itLocale,
    };

    const [locale, setLocale] = useState("it");

    const {t} = useTranslation();
    const classes = useStyles();

    function fetchSupplierAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isSupplier: true})
            .then(data => {
                setSupplierAccountList(data)
            })
            .catch((err) => {

            })
    }

    function fetchSupplierContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (supplierAccount != null) {
            filters.accountId = supplierAccount.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setSupplierContractList(response);
            });
    }

    function fetchCustomerAccounts() {
        const accountService = new AccountService();
        accountService.getAccounts({isCustomer: true})
            .then(data => {
                setCustomerAccountList(data)
            })
            .catch((err) => {

            });
    }

    function fetchCustomerContracts() {
        let contractService = new ContractService();
        let filters = {}
        if (customerAccount != null) {
            filters.accountId = customerAccount.id
        }

        contractService.getContractsByFilters(filters)
            .then(response => {
                setCustomerContractList(response);
            });
    }

    async function fetchWarehouses() {
        await new WarehouseService().getWarehousesByFilters()
            .then(result => {
                setWarehouses(result.warehouses);
            })
            .catch(error => {

            })
    }

    function handleSupplierAccountChange(event, account) {
        setSupplierAccount(account);
        setSupplierContract(null);
    }

    function handleSupplierContractChange(event, contract) {
        setSupplierContract(contract);
    }

    function handleCustomerAccountChange(event, account) {
        setCustomerAccount(account);
        setCustomerContract(null);
    }

    function handleCustomerContractChange(event, contract) {
        setCustomerContract(contract);
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleFirstDate(event) {
        if (compareAsc(event, anyCheckInEventDateTo) === 1) {
            setAnyCheckInEventDateFrom(event);
            setAnyCheckInEventDateTo(event);
        } else {
            setAnyCheckInEventDateFrom(event)
        }
    }

    function handleSecondDate(event) {
        if (compareAsc(anyCheckInEventDateFrom, event) === 1) {
            setAnyCheckInEventDateFrom(event);
            setAnyCheckInEventDateTo(event);
        } else {
            setAnyCheckInEventDateTo(event);
        }
    }

    function clearFilters() {
        setSupplierAccount(null);
        setSupplierContract(null);
        setCustomerAccount(null);
        setCustomerContract(null);
        setManifestFromDate(null);
        setManifestToDate(null);
        setCheckInToDate(null);
        setCheckInFromDate(null);
        setAnyCheckInEventDateFrom(null);
        setAnyCheckInEventDateTo(null);
        setWarehouse(null);

        applyFilters();
    }

    function applyFilters() {

        setLoading(true);

        const filters = {};
        if (supplierAccount) {
            filters.supplierAccountId = supplierAccount.id;
        }
        if (supplierContract) {
            filters.supplierContractId = supplierContract.id;
        }
        if (customerAccount) {
            filters.customerAccountId = customerAccount.id;
        }
        if (customerContract) {
            filters.customerContractId = customerContract.id;
        }
        if (manifestFromDate) {
            filters.manifestFromDate = moment(manifestFromDate).format("yyyy-MM-DD");
        }
        if (manifestToDate) {
            filters.manifestToDate = moment(manifestToDate).format("yyyy-MM-DD");
        }
        if (checkInFromDate) {
            filters.checkInFromDate = moment(checkInFromDate).format("yyyy-MM-DD");
        }
        if (checkInToDate) {
            filters.checkInToDate = moment(checkInToDate).format("yyyy-MM-DD");
        }
        if (anyCheckInEventDateFrom) {
            filters.anyCheckInEventDateFrom = moment(anyCheckInEventDateFrom).format("yyyy-MM-DD");
        }
        if (anyCheckInEventDateTo) {
            filters.anyCheckInEventDateTo = moment(anyCheckInEventDateTo).format("yyyy-MM-DD");
        }
        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        const taskQueueService = new TaskQueueService();
        taskQueueService.getRecords(filters).then((taxQueueRecords) => {
            setTaskQueueRecords(taxQueueRecords);
            setLoading(false);
        }).catch((error) => {
            error.response.json().then(response => {
                setNotificationMessage(response.status + " - " + response.message);
                setNotifySeverity('error');
                setOpenNotify(true);
                setLoading(false);
            })
        });
    }

    async function clearQueue() {

        setLoading(true);

        await new TaskQueueService().clearQueue()
            .then(result => {
                setLoading(false)
                applyFilters();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })

    }

    function addToQueue() {
        setLoading(true);

        const input = {};
        if (supplierAccount) {
            input.supplierAccountId = supplierAccount.id;
        }
        if (supplierContract) {
            input.supplierContractId = supplierContract.id;
        }
        if (customerAccount) {
            input.customerAccountId = customerAccount.id;
        }
        if (customerContract) {
            input.customerContractId = customerContract.id;
        }
        if (manifestFromDate) {
            input.manifestFromDate = moment(manifestFromDate).format("yyyy-MM-DD");
        }
        if (manifestToDate) {
            input.manifestToDate = moment(manifestToDate).format("yyyy-MM-DD");
        }
        if (checkInFromDate) {
            input.checkInFromDate = moment(checkInFromDate).format("yyyy-MM-DD");
        }
        if (checkInToDate) {
            input.checkInToDate = moment(checkInToDate).format("yyyy-MM-DD");
        }
        if (anyCheckInEventDateFrom) {
            input.anyCheckInEventDateFrom = moment(anyCheckInEventDateFrom).format("yyyy-MM-DD");
        }
        if (anyCheckInEventDateTo) {
            input.anyCheckInEventDateTo = moment(anyCheckInEventDateTo).format("yyyy-MM-DD");
        }
        if (warehouse) {
            input.warehouseId = warehouse.id;
        }

        const taskQueueService = new TaskQueueService();
        taskQueueService.addToQueue(input).then(() => {
            setOpenNotify(true);
            setNotifySeverity('success');
            setNotificationMessage("Record aggiunti in coda");
            setLoading(false)
            applyFilters();

        }).catch((error) => {
            error.response.json().then(response => {
                setNotificationMessage(response.status + " - " + response.message);
                setNotifySeverity('error');
                setOpenNotify(true);
                setLoading(false);
            })
        });
    }

    return <Paper>
        <Switch>
            <PrivateRoute exact path={"/tasks-queue"}>
                <Card elevation={1} defaultExpanded={true}>
                    <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                        <Typography variant={"button"}>{t("tasks_queue")}</Typography>
                    </DialogTitle>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="customerAccount"
                            options={customerAccountList}
                            className={classes.filter}
                            getOptionLabel={option => option.companyName}
                            value={customerAccount}
                            defaultValue={customerAccount}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchCustomerAccounts}
                            onChange={handleCustomerAccountChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("customer_account")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={customerAccount}
                                    defaultValue={customerAccount}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="customerContract"
                            disabled={!customerAccount}
                            options={customerContractList}
                            className={classes.filter}
                            getOptionLabel={option => option.code}
                            value={customerContract}
                            defaultValue={customerContract}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchCustomerContracts}
                            onChange={handleCustomerContractChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("customer_contract")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={customerContract}
                                    defaultValue={customerContract}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: "flex"}}>
                        <Autocomplete
                            id="supplierAccount"
                            options={supplierAccountList}
                            className={classes.filter}
                            getOptionLabel={option => option.companyName}
                            value={supplierAccount}
                            defaultValue={supplierAccount}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchSupplierAccounts}
                            onChange={handleSupplierAccountChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("supplier_account")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={supplierAccount}
                                    defaultValue={supplierAccount}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="supplierContract"
                            disabled={!supplierAccount}
                            options={supplierContractList}
                            className={classes.filter}
                            getOptionLabel={option => option.code}
                            value={supplierContract}
                            defaultValue={supplierContract}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchSupplierContracts}
                            onChange={handleSupplierContractChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("supplier_contract")}
                                    InputLabelProps={{shrink: true}}
                                    placeholder={t("any")}
                                    variant="outlined"
                                    margin="dense"
                                    value={supplierContract}
                                    defaultValue={supplierContract}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{display: "flex", width: "70%", margin: "auto", marginBlock: "2%"}}>
                        <DialogTitle style={{width: "fit-content", margin: "auto"}}
                                     variant={"button"}>{t("manifest")}</DialogTitle>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                            <Grid container justify="space-around">
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    inputVariant="outlined"
                                    label={t("manifest_from_date")}
                                    clearable
                                    value={manifestFromDate}
                                    onChange={(value) => {
                                        setManifestFromDate(value);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    label={t("manifest_to_date")}
                                    inputVariant="outlined"
                                    clearable
                                    value={manifestToDate}
                                    onChange={(value) => {
                                        setManifestToDate(value)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{display: "flex", width: "70%", margin: "auto", marginBlock: "2%"}}>
                        <DialogTitle style={{width: "fit-content"}}
                                     variant={"button"}>{t("Check in")}</DialogTitle>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                            <Grid container justify="space-around">
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    inputVariant="outlined"
                                    label={t("check_in_from_date")}
                                    clearable
                                    value={checkInFromDate}
                                    onChange={(value) => {
                                        setCheckInFromDate(value);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    okLabel={null}
                                    clearLabel={t("reset")}
                                    cancelLabel={t("cancel")}
                                    margin="dense"
                                    label={t("check_in_to_date")}
                                    inputVariant="outlined"
                                    clearable
                                    value={checkInToDate}
                                    onChange={(value) => {
                                        setCheckInToDate(value)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        <div style={{display: "flex", width: "70%", margin: "auto", marginBlock: "2%"}}>
                            <DialogTitle style={{width: "fit-content"}}
                                         variant={"button"}>{t("event")}</DialogTitle>
                            <Autocomplete
                                id="contract"
                                options={warehouses}
                                style={{width: "20%", margin: "auto"}}
                                getOptionLabel={option => option.code + " - " + option.name}
                                value={warehouse}
                                defaultValue={warehouse}
                                noOptionsText={t("no_options_available")}
                                onOpen={fetchWarehouses}
                                onChange={handleWarehouseChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("warehouse")}
                                        InputLabelProps={{shrink: true}}
                                        placeholder={t("any")}
                                        variant="outlined"
                                        margin="dense"
                                        value={warehouse}
                                        defaultValue={warehouse}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("event_date_from")}
                                value={anyCheckInEventDateFrom}
                                onChange={handleFirstDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />
                            <DateField
                                style={{margin: "auto", width: "20%"}}
                                label={t("event_date_to")}
                                value={anyCheckInEventDateTo}
                                onChange={handleSecondDate}
                                format={"dd/MM/yyyy"}
                                disableFuture={true}
                                clearable={true}
                            />
                        </div>

                    </div>

                    <div style={{textAlign: 'center'}}>
                        <Button color={"primary"} style={{margin: "5%"}}
                                onClick={clearFilters}>{t("cancel_filter")}</Button>
                        <Button color={"primary"} style={{margin: "5%"}}
                                onClick={applyFilters}>{t("apply_filter")}</Button>
                        <Button color={"primary"} style={{margin: "5%"}}
                                onClick={addToQueue}>{t("add_to_queue")}</Button>
                    </div>

                    {loading ?

                        <LoadingComponent/>

                        :

                        <div>
                            <InfoChip><Typography
                                variant={"subtitle2"}>{t("shipment_count")} {taskQueueRecords.length}</Typography></InfoChip>

                            {taskQueueRecords.length > 0 ?
                                <TableContainer>

                                    <div style={{textAlign: 'center'}}>
                                        <Button color={"primary"} startIcon={<DeleteIcon/>} style={{margin: "1%"}}
                                                onClick={clearQueue}>{t("clear_queue")}</Button>
                                    </div>

                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableCell>
                                                <Typography variant={"inherit"}>  {t("shipment_id")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={"inherit"}>  {t("last_try")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("error_count")} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant={"inherit"}>  {t("last_error_message")} </Typography>
                                            </TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {taskQueueRecords.map((record, index) => {
                                                return <TableRow>
                                                    <TableCell>{record.shipmentId}</TableCell>
                                                    <TableCell>{record.lastDateTimeTry ? new Date(record.lastDateTimeTry).toLocaleDateString() + " " + new Date(record.lastDateTimeTry).toLocaleTimeString() : ""}</TableCell>
                                                    <TableCell>{record.errorCount}</TableCell>
                                                    <TableCell>{record.errorMessage}</TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                null
                            }
                        </div>

                    }

                    <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                  onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
                </Card>
            </PrivateRoute>
        </Switch>
    </Paper>
}