import React, {useEffect, useState} from "react";
import {TableCell} from "@material-ui/core";
import {IconButton, TableRow, Tooltip, Typography} from "@mui/material";
import {DeleteForever, RestartAlt} from "@mui/icons-material";
import BookingQueueService from "./BookingQueueService";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import {useTranslation} from "react-i18next";
import {formatDate, formatDateTime} from "../utils/utils";


function BookingQueueRecord(props) {

    let record = props.record;
    let refreshCallback = props.refreshCallback;
    const {t} = useTranslation();
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    function resetTryCount() {
        let bookingQueueService = new BookingQueueService();
        bookingQueueService.resetTryCount(record.id)
            .then(response => {
                refreshCallback();
            })
            .catch(error => {
                refreshCallback();
            })
    }

    function deleteRecord() {
        let bookingQueueService = new BookingQueueService();
        bookingQueueService.deleteRecords(record.id)
            .then(response => {
                refreshCallback();
            })
            .catch(error => {
                refreshCallback();
            })
    }


    useEffect(() => {

    })

    return <TableRow>

        <TableCell>
            <Typography>{record.shipmentId} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{formatDate(record.date)} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{formatDateTime(record.lastTry)}</Typography>
        </TableCell>
        <TableCell>
            <Typography>{record.errorCount} </Typography>
        </TableCell>
        <TableCell>
            <Typography>{record.errorMessage} </Typography>
        </TableCell>
        <TableCell>

            <Tooltip title={t("reset_try_count")} enterDelay={800} enterNextDelay={800}>
                <IconButton onClick={resetTryCount}>
                    <RestartAlt/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t("delete_record")} enterDelay={800}  enterNextDelay={800}>
                <IconButton onClick={() => setConfirmDeleteDialogOpen(true)}>
                    <DeleteForever/>
                </IconButton>
            </Tooltip>

        </TableCell>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteRecord}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete_record_msg")}
        </ConfirmDialog>
    </TableRow>


}

export default BookingQueueRecord;