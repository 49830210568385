import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Paper, Typography} from "@mui/material";
import {ArrowBackRounded} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import DriverWorkListService from "./DriverWorkListService";


function DriverWorkListDocumentPage(props){

    const {id} = useParams();
    const {t} = useTranslation();

    const history = useHistory();
    const [loadingLabel, setLoadingLabel] = useState(true);
    const [labelError, setLabelError] = useState(false);
    const [href, setHref] = useState('');
    const [filename, setFilename] = useState('');
    const [content, setContent] = useState('');

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

   async function fetchDocument(){
       await new DriverWorkListService().getDriverWorkListDocument(id)
           .then(data => {
               setContent(data.content);
           })
           .catch(error => {
               error.response.json().then(response => {
                   setNotificationMessage(response.status + "-" + response.message);
                   setNotifySeverity('error');
                   setOpenNotify(true);
               })
           });

       setLoadingLabel(false);
   }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });

        fetchDocument();
    }, [])

    return <Paper>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={() => history.goBack()}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>

        {loadingLabel ?
            <div style={{marginBlock: "5%"}}>

                {/*<CheckCircleOutline style={{color: "green"}} fontSize={"large"}/>*/}
                {/*<Typography variant={"subtitle2"} fontSize={20} style={{marginBlock: "5%"}}>{t("shipment_created_wait_label_message")}</Typography>*/}
                <LoadingComponent/>
            </div>


            :

            (labelError ?

                    <div>
                        <Typography variant={"button"}>{"Si è verificato un errore nel recupero del documento"}</Typography>
                    </div>
                    :
                    <div style={{display: "flex", paddingBottom: "5%"}}>
                        <object style={{margin: "auto", marginBlock: "5%"}} width={"80%"} height={1024} type={'application/pdf'} data={"data:application/pdf;base64," + content}>";
                            html += "</object>
                        {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                    </div>
            )
        }

        {/*<Button></Button>*/}

    </Paper>




} export default DriverWorkListDocumentPage;