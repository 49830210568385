import {createTheme} from "@material-ui/core";

import * as themes from './schema.json'


const theme = createTheme({
    palette: {
        primary: {main: themes.data.default.palette.primary.main},
    },
})

export default theme