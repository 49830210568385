import fetch from 'unfetch';
import {checkStatus} from "../api/ApiUtils";
import UserService from "../users/UserService";


const userService = new UserService();


export default class RoleService {
    getAllRoles() {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch("/api/v1/roles", requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getRoleById(roleId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method : "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/roles/${roleId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getPermissionsOfRole(roleId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method : "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };
        return fetch(`/api/v1/roles/${roleId}/permissions`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    createRole(roleName, permissions) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({name:roleName, permissions:permissions})
        };

        return fetch('/api/v1/roles', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    editRole(roleId, roleName, permissions){

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({name:roleName, permissions:permissions})
        };
        return fetch(`/api/v1/roles/${roleId}`, requestOptions)
            .then(checkStatus)
    }

    getUsers(roleId){

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };
        return fetch(`/api/v1/roles/${roleId}/users`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    deleteRole(roleId) {

        const apiKey = userService.getApiKey();
        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/roles/${roleId}`, requestOptions)
            .then(checkStatus)
    }
}