import {Chip, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {CancelOutlined, CheckCircleOutlined, Schedule} from "@material-ui/icons";
import {Popover, Table} from "@material-ui/core";


function Pudo(props) {


    let pudo = props.pudo;


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSchedule = Boolean(anchorEl);

    const id = openSchedule ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const {t} = useTranslation();

    function formatSlotSchedule() {

        let days = {
            MONDAY: [],
            TUESDAY: [],
            WEDNESDAY: [],
            THURSDAY: [],
            FRIDAY: [],
            SATURDAY: [],
            SUNDAY: []
        };

        pudo.slots.map(slot => {

                let data = {};

                data.fromTime = slot.fromTime;
                data.fromTimeMinutes = slot.fromTimeMinutes;
                data.toTime = slot.toTime;
                data.toTimeMinutes = slot.toTimeMinutes;

                if (slot.weekDays.includes("MONDAY")) {
                    days.MONDAY.push(data);
                }
                if (slot.weekDays.includes("TUESDAY")) {
                    days.TUESDAY.push(data);
                }
                if (slot.weekDays.includes("WEDNESDAY")) {
                    days.WEDNESDAY.push(data);
                }
                if (slot.weekDays.includes("THURSDAY")) {
                    days.THURSDAY.push(data);
                }
                if (slot.weekDays.includes("FRIDAY")) {
                    days.FRIDAY.push(data);
                }
                if (slot.weekDays.includes("SATURDAY")) {
                    days.SATURDAY.push(data);
                }
                if (slot.weekDays.includes("SUNDAY")) {
                    days.SUNDAY.push(data);
                }

            }
        )

        return <Table>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("monday") + ": "}</Typography>
                </TableCell>
                <>
                {days.MONDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("tuesday") + ": "}</Typography>
                </TableCell>
                <>
                {days.TUESDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("wednesday") + ": "}</Typography>
                </TableCell>
                <>
                {days.WEDNESDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("thursday") + ": "}</Typography>
                </TableCell>
                <>
                {days.THURSDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("friday") + ": "}</Typography>
                </TableCell>
                <>
                {days.FRIDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("saturday") + ": "}</Typography>
                </TableCell>
                <>
                {days.SATURDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant={"subtitle2"}>{t("sunday") + ": "}</Typography>
                </TableCell>
                <>
                {days.SUNDAY.map(slot => {
                    return<TableCell><Chip label={(slot.fromTime < 10 ? "0" + slot.fromTime : slot.fromTime) + ":" + (slot.fromTimeMinutes < 10 ? "0" + slot.fromTimeMinutes : slot.fromTimeMinutes) + " - " + (slot.toTime < 10 ? "0" + slot.toTime : slot.toTime) + ":" + (slot.toTimeMinutes < 10 ? "0" + slot.toTimeMinutes : slot.toTimeMinutes)}/></TableCell>
                })}
                </>
            </TableRow>
        </Table>

    }


    return <TableRow style={{color: pudo.enabled? null : "gray", fontStyle: pudo.enabled? null : "italic"}}>
        <TableCell>
            {pudo.id}
        </TableCell>
        <TableCell>
            {pudo.code}
        </TableCell>
        <TableCell>
            {pudo.type}
        </TableCell>
        <TableCell>
            {pudo.warehouseCode}
        </TableCell>
        <TableCell>
            <Typography>{pudo.address?.companyName}</Typography>
        </TableCell>
        <TableCell>
            <Typography>{(pudo.address?.street ? pudo.address?.street : "") + (pudo.address?.buildingNr ? ", " + pudo.address?.buildingNr : "")} </Typography>
            <Typography>{(pudo.address?.postalCode ? pudo.address?.postalCode : "") + (pudo.address?.city ? " " + pudo.address?.city : "") + (pudo.address?.administrative_level_3 ? " (" + pudo.address?.administrative_level_3 + ")" : "")}</Typography>
            <Typography>{(pudo.address?.administrative_level_2 ? pudo.address?.administrative_level_2 : "") + (pudo.address?.country ? ", " + pudo.address?.country : "")}</Typography>
        </TableCell>
        {/*<TableCell>*/}
        {/*    <Typography>{"LAT: "}{pudo.latitude}</Typography>*/}
        {/*    <Typography>{"LON: "}{pudo.longitude}</Typography>*/}
        {/*</TableCell>*/}
        <TableCell>
            <TableRow>
                <Chip label={t("delivery")} icon={pudo.delivery ? <CheckCircleOutlined style={{color: "green"}}/> :
                    <CancelOutlined style={{color: "red"}}/>}/>
            </TableRow>
            <TableRow>
                <Chip label={t("pickup")} icon={pudo.pickup ? <CheckCircleOutlined style={{color: "green"}}/> :
                    <CancelOutlined style={{color: "red"}}/>}/>
            </TableRow>
            <TableRow>
                <Chip label={t("cod")} icon={pudo.cod ? <CheckCircleOutlined style={{color: "green"}}/> :
                    <CancelOutlined style={{color: "red"}}/>}/>
            </TableRow>

        </TableCell>
        <TableCell>
            <IconButton aria-describedby={id} onClick={handleClick}><Schedule/></IconButton>
            <Popover
                id={id}
                open={openSchedule}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {formatSlotSchedule()}
            </Popover>
        </TableCell>
    </TableRow>


}

export default Pudo;