import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";

const userService = new UserService();

export default class WarehouseCollectionService{


    getWarehouseCollections(filters){

        const apiKey = userService.getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };

        let pathVariable = `/api/v1/warehouse-collections`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    confirmWarehouseCollection(warehouseCollection) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                warehouseCollection
            )


        };

        return fetch('/api/v1/warehouse-collections', requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    getWarehouseCollectionDocument(warehouseCollectionId) {

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }


        };

        return fetch(`/api/v1/warehouse-collections/${warehouseCollectionId}/document`, requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }



}