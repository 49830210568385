import React, {useEffect, useState} from "react";
import HamletService from "./HamletService";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    Container,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {PrivateRoute} from "../../routes/PrivateRoute";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import Notification from "../../components/notifications/Notification";
import Hamlet from "./Hamlet";
import HamletForm from "./HamletForm";
import PostalCodeService from "../postal_codes/PostalCodeService";
import CityService from "../cities/CityService";

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",
        minHeight: 480,

    },
    container: {
        width: '100%',
    },
    table: {
        width: '100%',
        margin: "auto"

    },
    cell: {
        width: "27%"

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",

    },
    loading: {
        width: '100%',
        margin: "auto",
        marginBottom: "3%",
        display: "block",

    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        icon: {
            color: "secondary",
            marginRight: "1%",
        },
    },
    searchField: {
        width: "40%",
        margin: "auto",
        marginTop: "5%",
        display: "block"
    },
    filterMask: {
        width: "50%",
        margin: "auto",
        marginBottom: "1%",
    },
    filterContainer: {
        width: "100%",
        textAlign: 'center',
        justifyContent: "center",
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
}));





function HamletsTable(props){

    const [hamlets, setHamlets] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [cities, setCities] = useState([]);

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const [pages, setPages] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [refreshTable, setRefreshTable] = useState(false);


    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function closeNotification(){
        setOpenNotify(false);
    }

    function goToHamletForm(){
        history.push("hamlets/new");
    }

    function deleteCallback() {

        setOpenNotify(true);
        setNotifySeverity('success');
        setNotificationMessage("successful");

        setRefreshTable(!refreshTable);

    }

    const handleChangePage = (event, newPage) => {
        setPage(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }


    function fetchHamlets(){
        setFetching(true);
        let hamletService = new HamletService();
        let postalCodeService = new PostalCodeService();
        let cityService = new CityService();
        hamletService.getHamlets(page, rowsPerPage)
            .then(async data => {
                setHamlets(data.hamlets);
                setPages(Array.from(Array(Math.ceil(data.count / rowsPerPage)).keys()));

                let filters = {}
                filters.idList = [];

                data.hamlets.map(hamlet => {
                    if(!filters.idList.includes(hamlet.cityId)){
                        filters.idList.push(hamlet.cityId);
                    }
                })

                await cityService.getCities(null, null, filters, null)
                    .then(data => {
                        setCities(data.cities);
                        setFetching(false);
                    })
            })
            .catch(error => {
                setError(error.message)
            })
    }


    useEffect(()=>{
            fetchHamlets();
    },[location, page, rowsPerPage, refreshTable])

    return  <Container className={classes.root}>


        <PrivateRoute exact path={"/hamlets"}>

            <Button startIcon={<AddCircleIcon/>} onClick={goToHamletForm} variant="contained" color="primary"
                    className={classes.button}>
                {t("add_new_hamlet")}
            </Button>

            <TableContainer className={classes.container}>
                {(fetching) ?
                    <Table>
                        <LoadingComponent/>
                    </Table>

                    :
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableCell className={classes.cell}>{t("hamlet")}</TableCell>
                            <TableCell>{t("city")}</TableCell>
                            <TableCell/>

                        </TableHead>

                        <TableBody>

                            {hamlets.map((hamlet, index) => {

                                return <Hamlet deleteCallback={deleteCallback} hamlet={hamlet} cities={cities} postalCodes={postalCodes}/>
                            })}
                        </TableBody>

                        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                                      onClose={closeNotification}>{notificationMessage}</Notification>
                    </Table>
                }

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                labelRowsPerPage={t("rows_per_page")}
                labelDisplayedRows={() => {
                    return t("page")
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </PrivateRoute>
        <PrivateRoute strict path={`/hamlets/:id/edit/`} children={<HamletForm/>}>
        </PrivateRoute>
        <PrivateRoute exact path={`/hamlets/new/`}>
            <HamletForm city={null}/>
        </PrivateRoute>
    </Container>
}
export default HamletsTable;