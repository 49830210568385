import React, {useEffect, useState} from "react";
import {Paper, TableBody, TableCell, TableHead} from "@mui/material";
import {MenuItem, Select, Table, TableContainer, TablePagination, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Notification from "../components/notifications/Notification";
import VehicleService from "./VehicleService";
import Vehicle from "./Vehicle";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {PrivateRoute} from "../routes/PrivateRoute";
import {Switch, useHistory, useLocation} from "react-router-dom";
import VehicleForm from "./VehicleForm";


const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        margin: "auto",
        marginInline: "5%"
    }
}))

function VehicleTable(props) {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();

    const [vehicles, setVehicles] = useState([]);

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);


    function fetchVehicles() {

        setLoading(true)


        let vehicleService = new VehicleService();
        vehicleService.getVehiclesByFilters(null)
            .then(response => {
                setVehicles(response.vehicles);
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setLoading(false)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setLoading(false)
                    setNotificationMessage("Si è verificato un errore nel recupero dei mezzi: " + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })

    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || loading}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={loading || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function handleChangePage(event) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function deleteCallback(type, response)  {

        setOpenNotify(true);
        setNotifySeverity(type);
        setNotificationMessage(response.message);
        setPage(0);
        setRefresh(!refresh)

    }


    useEffect(() => {
        fetchVehicles();
    }, [location, refresh])

    return <Paper className={classes.root}>
        <Switch>
            <PrivateRoute exact path={`/vehicles`}>
                <Button className={classes.button} startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                        onClick={() => history.push("/vehicles/new")}>
                    {t("create_new_vehicle")}
                </Button>

                {loading ?
                    <LoadingComponent/>
                    :
                    <div>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableCell>{t("vehicle_model")}</TableCell>
                                    <TableCell>{t("vehicle_plate")}</TableCell>
                                    <TableCell>{t("warehouses")}</TableCell>
                                    <TableCell/>
                                </TableHead>

                                <TableBody>


                                    {vehicles.map((vehicle, index) => {

                                        return <Vehicle deleteCallback={deleteCallback} vehicle={vehicle}/>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: false,
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            onPageChange={handleChangePage}
                        />
                    </div>
                }
            </PrivateRoute>
            <PrivateRoute exact path={`/vehicles/:id/edit/`} children={<VehicleForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/vehicles/new/`}>
                <VehicleForm/>
            </PrivateRoute>
        </Switch>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </Paper>

}

export default VehicleTable;