import {TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import NoteTooltip from "../../components/NoteTooltip";
import React from "react";
import {useTranslation} from "react-i18next";


function ParcelEventTabItem(props) {

    let events = props.events;
    let warehouses = props.warehouses;
    let eventCodes = props.eventCodes;
    let drivers = props.drivers

    const {t} = useTranslation();

    return <TableBody>
        {events.map((event, index) => {
            console.log(event.warehouseId)
            return (
                <TableRow style={{border: "2px solid"}} key={index}>
                    <TableCell style={{color: event.deleted? "grey" : null, fontStyle: event.deleted? "italic" : null}}>
                        {new Date(event.dateTime).toLocaleDateString() + " - " + new Date(event.dateTime).toLocaleTimeString()} </TableCell>
                    <TableCell style={{color: event.deleted? "grey" : null, fontStyle: event.deleted? "italic" : null}}>
                        <Typography>
                            {event.eventCodeId ? eventCodes[event.eventCodeId].id + " - " + eventCodes[event.eventCodeId].name : ""}
                        </Typography>
                        <Typography>
                            {event.destinationWarehouseId && warehouses[event.destinationWarehouseId] && (event.eventCodeId === 65 || event.eventCodeId === 91) ? t("departing_to") + warehouses[event.destinationWarehouseId].code : ""}
                        </Typography>
                        <Typography variant={"subtitle2"} color={"primary"}>
                            {event.driverId && drivers[event.driverId] ? drivers[event.driverId].name + " " + drivers[event.driverId].surname : ""}
                        </Typography>
                    </TableCell>
                    <TableCell style={{color: event.deleted? "grey" : null, fontStyle: event.deleted? "italic" : null}}>
                        {event.warehouseId != null && warehouses[event.warehouseId] ? warehouses[event.warehouseId].name : ""}</TableCell>
                    <TableCell style={{color: event.deleted? "grey" : null, fontStyle: event.deleted? "italic" : null}}>
                        {event.notes ?
                        <NoteTooltip>{event.notes}</NoteTooltip>
                        : null
                    }</TableCell>
                </TableRow>
            )
        })}
    </TableBody>

}

export default ParcelEventTabItem;