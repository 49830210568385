import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Switch, useHistory} from "react-router-dom";
import {Autocomplete, Card, DialogTitle, Paper, TableCell, TableHead, TextField, Typography} from "@mui/material";
import moment from "moment";
import WarehouseService from "../../warehouses/WarehouseService";
import DateField from "../../components/fields/DateField";
import WarehouseCollectionService from "./WarehouseCollectionService";
import {AppStyles} from "../../theme/AppStyles";
import InfoChip from "../../components/chips/InfoChip";
import {Table, TableBody} from "@material-ui/core";
import WarehouseCollection from "./WarehouseCollection";
import Button from "@material-ui/core/Button";
import {AddCircleOutlined, SearchOutlined} from "@material-ui/icons";
import {PrivateRoute} from "../../routes/PrivateRoute";
import WarehouseCollectionPage from "./WarehouseCollectionPage";
import WarehouseCollectionDocumentPage from "./WarehouseCollectionDocumentPage";


function WarehouseCollectionTable(props) {


    const [warehouseCollections, setWarehouseCollection] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const classes = AppStyles();

    const today = moment();
    const aMonthAgo = moment().subtract(1, 'months');

    const [dateFrom, setDateFrom] = useState(aMonthAgo);
    const [dateTo, setDateTo] = useState(today);

    const {t} = useTranslation();
    const history = useHistory();


    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleDateFromChange(event) {
        setDateFrom(event)
    }

    function handleDateToChange(event) {
        setDateTo(event)
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    function goToNewWarehouseCollection(){
        history.push(`/warehouse-collection/new`)
    }

    async function fetchWarehouseCollections() {

        let filters = {};

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (dateFrom) {
            filters.dateFrom = moment(dateFrom).format("yyyy-MM-DD")
        }
        if (dateTo) {
            filters.dateTo = moment(dateTo).format("yyyy-MM-DD")
        }


        await new WarehouseCollectionService().getWarehouseCollections(filters)
            .then(result => {
                setWarehouseCollection(result.warehouseCollections);
            })
            .catch(error => {

            })


    }

    function search(){
        setRefresh(!refresh);
    }

    useEffect(() => {
        fetchWarehouseCollections();
    }, [refresh])


    return <div className={classes.root}>
        <Switch>
            <PrivateRoute exact path={"/warehouse-collection"}>
                <DialogTitle>{t("warehouse_collection")}</DialogTitle>
                <Card>
                    <Autocomplete
                        id="warehouse-select"
                        size="small"
                        style={{width: "20%", margin: "auto", marginTop: "4%"}}
                        options={warehouses}
                        getOptionLabel={option => option.code + " - " + option.name}
                        value={warehouse}
                        defaultValue={warehouse}
                        noOptionsText={t("no_options")}
                        onOpen={fetchWarehouses}
                        onChange={handleWarehouseChange}
                        ListboxProps={{style: {maxHeight: '15rem'},}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("warehouse")}
                                variant="outlined"
                                margin="dense"
                                value={warehouse}
                                defaultValue={warehouse}
                            />
                        )}
                    />
                    <div style={{display: "flex", marginBottom: "3%"}}>
                        <DateField
                            style={{margin: "auto", width: "25%"}}
                            label={t("from_date")}
                            value={dateFrom}
                            onChange={handleDateFromChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                        <DateField
                            style={{margin: "auto", width: "25%"}}
                            label={t("to_date")}
                            value={dateTo}
                            onChange={handleDateToChange}
                            format={"dd/MM/yyyy"}
                            disableFuture={true}
                        />
                    </div>
                    <Button
                        className={classes.button}
                        endIcon={<SearchOutlined/>}
                        onClick={search}
                        color="primary"
                        variant={"contained"}>
                        {t("search")}
                    </Button>
                </Card>

                <Button
                    className={classes.button}
                    endIcon={<AddCircleOutlined/>}
                    onClick={goToNewWarehouseCollection}
                    color="primary"
                    variant={"contained"}>
                    {t("new_warehouse_collection")}
                </Button>
                {warehouseCollections.length > 1 ?
                    <Paper style={{marginTop: "5%"}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableCell>
                                    <Typography variant={"inherit"}>  {t("warehouse")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"inherit"}>  {t("datetime")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"inherit"}>  {t("reference_name")} </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"inherit"}>  {t("document_type")} </Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {warehouseCollections.map(item => {
                                    return <WarehouseCollection item={item}/>
                                })}
                            </TableBody>
                        </Table>

                    </Paper>
                    :
                    <InfoChip>{t("no_rows")}</InfoChip>}
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-collection/new"}>
                <WarehouseCollectionPage/>
            </PrivateRoute>
            <PrivateRoute exact path={"/warehouse-collection/:id/document"}>
                <WarehouseCollectionDocumentPage/>
            </PrivateRoute>
        </Switch>

    </div>


}

export default WarehouseCollectionTable;