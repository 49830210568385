import React, {useEffect, useState} from "react";
import {Card, CircularProgress, DialogTitle, Paper, Table, TableBody, TableCell, TableRow} from "@mui/material";
import ZoneService from "./ZoneService";
import WarehouseService from "../warehouses/WarehouseService";
import ContractService from "../accounts/contracts/ContractService";
import ServiceService from "../shipments/services/ServiceService";
import {Button, TableHead, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {Search} from "@material-ui/icons";
import InfoChip from "../components/chips/InfoChip";
import {Download} from "@mui/icons-material";
import theme from "../theme/theme";


function PostalCodeExtraction(props) {

    const {t} = useTranslation();

    const [extractions, setExtractions] = useState([]);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [fetched, setFetched] = useState(false);

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    function handleServiceChange(event, service) {
        setService(service);
    }

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    async function fetchContracts() {

        await new ContractService().getContractsByFilters()
            .then(response => {
                setContracts(response);
            })

    }

    function fetchServices() {
        let serviceService = new ServiceService();
        serviceService.getServicesByFilters()
            .then(data => {
                setServices(data)
            })
    }

    async function getExtractions() {

        setLoading(true);
        setFetched(false);
        setExtractions([]);

        let filters = {};

        if (contract) {
            filters.contractId = contract.id;
        }

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (service) {
            filters.serviceId = service.id;
        }

        await new ZoneService().getPostalCodeExtraction(filters)
            .then(result => {
                setExtractions(result);
                setLoading(false);
                setFetched(true);
            })
            .catch(error => {
                setLoading(false);
                setFetched(true);
            })

    }

    async function getCSV() {

        setLoadingCSV(true);

        let filters = {};

        if (contract) {
            filters.contractId = contract.id;
        }

        if (warehouse) {
            filters.warehouseId = warehouse.id;
        }

        if (service) {
            filters.serviceId = service.id;
        }

        await new ZoneService().getPostalCodeExtractionCSV(filters)
            .then(response => {

                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();
                setLoadingCSV(false);

            })
            .catch(error => {
                setLoadingCSV(false);

            })

    }


    useEffect(() => {

    }, [])

    return <Paper>

        <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("postal_codes_extraction")}</DialogTitle>

        <Card style={{width: "90%", margin: "auto", marginBottom: "5%"}} elevation={0}>
            <Autocomplete
                id="contract"
                size="small"
                style={{minWidth: "30%", width: "fit-content", margin: "auto"}}
                options={contracts}
                getOptionLabel={option => option.code}
                value={contract}
                defaultValue={contract}
                noOptionsText={t("no_options")}
                onChange={handleContractChange}
                onOpen={fetchContracts}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        value={contract}
                        defaultValue={contract}
                    />
                )}
            />
            <Autocomplete
                id="warehouse"
                size="small"
                style={{minWidth: "30%", width: "fit-content", margin: "auto"}}
                options={warehouses}
                getOptionLabel={option => option.code}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options")}
                onChange={handleWarehouseChange}
                onOpen={fetchWarehouses}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("warehouse")}
                        variant="outlined"
                        margin="dense"
                        value={warehouse}
                        defaultValue={warehouse}
                    />
                )}
            />
            <Autocomplete
                id="service"
                size="small"
                style={{minWidth: "30%", width: "fit-content", margin: "auto"}}
                options={services}
                getOptionLabel={option => option.code}
                value={service}
                defaultValue={service}
                noOptionsText={t("no_options")}
                onChange={handleServiceChange}
                onOpen={fetchServices}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("service")}
                        variant="outlined"
                        margin="dense"
                        value={service}
                        defaultValue={service}
                    />
                )}
            />
            <div style={{display: "flex"}}>
                <Button endIcon={loading ? <CircularProgress size={22}/> : <Search/>}
                        style={{margin: "auto", marginBlock: "2%"}}
                        onClick={getExtractions}>{t("search")}</Button>
            </div>
        </Card>
        <div style={{display: "flex"}}>
            <Button disabled={loadingCSV}
                    endIcon={loadingCSV ? <CircularProgress size={22} style={{color: "white"}}/> : <Download/>}
                    style={{
                        margin: "auto",
                        marginBlock: "2%",
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }}
                    onClick={getCSV}>{t("download_csv")}</Button>
        </div>
        {fetched ?
            <div>
                <InfoChip><Typography
                    variant={"subtitle2"}>{extractions.length > 0 ? t("shipment_count") + extractions.length : t("no_data")}</Typography></InfoChip>
            </div>
            :
            null
        }

        {extractions.length > 0 ?
            <div style={{marginTop: "5%"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("postalCode")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}> {t("city")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("administrative3")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("warehouse")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("service")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("zone")}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle2"}>{t("timing")}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extractions.map(extraction => {


                            return <TableRow>
                                <TableCell>
                                    {extraction.postalCode}
                                </TableCell>
                                <TableCell>
                                    {extraction.city}
                                </TableCell>
                                <TableCell>
                                    {extraction.administrativeLevel3}
                                </TableCell>
                                <TableCell>
                                    {extraction.warehouseCode}
                                </TableCell>
                                <TableCell>
                                    {extraction.serviceCode}
                                </TableCell>
                                <TableCell>
                                    {extraction.zoneCode}
                                </TableCell>
                                <TableCell>
                                    {extraction.timingHours ?
                                        <Typography>{extraction.timingHours}</Typography>
                                        :
                                        <>
                                            {extraction.timingDays ?
                                                <Typography>{extraction.timingDays + " - " + t("timing_cutoff_time") + ": " + extraction.timingCutOffTime}</Typography>
                                                :
                                                null
                                            }
                                        </>

                                    }
                                </TableCell>
                            </TableRow>

                        })}
                    </TableBody>
                </Table>

            </div>

            :
            null
        }

    </Paper>


}

export default PostalCodeExtraction;