import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Switch, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {SearchOutlined} from "@material-ui/icons";
import {PrivateRoute} from "../../../routes/PrivateRoute";
import {LoadingComponent} from "../../../components/loading/LoadingComponent";
import {Card, CircularProgress, DialogTitle} from "@mui/material";
import {Autocomplete} from "@material-ui/lab";
import theme from "../../../theme/theme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Notification from "../../../components/notifications/Notification";
import SubContractForm from "./SubContractForm";
import SubContractService from "./SubContractService";
import SubContract from "./SubContract";
import AccountService from "../../AccountService";
import ContractService from "../ContractService";
import InfoChip from "../../../components/chips/InfoChip";


export default function SubContractsTable(props) {

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center'
        },
        container: {
            maxHeight: 840,
        },

        button: {
            marginTop: "3%",
            marginBottom: "3%",

        },
    });

    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [refresh, setRefresh] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);

    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState(null);

    const [subContracts, setSubContracts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function search() {
        setRefresh(!refresh);
    }

    function goToContractForm() {
        history.push(`/sub-contracts/new`)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function handleAccountChange(event, account) {
        setAccount(account);
        setContract(null);
        setContracts([]);
    }

    function handleContractChange(event, contract) {
        setContract(contract);
    }

    async function fetchSubContracts() {

        if (!contract) {
            //todo error
            return;
        }

        setLoading(true);

        setSubContracts([]);

        let filters = {};

        filters.contractId = contract.id;

        await new SubContractService().getSubContractsByFilters(filters)
            .then(result => {
                setSubContracts(result)
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })
    }

    async function fetchAccounts() {

        await new AccountService().getAccounts()
            .then(result => {
                setAccounts(result)
            })
            .catch(error => {

            })
    }

    async function fetchContracts() {

        let filters = {};

        if (account) {
            filters.accountId = account.id;
        }

        await new ContractService().getPagedContractsByFilters(filters)
            .then(response => {
                setContracts(response.contracts);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    useEffect(() => {
        fetchSubContracts();
    }, [location, refresh]);

    return <Paper className={classes.root}>
        <Switch>
            <PrivateRoute exact path={"/sub-contracts"}>
                <div style={{display: "block"}}>

                    <DialogTitle style={{margin: "auto"}}>{t("sub_contracts")}</DialogTitle>

                    <Card style={{width: "50%", margin: "auto", marginTop: "3%"}}>
                        <DialogTitle style={{margin: "auto"}}>{t("filters")}</DialogTitle>
                        <Autocomplete
                            id="account"
                            options={accounts}
                            // className={classes.filter}
                            style={{width: "35%", margin: "auto", marginBlock: "3%"}}
                            getOptionLabel={option => option.accountCorporateCode + " - " + option.companyName}
                            value={account}
                            defaultValue={account}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchAccounts}
                            onChange={handleAccountChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("account")}
                                    variant="outlined"
                                    margin="dense"
                                    value={account}
                                    defaultValue={account}
                                    required
                                    error={!account}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="account"
                            options={contracts}
                            disabled={!account}
                            style={{width: "35%", margin: "auto", marginBlock: "3%"}}
                            getOptionLabel={option => option.code + " - " + option.name}
                            value={contract}
                            defaultValue={contract}
                            noOptionsText={t("no_options_available")}
                            onOpen={fetchContracts}
                            onChange={handleContractChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("contract")}
                                    variant="outlined"
                                    margin="dense"
                                    value={contract}
                                    defaultValue={contract}
                                    required
                                    error={!contract}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />


                        <IconButton onClick={search}
                                    disabled={loading}
                                    style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{loading ?
                            <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                            <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
                    </Card>

                    <Button startIcon={<AddCircleIcon/>} variant="contained" color="primary"
                            onClick={goToContractForm}
                            className={classes.button}>
                        {t("create_new_sub_contract")}
                    </Button>
                    <TableContainer className={classes.container}>
                        {(loading) ?

                            <LoadingComponent/>

                            :
                            <div>
                                {subContracts.length > 0 ?
                                    <Table stickyHeader aria-label="">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("sub_contract_code")}</TableCell>
                                                <TableCell>{t("contract_code")}</TableCell>
                                                <TableCell>{t("account_corporate_code")}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subContracts.map((subContract, index) => {
                                                return <SubContract key={subContract.id} subContract={subContract}/>
                                            })}
                                        </TableBody>
                                    </Table>
                                    :
                                    <InfoChip>{t("no_data")}</InfoChip>
                                }
                            </div>

                        }
                    </TableContainer>
                </div>
            </PrivateRoute>
            <PrivateRoute strict path={`/sub-contracts/:id/edit`} children={<SubContractForm/>}>
            </PrivateRoute>
            <PrivateRoute exact path={`/sub-contracts/new`}>
                <SubContractForm/>
            </PrivateRoute>
        </Switch>

        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Paper>


}