import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import WarehouseStorageService from "./WarehouseStorageService";
import {Button, Paper, Typography} from "@mui/material";
import {ArrowBackRounded, CheckCircleOutline} from "@material-ui/icons";
import {LoadingComponent} from "../components/loading/LoadingComponent";


function WarehouseStorageLabelPage(props){


    const {id} = useParams();
    const {t} = useTranslation();

    const history = useHistory();
    const [loadingLabel, setLoadingLabel] = useState(true);
    const [labelError, setLabelError] = useState(false);
    const [href, setHref] = useState('');
    const [filename, setFilename] = useState('');
    const [content, setContent] = useState('');

    async function fetchLabel() {
        await new WarehouseStorageService().getWarehouseStorageLabel(id)
            .then(response => {
                setHref('data:application/pdf;base64,' + response.content);
                setFilename(response.fileName);
                setContent(response.content)
                setLoadingLabel(false)
                setLabelError(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    if (response.status === 425) {
                        setTimeout(() => {
                            fetchLabel();
                        }, 5000)
                    } else {
                        setLoadingLabel(false);
                        setLabelError(true);
                    }
                })
            })

    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });

        fetchLabel();
    }, [])


    return <Paper>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={() => history.push("/warehouse-storages")}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>

        {loadingLabel ?
            <div style={{marginBlock: "5%"}}>

                <CheckCircleOutline style={{color: "green"}} fontSize={"large"}/>
                <Typography variant={"subtitle2"} fontSize={20} style={{marginBlock: "5%"}}>{t("warehouse_storage_created_wait_label_message")}</Typography>
                <LoadingComponent/>
            </div>

            :

            (labelError ?

                    <div>
                        <Typography variant={"button"}>{"Si è verificato un errore nel recupero dell'etichetta"}</Typography>
                    </div>
                    :
                    <div>
                        <object style={{marginBlock: "5%"}} width={400} height={400} type={'application/pdf'} data={"data:application/pdf;base64," + content}>";
                            html += "</object>
                        {/*<Button style={{marginBlock: "5%"}} href={href} download={filename}> {t("download_label")}</Button>*/}
                    </div>
            )
        }

        {/*<Button></Button>*/}

    </Paper>





} export default WarehouseStorageLabelPage;