import React from 'react';
import {CircularProgress, Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


export const MinimalLoadingComponent = (props) => {

    let loadingText = props.loadingText;
    let loadingItemSize = props.loadingItemSize;

    const useStyle = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "flex",
            // minHeight: 587,

        },
        loadingItem: {
            width: '30%',
            margin: "auto",
            marginTop: "3%",
            marginBottom: "10%",
            display: "block",


        },
        loadingContainer: {
            width: '100%',
            alignSelf: "center"

        }
    })

    const classes = useStyle();


    return <div className={classes.root}>
        <Container className={classes.loadingContainer} variant="outlined">

            <Typography className={classes.loadingItem} variant="button">{loadingText}</Typography>

            <CircularProgress className={classes.loadingItem} color="primary" size={loadingItemSize}/>

        </Container>

    </div>

};