import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


export default class TripService {


    getTrips(filters) {

        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/trips`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getCsv(filters) {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/trips/csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }


    getDetailsCsv(filters) {

        const apiKey = new UserService().getApiKey();

        const lang = localStorage.getItem("i18nextLng");

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': lang
            }
        };
        let pathVariable = `/api/v1/trips/details-csv`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }

    getTripsForSummary(filters) {

        const apiKey = new UserService().getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/trips/summary`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());


    }


    saveTrip(trip) {


        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(trip)
        };

        return fetch('/api/v1/trips', requestOptions)
            .then(checkStatus)
            .then(response => response.json())

    }

    updateTrip(tripId, input) {


        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(`/api/v1/trips/${tripId}`, requestOptions)
            .then(checkStatus)

    }

    addParcelsToTrip(tripId, parcelsInput) {
        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(parcelsInput)
        };

        return fetch(`/api/v1/trips/${tripId}/add`, requestOptions)
            .then(checkStatus)
        // .then(response => response.json());
    }

    addLoadingListToTrip(tripId, loadingListInput) {

        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(loadingListInput)
        };

        return fetch(`/api/v1/trips/${tripId}/add-loading-list`, requestOptions)
            .then(checkStatus)
        // .then(response => response.json());
    }


    generateSummaryOut(summaryOutInput) {

        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(summaryOutInput)
        };

        return fetch(`/api/v1/summary_out`, requestOptions)
            .then(checkStatus)
            // .then(response => response.json());

    }

    downloadSummary(id, reduced) {
        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/summary_out/${id}/pdf?reduced=${reduced}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getParcelsInTrip(id) {
        const apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/trips/${id}/parcels`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    removeParcelFromTrip(tripId, input) {


        const apiKey = new UserService().getApiKey();


        const requestOption = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(`/api/v1/trips/${tripId}/remove`, requestOption)
            .then(checkStatus)
    }


    removeLoadingListFromTrip(tripId, input) {


        const apiKey = new UserService().getApiKey();


        const requestOption = {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        return fetch(`/api/v1/trips/${tripId}/remove-loading-list`, requestOption)
            .then(checkStatus)
    }


}