import ListItem from "@material-ui/core/ListItem";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
    AccountBalanceWallet,
    AirlineSeatReclineExtra,
    AirportShuttle,
    Alarm,
    ArrowForward,
    Business,
    CreditCard,
    DepartureBoard,
    DoubleArrow,
    Dvr,
    Equalizer,
    Euro,
    ExpandLess,
    ExpandMore,
    Explore,
    FindReplace,
    Flag,
    Home,
    LibraryAddCheck,
    LocalAtm,
    LocalShipping,
    LocationCity,
    Map,
    MarkunreadMailbox,
    MonetizationOn,
    Money,
    Payment,
    People,
    Person,
    PhoneAndroid,
    PieChart,
    PlaylistAdd,
    PlaylistAddCheck,
    Public,
    Queue,
    RecentActors,
    RemoveCircleOutline,
    Report,
    Storefront,
    SupervisorAccount,
    TransferWithinAStation
} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {Collapse, Divider, List} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles} from "../theme/useStyles";
import {
    AdminPanelSettings,
    AirlineStops,
    AltRoute,
    ArrowCircleUp,
    Article,
    CalendarToday,
    ChangeCircle,
    Directions,
    DomainVerification,
    Downloading,
    FactCheck,
    HourglassBottom,
    Outbox,
    Payments,
    Pending,
    PendingActions,
    PlaylistAddCircleOutlined,
    PriceCheck,
    ReceiptLong,
    Reviews,
    Route,
    Rule,
    ScheduleSendOutlined,
    ShareLocationOutlined,
    Summarize,
    Upload
} from "@mui/icons-material";
import {Typography} from "@mui/material";
import theme from "../theme/theme";

export default function BackofficeMenu(props) {

    let primaryTypographyProps = {style: {whiteSpace: "normal"}}

    const classes = useStyles();
    const [isArrivalsOpen, setArrivalsOpen] = useState(false);

    const [isRoutingMenuOpen, setRoutingMenuOpen] = useState(false);
    const [isTimingMenuOpen, setTimingMenuOpen] = useState(false);
    const [isDistrictsOpen, setDistrictsOpen] = useState(false);
    const [isManageUsersOpened, setManageUsersOpen] = useState(false);
    const [isAccountMenuOpened, setAccountMenuOpen] = useState(false);
    const [isShipmentMenuOpened, setShipmentMenuOpen] = useState(false);
    const [isManageWarehouseOpened, setManageWarehouseOpen] = useState(false);
    const [isAccountingOpened, setAccountingOpen] = useState(false);
    const [isGeoMenuOpened, setGeoMenuOpen] = useState(false);
    const [isManageDriverWorkListOpened, setManageDriverWorkListOpen] = useState(false);
    const [isDeparturesOpen, setDeparturesOpen] = useState(false);
    const [isCodsOpen, setCodsOpen] = useState(false);
    const [isLoadingListsOpen, setLoadingListsOpen] = useState(false);
    const [isPlanningAndControlOpen, setPlanningAndControlOpen] = useState(false);
    const [isRatingsOpen, setRatingsOpen] = useState(false);
    let permissions = localStorage.getItem("permissions");

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();


    useEffect(() => {

    })

    return (
        <div style={{height: "100%", width: props.itemWidth}}>
            <ListItem button component={NavLink} to="/">
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary={t("dashboard")}/>
            </ListItem>
            {permissions.includes("users:read") || permissions.includes("roles:write") ?
                <>
                    <ListItem button className={permissions.includes("users:read") ? null : classes.hidden}
                              onClick={() => setManageUsersOpen(!isManageUsersOpened)}>
                        <ListItemIcon>
                            <People/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("manage_users")}/>
                        {isManageUsersOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isManageUsersOpened} timeout="auto" unmountOnExit>

                        <List component="div" disablePadding>
                            {permissions.includes("users:read") ?
                                <ListItem button component={NavLink} to="/users" className={classes.nested}>
                                    <ListItemIcon>
                                        <People/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("users")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("roles:write") ?
                                <ListItem button component={NavLink} to="/roles" className={classes.nested}>
                                    <ListItemIcon>
                                        <SupervisorAccount/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("roles")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :

                null
            }
            {permissions.includes("accounts:write") || permissions.includes("contracts:write") || permissions.includes("rates:write") ?

                <>
                    <ListItem button onClick={() => setAccountMenuOpen(!isAccountMenuOpened)}>
                        <ListItemIcon>
                            <Person/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("accounts")}/>
                        {isAccountMenuOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isAccountMenuOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("accounts:write") ?
                                <ListItem button component={NavLink} to="/accounts" className={classes.nested}>
                                    <ListItemIcon>
                                        <Person/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("accounts")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounts:write") ?
                                <ListItem button component={NavLink} to="/contracts" className={classes.nested}>
                                    <ListItemIcon>
                                        <Article/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("contracts")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounts:write") ?
                                <ListItem button component={NavLink} to="/sub-contracts" className={classes.nested}>
                                    <ListItemIcon>
                                        <RecentActors/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("sub_contracts")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("rates:write") ?
                                <ListItem button component={NavLink} to="/rates" className={classes.nested}>
                                    <ListItemIcon>
                                        <Money/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("rates")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null
            }

            {permissions.includes("shipments:read") || permissions.includes("bookings:queue") || permissions.includes("parcel-events:write") || permissions.includes("warehouse-storages:read") || permissions.includes("su:admin") ?
                <>
                    <ListItem button onClick={() => setShipmentMenuOpen(!isShipmentMenuOpened)}>
                        <ListItemIcon>
                            <LocalShipping/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("shipments_management")}/>
                        {isShipmentMenuOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isShipmentMenuOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("shipments:read") ?
                                <ListItem button component={NavLink} to="/shipments" className={classes.nested}>
                                    <ListItemIcon>
                                        <LocalShipping/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("shipments")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("bookings:queue") ?
                                <ListItem button component={NavLink} to="/booking-queue" className={classes.nested}>
                                    <ListItemIcon>
                                        <CalendarToday/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("booking_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("su:admin") ?
                                <ListItem button component={NavLink} to="/primaedicola-queue"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <CalendarToday/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("primaedicola_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("su:admin") ?
                                <ListItem button component={NavLink} to="/parcels-queue"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <CalendarToday/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("parcels_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/delete-status" className={classes.nested}>
                                    <ListItemIcon>
                                        <RemoveCircleOutline/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("delete_status")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("warehouse-storages:read") ?
                                <ListItem button component={NavLink} to="/warehouse-storages"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <WarehouseIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("warehouse_storages")}/>
                                </ListItem>

                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/address-change-requests"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <ChangeCircle/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("address_change_requests")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/warehouse-collection"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <TransferWithinAStation/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("warehouse_collection")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/import-shipments"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Downloading/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("import_shipments")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null
            }

            {permissions.includes("pickups:read") ?
                <ListItem button component={NavLink} to="/pickups">
                    <ListItemIcon>
                        <Outbox/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={primaryTypographyProps}
                        primary={t("pickups")}/>
                </ListItem>

                :

                null
            }

            {permissions.includes("warehouses:write") || permissions.includes("drivers:write") || permissions.includes("vehicles:write") || permissions.includes("handhelds:write") ?
                <>
                    <ListItem button onClick={() => setManageWarehouseOpen(!isManageWarehouseOpened)}>
                        <ListItemIcon>
                            <Business/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("warehouse_management")}/>
                        {isManageWarehouseOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isManageWarehouseOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("shipments:write") ?
                                <ListItem button component={NavLink} to="/warehouses" className={classes.nested}>
                                    <ListItemIcon>
                                        <Business/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("warehouses")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("vehicles:write") ?
                                <ListItem button component={NavLink} to="/vehicles" className={classes.nested}>
                                    <ListItemIcon>
                                        <AirportShuttle/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("vehicles")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("drivers:write") ?
                                <ListItem button component={NavLink} to="/drivers" className={classes.nested}>
                                    <ListItemIcon>
                                        <AirlineSeatReclineExtra/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("drivers")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("handhelds:write") ?
                                <ListItem button component={NavLink} to="/handhelds" className={classes.nested}>
                                    <ListItemIcon>
                                        <PhoneAndroid/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("handhelds")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null
            }

            {permissions.includes("preinvoices:write") ||
            permissions.includes("cost-centers:write") ||
            permissions.includes("tax-rates:write") ||
            permissions.includes("accounting-rows:read") ||
            permissions.includes("ledger-accounts:write") ?

                <>
                    <ListItem button onClick={() => setAccountingOpen(!isAccountingOpened)}>
                        <ListItemIcon>
                            <Euro/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("accounting")}/>
                        {isAccountingOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isAccountingOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("preinvoices:write") ?
                                <ListItem className={classes.nested} button component={NavLink} to="/tax-queue">
                                    <ListItemIcon>
                                        <Queue/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("active_tax_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("preinvoices:write") ?
                                <ListItem className={classes.nested} button component={NavLink} to="/passive-tax-queue">
                                    <ListItemIcon>
                                        <Queue/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("passive_tax_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("preinvoices:write") ?
                                <ListItem className={classes.nested} button component={NavLink}
                                          to="/customer-pre-invoices">
                                    <ListItemIcon>
                                        <Payment/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("customer-pre-invoices")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("preinvoices:write") ?
                                <ListItem className={classes.nested} button component={NavLink}
                                          to="/supplier-pre-invoices">
                                    <ListItemIcon>
                                        <Payment/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("supplier-pre-invoices")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("cost-centers:write") ?
                                <ListItem button component={NavLink} to="/cost-centers" className={classes.nested}>
                                    <ListItemIcon>
                                        <MonetizationOn/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("cost_centers")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("tax-rates:write") ?
                                <ListItem button component={NavLink} to="/tax-rates" className={classes.nested}>
                                    <ListItemIcon>
                                        <Equalizer/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("tax_rates")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("ledger-accounts:write") ?
                                <ListItem button component={NavLink} to="/ledger-accounts" className={classes.nested}>
                                    <ListItemIcon>
                                        <AccountBalanceWallet/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("ledger_accounts")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounting-rows:read") ?
                                <ListItem button component={NavLink} to="/sales-orders" className={classes.nested}>
                                    <ListItemIcon>
                                        <PieChart/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("sales_orders")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounting-rows:read") ?
                                <ListItem button component={NavLink} to="/costs-orders" className={classes.nested}>
                                    <ListItemIcon>
                                        <PieChart/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("costs_orders")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounting-rows:read") ?
                                <ListItem button component={NavLink} to="/trips-orders" className={classes.nested}>
                                    <ListItemIcon>
                                        <PieChart/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("trips_orders")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("accounting-rows:read") ?
                                <ListItem button component={NavLink} to="/month_extraction" className={classes.nested}>
                                    <ListItemIcon>
                                        <PendingActions/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("month_extraction")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null

            }

            {permissions.includes("geo:write") ?

                <>
                    <ListItem button onClick={() => setGeoMenuOpen(!isGeoMenuOpened)}>
                        <ListItemIcon>
                            <Public/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("geo")}/>
                        {isGeoMenuOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isGeoMenuOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={NavLink} to="/countries" className={classes.nested}>
                                <ListItemIcon>
                                    <Flag/>
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={primaryTypographyProps}
                                    primary={t("countries")}/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/cities" className={classes.nested}>
                                <ListItemIcon>
                                    <LocationCity/>
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={primaryTypographyProps}
                                    primary={t("cities")}/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/hamlets" className={classes.nested}>
                                <ListItemIcon>
                                    <Home/>
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={primaryTypographyProps}
                                    primary={t("hamlets")}/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/postal-codes" className={classes.nested}>
                                <ListItemIcon>
                                    <MarkunreadMailbox/>
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={primaryTypographyProps}
                                    primary={t("postalCode")}/>
                            </ListItem>
                        </List>
                    </Collapse>

                </>

                :

                null

            }

            {permissions.includes("zones:read") ?

                <>
                    <ListItem button onClick={() => setRoutingMenuOpen(!isRoutingMenuOpen)}>
                        <ListItemIcon>
                            <Directions/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("routing")}/>
                        {isRoutingMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isRoutingMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("zones:read") ?
                                <ListItem button component={NavLink} to="/zones" className={classes.nested}>
                                    <ListItemIcon>
                                        <Map/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("zones")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("zones:read") ?
                                <ListItem button component={NavLink} to="/routing-rules/zones"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <AltRoute/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("routing_rules")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("zones:read") ?
                                <ListItem button component={NavLink} to="/extraction"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Summarize/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("postal_codes_extraction")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :

                null


            }

            {permissions.includes("districts:write") ?

                <>
                    <ListItem button onClick={() => setDistrictsOpen(!isDistrictsOpen)}>
                        <ListItemIcon>
                            <Explore/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps} s
                            primary={t("districts_management")}/>
                        {isDistrictsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isDistrictsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("districts:write") ?
                                <ListItem button component={NavLink} to="/districts" className={classes.nested}>
                                    <ListItemIcon>
                                        <Explore/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("districts")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("districts:write") ?
                                <ListItem button component={NavLink} to="/routing-rules/districts"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <AltRoute/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("routing_rules")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("districts:write") ?
                                <ListItem button component={NavLink} to="/slots" className={classes.nested}>
                                    <ListItemIcon>
                                        <Alarm/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("slots")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :

                null


            }


            {permissions.includes("timings:read") ?

                <>
                    <ListItem button onClick={() => setTimingMenuOpen(!isTimingMenuOpen)}>
                        <ListItemIcon>
                            <HourglassBottom/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("timings")}/>
                        {isTimingMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isTimingMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("timings:read") ?
                                <ListItem button component={NavLink} to="/timings" className={classes.nested}>
                                    <ListItemIcon>
                                        <HourglassBottom/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("timings")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("timings:read") ?
                                <ListItem button component={NavLink} to="/routing-rules/timings"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <AltRoute/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("areas")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("timings:read") ?
                                <ListItem button component={NavLink} to="/service-levels"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PieChart/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("service_levels")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("timings:write") ?
                                <ListItem button component={NavLink} to="/tasks-queue"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Pending/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("tasks_queue")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :

                null


            }

            {permissions.includes("driver-work-list:read") || permissions.includes("driver-work-list:write") || permissions.includes("parcel-events:write") ?
                <>
                    <ListItem button onClick={() => setManageDriverWorkListOpen(!isManageDriverWorkListOpened)}>
                        <ListItemIcon>
                            <FactCheck/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("driver_work_lists_management")}/>
                        {isManageDriverWorkListOpened ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isManageDriverWorkListOpened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("driver-work-list:read") ?
                                <ListItem button component={NavLink} to="/driver-work-lists" className={classes.nested}>
                                    <ListItemIcon>
                                        <FactCheck/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("driver_work_lists_menu")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/truck-load" className={classes.nested}>
                                    <ListItemIcon>
                                        <Upload/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("truck_load")}/>
                                </ListItem>

                                :

                                null
                            }
                            {permissions.includes("driver-work-list:write") ?
                                <ListItem button component={NavLink} to="/driver-work-lists/save"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PlaylistAdd/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("confirm_driver_work_list")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("driver-work-list:write") ?
                                <ListItem button component={NavLink} to="/driver-work-lists/confirm"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PendingActions/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("confirm_delayed")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("driver-work-list:write") ?
                                <ListItem button component={NavLink} to="/driver-work-lists/add"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PlaylistAddCircleOutlined/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("add_to_driver_work_list")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("driver-work-list:write") ?
                                <ListItem button component={NavLink} to="/driver-work-lists/close"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PlaylistAddCheck/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("close_driver_work_list")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("driver-work-list:write") ?
                                <ListItem button component={NavLink} to="/driver-work-lists/optimize"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <ShareLocationOutlined/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("optimize_driver_work_list")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :

                null

            }

            {permissions.includes("parcel-events:write") ?
                <>
                    <ListItem button onClick={() => setArrivalsOpen(!isArrivalsOpen)}>
                        <ListItemIcon>
                            <DoubleArrow/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("arrivals")}/>
                        {isArrivalsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isArrivalsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/arrivals" className={classes.nested}>
                                    <ListItemIcon>
                                        <DoubleArrow/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("arrivals")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("summary-in:write") ?
                                <ListItem button component={NavLink} to="/customers-missing" className={classes.nested}>
                                    <ListItemIcon>
                                        <Rule/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("customers_missing_parcels")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("summary-out:write") ?
                                <ListItem button component={NavLink} to="/warehouse-missing" className={classes.nested}>
                                    <ListItemIcon>
                                        <Rule/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("warehouse_missing_parcels")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null
            }

            {permissions.includes("trips:read") || permissions.includes("parcel-events:write") || permissions.includes("su:admin") || permissions.includes("summary-out:write") ?
                <>
                    <ListItem button onClick={() => setDeparturesOpen(!isDeparturesOpen)}>
                        <ListItemIcon>
                            <AirlineStops/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("departures")}/>
                        {isDeparturesOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isDeparturesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("trips:read") ?
                                <ListItem button component={NavLink} to="/trips" className={classes.nested}>
                                    <ListItemIcon>
                                        <LocalShipping/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("trips")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("routes:write") ?
                                <ListItem button component={NavLink} to="/routes" className={classes.nested}>
                                    <ListItemIcon>
                                        <Route/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("routes")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("parcel-events:write") ?
                                <ListItem button component={NavLink} to="/departures" className={classes.nested}>
                                    <ListItemIcon>
                                        <AirlineStops/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("departures")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("check-in-departures:read") ?
                                <ListItem button component={NavLink} to="/check-in-departures" className={classes.nested}>
                                    <ListItemIcon>
                                        <DepartureBoard/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("check_in_departures")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("summary-out:write") ?
                                <ListItem button component={NavLink} to="/summaries" className={classes.nested}>
                                    <ListItemIcon>
                                        <Summarize/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("summaries")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>

                :
                null
            }


            {permissions.includes("cods:write") ||
            permissions.includes("verify-payments:write") ||
            permissions.includes("cod-payments:write") ||
            permissions.includes("payments-to-customers:write")
                ?
                <>
                    <ListItem button onClick={() => setCodsOpen(!isCodsOpen)}>
                        <ListItemIcon>
                            <LocalAtm/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("cods")}/>
                        {isCodsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isCodsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("verify-payments:write") ?
                                <ListItem button component={NavLink} to="/payment-cards"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <CreditCard/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("payment_cards")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("verify-payments:write") ?
                                <ListItem button component={NavLink} to="/cods/supplier-report"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Report/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("supplier_report")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("cods:write") ?
                                <ListItem button component={NavLink} to="/cods/verify" className={classes.nested}>
                                    <ListItemIcon>
                                        <DomainVerification/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("verify_cods")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("cod-events:delete") ?
                                <ListItem button component={NavLink} to="/delete-cod-event-requests"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <ScheduleSendOutlined/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("delete_cod_events_requests")}
                                    />
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("cod-payments:write") ||
                            permissions.includes("verify-payments:write") ||
                            permissions.includes("payments-to-customers:write") ||
                            permissions.includes("bank-transactions:read") ||
                            permissions.includes("bank-transactions:write") ?
                                <div style={{backgroundColor: theme.palette.primary.main}}>
                                    <Divider/>
                                    <Typography variant={"subtitle2"} style={{
                                        width: "fit-content",
                                        margin: "auto",
                                        color: "white"
                                    }}>{t("cash")}</Typography>
                                    <Divider/>
                                </div>
                                :
                                null
                            }

                            {permissions.includes("cod-payments:write") ?
                                <ListItem button component={NavLink} to="/payments" className={classes.nested}>
                                    <ListItemIcon>
                                        <Payments/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("payment_slips")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("verify-payments:write") ?
                                <ListItem button component={NavLink} to="/payments/verify" className={classes.nested}>
                                    <ListItemIcon>
                                        <PriceCheck/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("verify_payment_slip")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("bank-transactions:read") ?
                                <ListItem button component={NavLink} to="/bank-transactions" className={classes.nested}>
                                    <ListItemIcon>
                                        <ReceiptLong/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("bank_transactions")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("bank-transactions:write") ?
                                <ListItem button component={NavLink} to="/import-from-cbi"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Downloading/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("import_from_cbi")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("payments-to-customers:write") ?
                                <ListItem button component={NavLink} to="/payments/customer" className={classes.nested}>
                                    <ListItemIcon>
                                        <Payments/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("payments_to_customer")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("cod-payments:write") ||
                            permissions.includes("verify-payments:write") ||
                            permissions.includes("payments-to-customers:write") ?
                                <div style={{backgroundColor: theme.palette.primary.main}}>
                                    <Divider/>
                                    <Typography variant={"subtitle2"} style={{
                                        width: "fit-content",
                                        margin: "auto",
                                        color: "white"
                                    }}>{t("cheques")}</Typography>
                                    <Divider/>
                                </div>
                                :
                                null
                            }
                            {permissions.includes("cod-payments:write") ?
                                <ListItem button component={NavLink} to="/cheque-forwarding" className={classes.nested}>
                                    <ListItemIcon>
                                        <ArrowForward/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("cheque_forwarding")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("verify-payments:write") ?
                                <ListItem button component={NavLink} to="/cheque-forwarding/verify"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <PriceCheck/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("verify_cheque_forwarding")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("payments-to-customers:write") ?
                                <ListItem button component={NavLink} to="/cheque/payments" className={classes.nested}>
                                    <ListItemIcon>
                                        <Payments/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("cheque_payment_to_customer")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>
                :
                null
            }
            {permissions.includes("loadingLists:read") ?
                <>
                    <ListItem button onClick={() => setLoadingListsOpen(!isLoadingListsOpen)}>
                        <ListItemIcon>
                            <ArrowCircleUp/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("loading_lists")}/>
                        {isLoadingListsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isLoadingListsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("loadingLists:read") ?
                                <ListItem button component={NavLink} to="/loading-lists" className={classes.nested}>
                                    <ListItemIcon>
                                        <ArrowCircleUp/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("loading_lists")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>
                :
                null
            }
            {permissions.includes("parcel-events:write") ?
                <ListItem button component={NavLink} to="/warehouse-check">
                    <ListItemIcon>
                        <LibraryAddCheck/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={primaryTypographyProps}
                        primary={t("warehouse_check")}/>
                </ListItem>
                :
                null
            }

            {permissions.includes("planning-and-control:read") ?
                <>
                    <ListItem button onClick={() => setPlanningAndControlOpen(!isPlanningAndControlOpen)}>
                        <ListItemIcon>
                            <AdminPanelSettings/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("planning_and_control")}/>
                        {isPlanningAndControlOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isPlanningAndControlOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("planning-and-control:read") ?
                                <ListItem button component={NavLink} to="/planning-and-control"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <AdminPanelSettings/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("planning_and_control")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("planning-and-control:read") ?
                                <ListItem button component={NavLink} to="/round-details" className={classes.nested}>
                                    <ListItemIcon>
                                        <FindReplace/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("round_details")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>
                :
                null
            }
            {permissions.includes("pudos:write") ?
                <ListItem button component={NavLink} to="/pudo">
                    <ListItemIcon>
                        <Storefront/>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={primaryTypographyProps}
                        primary={t("pudo")}/>
                </ListItem>
                :
                null
            }
            {permissions.includes("ratings:read") ?
                <>
                    <ListItem button onClick={() => setRatingsOpen(!isRatingsOpen)}>
                        <ListItemIcon>
                            <Reviews/>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={primaryTypographyProps}
                            primary={t("ratings")}/>
                        {isRatingsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={isRatingsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {permissions.includes("ratings:read") ?
                                <ListItem button component={NavLink} to="/ratings"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Reviews/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("ratings")}/>
                                </ListItem>
                                :
                                null
                            }
                            {permissions.includes("ratings:read") ?
                                <ListItem button component={NavLink} to="/rating-options"
                                          className={classes.nested}>
                                    <ListItemIcon>
                                        <Dvr/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={primaryTypographyProps}
                                        primary={t("rating_options")}/>
                                </ListItem>
                                :
                                null
                            }
                        </List>
                    </Collapse>
                </>
                :
                null
            }
        </div>
    );
}