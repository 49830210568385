import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import RatingOptionService from "./RatingOptionService";
import {IconButton, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import {useHistory} from "react-router-dom";
import {DeleteForever, Edit} from "@material-ui/icons";
import Notification from "../../components/notifications/Notification";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {useTranslation} from "react-i18next";


function RatingOption(props){


    const history = useHistory();
    const option = props.option;

    const {t} = useTranslation();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    function goToEditPage(){
        history.push(`/rating-options/${option.id}/edit`)
    }

    async function deleteOption() {

        await new RatingOptionService().deleteRatingOption(option.id)
            .then(result => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            })

    }


    useEffect(()=>{

    }, [])

    return <TableRow>
            <TableCell>
                {option.contractCode? option.contractCode : <Typography fontStyle={"italic"} variant={"overline"}>{"default"}</Typography>}
            </TableCell>
            <TableCell>
                {option.minScore}
            </TableCell>
            <TableCell>
                {option.maxScore}
            </TableCell>
            <TableCell>
                {option.locales.map(locale => {
                    return<div style={{width: "100%", display: "flex"}}>
                        <Chip style={{width: "15%"}} label={<Typography variant={"button"}>{locale.language}</Typography>}/><Typography style={{marginTop: "1%", marginLeft: "2%"}} variant={"subtitle2"}> {locale.text}</Typography>
                    </div>
                })}
            </TableCell>
            <TableCell>
                <IconButton onClick={goToEditPage}><Edit/></IconButton>
                <IconButton onClick={()=>setOpenConfirmDialog(true)}><DeleteForever/></IconButton>
            </TableCell>
        <ConfirmDialog open={openConfirmDialog}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteOption}
                       onCancel={() => setOpenConfirmDialog(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
        <Notification open={openNotify} severity={notifySeverity} duration={100000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </TableRow>



} export default RatingOption;