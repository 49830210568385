import React, {useEffect, useState} from "react";
import {Autocomplete, DialogTitle, IconButton, Table, TableHead, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Card, Paper, TableCell, TableRow} from "@material-ui/core";
import WarehouseService from "../warehouses/WarehouseService";
import Notification from "../components/notifications/Notification";
import DriverService from "../drivers/DriverService";
import VehicleService from "../vehicles/VehicleService";
import ShipmentService from "../shipments/ShipmentService";
import ParcelService from "../shipments/services/ParcelService";
import theme from "../theme/theme";
import {CancelRounded} from "@material-ui/icons";
import DriverWorkListService from "../driver_work_list/DriverWorkListService";
import InfoChip from "../components/chips/InfoChip";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import PickupService from "../pickups/PickupService";


function TruckLoadPage(props) {

    const {t} = useTranslation();

    const [warehouse, setWarehouse] = useState(null);
    const [driver, setDriver] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const [barcode, setBarcode] = useState('');

    const [greenBarcodes, setGreenBarcodes] = useState([]);
    const [unknownBarcodes, setUnknownBarcodes] = useState([]);

    const [shipmentData, setShipmentData] = useState([]);
    const [pickupData, setPickupData] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [parcelQueue, setParcelQueue] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse);

        setVehicle(null);
        setDriver(null);
        fetchDriversAndVehicles(warehouse);
    }

    async function handleDriverChange(event, driver) {
        setLoading(true)
        setDriver(driver);

        if (null !== driver) {
            let driverWorkListService = new DriverWorkListService();
            let workList = await driverWorkListService.getDriverTruckLoad(driver.id);
            await fetchWorkList(workList);
        } else {
            setParcelQueue([]);
            setShipmentData([]);
            setPickupData([]);
            setFetched(false);
            setLoading(false)
        }


    }

    async function fetchWorkList(workList) {
        let workListParcels = workList.parcels;
        let pickupIds = workList.pickupIds;

        setLoading(true);

        let driverShipmentData = [];

        let greens = [];

        const newPickupDatas = [];
        for (let i = 0; i < pickupIds?.length; i++) {
            let pickup = null;

            pickup = await new PickupService().getPickupById(pickupIds[i])
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })

            let greenData = {};
            greenData.barcode = pickup.barcode;
            greenData.customerBarcode = null;
            greens.push(greenData);

            let data = {};
            data.pickup = pickup;
            data.barcode = pickup.barcode;
            data.checked = true;

            //setPickupData([...pickupData, data])
            newPickupDatas.push(data);
        }

        setPickupData(newPickupDatas);

        for (let i = 0; i < workListParcels?.length; i++) {
            //let parcelService = new ParcelService();
            //let parcel = null;
            let parcel = workListParcels[i].parcel;
            //parcel = await parcelService.getParcelById(workListParcels[i].parcelId)
            //    .catch(error => {
            //        error.response.json().then(response => {
            //            setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
            //            setNotifySeverity('error');
            //            setOpenNotify(true);
            //        })
            //    })


            if (driverShipmentData.some(data => {
                return data.parcels.some(_parcel => {
                    return (_parcel.parcel.barcode.toUpperCase() === parcel.barcode.toUpperCase());
                })
            })) {
                driverShipmentData.map(data => {
                    data.parcels.map(async _parcel => {
                        if (_parcel.parcel.barcode.toUpperCase() === parcel.barcode.toUpperCase()) {

                            let greenData = {};
                            greenData.barcode = parcel.barcode;
                            greenData.customerBarcode = parcel.customerBarcode;
                            greens.push(greenData);
                            _parcel.checked = true;
                            setRefresh(!refresh);
                        }
                    })
                })
            } else {
                let shipmentService = new ShipmentService();
                let shipmentId = parcel?.shipmentId;
                if (parcel) {
                    let shipment = await shipmentService.getShipmentById(shipmentId, null)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                        .catch(error => {
                            error.response.json().then(response => {
                                setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })
                        })

                    let data = {};
                    data.shipment = shipment;
                    data.parcels = [];
                    for (let i = 0; i < parcels?.length; i++) {
                        let parcelData = {};
                        parcelData.parcel = parcels[i];
                        if (parcels[i].barcode === parcel.barcode) {
                            let greenData = {};
                            greenData.barcode = parcel.barcode;
                            greenData.customerBarcode = parcel.customerBarcode;
                            greens.push(greenData);
                            parcelData.checked = true;
                            setRefresh(!refresh);

                        }

                        data.parcels.push(parcelData);
                    }

                    driverShipmentData = ([...driverShipmentData, data]);
                }
            }


            setRefresh(!refresh);
            setTrigger(!trigger);

        }

        setShipmentData(driverShipmentData);
        setGreenBarcodes(greens);
        setFetched(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)

    }

    function handleVehicleChange(event, vehicle) {
        setVehicle(vehicle);
    }

    function handleBarcodeChange(event) {
        setBarcode(event.target.value.trim());
    }

    function handleKeyDown(event) {
        if (event.code === "Enter" && barcode !== '') {

            if (!warehouse || !driver) {
                setNotificationMessage(t("Selezionare una filiale e un'autista"));
                setNotifySeverity('error');
                setOpenNotify(true);
            } else {
                if (greenBarcodes.some(green => green.barcode?.toUpperCase() === barcode.toUpperCase() || green.customerBarcode?.toUpperCase() === barcode.toUpperCase())) {
                    setNotificationMessage(t("Collo già in carico vettura"));
                    setNotifySeverity('warning');
                    setOpenNotify(true);
                    setBarcode('');
                } else {
                    let queue = parcelQueue;
                    queue.push(barcode)
                    setParcelQueue(queue);
                    setBarcode('');

                    if (parcelQueue.length >= 1) {
                        let barcode = parcelQueue.pop();
                        fetchData(barcode);
                    }
                }
            }
        }
    }

    async function unloadParcel(parcel) {
        setLoading(true)

        let driverWorkListService = new DriverWorkListService();
        driverWorkListService.unloadParcel(driver.id, parcel.parcel.id, null)
            .then(response => {
                let greens = greenBarcodes;
                let green = greens.filter(green => green.barcode.toUpperCase() === parcel.parcel.barcode.toUpperCase())[0];
                greens.splice(greens.indexOf(green, 1));
                setGreenBarcodes(greens);
                parcel.checked = false;
                for (let i = 0; i < shipmentData.length; i++) {
                    if (!shipmentData[i].parcels.some(parcelData =>
                        parcelData.checked === true
                    )) {
                        shipmentData.splice(i, 1);
                    }
                }
                setRefresh(!refresh);
            })
            .catch(error => {

            })

        setLoading(false);
    }

    async function unloadPickup(pickup) {
        setLoading(true)

        let driverWorkListService = new DriverWorkListService();
        driverWorkListService.unloadParcel(driver.id, null, pickup.pickup.id)
            .then(response => {
                let greens = greenBarcodes;
                let green = greens.filter(green => green.barcode.toUpperCase() === pickup.barcode.toUpperCase())[0];
                greens.splice(greens.indexOf(green, 1));
                setGreenBarcodes(greens);
                pickup.checked = false;
                for (let i = 0; i < pickupData.length; i++) {
                    if (pickupData[i].checked === false) {
                        pickupData.splice(i, 1);
                    }
                }
                setRefresh(!refresh);
            })
            .catch(error => {

            })

        setLoading(false);
    }

    async function fetchData(barcode) {
        setLoading(true);
        let parcelService = new ParcelService();
        let parcel = null;
        let pickup = null;
        parcel = await parcelService.getParcelByBarcode(barcode)
            .catch(error => {
                error.response.json().then(async response => {

                    pickup = await new PickupService().getPickupByBarcode(barcode)
                        .catch(error => {
                            error.response.json().then(async response => {
                                setNotificationMessage("Si è verificato un errore nel recupero del collo: " + response.status + "-" + response.message);
                                setNotifySeverity('error');
                                setOpenNotify(true);

                                let errbarcodes = unknownBarcodes;
                                if (!errbarcodes.some(brco => brco.toUpperCase() === barcode.toUpperCase())) {
                                    errbarcodes.push(barcode);
                                }
                                setUnknownBarcodes(errbarcodes);

                                setFetched(true);
                            })
                        })

                    let barcodes = [barcode]

                    await new DriverWorkListService().loadParcel(driver.id, barcodes)
                        .then(response => {
                            response.barcodesInError.map(barcode => {
                                setNotificationMessage("Si è verificato un errore nella trasmissione del collo: " + barcode.barcode + "-" + barcode.errorMessage);
                                setNotifySeverity('error');
                                setOpenNotify(true);
                            })

                            response.parsedBarcodes.map(_barcode => {
                                if (_barcode.barcode.toUpperCase() === barcode.toUpperCase()) {
                                    let data = {};

                                    data.pickup = pickup;
                                    data.barcode = barcode;
                                    data.checked = true;

                                    setPickupData([...pickupData, data]);

                                    let greens = greenBarcodes;
                                    let greenData = {};
                                    greenData.barcode = barcode;
                                    greenData.customerBarcode = null;
                                    greens.push(greenData);
                                    setGreenBarcodes(greens);
                                    setNotificationMessage("Ritiro " + _barcode.barcode + " caricato correttamente");
                                    setNotifySeverity('success');
                                    setOpenNotify(true);
                                    setRefresh(!refresh);

                                }
                            })

                        })
                        .catch(error => {
                            // error.response.json().then(response => {
                            //     setNotificationMessage("Si è verificato un errore nel carico vettura: " + response.status + "-" + response.message);
                            //     setNotifySeverity('error');
                            //     setOpenNotify(true);
                            // })
                        })
                })
            })

        if (shipmentData.some(data => {
            return data.parcels.some(parcel => {
                return (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase());
            })
        })) {
            shipmentData.map(data => {
                data.parcels.map(async parcel => {
                    if (parcel.parcel.barcode.toUpperCase() === barcode.toUpperCase() || parcel.parcel.customerBarcode.toUpperCase() === barcode.toUpperCase()) {
                        let driverWorkListService = new DriverWorkListService();
                        let barcodes = [];
                        barcodes.push(barcode);
                        await driverWorkListService.loadParcel(driver.id, barcodes)
                            .then(response => {
                                response.barcodesInError.map(barcode => {
                                    setNotificationMessage("Si è verificato un errore nella trasmissione del collo: " + barcode.barcode + "-" + barcode.errorMessage);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })

                                response.parsedBarcodes.map(_barcode => {
                                    if (_barcode.barcode.toUpperCase() === barcode.toUpperCase()) {
                                        parcel.checked = true;
                                        let greens = greenBarcodes;
                                        let greenData = {};
                                        greenData.barcode = parcel.parcel.barcode;
                                        greenData.customerBarcode = parcel.parcel.customerBarcode;
                                        greens.push(greenData);
                                        setGreenBarcodes(greens);
                                        setNotificationMessage("Collo " + _barcode.barcode + " caricato correttamente");
                                        setNotifySeverity('success');
                                        setOpenNotify(true);
                                        setRefresh(!refresh);
                                    }
                                })

                            })
                            .catch(error => {
                                // error.response.json().then(response => {
                                //     setNotificationMessage("Si è verificato un errore nel carico vettura: " + response.status + "-" + response.message);
                                //     setNotifySeverity('error');
                                //     setOpenNotify(true);
                                // })
                            })
                    }
                })
            })
        } else {
            let shipmentService = new ShipmentService();
            let shipmentId = parcel?.shipmentId;
            if (parcel) {
                let shipment = await shipmentService.getShipmentById(shipmentId, null)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage("Si è verificato un errore nel recupero della spedizione: " + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let parcels = await shipmentService.getParcelsOfShipment(shipment?.id)
                    .catch(error => {
                        error.response.json().then(response => {
                            setNotificationMessage("Si è verificato un errore nel recupero dei colli: " + response.status + "-" + response.message);
                            setNotifySeverity('error');
                            setOpenNotify(true);
                        })
                    })

                let data = {};
                data.shipment = shipment;
                data.parcels = [];
                for (let i = 0; i < parcels?.length; i++) {
                    let parcelData = {};
                    parcelData.parcel = parcels[i];
                    if (parcels[i].barcode.toUpperCase() === barcode.toUpperCase() || parcels[i].customerBarcode.toUpperCase() === barcode.toUpperCase()) {
                        let driverWorkListService = new DriverWorkListService();
                        let barcodes = [];
                        barcodes.push(barcode);
                        await driverWorkListService.loadParcel(driver.id, barcodes)
                            .then(response => {
                                response.barcodesInError.map(barcode => {
                                    setNotificationMessage("Si è verificato un errore nella trasmissione del collo: " + barcode.barcode + "-" + barcode.errorMessage);
                                    setNotifySeverity('error');
                                    setOpenNotify(true);
                                })

                                response.parsedBarcodes.map(_barcode => {
                                    if (_barcode.barcode.toUpperCase() === barcode.toUpperCase()) {
                                        parcelData.checked = true;
                                        let greens = greenBarcodes;
                                        let greenData = {};
                                        greenData.barcode = parcel.parcel.barcode;
                                        greenData.customerBarcode = parcel.parcel.customerBarcode;
                                        greens.push(greenData);
                                        setGreenBarcodes(greens);
                                        setNotificationMessage("Collo " + _barcode.barcode + " caricato correttamente");
                                        setNotifySeverity('success');
                                        setOpenNotify(true);
                                        setRefresh(!refresh);
                                    }
                                })

                            })
                            .catch(error => {
                                // error.response.json().then(response => {
                                //     setNotificationMessage("Si è verificato un errore nel carico vettura: " + response.status + "-" + response.message);
                                //     setNotifySeverity('error');
                                //     setOpenNotify(true);
                                // })
                            })

                    } else {
                        parcelData.checked = false;
                    }

                    data.parcels.push(parcelData);
                }

                setShipmentData([...shipmentData, data]);
            }

        }

        setRefresh(!refresh);
        setTrigger(!trigger);
        setTimeout(() => {
            setLoading(false);
        }, 500)


    }

    function calcSumOfParcels(parNum) {

        shipmentData.map(shipmentData => {
            shipmentData.parcels.map(parcel => {
                if (parcel.checked) {
                    parNum += 1;
                }
            })
        })

        return parNum;

    }


    function fetchWarehouses() {
        let warehouseService = new WarehouseService();
        warehouseService.getWarehousesByFilters(0, 1000, null)
            .then(result => {
                setWarehouses(result.warehouses);
                if (result.warehouses?.length === 1) {
                    setWarehouse(result.warehouses[0]);
                    setVehicle(null);
                    setDriver(null);
                    fetchDriversAndVehicles(result.warehouses[0]);
                }
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage("Si è verificato un errore nel recupero delle filiali: " + response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            })
    }

    function fetchDriversAndVehicles(warehouse) {


        if (null !== warehouse) {
            let filters = {};
            filters.warehouseCode = warehouse.code;

            let driverService = new DriverService();
            driverService.getDriversByFilters(filters)
                .then(response => {
                    setDrivers(response.drivers)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero degli autisti: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })


            let vehicleService = new VehicleService();
            vehicleService.getVehiclesByFilters(filters)
                .then(response => {
                    setVehicles(response.vehicles)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage("Si è verificato un errore nel recupero dei mezzi: " + response.status + "-" + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                    })
                })
        }

    }

    useEffect(() => {
        fetchWarehouses();
    }, [])

    useEffect(() => {

    }, [refresh])


    useEffect(() => {
        if (parcelQueue.length > 0) {
            fetchData(parcelQueue.pop());
        }
    }, [trigger])

    return <div>
        <Paper style={{marginBottom: "5%"}}>
            <div>
                <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("truck_load")}</DialogTitle>
                <Autocomplete
                    id="warehouse-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={warehouses}
                    getOptionLabel={option => option.code + " - " + option.name}
                    value={warehouse}
                    defaultValue={warehouse}
                    noOptionsText={t("no_options")}
                    onChange={handleWarehouseChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("warehouse")}
                            variant="outlined"
                            margin="dense"
                            required
                            error={!warehouse}
                            value={warehouse}
                            defaultValue={warehouse}
                        />
                    )}
                />
                <Autocomplete
                    id="driver-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={drivers}
                    disabled={!warehouse}
                    getOptionLabel={option => option.name + " " + option.surname}
                    value={driver}
                    defaultValue={driver}
                    noOptionsText={t("no_options")}
                    onChange={handleDriverChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("driver")}
                            variant="outlined"
                            margin="dense"
                            value={driver}
                            required
                            error={!driver}
                            defaultValue={driver}
                        />
                    )}
                />
                <Autocomplete
                    id="vehicle-select"
                    size="small"
                    style={{marginBottom: "2%", width: "fit-content", minWidth: "20%", margin: "auto"}}
                    options={vehicles}
                    disabled={!warehouse}
                    getOptionLabel={option => option.model + " - " + option.plate}
                    value={vehicle}
                    defaultValue={vehicle}
                    noOptionsText={t("no_options")}
                    onChange={handleVehicleChange}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("vehicle")}
                            variant="outlined"
                            margin="dense"
                            value={vehicle}
                            defaultValue={vehicle}
                        />
                    )}
                />
                <div style={{width: "100%", marginTop: "2%", display: "flex"}}>
                    <Typography align={"center"} style={{width: "50%", margin: "auto"}}
                                variant={"caption"}>{t("truck_load_instructions")}</Typography>
                </div>
                <div style={{width: "100%", marginBottom: "3%", display: "flex"}}>
                    <TextField
                        style={{width: "20%", margin: "auto", marginBlock: "2%"}}
                        label={t("barcode")}
                        variant="outlined"
                        margin="dense"
                        value={barcode}
                        defaultValue={barcode}
                        onChange={handleBarcodeChange}
                        // onPaste={handleBarcodePaste}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
            {fetched ?
                <div>
                    {loading ?

                        <LoadingComponent/>

                        :
                        <div>

                            {shipmentData.length > 0 || unknownBarcodes.length > 0 || pickupData.length > 0 ?
                                <div>
                                    <Table style={{width: "20%", margin: "auto"}}>
                                        <TableHead>
                                            <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("shipments")}</Typography></TableCell>
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    alignSelf={"center"}
                                                    fontWeight={"bold"}>{t("parcels")}</Typography></TableCell>
                                                {pickupData.length > 0 ?
                                                    <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                        variant={"button"}
                                                        alignSelf={"center"}
                                                        fontWeight={"bold"}>{t("pickups")}</Typography></TableCell>
                                                    : null}
                                                {unknownBarcodes.length > 0 ?
                                                    <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                        variant={"button"}
                                                        alignSelf={"center"}
                                                        fontWeight={"bold"}>{t("unknown_parcels")}</Typography></TableCell>
                                                    : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableRow>
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                    fontSize: 30
                                                }}>{shipmentData.length}</Typography></TableCell>
                                            <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                variant={"button"}
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: "auto",
                                                    fontSize: 30
                                                }}>{calcSumOfParcels(0)}</Typography></TableCell>
                                            {pickupData.length > 0 ?
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                        fontSize: 30
                                                    }}>{pickupData.length}</Typography></TableCell>
                                                : null}
                                            {unknownBarcodes.length > 0 ?
                                                <TableCell style={{border: "2px solid", width: "50%"}}><Typography
                                                    variant={"button"}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        margin: "auto",
                                                        fontSize: 30
                                                    }}>{unknownBarcodes.length}</Typography></TableCell>
                                                : null}
                                        </TableRow>
                                    </Table>
                                    <div style={{paddingBottom: "5%"}}>
                                        <Table style={{width: "50%", margin: "auto", marginBlock: "5%"}}>
                                            <TableHead>
                                                <TableRow style={{backgroundColor: theme.palette.primary.main}}>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography variant={"button"} style={{
                                                            fontWeight: 'bold',
                                                            margin: "auto",
                                                        }}>{t("shipment_details")}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography variant={"button"} style={{
                                                            fontWeight: 'bold',
                                                            margin: "auto",
                                                        }}> {t("parcels")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {shipmentData.map(data => {
                                                return <TableRow>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("shipmentId")} : {data.shipment.id}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("reference")} : {data.shipment.reference}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("contract_code")} : {data.shipment.contract.code}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("destination")} : {data.shipment.deliveryAddress.postalCode} {data.shipment.deliveryAddress.city} {data.shipment.deliveryAddress.administrative_level_3}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        {data.parcels.map(parcel => {
                                                            return <div style={{display: "flex", alignItems: "center"}}>
                                                                <Typography
                                                                    color={parcel.checked ? "green" : "darkgrey"}>{parcel.parcel.barcode + (parcel.parcel.customerBarcode ? " - " + parcel.parcel.customerBarcode : null)}</Typography>
                                                                <IconButton
                                                                    style={{display: parcel.checked ? null : "none"}}
                                                                    color={'error'}
                                                                    size={"small"}
                                                                    onClick={() => unloadParcel(parcel)}><CancelRounded/></IconButton>
                                                            </div>
                                                        })}
                                                    </TableCell>

                                                </TableRow>
                                            })}
                                            {pickupData.map(data => {
                                                return <TableRow>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("pickupId")} : {data.pickup.id}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("warehouseCode")} : {data.pickup.warehouseCode}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("contract_code")} : {data.pickup.contractCode}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("pickup_date")} : {new Date(data.pickup.date).toLocaleDateString()}</Typography>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("pickup_address")} : {data.pickup.address?.postalCode} {data.pickup.address?.city} {data.pickup.address?.administrative_level_3}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            <Typography
                                                                color={data.checked ? "green" : "darkgrey"}>{data.barcode}</Typography>
                                                            <IconButton
                                                                style={{display: data.checked ? null : "none"}}
                                                                color={'error'}
                                                                size={"small"}
                                                                onClick={() => unloadPickup(data)}><CancelRounded/></IconButton>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            })}
                                            {unknownBarcodes.length > 0 ?
                                                <TableRow>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        <Typography
                                                            variant={"subtitle2"}>{t("unknown_parcels")}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{border: "2px solid", width: "50%"}}>
                                                        {unknownBarcodes.map(barcode => {
                                                            return <div style={{display: "flex", alignItems: "center"}}>
                                                                <Typography
                                                                    color={"darkgrey"}>{barcode}</Typography>
                                                            </div>
                                                        })}
                                                    </TableCell>

                                                </TableRow>
                                                : null}
                                        </Table>
                                        {/*<Table>*/}
                                        {/*    <TableRow>*/}
                                        {/*        <TableCell style={{border: "2px solid", width: "50%"}}>*/}
                                        {/*            <Typography*/}
                                        {/*                variant={"subtitle2"}>{t("unknown_parcels")}</Typography>*/}
                                        {/*        </TableCell>*/}
                                        {/*        <TableCell style={{border: "2px solid", width: "50%"}}>*/}
                                        {/*            {unknownBarcodes.map(barcode => {*/}
                                        {/*                return <div style={{display: "flex", alignItems: "center"}}>*/}
                                        {/*                    <Typography*/}
                                        {/*                        color={"darkgrey"}>{barcode}</Typography>*/}
                                        {/*                </div>*/}
                                        {/*            })}*/}
                                        {/*        </TableCell>*/}

                                        {/*    </TableRow>*/}
                                        {/*</Table>*/}
                                    </div>
                                </div>
                                :
                                <InfoChip>{t("no_parcels")}</InfoChip>}

                        </div>
                    }
                </div>
                :
                null
            }
            <Notification open={openNotify} severity={notifySeverity} duration={100000}
                          onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        </Paper>
        <Card style={{marginTop: "5%", width: "30%", margin: "auto"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow style={{backgroundColor: theme.palette.primary.main, width: "100%"}}>
                        <div style={{width: "fit-content", margin: "auto"}}>
                            <Typography variant={"button"} align={"center"} style={{
                                fontWeight: "bold",
                                width: "fit-content",
                                margin: "auto",
                                color: "white"
                            }}>{t("parcel_queue")}</Typography>
                        </div>
                    </TableRow>
                </TableHead>
                {parcelQueue.map(item => {
                    return <TableRow style={{width: "100%"}}>
                        <Typography style={{width: "fit-content", margin: "auto"}}>{item}</Typography>
                    </TableRow>
                })}
            </Table>

        </Card>
    </div>


}

export default TruckLoadPage;