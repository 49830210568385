import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import RoutingRuleService from "./RoutingRuleService";


function RoutingRule(props) {

    let area = props.area;

    const {t} = useTranslation();

    // const cacheableServiceService = new CacheableAsyncRequest(`servicesCache`, function (key) {
    //     let serviceService = new ServiceService();
    //     return serviceService.getById(key);
    // });
    // const cacheableDistrictService = new CacheableAsyncRequest(`districtsCache`, function (key) {
    //     let districtService = new DistrictService();
    //     return districtService.getById(key);
    // });
    // const cacheableCountryService = new CacheableAsyncRequest(`countryCache`, function (key) {
    //     let countryService = new CountryService();
    //     return countryService.getCountryById(key);
    // });
    // const cacheableAdmin1Service = new CacheableAsyncRequest(`admin1Cache`, function (key) {
    //     let administrativeLevel1Service = new AdministrativeLevel1Service();
    //     return administrativeLevel1Service.getAdminLevel1ByID(key);
    // });
    // const cacheableAdmin2Service = new CacheableAsyncRequest(`admin2Cache`, function (key) {
    //     let administrativeLevel2Service = new AdministrativeLevel2Service();
    //     return administrativeLevel2Service.getAdminLevel2ByID(key);
    // });
    // const cacheableAdmin3Service = new CacheableAsyncRequest(`admin3Cache`, function (key) {
    //     let administrativeLevel3Service = new AdministrativeLevel3Service();
    //     return administrativeLevel3Service.getAdminLevel3ByID(key);
    // });
    // const cacheableCityService = new CacheableAsyncRequest(`cityCache`, function (key) {
    //     let cityService = new CityService();
    //     return cityService.getCityById(key);
    // });
    // const cacheablePostalCodeService = new CacheableAsyncRequest(`postalCodeCache`, function (key) {
    //     let postalCodeService = new PostalCodeService();
    //     return postalCodeService.getById(key);
    // });

    const [anchorEl, setAnchorEl] = useState(null);
    const [district, setDistrict] = useState(null);
    const [zone, setZone] = useState(null);
    const [service, setService] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [country, setCountry] = useState(null);
    const [admin1, setAdmin1] = useState(null);
    const [admin2, setAdmin2] = useState(null);
    const [admin3, setAdmin3] = useState(null);
    const [city, setCity] = useState(null);

    const location = useLocation();


    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '75%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function editArea() {

    }

    // function fetchData() {
    //     if (null != area.districtId) {
    //         cacheableDistrictService.request(area.districtId)
    //             .then(response => {
    //                 setDistrict(response.code)
    //             })
    //
    //     }
    //     if (null != area.serviceId) {
    //         cacheableServiceService.request(area.serviceId)
    //             .then((response) => {
    //                 setService(response.name)
    //             })
    //     }
    //     if (null != area.postalCodeId) {
    //         cacheablePostalCodeService.request(area.postalCodeId)
    //             .then(data => {
    //                 setPostalCode(data.postalCode)
    //             })
    //     }
    //     if (null != area.countryId) {
    //         cacheableCountryService.request(area.countryId)
    //             .then(data => {
    //                 setCountry(data.iso3166Alpha3)
    //             })
    //     }
    //     if (null != area.administrativeLevel1Id) {
    //         cacheableAdmin1Service.request(area.administrativeLevel1Id)
    //             .then(data => {
    //                 setAdmin1(data.name)
    //             })
    //     }
    //     if (null != area.administrativeLevel2Id) {
    //      cacheableAdmin2Service.request(area.administrativeLevel2Id)
    //             .then(data => {
    //                 setAdmin2(data.name)
    //             })
    //     }
    //     if (null != area.administrativeLevel3Id) {
    //       cacheableAdmin3Service.request(area.administrativeLevel3Id)
    //             .then(data => {
    //                 setAdmin3(data.code)
    //             })
    //     }
    //     if (null != area.cityId) {
    //         cacheableCityService.request(area.cityId)
    //             .then(data => {
    //                 setCity(data.name)
    //             })
    //     }
    // }

    function deleteArea(areaId) {
        let areaService = new RoutingRuleService();
        areaService.deleteRoutingRule(areaId)
            .then(() => {
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json()
                    .then((response) => {
                    });
            })
            .finally(() => {
                setAnchorEl(null);
            })
    }

    useEffect(() => {
        // fetchData();
    }, [location])

    return <TableRow>
        <TableCell>
            {area.id}
        </TableCell>
        <TableCell>
            {area.district || area.zone || (area.timingContractCode && (area.timingContractCode + " - " + area.timingType + " - " + area.timing + "h")) || t("no_district_assigned")}
        </TableCell>
        <TableCell>
            {area.warehouseCode || t("no_district_assigned")}
        </TableCell>
        <TableCell>
            {area.contract || `*`}
        </TableCell>
        <TableCell>
            {area.service || `*`}
        </TableCell>
        <TableCell>
            {area.country || `*`}
        </TableCell>
        <TableCell>
            {area.administrativeLevel1 || `*`}
        </TableCell>
        <TableCell>
            {area.administrativeLevel2 || `*`}
        </TableCell>
        <TableCell>
            {area.administrativeLevel3 || `*`}
        </TableCell>
        <TableCell>
            {area.city || `*`}
        </TableCell>
        <TableCell>
            {area.postalCode || `*`}
        </TableCell>
        <TableCell>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={() => editArea(area.id)}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>*/}

            <MenuItem onClick={() => deleteArea(area.id)}><DeleteIcon className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>
    </TableRow>

}

export default RoutingRule;