import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {Button, CardActions, DialogTitle, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import Notification from "../../components/notifications/Notification";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'block',
            width: '100%',
            textAlign: 'center',
            justifyItems: 'center'
        },
        field: {
            width: "20%",
            margin: "auto",
            marginTop: "2%",
            marginBottom: "2%",
            display: 'flex',
            justifyItems: "center"

        },
        button_container: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
        },
        cancel_button: {
            marginInline: "4%",

            width: "15%",
            background: 'rgba(186,186,186,0.84)',

            color: "#000000",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                backgroundColor: "#d5d5d5",
            },
        },
        confirm_button: {
            marginInline: "4%",

            width: "15%",
            backgroundColor: "green",
            color: "white",
            transition: "all 0.2s",
            // border: "2px solid green",

            "&:hover": {
                background: 'rgba(24,147,0,0.84)',
                // color: "black",
                // border:  "2px solid green",

                "& $confirm_icon": {
                    // color: "green"
                }
            },
        },
        confirm_icon: {
            color: "white",
            transition: "all 0.3s",
        }
    }
));


function LedgerAccountForm(props) {

    let {t} = useTranslation();

    const [code, setCode] = useState('');
    const [name, setName] = useState('');

    const [notificationMessage, setNotificationMessage] = useState(null);
    const history = useHistory();

    const classes = useStyles();

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');


    useEffect(() => {

    }, [])


    function handleSubmit() {

    }

    function handleClose() {
        history.push('/ledger-accounts');
    }
    function closeNotification(){
        setOpenNotify(false);
    }


    return <Container>
        <form onSubmit={handleSubmit}>

            <DialogTitle> {t("ledger_account")}</DialogTitle>
            <TextField
                label={t("code")}
                variant="outlined"
                margin="dense"
                onChange={event => setCode(event.target.value.toUpperCase())}
                value={code}
                className={classes.field}

            />


            <TextField
                label={t("name")}
                variant="outlined"
                margin={"dense"}
                onChange={event => setName(event.target.value)}
                value={name}
                className={classes.field}

            />
            <CardActions className={classes.button_container}>
                <Button
                    className={classes.cancel_button}
                    endIcon={<CancelOutlined/>}
                    onClick={handleClose}
                    variant={"contained"}>
                    {t("cancel")}
                </Button>
                <Button
                    className={classes.confirm_button}
                    endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                    onClick={handleSubmit}
                    color="primary"
                    variant={"contained"}>
                    {t("confirm")}
                </Button>
            </CardActions>
        </form>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
    </Container>
}

export default LedgerAccountForm;