import {Checkbox, CircularProgress, DialogTitle, FormControlLabel, Modal, TableBody, Typography} from "@mui/material";
import {Backdrop, Card, Fade, MenuItem, Select, TablePagination, TextField, useTheme} from "@material-ui/core";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {
    CancelOutlined,
    CheckCircle,
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    SearchOutlined
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import ChipInput from "material-ui-chip-input";
import theme from "../../theme/theme";
import Notification from "../../components/notifications/Notification";
import InfoChip from "../../components/chips/InfoChip";
import ShipmentService from "../../shipments/ShipmentService";
import WarehouseService from "../../warehouses/WarehouseService";
import {Autocomplete} from "@material-ui/lab";
import {formatAddressFirstLine, formatAddressSecondLine, formatDateTime} from "../../utils/utils";
import CheckChip from "../../components/chips/CheckChip";
import StyledButton from "../../components/buttons/StyledButton";
import {HourglassBottom} from "@mui/icons-material";

const {useEffect, useState} = require("react");
const {Paper, Table, TableHead, TableCell} = require("@material-ui/core");
const {TableRow} = require("@mui/material");
const {useTranslation} = require("react-i18next");
const {useHistory} = require("react-router-dom");
const {AddressChangeRequestService} = require("./AddressChangeRequestService");

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function AddressChangeRequestTable(props) {

    const {t} = useTranslation();
    const history = useHistory;

    const [openPdf, setOpenPdf] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [refreshInput, setRefreshInput] = useState(false);
    const [shipments, setShipments] = useState([]);
    const [barcodes, setBarcodes] = useState([]);

    const [warehouse, setWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [requestCount, setRequestCount] = useState(0);

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [showCompleted, setShowCompleted] = useState(false);

    const [labelContent, setLabelContent] = useState('');
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [labelError, setLabelError] = useState(false);

    const [openCancelRequest, setOpenCancelRequest] = useState(false);

    const [allChecked, setAllChecked] = useState(false);

    const [pages, setPages] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [requests, setRequests] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    async function fetchWarehouses() {
        let wares = await new WarehouseService().getWarehousesByFilters();
        setWarehouses(wares.warehouses)
    }

    function handleWarehouseChange(event, warehouse) {
        setWarehouse(warehouse)
    }


    function handleChangePage(event) {
        setPage(event.target.value);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function TablePaginationActions(props) {
        const classes = usePaginationStyles();
        const theme = useTheme();
        const {page} = props;

        const handleFirstPageButtonClick = (event) => {
            setPage(0)
        };

        const handleBackButtonClick = (event) => {
            setPage(page - 1)
        };

        const handleNextButtonClick = (event) => {
            setPage(page + 1)
        };

        const handleLastPageButtonClick = (event) => {
            setPage(pages.length - 1);
        };

        return (
            <div className={classes.root}>
                <Select style={{minWidth: 50}}
                        labelId="page-select"
                        value={page}
                        onChange={handleChangePage}
                        options={pages}
                        defaultValue={page}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {paper: classes.menuPaper}
                        }}
                >
                    {pages.map((pg, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={pg}
                                >
                                    {pg + 1}
                                </MenuItem>
                            )
                        }
                    )}
                </Select>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0 || fetching}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPage/> : <FirstPage/>}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0 || fetching}
                            aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={fetching || page >= pages.length - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPage/> : <LastPage/>}
                </IconButton>
            </div>
        );
    }

    function handleChecked(event, key) {

        requests[key].checked = event.target.checked;

        if (requests.some(req => req.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefreshInput(!refreshInput);

    }

    function handleAllChecked(event) {

        setAllChecked(event.target.checked);

        for (let i = 0; i < requests.length; i++) {
            if(requests[i].completionDateTime === null && requests[i].pudo){
                requests[i].checked = !!event.target.checked;
            }
            // requests[i].checked = !!event.target.checked;
        }

        setRefreshInput(!refreshInput);

    }

    async function fetchLabels(shipmentIds) {

        setLoadingLabel(true);

        let filters = {};
        filters.idList = shipmentIds;


        await new ShipmentService().getShipmentLabelsByFilters(filters, "PDF")
            .then(result => {
                setLabelContent(result[0].content)
                setLoadingLabel(false)
                setLabelError(false);
                setOpenPdf(true);
            })
            .catch(error => {
                error.response.json().then(response => {
                    if (response.status === 425) {
                        setTimeout(() => {
                            fetchLabels(shipmentIds);
                        }, 5000)
                    } else {
                        setLoadingLabel(false);
                        setLabelError(true);
                    }
                })
            })

    }

    async function fetchRequests() {

        setFetching(true);

        let filters = {}

        filters.pageSize = rowsPerPage;
        filters.pageNumber = page;

        if (shipments.length > 0) {
            filters.shipments = shipments;
        }

        if (barcodes.length > 0) {
            filters.barcodes = barcodes;
        }

        if (!showCompleted) {
            filters.completed = false;
        }

        if (warehouse) {
            filters.warehouseId = warehouse?.id;
        }


        await new AddressChangeRequestService().getRequestsByFilters(filters)
            .then(response => {


                let req = [];
                for (let i = 0; i < response.requests.length; i++) {
                    let data = {};

                    data = response.requests[i];
                    data.checked = false;

                    req.push(data);
                }
                setRequests(req)
                setPages(Array.from(Array(Math.ceil(response.count / rowsPerPage)).keys()));
                setRequestCount(response.count);
            })
            .catch(error => {

            })

        setFetching(false);
        setFetched(true);

    }

    function addBarcode(barcode) {

        let codes = barcode.split(" ");

        for (let i = 0; i < codes.length; i++) {
            if (barcodes[i] !== "") {
                barcodes.push(codes[i])
            }
        }
    }

    function removeBarcode(barcode, index) {
        barcodes.splice(index, 1);
        setRefreshInput(!refreshInput);
    }

    function addShipmentId(id) {

        const re = /^[0-9\b]+$/;

        let ids = id.split(" ");

        for (let i = 0; i < ids.length; i++) {
            if (re.test(ids[i]) && ids[i] !== "") {
                shipments.push(ids[i])
            }
        }


    }

    function removeShipmentId(id, index) {
        shipments.splice(index, 1);
        setRefreshInput(!refreshInput)
    }

    function handleEnter(event) {

        if (event.code === "Enter") {
            fetchRequests();
        }

    }

    async function massiveConfirmRequests() {

        let input = [];

        for (let i = 0; i < requests.length; i++) {
            if (requests[i].checked) {
                input.push(requests[i].shipmentId)
            }
        }

        await confirmRequest(input);

    }

    async function openConfirmCancel() {
        setOpenCancelRequest(true);
    }

    async function cancelRequest(requestId) {

        await new AddressChangeRequestService().cancelRequest(requestId)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setRefresh(!refresh);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                });
            })

        setOpenCancelRequest(false);

    }


    async function confirmRequest(shipmentIds) {

        let input = {};
        input.shipmentIds = shipmentIds;

        await new ShipmentService().confirmDeliveryToPudo(input)
            .then(result => {
                setOpenNotify(true);
                setNotifySeverity('success');
                setNotificationMessage(t("successful"));
                setRefresh(!refresh);

                fetchLabels(shipmentIds);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setOpenNotify(true);
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                });
            });

    }

    function closeNotification() {
        setOpenNotify(false);
    }


    useEffect(() => {
        fetchRequests();
    }, [refresh, page, rowsPerPage])

    useEffect(() => {

    }, [refreshInput])


    return <Paper style={{
        width: '100%',
        textAlign: 'center',
        marginBottom: "15%"
    }}>
        <DialogTitle style={{margin: "auto", textAlign: "center"}}>
            <Typography variant={"button"}>{t("address_change_requests")}</Typography>
        </DialogTitle>
        <Card onKeyPress={handleEnter}
              style={{width: "95%", margin: "auto", marginBlock: "2%"}}>
            <DialogTitle style={{margin: "auto", textAlign: "center"}}>
                <Typography variant={"button"}>{t("filters")}</Typography>
            </DialogTitle>
            <div style={{display: "flex"}}>
                <ChipInput
                    style={{
                        margin: "auto",
                        marginBlock: "2%",
                        width: "30%"
                    }}
                    key={"shipmentId"}
                    label={t("shipmentId")}
                    value={shipments}
                    onAdd={(cID) => addShipmentId(cID)}
                    onDelete={((cID, index) => removeShipmentId(cID, index))}
                    variant={"outlined"}
                    margin={"dense"}
                    size={"small"}
                    blurBehavior="clear"
                    newChipKeys={KeyboardEvent.ENTER}
                    type={'number'}
                />
                <ChipInput
                    style={{
                        margin: "auto",
                        marginBlock: "2%",
                        width: "30%"
                    }}
                    key={"barcodes"}
                    label={t("barcode")}
                    value={barcodes}
                    onAdd={(cBarcode) => addBarcode(cBarcode)}
                    onDelete={((cBarcode, index) => removeBarcode(cBarcode, index))}
                    variant={"outlined"}
                    margin={"dense"}
                    size={"small"}
                    blurBehavior="clear"
                    newChipKeys={KeyboardEvent.ENTER}
                />
            </div>
            <Autocomplete
                id="warehouse"
                options={warehouses}
                size={"small"}
                style={{
                    margin: "auto",
                    marginBottom: "2%",
                    width: "30%"
                }}
                getOptionLabel={option => option.code + " - " + option.name}
                value={warehouse}
                defaultValue={warehouse}
                noOptionsText={t("no_options_available")}
                onOpen={fetchWarehouses}
                onChange={handleWarehouseChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("delivery_warehouse")}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        placeholder={t("any")}
                        margin="dense"
                        size={"small"}
                        value={warehouse}
                        defaultValue={warehouse}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <FormControlLabel
                style={{marginTop: "5%"}}
                label={<Typography fontSize={12}
                                   variant={"button"}>{t("show_completed_requests_too")}</Typography>}
                control={
                    <Checkbox
                        style={{color: theme.palette.primary.main}}
                        checked={showCompleted}
                        onChange={event => setShowCompleted(event.target.checked)}
                    />
                }
                labelPlacement={"right"}
            />
            <div style={{margin: "auto"}}>
                <IconButton onClick={() => setRefresh(!refresh)}
                            disabled={fetching}
                            style={{
                                backgroundColor: 'transparent',
                                margin: "auto",
                                marginBottom: "2%"
                            }}>{fetching ?
                    <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                    <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>
            </div>
        </Card>


        {fetching ?

            <LoadingComponent/>

            :

            <div>
                {requestCount > 0 ?
                    <InfoChip>{t("shipment_count") + requestCount}</InfoChip>
                    :
                    null
                }
                {fetched && requests.length === 0 ?

                    <InfoChip>{t("no_data")}</InfoChip>
                    :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        style={{color: theme.palette.primary.main}}
                                        onChange={handleAllChecked}
                                        checked={allChecked}/>
                                </TableCell>
                                <TableCell>
                                    {t("request_id")}
                                </TableCell>
                                <TableCell>
                                    {t("shipment_id")}
                                </TableCell>
                                <TableCell>
                                    {t("delivery_address")}
                                </TableCell>
                                <TableCell>
                                    {t("delivery_warehouse")}
                                </TableCell>
                                <TableCell>
                                    {t("pudo")}
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("user")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("dateTime")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("completion_user")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("completion_dateTime")}</Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requests.map((request, key) => {
                                return <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            disabled={request.completionDateTime !== null || !request.pudo}
                                            style={{color: theme.palette.primary.main}}
                                            onChange={event => handleChecked(event, key)}
                                            checked={request.checked}/>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{request.id}</Typography>
                                        <Typography>{request.pudoType}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{request.shipmentId}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{formatAddressFirstLine(request.shipmentDeliveryAddress)}</Typography>
                                        <Typography>{formatAddressSecondLine(request.shipmentDeliveryAddress)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{request.deliveryWarehouseCode}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {request.pudo?
                                        <>
                                            <Typography>{request.pudo?.code}</Typography>
                                            <Typography>{formatAddressFirstLine(request.pudo?.address)}</Typography>
                                            <Typography>{formatAddressSecondLine(request.pudo?.address)}</Typography>
                                        </>
                                            :
                                            <HourglassBottom/>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontStyle={"italic"}>{request.username}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{formatDateTime(request.dateTime)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontStyle={"italic"}>{request.completionUsername}</Typography>
                                        <Typography
                                            variant={"subtitle2"}>{formatDateTime(request.completionDateTime)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {request.completionDateTime !== null ?
                                            <CheckChip>{t("request_completed")}</CheckChip>
                                            :
                                            <StyledButton
                                                disabled={!request.pudo}
                                                icon={<CheckCircle/>}
                                                onClick={() => confirmRequest([request.shipmentId])}>{t("execute")}</StyledButton>
                                        }
                                        {request.completionDateTime !== null ?
                                            null
                                            :
                                            <IconButton onClick={()=>cancelRequest(request.id)}><CancelOutlined/></IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={-1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: false,
                            }}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={() => {
                                return t("page")
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Table>
                }
                {requests.length > 0 ?
                    <StyledButton
                        disabled={!requests.some(request => request.checked)}
                        icon={<CheckCircle/>}
                        onClick={massiveConfirmRequests}>{t("execute_selected")}</StyledButton>
                    :
                    null
                }
            </div>

        }

        <Modal open={openPdf}
               onClose={() => setOpenPdf(false)}
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={openPdf}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "fit-content",
                    marginInline: "auto",
                    marginTop: "5%",
                    height: "fit-content",
                    minHeight: "80%",
                    minWidth: "80%",
                    display: "flex"
                }}>
                    <div style={{margin: "auto", marginTop: "5%"}}>
                        <object style={{margin: "auto"}} width={800} height={800} type={'application/pdf'}
                                data={"data:application/pdf;base64," + labelContent}>";
                            html += "
                        </object>
                    </div>
                </Paper>
            </Fade>
        </Modal>


        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{t(notificationMessage)}</Notification>

    </Paper>


}

export default AddressChangeRequestTable;