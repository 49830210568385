import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import DistrictService from "./DistrictService";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";

function District(props) {

    let district = props.district;
    const {t} = useTranslation();

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '75%',
            margin: "auto"

        },
        cell: {
            margin: "auto"

        }
    });

    const classes = useStyles();

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function editDistrict() {
        setAnchorEl(null);
        history.push(`/districts/${district.id}/edit/`);
    }

    function deleteDistrict() {
        let districtService = new DistrictService();
        districtService.deleteDistrict(district.id)
            .then(() => {
                props.deleteCallback();
            })

    }

    useEffect(() => {

    }, [])


    return <TableRow>
        <TableCell className={classes.cell}>
            {district.id}
        </TableCell>
        <TableCell>
            {district.code}
        </TableCell>
        <TableCell>
            {district.maximumCapacityPerSlot || t("not_specified")}
        </TableCell>
        <TableCell>
            {district.routingRules.map(routingRule => {
                return <div>
                    <Typography variant={"caption"}>{routingRule.name}</Typography>
                </div>
                // return <div>
                //     <Typography variant={"caption"}>{routingRule.service}</Typography>
                //     <Typography variant={"caption"}>{" - "}</Typography>
                //     <Typography variant={"caption"}>{routingRule.country}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel1 ? ", " + routingRule.administrativeLevel1 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel2 ? ", " + routingRule.administrativeLevel2 : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.administrativeLevel3 ? ", " + routingRule.administrativeLevel3 : ""}</Typography>
                //     <Typography variant={"caption"}>{routingRule.city ? ", " + routingRule.city : ""}</Typography>
                //     <Typography
                //         variant={"caption"}>{routingRule.postalCode ? ", " + routingRule.postalCode : ""}</Typography>
                // </div>
            })}
        </TableCell>
        <TableCell>
            {district.slots.map(slot => {
                return <Typography display={"block"}
                                   variant={"caption"}>{slot.fromTime + "-" + slot.toTime + " - [ " + slot.weekDays.map(day => {
                    return " " + t(`${day.toLowerCase()}`) + " "
                }) + " ] "}</Typography>
            })}
        </TableCell>
        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>

        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={editDistrict}><EditIcon className={classes.icon}/>{t("edit")}</MenuItem>

            <MenuItem onClick={() => setConfirmDeleteDialogOpen(true)}><DeleteIcon
                className={classes.icon}/>{t("delete")}
            </MenuItem>
        </Menu>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteDistrict}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>


    </TableRow>
}

export default District;