import {TableCell, TableRow, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import BankTransactionService from "./BankTransactionService";
import {IconButton} from "@mui/material";
import {Delete} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import React, {useState} from "react";


function BankTransaction(props) {


    let transaction = props.bankTransaction;

    const history = useHistory();
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);


    function deleteTransaction() {

        new BankTransactionService().deleteTransaction(transaction.id)
            .then(result => {
                setConfirmDeleteDialogOpen(false);
                props.deleteCallback();
            })
            .catch(error => {

            })

    }


    async function goToEditTransactionPage() {
        history.push(`/bank-transactions/${transaction.id}/edit`)
    }

    const {t} = useTranslation();


    return <TableRow>

        <TableCell>
            <Typography style={{fontSize: 14}}>{transaction.id}</Typography>
            <Typography variant={"inherit"}>{transaction.paymentCardNumber}</Typography>
        </TableCell>
        <TableCell>
            {new Date(transaction.createdAt).toLocaleDateString() + " - " + new Date(transaction.createdAt).toLocaleTimeString()}
        </TableCell>
        <TableCell>
            {transaction.amount.toFixed(2)}
        </TableCell>

        <TableCell>
            {transaction.splits?.map(split => {
                return <TableRow>{split.paymentSlipId ? t("payment_slip") + " N." + split.paymentSlipId

                    :

                    <>
                        {split.paymentToCustomerId ? t("payment_to_customer") + " N." + split.paymentToCustomerId : split.generalTransactionDescription}
                    </>
                }


                </TableRow>
            })}
        </TableCell>
        <TableCell>
            {transaction.splits?.map(split => {
                return <TableRow>{split.amount.toFixed(2)}</TableRow>
            })}
        </TableCell>
        <TableCell>
            {transaction.splits?.map(split => {
                return <TableRow>{split.warehouseCode ? split.warehouseCode : "-"}</TableRow>
            })}
        </TableCell>
        <TableCell>
            <IconButton onClick={() => setConfirmDeleteDialogOpen(true)}>{
                <Delete/>}</IconButton>
            <IconButton onClick={goToEditTransactionPage}>{<EditIcon/>}</IconButton>
        </TableCell>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteTransaction}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>

    </TableRow>


}

export default BankTransaction;