import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import {Download} from "@mui/icons-material";
import DriverWorkListService from "./DriverWorkListService";
import moment from "moment";
import Notification from "../components/notifications/Notification";
import {Typography} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";

function DriverWorkList(props) {
    let driverWorkList = props.driverWorkList;

    const [driver, setDriver] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const useStyles = makeStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            justifyContent: "center",
            display: "block",

        },
        container: {
            width: '100%',
            maxHeight: 840,

        },
        table: {
            width: '50%',
            margin: "auto"

        },
        cell: {
            paddingLeft: "3%"

        },
    });

    const classes = useStyles();

    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function closeNotification() {
        setOpenNotify(false);
    }


    async function downloadCsv() {

        await new DriverWorkListService().getCsvDetails(driverWorkList.id)
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:text/csv;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });


    }


    function downloadPdf(driverWorkListId) {
        const driverWorkListService = new DriverWorkListService();
        driverWorkListService.getDriverWorkListDocument(driverWorkListId)
            .then(data => {
                let tempLink = document.createElement('a');
                tempLink.href = "data:application/pdf;base64," + data.content;
                tempLink.setAttribute('download', data.fileName);
                tempLink.click();
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    function deleteDriverWorkList(driverWorkListId) {
        const driverWorkListService = new DriverWorkListService();
        driverWorkListService.deleteDriverWorkList(driverWorkListId)
            .then(() => {
                props.deleteCallback();
            })
            .catch(error => {
                error.response.json().then((response) => {
                    setNotifySeverity('error');
                    setNotificationMessage(response.message);
                    setOpenNotify(true);
                });
            });
    }

    useEffect(() => {

    }, [])

    return <TableRow>
        <TableCell>{driverWorkList.id}</TableCell>
        <TableCell><Typography style={{
            color: driverWorkList.confirmed ? "limegreen" : "slategrey",
            fontWeight: "bold"
        }}>{driverWorkList.delayed ? "D" : ""}</Typography></TableCell>
        <TableCell>{moment(driverWorkList.dateTime).format('DD/MM/yyyy HH:mm')}</TableCell>
        <TableCell>{driverWorkList.driver}</TableCell>
        <TableCell>{driverWorkList.warehouseCode}</TableCell>
        <TableCell>{driverWorkList.open ? t("open") : t("closed")}</TableCell>
        <TableCell className={classes.cell}>
            <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon/>
            </Button>
        </TableCell>
        <Menu
            id="role-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => downloadPdf(driverWorkList.id)}><Download/>{t("download_pdf")}</MenuItem>
            <MenuItem onClick={downloadCsv}><Download/>{t("download_csv")}</MenuItem>
            <MenuItem onClick={() => setOpenDeleteConfirm(true)}><DeleteIcon/>{t("delete")}</MenuItem>
        </Menu>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>
        <ConfirmDialog open={openDeleteConfirm}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={() => deleteDriverWorkList(driverWorkList.id)}
                       onCancel={() => setOpenDeleteConfirm(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
    </TableRow>
}

export default DriverWorkList;