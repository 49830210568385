import {
    Button,
    Card,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Notification from "../components/notifications/Notification";
import AccountService from "./AccountService";

import CountryService from "../geo/countries/CountryService";
import {Autocomplete} from "@material-ui/lab";
import {LoadingComponent} from "../components/loading/LoadingComponent";
import AdministrativeLevel3Service from "../geo/administrative_level_3/AdministrativeLevel3Service";
import CityService from "../geo/cities/CityService";
import PostalCodeService from "../geo/postal_codes/PostalCodeService";
import {AddCircle, CancelOutlined, CheckCircleOutlined} from "@material-ui/icons";
import {DialogTitle, IconButton, Typography} from "@mui/material";
import {GridAddIcon} from "@material-ui/data-grid";
import AccountAddressValues from "./AccountAddressValues";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: "100%",
    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "3%",
        marginTop: "3%",
        width: '60%',
    },
    option: {
        backgroundColor: 'white',
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    address_container: {
        width: 600,
        display: "block"
    },
    address_field: {
        width: "60%",
        margin: "auto",
        marginTop: "3%"
    },
    column: {
        width: "25%"
    },

    button_container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        marginBlock: "4%",
        paddingBottom: "2%"
    },
    cancel_button: {
        marginInline: "4%",

        width: "25%",
        background: 'rgba(186,186,186,0.84)',

        color: "#000000",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    create_button: {
        margin: "4%",
        width: "25%",
        // backgroundColor: "whitesmoke"
    },
    confirm_button: {
        marginInline: "4%",

        width: "25%",
        backgroundColor: "green",
        color: "white",
        transition: "all 0.2s",
        // border: "2px solid green",

        "&:hover": {
            background: 'rgba(24,147,0,0.84)',
            // color: "black",
            // border:  "2px solid green",

            "& $confirm_icon": {
                // color: "green"
            }
        },
    },
    confirm_icon: {
        color: "white",
        transition: "all 0.3s",
    }
});


function AccountForm(props) {
    const [companyName, setCompanyName] = useState('');
    const [accountCorporateCode, setAccountCorporateCode] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [fiscalCode, setFiscalCode] = useState('');
    const [isSupplier, setSupplier] = useState(false);
    const [isCustomer, setCustomer] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [addingAddress, setAddingAddress] = useState(false);

    const [type, setType] = useState('');
    let types = ["REGISTERED_OFFICE", "OPERATING_CENTER", "WAREHOUSE_RETURN"];
    const [isDefault, setDefault] = useState(false);

    const [addresses, setAddresses] = useState([]);

    const [countries, setCountries] = useState([]);
    const [admin1List, setAdmin1List] = useState([]);
    const [admin2List, setAdmin2List] = useState([]);
    const [admin3List, setAdmin3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [hamlets, setHamlets] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [country, setCountry] = useState(null);
    const [adminL3, setAdminL3] = useState(null);
    const [freeAdministrativeLevel1, setFreeAdministrativeLevel1] = useState('');
    const [freeAdministrativeLevel2, setFreeAdministrativeLevel2] = useState('');
    const [freeAdministrativeLevel3, setFreeAdministrativeLevel3] = useState('');
    const [freeCity, setFreeCity] = useState('');
    const [freePostalCode, setFreePostalCode] = useState('');
    const [city, setCity] = useState(null);
    const [addressCompanyName, setAddressCompanyName] = useState('');
    const [postalCode, setPostalCode] = useState(null);
    const [street, setStreet] = useState('');
    const [buildingNr, setBuildingNr] = useState('');
    const [hamlet, setHamlet] = useState(null);

    const [adminBlock, setAdminBlock] = useState(false);
    const [creditBlock, setCreditBlock] = useState(false);

    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    let permissions = localStorage.getItem("permissions");

    const {id} = useParams();


    function fetchCountries() {
        let countryService = new CountryService();
        countryService.getCountriesByFilters(null, null, null, null).then(data => {
            setCountries(data.countries);
        })
    }

    function fetchAdminL3(country) {

        let filters = {};

        if(country){
            filters.countryId = country.id;
        }

        let administrativeLevel3Service = new AdministrativeLevel3Service();
        administrativeLevel3Service.getAllAdminLevel3WithFilters(null, null, filters, null)
            .then(data => {
                setAdmin3List(data.admins3)
            })
    }

    function fetchCities(administrativeLevel3) {
        let filters = {};
        if (administrativeLevel3) {
            filters.level3Id = administrativeLevel3.id;
        }

        let cityService = new CityService();
        cityService.getCities(null, null, filters, null)
            .then(data => {
                setCities(data.cities);
            })
            .catch(error => {

            })
    }

    function fetchPostalCodes(city, adminL3) {
        let filters = {};
        if (!city && adminL3) {
            filters.administrativeLevel3Id = adminL3.id;
        }
        if (city) {
            filters.cityId = city.id;
        }

        let postalCodeService = new PostalCodeService();
        postalCodeService.getAllPostalCodes(null, null, filters, null)
            .then(data => {
                setPostalCodes(data.postalCodes);
            })
            .catch(error => {

            })
    }

    function handleHamletChange(event, hamlet) {
        setHamlet(hamlet);
    }


    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdminL3Change(event, adminL3) {

        setAdminL3(adminL3);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);


    }

    function handleCityChange(event, city) {
        setCity(city);
        setPostalCode(null);
        setHamlet(null);
    }

    function handlePostalCodeChange(event, postalCode) {
        setHamlet(null);
        setPostalCode(postalCode);
    }

    function handleAddressValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.value;
        setAddresses(newAddresses);
    }

    function handleCheckboxValue(index, event, key) {
        let newAddresses = [...addresses];
        newAddresses[index][key] = event.target.checked;
        for (let i = 0; i < newAddresses.length; i++) {
            if (i !== index) {
                newAddresses[i][key] = false;
            }
        }
        setAddresses(newAddresses);
    }

    function handleIsCustomerChange(event){
        setCustomer(event.target.checked)
    }

    function handleIsSupplierChange(event){
        setSupplier(event.target.checked)
    }

    function closeNotification() {
        setOpenNotify(false);
    }

    function addAddress() {
        if (!country ||
            (!adminL3 && !freeAdministrativeLevel3) ||
            (!postalCode && !freePostalCode) ||
            (!city && !freeCity) ||
            !addressCompanyName ||
            !street
        ) {
            setOpenNotify(true);
            setNotifySeverity('error');
            setNotificationMessage("Inserire tutti i campi obbligatori");
        } else {
            let address = new AccountAddressValues();

            let addressData = {}

            addressData.country = country.iso3166Alpha2;
            addressData.administrative_level_3 = adminL3.code;
            addressData.city = city.name;
            addressData.postalCode = postalCode.postalCode;
            addressData.street = street;
            addressData.buildingNr = buildingNr;
            addressData.companyName = addressCompanyName;
            addressData.email = email;
            addressData.phone = phone;
            addressData.mobile = mobile;

            address.address = addressData;


            addresses.push(address);

            clearData();
            setAddingAddress(false);
            setRefresh(!refresh);
        }


    }

    function removeAddress(index) {

        let newAddresses = addresses;
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
        setRefresh(!refresh);


    }

    function clearData() {
        setCountry(null);
        setAdminL3(null);
        setCity(null);
        setPostalCode(null);
        setFreeAdministrativeLevel1('');
        setFreeAdministrativeLevel2('');
        setFreeAdministrativeLevel3('');
        setFreeCity('');
        setFreePostalCode('');
        setStreet('');
        setBuildingNr('');
        setAddressCompanyName('');
        setEmail('');
        setMobile('');
        setPhone('');
    }

    function handleSubmit() {

        let newAddresses = [];

        for (let i = 0; i < addresses.length; i++) {
            let newAddress = {};
            newAddress.address = {};
            newAddress.address.country = addresses[i].address.country;
            newAddress.address.administrativeLevel3 = addresses[i].address.administrative_level_3;
            newAddress.address.administrativeLevel2 = addresses[i].address.administrative_level_2;
            newAddress.address.administrativeLevel1 = addresses[i].address.administrative_level_1;
            newAddress.address.city = addresses[i].address.city;
            newAddress.address.postalCode = addresses[i].address.postalCode;
            newAddress.address.street = addresses[i].address.street;
            newAddress.address.buildingNr = addresses[i].address.buildingNr;
            newAddress.address.companyName = addresses[i].address.companyName;
            newAddress.address.email = addresses[i].address.email;
            newAddress.address.mobile = addresses[i].address.mobile;
            newAddress.address.phone = addresses[i].address.phone;
            newAddress.default = addresses[i].default;
            newAddress.type = addresses[i].type;

            newAddresses.push(newAddress);

        }

        const accountObj = {
            accountCorporateCode: accountCorporateCode,
            companyName: companyName,
            vatNumber: vatNumber,
            fiscalCode: fiscalCode,
            isCustomer: isCustomer,
            isSupplier: isSupplier,
            isCreditBlocked: creditBlock,
            isAdministrativeBlocked: adminBlock,
            addresses: newAddresses
        };

        if(id){
            let accountService = new AccountService();
            accountService.editAccount(accountObj, id)
                .then(success => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true);
                    setTimeout(() => {
                        history.push(`/accounts`);
                    }, 2000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        } else {
            let accountService = new AccountService();
            accountService.saveNewAccount(accountObj)
                .then(success => {
                    setNotifySeverity('success');
                    setNotificationMessage(t("successful"))
                    setOpenNotify(true);
                    setTimeout(() => {
                        history.push(`/accounts`);
                    }, 2000)
                })
                .catch(error => {
                    error.response.json().then((response) => {
                        setNotifySeverity('error');
                        setNotificationMessage(response.message);
                        setOpenNotify(true);
                    });
                })
        }




    }

    function handleFreeAdministrativeLevel1Change(event) {
        setFreeAdministrativeLevel1(event.target.value);
    }

    function handleFreeAdministrativeLevel2Change(event) {
        setFreeAdministrativeLevel2(event.target.value);
    }

    function handleFreeAdministrativeLevel3Change(event) {
        setFreeAdministrativeLevel3(event.target.value);
    }

    function handleFreeCityChange(event) {
        setFreeCity(event.target.value);
    }

    function handleFreePostalCodeChange(event) {
        setFreePostalCode(event.target.value);
    }

    function handleStreetChange(event) {
        setStreet(event.target.value);
    }

    function handleBuildingNrChange(event) {
        setBuildingNr(event.target.value)
    }

    function handleAddressCompanyNameChange(event) {
        setAddressCompanyName(event.target.value)
    }

    function handleEmailChange(event) {
        setEmail(event.target.value)
    }

    function handlePhoneChange(event) {
        setPhone(event.target.value)
    }

    function handleMobileChange(event) {
        setMobile(event.target.value)
    }

    function fetchAccount() {
        if (id) {
            const accountService = new AccountService();
            accountService.getById(id)
                .then(account => {
                    setAccountCorporateCode(account.accountCorporateCode);
                    setCompanyName(account.companyName);
                    setVatNumber(account.vatNumber);
                    setFiscalCode(account.fiscalCode);
                    setCustomer(account.customer);
                    setSupplier(account.supplier);
                    setAdminBlock(account.administrativeBlocked);
                    setCreditBlock(account.creditBlocked);

                    account.addresses.map(accAddress => {
                        // populateAddress(accAddress);\
                        addresses.push(accAddress);
                    })
                })
                .catch((err) => {

                })
        }
        setTimeout(()=>{
            setLoading(false);
        }, 1000)


    }

    useEffect(()=>{
        fetchAccount();
    }, [])

    const history = useHistory();

    const {t} = useTranslation();


    const classes = useStyles();

    const handleCompanyNameChange = event => {
        setCompanyName(event.target.value)
    };

    const handleAccountCorporateCodeChange = event => {
        setAccountCorporateCode(event.target.value)
    }

    const handleVatNumberChange = event => {
        setVatNumber(event.target.value)
    }

    const handleFiscalCodeChange = event => {
        setFiscalCode(event.target.value)
    }

    function openAddressMask() {
        setAddingAddress(true);
    }

    function cancelAddAddress() {
        setAddingAddress(false);
        clearData();
    }

    function generateAddress(country) {
        // if (country && country.id === 106) {
            return <div style={{display: "flex", marginBottom: "3%"}}>
                <Autocomplete
                    id="administrativeLevel3"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={admin3List}
                    disabled={!country}
                    getOptionLabel={option => option.name}
                    value={adminL3}
                    defaultValue={adminL3}
                    noOptionsText={t("no_options")}
                    onChange={handleAdminL3Change}
                    onOpen={() => fetchAdminL3(country)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrative3")}
                            variant="outlined"
                            margin="dense"
                            value={adminL3}
                            defaultValue={adminL3}
                        />
                    )}
                />
                <Autocomplete
                    id="city"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "2%"}}
                    options={cities}
                    disabled={!adminL3}
                    getOptionLabel={option => option.name}
                    value={city}
                    defaultValue={city}
                    noOptionsText={t("no_options")}
                    onChange={handleCityChange}
                    onOpen={() => fetchCities(adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            required
                            error={!city}
                            helperText={!adminL3 ? t("select_admin3_first") : (!city ? t("required") : null)}
                        />
                    )}
                />
                <Autocomplete
                    id="postalCode"
                    size="small"
                    style={{minWidth: "30%", width: "fit-content", marginInline: "1%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    value={postalCode}
                    defaultValue={postalCode}
                    noOptionsText={t("no_options")}
                    onChange={handlePostalCodeChange}
                    onOpen={() => fetchPostalCodes(city, adminL3)}
                    ListboxProps={{style: {maxHeight: '15rem'},}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={postalCode}
                            defaultValue={postalCode}
                            required
                            error={!postalCode}
                            helperText={!postalCode ? t("required") : null}
                        />
                    )}
                />

            </div>
        // } else {
        //     return <>
        //         <div style={{display: "flex", marginBottom: "5%"}}>
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative1")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel1}
        //                 onChange={handleFreeAdministrativeLevel1Change}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative2")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel2}
        //                 onChange={handleFreeAdministrativeLevel2Change}
        //             />
        //         </div>
        //         <div style={{display: "flex", marginBottom: "1%"}}>
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("administrative3")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeAdministrativeLevel3}
        //                 onChange={handleFreeAdministrativeLevel3Change}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("city")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freeCity}
        //                 required
        //                 error={!freeCity}
        //                 helperText={!freeCity ? t("required") : null}
        //                 onChange={handleFreeCityChange}
        //             />
        //             <TextField
        //                 style={{marginInline: "2%"}}
        //                 label={t("postalCode")}
        //                 variant={"outlined"}
        //                 size={"small"}
        //                 value={freePostalCode}
        //                 onChange={handleFreePostalCodeChange}
        //                 required
        //                 error={!freePostalCode}
        //                 helperText={!freePostalCode ? t("required") : null}
        //             />
        //         </div>
        //     </>
        // }
    }

    const handleClose = event => {
        history.push('/accounts');
    }


    return <div>
        {/*<DialogTitle>{t("account_data")}</DialogTitle>*/}

        {(loading) ?

            <LoadingComponent/>

            :

            <>
                <DialogTitle><Typography variant={"button"}>{t("account_data")}</Typography></DialogTitle>

                <Card style={{width: "60%", margin: "auto"}}>
                    <TextField
                        required
                        error={!accountCorporateCode}
                        helperText={!accountCorporateCode ? t("required") : null}
                        label={t("account_corporate_code")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleAccountCorporateCodeChange}
                        value={accountCorporateCode}
                        className={classes.field}
                    />

                    <TextField
                        required
                        error={!companyName}
                        helperText={!companyName ? t("required") : null}
                        label={t("company_name")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleCompanyNameChange}
                        value={companyName}
                        className={classes.field}
                    />

                    <TextField
                        required
                        error={!vatNumber}
                        helperText={!vatNumber ? t("required") : null}
                        label={t("vat_number")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleVatNumberChange}
                        value={vatNumber}
                        className={classes.field}
                    />

                    <TextField
                        required
                        error={!fiscalCode}
                        helperText={!fiscalCode ? t("required") : null}
                        label={t("fiscal_code")}
                        variant="outlined"
                        type="text"
                        margin="dense"
                        onChange={handleFiscalCodeChange}
                        value={fiscalCode}
                        className={classes.field}
                    />
                    <div>
                        <FormControlLabel
                            style={{margin: "5%"}}
                            label={<Typography fontSize={12}
                                               variant={"button"}>{t("customer")}</Typography>}
                            control={
                                <Checkbox
                                    checked={isCustomer === true}
                                    onChange={handleIsCustomerChange}
                                />
                            }
                        />
                        <FormControlLabel
                            style={{margin: "5%"}}
                            label={<Typography fontSize={12}
                                               variant={"button"}>{t("supplier")}</Typography>}
                            control={
                                <Checkbox
                                    checked={isSupplier === true}
                                    onChange={handleIsSupplierChange}
                                />
                            }
                        />
                    </div>
                    <div style={{marginBottom: "5%"}}>
                        <FormControlLabel control={<Switch
                            disabled={!permissions.includes("su:admin")}
                            checked={creditBlock}
                            onChange={event => setCreditBlock(event.target.checked)}
                            color={"primary"}

                        />} label={<Typography variant={"button"}>{t("credit_block")}</Typography>}
                                          labelPlacement="bottom"
                        />
                        <FormControlLabel control={<Switch
                            disabled={!permissions.includes("su:admin")}
                            checked={adminBlock}
                            onChange={event => setAdminBlock(event.target.checked)}
                            color={"primary"}

                        />} label={<Typography variant={"button"}>{t("administrative_block")}</Typography>}
                                          labelPlacement="bottom"
                        />
                    </div>
                </Card>
                <Grid item xs={12} style={{marginTop: "5%"}}>
                    <Grid container justifyContent="center" spacing={2}>
                        {addresses.map((address, index) => {
                            return <Grid item style={{width: "30%"}}>
                                <Card>
                                    <FormControl
                                        required
                                        variant={"outlined"}
                                        margin={"dense"}
                                        style={{marginBlock: "5%", width: "70%"}}
                                    >
                                        <InputLabel>{t("address_type")}</InputLabel>
                                        <Select
                                            value={address.type}
                                            required
                                            error={!address.type}
                                            label={t("address_type") + "*"}
                                            onChange={(event) => handleAddressValue(index, event, 'type')}
                                            // options={types}
                                            // MenuProps={{
                                            //     anchorOrigin: {
                                            //         vertical: "bottom",
                                            //         horizontal: "left"
                                            //     },
                                            //     transformOrigin: {
                                            //         vertical: "top",
                                            //         horizontal: "left"
                                            //     },
                                            //     getContentAnchorEl: null
                                            // }}
                                        >
                                            {types.map((type, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={type}
                                                    >
                                                        {t(type)}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <IconButton type={"button"} size={"small"} style={{float: "right"}}
                                                onClick={() => removeAddress(index)}>
                                        <CancelOutlined/>
                                    </IconButton>
                                    <div>
                                        <FormControlLabel
                                            label={<Typography fontSize={12}
                                                               variant={"button"}>{t("default_address")}</Typography>}
                                            control={
                                                <Checkbox
                                                    checked={address.default === true}
                                                    onChange={(event) => handleCheckboxValue(index, event, 'default')}
                                                />
                                            }
                                        />
                                    </div>


                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.companyName}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.street}
                                        {address.address.buildingNr ? ", " + address.address.buildingNr : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.postalCode}
                                        {", " + address.address.city}
                                        {" (" + address.address.administrative_level_3 + ")"}
                                        {" - " + address.address.country}
                                        {address.address.administrative_level_1 ? " " + address.address.administrative_level_1 : ""}
                                        {address.address.administrative_level_2 ? " " + address.address.administrative_level_2 : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.email ? "Email: " + address.address.email : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"}>
                                        {address.address.phone ? "Tel: " + address.address.phone : ""}
                                    </Typography>
                                    <Typography align={"left"} marginLeft={"5%"} marginBottom={"2%"}>
                                        {address.address.mobile ? "Cell: " + address.address.mobile : ""}
                                    </Typography>
                                </Card>
                            </Grid>
                        })}
                    </Grid>
                    {!addingAddress ?
                        <div>
                            <Button className={classes.create_button}
                                    endIcon={<GridAddIcon/>}
                                    onClick={openAddressMask}
                                    variant={"contained"}>
                                {t("add_address")}

                            </Button>
                        </div>
                        :

                        <Card style={{width: "60%", margin: "auto", marginTop: "5%"}}>
                            <Autocomplete
                                id="country"
                                size="small"
                                style={{minWidth: "30%", width: "fit-content", marginBlock: "4%", marginLeft: "2%"}}
                                options={countries}
                                getOptionLabel={option => option.iso3166Alpha2 + " - " + option.name}
                                value={country}
                                defaultValue={country}
                                onChange={handleCountryChange}
                                onSelect={fetchCountries}
                                ListboxProps={{style: {maxHeight: '15rem'},}}
                                noOptionsText={t("no_options")}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("country")}
                                    variant="outlined"
                                    margin="dense"
                                    value={country}
                                    defaultValue={country}
                                    required
                                    error={!country}
                                    helperText={!country ? t("required") : null}
                                />}
                            />

                            {generateAddress(country)}

                            <div style={{display: "flex", marginBlock: "5%"}}>
                                {/*<div style={{display: "flex", marginBlock: "2%"}}>*/}
                                <TextField
                                    required
                                    error={!addressCompanyName}
                                    helperText={!addressCompanyName ? t("required") : null}
                                    style={{marginInline: "2%"}}
                                    label={t("address_company_name")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={addressCompanyName}
                                    onChange={handleAddressCompanyNameChange}
                                />
                                {/*</div>*/}
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("street")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={street}
                                    required
                                    error={!street}
                                    helperText={!street ? t("required") : null}
                                    onChange={handleStreetChange}
                                />
                                <TextField
                                    style={{marginInline: "2%", width: "10%"}}
                                    label={t("buildingNr")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={buildingNr}
                                    onChange={handleBuildingNrChange}
                                />
                            </div>
                            <div style={{display: "flex", marginBottom: "5%"}}>
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("email")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("phone")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                                <TextField
                                    style={{marginInline: "2%"}}
                                    label={t("mobile")}
                                    variant={"outlined"}
                                    size={"small"}
                                    value={mobile}
                                    onChange={handleMobileChange}
                                />
                            </div>
                            <div>
                                <Button className={classes.create_button}
                                        endIcon={<CancelOutlined/>}
                                        onClick={cancelAddAddress}
                                        variant={"contained"}>
                                    {t("back")}

                                </Button>
                                <Button
                                    className={classes.create_button}
                                    endIcon={<AddCircle/>}
                                    onClick={addAddress}
                                    variant={"contained"}>
                                    {t("add")}

                                </Button>
                            </div>
                        </Card>
                    }
                </Grid>
                <CardActions className={classes.button_container}>
                    <Button
                        disabled={addingAddress}
                        className={classes.cancel_button}
                        endIcon={<CancelOutlined/>}
                        onClick={handleClose}
                        variant={"contained"}>
                        {t("cancel")}
                    </Button>
                    <Button
                        disabled={addingAddress}
                        className={classes.confirm_button}
                        endIcon={<CheckCircleOutlined className={classes.confirm_icon}/>}
                        onClick={handleSubmit}
                        color="primary"
                        variant={"contained"}>
                        {t("confirm")}
                    </Button>
                </CardActions>
            </>
        }
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={closeNotification}>{notificationMessage}</Notification>

    </div>
}

export default AccountForm;