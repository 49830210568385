import {Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {Cached} from "@material-ui/icons";
import DocumentService from "../documents/DocumentService";
import Notification from "../../components/notifications/Notification";
import theme from "../../theme/theme";


export default function MonthExtraction() {

    const [loading, setLoading] = useState(false);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const {t} = useTranslation();

    async function generateReport() {

        setLoading(true);

        await new DocumentService().previousMonth()
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setLoading(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoading(false);
                })
            })

    }

    return <div>
        <Notification open={openNotify} severity={notifySeverity} duration={15000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<Cached/>}
            variant={"contained"}
            style={{backgroundColor: theme.palette.primary.main, color: "white"}}
            onClick={generateReport}
        >
            <Typography>{t("generate_report")}</Typography>
        </LoadingButton>
    </div>


}