import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";


export default class QuoteService{

    getQuotesOfShipment(shipmentId){

        let apiKey = new UserService().getApiKey();

        let queryString = `shipmentId=${shipmentId}`;

        // if (filters) {
        //
        //     const keys = Object.keys(filters);
        //     for (let i = 0; i < keys.length; i++) {
        //         const key = keys[i];
        //         const value = filters[key];
        //
        //         let filterString = key.toString();
        //         if (queryString.length > 0) {
        //             queryString += `&`;
        //         }
        //         queryString += `${filterString}=${value}`;
        //     }
        // }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/quotes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }

    addQuote(shipmentId, input){

        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)

        };
        let pathVariable = `/api/v1/quotes/${shipmentId}/add`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);

    }

    deleteQuote(quoteId){

        let apiKey = new UserService().getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        };
        let pathVariable = `/api/v1/quotes/${quoteId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);

    }


}