import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../api/ApiUtils";

export default class HandheldService {
    getByFilters(filters) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = "";
        if (filters) {
            if (filters.warehouseId) {
                if (queryString.length > 0) {
                    queryString += "&";
                }

                queryString += "warehouseId=" + filters.warehouseId;
            }
        }

        let pathVariable = `/api/v1/handhelds`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getById(handheldId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let pathVariable = `/api/v1/handhelds/${handheldId}`;

        let requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    createHandheld(handheld) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(handheld)
        };

        return fetch(`/api/v1/handhelds`, requestOptions)
            .then(checkStatus)
    }

    updateHandheld(handheldId, handheld) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(handheld)
        };

        return fetch(`/api/v1/handhelds/${handheldId}`, requestOptions)
            .then(checkStatus)
    }

    deleteHandheld(handheldId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/handhelds/${handheldId}`, requestOptions)
            .then(checkStatus)
    }
}